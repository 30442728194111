import React, { FC, useState, useEffect, useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  useApolloClient,
  useQuery,
  useMutation,
  useLazyQuery,
} from '@apollo/react-hooks';
import {
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
  Box,
  Typography,
  Button,
  Paper,
} from '@material-ui/core';

import { ValueType } from 'react-select';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { ApolloError, MutationUpdaterFn } from 'apollo-boost';

import useStyles from './styles';
import SearchInput from '../Common/SearchInput/SearchInput';
import SearchFilter from '../Common/SearchFilter/SearchFilter';
import CustomTable from '../Common/CustomTable/CustomTable';
import UserFormDialog from '../Establishment/EstablishmentAdd/UserFormDialog/UserFormDialog';
import { FormInputDataState, User } from '../../types/user';
import ExcelIcon from '../../assets/images/excel.png';
import { WorkerFilter } from '../../types/filters';
import { GroupAction, HeadCell } from '../../types/table';
import { FilterControlType } from '../../types/search';
import { SnackbarType } from '../../types/snackbar';
import { displaySnackbar } from '../../common/snackbar';
import {
  CreateWorker,
  CreateWorkerVariables,
} from '../../graphql/worker/types/CreateWorker';
import { Workers, WorkersVariables } from '../../graphql/worker/types/Workers';
import {
  UpdateWorker,
  UpdateWorkerVariables,
} from '../../graphql/worker/types/UpdateWorker';
import {
  UpdateWorkerStatus,
  UpdateWorkerStatusVariables,
} from '../../graphql/worker/types/UpdateWorkerStatus';
import {
  CREATE_WORKER,
  UPDATE_WORKER,
  DELETE_WORKER,
  DELETE_WORKERS,
  UPDATE_WORKER_STATUS,
  CREATE_WORKERS,
} from '../../graphql/worker/mutation';
import { GET_WORKERS, GET_WORKER } from '../../graphql/worker/query';
import { EtablishmentNames } from '../../graphql/etablishment/types/EtablishmentNames';
import { GET_ETABLISHMENT_NAMES } from '../../graphql/etablishment/query';
import { checkActionGroup } from '../../common/validator';
import {
  DeleteWorker,
  DeleteWorkerVariables,
} from '../../graphql/worker/types/DeleteWorker';
import {
  DeleteWorkers,
  DeleteWorkersVariables,
} from '../../graphql/worker/types/DeleteWorkers';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import { Worker, WorkerVariables } from '../../graphql/worker/types/Worker';
import { exportData, formatWorkerData } from '../../common/excel';
import ExcelReader from '../Common/ExcelReader';
import EstablishmentChoice from '../Establishment/EstablishmentChoice';
import { getUsersFromFile } from '../../utils/excel-import.utils';
import { UserValidationState } from '../../types/user-validator.types';
import {
  getExcelImportUserErrorMessage,
  getImportExcelValidators,
  validateUsers,
} from '../../utils/user-validator.utils';
import { showSnackbar } from '../../utils/snackbar.util';
import {
  CreateWorkers,
  CreateWorkersVariables,
} from '../../graphql/worker/types/CreateWorkers';
import { formInputDataStateToUser } from '../../utils/user.utils';
import { handleUserListValidation } from '../../utils/user-list.utils';
import UserContext, { UserContextRole } from '../Context/UserContext';

interface EnhancedWorkerProps {}

const ACTIONS: GroupAction[] = [
  { label: 'Exporter en Excel', value: 'export' },
  { label: 'Activer', value: 'enable' },
  { label: 'Désactiver', value: 'disable' },
  { label: 'Supprimer', value: 'remove' },
];

const INIT_FILTER: WorkerFilter = {
  establishment: '',
};

const INIT_DATA = {
  name: '',
  lastName: '',
  email: '',
  mobilePhone: '',
  phone: '',
  userFunction: '',
  status: false,
  establishmentId: 0,
};

const normalizeFilter = (filters: WorkerFilter): any => {
  return {
    establishmentName: filters.establishment || '',
  };
};

/**
 * Worker Component
 * @param props WorkerProps
 */
const EnhancedWorker: FC<EnhancedWorkerProps & RouteComponentProps> = (
  props,
) => {
  const classes = useStyles();
  const userRole: UserContextRole = useContext(UserContext).userRole;
  const client = useApolloClient();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [data, setData] = useState<any[]>([]);
  const [filter, setFilter] = useState<WorkerFilter>(INIT_FILTER);
  const [options, setOptions] = useState<any[]>([]);
  const [inputData, setInputData] = useState<FormInputDataState>(INIT_DATA);
  const [workerId, setworkerId] = useState<number>(0);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openUpdateUserDialog, setOpenUpdateUserDialog] =
    useState<boolean>(false);
  const [editId, setEditId] = useState<number>(-1);
  const [inputUpdateData, setInputUpdateData] =
    useState<FormInputDataState>(INIT_DATA);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [importFiles, setImportFiles] = useState<FileList>();
  const [openEstablishmentChoice, setOpenEstablishmentChoice] =
    useState<boolean>(false);

  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  let filters = {
    name: searchKey || '',
    userRole,
  };

  const variables = {
    input: {
      filter: JSON.stringify({
        // etablishmentNull: false,
        ...normalizeFilter(filter),
        ...filters,
      }),
    },
  };

  const { data: establishmentNames } = useQuery<EtablishmentNames>(
    GET_ETABLISHMENT_NAMES,
  );

  const INPUT_FILTERS: FilterControlType[] = [
    {
      label: 'Etablissement',
      name: 'establishment',
      value: '',
      placeholder: 'Tous les établissements',
      type: 'select',
      options,
      autoComplete: true,
    },
  ];

  useEffect(() => {
    if (establishmentNames && establishmentNames.etablishments) {
      const opts = establishmentNames.etablishments.map((item: any) => {
        if (item)
          return {
            label: item.name,
            value: item.name,
          };
        return true;
      });
      setOptions(opts);
    }
  }, [establishmentNames]);

  const {
    loading: loadingWorkerList,
    data: workerList,
    refetch,
  } = useQuery<Workers, WorkersVariables>(GET_WORKERS, { variables });

  const [createWorker, { loading: loadingCreateWorker }] = useMutation<
    CreateWorker,
    CreateWorkerVariables
  >(CREATE_WORKER, {
    onCompleted: (data: CreateWorker) => {
      if (data && data.createWorker) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = "L'intervenant a été ajouté";
        displaySnackbar(client, snackbarData);
        setOpenDialog(false);
        setInputData(INIT_DATA);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la création d'intervenant";

      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const { message } = error.graphQLErrors[0];
        switch (message) {
          case 'EMAIL_ALREADY_USED':
            snackbarData.type = 'WARNING';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = "L'adresse email est déjà utilisée";
            break;
        }
      } else if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [createWorkers, { loading: loadingCreateWorkers }] = useMutation<
    CreateWorkers,
    CreateWorkersVariables
  >(CREATE_WORKERS, {
    onCompleted: (data: CreateWorkers) => {
      snackbarData.type = 'SUCCESS';
      snackbarData.autoHideDuration = 5000;
      showSnackbar({
        client,
        message: `Les intervenants ont été ajoutés`,
        type: 'SUCCESS',
      });
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la création des intervenants";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const { message } = error.graphQLErrors[0];
        if (message.includes('EMAIL_ALREADY_USED')) {
          snackbarData.type = 'WARNING';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `L'adresse email ${
            message.split(';')[0]
          } est déjà utilisée`;
        }
      } else if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [updateWorker, { loading: loadingUpdateWorker }] = useMutation<
    UpdateWorker,
    UpdateWorkerVariables
  >(UPDATE_WORKER, {
    onCompleted: (data: UpdateWorker) => {
      if (data && data.updateWorker) {
        setOpenUpdateUserDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour d'intervenant";
      displaySnackbar(client, snackbarData);
    },
  });

  const [getWorkerDetails] = useLazyQuery<Worker, WorkerVariables>(GET_WORKER, {
    onCompleted: (data: Worker) => {
      if (data && data.worker) {
        const { etablishment, user } = data.worker;
        setInputUpdateData({
          email: (user && user.email) || '',
          name: (user && user.firstName) || '',
          lastName: (user && user.lastName) || '',
          mobilePhone: (user && user.mobilePhone) || '',
          userFunction: (user && user.userFunction) || '',
          phone: (user && user.phone) || '',
          establishmentId: (etablishment && etablishment.id) || 0,
          status: (user && user.enabled) || false,
        });
        setOpenUpdateUserDialog(true);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la récupération des informations du bénéficiaire";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [deleteWorker, { loading: loadingDeleteWorker }] = useMutation<
    DeleteWorker,
    DeleteWorkerVariables
  >(DELETE_WORKER, {
    onCompleted: (data: DeleteWorker) => {
      if (data && data.deleteWorker) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `L'intervenant a été supprimé`;
        displaySnackbar(client, snackbarData);
        setOpenConfirmDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la suppression d'intervenant";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [deleteWorkers, { loading: loadingDeleteWorkers }] = useMutation<
    DeleteWorkers,
    DeleteWorkersVariables
  >(DELETE_WORKERS, {
    onCompleted: (data: DeleteWorkers) => {
      if (data && data.deleteWorkers) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Les intervenants ont été supprimés`;
        displaySnackbar(client, snackbarData);
        setOpenConfirmDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la suppression des intervenants";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  useEffect(() => {
    if (workerList && workerList.workers) {
      setData(workerList.workers);
    }
  }, [workerList]);

  const [getWorkerInfo] = useLazyQuery<Worker, WorkerVariables>(GET_WORKER, {
    onCompleted: (data: Worker) => {
      if (data && data.worker) {
        exportData({
          csvData: [formatWorkerData(data.worker)],
          fileName: 'Intervenant',
          sheetName: 'Intervenant',
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la récupération des informations d'intervenant";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [getSelectedWorkerInfo] = useLazyQuery<Workers, WorkersVariables>(
    GET_WORKERS,
    {
      onCompleted: (data: Workers) => {
        if (data) {
          exportData({
            csvData: data.workers.map((item: any) => formatWorkerData(item)),
            fileName: 'Intervenants',
            sheetName: 'Intervenants',
          });
        }
      },
      onError: (error: ApolloError) => {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          "Une erreur s'est produite lors de la récupération des informations d'intervenant";
        if (error.networkError) {
          snackbarData.type = 'ERROR';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            'Erreur de réseau. Veuillez vérifier votre connexion internet';
        }
        displaySnackbar(client, snackbarData);
      },
    },
  );

  const onHandleChangeStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const { checked } = event.target;
    if (id) {
      updateWorker({
        variables: {
          id,
          enabled: checked,
        },
      }).then(({ data }) => {
        if (data && data.updateWorker) {
          const statut =
            data.updateWorker.user && data.updateWorker.user.enabled
              ? 'activé'
              : 'désactivé';
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `L'intervenant a été ${statut}`;
          displaySnackbar(client, snackbarData);
        }
      });
    }
  };

  const [updateWorkerStatus] = useMutation<
    UpdateWorkerStatus,
    UpdateWorkerStatusVariables
  >(UPDATE_WORKER_STATUS, {
    onCompleted: (data: UpdateWorkerStatus) => {
      if (data && data.updateWorkerStatus) {
        const statut =
          data.updateWorkerStatus &&
          data.updateWorkerStatus[0] &&
          data.updateWorkerStatus[0].user &&
          data.updateWorkerStatus[0].user.enabled === true
            ? 'activé'
            : 'désactivé';
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Les intervenants ont été ${statut}s`;
        displaySnackbar(client, snackbarData);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour des intervenants";

      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const onHandleAddUser = (data: FormInputDataState, cb: any) => {
    const user: User = formInputDataStateToUser(data, 'worker') as User;
    handleUserListValidation(user, client, () => {
      createWorker({
        variables: user,
        update: (cache, { data }) => {
          const workerData = cache.readQuery<Workers>({
            query: GET_WORKERS,
            variables,
          });
          const newWorker = data && data.createWorker && data.createWorker.data;
          if (newWorker && workerData) {
            cache.writeQuery({
              query: GET_WORKERS,
              variables,
              data: {
                workers: [newWorker, ...workerData.workers],
              },
            });
          }
        },
      })
        .then(({ data }) => {
          return cb(!!(data && data.createWorker && data.createWorker.success));
        })
        .catch(() => {
          return cb(false);
        });
    });
  };

  const onHandleUpdateUser = (data: FormInputDataState, cb: any) => {
    const user: User = formInputDataStateToUser(data, 'worker') as User;
    handleUserListValidation(user, client, () => {
      updateWorker({
        variables: {
          ...user,
          id: editId,
        },
      }).then(({ data }) => {
        if (data && data.updateWorker) {
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `L'intervenant a été mis à jour`;
          displaySnackbar(client, snackbarData);
        }
      });
    });
  };

  const onHandleDelete = (id: number) => {
    setworkerId(id);
    setOpenConfirmDialog(true);
  };

  const onDeleteWorker = () => {
    if (workerId && workerId > 0) {
      deleteWorker({
        variables: {
          id: workerId,
        },
        update: (cache, { data }) => {
          const deletedId = data && data.deleteWorker && data.deleteWorker.id;

          const workerData = cache.readQuery<Workers>({
            query: GET_WORKERS,
            variables,
          });

          if (deletedId && workerData) {
            const newData = workerData.workers.filter(
              (worker) => worker && worker.id !== deletedId,
            );
            cache.writeQuery({
              query: GET_WORKERS,
              variables,
              data: {
                workers: newData,
              },
            });
          }
        },
      });
    } else {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'ID intervenant non trouvé.';
      displaySnackbar(client, snackbarData);
      setOpenConfirmDialog(false);
    }
  };
  const onHandleEditUser = (id: number) => {
    setEditId(id);
    getWorkerDetails({ variables: { id } });
  };

  const onHandleSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKey(value);
  };

  const onHandleExport = (id: number) => {
    getWorkerInfo({ variables: { id } });
  };

  const exportWorkerInfos = (selectedItems: number[]): void => {
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les intervenants à exporter`,
      )
    ) {
      const filters = JSON.stringify({ ids: selectedItems });
      const order = JSON.stringify({ id: 'asc' });
      const variables = { input: { filter: filters, orderBy: order } };
      getSelectedWorkerInfo({ variables });
    }
  };

  const changeWorkerStatus = (
    selectedItems: number[],
    enabled: boolean,
  ): void => {
    const status = enabled ? 'activer' : 'désactiver';
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les intervenants à ${status}`,
      )
    ) {
      updateWorkerStatus({
        variables: { ids: selectedItems, status: enabled },
      });
    }
  };

  const removeWorkers = (selectedItems: number[]): void => {
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les intervenants à supprimer`,
      )
    ) {
      deleteWorkers({
        variables: { ids: selectedItems },
        update: (cache, { data }) => {
          const deletedIds = ((data && data.deleteWorkers) || []).map(
            (item) => item && item.id,
          );

          const workerData = cache.readQuery<Workers>({
            query: GET_WORKERS,
            variables,
          });

          if (deletedIds && workerData && workerData.workers) {
            const newData = workerData.workers.filter(
              (worker) => worker && deletedIds.indexOf(worker.id) === -1,
            );
            cache.writeQuery({
              query: GET_WORKERS,
              variables,
              data: {
                workers: newData,
              },
            });
          }
        },
      });
    }
  };

  const onHandleAction = (action: ValueType<GroupAction>): void => {
    const actionGroup = action as GroupAction;

    if (actionGroup && actionGroup.value) {
      switch (actionGroup.value) {
        case 'export':
          exportWorkerInfos(selectedItems);
          break;
        case 'enable':
          changeWorkerStatus(selectedItems, true);
          break;
        case 'disable':
          changeWorkerStatus(selectedItems, false);
          break;
        case 'remove':
          removeWorkers(selectedItems);
          break;
      }
    }
  };
  const onExcelReaderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenEstablishmentChoice(true);
    if (event.target.files) {
      setImportFiles({ ...event.target.files });
    }
    event.preventDefault();
  };

  const workerToolbarButtons = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="worker-add"
      startIcon={<AddBoxIcon />}
      onClick={() => setOpenDialog(true)}
    >
      Ajouter un intervenant
    </Button>,
    <ExcelReader
      onChange={onExcelReaderChange}
      onClick={() => {}}
      loading={false}
    />,
  ];

  const columns: HeadCell[] = [
    {
      name: 'lastName',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value.user && value.user.lastName) || '-';
      },
    },
    {
      name: 'name',
      label: 'Prénom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.firstName) || '-';
      },
    },
    {
      name: 'etablishment',
      label: 'Etablissement',
      disablePadding: false,
      renderer: (value: any) => {
        const etabName = (value.etablishment && value.etablishment.name) || '-';
        return etabName;
      },
    },
    {
      name: 'mail',
      label: 'Mail',
      disablePadding: false,
      renderer: (value: any) => {
        return (value.user && value.user.email) || '-';
      },
    },
    {
      name: 'mobilePhone',
      label: 'Tél mobile',
      disablePadding: false,
      renderer: (value: any) => {
        return (value.user && value.user.mobilePhone) || '-';
      },
    },
    {
      name: 'phone',
      label: 'Tél fixe',
      disablePadding: false,
      renderer: (value: any) => {
        return (value.user && value.user.phone) || '-';
      },
    },
    {
      name: 'function',
      label: 'Fonction',
      disablePadding: false,
      renderer: (value: any) => {
        return (value.user && value.user.userFunction) || '-';
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => {
        const statut = value.user && value.user.enabled;
        return (
          <FormControlLabel
            control={
              <Switch
                checked={statut}
                onChange={(event) => onHandleChangeStatus(event, value.id)}
                name="enabled"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            }
            label={statut ? 'Activé' : 'Désactivé'}
          />
        );
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex" justifyContent="center">
          <Tooltip title="Exporter en Excel" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleExport(value.id);
              }}
              className={classes.margin}
            >
              <img
                alt="excel-icon"
                src={ExcelIcon}
                className={classes.excelIcon}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleDelete(value.id);
              }}
              className={classes.margin}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifier" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleEditUser(value.id);
              }}
              className={classes.margin}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const onCreateWorkersUpdate: MutationUpdaterFn<CreateWorkers> = (
    cache,
    { data },
  ) => {
    const workerDatas = cache.readQuery<Workers>({
      query: GET_WORKERS,
      variables,
    });
    const newWorkers = data && data.createWorkers && data.createWorkers.data;
    if (newWorkers && workerDatas) {
      cache.writeQuery({
        query: GET_WORKERS,
        variables,
        data: {
          beneficiaries: [...newWorkers, ...workerDatas.workers],
        },
      });
    }
  };

  const onChooseEstablishment = async (id: number) => {
    const file = importFiles && importFiles[0];
    const users: User[] = await getUsersFromFile(file as File, {
      userRole: 'worker',
      establishmentId: id,
    });
    const validationStatus: UserValidationState = validateUsers(
      users,
      getImportExcelValidators('worker'),
    );
    if (!validationStatus.valid && validationStatus.error) {
      const message: string = getExcelImportUserErrorMessage(
        validationStatus.error,
      );
      showSnackbar({
        message,
        type: 'ERROR',
        client,
      });
      setOpenEstablishmentChoice(false);
    } else {
      createWorkers({
        variables: {
          input: users.map((user) => {
            const { userRole, establishmentId, ...rest } = user;
            return {
              ...rest,
              etablishmentId: establishmentId,
            };
          }),
        },
        update: onCreateWorkersUpdate,
      }).then(() => {
        refetch();
      });
    }
  };

  return (
    <Box className={classes.root}>
      <Row>
        <Col xs={12} xl={9} lg={8} md={6}>
          <Paper className={classes.paper}>
            <SearchInput
              onChange={onHandleSearchKey}
              value={searchKey}
              placeholder="Recherchez un intervenant"
            />
          </Paper>
        </Col>
        {userRole === 'SUPER_ADMIN' && (
          <Col xs={12} xl={3} lg={4} md={6}>
            <Paper className={classes.paper}>
              <Typography className={classes.labelFilter}>
                Filtre de recherche :
              </Typography>
              <SearchFilter
                filterControls={INPUT_FILTERS}
                filter={filter}
                setFilter={setFilter}
              />
            </Paper>
          </Col>
        )}
      </Row>
      <Box className={classes.workerList}>
        <CustomTable
          toolbar
          selectable
          data={data}
          columns={columns}
          toolbarActions={ACTIONS}
          loadingData={loadingWorkerList}
          onHandleAction={onHandleAction}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          toolbarButtons={workerToolbarButtons}
          isLeftContent={true}
          toolbarProps={{
            toolbarTitle: 'Liste des intervenants',
          }}
        />
        <UserFormDialog
          userRole="worker"
          open={openDialog}
          title="Ajout d'un intervenant"
          onSubmit={onHandleAddUser}
          data={inputData}
          loadingButton={loadingCreateWorker}
          activeEstablishmentField={true}
          handleClose={() => {
            setOpenDialog(false);
          }}
        />
        <UserFormDialog
          userRole="worker"
          open={openUpdateUserDialog}
          title="Modification d'un intervenant"
          onSubmit={onHandleUpdateUser}
          data={inputUpdateData}
          loadingButton={loadingUpdateWorker}
          activeEstablishmentField={true}
          buttonLabel="Enregistrer"
          handleClose={() => {
            setOpenUpdateUserDialog(false);
          }}
        />
        <ConfirmationDialog
          title="Suppression"
          message="Etes-vous sûr de vouloir supprimer cet intervenant ?"
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={onDeleteWorker}
          loadingConfirmation={loadingDeleteWorker}
        />
        <EstablishmentChoice
          open={openEstablishmentChoice}
          setOpen={setOpenEstablishmentChoice}
          onChooseEstablishment={onChooseEstablishment}
        />
      </Box>
    </Box>
  );
};

export default withRouter(EnhancedWorker);
