import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import useStyles from './styles';
import partyPopper from '../../../../assets/images/party-popper.png';

interface SuccessPageProps {
  message: string;
  onContinue?: () => void;
}

const SuccessPage: FC<SuccessPageProps & RouteComponentProps> = (props) => {
  const { history, message, onContinue } = props;
  const classes = useStyles();
  const defaultOnClick = () => history.push('/etablissements');
  const onClick = onContinue || defaultOnClick;

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.wrapForm}>
          <Box className={classes.imgContainer}>
            <img src={partyPopper} alt="party-popper" />
          </Box>
          <Box className={classes.title}>
            <Typography
              variant="h4"
              component="h1"
              className={classes.textTitle}
            >
              Félicitations !
            </Typography>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              className={classes.descTitle}
            >
              {message}
            </Typography>
          </Box>
          <Box className={classes.form}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={onClick}
            >
              Continuer
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(SuccessPage);
