import React from 'react';
import Box from '@material-ui/core/Box';

import Grid from '@material-ui/core/Grid';
import Card from '../../../../Common/CustomCard';
import { EtablishmentDataType } from '../../../../../types/establishment';
import EstablishmentBasicInfoForm from '../../EstablishmentBasicInfoForm';
import EstablishmentRootList from '../../EstablishmentChild/EstablishmentRootList';
import { AdministratorList, WorkerList, BeneficiaryList } from '../../UserList';
import ProposedPrestations from '../../EstablishmentBasicInfoForm/ProposedPrestations';

const getStepComponents = (
  inputData: EtablishmentDataType,
  setInputData: React.Dispatch<React.SetStateAction<EtablishmentDataType>>,
  type: string,
): any[] => {
  switch (type) {
    case 'HOME':
    case 'CHILD':
    case 'RESIDENCE':
      return [
        <Box
          display="flex"
          justifyContent="center"
          style={{ padding: 16, marginBottom: 100 }}
        >
          <EstablishmentRootList
            setEtabInputData={setInputData}
            dataSource="add-establishment"
          />
        </Box>,
        <EstablishmentBasicInfoForm
          inputData={inputData}
          onChange={(data) => setInputData(data)}
        />,
        <Box
          display="flex"
          justifyContent="center"
          style={{
            padding: 16,
            marginBottom: 100,
            minHeight: 400,
            marginTop: 8,
          }}
        >
          <Grid item>
            <Card title="Prestations proposées">
              <ProposedPrestations
                inputData={inputData}
                setInputData={setInputData}
              />
            </Card>
          </Grid>
        </Box>,
        <Box
          display="flex"
          justifyContent="center"
          style={{ padding: 16, marginBottom: 100 }}
        >
          <AdministratorList
            setEtabInputData={setInputData}
            dataSource="add-establishment"
          />
        </Box>,
        <Box
          display="flex"
          justifyContent="center"
          style={{ padding: 16, marginBottom: 100 }}
        >
          <WorkerList
            setEtabInputData={setInputData}
            dataSource="add-establishment"
          />
        </Box>,
        <Box
          display="flex"
          justifyContent="center"
          style={{ padding: 16, marginBottom: 100 }}
        >
          <BeneficiaryList
            setEtabInputData={setInputData}
            dataSource="add-establishment"
          />
        </Box>,
      ];
      break;

    default:
      return [
        <EstablishmentBasicInfoForm
          inputData={inputData}
          onChange={(data) => setInputData(data)}
        />,
        <Box
          display="flex"
          justifyContent="center"
          style={{ padding: 16, marginBottom: 100 }}
        >
          <AdministratorList
            setEtabInputData={setInputData}
            dataSource="add-establishment"
          />
        </Box>,
        <Box
          display="flex"
          justifyContent="center"
          style={{ padding: 16, marginBottom: 100 }}
        >
          <WorkerList
            setEtabInputData={setInputData}
            dataSource="add-establishment"
          />
        </Box>,
        <Box
          display="flex"
          justifyContent="center"
          style={{ padding: 16, marginBottom: 100 }}
        >
          <BeneficiaryList
            setEtabInputData={setInputData}
            dataSource="add-establishment"
          />
        </Box>,
        <Box
          display="flex"
          justifyContent="center"
          style={{ padding: 16, marginBottom: 100 }}
        >
          <EstablishmentRootList
            setEtabInputData={setInputData}
            dataSource="add-establishment"
          />
        </Box>,
      ];
      break;
  }
};

export default getStepComponents;
