import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 auto',
      padding: '116px 24px 4px',
      maxWidth: 1920,
      minHeight: 'calc(100vh - 80px)',
    },
    button: {
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    white: {
      color: theme.palette.common.white,
    },
    excelIcon: {
      width: 24,
      height: 24,
    },
    margin: {
      marginLeft: 8,
      marginRight: 8,
    },
    paper: {
      boxShadow: '0 6px 16px rgba(0,0,0,0.09)',
      padding: 15,
      borderRadius: 16,
      marginBottom: 30,
    },
    labelFilter: {
      fontSize: '0.875rem',
      color: '#9E9E9E',
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      top: -12,
      left: 30,
      padding: '0 8px',
    },
    beneficiaryList: {
      '& .MuiPaper-root': {
        boxShadow: 'none',
      },
    },
  }),
);

export default useStyles;
