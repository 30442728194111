import React, { FC, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useStyles from './styles';
import { FilterControlType, SnackbarType } from '../../types';
import Box from '@material-ui/core/Box';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { EtablishmentNames } from '../../graphql/etablishment/types/EtablishmentNames';
import {
  GET_ETABLISHMENT_BY_USER_ID,
  GET_ETABLISHMENT_NAMES,
  GET_ETABLISHMENT_WITHOUT_ORGANIZATION,
} from '../../graphql/etablishment/query';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/Col';
import {
  Button,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import SearchInput from '../Common/SearchInput';
import { EstablishmentFilter, PrestationFilter } from '../../types/filters';
import SearchFilter from '../Common/SearchFilter';
import UserContext, {
  UserContextRole,
  UserContextType,
} from '../Context/UserContext';
import CustomTable from '../Common/CustomTable';
import { GroupAction, HeadCell } from '../../types/table';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ExcelReader from '../Common/ExcelReader';
import ExcelIcon from '../../assets/images/excel.png';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  Prestation as PrestationInterface,
  PrestationDataType,
} from '../../types/prestation';
import PrestationAddFormDialog from './PrestationAddFormDialog';
import { validate } from '../../utils/universal-validator.utils';
import { DEFAULT_PRESTATION_VALIDATORS } from '../../utils/prestation-validator.utils';
import { showSnackbar } from '../../utils/snackbar.util';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import {
  createPrestation,
  createPrestationVariables,
} from '../../graphql/prestation/types/createPrestation';
import {
  CREATE_PRESTATION,
  CREATE_PRESTATIONS,
  DELETE_PRESTATION,
  DELETE_PRESTATIONS,
  UPDATE_PRESTATION,
} from '../../graphql/prestation/mutation';
import { ApolloError } from 'apollo-boost';
import { CreatePrestationInput } from '../../graphql/types/graphql-global-types';
import { displaySnackbar } from '../../common/snackbar';
import {
  Prestations,
  PrestationsVariables,
  Prestations_prestations,
} from '../../graphql/prestation/types/Prestations';
import {
  GET_PRESTATION,
  GET_PRESTATIONS,
  GET_PRESTATION_IDS,
} from '../../graphql/prestation/query';
import {
  DeletePrestation,
  DeletePrestationVariables,
} from '../../graphql/prestation/types/DeletePrestation';
import {
  UpdatePrestation,
  UpdatePrestationVariables,
} from '../../graphql/prestation/types/UpdatePrestation';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import {
  Prestation as PrestationType,
  PrestationVariables,
} from '../../graphql/prestation/types/Prestation';
import { GetMe } from '../../graphql/user/types/GetMe';
import { GET_ME } from '../../graphql/user/query';
import {
  GetEtablishmentByUserId,
  GetEtablishmentByUserIdVariables,
} from '../../graphql/etablishment/types/GetEtablishmentByUserId';
import { exportData } from '../../common/excel';
import { formatPrestationData } from '../../common/excel/formatter/prestation';
import { checkActionGroup } from '../../common/validator';
import { ValueType } from 'react-select';
import {
  PrestationByIds,
  PrestationByIdsVariables,
} from '../../graphql/prestation/types/PrestationByIds';
import {
  DeletePrestations,
  DeletePrestationsVariables,
} from '../../graphql/prestation/types/DeletePrestations';
import EstablishmentChoice from '../Establishment/EstablishmentChoice';
import {
  CreatePrestations,
  CreatePrestationsVariables,
} from '../../graphql/prestation/types/CreatePrestations';
import { getFromFile } from '../../utils/excel-import-prestation';
import {
  EtablishmentWithoutOrganization,
  EtablishmentWithoutOrganizationVariables,
} from '../../graphql/etablishment/types/EtablishmentWithoutOrganization';

interface PrestationProps {
  establishmentId: number;
}

const INIT_FILTER: PrestationFilter = {
  establishment: '',
};

const INIT_FILTER_ORGANISATION: EstablishmentFilter = {
  city: '',
  postalCode: '',
  enabled: -1,
  type: 'ALL',
};

const ACTIONS: GroupAction[] = [
  { label: 'Supprimer', value: 'remove' },
  { label: 'Exporter en Excel', value: 'export' },
];
const INIT_DATA: PrestationInterface = {
  libelle: '',
  establishmentId: null,
};

/**
 * InterventionReport Component
 * @param props InterventionReportProps
 */
const Prestation: FC<PrestationProps & RouteComponentProps> = (
  props: PrestationProps,
) => {
  const { establishmentId } = props;

  const user: UserContextType = useContext(UserContext);

  const { data: establishmentData } = useQuery<
    GetEtablishmentByUserId,
    GetEtablishmentByUserIdVariables
  >(GET_ETABLISHMENT_BY_USER_ID, {
    variables: {
      id: user.userId,
    },
  });

  const client = useApolloClient();
  const classes = useStyles();
  const userRole: UserContextRole = useContext(UserContext).userRole;
  const [options, setOptions] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [data, setData] = useState<any[]>([]);
  const [clearSelected, setClearSelected] = useState<boolean>(false);
  const [filter, setFilter] = useState<PrestationFilter>(INIT_FILTER);
  const [filterorga, setFilterorga] = useState<EstablishmentFilter>(
    INIT_FILTER_ORGANISATION,
  );
  const [prestationId, setprestationId] = useState<number>(0);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [establishmentorga, setEstablishmentorga] = useState<any[]>([]);
  const [openCreatePrestationDialog, setOpenCreatePrestationDialog] =
    useState<boolean>(false);

  const { data: establishmentNames } = useQuery<EtablishmentNames>(
    GET_ETABLISHMENT_NAMES,
  );

  const normalizeFilter = (filters: EstablishmentFilter): any => {
    return {
      postalCode: filters.postalCode,
      enabled: filters.enabled,
      city: filters.city,
      etablishmentType: filters.type === 'ALL' ? '' : filters.type,
    };
  };

  const { data: establishments } = useQuery<
    EtablishmentWithoutOrganization,
    EtablishmentWithoutOrganizationVariables
  >(GET_ETABLISHMENT_WITHOUT_ORGANIZATION, {
    variables: {
      input: {
        filter: JSON.stringify({
          ...normalizeFilter(filterorga),
        }),
      },
    },
  });

  useEffect(() => {
    if (establishments?.etablishmentWithoutOrganization) {
      setEstablishmentorga(establishments.etablishmentWithoutOrganization);
    }
  });

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const onHandleSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKey(value);
  };
  const [inputData, setInputData] = useState<PrestationInterface>(INIT_DATA);
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };
  const [editId, setEditId] = useState<number>(-1);
  const [inputUpdateData, setInputUpdateData] =
    useState<PrestationDataType>(INIT_DATA);
  const [openUpdateDialog, setopenUpdateDialog] = useState<boolean>(false);

  const valueEtablishmentId =
    establishments?.etablishmentWithoutOrganization.find(
      (item) => item.name === filter.establishment,
    );

  let variables =
    filter.establishment != '' || searchKey != ''
      ? {
          input: {
            establishmentId: valueEtablishmentId?.id,
            libelle: searchKey,
          },
        }
      : {
          input: {
            establishmentId: establishmentData?.etablishmentByUserId?.id,
          },
        };

  if (
    establishmentId != null &&
    valueEtablishmentId?.id == null &&
    establishmentData?.etablishmentByUserId == null
  ) {
    variables = {
      input: {
        establishmentId: establishmentId,
        libelle: searchKey,
      },
    };
  }

  const {
    loading: loadingPrestationList,
    data: prestationList,
    refetch,
  } = useQuery<Prestations, PrestationsVariables>(GET_PRESTATIONS, {
    variables,
  });

  const [openEstablishmentChoice, setOpenEstablishmentChoice] =
    useState<boolean>(false);

  const [importFiles, setImportFiles] = useState<FileList>();

  useEffect(() => {
    if (prestationList && prestationList.prestations) {
      setData(prestationList.prestations);
    }
  }, [prestationList]);

  const onHandleCreatePrestation = (data: PrestationDataType, cb: any) => {
    data.establishmentId = data.establishmentId || establishmentId;
    const validation = validate(data, DEFAULT_PRESTATION_VALIDATORS);
    if (!validation.valid) {
      showSnackbar({
        type: 'ERROR',
        message: 'Invalid field Libelle or etablishment',
        client,
      });
    } else {
      createPrestation({
        variables: {
          input: {
            libelle: data.libelle,
            establishmentId: data.establishmentId,
          },
        },
      }).then(({ data }) => {
        if (data && data.createPrestation) {
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `La prestation a été ajoutée`;
          displaySnackbar(client, snackbarData);
          refetch();
        }
      });
    }
  };

  const [createPrestation, { loading }] = useMutation<
    createPrestation,
    createPrestationVariables
  >(CREATE_PRESTATION, {
    onCompleted: (data: createPrestation) => {
      if (data && data.createPrestation) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `La prestation a été bien ajouter`;
        displaySnackbar(client, snackbarData);
        setOpenCreatePrestationDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de l'ajout de la prestation";

      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [createPrestations, { loading: loadingCreatePrestations }] =
    useMutation<CreatePrestations, CreatePrestationsVariables>(
      CREATE_PRESTATIONS,
      {
        onCompleted: (data: CreatePrestations) => {
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          showSnackbar({
            client,
            message: `Les prestations ont été ajoutées`,
            type: 'SUCCESS',
          });
        },
        onError: (error: ApolloError) => {
          snackbarData.type = 'ERROR';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            "Une erreur s'est produite lors de la création des prestations";
          if (error.networkError) {
            snackbarData.type = 'ERROR';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message =
              'Erreur de réseau. Veuillez vérifier votre connexion internet';
          }
          displaySnackbar(client, snackbarData);
        },
      },
    );

  useEffect(() => {
    if (establishments && establishments.etablishmentWithoutOrganization) {
      const opts = establishments.etablishmentWithoutOrganization.map(
        (item: any) => {
          if (item)
            return {
              label: item.name,
              value: item.name,
            };
          return true;
        },
      );
      setOptions(opts);
    }
  }, [establishments]);

  const INPUT_FILTERS: FilterControlType[] = [
    {
      label: 'Etablissement',
      name: 'establishment',
      value: '',
      placeholder: 'Tous les établissements',
      type: 'select',
      options,
      autoComplete: true,
    },
  ];

  const onExcelReaderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenEstablishmentChoice(true);
    if (event.target.files) {
      setImportFiles({ ...event.target.files });
    }
    event.preventDefault();
  };

  const toolbarButtons = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="worker-add"
      startIcon={<AddBoxIcon />}
      onClick={() => setOpenCreatePrestationDialog(true)}
    >
      Ajouter une prestation
    </Button>,
    <ExcelReader
      onChange={onExcelReaderChange}
      onClick={() => {}}
      loading={false}
    />,
  ];
  const onHandleExport = (id: number) => {
    if (selectedItems.length != 0) {
      snackbarData.type = 'INFO';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = `Veuillez choisir une action groupée pour les exports multiples`;
      displaySnackbar(client, snackbarData);
      setOpenConfirmDialog(false);
    } else {
      getPrestationInfo({ variables: { id } });
    }
  };

  const [getPrestationInfo] = useLazyQuery<PrestationType, PrestationVariables>(
    GET_PRESTATION,
    {
      onCompleted: (data: PrestationType) => {
        if (data && data.prestationById) {
          exportData({
            csvData: [formatPrestationData(data.prestationById)],
            fileName: 'Prestation',
            sheetName: 'Prestation',
          });
        }
      },
      onError: (error: ApolloError) => {
        snackbarData.message =
          "Une erreur s'est produite lors de la récupération des informations du bénéficiaire";
        if (error.networkError) {
          snackbarData.type = 'ERROR';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            'Erreur de réseau. Veuillez vérifier votre connexion internet';
        }
        displaySnackbar(client, snackbarData);
      },
    },
  );

  const [getSelectedPrestationInfo] = useLazyQuery<
    PrestationByIds,
    PrestationByIdsVariables
  >(GET_PRESTATION_IDS, {
    onCompleted: (data: PrestationByIds) => {
      if (data) {
        exportData({
          csvData: data.prestationsByIds.map((item: any) =>
            formatPrestationData(item),
          ),
          fileName: 'Prestations',
          sheetName: 'Prestations',
        });
      }
      refetch();
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la récupération des informations du bénéficiaire";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const exportPrestationInfos = (selectedItems: number[]): void => {
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les prestations à exporter`,
      )
    ) {
      getSelectedPrestationInfo({ variables: { ids: selectedItems } });
    }
  };

  const onHandleUpdate = (data: PrestationDataType, cb: any) => {
    const validation = validate(data, DEFAULT_PRESTATION_VALIDATORS);

    if (!validation.valid) {
      showSnackbar({
        type: 'ERROR',
        message: 'Invalid field Libelle',
        client,
      });
    } else {
      updatePrestation({
        variables: {
          input: {
            id: editId,
            libelle: data.libelle,
            etablishmentId: data.establishmentId,
          },
        },
      }).then(({ data }) => {
        if (data && data.updatePrestation) {
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `La prestation a été mise à jour`;
          displaySnackbar(client, snackbarData);
          refetch();
        }
      });
    }
  };

  const [updatePrestation, { loading: loadingUpdatePrestation }] = useMutation<
    UpdatePrestation,
    UpdatePrestationVariables
  >(UPDATE_PRESTATION, {
    onCompleted: (data: UpdatePrestation) => {
      if (data && data.updatePrestation) {
        setopenUpdateDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour de la prestation";
      displaySnackbar(client, snackbarData);
    },
  });

  const onHandleDelete = (id: number) => {
    if (selectedItems.length != 0) {
      snackbarData.type = 'INFO';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = `Veuillez choisir une action groupée pour les suppressions multiples`;
      displaySnackbar(client, snackbarData);
      setOpenConfirmDialog(false);
    } else {
      setprestationId(id);
      setOpenConfirmDialog(true);
    }
  };

  const onDeletePrestation = () => {
    if (prestationId && prestationId > 0) {
      deletePrestation({
        variables: {
          id: prestationId,
        },
        update: (cache, { data }) => {
          const deletedId =
            data && data.deletePrestation && data.deletePrestation.id;

          const prestationData = cache.readQuery<Prestations>({
            query: GET_PRESTATIONS,
          });

          if (deletedId && prestationData) {
            const newData = prestationData.prestations.filter(
              (prestation) => prestation && prestation.id !== deletedId,
            );
            cache.writeQuery({
              query: GET_PRESTATIONS,
              data: {
                prestations: newData,
              },
            });
          }
        },
      });
    } else {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'ID prestation non trouvé.';
      displaySnackbar(client, snackbarData);
      setOpenConfirmDialog(false);
    }
  };

  const [deletePrestation, { loading: loadingDeletePrestation }] = useMutation<
    DeletePrestation,
    DeletePrestationVariables
  >(DELETE_PRESTATION, {
    onCompleted: (data: DeletePrestation) => {
      if (data && data.deletePrestation) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `La prestation a été supprimée`;
        displaySnackbar(client, snackbarData);
        setOpenConfirmDialog(false);
        refetch();
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la suppression de la prestation";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [deletePrestations, { loading: loadingDeletePrestations }] =
    useMutation<DeletePrestations, DeletePrestationsVariables>(
      DELETE_PRESTATIONS,
      {
        onCompleted: (data: DeletePrestations) => {
          if (data && data.deletePrestations) {
            snackbarData.type = 'SUCCESS';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = `Les prestations ont été supprimées`;
            displaySnackbar(client, snackbarData);
            setOpenConfirmDialog(false);
            refetch();
            setSelectedItems([]);
          }
        },
        onError: (error: ApolloError) => {
          snackbarData.type = 'ERROR';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            "Une erreur s'est produite lors de la suppression des prestations";
          if (error.networkError) {
            snackbarData.type = 'ERROR';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message =
              'Erreur de réseau. Veuillez vérifier votre connexion internet';
          }
          displaySnackbar(client, snackbarData);
        },
      },
    );

  const onHandleEdit = (id: number) => {
    setEditId(id);
    getPrestationDetails({ variables: { id } });
  };

  const onHandleAction = (action: ValueType<GroupAction>): void => {
    const actionGroup = action as GroupAction;

    if (actionGroup && actionGroup.value) {
      switch (actionGroup.value) {
        case 'export':
          exportPrestationInfos(selectedItems);
          break;
        case 'remove':
          removePrestations(selectedItems);
          break;
      }
    }
  };

  const removePrestations = (selectedItems: number[]): void => {
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les prestations à supprimer`,
      )
    ) {
      deletePrestations({
        variables: {
          ids: selectedItems,
        },
        update: (cache, { data }) => {
          const deletedIds = ((data && data.deletePrestations) || []).map(
            (item) => item && item.id,
          );

          const prestationData = cache.readQuery<Prestations>({
            query: GET_PRESTATIONS,
            variables,
          });

          if (deletedIds && prestationData && prestationData.prestations) {
            const newData = prestationData.prestations.filter(
              (prestation) =>
                prestation && deletedIds.indexOf(prestation.id) === -1,
            );
            cache.writeQuery({
              query: GET_PRESTATIONS,
              variables,
              data: {
                prestations: newData,
              },
            });
          }
        },
      });
    }
  };

  const [getPrestationDetails] = useLazyQuery<
    PrestationType,
    PrestationVariables
  >(GET_PRESTATION, {
    onCompleted: (data: PrestationType) => {
      if (data && data.prestationById) {
        const { libelle, etablishment } = data.prestationById;
        setInputUpdateData({
          libelle: libelle || '',
          establishmentId: (etablishment && etablishment.id) || 0,
        });
        setopenUpdateDialog(true);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la récupération des informations de la prestation";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const onChooseEstablishment = async (id: number) => {
    const file = importFiles && importFiles[0];
    const prestations: PrestationInterface[] = await getFromFile(file as File);
    createPrestations({
      variables: {
        input: prestations.map((prestation) => {
          const { libelle } = prestation;
          return {
            libelle,
            establishmentId: id,
          };
        }),
      },
      update: (cache, { data }) => {
        const prestationDatas = cache.readQuery<PrestationVariables>({
          query: GET_PRESTATIONS,
          variables,
        });
        const newPrestations =
          data && data.createPrestations && data.createPrestations.data;
        if (newPrestations && prestationDatas) {
          cache.writeQuery({
            query: GET_PRESTATIONS,
            data: {
              prestations: newPrestations,
            },
          });
        }
      },
    }).then(() => {
      refetch();
    });
  };

  const columns: HeadCell[] = [
    {
      name: 'libelle',
      label: 'Libellé',
      disablePadding: false,
      renderer: (value: any) => {
        return value?.libelle ?? '';
      },
    },
    {
      name: 'etablishment',
      label: 'Établissement',
      disablePadding: false,
      renderer: (value: any) => {
        const etabName = (value.etablishment && value.etablishment.name) || '-';
        return etabName;
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex" justifyContent="flex-end">
          <Tooltip title="Exporter en Excel" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleExport(value.id);
              }}
              className={classes.margin}
            >
              <img
                alt="excel-icon"
                src={ExcelIcon}
                className={classes.excelIcon}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleDelete(value.id);
              }}
              className={classes.margin}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifier" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleEdit(value.id);
              }}
              className={classes.margin}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box className={establishmentId ? '' : classes.root}>
      <Row>
        <Col xs={12} xl={9} lg={8} md={6}>
          <Paper className={classes.paper}>
            <SearchInput
              onChange={onHandleSearchKey}
              value={searchKey}
              placeholder="Recherchez une prestation"
            />
          </Paper>
        </Col>

        {establishmentId === undefined && userRole === 'SUPER_ADMIN' ? (
          <Col xs={12} xl={3} lg={4} md={6}>
            <Paper className={classes.paper}>
              <Typography className={classes.labelFilter}>
                Filtre de recherche :
              </Typography>
              <SearchFilter
                filterControls={INPUT_FILTERS}
                filter={filter}
                setFilter={setFilter}
              />
            </Paper>
          </Col>
        ) : null}
      </Row>
      <Box>
        <CustomTable
          toolbar
          selectable
          data={data}
          columns={columns}
          toolbarActions={ACTIONS}
          toolbarButtons={toolbarButtons}
          clearSelected={clearSelected}
          selectedItems={selectedItems}
          isLeftContent={true}
          setSelectedItems={setSelectedItems}
          loadingData={loadingPrestationList}
          onHandleAction={onHandleAction}
          toolbarProps={{
            toolbarTitle: 'Liste des prestations',
          }}
        />
        <PrestationAddFormDialog
          open={openUpdateDialog}
          title="Modification d'une prestation"
          loadingButton={false}
          onSubmit={onHandleUpdate}
          buttonLabel="Enregistrer"
          handleClose={() => {
            setopenUpdateDialog(false);
          }}
          data={inputUpdateData}
          idEstablishment={establishmentId}
        />
        <ConfirmationDialog
          title="Suppression"
          message="Etes-vous sûr de vouloir supprimer cette prestation ?"
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={onDeletePrestation}
          loadingConfirmation={loadingDeletePrestation}
        />
        <PrestationAddFormDialog
          open={openCreatePrestationDialog}
          title={'Ajouter une prestation'}
          loadingButton={false}
          onSubmit={onHandleCreatePrestation}
          buttonLabel={'Ajouter'}
          handleClose={() => setOpenCreatePrestationDialog(false)}
          data={inputData}
          idEstablishment={establishmentId}
        />
        <EstablishmentChoice
          open={openEstablishmentChoice}
          setOpen={setOpenEstablishmentChoice}
          onChooseEstablishment={onChooseEstablishment}
        />
      </Box>
    </Box>
  );
};

export default withRouter(Prestation);
