import React from 'react';

import Layout from '../Layout';

interface WrapperComponentProps {
  children?: any;
}

/**
 * WrapperComponent
 * @param props WrapperComponentProps
 */
const WrapperComponent = (props: WrapperComponentProps) => {
  const { children } = props;
  return <Layout>{children}</Layout>;
};

/**
 * Wrapper Component
 * @param WrappedComponent any
 */
const Wrapper = (WrappedComponent: any) => () => (
  <WrapperComponent>
    <WrappedComponent />
  </WrapperComponent>
);

export default Wrapper;
