import { Beneficiary, User, UserRole } from '../types/user';
import { DocumentNode, ExecutionResult } from 'graphql';
import {
  GET_ADMINISTRATORS,
  GET_ADMINISTRATOR,
} from '../graphql/administrator/query';
import {
  GET_BENEFICIARIES,
  GET_BENEFICIARY,
} from '../graphql/beneficiary/query';
import { GET_WORKERS, GET_WORKER } from '../graphql/worker/query';
import {
  useQuery,
  MutationHookOptions,
  useMutation,
  QueryHookOptions,
} from '@apollo/react-hooks';
import {
  CREATE_ADMIN,
  UPDATE_ADMIN,
  DELETE_ADMIN,
  DELETE_ADMINS,
  UPDATE_ADMIN_STATUS,
  CREATE_ADMINS,
} from '../graphql/administrator/mutation';
import {
  CREATE_BENEFICIARY,
  UPDATE_BENEFICIARY,
  DELETE_BENEFICIARY,
  DELETE_BENEFICIARIES,
  UPDATE_BENEFICIARY_STATUS,
  CREATE_BENEFICIARIES,
} from '../graphql/beneficiary/mutation';
import {
  CREATE_WORKER,
  UPDATE_WORKER,
  DELETE_WORKER,
  DELETE_WORKERS,
  UPDATE_WORKER_STATUS,
  CREATE_WORKERS,
} from '../graphql/worker/mutation';
import {
  CreateUser,
  CreateUserVariables,
  UpdateUser,
  UpdateUserVariables,
} from '../components/Establishment/EstablishmentAdd/EstablishmentEdit/UserEditList/interfaces/UserEditList.interfaces';
import { useUploadSignature } from './upload-image.hooks';

interface UseUserMutationArgs<TData, TVariables> {
  options?: MutationHookOptions<TData, TVariables>;
  userRole: UserRole;
}

interface UseUserQueryArgs<TData, TVariables> {
  options?: QueryHookOptions<TData, TVariables>;
  userRole: UserRole;
}

// User Query and Mutations schemas

const USE_CREATE_USER_MUTATIONS: Record<UserRole, DocumentNode> = {
  administrator: CREATE_ADMIN,
  beneficiary: CREATE_BENEFICIARY,
  worker: CREATE_WORKER,
};

const USE_READ_USER_QUERIES: Record<UserRole, DocumentNode> = {
  administrator: GET_ADMINISTRATOR,
  beneficiary: GET_BENEFICIARY,
  worker: GET_WORKER,
};

const USE_UPDATE_USER_MUTATIONS: Record<UserRole, DocumentNode> = {
  administrator: UPDATE_ADMIN,
  beneficiary: UPDATE_BENEFICIARY,
  worker: UPDATE_WORKER,
};

const USE_DELETE_USER_MUTATIONS: Record<UserRole, DocumentNode> = {
  administrator: DELETE_ADMIN,
  beneficiary: DELETE_BENEFICIARY,
  worker: DELETE_WORKER,
};

// Users Query and Mutations schemas

const USE_CREATE_USERS_MUTATIONS: Record<UserRole, DocumentNode> = {
  administrator: CREATE_ADMINS,
  beneficiary: CREATE_BENEFICIARIES,
  worker: CREATE_WORKERS,
};
const USE_READ_USERS_QUERIES: Record<UserRole, DocumentNode> = {
  administrator: GET_ADMINISTRATORS,
  beneficiary: GET_BENEFICIARIES,
  worker: GET_WORKERS,
};

const USE_DELETE_USERS_MUTATIONS: Record<UserRole, DocumentNode> = {
  administrator: DELETE_ADMINS,
  beneficiary: DELETE_BENEFICIARIES,
  worker: DELETE_WORKERS,
};

const USE_UPDATE_USERS_STATUS: Record<UserRole, DocumentNode> = {
  administrator: UPDATE_ADMIN_STATUS,
  beneficiary: UPDATE_BENEFICIARY_STATUS,
  worker: UPDATE_WORKER_STATUS,
};

// User CRUD

export const useReadUserQuery = <TData, TVariables>(
  args: UseUserQueryArgs<TData, TVariables>,
) => {
  const { options, userRole } = args;
  return useQuery<TData, TVariables>(USE_READ_USER_QUERIES[userRole], options);
};

export type UseCreateUserMutation = (
  args: UseUserMutationArgs<CreateUser, CreateUserVariables>,
  documents?: string,
) => [
  (user: User | Beneficiary) => Promise<ExecutionResult<CreateUser>>,
  { loading: boolean },
];

export const useCreateUserMutation: UseCreateUserMutation = (
  args,
  documents,
) => {
  const { options, userRole } = args;
  const [create, { loading }] = useMutation<CreateUser, CreateUserVariables>(
    USE_CREATE_USER_MUTATIONS[userRole],
    options,
  );
  const uploadSignature = useUploadSignature();
  return [
    async (user) => {
      if (userRole === 'beneficiary' && !!(user as Beneficiary).signatureFile) {
        const beneficiary: Beneficiary = user as Beneficiary;
        const { signatureFile, ...rest } = beneficiary;
        const url = await uploadSignature(signatureFile);
        return create({
          variables: {
            ...rest,
            signature: url,
            beneficiaryDocuments: documents,
          },
        });
      } else {
        return create({
          variables: {
            ...(user as User),
          },
        });
      }
    },
    { loading },
  ];
};

export type UseUpdateUserMutation = (
  args: UseUserMutationArgs<UpdateUser, UpdateUserVariables>,
) => [
  (user: User | Beneficiary) => Promise<ExecutionResult<UpdateUser>>,
  { loading: boolean },
];

export const useUpdateUserMutation: UseUpdateUserMutation = (args) => {
  const { options, userRole } = args;
  const [update, { loading }] = useMutation<UpdateUser, UpdateUserVariables>(
    USE_UPDATE_USER_MUTATIONS[userRole],
    options,
  );
  const uploadSignature = useUploadSignature();
  return [
    async (user) => {
      if (userRole === 'beneficiary' && !!(user as Beneficiary).signatureFile) {
        const beneficiary: Beneficiary = user as Beneficiary;
        const { signatureFile, ...rest } = beneficiary;
        const url = await uploadSignature(signatureFile);
        return update({
          variables: {
            ...rest,
            signature: url,
          },
        });
      } else {
        return update({
          variables: {
            ...(user as User),
          },
        });
      }
    },
    { loading },
  ];
};

export const useDeleteUserMutation = <TData, TVariables>(
  args: UseUserMutationArgs<TData, TVariables>,
) => {
  const { options, userRole } = args;
  return useMutation<TData, TVariables>(
    USE_DELETE_USER_MUTATIONS[userRole],
    options,
  );
};

// Users CRUD

export const useCreateUsersMutation = <TData, TVariables>(
  args: UseUserMutationArgs<TData, TVariables>,
) => {
  const { options, userRole } = args;
  return useMutation<TData, TVariables>(
    USE_CREATE_USERS_MUTATIONS[userRole],
    options,
  );
};

export const useReadUsersQuery = <TData, TVariables>(
  args: UseUserQueryArgs<TData, TVariables>,
) => {
  const { options, userRole } = args;
  return useQuery<TData, TVariables>(USE_READ_USERS_QUERIES[userRole], options);
};

export const useDeleteUsersMutation = <TData, TVariables>(
  args: UseUserMutationArgs<TData, TVariables>,
) => {
  const { options, userRole } = args;
  return useMutation<TData, TVariables>(
    USE_DELETE_USERS_MUTATIONS[userRole],
    options,
  );
};

export const useUpdateUsersStatusMutation = <TData, TVariables>(
  args: UseUserMutationArgs<TData, TVariables>,
) => {
  const { options, userRole } = args;
  return useMutation<TData, TVariables>(
    USE_UPDATE_USERS_STATUS[userRole],
    options,
  );
};

export const useDeleteAnyUsersMutation = () => {
  const [deleteAdministrators] = useDeleteUsersMutation({
    userRole: 'administrator',
  });
  const [deleteBeneficiarys] = useDeleteUsersMutation({
    userRole: 'beneficiary',
  });
  const [deleteWorkers] = useDeleteUsersMutation({ userRole: 'worker' });
  return [
    (ids: number[], userRole: UserRole) => {
      const options = {
        variables: {
          ids,
        },
      };
      switch (userRole) {
        case 'administrator':
          return deleteAdministrators(options);
        case 'beneficiary':
          return deleteBeneficiarys(options);
        case 'worker':
          return deleteWorkers(options);
      }
    },
  ];
};
