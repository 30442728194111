import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      margin: theme.spacing(4, 0),

      [theme.breakpoints.down(700)]: {
        flexFlow: 'column',
      },
    },
    imageContainer: {
      width: 170,
      height: 170,
      borderRadius: 10,
      overflow: 'hidden',
      marginRight: theme.spacing(4),

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },

      [theme.breakpoints.down(700)]: {
        marginBottom: theme.spacing(2),
        alignSelf: 'center',
      },
    },
    establishmentName: {
      fontWeight: 600,
      fontSize: '1.6em',
      color: '#000',

      [theme.breakpoints.down(485)]: {
        fontSize: 16,
      },
    },
    etabInfos: {
      flexGrow: 10,
      height: 170,
    },
    etabDetails: {
      display: 'flex',
      flexDirection: 'row',
    },
    headerInfo: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    rightDetailsInfo: {
      marginLeft: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      borderLeft: '1px solid #cecece',
    },
    leftDetailsInfo: {
      marginRight: theme.spacing(4),
    },
    margin: {
      marginLeft: 4,
      marginRight: 4,
    },
    excelIcon: {
      width: 28,
      height: 28,
    },
  }),
);

export default useStyles;
