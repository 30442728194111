import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { useLazyQuery } from '@apollo/react-hooks';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import Avatar from '@material-ui/core/Avatar';

import { GET_ETABLISHMENT } from '../../../../graphql/etablishment/query';
import {
  Etablishment,
  EtablishmentVariables,
} from '../../../../graphql/etablishment/types/Etablishment';
import useStyles from './styles';
import ExcelIcon from '../../../../assets/images/excel.png';
import { exportData, formatEstablishmentData } from '../../../../common/excel';

interface EstablishmentDetailsHeaderProps {
  establishmentId: number;
  hideTitle?: boolean;
  establishmentType?: string;
  redirectionUrl?: string;
  userRole?: string;
}

const EstablishmentDetailsHeader: FC<
  EstablishmentDetailsHeaderProps & RouteComponentProps
> = (props) => {
  const {
    history,
    hideTitle,
    establishmentId,
    redirectionUrl,
    establishmentType,
    userRole,
  } = props;
  const classes = useStyles();

  const [getEtabInfo, { loading }] = useLazyQuery<
    Etablishment,
    EtablishmentVariables
  >(GET_ETABLISHMENT, {
    onCompleted: (data: Etablishment) => {
      if (data && data.etablishment) {
        exportData({
          csvData: [formatEstablishmentData(data.etablishment)],
          fileName: 'Etablissement',
          sheetName: 'Etablissement',
        });
      }
    },
  });

  const handleGoBack = () => {
    if (redirectionUrl) {
      history.push(redirectionUrl);
    } else {
      history.push(`/etablissements`);
    }
  };

  const onEdit = () => {
    history.push(`/etablissements/modifier/${establishmentId}`);
  };

  const onHandleExport = () => {
    getEtabInfo({ variables: { id: establishmentId } });
  };

  return (
    <Box
      display="flex"
      justifyContent={!hideTitle ? 'space-between' : 'flex-end'}
      alignItems="center"
      className={classes.root}
    >
      <Box className={classes.headerText}>
        {!hideTitle && (
          <Button
            color="default"
            variant="text"
            startIcon={<ArrowBackIcon />}
            onClick={handleGoBack}
            className={classes.backButton}
          >
            <span className={classes.text}>Retour</span>
          </Button>
        )}
        <Typography className={classes.title} component="h2">
          {establishmentType == 'RESIDENCE_PARENT'
            ? "Détails de l'organisation"
            : "Détails de l'établissement"}
        </Typography>
      </Box>
      <Box className={classes.headerActions}>
        <Button
          variant="contained"
          color="default"
          style={{
            marginRight: 16,
            display: userRole && userRole == 'WORKER' ? 'none' : 'inherit',
          }}
          className={classes.button}
          disabled={userRole && userRole == 'WORKER' ? true : loading || false}
          onClick={onHandleExport}
          startIcon={
            <Avatar
              variant="square"
              alt="excel-icon"
              src={ExcelIcon}
              className={classes.excelIcon}
            />
          }
        >
          Exporter
        </Button>
        <Button
          style={{
            display: userRole && userRole == 'WORKER' ? 'none' : 'inherit',
          }}
          variant="contained"
          color="primary"
          disabled={userRole && userRole == 'WORKER' ? true : false}
          className={classes.button}
          startIcon={<EditIcon />}
          onClick={onEdit}
        >
          Modifier
        </Button>
      </Box>
    </Box>
  );
};

export default withRouter(EstablishmentDetailsHeader);
