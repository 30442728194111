import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      display: 'flex',
      position: 'relative',
      borderRadius: theme.shape.borderRadius,

      marginLeft: 0,
      width: '100%',
      // minWidth: 350,
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
        minWidth: 325,
      },
      [theme.breakpoints.down(500)]: {
        minWidth: '100%',
      },
    },
    searchIcon: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      padding: theme.spacing(0, 2),
      borderRadius: '0 16px 16px 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchIconActive: {
        transition: 'all .3s',
        cursor: 'pointer',
        '&:hover':{
          filter: 'brightness(70%)',
        },
    },
    inputRoot: {
      color: 'inherit',
      backgroundColor: '#F5F5F5',
      borderRadius: '16px 0 0 16px',
      width: '100%',
      '&:hover': {
        backgroundColor: '#F5F5F5',
      },
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: theme.spacing(3),
      width: '100%',
      height: 40,
    },
  }),
);

export default useStyles;
