import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #f5f5f5',
      borderRadius: 16,
      width: 280,
      height: 'auto',
      padding: theme.spacing(2),
      margin: theme.spacing(0, 2, 2, 0),
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    title: {
      fontSize: 16,
      fontWeight: 500,
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
  }),
);

export default useStyles;
