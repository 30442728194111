import React, { FC } from 'react';
import Avatar from '@material-ui/core/Avatar';

import useStyles from './styles';
import UploadButton from '../UploadButton';
import ExcelIcon from '../../../assets/images/excel.png';

interface ExcelReaderProps {
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const allowedFiles = ['xlsx', 'xls']
  .map((item: string) => {
    return `.${item}`;
  })
  .join(',');

const ExcelReader: FC<ExcelReaderProps> = (props) => {
  const { onChange, loading, onClick, disabled } = props;
  const classes = useStyles();

  return (
    <UploadButton
      className={classes.button}
      variant="contained"
      color="default"
      name="admin-import"
      accept={allowedFiles}
      onChange={onChange}
      onClick={onClick}
      disabled={loading || disabled}
      startIcon={
        <Avatar
          variant="square"
          alt="excel-icon"
          src={ExcelIcon}
          className={classes.excelIcon}
        />
      }
    >
      Importer une liste
    </UploadButton>
  );
};

export default ExcelReader;
