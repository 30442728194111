import { SelectOptionsType } from '../../../types';
import {
  EstablishmentMin,
  InterventionReportData,
  UserMin,
} from '../../../types/intervention-report.types';
import {
  InterventionFilter,
  InterventionReportEditData,
} from '../interfaces/intervention-report.interfaces';
import moment from 'moment';

export const defaultUserMin: UserMin = {
  id: 0,
  name: '',
  phone: '',
  mobilePhone: '',
  email: '',
};

export const defaultEstablishmentMin: EstablishmentMin = {
  id: 0,
  address: '',
  name: '',
  rcs: '',
  enabled: false,
  etablishmentType: 'DOMICILE',
};

export const defaultInterventionReportData: InterventionReportData = {
  id: 0,
  name: '',
  status: '',
  dateStart: new Date(),
  dateEnd: new Date(),
  establishment: { ...defaultEstablishmentMin },
  duration: 0,
  prestation: '',
  worker: { ...defaultUserMin },
  beneficiary: { ...defaultUserMin },
  planningId: 0,
  intervention: '',
};

export const INITIAL_FILTERS: InterventionFilter = {
  dateStart: moment().clone().startOf('month').toISOString(),
  dateEnd: moment().clone().endOf('month').toISOString(),
  worker: null,
  beneficiary: null,
  establishment: null,
  status: null,
  skipFilter: 0,
  takeFilter: 10,
  rapportTypes: null,
};

export const defaultIntervetionReportEditData: InterventionReportEditData = {
  id: 0,
  dateStart: new Date(),
  dateEnd: new Date(),
  planningId: 0,
};
