import { FilterControlType } from '../../../types';

export const SEARCH_FILTERS: FilterControlType[] = [
  {
    label: 'Période du',
    name: 'dateStart',
    value: '',
    placeholder: 'Date de début',
    type: 'date',
    marginRight: 16,
  },
  {
    label: 'Période au',
    name: 'dateEnd',
    value: '',
    placeholder: 'Date de fin',
    type: 'date',
    marginRight: 16,
  },
  {
    label: 'Intervenant',
    name: 'worker',
    value: 'ALL',
    placeholder: 'Tous les intervenants',
    type: 'select',
    autoComplete: true,
    options: [],
    marginRight: 16,
  },
  {
    label: 'Bénéficiaire',
    name: 'beneficiary',
    value: 'ALL',
    placeholder: 'Tous les bénéficiaires',
    type: 'select',
    autoComplete: true,
    options: [],
    marginRight: 16,
  },
  {
    label: 'Etablissement',
    name: 'establishment',
    value: '',
    placeholder: 'Tous les établissements',
    type: 'select',
    autoComplete: true,
    options: [],
  },
];

export const splitTable = (
  tab: any,
  n: number,
  i: number,
  size: number,
  end: number,
  start: number,
  sousTab: any,
): any => {
  console.log('iiiiiiiiiiii', i);

  if (tab.length - end < 0) {
    sousTab[i] = tab.slice(start, tab.length);
  } else {
    sousTab[i] = tab.slice(start, end);
    start = end;
    end = (n + 1) * size;
    return splitTable(tab, n + 1, i + 1, size, end, start, sousTab);
  }
  return sousTab;
};
