import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import useStyles from './styles';

interface CardInfoProps {
  title: string;
  height?: number;
}

const CardInfo: FC<CardInfoProps> = (props) => {
  const { title, children, height } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root} style={{ height }} elevation={0}>
      <Typography color="primary" className={classes.title}>
        {title}
      </Typography>
      <Box className={classes.content}>{children}</Box>
    </Paper>
  );
};

export default CardInfo;
