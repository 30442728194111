import gql from 'graphql-tag';

import {
  USER_FRAGMENT,
  ADMIN_FRAGMENT,
  ESTABLISHMENT_FRAGMENT,
} from './fragment';

export const CREATE_ADMIN = gql`
  mutation CreateAdmin(
    $firstName: String
    $lastName: String!
    $email: String!
    $phone: String
    $mobilePhone: String
    $enabled: Boolean
    $userFunction: String
    $establishmentId: Int
    $skipMail: Boolean
  ) {
    createAdmin(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        mobilePhone: $mobilePhone
        userFunction: $userFunction
        enabled: $enabled
        establishmentId: $establishmentId
        skipMail: $skipMail
      }
    ) {
      action
      success
      message
      data {
        ...AdminInfo
        etablishments {
          ...EtablishmentInfo
        }
      }
    }
  }
  ${ADMIN_FRAGMENT}
  ${ESTABLISHMENT_FRAGMENT}
`;

export const CREATE_ADMINS = gql`
  mutation CreateAdmins($input: [CreateAdministratorInput!]!) {
    createAdmins(input: $input) {
      action
      success
      message
      data {
        ...AdminInfo
        etablishments {
          ...EtablishmentInfo
        }
      }
    }
  }
  ${ADMIN_FRAGMENT}
  ${ESTABLISHMENT_FRAGMENT}
`;

export const UPDATE_ADMIN = gql`
  mutation UpdateAdmin(
    $id: Int!
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $userFunction: String
    $mobilePhone: String
    $enabled: Boolean
    $establishmentId: Int
  ) {
    updateAdmin(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        userFunction: $userFunction
        mobilePhone: $mobilePhone
        enabled: $enabled
        establishmentId: $establishmentId
      }
    ) {
      id
      user {
        ...UserInfo
      }
      etablishments {
        ...EtablishmentInfo
      }
    }
  }
  ${USER_FRAGMENT}
  ${ESTABLISHMENT_FRAGMENT}
`;

export const UPDATE_ADMIN_STATUS = gql`
  mutation UpdateAdminStatus($ids: [Int]!, $status: Boolean!) {
    updateAdminStatus(ids: $ids, status: $status) {
      id
      user {
        ...UserInfo
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const DELETE_ADMIN = gql`
  mutation DeleteAdmin($id: Int!) {
    deleteAdmin(id: $id) {
      ...AdminInfo
    }
  }
  ${ADMIN_FRAGMENT}
`;

export const DELETE_ADMINS = gql`
  mutation DeleteAdmins($ids: [Int]!) {
    deleteAdmins(ids: $ids) {
      ...AdminInfo
    }
  }
  ${ADMIN_FRAGMENT}
`;

export const CREATE_PASSWORD = gql`
  mutation CreatePassword($userId: Int!, $token: String!, $password: String!) {
    createPassword(
      input: { userId: $userId, token: $token, password: $password }
    )
  }
`;
