import React, { FC, useState, useEffect } from 'react';
import { useApolloClient, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { HeadCell, TableDataSource } from '../../../../types/table';
import useStyles from '../UserList/styles';
import CustomTable from '../../../Common/CustomTable';
import { EtablishmentDataType } from '../../../../types/establishment';
import EstablishmentRootChoice from './EstablishmentRootChoice';
import {
  GET_ETABLISHMENT_ROOT_DATA,
  GET_ETABLISHMENT_DATA,
} from '../../../../graphql/common/etablishment/query';
import { ETABLISHMENT_ROOT_DATA } from '../../../../constants/etablishement';

interface EstablishmentRootListProps {
  dataSource?: TableDataSource;
  setEtabInputData?: React.Dispatch<React.SetStateAction<EtablishmentDataType>>;
}

const EstablishmentRootList: FC<
  EstablishmentRootListProps & RouteComponentProps
> = (props) => {
  const { setEtabInputData, history } = props;
  const classes = useStyles();
  const location = useLocation();
  const client = useApolloClient();
  const [selectedParent, setSelectedParent] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    handleRemoveResidence();
  }, []);

  useEffect(() => {
    if (setEtabInputData) {
      setEtabInputData((prevState: EtablishmentDataType) => ({
        ...prevState,
        residenceIds: [selectedParent],
      }));
    }
  }, [selectedParent, setEtabInputData]);

  useEffect(() => {
    if (location.hash.replace('#', '') === 'choix') {
      setOpenDialog(true);
    }
  }, [location]);

  const etabData = client.readQuery({
    query: GET_ETABLISHMENT_ROOT_DATA,
  });

  useEffect(() => {
    if (etabData && etabData.etablishmentRootData) {
      const item =
        etabData.etablishmentRootData.__typename &&
        etabData.etablishmentRootData.__typename === 'EtablishmentRootData'
          ? [etabData.etablishmentRootData]
          : [];
      const etabIds = etabData.etablishmentRootData.id
        ? [etabData.etablishmentRootData.id]
        : [];

      const getEtabData = client.readQuery({
        query: GET_ETABLISHMENT_DATA,
      });
      client.writeQuery({
        query: GET_ETABLISHMENT_DATA,
        data: {
          etablishmentData: {
            ...getEtabData.etablishmentData,
            residenceIds: etabIds,
          },
        },
      });

      setData(item);
      setSelectedParent(etabData.etablishmentRootData.id);
    }
  }, [etabData]);

  const columns: HeadCell[] = [
    {
      name: 'logo',
      label: 'Logo',
      disablePadding: false,
      renderer: (value: any) => {
        return <Avatar alt="logo" variant="rounded" src="" />;
      },
    },
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return value.name || '-';
      },
    },
    {
      name: 'address',
      label: 'Adresse',
      disablePadding: false,
      renderer: (value: any) => {
        return value.address || '-';
      },
    },
    {
      name: 'postalCode',
      label: 'Code postal',
      disablePadding: false,
      renderer: (value: any) => {
        return value.postalCode || '-';
      },
    },
    {
      name: 'City',
      label: 'Ville',
      disablePadding: false,
      renderer: (value: any) => {
        return value.city || '-';
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex">
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveResidence();
              }}
              className={classes.margin}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleRemoveResidence = () => {
    client.writeQuery({
      query: GET_ETABLISHMENT_ROOT_DATA,
      data: {
        etablishmentRootData: ETABLISHMENT_ROOT_DATA,
      },
    });
    setData([]);
    setSelectedParent(0);
  };

  const isSetResidence = () => {
    return data.length > 0;
  };

  const handleSelectParent = () => {
    history.push(`${location.pathname}${location.search}#choix`);
  };

  const adminToolbarButtons = [
    <Button
      className={`${classes.white}`}
      variant="contained"
      color="secondary"
      name="admin-add"
      disabled={isSetResidence()}
      startIcon={<AddBoxIcon />}
      onClick={handleSelectParent}
    >
      Sélectionner une organisation
    </Button>,
  ];

  return (
    <>
      <CustomTable
        toolbar
        selectable={false}
        maxWidth={1080}
        data={data}
        columns={columns}
        toolbarButtons={adminToolbarButtons}
        noFooter
        noLoading
        hideSelectedText
      />
      <EstablishmentRootChoice open={openDialog} setOpen={setOpenDialog} />
    </>
  );
};

export default withRouter(EstablishmentRootList);
