import { useQuery } from '@apollo/react-hooks';
import React, { createContext, useContext, useState } from 'react';
import { GET_ETABLISHMENT_BY_USER_ID } from '../../graphql/etablishment/query';
import {
  GetEtablishmentByUserId,
  GetEtablishmentByUserIdVariables,
} from '../../graphql/etablishment/types/GetEtablishmentByUserId';
import UserContext from './UserContext';

interface EstablishmentContextType {
  establishmentId: number;
  establishmentType: string;
  newEtablishmentId: number;
  setNewEtablishmentId: React.Dispatch<React.SetStateAction<number>>;
}

const establishment: EstablishmentContextType = {
  establishmentId: 0,
  establishmentType: '',
  newEtablishmentId: 0,
  setNewEtablishmentId: () => {},
};

export const EstablishmentContext = createContext(establishment);

const EstablishmentProvider = (props: any) => {
  const [establishmentType, setEstablishmentType] = useState<string>('');
  const [establishmentId, setEstablishmentId] = useState<number>(0);
  const [newEtablishmentId, setNewEtablishmentId] = useState<number>(0);
  const { userId } = useContext(UserContext);

  const { data } = useQuery<
    GetEtablishmentByUserId,
    GetEtablishmentByUserIdVariables
  >(GET_ETABLISHMENT_BY_USER_ID, {
    variables: { id: userId },
    onCompleted: (data: GetEtablishmentByUserId) => {
      if (
        data &&
        data.etablishmentByUserId &&
        data.etablishmentByUserId.etablishmentType &&
        data.etablishmentByUserId.etablishmentType.name
      ) {
        setEstablishmentType(data.etablishmentByUserId.etablishmentType.name);
        setEstablishmentId(data.etablishmentByUserId.id);
      }
    },
  });

  return (
    <EstablishmentContext.Provider
      value={{
        establishmentId,
        establishmentType,
        newEtablishmentId,
        setNewEtablishmentId,
      }}
    >
      {props.children}
    </EstablishmentContext.Provider>
  );
};

export default EstablishmentProvider;
