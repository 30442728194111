import {
  useApolloClient,
  useLazyQuery,
  useMutation,
} from '@apollo/react-hooks';
import React, { FC, useContext, useState } from 'react';
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom';
import {
  ESTABLISHMENT_EDITOR_TITLE,
  ETABLISHMENT_DATA,
} from '../../../../constants/etablishement';
import { CreateEstablishment } from '../../../../graphql/etablishment/types/CreateEstablishment';
import { useCreateEstablishment } from '../../../../hooks/establishment.hooks';
import { useDeleteAnyUsersMutation } from '../../../../hooks/User.hooks';
import {
  EstablishmentDataType,
  EstablishmentType,
} from '../../../../types/establishment';
import { EstablishmentContext, UserContext } from '../../../Context';
import { UserContextType } from '../../../Context/UserContext';
import { GET_STATUS_BY_NAME } from '../../../../graphql/status/query';
import { GET_PRESIGNED_URL } from '../../../../graphql/fileUpload/mutations';
import EstablishmentEditor from '../EstablishmentEdit/components/EstablishmentEditor';
import { EstablishmentEditorActions } from '../EstablishmentEdit/components/EstablishmentEditor/EstablishmentEditor';
import { onEstablishmentSaveError } from '../EstablishmentEdit/utils/EstablishmentEdit.utils';
import EstablishmentEditorWrapper from '../EstablishmentEditorWrapper';
import {
  EstablishmentCreateFinish,
  SetEstablishment,
  SetEstablishmentType,
  SetMinorChanges,
} from './reducer/establishment-create.actions';
import { useEstablishmentCreateReducer } from './reducer/establishment-create.reducer';
import { handleEstablishmentCreateValidation } from './utils/establishment-create.utils';
import { SnackbarType } from '../../../../types';
import { displaySnackbar } from '../../../../common';
/**
 *  for the moment, this component only works for establishment of type child
 */
interface EstablishmentCreateProps {
  establishmentType: EstablishmentType;
  /*
    rootEstablishmentId MUST be provided when you are creating
    a child establishment WHEN you are an Administrator. Otherwise
    might get a ton of bugs.
  */
  rootEstablishmentId?: number;
}

interface LocationState {
  previousPath: string;
}

const EstablishmentCreate: FC<
  EstablishmentCreateProps & RouteComponentProps
> = (props) => {
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };
  const { history, establishmentType, rootEstablishmentId } = props;
  const client = useApolloClient();
  const location = useLocation<LocationState>();
  const [etablismentSave, setEtablismentSave] = useState<number>();
  const userContext: UserContextType = useContext(UserContext);
  const { setNewEtablishmentId } = useContext(EstablishmentContext);

  const [state, dispatch] = useEstablishmentCreateReducer({
    establishmentType,
    establishment: {
      ...ETABLISHMENT_DATA,
      ...(rootEstablishmentId ? { residenceIds: [rootEstablishmentId] } : {}),
    },
  });

  const [getUrl] = useMutation(GET_PRESIGNED_URL);

  const [getStatusByName, { data: statusData }] =
    useLazyQuery<any>(GET_STATUS_BY_NAME);

  const [deleteUsers] = useDeleteAnyUsersMutation();

  const redirectPage = () => {
    // history.goBack();
    let prevPath: string = '';

    if (location.state && location.state.previousPath) {
      prevPath = location.state.previousPath;
    }

    history.push({
      pathname: prevPath,
      search: '?tab=1',
    });
  };

  const handleClose = () => {
    if (state.establishment) {
      const { administratorIds, workerIds, beneficiaryIds } =
        state.establishment;
      deleteUsers(administratorIds, 'administrator');
      deleteUsers(workerIds, 'worker');
      deleteUsers(beneficiaryIds, 'beneficiary');
    }
    redirectPage();
  };

  const createOnComplete = (data: CreateEstablishment) => {
    dispatch(new EstablishmentCreateFinish());

    if (data && data.createEtablishment) {
      if (etablismentSave !== 2) {
        onSuccessContinue();
      }
      const id = data.createEtablishment.data?.id;
      setNewEtablishmentId(id!);
      snackbarData.type = 'SUCCESS';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'Etablissement enregistré avec succès';
      displaySnackbar(client, snackbarData);
    }
  };

  const onSuccessContinue = () => {
    redirectPage();
  };

  const createEstablishment = useCreateEstablishment({
    options: {
      onCompleted: createOnComplete,
      onError: onEstablishmentSaveError(client),
    },
    establishmentType,
  });

  if (!state.establishment) {
    return <></>;
  }

  const onMajorChanges = (value: EstablishmentDataType) => {
    dispatch(new SetEstablishment(value));
    dispatch(new SetMinorChanges(null));
    if (value.establishmentType) {
      dispatch(new SetEstablishmentType(value.establishmentType));
    }
  };

  const onMinorChanges = (value: EstablishmentDataType) => {
    dispatch(new SetMinorChanges(value));
    if (value.establishmentType) {
      dispatch(new SetEstablishmentType(value.establishmentType));
    }
  };

  const uploadLogo = (file: File, dataEstablishement: any) => {
    getUrl({ variables: { fileName: file.name, folder: 'logo' } }).then(
      (data) => {
        if (data) {
          const { uploadFile } = data.data;
          const { url, uuid } = uploadFile;

          fetch(url, {
            method: 'PUT',
            body: file,
          })
            .then((res) => {
              const dataUpdated = { ...dataEstablishement, logo: url };
              handleEstablishmentCreateValidation(
                dataUpdated,
                client,
                establishmentType,
                () => createEstablishment(dataUpdated),
              );
            })
            .catch((error) => {
              console.log('error : ', error);
            });
        }
      },
    );
  };

  const onFinalize = (value: EstablishmentDataType) => {
    let establishmentValue: EstablishmentDataType = value;
    if (userContext.userRole === 'ADMIN' && rootEstablishmentId) {
      establishmentValue = {
        ...establishmentValue,
        residenceIds: [rootEstablishmentId],
      };
    }

    if (etablismentSave !== 5) {
      if (establishmentValue.picture[0]) {
        uploadLogo(establishmentValue.picture[0], establishmentValue);
      } else {
        handleEstablishmentCreateValidation(
          establishmentValue,
          client,
          establishmentType,
          () => createEstablishment(establishmentValue),
        );
      }
    } else {
      redirectPage();
    }
  };

  const actions: EstablishmentEditorActions = {
    onMajorChanges,
    onFinalize,
    onMinorChanges,
  };

  return (
    <EstablishmentEditorWrapper
      title="Ajout d'un établissement"
      onClose={handleClose}
    >
      <EstablishmentEditor
        setEtablismentSave={setEtablismentSave}
        value={state.establishment}
        establishmentType={establishmentType}
        actions={actions}
        title={ESTABLISHMENT_EDITOR_TITLE[state.establishmentType]}
        history={history}
      />
    </EstablishmentEditorWrapper>
  );
};

export default withRouter(EstablishmentCreate);
