import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { Redirect, useParams, withRouter } from 'react-router-dom';
import EstablishmentAdmin from '../../EstablishmentAdmin';

interface EstablishmentChildDetailsProps {}
interface RouteParams {
  id: string;
}

// ONLY USE IN ADMIN

const EstablishmentChildDetails: FC<EstablishmentChildDetailsProps> = (
  props,
) => {
  const { id } = useParams<RouteParams>();
  const establishmentId: number = +id;
  if (!establishmentId) {
    return <Redirect to="/votre-etablissement" />;
  }
  return (
    <EstablishmentAdmin
      establishmentId={establishmentId}
      hideTitle={false}
      redirectionUrl="/votre-etablissement"
    />
  );
};

export default withRouter(EstablishmentChildDetails);
