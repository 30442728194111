import { FC } from 'react';
import Box from '@material-ui/core/Box';
import React from 'react';

interface UserListWrapperProps {}
const StepWrapper: FC<UserListWrapperProps> = (props) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      style={{ padding: 16, marginBottom: 100 }}
    >
      {props.children}
    </Box>
  );
};

export default StepWrapper;
