import { ApolloClient, ApolloError } from 'apollo-boost';
import { CreateUnitLife } from '../../../../../../graphql/unit/types/CreateUnitLife';
import { showSnackbar } from '../../../../../../utils/snackbar.util';

export interface OnCreateUnitLifeErrorArgs {
  client: ApolloClient<object>;
}

export interface OnCreateUnitLifeCompleteArgs {
  client: ApolloClient<object>;
}

export const onCreateUnitLifeError = ({
  client,
}: OnCreateUnitLifeErrorArgs) => (error: ApolloError) => {
  let message =
    "Une erreur s'est produite lors de la création de l'unité de vie";
  if (error.message.includes('NAME_ALREADY_EXISTS')) {
    message = "Le nom de l'unité de vie existe déjà";
  }
  if (error.networkError) {
    message = 'Erreur de réseau. Veuillez vérifier votre connexion internet';
  }
  showSnackbar({
    type: 'ERROR',
    message,
    client,
  });
};

export const onCreateUnitLifeComplete = ({
  client,
}: OnCreateUnitLifeCompleteArgs) => (data: CreateUnitLife) => {
  if (data.createUnitLife) {
    showSnackbar({
      client,
      message: `L'unité de vie a été ajoutée`,
      type: 'SUCCESS',
    });
  }
};
