import { MutationHookOptions } from '@apollo/react-hooks';
import { ApolloClient, ApolloError } from 'apollo-boost';
import {
  UpdateEstablishmentStatus,
  UpdateEstablishmentStatusVariables,
} from '../../../../graphql/etablishment/types/UpdateEstablishmentStatus';
import { showSnackbar } from '../../../../utils/snackbar.util';

export interface UpdateEstablishmentsStatusOptionsArgs {
  client: ApolloClient<object>;
}

export const UpdateEstablishmentsStatusOptions = ({
  client,
}: UpdateEstablishmentsStatusOptionsArgs): MutationHookOptions<
  UpdateEstablishmentStatus,
  UpdateEstablishmentStatusVariables
> => ({
  onCompleted: (data: UpdateEstablishmentStatus) => {
    const count = data.updateEtablishmentStatus.length;
    const enabled = data.updateEtablishmentStatus.find((v) => !!v)?.enabled;
    const status = enabled ? 'activé' : 'désactivé';
    let message = ``;
    if (count > 1) {
      message = `Les établissements ont été ${status}s`;
    } else {
      message = `L'établissement a été ${status}`;
    }
    showSnackbar({
      client,
      message,
      type: 'SUCCESS',
    });
  },
  onError: (error: ApolloError) => {
    let message = ``;
    if (error.networkError) {
      message = `Erreur de réseau. Veuillez vérifier votre connexion internet`;
    } else {
      message = error.message;
    }
    showSnackbar({
      client,
      message,
      type: 'ERROR',
    });
  },
});
