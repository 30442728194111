const ACCESS_TOKEN_KEY = 'accessToken';

const isAuthenticated = (): boolean => {
  return !!localStorage.getItem(ACCESS_TOKEN_KEY);
};

const setAccessToken = (token: string): void => {
  return localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

const clearLocalStorage = (): void => {
  return localStorage.clear();
};

export { isAuthenticated, setAccessToken, clearLocalStorage };
