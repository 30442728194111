import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: theme.spacing(0, 4),
    },
    inlineGroup: {
      flexDirection: 'row',
      width: 400,
      justifyContent: 'space-between',

      [theme.breakpoints.down(500)]: {
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    button: {
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    white: {
      color: theme.palette.common.white,
    },
  }),
);

export default useStyles;
