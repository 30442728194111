import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import Button from '@material-ui/core/Button';

interface StepperActionsProps {
  activeStep: number;
  stepsLength: number;
  handlePrevStep: () => void;
  handleNextStep: () => void;
  handleIgnoreStep: () => void;
  handleFinish: () => void;
  isValidNextButton: boolean;
  finalizeButtonDisable: boolean;
}

const StepperActions: FC<StepperActionsProps> = (props) => {
  const {
    activeStep,
    isValidNextButton,
    stepsLength,
    handlePrevStep,
    handleNextStep,
    handleIgnoreStep,
    finalizeButtonDisable,
    handleFinish,
  } = props;
  const classes = useStyles();

  const previousButton = (
    <Button
      variant="contained"
      color="default"
      className={classes.actionButton}
      onClick={handlePrevStep}
    >
      Retour
    </Button>
  );
  const nextButton = (
    <Button
      variant="contained"
      color="primary"
      disabled={!isValidNextButton}
      className={classes.actionButton}
      onClick={handleNextStep}
    >
      Suivant
    </Button>
  );
  const ignoreButton = (
    <Button
      variant="text"
      color="primary"
      className={`${classes.actionButton} ${classes.lowercase}`}
      onClick={handleIgnoreStep}
    >
      Ignorer
    </Button>
  );
  const finalizeButton = (
    <Button
      variant="contained"
      color="primary"
      disabled={finalizeButtonDisable}
      className={classes.actionButton}
      onClick={handleFinish}
    >
      Finaliser
    </Button>
  );
  const lengthMinusOne = stepsLength - 1;
  return (
    <>
      {activeStep !== 0 && previousButton}
      {activeStep !== lengthMinusOne && nextButton}
      {activeStep === lengthMinusOne && finalizeButton}
    </>
  );
};

export default StepperActions;
