import React, { FC, useState } from 'react';
import { MyPlannings_myPlannings } from '../../../../graphql/planning/types/MyPlannings';
import CustomTable from '../../CustomTable';
import { GroupAction, HeadCell } from '../../../../types/table';
import { Box, Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleIcon from '@material-ui/icons/People';
import WorkIcon from '@material-ui/icons/Work';
import moment from 'moment-timezone';
import useStyles from './style';
import BeneficiaryPlanningEdit from '../AppointmentDetails/BeneficiaryPlanningEdit/BeneficiaryPlanningEdit';
import WorkerPlanningEdit from '../AppointmentDetails/WorkerPlanningEdit/WorkerPlanningEdit';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import {
  DeletePlanningInIntervention,
  DeletePlanningInInterventionVariables,
} from '../../../../graphql/planning/types/DeletePlanningInIntervention';
import {
  DELETE_MULTIPLE_PLANNING,
  DELETE_PLANNING_IN_INTERVENTION,
  UPDATE_PLANNING_MULTIPLE_STATUS,
} from '../../../../graphql/planning/mutation';
import { displaySnackbar } from '../../../../common';
import { SelectOptionsType, SnackbarType } from '../../../../types';
import { InterventionPlanningEnum } from '../../../../graphql/types/graphql-global-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  UpdateMultiplePlanningStatusVariables,
  UpdateMultiplePlanningStatus_updateMultipleStatus,
} from '../../../../graphql/planning/types/UpdateMultiplePlanningStatus';
import { ValueType } from 'react-select';
import {
  DeleteMultiplePlanning,
  DeleteMultiplePlanningVariables,
} from '../../../../graphql/planning/types/DeleteMultiplePlanning';

interface Props {
  loading: boolean;
  plannings: MyPlannings_myPlannings[];
  onFinish: () => void;
}

const tableLabels: Record<string, string> = {
  IN_PROGRESS: 'En cours',
  WAITING_VALIDATION: 'En cours de validation',
  COMPLETED: 'Terminé',
  CANCELED: 'Annulé',
  TODO: 'A faire',
};

const statusList: SelectOptionsType[] = [
  { value: '', label: '' },
  { value: InterventionPlanningEnum.IN_PROGRESS, label: 'En cours' },
  {
    value: InterventionPlanningEnum.WAITING_VALIDATION,
    label: 'En cours de validation',
  },
  { value: InterventionPlanningEnum.COMPLETED, label: 'Terminé' },
  { value: InterventionPlanningEnum.CANCELED, label: 'Annulé' },
  { value: InterventionPlanningEnum.TODO, label: 'A faire' },
];

const ACTIONS: GroupAction[] = [{ label: 'Supprimer', value: 'remove' }];
const AppointmentList: FC<Props> = (props) => {
  const classes = useStyles();
  const { loading, plannings, onFinish } = props;
  const client = useApolloClient();
  const [workersOpened, setWorkersOpened] = useState<boolean>(false);
  const [benefOpened, setBenefOpened] = useState<boolean>(false);
  const [id, setId] = useState<number | undefined>();
  const [selected, setSelected] = useState<number[]>([]);
  const [oneSelectionMode, setOneSelectionMode] = useState<boolean>(true);
  const [selectionStatus, setSelectionStatus] = useState<SelectOptionsType>();
  const [statusAutocomplete, setStatusAutocomplete] = useState<string>('');
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };
  const [updateStatus, { loading: loadUpdateStatus }] = useMutation<
    UpdateMultiplePlanningStatus_updateMultipleStatus,
    UpdateMultiplePlanningStatusVariables
  >(UPDATE_PLANNING_MULTIPLE_STATUS, {
    onCompleted: (data: UpdateMultiplePlanningStatus_updateMultipleStatus) => {
      snackbarData.type = 'SUCCESS';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'Les activités ont été mise à jour';
      setStatusAutocomplete('');
      displaySnackbar(client, snackbarData);
      setSelected([]);
      onFinish();
    },
    onError: (error) => {
      snackbarData.message = 'Une erreur est survenue';
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      displaySnackbar(client, snackbarData);
    },
  });
  const [
    deletePlanningInIntervention,
    { loading: loadingDeletePlanningInIntervention },
  ] = useMutation<
    DeletePlanningInIntervention,
    DeletePlanningInInterventionVariables
  >(DELETE_PLANNING_IN_INTERVENTION, {
    onCompleted: (data: DeletePlanningInIntervention) => {
      snackbarData.type = 'SUCCESS';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = "L'activité a été effaçée";
      displaySnackbar(client, snackbarData);
      onFinish();
    },
    onError: (error) => {
      snackbarData.message = 'Une erreur est survenue';
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      displaySnackbar(client, snackbarData);
    },
  });
  const [deleteMultiplePlanning, { loading: loadingDeleteMultiplePlanning }] =
    useMutation<DeleteMultiplePlanning, DeleteMultiplePlanningVariables>(
      DELETE_MULTIPLE_PLANNING,
      {
        onCompleted: (data: DeleteMultiplePlanning) => {
          if (data.deleteMultiplePlanning.success) {
            snackbarData.type = 'SUCCESS';
            snackbarData.autoHideDuration = 5000;
          } else {
            snackbarData.type = 'ERROR';
            snackbarData.autoHideDuration = 5000;
          }
          snackbarData.message = data.deleteMultiplePlanning?.message ?? '';
          displaySnackbar(client, snackbarData);
          onFinish();
        },
        onError: (error) => {
          snackbarData.message = 'Une erreur est survenue';
          snackbarData.type = 'ERROR';
          displaySnackbar(client, snackbarData);
        },
      },
    );
  const setOneIdFocus = (idFocused: number) => {
    setOneSelectionMode(true);
    setId(idFocused);
  };
  const setSelectedFocus = (ids: number[]) => {
    setSelected(ids);
  };
  const optionSelectedComparison = (
    option: SelectOptionsType,
    value: SelectOptionsType | null,
  ) => false;

  const handleStatusChange = (
    event: React.ChangeEvent<{}>,
    value: SelectOptionsType | null,
  ) => {
    if (value?.value) {
      const variables: UpdateMultiplePlanningStatusVariables = {
        ids: selected,
        status: value?.value as InterventionPlanningEnum,
      };
      updateStatus({ variables });
    }
  };
  const toolbarButtons = [
    <Button
      className={`${classes.white}`}
      variant="contained"
      color="primary"
      onClick={(event) => {
        setOneSelectionMode(false);
        setBenefOpened(true);
      }}
      disabled={selected.length === 0}
      name="worker-add"
      startIcon={<PeopleIcon />}
    >
      Mettre à jour les bénéficiaires
    </Button>,
    <Button
      className={`${classes.white}`}
      variant="contained"
      color="primary"
      disabled={selected.length === 0}
      name="worker-add"
      onClick={(event) => {
        setOneSelectionMode(false);
        setWorkersOpened(true);
      }}
      startIcon={<WorkIcon />}
    >
      Mettre à jour les intervenants
    </Button>,
    <Autocomplete
      disabled={selected.length === 0}
      options={statusList}
      value={selectionStatus}
      onChange={handleStatusChange}
      inputValue={statusAutocomplete}
      onInputChange={(e, value) => setStatusAutocomplete(value)}
      getOptionLabel={(options: SelectOptionsType) => options.label}
      getOptionSelected={optionSelectedComparison}
      noOptionsText={'Aucune option'}
      style={{ minWidth: 250 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Changement groupé de statut"
          name="status"
          placeholder="Statut"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
      )}
    />,
  ];
  const columns: HeadCell[] = [
    {
      name: 'debut',
      label: 'Début',
      disablePadding: false,
      renderer: (value: any) => {
        return (
          (value?.dateStart &&
            moment.utc(value?.dateStart).format('DD/MM/YYYY HH:mm:ss')) ??
          '-'
        );
      },
    },
    {
      name: 'fin',
      label: 'Fin',
      disablePadding: false,
      renderer: (value: any) => {
        return (
          (value?.dateEnd &&
            moment.utc(value?.dateEnd).format('DD/MM/YYYY HH:mm:ss')) ??
          '-'
        );
      },
    },
    {
      name: 'titre',
      label: 'Titre',
      disablePadding: false,
      renderer: (value: any) => {
        return value?.title || value?.intervention?.description || '-';
      },
    },
    {
      name: 'status',
      label: 'Statut',
      disablePadding: false,
      renderer: (value: any) => {
        const key: string = value?.status?.name || '';
        return tableLabels[key] || '-';
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex" justifyContent="center">
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e: any) => {
                e.stopPropagation();
                deletePlanningInIntervention({ variables: { id: value.id } });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Bénéficiaires" placement="top" arrow>
            <IconButton
              onClick={(e: any) => {
                e.stopPropagation();
                setOneIdFocus(value.id as number);
                setBenefOpened(true);
              }}
            >
              <PeopleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Intervenants" placement="top" arrow>
            <IconButton
              onClick={(e: any) => {
                e.stopPropagation();
                setOneIdFocus(value.id as number);
                setWorkersOpened(true);
              }}
            >
              <WorkIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
  const onHandleAction = (action: ValueType<GroupAction>): void => {
    const actionGroup = action as GroupAction;

    if (actionGroup && actionGroup.value) {
      switch (actionGroup.value) {
        case 'remove':
          if (selected.length === 0) {
            snackbarData.type = 'ERROR';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message =
              'Veuillez au moins choisir un planning à supprimer';
            displaySnackbar(client, snackbarData);
            return;
          }
          deleteMultiplePlanning({ variables: { ids: selected } });
          break;
      }
    }
  };

  const calculateSelectedIds = (): number[] => {
    return (
      (oneSelectionMode && id && [id as number]) ||
      (!oneSelectionMode && selected.length !== 0 && selected) ||
      []
    );
  };
  const calculatedIds: number[] = calculateSelectedIds();
  return (
    <>
      <Box className={classes.appointmentListMargin}>
        <CustomTable
          selectable
          toolbar
          hideSelectedText={true}
          toolbarButtons={toolbarButtons}
          clearSelected={selected.length === 0}
          selectedItems={selected}
          toolbarActions={ACTIONS}
          onHandleAction={onHandleAction}
          setSelectedItems={setSelectedFocus}
          loadingData={
            loading ||
            loadingDeletePlanningInIntervention ||
            loadUpdateStatus ||
            loadingDeleteMultiplePlanning
          }
          data={plannings}
          columns={columns}
        />
        {benefOpened && (
          <BeneficiaryPlanningEdit
            open={benefOpened}
            setOpen={(value: boolean) => {
              setBenefOpened(value);
              if (!value) {
                onFinish();
              }
            }}
            id={calculatedIds}
          />
        )}
        {workersOpened && (
          <WorkerPlanningEdit
            open={workersOpened}
            setOpen={(value: boolean) => {
              setWorkersOpened(value);
              if (!value) {
                onFinish();
              }
            }}
            id={calculatedIds}
          />
        )}
      </Box>
    </>
  );
};

export default AppointmentList;
