import React, { FC, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';

import useStyles from './styles';
import Scheduler from '../../../Common/Scheduler';

import { displaySnackbar } from '../../../../common';
import { SnackbarType } from '../../../../types';
import PlanningToolbar from './PlanningToolbar';
import { useGetMyPlannings } from '../../../Common/Scheduler/hooks/appointment.hooks';
import { PlanningFilter } from '../../../../types/filters';
import { MyPlanningAdvancedFilters } from '../../../../graphql/types/graphql-global-types';
import { INITIAL_FILTER } from './constants/planning.constants';
import {
  selectFilterOptions,
  toQueryFilter,
} from './utils/planning-filter.utils';
import FilterControls from './FilterControls';
import { PlanningFilterRecord } from './interfaces/planning-filter.interfaces';
import AppointmentList from '../../../Common/Scheduler/AppointmentList';
import { useApolloClient } from '@apollo/react-hooks';
import moment from 'moment';

interface PlanningProps {
  establishmentId?: number;
}

const Planning: FC<PlanningProps> = (props) => {
  const { establishmentId } = props;
  const classes = useStyles();
  const client = useApolloClient();
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };
  const [filter, setFilter] = useState<PlanningFilter>({
    ...INITIAL_FILTER,
    d1: moment().clone().startOf('month').toDate(),
    d2: moment().clone().endOf('month').toDate(),
  });
  const planningFilter: MyPlanningAdvancedFilters = {
    etablishmentId: establishmentId,
    ...toQueryFilter(filter),
  };
  const { data, loading, refetch, originalData, error } =
    useGetMyPlannings(planningFilter);

  if (error) {
    if (error.graphQLErrors) {
      snackbarData.message =
        "Le résultat de la recherche est trop volumineux, veuillez réduire l'intervalle de dates";
    } else {
      snackbarData.message = "Une erreur s'est produite";
    }
    snackbarData.type = 'ERROR';
    snackbarData.autoHideDuration = 5000;
    displaySnackbar(client, snackbarData);
  }
  const filterOptions: PlanningFilterRecord = selectFilterOptions(data || []);

  const [calendarView, setCalendarView] = useState<boolean>(true);
  const filtercontrols = (
    <FilterControls
      filter={filter}
      setFilter={setFilter}
      filterOptions={filterOptions}
      onLoading={loading}
    />
  );

  useEffect(() => {
    refetch();
  }, [data.length]);

  return (
    <Box className={classes.affichagePlanning}>
      <PlanningToolbar
        calendarMode={calendarView}
        onChangeCalendarMode={(value) => {
          setCalendarView(value);
        }}
        refetchAppointment={refetch}
        filterControls={filtercontrols}
      />
      {originalData && !calendarView && (
        <>
          <AppointmentList
            loading={loading}
            onFinish={() => refetch()}
            plannings={originalData || []}
          />
        </>
      )}
      {calendarView && (
        <Scheduler
          appointments={data}
          refetchAppointment={refetch}
          loadingData={loading}
          filter={filter}
        />
      )}
    </Box>
  );
};

export default Planning;
