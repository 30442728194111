import React, { FC, useState, useCallback, useEffect, createRef } from 'react';
import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { GetApp } from '@material-ui/icons';
import DropzoneEl, { useDropzone } from 'react-dropzone';
import imagesDoc from '../../../../../web/src/assets/images/electro_house.png';
import Iframe from 'react-iframe';

import useStyles from './styles';

interface DropzoneProps {
  multiple?: boolean;
  width?: number;
  isDownloadableDocument?: boolean;
  onDelete?: () => void;
  height?: number;
  minSize?: number;
  maxSize?: number;
  setInputData?: any;
  base64Img?: string;
  accept: AcceptFile;
  enablePreview?: boolean;
  showAction?: boolean;
  label?: string;
  documentName?: string;
  selectedFiles?: any[];
  setSelecetedDocument?: (name: string, file: string) => void;
  onDeleteDocument?: (name: string) => void;
  inputData?: any;
  pictureUrl?: string;
  setSelectedFiles?: (args: any) => void;
}

// 1 MB = 1048576 Bytes
const MIN_SIZE = 0;
const MAX_SIZE = 26214400; // 25 MB

type AcceptFile =
  | 'image/*'
  | '.doc, .pdf, .txt, .docx, .xlsx'
  | 'image/*, .doc, .pdf, .txt, .docx, .xlsx';

const imageMime = ['png', 'jpg', 'jpeg', 'svg'];

const Dropzone: FC<DropzoneProps> = (props) => {
  const classes = useStyles();
  const { width, height, minSize, maxSize, multiple, pictureUrl } = props;
  const { setInputData, inputData, isDownloadableDocument } = props;
  const { enablePreview, accept, showAction, onDelete, documentName } = props;
  const {
    selectedFiles,
    setSelectedFiles,
    label,
    base64Img,
    setSelecetedDocument,
    onDeleteDocument,
  } = props;
  const [fileImgType, setFileImgType] = useState<boolean>(false);
  const [document, setDocument] = useState<any>();
  const [actualBase64Img, setActualBase64Img] = useState(base64Img);
  const [actualPictureUrl, setActualPictureUrl] = useState<string | undefined>(
    pictureUrl,
  );
  const [selectedInputFiles, setSelectedInputFiles] = useState<any[]>(
    selectedFiles || [],
  );

  const [picture, setPicture] = useState<boolean>(false);

  useEffect(() => {
    if (setSelectedFiles) {
      setSelectedFiles(selectedInputFiles);
    }
    if (documentName && setSelecetedDocument && selectedInputFiles.length > 0) {
      const docFile: File = selectedInputFiles[0];
      getBase64(docFile);
    }
  }, [selectedInputFiles]);

  useEffect(() => {
    if (documentName && document && setSelecetedDocument) {
      setSelecetedDocument(documentName, document);
    }
  }, [document]);

  const onDrop = useCallback((acceptedFiles: any[]) => {
    const files = acceptedFiles.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );
    setSelectedInputFiles(multiple ? [...selectedInputFiles, ...files] : files);
  }, []);

  const {
    isDragActive,
    isDragReject,
    getRootProps,
    open,
    getInputProps,
  } = useDropzone({
    accept,
    onDrop,
    minSize: minSize || MIN_SIZE,
    maxSize: maxSize || MAX_SIZE,
    multiple,
  });

  useEffect(() => {
    if (isDownloadableDocument && selectedInputFiles[0]) {
      const file: File = selectedInputFiles[0];
      getBase64(file);
      setFileImgType(file.type.split('/')[0] == 'image' ? true : false);
    }
    return () => {
      setPicture(true);
      selectedInputFiles.forEach((file: any) =>
        URL.revokeObjectURL(file.preview),
      );
    };
  }, [selectedInputFiles]);

  const getBase64 = (file: File): string | null => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (reader && reader.result) {
        setDocument(reader.result);
        return reader.result;
      }
    };
    reader.onerror = function (error) {
      return null;
    };
    return null;
  };

  const onHandleDelete = () => {
    setPicture(false);
    setActualPictureUrl('');
    setActualBase64Img('');
    if (setInputData) {
      setInputData({ ...inputData, base64logo: '', picture: '', logo: '' });
    }
    if ((isDownloadableDocument && onDelete) || onDelete) {
      onDelete();
    }
    if (documentName && onDeleteDocument) {
      onDeleteDocument(documentName);
    }
    setSelectedInputFiles([]);
  };

  const onHandleSelectPicture = () => {
    open();
  };

  useEffect(() => {
    if (actualPictureUrl && actualPictureUrl.split('.').length > 0) {
      const type: any = actualPictureUrl.split('.').pop();
      setFileImgType(imageMime.includes(type));
    }
  }, [actualPictureUrl]);

  return (
    <Box className={classes.root}>
      {
        <>
          {(base64Img && base64Img.length > 0) ||
          (actualPictureUrl && actualPictureUrl.length > 0) ||
          (selectedInputFiles && selectedInputFiles.length > 0) ? (
            <Box
              className={`${classes.uploadArea}  ${classes.borderSolid}`}
              style={{
                width,
                height,
                overflow: 'hidden',
                overflowX: 'hidden',
                overflowY: 'hidden',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {isDownloadableDocument && !fileImgType ? (
                <Box marginTop="80px" marginLeft="20px">
                  <Box
                    style={
                      documentName
                        ? {
                            position: 'relative',
                            left: '50px',
                            top: '167px',
                            opacity: 0.0,
                            width: '250px',
                            height: '-11px',
                            zIndex: 44,
                            paddingTop: '220px',
                          }
                        : {
                            position: 'relative',
                            left: '7px',
                            top: '167px',
                            opacity: 0.0,
                            width: '250px',
                            height: '-11px',
                            zIndex: 44,
                            paddingTop: '220px',
                          }
                    }
                  ></Box>
                  <Iframe
                    url={`http://docs.google.com/gview?url=${
                      document || actualPictureUrl
                    }&embedded=true`}
                    src={
                      document
                        ? document
                        : `http://docs.google.com/gview?url=${
                            document || actualPictureUrl
                          }&embedded=true`
                    }
                    width="280px"
                    height="355px"
                    className={classes.iframe}
                    position="relative"
                  />
                </Box>
              ) : (
                <img
                  alt="document"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  src={
                    (selectedInputFiles &&
                      selectedInputFiles.length > 0 &&
                      selectedInputFiles[0].preview) ||
                    base64Img ||
                    actualPictureUrl ||
                    ''
                  }
                />
              )}
            </Box>
          ) : (
            <></>
          )}

          <div
            {...getRootProps({
              className:
                base64Img != '' ||
                (actualPictureUrl && actualPictureUrl.length > 0) ||
                (selectedInputFiles && selectedInputFiles.length > 0)
                  ? `${classes.hidden}`
                  : classes.uploadArea,
            })}
            style={
              (actualPictureUrl && actualPictureUrl.length > 0) ||
              (selectedInputFiles && selectedInputFiles.length > 0)
                ? { width, height: 0 }
                : { width, height }
            }
          >
            <input {...getInputProps()} />
            {isDragActive && !isDragReject && (
              <Typography component="span" className={classes.uploadText}>
                Déposez ici votre image
              </Typography>
            )}
            {isDragReject && (
              <Typography component="span" className={classes.uploadText}>
                Type de fichier non accepté
              </Typography>
            )}
            {!isDragActive && !isDragReject && (
              <Typography component="span" className={classes.uploadText}>
                {label ? label : `Glissez et déposez ici votre image`} <br /> ou{' '}
                <br />
                <span className={classes.link}>Parcourir</span>
              </Typography>
            )}
          </div>

          {((picture && selectedInputFiles.length > 0) ||
            base64Img != '' ||
            (actualPictureUrl && actualPictureUrl.length > 0)) &&
          !isDownloadableDocument ? (
            <Box display="flex" justifyContent="space-between">
              <IconButton onClick={onHandleDelete}>
                <DeleteIcon />
              </IconButton>
              <IconButton onClick={onHandleSelectPicture} color="primary">
                <EditIcon />
              </IconButton>
            </Box>
          ) : (
            <></>
          )}

          {((picture && selectedInputFiles.length > 0) ||
            base64Img != '' ||
            (actualPictureUrl && actualPictureUrl.length > 0)) &&
          isDownloadableDocument ? (
            <Box display="flex" justifyContent="space-between">
              <Box>
                <a href={document || actualPictureUrl} target="_blank" download>
                  <IconButton>
                    <GetApp />
                  </IconButton>
                </a>
              </Box>
              <Box>
                <IconButton onClick={onHandleDelete}>
                  <DeleteIcon />
                </IconButton>
                <IconButton onClick={onHandleSelectPicture} color="primary">
                  <EditIcon />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </>
      }
    </Box>
  );
};

export default Dropzone;
