import { FilterControlType } from '../../../types';

export const SEARCH_FILTERS: FilterControlType[] = [
  {
    label: 'Type',
    name: 'type',
    value: 'ALL',
    placeholder: 'Tous les types',
    type: 'select',
    options: [
      {
        label: 'Tous les types',
        value: 'ALL',
        all: true,
      },
      {
        label: 'Domicile',
        value: 'DOMICILE',
      },
      {
        label: 'Résidence',
        value: 'RESIDENCE',
      },
    ],
  },
  {
    label: 'Statut',
    name: 'enabled',
    value: -1,
    placeholder: 'Tous les statuts',
    type: 'select',
    options: [
      {
        label: 'Tous les statuts',
        value: -1,
        all: true,
      },
      {
        label: 'Activé',
        value: 1,
      },
      {
        label: 'Désactivé',
        value: 0,
      },
    ],
  },
  {
    label: 'Code postal',
    name: 'postalCode',
    value: '',
    placeholder: 'Tapez le code postal',
    type: 'text',
  },
  {
    label: 'Ville',
    name: 'city',
    value: '',
    placeholder: 'Tapez une ville',
    type: 'text',
  },
];
