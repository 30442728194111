import gql from 'graphql-tag';

export const GET_PRESTATIONS = gql`
  query Prestations($input: FilterPrestation) {
    prestations(input: $input) {
      id
      libelle
      etablishmentId
      etablishment {
        id
        name
        address
        city
      }
    }
  }
`;

export const GET_PRESTATION = gql`
  query Prestation($id: Int!) {
    prestationById(id: $id) {
      id
      libelle
      etablishmentId
      etablishment {
        id
        name
        address
      }
    }
  }
`;

export const GET_PRESTATION_IDS = gql`
  query PrestationByIds($ids: [Int!]!) {
    prestationsByIds(ids: $ids){
      id
      libelle
      etablishment{
        id
        name
        address
      }
    }
  }
`;