import gql from 'graphql-tag';

export const USER_FRAGMENT = gql`
  fragment UserInfo on User {
    id
    firstName
    lastName
    email
    phone
    mobilePhone
    enabled
  }
`;

export const BENEFICIARY_FRAGMENT = gql`
  fragment BeneficiaryInfo on Beneficiary {
    id
    birthDate
    guardianEnabled
    guardianName
    signature
    disabilityRecognition
    user {
      ...UserInfo
    }
  }
  ${USER_FRAGMENT}
`;

export const ESTABLISHMENT_FRAGMENT = gql`
  fragment EtablishmentInfo on Etablishment {
    id
    name
  }
`;

export const UNIT_LIFE_FRAGMENT = gql`
  fragment UnitLifeInfo on UnitLife {
    id
    name
  }
`;
