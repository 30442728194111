import React, { FC } from 'react';

import EstablishmentHome from '../EstablishmentHome';
import EstablishmentResidence from '../EstablishmentResidence';
import EstablishmentRoot from '../EstablishmentRoot';
import EstablishmentChild from '../EstablishmentChild';
import EstablishmentChoice from '../EstablishmentChoice';
import { EstablishmentType } from '../../../../types/establishment';
import SuccessPage from '../SuccessPage';

interface EstablishmentAddComponentDisplayProps {
  type?: EstablishmentType | string;
}

const ESTABLISHMENT_TYPE_COMPONENTS = {
  HOME: <EstablishmentHome />,
  RESIDENCE: <EstablishmentResidence />,
  RESIDENCE_ROOT: <EstablishmentRoot />,
  RESIDENCE_CHILD: <EstablishmentChild />,
};

const ESTABLISHMENT_ADD_SUB_COMPONENTS: Record<string, any> = {
  '': <EstablishmentChoice />,
  SUCCESS: (
    <SuccessPage message="Votre établissement a bien été créé avec succès." />
  ),
};

const EstablishmentAddComponentDisplay: FC<EstablishmentAddComponentDisplayProps> = (
  props,
) => {
  const { type } = props;

  const routes: Record<string, any> = {
    ...ESTABLISHMENT_TYPE_COMPONENTS,
    ...ESTABLISHMENT_ADD_SUB_COMPONENTS,
  };

  return <>{routes[type || '']}</>;
};

export default EstablishmentAddComponentDisplay;
