import React, { FC, useState, useContext, useEffect } from 'react';
import {
  withRouter,
  RouteComponentProps,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import useStyles from './styles';
import CustomFullscreenDialog from '../../../../../../Common/CustomFullscreenDialog';
import CustomTable from '../../../../../../Common/CustomTable';
import { HeadCell } from '../../../../../../../types/table';
import { FilterControlType } from '../../../../../../../types';
import SearchInput from '../../../../../../Common/SearchInput';
import SearchFilter from '../../../../../../Common/SearchFilter';
import UserContext from '../../../../../../Context/UserContext';
import { ApolloError } from 'apollo-boost';
import {
  GET_ETABLISHMENT_BY_USER_ID,
  GET_ETABLISHMENT_BENEFICIARIES,
} from '../../../../../../../graphql/etablishment/query';
import { GET_ETABLISHMENT_DETAILS } from '../../../../../../../graphql/etablishment/query';
import {
  EtablishmentDetails,
  EtablishmentDetailsVariables,
} from '../../../../../../../graphql/etablishment/types/EtablishmentDetails';
import { GET_BENEFICIARY_IN_ETABLISHMENT } from '../../../../../../../graphql/beneficiary/query';
import {
  EtablishmentBeneficiaries,
  EtablishmentBeneficiariesVariables,
  EtablishmentBeneficiaries_etablishment_beneficiaries,
} from '../../../../../../../graphql/etablishment/types/EtablishmentBeneficiaries';
import {
  BeneficiaryInEtablishment,
  BeneficiaryInEtablishmentVariables,
  BeneficiaryInEtablishment_beneficiaryInEtablishment,
} from '../../../../../../../graphql/beneficiary/types/BeneficiaryInEtablishment';
import { GET_PLANNING_DATA } from '../../../../../../../graphql/common/planning/query';
import { SnackbarType } from '../../../../../../../types/snackbar';
import { displaySnackbar } from '../../../../../../../common/snackbar';
import { EstablishmentContext } from '../../../../../../Context';
import moment from 'moment';
import { PlanningDataType } from '../../../../../../../types/planning';
import { PLANNING_DATA } from '../../../../../../../constants/planning';
import { EstablishmentTypeType } from '../../../../../../../types/establishment';
interface BeneficiaryListProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  inputData: PlanningDataType;
  setInputData: React.Dispatch<React.SetStateAction<any>>;
}

interface ListFilter {
  enabled: number;
}

const INIT_FILTER: ListFilter = {
  enabled: -1,
};

const BeneficiaryList: FC<BeneficiaryListProps & RouteComponentProps> = (
  props,
) => {
  const location = useLocation();
  const classes = useStyles();
  const client = useApolloClient();
  const userContext = useContext(UserContext);
  const { history, open, setOpen, inputData, setInputData } = props;
  const [filter, setFilter] = useState<ListFilter>(INIT_FILTER);
  const [searchKey, setSearchKey] = useState<string>('');
  const [selectedItem, setSelectedItems] = useState<number[]>([]);
  const [beneficiaries, setBeneficiaries] = useState<
    (BeneficiaryInEtablishment_beneficiaryInEtablishment | null)[]
  >([]);
  const [establishmentType, setEstablishmentType] =
    useState<EstablishmentTypeType | string>('');
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const { establishmentType: establishmentTypeFromContext } =
    useContext(EstablishmentContext);

  const INPUT_FILTERS: FilterControlType[] = [
    {
      label: 'Statut',
      name: 'enabled',
      value: -1,
      placeholder: 'Tous les statuts',
      type: 'select',
      options: [
        {
          label: 'Tous les statuts',
          value: -1,
          all: true,
        },
        {
          label: 'Activé',
          value: 1,
        },
        {
          label: 'Désactivé',
          value: 0,
        },
      ],
    },
  ];

  let filters = {
    name: searchKey.trim() || '',
  };

  const inputFilters = {
    input: {
      filter: JSON.stringify({
        ...filters,
        ...filter,
      }),
    },
  };

  const planningData = client.readQuery({
    query: GET_PLANNING_DATA,
  });

  const { id } = useParams();

  const { data: establishmentData } = useQuery<
    EtablishmentDetails,
    EtablishmentDetailsVariables
  >(GET_ETABLISHMENT_DETAILS, {
    variables: { id: +id },
  });

  useEffect(() => {
    if (establishmentData && establishmentData.etablishment) {
      const establishment = establishmentData.etablishment;
      const estabType =
        establishment &&
        establishment.etablishmentType &&
        establishment.etablishmentType.name;

      if (estabType) {
        setEstablishmentType(estabType as EstablishmentTypeType);
      }
    }
  }, [establishmentData]);

  const { loading, data } = useQuery<
    BeneficiaryInEtablishment,
    BeneficiaryInEtablishmentVariables
  >(GET_BENEFICIARY_IN_ETABLISHMENT, {
    variables: {
      etablishmentId:
        +id ||
        (planningData &&
          planningData.planningData &&
          planningData.planningData.establishmentId) ||
        0,
      input: inputFilters.input,
    },
  });

  useEffect(() => {
    if (data && data.beneficiaryInEtablishment) {
      setBeneficiaries(data.beneficiaryInEtablishment);
    }
  }, [data]);

  const handleGoBack = () => {
    history.push(`${location.pathname}${location.search}`);
    setOpen(false);
  };

  const handleSubmitSelection = () => {
    if (selectedItem.length < 1) {
      snackbarData.type = 'INFO';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'Veuillez sélectionner un bénéficiaire';
      displaySnackbar(client, snackbarData);
    } else {
      const estabType = establishmentTypeFromContext || establishmentType;
      if (estabType === 'DOMICILE') {
        if (selectedItem && selectedItem.length > 1) {
          snackbarData.type = 'INFO';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = 'Veuillez sélectionner un seul bénéficiaire';
          displaySnackbar(client, snackbarData);
        } else {
          let domicileBeneficiary = selectedItem;
          const planning = client.readQuery({
            query: GET_PLANNING_DATA,
          });
          client.writeQuery({
            query: GET_PLANNING_DATA,
            data: {
              planningData: {
                ...planning.planningData,
                beneficiaries: domicileBeneficiary,
              },
            },
          });
          setInputData((prevState: any) => ({
            ...prevState,
            beneficiaries: domicileBeneficiary,
          }));
          setSelectedItems([]);
          history.push(`${location.pathname}${location.search}`);
          setOpen(false);
        }
      } else {
        let mergedBenficiary: number[] = [];
        if (selectedItem.length != 0) {
          const planningData = client.readQuery({
            query: GET_PLANNING_DATA,
          });
          let planningBeneficiaries = planningData.planningData.beneficiaries;
          if (planningBeneficiaries.length !== 0) {
            mergedBenficiary = planningBeneficiaries.concat(selectedItem);
          } else {
            mergedBenficiary = selectedItem;
          }
          client.writeQuery({
            query: GET_PLANNING_DATA,
            data: {
              planningData: {
                ...planningData.planningData,
                beneficiaries: mergedBenficiary,
              },
            },
          });
          setInputData((prevState: any) => ({
            ...prevState,
            beneficiaries: mergedBenficiary,
          }));
          setSelectedItems([]);
          history.push(`${location.pathname}${location.search}`);
          setOpen(false);
        } else {
          snackbarData.type = 'INFO';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            'Veuillez sélectionner au moins un bénéficiaire';
          displaySnackbar(client, snackbarData);
        }
      }
    }
  };

  const onHandleSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKey(value);
  };

  const columns: HeadCell[] = [
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value.user && value.user.lastName) || '-';
      },
    },
    {
      name: 'lastName',
      label: 'Prénom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.firstName) || '-';
      },
    },
    {
      name: 'birthDate',
      label: 'Date de naissance',
      disablePadding: false,
      renderer: (value: any) => {
        return (
          (value &&
            value.birthDate &&
            moment(value.birthDate).format('DD-MM-YYYY')) ||
          '-'
        );
      },
    },
    {
      name: 'disabilityRecognition',
      label: "Reconnaissance d'handicap",
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.disabilityRecognition ? 'Oui' : 'Non') || '-';
      },
    },
    {
      name: 'mail',
      label: 'Mail',
      disablePadding: false,
      renderer: (value: any) => {
        return (value.user && value.user.email) || '-';
      },
    },
    {
      name: 'mobilePhone',
      label: 'Tél mobile',
      disablePadding: false,
      renderer: (value: any) => {
        return (value.user && value.user.mobilePhone) || '-';
      },
    },
    {
      name: 'phone',
      label: 'Tél fixe',
      disablePadding: false,
      renderer: (value: any) => {
        return (value.user && value.user.phone) || '-';
      },
    },
    {
      name: 'guardianEnabled',
      label: 'Sous tutelle',
      disablePadding: false,
      renderer: (value: any) => {
        return value.guardianEnabled !== undefined
          ? value.guardianEnabled
            ? 'Oui'
            : 'Non'
          : '-';
      },
    },
    {
      name: 'guardianName',
      label: 'Nom tuteur',
      disablePadding: false,
      renderer: (value: any) => {
        return value.guardianName || '';
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => {
        return value.user && value.user.enabled ? 'Activé' : 'Désactivé';
      },
    },
  ];

  const toolbarActionContent = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="submit-selection"
      onClick={handleSubmitSelection}
    >
      Valider ma sélection
    </Button>,
  ];

  return (
    <CustomFullscreenDialog
      open={open}
      handleGoBack={handleGoBack}
      title="Sélection de bénéficiaire(s)"
    >
      <Box display="flex" justifyContent="center">
        <Row className={classes.row}>
          <Col xs={12} xl={8} lg={8} md={6}>
            <Paper className={classes.paper}>
              <SearchInput
                onChange={onHandleSearchKey}
                value={searchKey}
                placeholder="Recherchez un bénéficiaire"
              />
            </Paper>
          </Col>
          <Col xs={12} xl={4} lg={4} md={6}>
            <Paper className={classes.paper}>
              <Typography className={classes.labelFilter}>
                Filtre de recherche :
              </Typography>
              <SearchFilter
                filterControls={INPUT_FILTERS}
                filter={filter}
                setFilter={setFilter}
              />
            </Paper>
          </Col>
        </Row>
      </Box>
      <CustomTable
        toolbar
        selectable
        hideSelectedText
        selectedItems={selectedItem}
        setSelectedItems={setSelectedItems}
        maxWidth={1640}
        data={beneficiaries}
        columns={columns}
        loadingData={loading}
        toolbarProps={{ toolbarTitle: 'Liste des bénéficiaires' }}
        toolbarActionContent={toolbarActionContent}
        toolbarStyles={{ width: '165px' }}
        useFlexToolbar
        isLeftContent={true}
        toolbarClasses={classes.flowRowBenefList}
      />
    </CustomFullscreenDialog>
  );
};

export default withRouter(BeneficiaryList);
