import React, { FC, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Button from '@material-ui/core/Button';
import location, { useLocation } from 'react-router-dom';

import ExcelIcon from '../../../assets/images/excel.png';
import CustomTable from '../../Common/CustomTable';
import useStyles from './styles';
import { HeadCell, GroupAction } from '../../../types/table';
import SearchInput from '../../Common/SearchInput';
import SearchFilter from '../../Common/SearchFilter';
import { SEARCH_FILTERS } from './filters';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { GET_ESTABLISHMENT_CHILDS } from '../../../graphql/etablishment/query';
import {
  EstablishmentChilds,
  EstablishmentChildsVariables,
} from '../../../graphql/etablishment/types/EstablishmentChilds';
import {
  useDeleteEstablishments,
  useExportEstablishments,
  useUpdateEstablishmentsStatus,
} from '../../../hooks/establishment.hooks';
import EstablishmentAdd from '../../Establishment/EstablishmentAdd';
import { exportEstablishmentsOptions } from './constants/export-establishments-options.utils';
import { ValueType } from 'react-select';
import { UpdateEstablishmentsStatusOptions } from './constants/update-establishments-status-options.utils';
import { DeleteEstablishmentsOptions } from './constants/delete-establishments-options.utils';
import { ESTABLISHMENT_STATUS } from '../../../types/establishment';

interface EstablishmentChildListProps {
  establishmentRootId: number;
}

const ACTIONS: GroupAction[] = [
  { label: 'Exporter en Excel', value: 'export' },
  { label: 'Activer', value: 'enable' },
  { label: 'Désactiver', value: 'disable' },
  { label: 'Supprimer', value: 'remove' },
];

interface FilterState {
  enabled: number;
  city: string;
  postalCode: string;
}

const INIT_FILTER = {
  enabled: -1,
  city: '',
  postalCode: '',
};

const EstablishmentChildList: FC<
  EstablishmentChildListProps & RouteComponentProps
> = (props) => {
  const { establishmentRootId, history } = props;
  const client = useApolloClient();
  const location = useLocation();
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [data, setData] = useState<any[]>([]);
  const [filter, setFilter] = useState(INIT_FILTER);

  let filters = {
    name: searchKey.trim() || '',
  };

  const filterEstablishmentChild = {
    filter: JSON.stringify({
      ...filters,
      ...filter,
    }),
  };

  const {
    data: establishmentsDatas,
    loading: establishmentsDatasLoading,
    refetch,
  } = useQuery<EstablishmentChilds, EstablishmentChildsVariables>(
    GET_ESTABLISHMENT_CHILDS,
    {
      variables: {
        establishmentRootId,
        filterEstablishmentChild: { filter: filterEstablishmentChild.filter },
      },
    },
  );

  const exportEstablishments = useExportEstablishments(
    exportEstablishmentsOptions({ client }),
  );
  const deleteEstablishments = useDeleteEstablishments(
    DeleteEstablishmentsOptions({ client, refetch }),
  );
  const updateEstablishmentsStatus = useUpdateEstablishmentsStatus(
    UpdateEstablishmentsStatusOptions({ client }),
  );

  useEffect(() => {
    const establishmentChilds =
      establishmentsDatas && establishmentsDatas.establishmentChilds;
    if (
      !establishmentsDatasLoading &&
      establishmentChilds &&
      establishmentChilds
    ) {
      setData(establishmentChilds);
    }
  }, [establishmentsDatas]);

  const onHandleChangeStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const { checked } = event.target;
    if (id) {
      updateEstablishmentsStatus([id], checked);
    }
  };

  const onHandleSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const onHandleExport = (id: number) => {
    exportEstablishments([id]);
  };

  const onHandleAction = (action: ValueType<GroupAction>): void => {
    const actionGroup = action as GroupAction;

    if (actionGroup && actionGroup.value) {
      switch (actionGroup.value) {
        case 'export':
          exportEstablishments(selectedItems);
          break;
        case 'enable':
          updateEstablishmentsStatus(selectedItems, true);
          break;
        case 'disable':
          updateEstablishmentsStatus(selectedItems, false);
          break;
        case 'remove':
          deleteEstablishments(selectedItems);
          break;
      }
    }
  };

  const onHandleDelete = (id: number) => {
    deleteEstablishments([id]);
  };

  const onHandleEdit = (id: number) => {
    history.push(`/etablissements/modifier/${id}`);
  };

  const onAdd = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    history.push({
      pathname: `/creation-etablissement-fille/${establishmentRootId}`,
      state: {
        previousPath: location.pathname,
      },
    });
  };

  const onHandleClickRow = (id: number) => {
    history.push({
      pathname: `/detail-etablissement-fille/${establishmentRootId}/${id}`,
    });
    window.location.reload();
  };

  const onChooseEstablishment = () => {};

  const columns: HeadCell[] = [
    {
      name: 'logo',
      label: 'Logo',
      disablePadding: false,
      renderer: (value: any) => {
        return (
          <Avatar
            alt="logo"
            variant="rounded"
            className="contain-avatar"
            src={value && value.logo}
          />
        );
      },
    },
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.name) || '-';
      },
    },
    {
      name: 'etablishmentType',
      label: 'Type',
      disablePadding: false,
      renderer: (value: any) => {
        const type =
          value && value.etablishmentType
            ? value.etablishmentType.name === 'RESIDENCE_PARENT'
              ? 'Organisation'
              : value.etablishmentType.name === 'RESIDENCE_CHILD'
              ? 'Résidence fille'
              : value.etablishmentType.name === 'RESIDENCE'
              ? 'Résidence'
              : value.etablishmentType.name === 'DOMICILE'
              ? 'Domicile'
              : '-'
            : '-';
        return type;
      },
    },
    {
      name: 'rcs',
      label: 'Siren/Siret',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.rcs) || '-';
      },
    },
    {
      name: 'etablishmentStatus',
      label: "Statut d'organisation",
      disablePadding: false,
      renderer: (value: any) => {
        const status =
          value.etablishmentStatus &&
          ESTABLISHMENT_STATUS[value.etablishmentStatus.name];
        return status;
      },
    },
    {
      name: 'address',
      label: 'Adresse',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.address) || '-';
      },
    },
    {
      name: 'postalCode',
      label: 'Code postal',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.postalCode) || '-';
      },
    },
    {
      name: 'city',
      label: 'Ville',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.city) || '-';
      },
    },
    {
      name: 'administrators',
      label: 'NB Admin',
      numeric: true,
      disablePadding: true,
      renderer: (value: any) => {
        return (
          (value && value.administrators && value.administrators.length) || 0
        );
      },
    },
    {
      name: 'beneficiaries',
      label: 'NB Bénéficiaires',
      numeric: true,
      disablePadding: true,
      renderer: (value: any) => {
        return (
          (value && value.beneficiaries && value.beneficiaries.length) || 0
        );
      },
    },
    {
      name: 'workers',
      label: 'NB Intervenants',
      numeric: true,
      disablePadding: true,
      renderer: (value: any) => {
        return (value && value.workers && value.workers.length) || 0;
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => (
        <FormControlLabel
          control={
            <Switch
              checked={value.enabled}
              onChange={(event) => onHandleChangeStatus(event, value.id)}
              name="enabled"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          }
          label={value.enabled ? 'Activé' : 'Désactivé'}
        />
      ),
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex">
          <Tooltip title="Exporter en Excel" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleExport(value.id);
              }}
              className={classes.margin}
            >
              <img
                alt="excel-icon"
                src={ExcelIcon}
                className={classes.excelIcon}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleDelete(value.id);
              }}
              className={classes.margin}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifier" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleEdit(value.id);
              }}
              className={classes.margin}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const adminToolbarButtons = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="admin-add"
      startIcon={<AddBoxIcon />}
      onClick={(e) => {
        onAdd(e);
      }}
    >
      Ajouter un établissement
    </Button>,
  ];

  return (
    <Box className={classes.root}>
      <Box className={classes.searchFilter}>
        <Box className={classes.search}>
          <SearchInput
            onChange={onHandleSearchKey}
            value={searchKey}
            placeholder="Recherchez un établissement"
          />
        </Box>
        <Box className={classes.filters}>
          <SearchFilter
            filterControls={SEARCH_FILTERS}
            filter={filter}
            setFilter={setFilter}
            overrideCols={{ xl: 4, lg: 4, md: 4, sm: 12 }}
          />
        </Box>
      </Box>
      <CustomTable
        toolbar
        selectable
        data={data}
        columns={columns}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        toolbarActions={ACTIONS}
        loadingData={establishmentsDatasLoading}
        onHandleAction={onHandleAction}
        toolbarButtons={adminToolbarButtons}
        toolbarProps={{
          toolbarTitle: 'Liste des établissements',
        }}
        isLeftContent={true}
        handleClickRow={onHandleClickRow}
      />
    </Box>
  );
};

export default withRouter(EstablishmentChildList);
