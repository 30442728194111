import {
  BeneficiaryValidatorError,
  UserValidationError,
  UserValidationMessages,
} from '../types/user-validator.types';
import { User, Beneficiary } from '../types/user';

export const EMPTY_STRING_ERRORS: Partial<
  Record<keyof User | keyof Beneficiary, UserValidationError>
> = {
  firstName: UserValidationError.EMPTY_FIRST_NAME,
  lastName: UserValidationError.EMPTY_LAST_NAME,
  email: UserValidationError.EMPTY_EMAIL,
  phone: UserValidationError.EMPTY_PHONE,
  mobilePhone: UserValidationError.EMPTY_MOBILE_PHONE,
  guardianName: BeneficiaryValidatorError.EMPTY_GUARDIAN_NAME,
};

export const INVALID_PHONE_ERROR: Partial<
  Record<keyof User | keyof Beneficiary, UserValidationError>
> = {
  phone: UserValidationError.INVALID_PHONE,
  mobilePhone: UserValidationError.INVALID_MOBILE_PHONE,
};

export const USER_DEFAULT_VALIDATION_MESSAGES: UserValidationMessages = {
  EMPTY_EMAIL: `Adresse email manquant`,
  EMPTY_FIRST_NAME: `Prénom manquant`,
  EMPTY_LAST_NAME: `Nom manquant`,
  FUNCTION_MUTS_STRING: `La fonction doit contenir des lettres`,
  EMPTY_MOBILE_PHONE: `Numéro de téléphone mobile manquant`,
  EMPTY_GUARDIAN_NAME: `Veuillez remplir le champ nom et prénom tuteur.`,
  EMPTY_PHONE: `Numéro de téléphone manquant`,
  INVALID_EMAIL: `L'adresse email est incorrecte`,
  INVALID_FIRST_NAME: ``,
  INVALID_LAST_NAME: ``,
  INVALID_MOBILE_PHONE: `Le numéro de téléphone mobile est incorrect`,
  INVALID_PHONE: `Le numéro de téléphone est incorrect`,
  NO_ESTABLISHMENT: `Veuillez choisir un établissement`,
  INVALID_DATE: `Veuillez choisir une date valide`,
  BIRTHDATE_NOT_VALID: `La date de naissance ne doit pas être récente`,
};
