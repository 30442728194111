import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    button: {
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    white: {
      color: theme.palette.common.white,
    },
    paper: {
      boxShadow: '0 6px 16px rgba(0,0,0,0.09)',
      padding: 15,
      borderRadius: 16,
      marginBottom: 30,
      width: '100%',
      maxWidth: 1640,
    },
    labelFilter: {
      fontSize: '0.875rem',
      color: '#9E9E9E',
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      top: -12,
      left: 30,
      padding: '0 8px',
    },
    flowRow: {
      flexFlow: 'row',
      marginBottom: theme.spacing(0),
      paddingLeft: 0,
      paddingRight: 6,
      justifyContent: 'space-between',

      '& .MuiBox-root': {
        marginBottom: 0,
      },

      [theme.breakpoints.down(620)]: {
        '& .MuiBox-root': {
          marginBottom: theme.spacing(2),
        },

        flexFlow: 'wrap',
        marginBottom: theme.spacing(2),
      },
    },
    flowRowUnitLife: {
      flexFlow: 'row',
      marginBottom: theme.spacing(0),
      paddingLeft: 0,
      paddingRight: 6,
      justifyContent: 'space-between',

      '& .MuiBox-root': {
        marginBottom: 0,
      },
    },
  }),
);

export default useStyles;
