import React, { FC, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';

import useStyles from './styles';
import DocumentThumbnail from './DocumentThumbnail';

import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { GET_ME } from '../../../../graphql/user/query';
import { GET_ETABLISHMENT_BY_USER_ID } from '../../../../graphql/etablishment/query';
import { GetEtablishmentByUserId } from '../../../../graphql/etablishment/types/GetEtablishmentByUserId';
import { GetMe } from '../../../../graphql/user/types/GetMe';

interface DownloadableDocumentProps {}

const DOCS = [
  'Status',
  "Autorisation d'ouverture/Arrêté de création",
  "Arrêté d'extension",
  "Projet d'établissement",
  'Contrat de séjour',
  'Règlement intérieur',
];

const DownloadableDocument: FC<DownloadableDocumentProps> = (props) => {
  const classes = useStyles();

  const [documents, setDocuments] = useState<any>();

  const { data: me } = useQuery<GetMe>(GET_ME);

  const [
    getEtablishment,
    { data: etablishmentData },
  ] = useLazyQuery<GetEtablishmentByUserId>(GET_ETABLISHMENT_BY_USER_ID);

  useEffect(() => {
    if (me && me.me && me.me.id) {
      getEtablishment({ variables: { id: me.me.id } });
    }
  }, [me]);

  useEffect(() => {
    if (
      etablishmentData &&
      etablishmentData.etablishmentByUserId &&
      etablishmentData.etablishmentByUserId.documents
    ) {
      setDocuments(etablishmentData.etablishmentByUserId.documents);
    }
  }, [etablishmentData]);

  return (
    <Box className={classes.root}>
      {documents &&
        documents.map((item: any, index: number) => (
          <DocumentThumbnail
            key={index}
            id={item.id}
            title={item.name}
            path={item.path}
          />
        ))}
    </Box>
  );
};

export default DownloadableDocument;
