import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexFlow: 'row wrap',
      paddingRight: 0,
      paddingLeft: 0,
      '& .row': {
        width: '100%',
        marginRight: 0,
        marginLeft: 0,
      },
      marginBottom: theme.spacing(1),

      [theme.breakpoints.down(1600)]: {},
    },
    rootDetail: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexFlow: 'row wrap',
      paddingRight: 0,
      paddingLeft: 0,
      marginBottom: theme.spacing(1),
    },
    actionTableContent: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      // width: '325px',
      width: '100%',

      [theme.breakpoints.down(1600)]: {
        width: '100%',
      },
    },
    actionTableContentDetail: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      // minWidth: 325,
      width: '100%',

      [theme.breakpoints.down(1765)]: {
        width: '100%',
      },
    },
    fields: {
      [theme.breakpoints.down(1600)]: {
        width: '100%',

        '& .MuiTextField-root': {
          marginBottom: 16,
        },
        '& .MuiBox-root': {
          '& span': {
            width: 'calc(20% - 13px)',
          },
        },
      },
      [theme.breakpoints.down(1389)]: {
        '& .MuiBox-root': {
          '& span': {
            width: 'calc(33.3% - 16px)',
            marginBottom: theme.spacing(2),
          },
        },
      },
      [theme.breakpoints.down(895)]: {
        '& .MuiBox-root': {
          width: '100%',
          '& span': {
            width: 'calc(50% - 16px)',
            marginBottom: theme.spacing(2),
          },
        },
      },
      [theme.breakpoints.down(600)]: {
        width: '100%',

        '& .MuiBox-root': {
          '& span': {
            width: '100%',
            marginBottom: theme.spacing(2),
            marginRight: '0 !important',
          },
        },
      },
    },
    fieldsDetail: {
      [theme.breakpoints.down(1765)]: {
        width: '100%',

        '& .MuiBox-root': {
          width: '100%',

          '& span': {
            width: 'calc(25% - 13px)',
          },
        },
      },
      [theme.breakpoints.down(1440)]: {
        width: '100%',

        '& .MuiBox-root': {
          width: '100%',

          '& span': {
            width: 'calc(50% - 16px)',
            marginBottom: theme.spacing(2),
          },
        },
      },
      [theme.breakpoints.down(600)]: {
        width: '100%',

        '& .MuiBox-root': {
          '& span': {
            width: '100%',
            marginBottom: 0,
            marginRight: '0 !important',
          },
        },
      },
    },
    title: {
      fontSize: '1.5rem',
      marginRight: 24,
      color: theme.palette.primary.main,
      [theme.breakpoints.down(900)]: {
        fontSize: '1.12rem',
        marginRight: 12,
      },
    },
    subTitle: {
      fontSize: '0.875rem',
      color: theme.palette.text.primary,
      marginRight: 20,
      [theme.breakpoints.down(400)]: {
        marginRight: 12,
        fontSize: '0.75rem',
      },
    },
    button: {
      '& .MuiButton-label': {
        color: theme.palette.common.white,
        textTransform: 'initial',
      },
    },
    colSpacing: {
      padding: 0,
      [theme.breakpoints.down(991)]: {
        marginBottom: 16,
      },
    },
    actionTableToolbar: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',

      [theme.breakpoints.down(1600)]: {
        width: '100%',
        marginBottom: theme.spacing(2),
      },
    },
    actionTableToolbarDetail: {
      display: 'flex',
      justifyContent: 'flex-start',

      [theme.breakpoints.down(1765)]: {
        width: '100%',
        marginBottom: theme.spacing(2),
      },
    },
    '& grouped-action': {
      singleValue: (base) => ({ ...base, color: '#424242' }),
      valueContainer: (base) => ({
        ...base,
        color: '#424242',
        width: '100%',
        fontSize: '14px',
        backgroundColor: '#fff',
      }),
    },
    marginRight: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down(991)]: {
        marginRight: 0,
        marginBottom: theme.spacing(2),
        width: '100%',
      },
    },
  }),
);

export default useStyles;
