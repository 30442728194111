import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    listShowInput: {
      maxHeight: 390,
      overflow: 'auto',
    },
    listHideInput: {
      maxHeight: 480,
      overflow: 'auto',
    },
    itemText: {},
    dynamicInput: {
      margin: theme.spacing(2, 0),
    },
    dashedButton: {
      margin: theme.spacing(2, 0),
      height: 54,
      border: '1px dashed #9E9E9E',

      '& .MuiButton-label': {
        textAlign: 'left',
        textTransform: 'initial',
        color: '#00000061',
        fontWeight: 'normal',
      },
    },
  }),
);

export default useStyles;
