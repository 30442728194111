import { createContext } from 'react';

interface AppContextType {
  baseURL: string;
}

const app: AppContextType = {
  baseURL: '',
};

const AppContext = createContext(app);

export default AppContext;
