import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 auto',
      padding: '80px 24px 4px',
      maxWidth: 1920,
      minHeight: 'calc(100vh - 80px)',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down(1199)]: {
        marginBottom: theme.spacing(3),
        flexDirection: 'row',
        justifyContent: 'center',
      },
      [theme.breakpoints.down(768)]: {
        flexDirection: 'column',
      },
    },
    infos: {
      marginBottom: theme.spacing(3),

      [theme.breakpoints.down(1199)]: {
        width: '50%',
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.down(768)]: {
        width: '100%',
      },
    },
    prestationList: {
      [theme.breakpoints.down(1199)]: {
        width: '50%',
      },
      [theme.breakpoints.down(768)]: {
        width: '100%',
      },
    },

    leftContainer: {
      minWidth: 290,
      [theme.breakpoints.down(1200)]: {
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down(678)]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    detailInfoContainer: {
      marginBottom: '24px',
      [theme.breakpoints.down(1200)]: {
        width: '50%',
        padding: 24,
      },
      [theme.breakpoints.down(678)]: {
        width: '90%',
        marginBottom: 0,
      },
      [theme.breakpoints.down(448)]: {
        width: '100%',
      },
      [theme.breakpoints.down(378)]: {
        padding: '16px 0',
      },
    },
    presentationListContainer: {
      [theme.breakpoints.down(1200)]: {
        width: '50%',
        padding: 24,
      },
      [theme.breakpoints.down(678)]: {
        width: '90%',
        marginBottom: '24px',
      },
      [theme.breakpoints.down(448)]: {
        width: '100%',
      },
      [theme.breakpoints.down(378)]: {
        padding: '16px 0',
      },
    },
  }),
);

export default useStyles;
