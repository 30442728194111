import React, { FC, useEffect, useState } from 'react';
import { Redirect, withRouter, Route } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';

import Loader from '../Common/Loader';
import PrivateRoutes from '../Common/PrivateRouteOutlet';
import { GET_ME } from '../../graphql/user/query';
import { GetMe } from '../../graphql/user/types/GetMe';
import { getEstablishmentLink, getPrivateRoutes } from './routes';
import {
  isAuthenticated,
  clearLocalStorage,
} from '../../services/localStorage';
import { UserContext, AppContext } from '../Context';
import { UserContextRole } from '../Context/UserContext';
import EstablishmentProvider from '../Context/EstablishmentContext';

interface RouteManagerProps {}

const RouteManager: FC<RouteManagerProps> = (props) => {
  const [userRole, setUserRole] = useState<UserContextRole>('');
  const [userId, setUserId] = useState<number>(0);
  const { data, loading } = useQuery<GetMe>(GET_ME);
  useEffect(() => {
    if (data && data.me && data.me.role) {
      setUserRole(data.me.role.name as UserContextRole);
      setUserId(data.me.id);
    } else if (!loading) {
      clearLocalStorage();
      document.location.reload();
    }
  }, [data, loading]);

  if (!isAuthenticated()) {
    return <Redirect to="/authentification" />;
  }

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          width="100%"
        >
          <Loader />
        </Box>
      ) : (
        userRole && (
          <AppContext.Provider
            value={{ baseURL: getEstablishmentLink[userRole] }}
          >
            <UserContext.Provider value={{ userRole, userId }}>
              <EstablishmentProvider>
                <Route
                  exact={true}
                  path="/"
                  render={() => (
                    <Redirect to={getEstablishmentLink[userRole]} />
                  )}
                />
                <PrivateRoutes routes={getPrivateRoutes[userRole] || []} />
              </EstablishmentProvider>
            </UserContext.Provider>
          </AppContext.Provider>
        )
      )}
    </>
  );
};

export default withRouter(RouteManager);
