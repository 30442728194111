import {
  basicInformationsStep,
  administratorsListStep,
  workersListStep,
  beneficiariesListStep,
  establishmentRootList,
  proposedPrestationStep,
  selectEstablishementTypeStep,
} from './Steps';
import {
  EstablishmentNewEditStep,
  EstablishmentType,
} from '../../../../../types/establishment';

const homeSteps: EstablishmentNewEditStep[] = [
  establishmentRootList(),
  basicInformationsStep(),
  proposedPrestationStep(),
  administratorsListStep(),
  workersListStep(),
  beneficiariesListStep(),
];

const residenceSteps: EstablishmentNewEditStep[] = [
  establishmentRootList(),
  basicInformationsStep(),
  proposedPrestationStep(),
  administratorsListStep(),
  workersListStep(),
  beneficiariesListStep(),
];

// should only be called from Organisation details
const childSteps: EstablishmentNewEditStep[] = [
  selectEstablishementTypeStep(),
  basicInformationsStep(),
  proposedPrestationStep(),
  administratorsListStep(),
  workersListStep(),
  beneficiariesListStep(),
];

const rootSteps: EstablishmentNewEditStep[] = [
  basicInformationsStep(),
  administratorsListStep(),
];

export const SUPER_ADMIMNISTRATOR_STEPS: Record<
  EstablishmentType,
  EstablishmentNewEditStep[]
> = {
  HOME: homeSteps,
  RESIDENCE: residenceSteps,
  RESIDENCE_CHILD: childSteps,
  RESIDENCE_ROOT: rootSteps,
  RESIDENCE_PARENT: rootSteps,
  DOMICILE: homeSteps,
};
