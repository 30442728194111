import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { ValueType } from 'react-select';

import TableHead from './TableHead';
import TableToolbar from './TableToolbar';
import TablePaginationCustom from './TablePaginationCustom';
import useStyles, { StyledTableCell, StyledTableRow } from './styles';
import { HeadCell, ToolbarProps, GroupAction } from '../../../types/table';
import Loader from '../Loader';
import EmptyData from '../EmptyData';
import FlexTableToolbar from './FlexTableToolbar';

interface CustomTableProps {
  data: any[];
  columns: HeadCell[];
  dense?: boolean;
  selectable?: boolean;
  loadingData?: boolean;
  clearSelected?: boolean;
  toolbar?: boolean;
  toolbarProps?: ToolbarProps;
  maxWidth?: number;
  toolbarActions?: GroupAction[];
  onHandleAction?: (value: ValueType<GroupAction>) => void;
  toolbarButtons?: any[];
  toolbarStyles?: any;
  selectedItems?: number[];
  setSelectedItems?: (items: number[]) => void;
  noFilter?: boolean;
  noFilterText?: string;
  noFooter?: boolean;
  noLoading?: boolean;
  toolbarActionContent?: any;
  hideSelectedText?: boolean;
  xlCol?: number[];
  useFlexToolbar?: boolean;
  toolbarClasses?: any;
  isLeftContent?: boolean;
  handleClickRow?: (id: number) => void;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const CustomTable = (props: CustomTableProps) => {
  const { data, columns, dense, selectable, loadingData, xlCol } = props;
  const { toolbar, toolbarProps, maxWidth, toolbarActions } = props;
  const { onHandleAction, toolbarButtons, toolbarStyles } = props;
  const {
    selectedItems,
    setSelectedItems,
    noFooter,
    noLoading,
    noFilter,
    noFilterText,
  } = props;
  const { handleClickRow, toolbarActionContent, hideSelectedText } = props;
  const { useFlexToolbar, toolbarClasses, clearSelected, isLeftContent } =
    props;

  const classes = useStyles();
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string | number>('id');
  const [selected, setSelected] = useState<number[]>(selectedItems || []);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  useEffect(() => {
    setPage(0);
  }, [data]);

  useEffect(() => {
    if (clearSelected) {
      setSelected([]);
    }
  }, [clearSelected]);

  useEffect(() => {
    if (setSelectedItems) setSelectedItems(selected);
  }, [selected, setSelectedItems]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    key: string | number,
  ) => {
    const isAsc = orderBy === key && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(key);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const onHandleGoupedAction = (value: ValueType<GroupAction>) => {
    if (onHandleAction) {
      onHandleAction(value);
      setSelected([]);
    }
  };

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} style={{ maxWidth }}>
        {toolbar &&
          (useFlexToolbar ? (
            <FlexTableToolbar
              {...{ ...toolbarProps }}
              toolbarActions={toolbarActions}
              onHandleAction={onHandleGoupedAction}
              toolbarButtons={toolbarButtons}
              numSelected={selected.length}
              rowCount={data.length}
              hideSelectedText={hideSelectedText || false}
              toolbarActionContent={toolbarActionContent}
              toolbarStyles={toolbarStyles}
              toolbarClasses={toolbarClasses}
            />
          ) : (
            <TableToolbar
              {...{ ...toolbarProps }}
              isLeftContent={isLeftContent}
              toolbarActions={toolbarActions}
              onHandleAction={onHandleGoupedAction}
              toolbarButtons={toolbarButtons}
              numSelected={selected.length}
              rowCount={data.length}
              hideSelectedText={hideSelectedText || false}
              toolbarActionContent={toolbarActionContent}
              toolbarStyles={toolbarStyles}
              xlCol={xlCol || []}
            />
          ))}
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="enhanced table"
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <TableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              columns={columns}
              isSelectable={selectable}
            />
            <TableBody>
              {!noLoading && loadingData ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={14}>
                    <Loader />
                  </StyledTableCell>
                </StyledTableRow>
              ) : !noLoading && data.length === 0 ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={14}>
                    <EmptyData
                      text={
                        noFilter && noFilterText
                          ? noFilterText
                          : 'Aucun résultat trouvé'
                      }
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item: any, index: number) => {
                    const isItemSelected = item ? isSelected(item.id) : false;
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`row-${item.id}${index}`}
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        onClick={(
                          event: React.MouseEvent<
                            HTMLTableRowElement,
                            MouseEvent
                          >,
                        ) => {
                          event.stopPropagation();
                          event.preventDefault();
                          if (handleClickRow) handleClickRow(item.id);
                        }}
                      >
                        {selectable && (
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleClick(event, item.id);
                              }}
                              inputProps={{
                                'aria-labelledby': `enhanced-table-checkbox-${item.id}`,
                              }}
                            />
                          </StyledTableCell>
                        )}
                        {columns.map((cell: HeadCell, cellIndex: number) => {
                          return (
                            <StyledTableCell
                              key={`row-${cell.name}-${cellIndex}`}
                              // scope="row"
                              padding={cell.disablePadding ? 'none' : 'default'}
                              align={cell.numeric ? 'center' : 'left'}
                            >
                              {cell.renderer
                                ? cell.renderer(item, index)
                                : item[cell.name] || '-'}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })
              )}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 23 : 43) * emptyRows }}>
                  <TableCell colSpan={14} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {!noFooter && (
          <TablePagination
            labelRowsPerPage={'Lignes par page :'}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} sur ${count}`
            }
            rowsPerPageOptions={[5, 10, 15, 20, 25]} //{ label: 'All', value: -1 }
            colSpan={3}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationCustom}
          />
        )}
      </Paper>
    </div>
  );
};

export default CustomTable;
