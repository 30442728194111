import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

interface FooterProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 80,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(1, 6),
      backgroundColor: '#fff',
      border: '1px solid #E0E0E0',
      position: 'fixed',
      zIndex: 998,
      bottom: 0,
      left: 0,
      width: '100%',
      boxSizing: 'border-box',
      [theme.breakpoints.down(500)]: {
        padding: theme.spacing(1, 1),
        height: 'auto',
      },
    },
  }),
);

const Footer: FC<FooterProps> = props => {
  const { children } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <div>{children}</div>
    </Box>
  );
};

export default Footer;
