import {
  ValidatorFunc,
  Validators,
  ValidationState,
} from '../types/validator.interface';

export const validate = <TargetType, ErrorType>(
  target: TargetType,
  targetValidator: Validators<TargetType, ErrorType>,
): ValidationState<ErrorType> => {
  const keys: (keyof TargetType)[] = Object.keys(
    target,
  ) as (keyof TargetType)[];
  for (let key of keys) {
    const validators: ValidatorFunc<TargetType, ErrorType>[] | undefined =
      targetValidator[key];
    if (validators) {
      for (let validator of validators) {
        const validationState: ValidationState<ErrorType> = validator(
          target,
          key,
        );
        if (!validationState.valid) {
          return validationState;
        }
      }
    }
  }
  return {
    valid: true,
  };
};

export const validateSeveral = <TargetType, ErrorType>(
  targets: TargetType[],
  targetValidator: Validators<TargetType, ErrorType>,
): ValidationState<ErrorType> => {
  for (let target of targets) {
    const validationState: ValidationState<ErrorType> = validate(
      target,
      targetValidator,
    );
    if (!validationState.valid) {
      return validationState;
    }
  }
  return {
    valid: true,
  };
};
