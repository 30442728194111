import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

interface SignatureProps {
  title?: string;
  imageUrl?: string | null;
}

const Signature: FC<SignatureProps> = (props) => {
  const { title, imageUrl } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      <Box className={classes.signatureContainer}>
        {imageUrl && <img src={imageUrl} alt="Signature du bénéficiaire" />}
      </Box>
    </Box>
  );
};

export default Signature;
