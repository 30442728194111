import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      [theme.breakpoints.down(748)]: {
        flexFlow: 'row wrap',
      },
    },
    excelIcon: {
      width: 24,
      height: 24,
    },
    margin: {
      marginLeft: 8,
      marginRight: 8,
    },
    name: {
      fontSize: '1.2em',
      fontWeight: 600,
    },
    nameInfo: {
      flexGrow: 1,
      // minWidth: 300,

      [theme.breakpoints.down(749)]: {
        minWidth: 200,
      },

      [theme.breakpoints.down(748)]: {
        marginBottom: theme.spacing(2),
      },
    },
    info: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 2,
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(4),
      borderLeft: '1px solid #CECECE',

      [theme.breakpoints.down(749)]: {
        flexFlow: 'row wrap',
      },

      [theme.breakpoints.down(748)]: {
        borderLeft: 0,
        paddingLeft: 0,
        borderTop: '1px solid #CECECE',
        paddingTop: theme.spacing(2),
      },
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      height: 'auto',
      alignSelf: 'flex-start',
    },
    textSecondary: {
      fontSize: '1em',
      fontWeight: 600,
      marginBottom: theme.spacing(2),
    },
    mb: {
      marginBottom: theme.spacing(1),
    },
    formGroup: {
      marginTop: theme.spacing(2),
    },
    formControlLabel: {
      width: '100%',
      boxSizing: 'border-box',
    },
    inputLabel: {
      fontSize: '0.975rem',
    },
    switch: {
      color: theme.palette.primary.main,
      marginRight: 10,
    },
  }),
);

export default useStyles;
