import React, { FC } from 'react';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import { ChoiceType } from '../../../../../types/establishment';

interface ChoiceBoxProps extends ChoiceType {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: 340,
      height: 280,
      background: '#fff',
      border: '1px solid #9E9E9E',
      borderRadius: 16,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1),
      cursor: 'pointer',
      transition: ' background-color ease 0.8s',
      margin: theme.spacing(2),

      '&:hover': {
        backgroundColor: grey[100],
      },
      [theme.breakpoints.down(416)]: {
        width: 265,
        height: 220,
      },
    },
    image: {
      width: 176,
      height: 134,
      display: 'flex',
      alignItems: 'flex-end',
      '& .MuiAvatar-img': {
        height: 'initial',
        width: 'initial',
        objectFit: 'contain',
      },
    },
    typo: {
      fontSize: 18,
      color: '#212121',
      marginTop: theme.spacing(2),
    },
  }),
);

const ChoiceBox: FC<ChoiceBoxProps & RouteComponentProps> = (props) => {
  const { title, imgSource, id, history, onClick, type } = props;
  const location = useLocation();
  const classes = useStyles();

  const onHandleClick = () => {
    if (onClick) {
      onClick(id);
    }

    if(type && type === 'ADMIN'){
      history.push({
        pathname: `${location.pathname}?type=${id}`,
        state: location.state,
      });
    }else{
      history.push(`${location.pathname}?type=${id}`);

    }
  };

  return (
    <Paper onClick={onHandleClick} elevation={0} className={classes.paper}>
      <Avatar
        variant="square"
        alt="choice-box-img"
        src={require(`../../../../../assets/images/${imgSource}`)}
        className={classes.image}
      />
      <Typography className={classes.typo}>{title}</Typography>
    </Paper>
  );
};

export default withRouter(ChoiceBox);
