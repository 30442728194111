import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Order } from '../../../../types/sort';
import { HeadCell } from '../../../../types/table';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

interface CustomTableHeadProps {
  numSelected: number;
  order: Order;
  orderBy: string | number;
  rowCount: number;
  columns: HeadCell[];
  isSelectable?: boolean;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    key: string | number,
  ) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomTableHead = (props: CustomTableHeadProps) => {
  const {
    order,
    orderBy,
    numSelected,
    rowCount,
    columns,
    isSelectable,
    onRequestSort,
    onSelectAllClick,
  } = props;

  const createSortHandler =
    (key: string | number) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, key);
    };

  return (
    <TableHead>
      <TableRow>
        {isSelectable && (
          <StyledTableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </StyledTableCell>
        )}
        {columns.map((column: HeadCell) => (
          <StyledTableCell
            key={`cell-${column.name}`}
            align={column.numeric ? 'center' : 'left'}
            padding={column.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === column.name ? order : false}
            style={
              !isSelectable
                ? { padding: '12px 0 12px 12px', minWidth: column.minWidth }
                : { minWidth: column.minWidth }
            }
          >
            <TableSortLabel
              active={orderBy === column.name}
              direction={orderBy === column.name ? order : 'asc'}
              onClick={createSortHandler(column.name)}
            >
              {column.label}
              {orderBy === column.name ? (
                <span
                  style={{
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
