import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formControl: {
      width: '100%',
      // minWidth: 80,
    },
  }),
);

export default useStyles;
