import React, { FC, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import Card from '../../../Common/CustomCard';
import { EtablishmentDataType } from '../../../../types/establishment';
import { useQuery } from '@apollo/react-hooks/lib/useQuery';
import { useLocation } from 'react-router-dom';
import {
  GetStatusBySlug,
  GetStatusBySlugVariables,
} from '../../../../graphql/status/types/GetStatusBySlug';
import { GET_STATUS_BY_SLUG } from '../../../../graphql/status/query';
import LegalDatas from './LegalDatas';
import Dropzone from '../../../Common/Dropzone';

interface EstablishmentBasicInfoFormProps {
  inputData: EtablishmentDataType;
  onChange: (data: EtablishmentDataType) => void;
}

const EstablishmentBasicInfoForm: FC<EstablishmentBasicInfoFormProps> = (
  props,
) => {
  const classes = useStyles();
  const { inputData, onChange } = props;

  const { data: establishmentStatus } = useQuery<
    GetStatusBySlug,
    GetStatusBySlugVariables
  >(GET_STATUS_BY_SLUG, { variables: { slug: 'ETABLISHMENT_STATUS' as any } });

  const [showInput, setShowInput] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const type = query.get('type') || '';

  useEffect(() => {
    if (establishmentStatus && establishmentStatus.getStatusBySlug) {
      const id =
        (establishmentStatus.getStatusBySlug.length > 0 &&
          establishmentStatus.getStatusBySlug[0] &&
          establishmentStatus.getStatusBySlug[0].id) ||
        0;
      onChange({
        ...inputData,
        etablishmentStatusId: id,
      });
    }
  }, [establishmentStatus]);

  const getBase64 = (file: File) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (reader && reader.result)
        onChange({
          ...inputData,
          picture: selectedFiles,
          base64logo: reader.result?.toString(),
        });
    };
    reader.onerror = function (error) {
      console.log('Error converting logo to base 64: ', error);
    };
  };

  useEffect(() => {
    if (selectedFiles) {
      if (selectedFiles.length > 0) {
        const file: File = selectedFiles[0] as File;
        getBase64(file);
      }
    }
  }, [selectedFiles]);

  return (
    <>
      <Box className={classes.content}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item>
            <Card
              title={
                type === 'RESIDENCE_ROOT'
                  ? "Logo de l'organisation"
                  : 'Logo de votre établissement'
              }
            >
              <Dropzone
                accept="image/*"
                multiple={false}
                pictureUrl={inputData.logo}
                base64Img={inputData.base64logo || ''}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                inputData={inputData}
                setInputData={onChange}
                enablePreview
                showAction
              />
            </Card>
          </Grid>
          <Grid item>
            <Card title="Données légales">
              <LegalDatas
                inputData={inputData}
                setInputData={onChange}
                establishmentStatus={establishmentStatus}
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EstablishmentBasicInfoForm;
