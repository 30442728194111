import React, { FC } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';

import useStyles from './styles';

export interface FilterDateProps {
  label: string;
  name: string;
  placeholder?: string;
  value: string;
  minWidth?: number;
  additionalClass?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement> | any) => void;
}

const FilterDate: FC<FilterDateProps> = (props) => {
  const { label, name, placeholder, value, onChange, minWidth,additionalClass } = props;
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={`${classes.formControl} ${additionalClass || ''}`}
      style={{ minWidth }}
    >
      <TextField
        type="date"
        variant="outlined"
        placeholder={placeholder || label}
        value={value}
        onChange={onChange}
        label={label}
        name={name}
        InputLabelProps={{ shrink: true }}
      />
    </FormControl>
  );
};

export default FilterDate;
