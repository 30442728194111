import { User } from '../../../../../types/user';

export const DEFAULT_USER: User = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  enabled: false,
  mobilePhone: '',
  userFunction: '',
  phone: '',
  userRole: 'administrator',
};
