import React, { FC, useState, useEffect } from 'react';
import { UserRole, User } from '../../../../../types/user';
import {
  useReadUsersQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useUpdateUsersStatusMutation,
  useCreateUsersMutation,
  useDeleteUsersMutation,
  useDeleteUserMutation,
} from '../../../../../hooks/User.hooks';
import { UserListActions } from './interfaces/UserListActions.interface';
import UserListTable from './components/UserListTable';
import {
  Users,
  UsersVariables,
  UserData,
} from '../../../../../types/Users.interface';
import { mapToUser } from './utils/Users.util';
import { useApolloClient } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-boost';
import { showSnackbar } from '../../../../../utils/snackbar.util';
import { onCreateUserMutationOptions } from './utils/CreateUserMutationOptions.utils';
import { updateUserMutationOptions } from './utils/UpdateUserMutationOptions.utils';
import { filterUsers } from './utils/UserEditList.utils';
import { onUpdateUserStatusMutationOptions } from './utils/UpdateUserStatusOptions.utils';
import { OnUpdateUsersStatusCompletedOptions } from './interfaces/UpdateUserStatusOptions.interfaces';
import { onCreateUsersMutationOptions } from './utils/CreateUsersMutationOptions';
import { UserValidationState } from '../../../../../types/user-validator.types';
import {
  getDefaultUserValidators,
  getExcelImportUserErrorMessage,
  getUserValidationDefaultErrorMessages,
  validateUser,
  validateUsers,
} from '../../../../../utils/user-validator.utils';
import { getUsersFromFiles } from '../../../../../utils/excel-import.utils';
import { getPartialUsers } from '../../../../../utils/user.utils';
import { onDeleteUsersMutationOptions } from './utils/DeleteUsersMutationOptions.utils';
import { onDeleteUserMutationOptions } from './utils/DeleteUserMutationOptions.utils';
import { mapToIds } from '../../../../../common/utils';
import { useContext } from 'react';
import { EstablishmentContext } from '../../../../Context';

// User list only contains users that has the same role
interface UserEditListProps {
  userRole: UserRole;
  usersIds: number[];
  establishmentId: number;
  onUsersListIdsChange: (ids: number[]) => void;
  handleClickRow?: (id: number) => void;
}

const USERS_DATA_KEYS: Record<UserRole, string> = {
  administrator: 'administrators',
  beneficiary: 'beneficiaries',
  worker: 'workers',
};

const UserEditList: FC<UserEditListProps> = (props) => {
  const { newEtablishmentId } = useContext(EstablishmentContext);

  const {
    userRole,
    usersIds,
    establishmentId,
    onUsersListIdsChange,
    handleClickRow,
  } = props;
  const [users, setUsers] = useState<User[]>([]);
  const [beneficiaryDocuments, setBeneficiaryDocuments] = useState<string>('');
  const client: ApolloClient<object> = useApolloClient();
  const [
    updateUserStatusOnCompletedOptions,
    setUpdateUserStatusOnCompletedOptions,
  ] = useState<OnUpdateUsersStatusCompletedOptions>({
    enabled: false,
    numbers: 0,
  });

  const { data: usersData, loading: usersDataLoading } = useReadUsersQuery<
    Users,
    UsersVariables
  >({
    userRole,
    options: {
      variables: {
        input: {
          filter: JSON.stringify({
            ids: usersIds,
          }),
        },
      },
    },
  });

  const [updateUserMutation, { loading: updateUserLoading }] =
    useUpdateUserMutation({
      userRole,
      options: updateUserMutationOptions({ client, userRole }),
    });

  const [createUserMutation, { loading: createUserLoading }] =
    useCreateUserMutation(
      {
        userRole,
        options: onCreateUserMutationOptions({
          client,
          addUser: (user) => {
            onUsersListIdsChange([...usersIds, user.id]);
          },
          userRole,
        }),
      },
      userRole === 'beneficiary' ? beneficiaryDocuments : undefined,
    );

  const [createUsersMutation, { loading: createUsersLoading }] =
    useCreateUsersMutation({
      userRole,
      options: onCreateUsersMutationOptions({
        client,
        addUsers: (users) => {
          onUsersListIdsChange([...usersIds, ...mapToIds(users)]);
        },
        userRole,
      }),
    });

  const [
    updateUsersStatusMutation,
    //{ loading: updateUsersStatusLoading },
  ] = useUpdateUsersStatusMutation({
    userRole,
    options: onUpdateUserStatusMutationOptions({
      client,
      userRole,
      options: updateUserStatusOnCompletedOptions,
    }),
  });

  const [deleteUserMutation, { loading: deleteUserMutationLoading }] =
    useDeleteUserMutation({
      userRole,
      options: onDeleteUserMutationOptions({
        client,
        userRole,
      }),
    });

  const [deleteUsersMutation, { loading: deleteUsersMutationLoading }] =
    useDeleteUsersMutation({
      userRole,
      options: onDeleteUsersMutationOptions({
        client,
        userRole,
      }),
    });

  useEffect(() => {
    if (!usersDataLoading && !!usersData) {
      const users: User[] = mapToUser(
        (usersData as any)[USERS_DATA_KEYS[userRole]].filter(
          (userData: UserData) => !!userData.user,
        ),
        userRole,
        establishmentId,
      );
      setUsers(users);
    }
  }, [usersData]);

  const createUser = (user: User, onSuccess?: (res: any) => void) => {
    const userValidationState: UserValidationState = validateUser(
      user,
      getDefaultUserValidators(userRole),
    );
    if (userValidationState.valid) {
      createUserMutation({
        ...user,
        userRole,
        establishmentId: newEtablishmentId,
      }).then((res) => onSuccess && onSuccess(res));
    } else if (userValidationState.error) {
      const errorMessage: string = getUserValidationDefaultErrorMessages(
        userValidationState.error,
      );
      showSnackbar({
        type: 'ERROR',
        message: errorMessage,
        client,
      });
    }
  };

  const createUsers = (users: User[]) => {
    const userValidationState: UserValidationState = validateUsers(
      users,
      getDefaultUserValidators(userRole),
    );
    if (userValidationState.valid) {
      createUsersMutation({
        variables: {
          input: getPartialUsers(users, []).map((user) => {
            const { establishmentId, userRole, ...rest } = user;
            const etabId =
              userRole === 'administrator'
                ? { establishmentId }
                : { etablishmentId: establishmentId };
            return {
              ...etabId,
              ...rest,
              etablishmentId: newEtablishmentId,
            };
          }),
        },
      });
    } else if (userValidationState.error) {
      const errorMessage: string = getExcelImportUserErrorMessage(
        userValidationState.error,
      );
      showSnackbar({
        type: 'ERROR',
        message: errorMessage,
        client,
      });
    }
  };

  const deleteUser = (id: number) => {
    deleteUserMutation({
      variables: {
        id,
      },
    });
    setUsers((prevValue: User[]) =>
      prevValue.filter((user: User) => user.id !== id),
    );
  };

  const updateUser = (user: User, onSuccess?: (res: any) => void) => {
    const userValidationState: UserValidationState = validateUser(
      user,
      getDefaultUserValidators(userRole),
    );
    if (userValidationState.valid) {
      updateUserMutation({
        ...user,
      }).then((res) => onSuccess && onSuccess(res));
    } else if (userValidationState.error) {
      const errorMessage: string = getUserValidationDefaultErrorMessages(
        userValidationState.error,
      );
      showSnackbar({
        type: 'ERROR',
        message: errorMessage,
        client,
      });
    }
  };

  const deleteUsers = (ids: number[]) => {
    deleteUsersMutation({
      variables: {
        ids,
      },
    });
    setUsers((prevValue: User[]) => filterUsers(prevValue, ids));
  };

  const updateUsersStatus = (ids: number[], enabled: boolean) => {
    setUpdateUserStatusOnCompletedOptions({
      enabled,
      numbers: ids.length,
    });
    updateUsersStatusMutation({
      variables: {
        ids,
        status: enabled,
      },
    });
  };

  const importExcel = async (fileList: FileList) => {
    const users: User[] = await getUsersFromFiles(fileList, {
      userRole,
      establishmentId: establishmentId ? establishmentId : -1,
    });
    createUsers(users);
  };

  const actions: UserListActions = {
    createUser,
    createUsers,
    deleteUser,
    deleteUsers,
    importExcel,
    updateUsersStatus,
    updateUser,
  };

  const loading: boolean =
    usersDataLoading ||
    createUsersLoading ||
    createUsersLoading ||
    updateUserLoading ||
    createUserLoading ||
    deleteUserMutationLoading ||
    deleteUsersMutationLoading;

  return (
    <UserListTable
      actions={actions}
      userRole={userRole}
      users={users}
      loading={loading}
      handleClickRow={handleClickRow}
      establishmentId={establishmentId}
      beneficiaryDocuments={beneficiaryDocuments}
      setBeneficiaryDocuments={setBeneficiaryDocuments}
    />
  );
};

export default UserEditList;
