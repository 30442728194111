import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import CloseIcon from '@material-ui/icons/Close';
import CustomTable from '../../../CustomTable';
import { GroupAction, HeadCell } from '../../../../../types/table';
import { WorkerByIds_workerByIds } from '../../../../../graphql/worker/types/WorkerByIds';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
} from '@apollo/react-hooks';
import WorkerListUpdate from '../WorkerListUpdate/WorkerListUpdate';
import { ValueType } from 'react-select';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { SnackbarType } from '../../../../../types';
import {
  updateMembersPlanning,
  updateMembersPlanningVariables,
} from '../../../../../graphql/planning/types/updateMembersPlanning';
import {
  updatePlanningActionEnum,
  updatePlanningMembersEnum,
} from '../../../../../graphql/types/graphql-global-types';
import { UPDATE_PLANNING_MEMBERS } from '../../../../../graphql/planning/mutation';
import { displaySnackbar } from '../../../../../common';
import { PlanningBenefsVariables } from '../../../../../graphql/planning/types/PlanningBenefs';
import { GET_PLANNING_WORKERS } from '../../../../../graphql/planning/query';
import {
  PlanningWorkers,
  PlanningWorkers_planningWorkers,
  PlanningWorkersVariables,
} from '../../../../../graphql/planning/types/PlanningWorkers';

const ACTIONS: GroupAction[] = [{ label: 'Supprimer', value: 'remove' }];
interface Props {
  open: boolean;
  setOpen: any;
  id: number[];
}
const WorkerPlanningEdit = (props: Props) => {
  const { open, setOpen, id: idPlanning } = props;
  const client = useApolloClient();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [workers, setWorkers] = useState<
    (PlanningWorkers_planningWorkers | null)[]
  >([]);
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };
  const [openWorkerList, setOpenWorkerList] = useState<boolean>(false);
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };
  const [updatePlanningMembers, { data }] = useMutation<
    updateMembersPlanning,
    updateMembersPlanningVariables
  >(UPDATE_PLANNING_MEMBERS, {
    onCompleted: (response) => {
      if (response.updateMembersPlanning.success) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = response?.updateMembersPlanning.message ?? '';
        displaySnackbar(client, snackbarData);
        setSelectedItems([]);
        refetch();
      } else {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = response?.updateMembersPlanning.message ?? '';
        displaySnackbar(client, snackbarData);
      }
    },
    onError: (error) => {
      snackbarData.message = 'Une erreur est survenue';
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      displaySnackbar(client, snackbarData);
    },
  });
  const [getWorkers, { data: workerData, loading: loadingWorkers, refetch }] =
    useLazyQuery<PlanningWorkers, PlanningWorkersVariables>(
      GET_PLANNING_WORKERS,
      {
        onCompleted: (data: PlanningWorkers) => {
          const reponse = data?.planningWorkers;
          if (reponse) {
            setWorkers(reponse);
          }
        },
      },
    );
  const refresh = () => {
    if (idPlanning) {
      const vars: PlanningBenefsVariables = { Idplanning: idPlanning };
      getWorkers({ variables: vars });
    }
  };
  useEffect(() => {
    refresh();
  }, [idPlanning]);
  const onHandleActions = (value: ValueType<GroupAction>) => {
    const actionGroup = value as GroupAction;
    if (actionGroup && actionGroup.value) {
      switch (actionGroup.value) {
        case 'remove':
          const variables_delete: updateMembersPlanningVariables = {
            input: {
              idmembers: selectedItems,
              action: updatePlanningActionEnum.REMOVE,
              members: updatePlanningMembersEnum.WORKERS,
              idPlanning: idPlanning,
            },
          };
          updatePlanningMembers({ variables: variables_delete });
          break;
      }
    }
  };
  const onHandleDelete = (id: number) => {
    const variables_delete: updateMembersPlanningVariables = {
      input: {
        idmembers: [id],
        action: updatePlanningActionEnum.REMOVE,
        members: updatePlanningMembersEnum.WORKERS,
        idPlanning: idPlanning,
      },
    };
    updatePlanningMembers({ variables: variables_delete });
  };
  const workerToolbarButtons = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="worker-add"
      startIcon={<AddBoxIcon />}
      onClick={() => {
        setOpenWorkerList(true);
      }}
    >
      Ajouter un intervenant
    </Button>,
  ];
  const columns: HeadCell[] = [
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.lastName) || '-';
      },
    },
    {
      name: 'lastName',
      label: 'Prénom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.firstName) || '-';
      },
    },
    {
      name: 'function',
      label: 'Fonction',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.userFunction) || '-';
      },
    },
    {
      name: 'mail',
      label: 'Mail',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.email) || '-';
      },
    },
    {
      name: 'mobilePhone',
      label: 'Tél mobile',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.mobilePhone) || '-';
      },
    },
    {
      name: 'phone',
      label: 'Tél fixe',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.phone) || '-';
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => {
        const statut = value.user && value.user.enabled;
        return statut ? 'Activé' : 'Désactivé';
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex" justifyContent="center">
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleDelete(value.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const onHandleAction = (action: ValueType<GroupAction>): void => {
    const actionGroup = action as GroupAction;
    if (actionGroup && actionGroup.value) {
      switch (actionGroup.value) {
        case 'remove':
          const variables_delete: updateMembersPlanningVariables = {
            input: {
              idmembers: selectedItems,
              action: updatePlanningActionEnum.REMOVE,
              members: updatePlanningMembersEnum.WORKERS,
              idPlanning: idPlanning,
            },
          };
          updatePlanningMembers({ variables: variables_delete });
          break;
      }
    }
  };
  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Liste des intervenants
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.tableContainer}>
          <CustomTable
            toolbar
            selectable
            toolbarActions={ACTIONS}
            columns={columns}
            data={workers}
            loadingData={loadingWorkers}
            toolbarButtons={workerToolbarButtons}
            selectedItems={selectedItems}
            isLeftContent={true}
            setSelectedItems={setSelectedItems}
            onHandleAction={onHandleAction}
          />
        </div>
      </Dialog>
      {openWorkerList && idPlanning && (
        <WorkerListUpdate
          open={openWorkerList}
          onFinish={() => {
            setOpenWorkerList(false);
            refetch();
          }}
          setOpen={setOpenWorkerList}
          idPlanning={idPlanning}
        />
      )}
    </div>
  );
};

export default WorkerPlanningEdit;
