import { displaySnackbar } from '../common';
import { ShowSnackBarArgs } from '../types/snackbar';

export const showSnackbar = ({ type, message, client }: ShowSnackBarArgs) => {
  displaySnackbar(client, {
    type,
    message,
    isOpen: true,
  });
};
