import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 auto',
      padding: '0px 24px 4px',
      maxWidth: 1920,
      minHeight: 'calc(100vh - 400px)',
    },
  }),
);

export default useStyles;
