import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';
import { GroupAction, HeadCell } from '../../../../../types/table';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
} from '@apollo/react-hooks';
import CustomTable from '../../../CustomTable';
import BeneficiaryListUpdate from '../BeneficiaryListUpdate/BeneficiaryListUpdate';
import { SnackbarType } from '../../../../../types/snackbar';
import moment from 'moment';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import { GET_PLANNING_BENEFICIARIES } from '../../../../../graphql/planning/query';
import {
  PlanningBenefs,
  PlanningBenefs_planningBeneficiaries,
  PlanningBenefsVariables,
} from '../../../../../graphql/planning/types/PlanningBenefs';
import { UPDATE_PLANNING_MEMBERS } from '../../../../../graphql/planning/mutation';
import { displaySnackbar } from '../../../../../common';
import {
  updateMembersPlanning,
  updateMembersPlanningVariables,
} from '../../../../../graphql/planning/types/updateMembersPlanning';
import {
  updatePlanningActionEnum,
  updatePlanningMembersEnum,
} from '../../../../../graphql/types/graphql-global-types';
import { ValueType } from 'react-select';

const ACTIONS: GroupAction[] = [{ label: 'Supprimer', value: 'remove' }];

interface Props {
  open: boolean;
  setOpen: any;
  id: number[];
}

const BeneficiaryPlanningEdit = (props: Props) => {
  const { open, setOpen, id: IdPlannings } = props;
  const classes = useStyles();
  const client = useApolloClient();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [beneficiaries, setBeneficiaries] = useState<
    PlanningBenefs_planningBeneficiaries[]
  >([]);
  const [openBeneficiaryUpdate, setOpenBeneficiaryUpdate] =
    useState<boolean>(false);
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };
  const onHandleActions = (value: ValueType<GroupAction>) => {
    const actionGroup = value as GroupAction;
    if (actionGroup && actionGroup.value) {
      switch (actionGroup.value) {
        case 'remove':
          const variables_delete: updateMembersPlanningVariables = {
            input: {
              idmembers: selectedItems,
              action: updatePlanningActionEnum.REMOVE,
              members: updatePlanningMembersEnum.BENEFICIARY,
              idPlanning: IdPlannings,
            },
          };
          updatePlanningMembers({ variables: variables_delete });
          break;
      }
    }
  };
  const [
    getBeneficiaries,
    { data: benefData, loading: loadingBenef, refetch },
  ] = useLazyQuery<PlanningBenefs, PlanningBenefsVariables>(
    GET_PLANNING_BENEFICIARIES,
    {
      onCompleted: (data: PlanningBenefs) => {
        const reponse = data?.planningBeneficiaries;
        if (reponse) {
          setBeneficiaries(reponse);
        }
      },
    },
  );
  const [updatePlanningMembers, { data }] = useMutation<
    updateMembersPlanning,
    updateMembersPlanningVariables
  >(UPDATE_PLANNING_MEMBERS, {
    onCompleted: (response) => {
      if (response.updateMembersPlanning.success) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = response?.updateMembersPlanning.message ?? '';
        displaySnackbar(client, snackbarData);
        setSelectedItems([]);
        refetch();
      } else {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = response?.updateMembersPlanning.message ?? '';
        displaySnackbar(client, snackbarData);
      }
    },
    onError: (error) => {
      console.log(error);
      snackbarData.message = 'Une erreur est survenue';
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      displaySnackbar(client, snackbarData);
    },
  });
  const onHandleAdd = () => {
    setOpenBeneficiaryUpdate(true);
  };
  const toolbarButtons = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="worker-add"
      startIcon={<AddBoxIcon />}
      onClick={onHandleAdd}
    >
      Ajouter un bénéficiaire
    </Button>,
  ];
  useEffect(() => {
    const id = IdPlannings;
    if (id) {
      const vars: PlanningBenefsVariables = { Idplanning: id };
      getBeneficiaries({ variables: vars });
    }
  }, [IdPlannings]);

  const handleClose = () => {
    setOpen(false);
  };
  const onHandleDelete = (id: number) => {
    const variables_delete: updateMembersPlanningVariables = {
      input: {
        idmembers: [id],
        action: updatePlanningActionEnum.REMOVE,
        members: updatePlanningMembersEnum.BENEFICIARY,
        idPlanning: IdPlannings,
      },
    };
    updatePlanningMembers({ variables: variables_delete });
  };

  const columns: HeadCell[] = [
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.lastName) || '-';
      },
    },
    {
      name: 'lastName',
      label: 'Prénom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.firstName) || '-';
      },
    },
    {
      name: 'birthDate',
      label: 'Date de naissance',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && moment(value.birthDate).format('DD-MM-YYYY')) || '-';
      },
    },
    {
      name: 'disabilityRecognition',
      label: "Reconnaissance d'handicap",
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.disabilityRecognition ? 'Oui' : 'Non') || '-';
      },
    },
    {
      name: 'unitLife',
      label: 'Unité de vie',
      minWidth: 120,
      disablePadding: false,
      renderer: (value: any) => {
        return value?.unitLife?.name || '-';
      },
    },
    {
      name: 'mail',
      label: 'Mail',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.email) || '-';
      },
    },
    {
      name: 'mobilePhone',
      label: 'Tél mobile',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.mobilePhone) || '-';
      },
    },
    {
      name: 'phone',
      label: 'Tél fixe',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.phone) || '-';
      },
    },
    {
      name: 'guardianEnabled',
      label: 'Sous tutelle',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.guardianEnabled ? 'Oui' : 'Non') || '-';
      },
    },
    {
      name: 'guardianName',
      label: 'Nom tuteur',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.guardianName) || '-';
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => {
        const statut =
          value.user && value.user.enabled ? 'Activé' : 'Desactivé';
        return statut;
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex" justifyContent="center">
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleDelete(value.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Liste des bénéficiaires
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.tableContainer}>
          <CustomTable
            toolbar
            selectable
            toolbarActions={ACTIONS}
            columns={columns}
            data={beneficiaries}
            loadingData={loadingBenef}
            toolbarButtons={toolbarButtons}
            isLeftContent={true}
            onHandleAction={onHandleActions}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        </div>
      </Dialog>
      {openBeneficiaryUpdate && (
        <BeneficiaryListUpdate
          open={openBeneficiaryUpdate}
          setOpen={setOpenBeneficiaryUpdate}
          idPlanning={IdPlannings}
          onFinish={() => {
            setOpenBeneficiaryUpdate(false);
            refetch();
          }}
        />
      )}
    </div>
  );
};

export default BeneficiaryPlanningEdit;
