import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiPaper-root': {
        maxWidth: '430px',
      },
      '& .MuiDialogTitle-root': {
        height: '74px',
      },
      '& .MuiDialogContent-root': {
        padding: theme.spacing(0, 8, 4),
      },
      // '& .MuiDialogActions-root': {
      //   padding: '0 50px 0 50px',
      //   marginBottom: '46px',
      //   display: 'flex',
      //   flexDirection: 'row',
      //   justifyContent: 'center',
      // },
      // '& .MuiButton-root': {
      //   height: '56px',
      //   // width: '149px',
      //   fontSize: '14px',
      //   boxShadow: '0px 2px 2px #0000003D',
      // },
    },
    button: {
      height: '56px',
      fontSize: '14px',
      boxShadow: '0px 2px 2px #0000003D',
      padding: theme.spacing(0, 4),
    },
    title: {
      color: 'black',
      fontSize: '35px',
      textAlign: 'center',
      lineHeight: '42px',
      marginBottom: '15px',
    },
    message: {
      fontSize: '16px',
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    cancelButton: {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(2),
    },
    actions: {
      padding: '0 50px 0 50px',
      marginBottom: '46px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  }),
);

export default useStyles;
