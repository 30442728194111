import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import useStyles from './styles';
import { SnackbarType } from '../../../types/snackbar';
import { GET_SNACKBAR_STATE } from '../../../graphql/common/snackbar/query';

interface GlobalSnackbarProps {}

const GlobalSnackbar = (props: GlobalSnackbarProps) => {
  const client = useApolloClient();
  const classes = useStyles();

  const { data } = useQuery(GET_SNACKBAR_STATE, {
    fetchPolicy: 'cache-only',
  });

  const handleClose = (): void => {
    client.writeData({
      data: {
        snackBar: {
          isOpen: false,
          autoHideDuration: null,
          __typename: 'SnackBar',
        },
      },
    });
  };

  if (data) {
    const { isOpen, message, type, autoHideDuration }: SnackbarType =
      data.snackBar
        ? data.snackBar
        : {
            isOpen: true,
            message: '',
            type: 'ERROR',
          };

    return (
      <Snackbar
        className={classes.root}
        open={isOpen}
        autoHideDuration={autoHideDuration ? autoHideDuration : 5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleClose}
          elevation={6}
          variant="filled"
          severity={String(type).toLowerCase() as any}
        >
          {message}
        </Alert>
      </Snackbar>
    );
  }

  return null;
};

export default GlobalSnackbar;
