import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 80,
      width: '100%',
      backgroundColor: '#ffffff',
      borderLeft: 0,
      borderRight: 0,
      borderTop: 0,
      position: 'fixed',
      top: 0,
      zIndex: 999,
      border: '1px solid #d5e3d6',
      '& .navbar-toggler': {
        marginLeft: 12,
      },
    },
    menuList: {
      marginLeft: 40,
      height: 80,
      [theme.breakpoints.down(1200)]: {
        height: '100%',
        marginBottom: 24,
      },
      [theme.breakpoints.down(650)]: {
        marginLeft: 20,
      },
    },
    navbar: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down(1200)]: {
        display: 'block',
      },
    },
    collapsNavBar: {
      [theme.breakpoints.down(1200)]: {
        position: 'absolute',
        top: 80,
        left: 0,
        background: '#002B67',
        width: '100%',
        zIndex: 10,
      },
    },
    navbarItem: {
      height: '100%',
      padding: 0,
      marginLeft: 16,
      marginRight: 16,
      color: '#212121',
      fontSize: 16,
      borderBottom: '1px solid transparent',
      '&:hover': {
        backgroundColor: 'transparent',
        borderBottomColor: '#212121',
      },
      [theme.breakpoints.down(1200)]: {
        marginTop: 16,
        color: '#ffffff',
      },
    },
    activeMenu: {
      color: '#ED1C26',
      fontSize: 16,
      fontWeight: 600,
      borderBottom: '1px solid #ED1C26',

      '&:hover': {
        borderBottom: '1px solid #ED1C26',
      },
    },
    toolbar: {
      height: 80,
    },
    grow: {
      flexGrow: 1,
    },
    rightMenu: {
      display: 'flex',
      flexDirection: 'row',
      width: 'auto',
    },
    userSection: {
      marginLeft: 34,
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down(650)]: {
        display: 'none',
      },
    },
    userSectionMobile: {
      display: 'none',

      [theme.breakpoints.down(650)]: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        marginTop: 24,
      },
    },
    avatar: {
      width: 50,
      height: 50,
      marginLeft: 16,
      border: '1px solid #d5e3d6',
    },
    iconButton: {
      backgroundColor: '#F5F5F5',
    },
    logo: {
      width: 150,
      height: 48,
    },

    expandButton: {
      color: '#9E9E9E',
      backgroundColor: 'transparent',
    },
    brandLink: {
      cursor: 'pointer',
    },
  }),
);

export default useStyles;
