import React, { FC, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery, useApolloClient, useMutation } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import { ApolloError } from 'apollo-boost';

import EstablishmentHeader from '../Header';
import EstablishmentFooter from '../Footer';
import Stepper from '../../../Common/CustomStepper';
import useStyles from './style';
import { ETABLISHMENT_DATA } from '../../../../constants/etablishement';
import { EtablishmentDataType } from '../../../../types/establishment';
import { GET_ETABLISHMENT_DATA } from '../../../../graphql/common/etablishment/query';
import { CREATE_ESTABLISHMENT } from '../../../../graphql/etablishment/mutation';
import { GET_STATUS_BY_NAME } from '../../../../graphql/status/query';
import {
  CreateEstablishment,
  CreateEstablishmentVariables,
} from '../../../../graphql/etablishment/types/CreateEstablishment';
import {
  GetStatusByName,
  GetStatusByNameVariables,
} from '../../../../graphql/status/types/GetStatusByName';
import { SnackbarType } from '../../../../types/snackbar';
import { displaySnackbar } from '../../../../common/snackbar';
import { isValidPostalCode } from '../../../../common/validator';
import StepDisplayer from '../EstablishmentHome/StepDisplayer';
import StepperActions from '../EstablishmentHome/StepperActions';
import getStepComponents from '../EstablishmentHome/Steps';
import {
  getCachedBeneficiaryData,
  getCachedAdminData,
  getCachedWorkerData,
  mapToIds,
} from '../../../../common/utils';

interface EstablishmentChildProps {}

const STEPS = [
  "Choix <br/>d'organisation",
  'Informations <br/>de base',
  "Liste <br/>d'administrateur",
  "Liste <br/>d'intervenant",
  'Liste <br/>de bénéficiaire',
  "Choix de <br/>d'organisation",
];

const getStatus = (status: string) => {
  switch (status) {
    case 'ENTERPRISE':
      return 'Entreprise';
    case 'ASSOCIATION':
      return 'Association';
    case 'COLLECTIVITY':
      return 'Collectivité';
    case 'HOSPITAL':
      return 'Hôpital';
    default:
      return '-';
  }
};

const EstablishmentChild: FC<EstablishmentChildProps & RouteComponentProps> = (
  props,
) => {
  const { history } = props;
  const classes = useStyles();
  const client = useApolloClient();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [type, setType] = useState<string>('CHILD');
  const [inputData, setInputData] =
    useState<EtablishmentDataType>(ETABLISHMENT_DATA);

  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const createOnError = (error: ApolloError) => {
    snackbarData.type = 'ERROR';
    snackbarData.autoHideDuration = 5000;
    snackbarData.message =
      "Une erreur s'est produite lors de la création de l'établissement.";

    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      const { message } = error.graphQLErrors[0];
      switch (message) {
      }
    } else if (error.networkError) {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        'Erreur de réseau. Veuillez vérifier votre connexion internet.';
    }
    displaySnackbar(client, snackbarData);
  };

  const createOnComplete = (data: CreateEstablishment) => {
    if (data && data.createEtablishment) {
      client.writeQuery({
        query: GET_ETABLISHMENT_DATA,
        data: {
          etablishmentData: ETABLISHMENT_DATA,
        },
      });
      history.push('/etablissements/ajout?type=SUCCESS');
    }
  };

  const [createEstablishment, { loading }] = useMutation<
    CreateEstablishment,
    CreateEstablishmentVariables
  >(CREATE_ESTABLISHMENT, {
    onCompleted: createOnComplete,
    onError: createOnError,
  });

  const { data: etabType } = useQuery<
    GetStatusByName,
    GetStatusByNameVariables
  >(GET_STATUS_BY_NAME, { variables: { name: 'RESIDENCE_CHILD' } });

  const { data: etabFunding } = useQuery<
    GetStatusByName,
    GetStatusByNameVariables
  >(GET_STATUS_BY_NAME, { variables: { name: 'OTHERS' } });

  const isValid = (): boolean => {
    return !!(
      inputData.rcs.trim() &&
      inputData.name.trim() &&
      inputData.address.trim() &&
      inputData.city.trim() &&
      inputData.postalCode.trim() &&
      isValidPostalCode(inputData.postalCode)
    );
  };

  const isSelectedAdmin = () => {
    // return inputData.administratorIds.length > 0;
    return true;
  };

  const disableFinishAction = () => {
    return (
      inputData.residenceIds.length <= 0 ||
      (inputData.residenceIds.length === 1 && inputData.residenceIds[0] === 0)
    );
  };

  const isSelectedWorker = () => {
    // return inputData.workerIds.length > 0;
    return true;
  };
  const [fileLoading, setFileLoading] = useState<boolean>(false);

  const isSelectedBeneficiary = () => {
    // return inputData.beneficiaryIds.length > 0;
    return true;
  };

  const isValidNextButton = (): boolean => {
    switch (activeStep) {
      case 0:
        return isValid();
      case 1:
        return isSelectedAdmin();
      case 2:
        return isSelectedWorker();
      case 3:
        return isSelectedBeneficiary();
      default:
        return false;
    }
  };

  const data = client.readQuery({
    query: GET_ETABLISHMENT_DATA,
  });

  const handleNextStep = () => {
    if (activeStep === 0 || activeStep === 1) {
      client.writeQuery({
        query: GET_ETABLISHMENT_DATA,
        data: {
          etablishmentData: {
            ...inputData,
            administrators: getCachedAdminData(data),
            workers: getCachedWorkerData(data),
            beneficiaries: getCachedBeneficiaryData(data),
          },
        },
      });
    }
    setActiveStep(activeStep + 1);
  };

  const handleIgnoreStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleFinishCreation = () => {
    const data = client.readQuery({
      query: GET_ETABLISHMENT_DATA,
    });

    if (data) create(data);
  };

  const create = (data: any) => {
    if (data && data.etablishmentData) {
      const typeId =
        etabType && etabType.getStatusByName && etabType.getStatusByName.id;
      const fundingId =
        etabFunding &&
        etabFunding.getStatusByName &&
        etabFunding.getStatusByName.id;
      const {
        id,
        __typename,
        prestations,
        administrators,
        workers,
        beneficiaries,
        ...rest
      } = data.etablishmentData;
      const administratorIds = mapToIds(administrators);
      const workerIds = mapToIds(workers);
      const beneficiaryIds = mapToIds(beneficiaries);

      createEstablishment({
        variables: {
          input: {
            ...rest,
            prestations: prestations.join(','),
            etablishmentTypeId: typeId,
            etablishmentFundingId: fundingId,
            administratorIds,
            workerIds,
            beneficiaryIds,
          },
        },
        update: (cache, { data }) => {},
      });
    }
  };

  return (
    <Box className={classes.root}>
      <EstablishmentHeader title="Etablissement de type résidence fille ">
        <Stepper steps={STEPS} activeStep={activeStep} />
      </EstablishmentHeader>
      <StepDisplayer
        components={getStepComponents(inputData, setInputData, type)}
        activeStep={activeStep}
      />
      <EstablishmentFooter>
        <StepperActions
          activeStep={activeStep}
          stepsLength={STEPS.length}
          handlePrevStep={handlePrevStep}
          handleNextStep={handleNextStep}
          handleFinish={handleFinishCreation}
          handleIgnoreStep={handleIgnoreStep}
          isValidNextButton={isValidNextButton()}
          finalizeButtonDisable={
            disableFinishAction() || loading || fileLoading
          }
        />
      </EstablishmentFooter>
    </Box>
  );
};

export default withRouter(EstablishmentChild);
