import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down(400)]: {
        '&.MuiStepper-root': {
          padding: '24px 0',
        },
      },
    },
    step: {
      [theme.breakpoints.down(530)]: {
        paddingLeft: '4px !important',
        paddingRight: '4px !important',
      },
      [theme.breakpoints.down(480)]: {
        paddingLeft: '1px !important',
        paddingRight: '1px !important',
      },
    },
    label: {
      width: 150,
      '& .MuiStepIcon-active': {
        color: '#ED1C26',
      },
      [theme.breakpoints.down(768)]: {
        width: 100,
      },
      [theme.breakpoints.down(530)]: {
        '& .MuiStepLabel-label': {
          fontSize: 12,
        },
      },
      [theme.breakpoints.down(500)]: {
        width: 84,
      },
      [theme.breakpoints.down(416)]: {
        width: 70,
        '& .MuiStepLabel-label': {
          fontSize: 10,
        },
      },
      [theme.breakpoints.down(345)]: {
        width: 65,
      },
    },
  }),
);

export default useStyles;
