import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowIcon from '@material-ui/icons/ArrowBack';

import useStyles from './styles';

interface CustomFullscreenDialogProps {
  title: string;
  open: boolean;
  subTitle?: string;
  handleGoBack: () => void;
}

const CustomFullscreenDialog: FC<CustomFullscreenDialogProps> = (props) => {
  const { children, handleGoBack, title, open, subTitle } = props;
  const classes = useStyles();

  return (
    <Dialog fullScreen className={classes.rootDialog} open={open}>
      <Box className={classes.dialogTitle}>
        <Button
          color="default"
          variant="text"
          startIcon={<ArrowIcon />}
          onClick={handleGoBack}
          style={{ textTransform: 'initial' }}
        >
          Retour
        </Button>
        <Box width="100%" alignSelf="center">
          <Typography
            component="h2"
            variant="h4"
            color="textPrimary"
            style={{ textAlign: 'center', width: '100%' }}
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography
              component="p"
              color="textPrimary"
              style={{ textAlign: 'center', width: '100%' }}
            >
              {subTitle}
            </Typography>
          )}
        </Box>
      </Box>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CustomFullscreenDialog;
