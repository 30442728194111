import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 998,
      height: 88,
      borderRadius: 16,
      fontSize: 16,
      backgroundColor: '#F5F5F5',
      color: theme.palette.primary.main,
      boxShadow: 'none',

      '& button': {
        textTransform: 'none',
      },
      '& .MuiTabs-root': {
        height: '100%',
        padding: 15,
      },
      '& .MuiTabs-root div': {
        height: '100%',
      },
      '& .Mui-selected': {
        border: 0,
        backgroundColor: 'white',
        borderRadius: 9,
        userSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
  }),
);

export default useStyles;
