import React, { FC, useState } from 'react';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import { PlanningFilter } from '../../../../../types/filters';
import { PlanningFilterRecord } from '../interfaces/planning-filter.interfaces';
import { SelectOptionsType, SnackbarType } from '../../../../../types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Col from 'react-bootstrap/Col';
import { Paper, Typography } from '@material-ui/core';
import SearchFilter from '../../../../Common/SearchFilter';
import SearchInput from '../../../../Common/SearchInput';
import Row from 'react-bootstrap/cjs/Row';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FilterDate from '../../../../Common/SearchFilter/FilterDate';
import moment from 'moment';
import { handleInputChange } from 'react-select/src/utils';
import { MAX_DATE_PLANNING_INTERVAL } from '../../../../../constants/DateConfig';
import { useApolloClient } from '@apollo/react-hooks';
import { displaySnackbar } from '../../../../../common';
import { Button } from '@material-ui/core';
import Search from '@material-ui/icons/Search';

interface FilterControlsProps {
  filter: PlanningFilter;
  setFilter: (filter: PlanningFilter) => void;
  filterOptions: PlanningFilterRecord;
  onLoading: boolean;
}

interface InputValues {
  worker: string;
  beneficiary: string;
  keyword: string;
}

const DEFAULT_INPUT_VALUES: InputValues = {
  worker: '',
  beneficiary: '',
  keyword: '',
};

const FilterControls: FC<FilterControlsProps> = (props) => {
  const classes = useStyles();
  const { filter, setFilter, filterOptions, onLoading } = props;
  const [d1, setD1] = useState<Date | null>(filter.d1);
  const [d2, setD2] = useState<Date | null>(filter.d2);
  const { beneficiary, worker } = filterOptions;
  const [filterTmp, setFilterTmp] = useState<PlanningFilter>(filter);
  const client = useApolloClient();
  const [inputValues, setInputValues] =
    useState<InputValues>(DEFAULT_INPUT_VALUES);

  const handleChange = <T extends {}>(value: T | null, name: string) => {
    setFilterTmp({
      ...filterTmp,
      [name]: value,
    });
  };

  const handleAutocompleteInputChanges = <T extends {}>(
    value: T,
    name: string,
  ) => {
    setInputValues((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
    setFilterTmp({
      ...filterTmp,
      [name]: value,
    });
  };

  const onWorkerChanges = (
    event: React.ChangeEvent<{}>,
    value: SelectOptionsType | null,
  ) => handleChange<SelectOptionsType>(value, 'worker');

  const onBeneficiaryChanges = (
    event: React.ChangeEvent<{}>,
    value: SelectOptionsType | null,
  ) => handleChange<SelectOptionsType>(value, 'beneficiary');

  const optionSelectedComparison = (
    option: SelectOptionsType,
    value: SelectOptionsType | null,
  ) => !!value && option.value === value.value;

  const handleDateChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    let interval: number = 0;
    if (d1 === null || d2 === null) {
      const snackbarData: SnackbarType = {
        type: 'ERROR',
        message: "Les deux bornes de l'intervalle doivent être définies",
        isOpen: true,
      };
      displaySnackbar(client, snackbarData);
      return;
    }
    chekcDateInterval(name, value, d1, d2);
  };

  const chekcDateInterval = (name: string, value: any, d1: Date, d2: Date) => {
    let interval: number = 0;
    let ret: boolean = true;
    switch (name) {
      case 'd1':
        let utcDate1 = new Date(value);
        setD1(utcDate1);
        // interval = Math.abs(moment(value).diff(filterTmp.d2, 'days'));
        // if (interval > MAX_DATE_PLANNING_INTERVAL) {
        //   ret = false;
        //   const snackbarData: SnackbarType = {
        //     type: 'ERROR',
        //     message:
        //       "L'intervalle entre les deux dates doit être de 6 mois maximum",
        //     isOpen: true,
        //   };
        //   displaySnackbar(client, snackbarData);
        // }
        handleDateChange(utcDate1, d2);
        break;
      case 'd2':
        let utcDate2 = new Date(value);
        setD2(utcDate2);
        // interval = Math.abs(moment(value).diff(filterTmp.d1, 'days'));
        // if (interval > MAX_DATE_PLANNING_INTERVAL) {
        //   ret = false;
        //   const snackbarData: SnackbarType = {
        //     type: 'ERROR',
        //     message:
        //       "L'intervalle entre les deux dates doit être de 6 mois maximum",
        //     isOpen: true,
        //   };
        //   displaySnackbar(client, snackbarData);
        // }
        handleDateChange(d1, utcDate2);
        break;
    }

    return ret;
  };

  const handleDateChange = (date1: Date, date2: Date) => {
    setFilterTmp({
      ...filterTmp,
      d1: date1,
      d2: date2,
    });
  };

  const onClickSearch = () => {
    let isDateOk: boolean = false;
    if (filterTmp.d1 && filterTmp.d2) {
      isDateOk = chekcDateInterval(
        'd1',
        filterTmp.d1,
        filterTmp.d1,
        filterTmp.d2,
      );
    }
    if (isDateOk)
      setFilter({
        ...filterTmp,
      });
  };
  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap">
      <Paper className={classes.paper}>
        <Row>
          <Col md={3} sm={12} className={classes.column}>
            <SearchInput
              classNames={classes.unsetWidth}
              onChange={(e) => {
                handleAutocompleteInputChanges(e.target.value, 'keyword');
              }}
              value={inputValues.keyword}
              placeholder="Activité"
              onClickSearch={() => {
                handleChange(inputValues.keyword, 'keyword');
              }}
            />
          </Col>
          <Col md={3} sm={12} className={classes.column}>
            <FilterDate
              additionalClass={classes.unsetWidth}
              label="Période du"
              name="d1"
              value={(d1 && moment(d1).format('YYYY-MM-DD')) || ''}
              onChange={handleDateChanges}
              placeholder="Date de début"
            />
          </Col>
          <Col md={3} sm={12} className={classes.column}>
            <FilterDate
              additionalClass={classes.unsetWidth}
              label="Période au"
              name="d2"
              value={(d2 && moment(d2).format('YYYY-MM-DD')) || ''}
              onChange={handleDateChanges}
              placeholder="Date de fin"
            />
          </Col>
          {worker && (
            <Col md={3} sm={12} className={classes.column}>
              <Autocomplete
                options={worker}
                value={filterTmp.worker}
                onChange={onWorkerChanges}
                inputValue={inputValues.worker}
                onInputChange={(e, value) =>
                  handleAutocompleteInputChanges(value, 'worker')
                }
                getOptionLabel={(options: SelectOptionsType) => options.label}
                getOptionSelected={optionSelectedComparison}
                className={`${classes.formControl} ${classes.unsetWidth}`}
                noOptionsText={'Aucune option'}
                style={{ minWidth: 250 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Intervenant"
                    name="worker"
                    placeholder="intervenants"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Col>
          )}
          {beneficiary && (
            <Col md={3} sm={12} className={classes.column}>
              <Autocomplete
                options={beneficiary}
                value={filterTmp.beneficiary}
                onChange={onBeneficiaryChanges}
                inputValue={inputValues.beneficiary}
                onInputChange={(e, value) =>
                  handleAutocompleteInputChanges(value, 'beneficiary')
                }
                getOptionLabel={(options: SelectOptionsType) => options.label}
                getOptionSelected={optionSelectedComparison}
                className={`${classes.formControl} ${classes.unsetWidth}`}
                noOptionsText={'Aucune option'}
                style={{ minWidth: 250 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bénéficiaire"
                    name="beneficiary"
                    placeholder="bénéficiaires"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Col>
          )}
          <Col>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<Search />}
              onClick={onClickSearch}
              disabled={onLoading}
            >
              Rechercher
            </Button>
          </Col>
        </Row>
      </Paper>
    </Box>
  );
};

export default FilterControls;
