import { EstablishmentDataType } from '../types/establishment';
import {
  EstablishmentValidationError,
  EstablishmentValidationMessages,
} from '../types/establishment-validator.types';

export const EMPTY_STRING_ERRORS: Partial<Record<
  keyof EstablishmentDataType,
  EstablishmentValidationError
>> = {
  name: EstablishmentValidationError.EMPTY_NAME,
  rcs: EstablishmentValidationError.EMPTY_RCS,
  address: EstablishmentValidationError.EMPTY_ADRESS,
  postalCode: EstablishmentValidationError.EMPTY_POSTAL_CODE,
  city: EstablishmentValidationError.EMPTY_CITY,
};

export const EXISTENCE_ERRORS: Partial<Record<
  keyof EstablishmentDataType,
  EstablishmentValidationError
>> = {
  establishmentType: EstablishmentValidationError.NO_ESTABLISHMENT_TYPE,
};

export const INVALID_STRING_ERRORS: Partial<Record<
  keyof EstablishmentDataType,
  EstablishmentValidationError
>> = {
  name: EstablishmentValidationError.INVALID_NAME,
  rcs: EstablishmentValidationError.INVALID_RCS,
  address: EstablishmentValidationError.INVALID_ADRESS,
  postalCode: EstablishmentValidationError.INVALID_POSTAL_CODE,
  city: EstablishmentValidationError.INVALID_CITY,
};

export const EMPTY_LIST_ERRORS: Partial<Record<
  keyof EstablishmentDataType,
  EstablishmentValidationError
>> = {
  administratorIds: EstablishmentValidationError.NO_ADMINISTRATOR,
  beneficiaryIds: EstablishmentValidationError.NO_BENEFICIARY,
  workerIds: EstablishmentValidationError.NO_WORKER,
  residenceIds: EstablishmentValidationError.NO_RESIDENCE_ID,
};

export const ESTABLISHMENT_VALIDATION_MESSAGES: EstablishmentValidationMessages = {
  EMPTY_NAME: `Raison social de l'établissement manquant`,
  EMPTY_ADRESS: `Adresse de l'établissement manquant`,
  EMPTY_CITY: `Nom de la ville manquant`,
  EMPTY_POSTAL_CODE: `Code postal manquant`,
  EMPTY_RCS: `RCS manquant`,
  INVALID_NAME: `Nom de l'établissement invalide`,
  INVALID_ADRESS: `Adresse de l'établissement invalide`,
  INVALID_CITY: `Nom de la ville invalide`,
  INVALID_POSTAL_CODE: `Code postal invalide`,
  INVALID_RCS: `RCS invalide`,
  NO_ADMINISTRATOR: `L'établissement n'a aucun administrateur`,
  NO_BENEFICIARY: `L'établissement n'a aucun bénéficiaire`,
  NO_RESIDENCE_ID: `L'établissement n'est rattaché à aucune établissement mère`,
  NO_WORKER: `L'établissement n'a aucun administrateur`,
  NO_ESTABLISHMENT_TYPE: `Type d'établissement non définit`,
};
