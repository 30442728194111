import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Logo from '../../../..//assets/images/logo-neo.png';

interface EstablishmentEditorWrapperProps {
  title?: string;
  onClose?: () => void;
}

const EstablishmentEditorWrapper: FC<EstablishmentEditorWrapperProps> = (
  props,
) => {
  const { children, title = ``, onClose = () => {} } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolBar}>
          <Box className={classes.logo}>
            <img src={Logo} alt="Logo" className={classes.img} />
          </Box>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box className={classes.container}>{children}</Box>
    </Box>
  );
};

export default EstablishmentEditorWrapper;
