import React, { FC } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import useStyles from './styles';
import { useViewport } from '../../../../hooks';
import { Tab as TabType } from '../../../../types/tabs';
import { EstablishmentTypeType } from '../../../../types/establishment';

interface EstablishmentDetailsTabsProps {
  tabs: TabType[];
  value: string | number;
  type: EstablishmentTypeType | string;
  handleChange: (
    event: React.ChangeEvent<{}>,
    newValue: number | string,
  ) => void;
}

const BREAKPOINT = 768;

const EstablishmentDetailsTabs: FC<EstablishmentDetailsTabsProps> = (props) => {
  const classes = useStyles();
  const { tabs, value, handleChange, type } = props;
  const { width } = useViewport();

  return (
    <AppBar className={classes.root} position="relative">
      <Tabs
        variant={width > BREAKPOINT ? 'fullWidth' : 'scrollable'}
        scrollButtons="auto"
        value={value}
        onChange={handleChange}
      >
        {tabs.map((tab: TabType, index: number) => (
          <Tab label={tab.label} key={tab.index} />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default EstablishmentDetailsTabs;
