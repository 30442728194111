import React, { FC } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import useStyles from './styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';

interface CustomSwitchProps {
  label: string;
  labelChecked: string;
  labelUnchecked: string;
  name: string;
  checked: boolean;
  labelPlacement?: 'bottom' | 'top' | 'end' | 'start' | undefined;
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end'
    | 'left'
    | 'right';
  onChange: (event: React.ChangeEvent<any>) => void;
}

const CustomSwitch: FC<CustomSwitchProps> = (props) => {
  const {
    onChange,
    label,
    name,
    checked,
    labelChecked,
    labelUnchecked,
    labelPlacement,
    justify,
  } = props;
  const classes = useStyles();

  return (
    <FormGroup className={`CustomSwitch-root ${classes.formGroup}`}>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <FormControlLabel
        className={classes.formControlLabel}
        style={{
          justifyContent: justify || 'space-between',
        }}
        label={checked ? labelChecked : labelUnchecked}
        labelPlacement={labelPlacement || 'start'}
        control={
          <Switch
            name={name}
            color="primary"
            checked={checked}
            className={classes.switch}
            onChange={onChange}
          />
        }
      />
    </FormGroup>
  );
};

export default CustomSwitch;
