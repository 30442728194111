import {
  EtablishmentDataType,
  EtablishmentRootDataType,
  EstablishmentType,
} from '../types/establishment';

export const ETABLISHMENT_DATA: EtablishmentDataType = {
  __typename: 'EtablishmentData',
  id: 0,
  name: '',
  rcs: '',
  address: '',
  postalCode: '',
  city: '',
  effective: 0,
  etablishmentObject: '',
  logo: '',
  enabled: false,
  prestations: [],
  picture: [],
  base64logo: '',
  etablishmentTypeId: 0,
  etablishmentStatusId: 0,
  administratorIds: [],
  workerIds: [],
  beneficiaryIds: [],
  residenceIds: [],
  workers: [],
  administrators: [],
  beneficiaries: [],
};

export const ETABLISHMENT_ROOT_DATA: EtablishmentRootDataType = {
  __typename: '',
  id: 0,
  logo: '',
  name: '',
  address: '',
  postalCode: '',
  etablishmentObject: '',
  city: '',
};

export const ESTABLISHMENT_EDITOR_TITLE: Record<EstablishmentType, string> = {
  DOMICILE: 'Etablissement DOMICILE',
  HOME: 'Etablissement DOMICILE',
  RESIDENCE: 'Etablissement RÉSIDENCE',
  RESIDENCE_CHILD: `Choix type d'établissement`,
  RESIDENCE_ROOT: 'ORGANISATION',
  RESIDENCE_PARENT: 'ORGANISATION',
};
