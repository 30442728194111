export type ROLE = 'SUPER_ADMIN' | 'ADMIN' | 'WORKER' | 'BENEFICIARY';

const UserRoles = {
  SUPER_ADMIN: 'Administrateur Neoptim',
  ADMIN: 'Administrateur',
  WORKER: 'Intervenant',
  BENEFICIARY: 'Bénéficiaire',
};

export const getRole = (role: ROLE | string): string => {
  if (!role) return '';
  return UserRoles[role as ROLE];
};
