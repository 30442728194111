import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',

      [theme.breakpoints.down(785)]: {
        flexWrap: 'wrap',
      },
    },
    button: {
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
      marginRight: theme.spacing(2),
    },
    white: {
      color: theme.palette.common.white,
    },
    toolBox: {
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
    searchFilter: {
      [theme.breakpoints.down(785)]: {
        marginTop: theme.spacing(2),
        width: '100%',

        '& .MuiAutocomplete-root': {
          maxWidth: '50% !important',
        },
      },
      [theme.breakpoints.down(590)]: {
        flexDirection: 'column',

        '& .MuiAutocomplete-root': {
          maxWidth: '100% !important',
          marginRight: '0 !important',
        },
      },
    },
  }),
);

export default useStyles;
