import React, { FC, useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import useStyles from './styles';
import { PlanningDataType } from '../../../../../../types/planning';
import ChooseByUnitLife from './ChooseByUnitLife';
import ChooseByList from './ChooseByList';
import { EstablishmentContext } from '../../../../../Context';

type ChoiceType = 'LIST' | 'UNIT_LIFE';

interface BeneficiaryChoiceProps {
  inputData: PlanningDataType;
  setInputData: React.Dispatch<React.SetStateAction<any>>;
}

const BeneficiaryChoice: FC<BeneficiaryChoiceProps> = (props) => {
  const classes = useStyles();
  const { inputData, setInputData } = props;
  const [choice, setChoice] = useState<ChoiceType>('LIST');
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const { establishmentType } = useContext(EstablishmentContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChoice((event.target as HTMLInputElement).value as ChoiceType);
  };

  const getChoice = (type: ChoiceType) => {
    const componentList: Record<ChoiceType, any> = {
      LIST: (
        <ChooseByList
          inputData={inputData}
          setInputData={setInputData}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      ),
      UNIT_LIFE: (
        <ChooseByUnitLife
          inputData={inputData}
          setInputData={setInputData}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      ),
    };
    return componentList[type];
  };

  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        marginBottom="16px"
      >
        <FormControl>
          <RadioGroup
            className={classes.inlineGroup}
            name="choice"
            value={choice}
            onChange={handleChange}
          >
            <FormControlLabel
              label="Choix par liste"
              value="LIST"
              control={<Radio color="primary" />}
              style={{
                display:
                  establishmentType && establishmentType !== 'RESIDENCE'
                    ? 'none'
                    : 'flex',
              }}
            />
            {establishmentType && establishmentType === 'RESIDENCE' && (
              <FormControlLabel
                label="Choix par unité de vie"
                value="UNIT_LIFE"
                control={<Radio color="primary" />}
              />
            )}
          </RadioGroup>
        </FormControl>
      </Box>
      {getChoice(choice)}
    </Box>
  );
};

export default BeneficiaryChoice;
