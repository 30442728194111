import React, { FC } from 'react';
import { useLocation, withRouter, RouteComponentProps } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import useStyles from './styles';
import useQueryString from '../../../hooks/useQueryString';
import CustomFullscreenDialog from '../../Common/CustomFullscreenDialog';
import InterventionReportInfo from './InterventionReportInfo';
import InterventionReportContent from './InterventionReportContent';
import {
  EstablishmentMin,
  InterventionReportData,
  PlanninChildData,
} from '../../../types/intervention-report.types';
import { useState } from 'react';

interface InterventionReportDetailsProps {
  interventionReport: InterventionReportData;
  open: boolean;
  setOpen: (value: boolean) => void;
  onEdit?: (item: PlanninChildData) => void;
}

const InterventionReportDetails: FC<
  InterventionReportDetailsProps & RouteComponentProps
> = (props) => {
  const classes = useStyles();
  const { history, open, setOpen, interventionReport, onEdit } = props;
  const { pathname } = useLocation();
  const queryString = useQueryString();
  const { establishment } = interventionReport;

  const [establismentData, setEstablismentData] =
    useState<EstablishmentMin>(establishment);

  const interventionReportId = queryString.hasOwnProperty(
    'rapport_intervention',
  )
    ? queryString['rapport_intervention']
    : 0;

  const handleGoBack = () => {
    const tabId = queryString.hasOwnProperty('tab') ? queryString['tab'] : 0;
    const workerId = queryString.hasOwnProperty('intervenant_id')
      ? queryString['intervenant_id']
      : 0;

    history.push({
      pathname,
      search: `?tab=${tabId}&intervenant_id=${workerId}`,
    });
    setOpen(false);
  };

  const edit = () => {
    onEdit && onEdit(interventionReport as PlanninChildData);
  };

  const isOpenDialog = () => {
    return (interventionReportId && interventionReportId > 0) || open;
  };

  return (
    <CustomFullscreenDialog
      title="Détails de rapport d'intervention"
      handleGoBack={handleGoBack}
      open={isOpenDialog()}
    >
      <Box className={classes.details}>
        <InterventionReportInfo
          interventionReportId={interventionReportId}
          establishment={establishment}
          onEdit={edit}
        />
        <InterventionReportContent interventionReport={interventionReport} />
      </Box>
    </CustomFullscreenDialog>
  );
};

export default withRouter(InterventionReportDetails);
