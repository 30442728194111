import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .row': {
        '& .col-xl-3, .col-lg-4, .col-md-6, .col-sm-6, .col-12': {
          paddingLeft: 0,
        },
        [theme.breakpoints.down(1199)]: {
          '& .col-12': {
            paddingTop: 16,
          },
        },
      },
      '& .MuiTableCell-stickyHeader': {
        position: 'relative',
      }
    },
    button: {
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    downButton: {
      color: '#fff',
      textDecoration: 'none',
    },
    lodingProgress:{
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -10,
    },
    white: {
      color: theme.palette.common.white,
    },
    excelIcon: {
      width: 24,
      height: 24,
    },
    pdfIcon: {
      width: 24,
      height: 24,

      '& > img': {
        objectFit: 'contain !important'
      }
    },
    margin: {
      marginLeft: 8,
      marginRight: 8,
    },
    pdfHeaderView: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '35px',
    },
    pdfLogo: {
      width: '150px',
      height: '50px',
      justifyContent: 'center',
      margin: 'auto',
    },
    psdTitle: {
      textAlign: 'center',
      margin: '25px 0px 25px',
      fontSize: 15,
    },
  }),
);

export default useStyles;
