import { ApolloClient } from 'apollo-boost';
import {
  EstablishmentDataType,
  EstablishmentType,
} from '../../../../../types/establishment';
import { EstablishmentValidationError } from '../../../../../types/establishment-validator.types';
import {
  getDefaultEstablishmentValidators,
  getEstablishmentDefaultErrorMessage,
} from '../../../../../utils/establishment-validator.utils';
import { showSnackbar } from '../../../../../utils/snackbar.util';
import { validate } from '../../../../../utils/universal-validator.utils';

export const handleEstablishmentCreateValidation = (
  establishment: EstablishmentDataType,
  client: ApolloClient<object>,
  establishmentType: any,
  onSuccess: () => void,
) => {
  const establishmentValidationState = validate<
    EstablishmentDataType,
    EstablishmentValidationError
  >(establishment, getDefaultEstablishmentValidators(establishmentType));
  if (establishmentValidationState.valid) {
    onSuccess();
  } else if (establishmentValidationState.error) {
    const errorMessage: string = getEstablishmentDefaultErrorMessage(
      establishmentValidationState.error,
    );
    showSnackbar({
      type: 'ERROR',
      message: errorMessage,
      client,
    });
  }
};
