import gql from 'graphql-tag';

export const GET_PRESIGNED_URL = gql`
    mutation GetPresignedUrl($fileName: String!, $folder: String!){
        uploadFile(fileName: $fileName, folder: $folder){
            id
            url 
            uuid
        }
    }
`;