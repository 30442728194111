import { ApolloError } from 'apollo-boost';
import { MutationHookOptions } from '@apollo/react-hooks';
import { showSnackbar } from '../../../../../../utils/snackbar.util';
import {
  OnErrorFunc,
  OnCompletedFunc,
  UpdateUserVariables,
  UpdateUser,
} from '../interfaces/UserEditList.interfaces';
import {
  OnUpdateUserMutationErrorArgs,
  OnUpdateUserMutationCompletedArgs,
  UpdateUserMutationOptionsArgs,
} from '../interfaces/UpdateUserMutationOptions.interfaces';
import { buildUpdateUsersMessage } from '../constants/UserList.constants';
import { ShowSnackBarArgs } from '../../../../../../types/snackbar';

const onUpdateUserMutationError: (
  args: OnUpdateUserMutationErrorArgs,
) => OnErrorFunc = ({ client }) => (apolloError: ApolloError) => {
  const [graphQLError] = apolloError.graphQLErrors;
  let showSnackBarArgs: ShowSnackBarArgs = {
    type: 'ERROR',
    client,
    message: `Une erreur s'est produite lors de l'enregistrement`,
  };
  if (graphQLError.message === 'EMAIL_ALREADY_USED') {
    showSnackBarArgs.message = `L'adresse e-mail est déjà utilisée`;
  }
  showSnackbar(showSnackBarArgs);
};

const onUpdateUserMutationCompleted: (
  args: OnUpdateUserMutationCompletedArgs,
) => OnCompletedFunc = ({ client, userRole }) => () => {
  showSnackbar({
    client,
    message: buildUpdateUsersMessage(userRole),
    type: 'SUCCESS',
  });
};

export const updateUserMutationOptions: (
  args: UpdateUserMutationOptionsArgs,
) => MutationHookOptions<UpdateUser, UpdateUserVariables> = ({
  client,
  userRole,
}) => ({
  onError: onUpdateUserMutationError({ client, userRole }),
  onCompleted: onUpdateUserMutationCompleted({ client, userRole }),
});
