import React, { FC, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';

import useStyles from './styles';
import { useMutation } from '@apollo/react-hooks';
import DocsImage from '../../../../../../assets/images/electro_house.png';
import Dropzone from '../../../../../Common/Dropzone';
import { GET_PRESIGNED_URL } from '../../../../../../graphql/fileUpload/mutations';
import { UPDATE_DOCUMENT } from '../../../../../../graphql/document/mutation';

interface DocumentViewerProps {
  path: string;
  id: number;
}

const DocumentViewer: FC<DocumentViewerProps> = (props) => {
  const classes = useStyles();
  const { path, id } = props;
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

  const [getUrl] = useMutation(GET_PRESIGNED_URL);

  const [updateDocument] = useMutation(UPDATE_DOCUMENT);

  const uploadDocument = (file: File) => {
    getUrl({ variables: { fileName: file.name, folder: 'document' } }).then(
      (data) => {
        if (data) {
          const { uploadFile } = data.data;
          const { url, uuid } = uploadFile;

          fetch(url, {
            method: 'PUT',
            body: file,
          })
            .then((res) => {
              updateDocument({ variables: { input: { id, path: url } } });
            })
            .catch((error) => {
              console.log('error : ', error);
            });
        }
      },
    );
  };

  useEffect(() => {
    if (selectedFiles[0]) {
      uploadDocument(selectedFiles[0]);
    }
  }, [selectedFiles]);

  const onHandleDelete = () => {
    updateDocument({ variables: { input: { id, path: '' } } });
  };

  return (
    <Box className={classes.root}>
      <Dropzone
        accept=".doc, .pdf, .txt, .docx, .xlsx"
        multiple={false}
        pictureUrl={path || ''}
        base64Img={''}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        enablePreview
        height={160}
        isDownloadableDocument={true}
        onDelete={onHandleDelete}
        showAction={true}
      />
    </Box>
  );
};

export default DocumentViewer;
