import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingRight: 0,
      paddingLeft: 0,
      '& .row': {
        width: '100%',
        marginRight: 0,
        marginLeft: 0,
      },
      marginBottom: theme.spacing(1),
    },
    title: {
      fontSize: '1.5rem',
      marginRight: 24,
      color: theme.palette.primary.main,
      [theme.breakpoints.down(900)]: {
        fontSize: '1.12rem',
        marginRight: 12,
      },
    },
    subTitle: {
      fontSize: '0.875rem',
      color: theme.palette.text.primary,
      marginRight: 20,
      [theme.breakpoints.down(400)]: {
        marginRight: 12,
        fontSize: '0.75rem',
      },
    },
    button: {
      '& .MuiButton-label': {
        color: theme.palette.common.white,
        textTransform: 'initial',
      },
    },
    colSpacing: {
      padding: 0,
      
      [theme.breakpoints.down(991)]: {
        marginBottom: 16,
      },
    },
    leftCol: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },
    actionTableToolbar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      
      [theme.breakpoints.down(991)]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    '& grouped-action': {
      singleValue: (base) => ({ ...base, color: '#424242' }),
      valueContainer: (base) => ({
        ...base,
        color: '#424242',
        width: '100%',
        fontSize: '14px',
        backgroundColor: '#fff',
      }),
    },
    marginRight: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down(991)]: {
        marginRight: 0,
        marginBottom: theme.spacing(2),
        width: '100%',
      },
    },
    row: {
      [theme.breakpoints.down(1500)]: {
        flexDirection: 'column',
      },
    },
    buttons: {},
    actions: {
      width: '100%',
      marginTop: theme.spacing(2),
      alignSelf: 'flex-end',

      [theme.breakpoints.down(1362)]: {
        maxWidth: '100%',
      },
    },
  }),
);

export default useStyles;
