import React, { useState, useEffect, FC } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import useStyles from './styles';
import CustomTable from '../../../Common/CustomTable';
import { HeadCell } from '../../../../types/table';
import {
  GET_UNIT_LIFES,
  GET_UNIT_LIFES_BY_ETABLISHMENT,
} from '../../../../graphql/unit/query';
import {
  UnitLifes,
  UnitLifes_unitLifes,
} from '../../../../graphql/unit/types/UnitLifes';
import { DATE_FORMAT } from '../../../../constants/format';
import { showSnackbar } from '../../../../utils/snackbar.util';
import {
  useCreateUnitLife,
  useDeleteUnitLife,
  useUpdateUnitLife,
} from './UnitLifeAdd/hooks/unit-life.hooks';
import {
  onCreateUnitLifeComplete,
  onCreateUnitLifeError,
} from './UnitLifeAdd/utils/create-unit-life.utils';
import UnitLifeEditor from './UnitLifeAdd/component/UnitLifeEditor';
import { LifeUnit } from '../../../../types/unit';
import {
  onDeleteUnitLifeComplete,
  onDeleteUnitLifeError,
} from './UnitLifeAdd/utils/delete-unit-life.utils';
import {
  onUpdateUnitLifeComplete,
  onUpdateUnitLifeError,
} from './UnitLifeAdd/utils/update-unit-life.utils';
import {
  getLifeUnitErrorMessage,
  validateLifeUnit,
} from './UnitLifeAdd/utils/life-unit.validator';
import ConfirmationDialog from '../../../Common/ConfirmationDialog';
import { CreateUnitLife } from '../../../../graphql/unit/types/CreateUnitLife';
import { UnitLifeByEstablishment } from '../../../../graphql/unit/types/UnitLifeByEstablishment';

export const DEFAULT_LIFE_UNIT: LifeUnit = {
  name: '',
  createdAt: '',
  establishmentId: 0,
};

interface UnitLifeProps {
  establishmentId: number;
}

const UnitLife: FC<UnitLifeProps> = (props) => {
  const classes = useStyles();
  const { establishmentId } = props;
  const client = useApolloClient();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [unitData, setUnitData] = useState<LifeUnit[]>([]);
  const [lifeUnitInput, setLifeUnitInput] = useState<LifeUnit>(
    DEFAULT_LIFE_UNIT,
  );
  const [confimationDialogOpen, setOpenConfirmationDialog] = useState<boolean>(
    false,
  );
  const [deleteId, setDeleteId] = useState<number>(0);
  const { data, loading: getUnitLifesLoading, refetch } = useQuery<
    UnitLifeByEstablishment
  >(GET_UNIT_LIFES_BY_ETABLISHMENT, {
    variables: { establishmentId: establishmentId },
  });

  const [deleteUnitLife] = useDeleteUnitLife({
    options: {
      onError: onDeleteUnitLifeError({ client }),
      onCompleted: onDeleteUnitLifeComplete({ client }),
    },
  });

  const [createUnitLife, createUnitLifeLoading] = useCreateUnitLife({
    options: {
      onCompleted: (data: CreateUnitLife) => {
        refetch();
        onCreateUnitLifeComplete({ client })(data);
      },
      onError: onCreateUnitLifeError({ client }),
    },
  });

  const [updateUnitLife, updateUnitLifeLoading] = useUpdateUnitLife({
    options: {
      onCompleted: onUpdateUnitLifeComplete({ client }),
      onError: onUpdateUnitLifeError({ client }),
    },
  });

  useEffect(() => {
    if (data && data.unitLifeByEstablishment) {
      setUnitData(
        data.unitLifeByEstablishment.map((lifeUnit) => {
          const { __typename, ...rest } = lifeUnit as UnitLifes_unitLifes;
          return rest;
        }),
      );
    }
  }, [data]);

  const onUnitLifeDelete = (id: number) => {
    deleteUnitLife(id).then((data) => {
      if (!data.errors) {
        setOpenConfirmationDialog(false);
        refetch();
      }
    });
  };

  const onSubmit = (lifeUnit: LifeUnit) => {
    const submitData = {
      ...lifeUnit,
      establishmentId,
    };
    const validationState = validateLifeUnit(submitData);
    const mutationFinish = (data: any) => {
      if (data) {
        setOpenDialog(false);
        setLifeUnitInput({ ...DEFAULT_LIFE_UNIT, establishmentId });
      }
    };
    if (validationState.valid) {
      if (submitData.id) {
        updateUnitLife(submitData).then(mutationFinish);
      } else {
        createUnitLife(submitData).then(mutationFinish);
      }
    } else if (validationState.error) {
      const message = getLifeUnitErrorMessage(validationState.error);
      showSnackbar({
        client,
        message,
        type: 'ERROR',
      });
    }
  };

  const onEdit = (lifeUnit: LifeUnit) => {
    setLifeUnitInput({ ...lifeUnit, establishmentId });
    setOpenDialog(true);
  };

  const columns: HeadCell[] = [
    {
      name: 'createdAt',
      label: 'Date',
      disablePadding: false,
      minWidth: 100,
      renderer: (value: any) => {
        return (
          (value &&
            value.createdAt &&
            moment(value.createdAt).format(DATE_FORMAT)) ||
          '-'
        );
      },
    },
    {
      name: 'name',
      label: "Nom de l'unité de vie",
      disablePadding: false,
      minWidth: 100,
      renderer: (value: any) => {
        return (value && value.name) || '-';
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex" justifyContent="space-around" maxWidth={300}>
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setDeleteId(value.id);
                setOpenConfirmationDialog(true);
              }}
              className={classes.margin}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifier" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onEdit({ ...value });
              }}
              className={classes.margin}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const toolbarButtons = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="admin-add"
      style={{ minWidth: 218 }}
      startIcon={<AddBoxIcon />}
      onClick={handleOpenDialog}
    >
      Ajouter une unité de vie
    </Button>,
  ];

  const submitButtonLabel = lifeUnitInput.id ? 'Enregistrer' : 'Ajouter';
  const dialogTitle = lifeUnitInput.id
    ? `Modification d'une unité de vie`
    : `Ajout d'une unité de vie`;

  return (
    <Box className={classes.root}>
      <CustomTable
        toolbar
        selectable={false}
        useFlexToolbar
        maxWidth={1348}
        data={unitData}
        columns={columns}
        toolbarButtons={toolbarButtons}
        hideSelectedText
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        loadingData={getUnitLifesLoading}
      />
      <UnitLifeEditor
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setLifeUnitInput({ ...DEFAULT_LIFE_UNIT, establishmentId });
        }}
        title={dialogTitle}
        lifeUnit={lifeUnitInput}
        onSubmit={onSubmit}
        loading={createUnitLifeLoading || updateUnitLifeLoading}
        buttonLabel={submitButtonLabel}
      />
      <ConfirmationDialog
        open={confimationDialogOpen}
        setOpen={setOpenConfirmationDialog}
        message="Voulez vous vraiment supprimer cette unité de vie?"
        title="Suppression"
        onConfirm={() => onUnitLifeDelete(deleteId)}
      />
    </Box>
  );
};

export default UnitLife;
