import gql from 'graphql-tag';

export const UNIT_LIFE_FRAGMENT = gql`
  fragment UnitLifeInfo on UnitLife {
    id
    name
    establishmentId
    createdAt
  }
`;
