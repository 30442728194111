import { UserRole } from '../../../../../../types/user';
import { type } from 'os';

export const USER_LIST_LABEL: Record<UserRole, string> = {
  administrator: 'administrateur',
  beneficiary: 'bénéficiaire',
  worker: 'intervenant',
};

export const UPDATE_USER_DATA_KEY: Record<UserRole, string> = {
  administrator: 'updateAdmin',
  beneficiary: 'updateBeneficiary',
  worker: 'updateWorker',
};

export const CREATE_USER_DATA_KEY: Record<UserRole, string> = {
  administrator: 'createAdmin',
  beneficiary: 'createBeneficiary',
  worker: 'createWorker',
};

export const CREATE_USERS_DATA_KEY: Record<UserRole, string> = {
  administrator: 'createAdmins',
  beneficiary: 'createBeneficiaries',
  worker: 'createWorkers',
};

const USER_STATUS_LABEL: Record<'true' | 'false', string> = {
  false: 'désactivé',
  true: 'activé',
};

const USERS_STATUS_LABEL: Record<'true' | 'false', string> = {
  false: 'désactivés',
  true: 'activés',
};

const booleanToString = (b: boolean): 'true' | 'false' =>
  b ? 'true' : 'false';

type UpdateUserStatusMessage = (enabled: boolean, userRole: UserRole) => string;
type UpdateUsersStatusMessage = UpdateUserStatusMessage;
type UpdateUserMessage = (userRole: UserRole) => string;

export const buildUpdateUsersMessage: UpdateUserMessage = (
  userRole: UserRole,
) =>
  ({
    administrator: `L'administrateur a été mis à jour.`,
    beneficiary: `Le bénéficiaire a été mis à jour.`,
    worker: `L'intervenant a été mis à jour.`,
  }[userRole]);

export const buildUpdateUserStatusMessage: UpdateUserStatusMessage = (
  enabled: boolean,
  userRole: UserRole,
) =>
  ({
    administrator: `L'administrateur a été ${
      USER_STATUS_LABEL[booleanToString(enabled)]
    }`,
    beneficiary: `Le bénéficiaire a été ${
      USER_STATUS_LABEL[booleanToString(enabled)]
    }`,
    worker: `L'intervenant a été ${
      USER_STATUS_LABEL[booleanToString(enabled)]
    }`,
  }[userRole]);

export const buildUpdateUsersStatusMessage: UpdateUsersStatusMessage = (
  enabled: boolean,
  userRole: UserRole,
) =>
  ({
    administrator: `Les administrateurs ont été ${
      USERS_STATUS_LABEL[booleanToString(enabled)]
    }`,
    beneficiary: `Les bénéficiaires ont été ${
      USERS_STATUS_LABEL[booleanToString(enabled)]
    }`,
    worker: `Les intervenants ont été ${
      USERS_STATUS_LABEL[booleanToString(enabled)]
    }`,
  }[userRole]);
