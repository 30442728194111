import { FormInputDataState, User, UserRole } from '../types/user';

export const getPartialUser = (
  user: User,
  unwantedKeys: (keyof User)[] = [],
): Partial<User> =>
  Object.entries(user).reduce((res: Partial<User>, [key, value]) => {
    if (unwantedKeys.includes(key as keyof User)) {
      return res;
    }
    return {
      ...res,
      [key]: value,
    };
  }, {});

export const getPartialUsers = (
  users: User[],
  unwantedKeys: (keyof User)[] = [],
): Partial<User>[] => users.map((user) => getPartialUser(user, unwantedKeys));

export const formInputDataStateToUser = (
  data: FormInputDataState,
  userRole: UserRole,
): Partial<User> => {
  const { guardianEnabled, guardianName } = data;
  const guardian =
    userRole === 'beneficiary'
      ? {
          guardianEnabled,
          guardianName,
          signature: data.signature,
          disabilityRecognition: data.disabilityRecognition,
          birthDate: data.birthDate,
        }
      : {};
  return {
    email: data.email,
    firstName: data.name,
    enabled: !!data.status,
    lastName: data.lastName,
    unitLifeId: data.unitLifeId,
    signature: data.signature,
    userFunction: data.userFunction,
    establishmentId: data.establishmentId,
    phone: data.phone,
    mobilePhone: data.mobilePhone,
    birthDate: data.birthDate,
    disabilityRecognition: data.disabilityRecognition,
    userRole,
    ...guardian,
  };
};
