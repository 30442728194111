import { useMutation } from '@apollo/react-hooks';
import { GET_PRESIGNED_URL } from '../graphql/fileUpload/mutations';
import {
  GetPresignedUrl,
  GetPresignedUrlVariables,
} from '../graphql/fileUpload/types/GetPresignedUrl';

export const useUploadSignature = () => {
  const [registerURL] = useMutation<GetPresignedUrl, GetPresignedUrlVariables>(
    GET_PRESIGNED_URL,
  );
  return async (signatureFile: any): Promise<string | null> => {
    const { data } = await registerURL({
      variables: {
        fileName: signatureFile.name,
        folder: 'signature',
      },
    });
    const url = data && data.uploadFile && data.uploadFile.url;
    if (!url) {
      return null;
    }
    const f = await fetch(url, {
      method: 'PUT',
      body: signatureFile,
    });
    return f ? url : null;
  };
};
