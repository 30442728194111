import React, { useState, FormEvent, ChangeEvent } from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-boost';
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';

import { isValidEmail } from '../../../common/validator';
import { isAuthenticated } from '../../../services/localStorage';
import { displaySnackbar } from '../../../common/snackbar';
import { SnackbarType } from '../../../types';
import { SEND_RESET_PASSWORD_LINK } from '../../../graphql/user/mutation';
import { SendResetPasswordLink } from '../../../graphql/user/types/SendResetPasswordLink';
import { SendResetPasswordLinkVariables } from '../../../graphql/user/types/SendResetPasswordLink';
import useStyles from './styles';
import logo from '../../../assets/images/logo-neoptim.png';

interface ForgotPasswordProps {}

/**
 * ForgotPassword Component
 * @param props ForgotPasswordProps
 */
const ForgotPassword = (props: ForgotPasswordProps & RouteComponentProps) => {
  const { history } = props;
  const classes = useStyles();
  const client = useApolloClient();
  const [email, setEmail] = useState<string>('');

  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const [sendLink, { loading }] = useMutation<
    SendResetPasswordLink,
    SendResetPasswordLinkVariables
  >(SEND_RESET_PASSWORD_LINK, {
    onCompleted: (data: SendResetPasswordLink) => {
      snackbarData.message =
        'Le lien de récupération de votre mot de passe a été envoyé.';
      snackbarData.type = 'SUCCESS';
      snackbarData.autoHideDuration = 5000;
      displaySnackbar(client, snackbarData);
    },
    onError: (error: ApolloError): void => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = error.networkError
        ? 'Erreur de réseau. Veuillez vérifier votre connexion internet.'
        : "Une erreur s'est produite lors de l'envoi de l'email.";
      displaySnackbar(client, snackbarData);
    },
  });

  if (isAuthenticated()) {
    return <Redirect {...{ to: '/' }} />;
  }

  const isValid = (): boolean => {
    return isValidEmail(email);
  };

  const handleSendEmail = async (
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();

    if (isValid()) {
      await sendLink({ variables: { email: email } });
    } else {
      snackbarData.type = 'WARNING';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "L'adresse email que vous avez entrée n'est pas valide.";
      displaySnackbar(client, snackbarData);
    }
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value.trim());
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.wrapForm}>
          <Box className={classes.logo}>
            <img src={logo} alt="Neoptim Logo" />
          </Box>
          <Box className={classes.title}>
            <Typography
              variant="h4"
              component="h1"
              className={classes.textTitle}
            >
              Vous n'arrivez pas à vous connecter ?
            </Typography>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              className={classes.descTitle}
            >
              Nous vous enverrons un lien de récupération
            </Typography>
          </Box>
          <form
            onSubmit={handleSendEmail}
            className={classes.form}
            autoComplete="off"
          >
            <TextField
              label="Adresse e-mail"
              type="text"
              name="email"
              onChange={handleEmailChange}
              variant="outlined"
              margin="normal"
              fullWidth
              className={classes.textField}
            />
            <div className={classes.buttonWrapper}>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
                fullWidth
                className={classes.button}
              >
                Envoyer le lien
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.lodingProgress}
                />
              )}
            </div>
            <Box className={classes.actions}>
              <Link
                className={classes.link}
                color="secondary"
                onClick={() => history.push('/authentification')}
              >
                Revenir à la connexion
              </Link>
            </Box>
          </form>
        </Box>
        <Typography
          variant="body2"
          component="footer"
          color="primary"
          align="center"
          className={classes.footer}
        >
          &copy; Copyright - Neoptim consulting
        </Typography>
      </Box>
    </Box>
  );
};

export default withRouter(ForgotPassword);
