import React, {
  ChangeEvent,
  FC,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import Dialog from '@material-ui/core/Dialog';
import useStyles, {
  styles,
} from '../../Establishment/EstablishmentAdd/UserFormDialog/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { Prestation } from '../../../types/prestation';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { EtablishmentNames } from '../../../graphql/etablishment/types/EtablishmentNames';
import {
  GET_ETABLISHMENT_DETAILS,
  GET_ETABLISHMENT_NAMES,
  GET_ETABLISHMENT_WITHOUT_ORGANIZATION,
} from '../../../graphql/etablishment/query';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {
  EtablishmentDetails,
  EtablishmentDetailsVariables,
} from '../../../graphql/etablishment/types/EtablishmentDetails';
import { EtablishmentWithoutOrganization, EtablishmentWithoutOrganizationVariables } from '../../../graphql/etablishment/types/EtablishmentWithoutOrganization';
import { EstablishmentFilter } from '../../../types/filters';

interface PrestationAddFormDialogProps {
  open: boolean;
  title: String;
  handleClose: () => void;
  data: Prestation;
  idEstablishment?: number;
  loadingButton: boolean;
  onSubmit: (data: Prestation, cb: any) => void;
  buttonLabel: string;
}
interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.dialogTitle}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const PrestationAddFormDialog: FC<PrestationAddFormDialogProps> = (props) => {
  const classes = useStyles();
  const {
    open,
    title,
    handleClose,
    data,
    idEstablishment,
    loadingButton,
    onSubmit,
    buttonLabel,
  } = props;

  const INIT_FILTER: EstablishmentFilter = {
    city: '',
    postalCode: '',
    enabled: -1,
    type: 'ALL',
  };

  const [inputData, setInputData] = useState<Prestation>(data);
  const [autoCompleteValue, setAutocompleValue] = useState<any>(null);
  const [establishmentData, setEstablishmentData] = useState<any[]>([]);
  const [filter, setFilter] = useState<EstablishmentFilter>(INIT_FILTER);
  const [searchKey, setSearchKey] = useState<string>('');
  const [establishmentorga, setEstablishmentorga] = useState<any[]>([]);
  const [etablishmentName, setEtablishmentName] = useState<String>('');
  const [autoCompleteInputValue, setAutocompleInputValue] =
    useState<string>('');

  const onHandleClose = () => {
    resetForm();
    handleClose();
  };

  const resetForm = () => {
    setInputData({
      libelle:'',
      establishmentId:0,
    });
    setAutocompleValue(null);
    setAutocompleInputValue('');
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputData({
      ...inputData,
      libelle: value,
    });
  };
  const onChangeAutoComplete = (
    event: React.ChangeEvent<any>,
    newValue: any,
  ) => {
    setInputData({
      ...inputData,
      establishmentId: (newValue && newValue.id) || 0,
    });
    setAutocompleValue(newValue);
  };
  const onInputChangeAutoComplete = (
    event: React.ChangeEvent<any>,
    newValue: any,
  ) => {
    setAutocompleInputValue(newValue);
  };
  const onHandleSubmit = () => {
    onSubmit(inputData, (res: boolean) => {
      //if (res) resetForm();
    });
    resetForm();
  };
  const [getDetails] = useLazyQuery<
    EtablishmentDetails,
    EtablishmentDetailsVariables
  >(GET_ETABLISHMENT_DETAILS, {
    variables: { id: idEstablishment! },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const name = data?.etablishment?.name;
      if (name) {
        setEtablishmentName(name);
      }
    },
    onError: (error) => {},
  });
  const [getEstablishmentNames] = useLazyQuery<EtablishmentNames>(
    GET_ETABLISHMENT_NAMES,
    {
      onCompleted: ({ etablishments }) => {
        setEstablishmentData(etablishments);
      },
    },
  );

  const normalizeFilter = (filters: EstablishmentFilter): any => {
    return {
      postalCode: filters.postalCode,
      enabled: filters.enabled,
      city: filters.city,
      etablishmentType: filters.type === 'ALL' ? '' : filters.type,
    };
  };
  
  const variables = {
    input: {
      filter: JSON.stringify({
        ...normalizeFilter(filter),
      }),
    },
  };

  const { data: establishments, loading } = useQuery<
  EtablishmentWithoutOrganization,
  EtablishmentWithoutOrganizationVariables
>(GET_ETABLISHMENT_WITHOUT_ORGANIZATION, { variables });

  useEffect(()=>{
    if(establishments?.etablishmentWithoutOrganization){
      setEstablishmentorga(establishments.etablishmentWithoutOrganization);
    }
  })
  
  useEffect(() => {
    if (!idEstablishment) {
      getEstablishmentNames();
    } else {
      getDetails();
    }
  }, [idEstablishment]);

  useEffect(() => {
    setInputData({
      ...data,
    });
  }, [data]);

  useEffect(() => {
    if (inputData && inputData.establishmentId) {
      const value = establishmentData.find(
        (item) => item && item.id === inputData.establishmentId,
      );
      setAutocompleValue(value);
    }
  }, [inputData]);

  return (
    <Dialog
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      className={classes.root}
      // onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title" onClose={onHandleClose}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          label="Libellé"
          name="libelle"
          placeholder="Libellé"
          value={inputData.libelle}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
          margin="normal"
          autoComplete="off"
        />
        {!idEstablishment && (
          <Autocomplete
            options={establishmentorga}
            value={autoCompleteValue}
            onChange={onChangeAutoComplete}
            noOptionsText={'Aucune option'}
            inputValue={autoCompleteInputValue}
            onInputChange={onInputChangeAutoComplete}
            getOptionLabel={(option: any) => option && option.name}
            id="controllable-autocomplete"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Etablissement"
                name="establishment"
                placeholder="Choisissez un établissement"
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        )}
        {idEstablishment && etablishmentName && (
          <TextField
            label="Etablissement"
            name="establishment"
            placeholder="Etablissement"
            value={etablishmentName}
            onChange={handleChange}
            disabled={true}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
            autoComplete="off"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={loadingButton}
          onClick={onHandleSubmit}
        >
          {buttonLabel || 'Ajouter'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrestationAddFormDialog;
