import { User, Beneficiary } from './user';

export enum DefaultUserValidationError {
  EMPTY_FIRST_NAME = 'EMPTY_FIRST_NAME',
  INVALID_FIRST_NAME = 'INVALID_FIRST_NAME',
  EMPTY_LAST_NAME = 'EMPTY_LAST_NAME',
  INVALID_LAST_NAME = 'INVALID_LAST_NAME',
  EMPTY_EMAIL = 'EMPTY_EMAIL',
  INVALID_EMAIL = 'INVALID_EMAIL',
  EMPTY_MOBILE_PHONE = 'EMPTY_MOBILE_PHONE',
  INVALID_MOBILE_PHONE = 'INVALID_MOBILE_PHONE',
  EMPTY_PHONE = 'EMPTY_PHONE',
  NOT_TYPE_NUMBER = 'FUNCTION_MUTS_STRING',
  INVALID_PHONE = 'INVALID_PHONE',
  NO_ESTABLISHMENT = 'NO_ESTABLISHMENT',
  NOT_VALID_DATE = 'INVALID_DATE',
  BIRTHDATE_NOT_VALID = 'BIRTHDATE_NOT_VALID',
}

export enum BeneficiaryValidatorError {
  EMPTY_GUARDIAN_NAME = 'EMPTY_GUARDIAN_NAME',
}

export const UserValidationError = {
  ...DefaultUserValidationError,
  ...BeneficiaryValidatorError,
};

export type UserValidationError =
  | DefaultUserValidationError
  | BeneficiaryValidatorError;

export interface DefaultUserValidationState {
  valid: boolean;
  error?: UserValidationError;
}

export interface BeneficiaryValidatorState {
  valid: boolean;
  error?: BeneficiaryValidatorError;
}
export type UserValidationState =
  | DefaultUserValidationState
  | BeneficiaryValidatorState;
export type UserValidatorFunc = (
  user: User | Beneficiary,
  key: keyof User | keyof Beneficiary,
) => UserValidationState;

export type UserValidators = Partial<Record<keyof User, UserValidatorFunc[]>>;
export type UserValidationMessages = Record<UserValidationError, string>;
