import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

interface CustomCardProps {
  title?: string;
}

const CustomCard: FC<CustomCardProps> = (props) => {
  const { title, children } = props;
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.paper}>
      {title && <Typography className={classes.title}>{title}</Typography>}
      <div>{children}</div>
    </Paper>
  );
};

export default CustomCard;
