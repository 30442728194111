import React, { FC, useState, useEffect, useContext } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import { withRouter, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import useStyles from './styles';
import EstablishmentDetailsHeader from './EstablishmentDetailsHeader';
import EstablishmentDetailsContent from './EstablishmentDetailsContent';
import EstablishmentDetailsInfos from './EstablishmentDetailsInfos';
import PrestationList from './PrestationList';
import { GET_ETABLISHMENT_DETAILS } from '../../../graphql/etablishment/query';
import {
  EtablishmentDetails,
  EtablishmentDetailsVariables,
} from '../../../graphql/etablishment/types/EtablishmentDetails';
import { ExtendedEstablishmentInfoData } from './types';
import { INIT_ESTABLISHMENT_INFO } from './types';
import { EstablishmentTypeType } from '../../../types/establishment';
import { GET_ETABLISHMENT_CONTRIBUTORS_INFO } from '../../../graphql/etablishment/query';
import {
  EtablishmentContributorsInfo,
  EtablishmentContributorsInfoVariables,
  EtablishmentContributorsInfo_etablishment,
} from '../../../graphql/etablishment/types/EtablishmentContributorsInfo';
import { UserContext } from '../../Context';
import { EstablishmentPrestationData } from './types';
import {
  Prestations,
  PrestationsVariables,
} from '../../../graphql/prestation/types/Prestations';
import { GET_PRESTATIONS } from '../../../graphql/prestation/query';

interface EstablishmentDetailsProps {}

interface RouteParams {
  id: string;
}

const EstablishmentDetails: FC<EstablishmentDetailsProps> = (props) => {
  const classes = useStyles();
  const { id } = useParams<RouteParams>();
  const { userId, userRole } = useContext(UserContext);
  const [establishmentId] = useState<number>(Number(id));
  const [prestations, setPrestations] = useState<EstablishmentPrestationData>({
    prestations: '',
  });

  const variables = {
    input: {
      establishmentId: establishmentId,
    },
  };

  const {
    loading: loadingPrestationList,
    data: prestationList,
    refetch,
  } = useQuery<Prestations, PrestationsVariables>(GET_PRESTATIONS, {
    variables,
  });

  const [establishmentType, setEstablishmentType] = useState<
    EstablishmentTypeType | string
  >('');
  const [etabInfoData, setEtabInfoData] =
    useState<ExtendedEstablishmentInfoData>({
      ...INIT_ESTABLISHMENT_INFO,
      residences: null,
    });
  const [contributorInfos, setContributorInfos] =
    useState<EtablishmentContributorsInfo_etablishment | null>(null);

  const { data } = useQuery<EtablishmentDetails, EtablishmentDetailsVariables>(
    GET_ETABLISHMENT_DETAILS,
    {
      variables: { id: establishmentId },
    },
  );

  const [getEtabInfo] = useLazyQuery<
    EtablishmentDetails,
    EtablishmentDetailsVariables
  >(GET_ETABLISHMENT_DETAILS, {
    variables: { id: establishmentId },
  });

  const { data: establishmentData } = useQuery<
    EtablishmentContributorsInfo,
    EtablishmentContributorsInfoVariables
  >(GET_ETABLISHMENT_CONTRIBUTORS_INFO, {
    variables: { id: establishmentId },
  });

  useEffect(() => {
    if (establishmentData && establishmentData.etablishment) {
      const residenceParent =
        (establishmentData.etablishment.residences &&
          establishmentData.etablishment.residences[0]) ||
        null;

      setContributorInfos(establishmentData.etablishment);
      setEtabInfoData({
        ...etabInfoData,
        residences: residenceParent,
      });
    }
  }, [establishmentData]);

  useEffect(() => {
    if (data && data.etablishment) {
      const establishment = data.etablishment;
      const establishmentType =
        establishment &&
        establishment.etablishmentType &&
        establishment.etablishmentType.name;

      if (establishmentType) {
        setEstablishmentType(establishmentType as EstablishmentTypeType);
      }

      if (
        data.etablishment.prestations &&
        establishmentType != 'RESIDENCE_PARENT'
      ) {
        setPrestations({ prestations: data.etablishment.prestations });
      }
      setEtabInfoData({
        ...etabInfoData,
        name: establishment.name,
        logo: establishment.logo || '',
        enabled: establishment.enabled || false,
        rcs: establishment.rcs || '',
        address: establishment.address || '',
        type: establishmentType || '',
        objet: establishment.etablishmentObject || '',
        codePostal: establishment.postalCode || '',
        ville: establishment.city || '',
        status:
          (establishment.etablishmentStatus &&
            establishment.etablishmentStatus.name) ||
          '',
      });
    }
  }, [data]);

  useEffect(() => {
    getEtabInfo();
  }, [etabInfoData]);

  return (
    <Box className={classes.root}>
      <EstablishmentDetailsHeader
        establishmentId={establishmentId}
        establishmentType={establishmentType}
      />
      <Row style={{ marginBottom: 48 }}>
        <Col sm={12} xl={3}>
          <Box className={classes.details}>
            <Box className={classes.infos}>
              <EstablishmentDetailsInfos
                id={establishmentId}
                data={etabInfoData}
                userRole={userRole}
                setData={setEtabInfoData}
              />
            </Box>
            {/* <Box>
              <PrestationList data={prestationList} />
            </Box> */}
          </Box>
        </Col>
        <Col sm={12} xl={9}>
          <EstablishmentDetailsContent
            type={establishmentType}
            establishmentId={establishmentId}
            data={contributorInfos}
          />
        </Col>
      </Row>
    </Box>
  );
};

export default withRouter(EstablishmentDetails);
