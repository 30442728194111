import React, { FC, useState, useEffect, useContext } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-boost';
import { DataProxy } from 'apollo-cache';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import {
  Beneficiaries,
  BeneficiariesVariables,
} from '../../../../graphql/beneficiary/types/Beneficiaries';
import {
  CreateBeneficiary,
  CreateBeneficiaryVariables,
} from '../../../../graphql/beneficiary/types/CreateBeneficiary';
import {
  UpdateBeneficiary,
  UpdateBeneficiaryVariables,
} from '../../../../graphql/beneficiary/types/UpdateBeneficiary';
import { GET_BENEFICIARIES } from '../../../../graphql/beneficiary/query';
import { withRouter, RouteComponentProps } from 'react-router';
import {
  CREATE_BENEFICIARY,
  UPDATE_BENEFICIARY,
  DELETE_BENEFICIARY,
  DELETE_BENEFICIARIES,
  UPDATE_BENEFICIARY_STATUS,
  CREATE_BENEFICIARIES,
} from '../../../../graphql/beneficiary/mutation';
import { SnackbarType } from '../../../../types';
import { displaySnackbar, checkActionGroup } from '../../../../common';
import { HeadCell, GroupAction } from '../../../../types/table';
import UserFormDialog from '../UserFormDialog';
import { Beneficiary, FormInputDataState, User } from '../../../../types/user';
import SearchInput from '../../../Common/SearchInput';
import useStyles from './styles';
import CustomTable from '../../../Common/CustomTable';
import { ValueType } from 'react-select';
import { EtablishmentDataType } from '../../../../types/establishment';
import ConfirmationDialog from '../../../Common/ConfirmationDialog';
import {
  DeleteBeneficiary,
  DeleteBeneficiaryVariables,
} from '../../../../graphql/beneficiary/types/DeleteBeneficiary';
import {
  UpdateBeneficiaryStatus,
  UpdateBeneficiaryStatusVariables,
} from '../../../../graphql/beneficiary/types/UpdateBeneficiaryStatus';
import {
  DeleteBeneficiaries,
  DeleteBeneficiariesVariables,
} from '../../../../graphql/beneficiary/types/DeleteBeneficiaries';
import { EtablishmentContributorsInfo_etablishment_beneficiaries } from '../../../../graphql/etablishment/types/EtablishmentContributorsInfo';
import { TableDataSource } from '../../../../types/table';
import { GET_ETABLISHMENT_DATA } from '../../../../graphql/common/etablishment/query';
import { isGlobalList } from '../../../../common/validator';
import { filterUserByName } from '../../../../common/utils';
import { getUsersFromFiles } from '../../../../utils/excel-import.utils';
import { UserValidationState } from '../../../../types/user-validator.types';
import {
  getExcelImportUserErrorMessage,
  getImportExcelValidators,
  validateUsers,
} from '../../../../utils/user-validator.utils';
import {
  formInputDataStateToUser,
  getPartialUsers,
} from '../../../../utils/user.utils';
import {
  CreateBeneficiaries,
  CreateBeneficiariesVariables,
} from '../../../../graphql/beneficiary/types/CreateBeneficiaries';
import { showSnackbar } from '../../../../utils/snackbar.util';
import ExcelReader from '../../../Common/ExcelReader';
import { handleEstablishmentCreateUserListValidation } from '../../../../utils/user-list.utils';
import { CreateBeneficiaryInput } from '../../../../graphql/types/graphql-global-types';
import { useUploadSignature } from '../../../../hooks/upload-image.hooks';
import moment from 'moment';
import { EstablishmentContext } from '../../../Context/EstablishmentContext';

interface BeneficiaryListProps {
  etabInputData?: EtablishmentDataType;
  setEtabInputData?: React.Dispatch<React.SetStateAction<EtablishmentDataType>>;
  disableImportAction?: boolean;
  loading?: boolean;
  fromDetails?: boolean;
  dataSource?: TableDataSource;
  beneficiaryData?:
    | (EtablishmentContributorsInfo_etablishment_beneficiaries | null)[]
    | any[];
}

const ACTIONS: GroupAction[] = [
  { label: 'Activer', value: 'enable' },
  { label: 'Désactiver', value: 'disable' },
  { label: 'Supprimer', value: 'remove' },
];

const INIT_DATA = {
  name: '',
  lastName: '',
  email: '',
  mobilePhone: '',
  phone: '',
  status: false,
};

const getBeneficiaryIds = (etabInputData?: EtablishmentDataType) =>
  (etabInputData && etabInputData.beneficiaryIds) || [];

const BeneficiaryList: FC<BeneficiaryListProps & RouteComponentProps> = (
  props,
) => {
  const {
    setEtabInputData,
    etabInputData,
    beneficiaryData,
    loading,
    dataSource,
    fromDetails,
  } = props;
  const classes = useStyles();
  const client = useApolloClient();
  const query = new URLSearchParams(props.location.search);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [data, setData] = useState<any[]>([]);
  const [inputData, setInputData] = useState<FormInputDataState>(INIT_DATA);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [beneficiaryId, setBeneficiaryId] = useState<number>(0);
  const [openUpdateUserDialog, setOpenUpdateUserDialog] =
    useState<boolean>(false);
  const [editId, setEditId] = useState<number>(-1);
  const [inputUpdateData, setInputUpdateData] =
    useState<FormInputDataState>(INIT_DATA);
  const { newEtablishmentId } = useContext(EstablishmentContext);
  const uploadSignature = useUploadSignature();
  useEffect(() => {
    if (setEtabInputData) {
      setEtabInputData((prevState: EtablishmentDataType) => ({
        ...prevState,
        beneficiaryIds: selectedItems,
      }));
    }
  }, [selectedItems, setEtabInputData]);

  useEffect(() => {
    if (fromDetails && beneficiaryData) {
      setData(beneficiaryData);
    }
  }, [beneficiaryData]);

  const filter = searchKey && {
    name: searchKey,
  };
  const creation = !etabInputData;
  const creationFilter = {
    etablishmentNull: true,
  };
  const editFilter = {
    id: getBeneficiaryIds(etabInputData),
    establishmentEdit: true,
  };
  const variables = {
    input: {
      filter: JSON.stringify({
        ...(creation ? creationFilter : editFilter),
        ...filter,
      }),
    },
  };

  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const { loading: loadingBeneficiaryList, data: beneficiaryList } = useQuery<
    Beneficiaries,
    BeneficiariesVariables
  >(GET_BENEFICIARIES, { variables });

  const [createBeneficiary, { loading: loadingCreateBenef }] = useMutation<
    CreateBeneficiary,
    CreateBeneficiaryVariables
  >(CREATE_BENEFICIARY, {
    onCompleted: (data: CreateBeneficiary) => {
      if (data && data.createBeneficiary) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = 'Le bénéficiaire a été ajouté';
        displaySnackbar(client, snackbarData);
        setOpenDialog(false);
        setInputData(INIT_DATA);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la création de bénéficiaire";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const { message } = error.graphQLErrors[0];
        switch (message) {
          case 'EMAIL_ALREADY_USED':
            snackbarData.type = 'WARNING';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = "L'adresse email est déjà utilisée";
            break;
        }
      } else if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [createBeneficiaries] = useMutation<
    CreateBeneficiaries,
    CreateBeneficiariesVariables
  >(CREATE_BENEFICIARIES, {
    onCompleted: (data: CreateBeneficiaries) => {
      if (data && data.createBeneficiaries) {
        if (data.createBeneficiaries.success) {
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = 'La liste de bénéficiaire a été importée';
        } else {
          snackbarData.type = 'WARNING';
          snackbarData.message = 'Des informations dupliquées existent';
        }
        displaySnackbar(client, snackbarData);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la création du bénéficiaire";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const { message } = error.graphQLErrors[0];
        if (message.includes('EMAIL_ALREADY_USED')) {
          snackbarData.type = 'WARNING';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `L'adresse email ${
            message.split(';')[0]
          } est déjà utilisée`;
        }
      } else if (error.networkError) {
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [updateBeneficiary, { loading: loadingUpdateBenef }] = useMutation<
    UpdateBeneficiary,
    UpdateBeneficiaryVariables
  >(UPDATE_BENEFICIARY, {
    onCompleted: (data: UpdateBeneficiary) => {
      if (data && data.updateBeneficiary) {
        setOpenUpdateUserDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour du bénéficiaire";
      displaySnackbar(client, snackbarData);
    },
  });

  const [deleteBeneficiary, { loading: loadingDeleteBeneficiary }] =
    useMutation<DeleteBeneficiary, DeleteBeneficiaryVariables>(
      DELETE_BENEFICIARY,
      {
        onCompleted: (data: DeleteBeneficiary) => {
          if (data && data.deleteBeneficiary) {
            snackbarData.type = 'SUCCESS';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = `Le bénéficiare a été supprimé`;
            displaySnackbar(client, snackbarData);
            setOpenConfirmDialog(false);
          }
        },
        onError: (error: ApolloError) => {
          snackbarData.type = 'ERROR';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            "Une erreur s'est produite lors de la suppression du bénéficiaire";
          if (error.networkError) {
            snackbarData.type = 'ERROR';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message =
              'Erreur de réseau. Veuillez vérifier votre connexion internet';
          }
          displaySnackbar(client, snackbarData);
        },
      },
    );

  const [updateBeneficiaryStatus] = useMutation<
    UpdateBeneficiaryStatus,
    UpdateBeneficiaryStatusVariables
  >(UPDATE_BENEFICIARY_STATUS, {
    onCompleted: (data: UpdateBeneficiaryStatus) => {
      if (data && data.updateBeneficiaryStatus) {
        const statut =
          data.updateBeneficiaryStatus &&
          data.updateBeneficiaryStatus[0] &&
          data.updateBeneficiaryStatus[0].user &&
          data.updateBeneficiaryStatus[0].user.enabled === true
            ? 'activé'
            : 'désactivé';
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Les bénéficiaires ont été ${statut}s`;
        displaySnackbar(client, snackbarData);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour des bénéficiaires";

      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [deleteBenefs] = useMutation<
    DeleteBeneficiaries,
    DeleteBeneficiariesVariables
  >(DELETE_BENEFICIARIES, {
    onCompleted: (data: DeleteBeneficiaries) => {
      if (data && data.deleteBeneficiaries) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Les bénéficiaires ont été supprimés`;
        displaySnackbar(client, snackbarData);
        setOpenConfirmDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la suppression des bénéficiaires";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  useEffect(() => {
    if (
      beneficiaryList &&
      beneficiaryList.beneficiaries &&
      isGlobalList(dataSource as string)
    ) {
      setData(beneficiaryList.beneficiaries);
    }
  }, [beneficiaryList]);

  const cachedBeneficiaryList = client.readQuery({
    query: GET_ETABLISHMENT_DATA,
  });

  useEffect(() => {
    if (
      cachedBeneficiaryList &&
      cachedBeneficiaryList.etablishmentData &&
      cachedBeneficiaryList.etablishmentData.beneficiaries &&
      dataSource &&
      dataSource === 'add-establishment'
    ) {
      const result = filterUserByName(
        cachedBeneficiaryList.etablishmentData.beneficiaries,
        searchKey,
      );
      const benefList = cachedBeneficiaryList.etablishmentData.beneficiaries;
      setData(searchKey ? result : benefList);
    }
  }, [cachedBeneficiaryList, searchKey]);

  const columns: HeadCell[] = [
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.lastName) || '-';
      },
    },
    {
      name: 'lastName',
      label: 'Prénom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.firstName) || '-';
      },
    },
    {
      name: 'mail',
      label: 'Mail',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.email) || '-';
      },
    },
    {
      name: 'mobilePhone',
      label: 'Tél mobile',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.mobilePhone) || '-';
      },
    },
    {
      name: 'phone',
      label: 'Tél fixe',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.phone) || '-';
      },
    },
    {
      name: 'birthDate',
      label: 'Date de naissance',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && moment(value.birthDate).format('DD-MM-YYYY')) || '-';
      },
    },
    {
      name: 'disabilityRecognition',
      label: `Reconnaissance d'handicap`,
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.disabilityRecognition ? 'Oui' : 'Non') || '-';
      },
    },
    {
      name: 'guardianEnabled',
      label: 'Sous tutelle',
      disablePadding: false,
      renderer: (value: any) => {
        return value.guardianEnabled !== undefined
          ? value.guardianEnabled
            ? 'Oui'
            : 'Non'
          : '-';
      },
    },
    {
      name: 'guardianName',
      label: 'Nom tuteur',
      disablePadding: false,
      renderer: (value: any) => {
        return value.guardianName || '';
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => {
        const statut = value && value.user && value.user.enabled;

        return (
          <FormControlLabel
            control={
              <Switch
                checked={statut}
                onChange={(event) => onHandleChangeStatus(event, value.id)}
                name="enabled"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            }
            label={statut ? 'Activé' : 'Désactivé'}
          />
        );
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex">
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleDelete(value.id);
              }}
              className={classes.margin}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifier" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleEditUser(value.id);
              }}
              className={classes.margin}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const onHandleChangeStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const { checked } = event.target;
    updateBeneficiary({
      variables: {
        id,
        enabled: checked,
      },
      update: onUpdatedCachedEtab,
    }).then((options) => {
      if (options && options.data && options.data.updateBeneficiary) {
        const statut =
          options.data.updateBeneficiary.user &&
          options.data.updateBeneficiary.user.enabled
            ? 'activé'
            : 'désactivé';
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Le bénéficiaire a été ${statut}`;
        displaySnackbar(client, snackbarData);
      }
    });
  };

  const onUpdatedCachedEtab: any = (
    cache: DataProxy,
    { data }: { data: UpdateBeneficiary | null | undefined },
  ) => {
    if (
      dataSource &&
      dataSource === 'add-establishment' &&
      data &&
      data.updateBeneficiary
    ) {
      let etabData = cache.readQuery<any>({
        query: GET_ETABLISHMENT_DATA,
      });

      if (etabData && etabData.etablishmentData) {
        let benefs = etabData.etablishmentData.beneficiaries || [];
        let newBenefs = benefs.filter((benef: any) => {
          if (
            benef &&
            benef.id &&
            data.updateBeneficiary &&
            data.updateBeneficiary.id
          ) {
            return benef.id !== data.updateBeneficiary.id;
          }
        });
        let newEtablishmentBenefs = [data.updateBeneficiary, ...newBenefs];

        etabData.etablishmentData = {
          ...etabData.etablishmentData,
          beneficiaries: newEtablishmentBenefs,
        };
        cache.writeQuery({
          query: GET_ETABLISHMENT_DATA,
          data: {
            etablishmentData: etabData.etablishmentData,
          },
        });
      }
    }
  };

  const addNewBeneficiariesToCache = (
    cache: DataProxy,
    newBeneficiaries: any[],
  ) => {
    if (dataSource && dataSource === 'add-establishment') {
      let etabData = cache.readQuery<any>({
        query: GET_ETABLISHMENT_DATA,
      });

      if (etabData && etabData.etablishmentData) {
        const benefs = etabData.etablishmentData.beneficiaries || [];
        let newEtablishmentBenefs = [...newBeneficiaries, ...benefs];

        etabData.etablishmentData = {
          ...etabData.etablishmentData,
          id: newEtablishmentBenefs.length + 1,
          beneficiaries: newEtablishmentBenefs,
        };

        cache.writeQuery({
          query: GET_ETABLISHMENT_DATA,
          data: {
            etablishmentData: etabData.etablishmentData,
          },
        });
      }
    } else {
      const benefData = cache.readQuery<Beneficiaries>({
        query: GET_BENEFICIARIES,
        variables,
      });
      if (newBeneficiaries && benefData) {
        cache.writeQuery({
          query: GET_BENEFICIARIES,
          variables,
          data: {
            beneficiaries: [...newBeneficiaries, ...benefData.beneficiaries],
          },
        });
      }
    }
  };

  const onHandleAddUser = (data: FormInputDataState, cb: any) => {
    const user: Beneficiary = formInputDataStateToUser(
      data,
      'beneficiary',
    ) as Beneficiary;
    handleEstablishmentCreateUserListValidation(user, client, async () => {
      const url = data.file && (await uploadSignature(data.file));
      createBeneficiary({
        variables: {
          ...user,
          establishmentId: newEtablishmentId,
          signature: url || user.signature,
        },
        update: (cache, { data }) => {
          const newBenef =
            data && data.createBeneficiary && data.createBeneficiary.data;

          addNewBeneficiariesToCache(cache, [newBenef]);
        },
      })
        .then(({ data }) => {
          return cb(
            !!(
              data &&
              data.createBeneficiary &&
              data.createBeneficiary.success
            ),
          );
        })
        .catch(() => {
          return cb(false);
        });
    });
  };

  const onHandleDelete = (id: number) => {
    setBeneficiaryId(id);
    setOpenConfirmDialog(true);
  };

  const onDeleteBeneficiary = () => {
    if (beneficiaryId && beneficiaryId > 0) {
      deleteBeneficiary({
        variables: {
          id: beneficiaryId,
        },
        update: (cache, { data }) => {
          const deletedId =
            data && data.deleteBeneficiary && data.deleteBeneficiary.id;

          if (
            dataSource &&
            dataSource === 'add-establishment' &&
            data &&
            data.deleteBeneficiary
          ) {
            let etabData = cache.readQuery<any>({
              query: GET_ETABLISHMENT_DATA,
            });

            if (etabData && etabData.etablishmentData) {
              let benefs = etabData.etablishmentData.beneficiaries || [];
              let newBenefs = benefs.filter((benef: any) => {
                if (benef && benef.id) {
                  return deletedId !== benef.id;
                }
              });

              etabData.etablishmentData = {
                ...etabData.etablishmentData,
                beneficiaries: newBenefs,
              };
              cache.writeQuery({
                query: GET_ETABLISHMENT_DATA,
                data: {
                  etablishmentData: etabData.etablishmentData,
                },
              });
            }
          } else {
            const beneficiaryData = cache.readQuery<Beneficiaries>({
              query: GET_BENEFICIARIES,
              variables,
            });

            if (deletedId && beneficiaryData) {
              const newData = beneficiaryData.beneficiaries.filter(
                (beneficiary) => beneficiary && beneficiary.id !== deletedId,
              );

              cache.writeQuery({
                query: GET_BENEFICIARIES,
                variables,
                data: {
                  beneficiaries: newData,
                },
              });
            }
          }
        },
      });
    } else {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'ID bénéficiare non trouvé.';
      displaySnackbar(client, snackbarData);
      setOpenConfirmDialog(false);
    }
  };

  const onHandleEditUser = (id: number) => {
    setEditId(id);
    const { user, guardianEnabled, guardianName } = data.find(
      (item) => item.id === id,
    );
    const input = { ...user, guardianName, guardianEnabled };

    setInputUpdateData({
      ...input,
      name: input.firstName,
      lastName: input.lastName || '',
      status: input.enabled,
    });
    setOpenUpdateUserDialog(true);
  };

  const onHandleUpdateUser = (data: FormInputDataState, cb: any) => {
    const user: User = formInputDataStateToUser(data, 'beneficiary') as User;
    handleEstablishmentCreateUserListValidation(user, client, () => {
      updateBeneficiary({
        variables: {
          ...user,
          id: editId,
        },
        update: onUpdatedCachedEtab,
      }).then(({ data }) => {
        if (data && data.updateBeneficiary) {
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `Le bénéficiaire a été mis à jour`;
          displaySnackbar(client, snackbarData);
        }
      });
    });
  };

  const onHandleSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKey(value);
  };

  const changeBenefStatus = (
    selectedItems: number[],
    enabled: boolean,
  ): void => {
    const status = enabled ? 'activer' : 'désactiver';
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les bénéficiaires à ${status}`,
      )
    ) {
      updateBeneficiaryStatus({
        variables: { ids: selectedItems, status: enabled },
        update: (cache, { data }) => {
          if (
            dataSource &&
            dataSource === 'add-establishment' &&
            data &&
            data.updateBeneficiaryStatus
          ) {
            let etabData = cache.readQuery<any>({
              query: GET_ETABLISHMENT_DATA,
            });

            if (etabData && etabData.etablishmentData) {
              let benefs = etabData.etablishmentData.beneficiaries || [];
              let updatedIds = data.updateBeneficiaryStatus.map(
                (item: any) => item && item.id,
              );
              let newBenefs = benefs.filter((worker: any) => {
                if (worker && worker.id) {
                  return !updatedIds.includes(worker.id);
                }
              });
              let newEtablishmentBenefs = [
                ...data.updateBeneficiaryStatus,
                ...newBenefs,
              ];

              etabData.etablishmentData = {
                ...etabData.etablishmentData,
                beneficiaries: newEtablishmentBenefs,
              };
              cache.writeQuery({
                query: GET_ETABLISHMENT_DATA,
                data: {
                  etablishmentData: etabData.etablishmentData,
                },
              });
            }
          }
        },
      });
    }
  };

  const removeBeneficiaries = (selectedItems: number[]): void => {
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les bénéficiaires à supprimer`,
      )
    ) {
      deleteBenefs({
        variables: { ids: selectedItems },
        update: (cache, { data }) => {
          const deletedBenefs = ((data && data.deleteBeneficiaries) || []).map(
            (item) => item && item.id,
          );

          if (
            dataSource &&
            dataSource === 'add-establishment' &&
            data &&
            data.deleteBeneficiaries
          ) {
            let etabData = cache.readQuery<any>({
              query: GET_ETABLISHMENT_DATA,
            });

            if (etabData && etabData.etablishmentData) {
              let benefs = etabData.etablishmentData.beneficiaries || [];
              let newBenefs = benefs.filter((benef: any) => {
                if (benef && benef.id) {
                  return !deletedBenefs.includes(benef.id);
                }
              });

              etabData.etablishmentData = {
                ...etabData.etablishmentData,
                beneficiaries: newBenefs,
              };
              cache.writeQuery({
                query: GET_ETABLISHMENT_DATA,
                data: {
                  etablishmentData: etabData.etablishmentData,
                },
              });
            }
          } else {
            const benefData = cache.readQuery<Beneficiaries>({
              query: GET_BENEFICIARIES,
              variables,
            });

            if (deletedBenefs && benefData && benefData.beneficiaries) {
              const newData = benefData.beneficiaries.filter(
                (benef) => benef && deletedBenefs.indexOf(benef.id) === -1,
              );
              cache.writeQuery({
                query: GET_BENEFICIARIES,
                variables,
                data: {
                  beneficiaries: newData,
                },
              });
            }
          }
        },
      });
    }
  };

  const onHandleAction = (action: ValueType<GroupAction>): void => {
    const actionGroup = action as GroupAction;

    if (actionGroup && actionGroup.value) {
      switch (actionGroup.value) {
        case 'enable':
          changeBenefStatus(selectedItems, true);
          break;
        case 'disable':
          changeBenefStatus(selectedItems, false);
          break;
        case 'remove':
          removeBeneficiaries(selectedItems);
          break;
      }
    }
  };

  const onExcelReaderChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files) {
      const users: User[] = await getUsersFromFiles(
        { ...event.target.files },
        {
          userRole: 'beneficiary',
        },
      );
      const userValidationState: UserValidationState = validateUsers(
        users,
        getImportExcelValidators('beneficiary'),
      );
      if (userValidationState.valid) {
        const partialUsers = getPartialUsers(users, [
          'userRole',
        ]) as CreateBeneficiaryInput[];

        createBeneficiaries({
          variables: {
            input: partialUsers.map((item) => ({
              ...item,
              etablishmentType: query.get('type'),
              etablishmentId: newEtablishmentId,
            })),
          },
          update: (cache, { data }) => {
            const newBeneficiaries =
              data && data.createBeneficiaries && data.createBeneficiaries.data;
            if (newBeneficiaries) {
              addNewBeneficiariesToCache(cache, newBeneficiaries);
            }
          },
        });
      } else if (userValidationState.error) {
        const errorMessage: string = getExcelImportUserErrorMessage(
          userValidationState.error,
        );
        showSnackbar({
          type: 'ERROR',
          message: errorMessage,
          client,
        });
      }
    }
  };

  const beneficiaryToolbarButtons = [
    <SearchInput
      onChange={onHandleSearchKey}
      name="beneficiary"
      value={searchKey}
      placeholder="Recherchez un bénéficiaire "
      // styles={{ minWidth: 350 }}
    />,
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="beneficiary-add"
      startIcon={<AddBoxIcon />}
      style={{ minWidth: 200 }}
      onClick={() => setOpenDialog(true)}
    >
      Ajouter un bénéficiaire
    </Button>,
    <ExcelReader
      onChange={onExcelReaderChange}
      onClick={() => {}}
      loading={false}
    />,
  ];

  return (
    <>
      <CustomTable
        toolbar
        selectable
        maxWidth={1348}
        data={data}
        columns={columns}
        toolbarActions={ACTIONS}
        onHandleAction={onHandleAction}
        toolbarButtons={beneficiaryToolbarButtons}
        toolbarStyles={{ display: 'flex', justifyContent: 'flex-end' }}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        loadingData={loading || loadingBeneficiaryList}
      />
      <UserFormDialog
        userRole="beneficiary"
        open={openDialog}
        title="Ajout d'un bénéficiaire"
        onSubmit={onHandleAddUser}
        data={inputData}
        loadingButton={loadingCreateBenef}
        handleClose={() => {
          setInputData(INIT_DATA);
          setOpenDialog(false);
        }}
      />
      <UserFormDialog
        userRole="beneficiary"
        open={openUpdateUserDialog}
        title="Modifier un bénéficiaire"
        onSubmit={onHandleUpdateUser}
        data={inputUpdateData}
        loadingButton={loadingUpdateBenef}
        buttonLabel="Enregistrer"
        handleClose={() => {
          setOpenUpdateUserDialog(false);
        }}
      />
      <ConfirmationDialog
        title="Suppression"
        message="Etes-vous sûr de vouloir supprimer ce bénéficiaire?"
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteBeneficiary}
        loadingConfirmation={loadingDeleteBeneficiary}
      />
    </>
  );
};

export default withRouter(BeneficiaryList);
