import gql from 'graphql-tag';

import { STATUS_FRAGMENT } from './fragment';

export const GET_STATUS_BY_NAME = gql`
  query GetStatusByName($name: String!) {
    getStatusByName(name: $name) {
      ...StatusInfo
    }
  }
  ${STATUS_FRAGMENT}
`;

export const GET_STATUS_BY_SLUG = gql`
  query GetStatusBySlug($slug: Slug!) {
    getStatusBySlug(slug: $slug) {
      ...StatusInfo
    }
  }
  ${STATUS_FRAGMENT}
`;
