import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 320,
    },
    title: {
      textAlign: 'center',
      fontSize: '1.2em',
      marginBottom: theme.spacing(1),
    },
    signatureContainer: {
      width: '100%',
      border: '1px solid #f5f5f5',
      overflow: 'hidden',
      marginRight: theme.spacing(4),

      '& img': {
        height: 'auto',
        width: 'auto',
        objectFit: 'contain',
        maxWidth: '318px',
        maxHeight: '138',
      },
    },
  }),
);

export default useStyles;
