import React, { FC, Component } from 'react';
import useStyles from './styles';

interface StepManagerProps {
  components: any[];
  activeStep: number;
}

const StepDisplayer: FC<StepManagerProps> = (props) => {
  const { components, activeStep } = props;
  const classes = useStyles();
  return <>{components[activeStep]}</>;
};

export default StepDisplayer;
