import { EstablishmentDataType } from './establishment';

export enum EstablishmentValidationError {
  EMPTY_NAME = 'EMPTY_NAME',
  EMPTY_RCS = 'EMPTY_RCS',
  EMPTY_ADRESS = 'EMPTY_ADRESS',
  EMPTY_POSTAL_CODE = 'EMPTY_POSTAL_CODE',
  EMPTY_CITY = 'EMPTY_CITY',
  NO_RESIDENCE_ID = 'NO_RESIDENCE_ID',
  NO_ADMINISTRATOR = 'NO_ADMINISTRATOR',
  NO_WORKER = 'NO_WORKER',
  NO_BENEFICIARY = 'NO_BENEFICIARY',
  NO_ESTABLISHMENT_TYPE = 'NO_ESTABLISHMENT_TYPE',
  INVALID_NAME = 'INVALID_NAME',
  INVALID_RCS = 'INVALID_RCS',
  INVALID_ADRESS = 'INVALID_ADRESS',
  INVALID_POSTAL_CODE = 'INVALID_POSTAL_CODE',
  INVALID_CITY = 'INVALID_CITY',
}

export interface EstablishmentValdationState {
  valid: boolean;
  error?: EstablishmentValidationError;
}

export type EstablishmentValidatorFunc = (
  establishment: EstablishmentDataType,
  key: keyof EstablishmentDataType,
) => EstablishmentValdationState;

export type EstablishmentValidators = Partial<
  Record<keyof EstablishmentDataType, EstablishmentValidatorFunc[]>
>;
export type EstablishmentValidationMessages = Record<
  EstablishmentValidationError,
  string
>;
