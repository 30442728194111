import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    button: {
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    white: {
      color: theme.palette.common.white,
    },
    margin: {
      marginLeft: 4,
      marginRight: 4,
    },
    excelIcon: {
      width: 24,
      height: 24,
    },
  }),
);

export default useStyles;
