import FileSaver from 'file-saver';
import XLSX from 'xlsx';

interface ExportExcelProps {
  csvData: any;
  fileName: string;
  sheetName: string;
}

export const exportData = (props: ExportExcelProps) => {
  const { csvData, fileName, sheetName } = props;

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const filename = `${fileName}_${new Date().getTime()}${fileExtension}`;

  const worksheet = XLSX.utils.json_to_sheet(csvData);

  console.log('csvData : ', csvData);
  console.log('worksheet : ', worksheet);

  const workbook = {
    Sheets: { [sheetName]: worksheet },
    SheetNames: [sheetName],
  };
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, filename);
};
