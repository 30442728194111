import { MutationHookOptions } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-boost';
import { UserRole } from '../../../../../../types/user';
import { showSnackbar } from '../../../../../../utils/snackbar.util';
import { capitalizeWord } from '../../../../../../utils/string.utils';
import { USER_LIST_LABEL } from '../constants/UserList.constants';
import { OnCompletedFunc } from '../interfaces/UserEditList.interfaces';

export interface OnDeleteUsersMutationOptionsArgs {
  client: ApolloClient<object>;
  userRole: UserRole;
}

export interface OnDeleteUsersMutationCompletedArgs {
  client: ApolloClient<object>;
  userRole: UserRole;
}

export interface OnDeleteUsersMutationErrorArgs {
  client: ApolloClient<object>;
  userRole: UserRole;
}

const onDeleteUsersMutationComplete: (
  args: OnDeleteUsersMutationCompletedArgs,
) => OnCompletedFunc = ({ client, userRole }) => () => {
  showSnackbar({
    type: 'SUCCESS',
    message: `${capitalizeWord(
      USER_LIST_LABEL[userRole],
    )}s supprimés avec succès.`,
    client,
  });
};
const onDeleteUsersMutationError: (
  args: OnDeleteUsersMutationErrorArgs,
) => OnCompletedFunc = ({ client, userRole }) => () => {
  showSnackbar({
    type: 'ERROR',
    message: `Une erreur s'est produite lors de la suppression`,
    client,
  });
};

export const onDeleteUsersMutationOptions: (
  args: OnDeleteUsersMutationOptionsArgs,
) => MutationHookOptions = ({ client, userRole }) => ({
  onCompleted: onDeleteUsersMutationComplete({ client, userRole }),
  onError: onDeleteUsersMutationError({ client, userRole }),
});
