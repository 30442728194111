import { useLocation } from 'react-router-dom';

const useQueryString = <T extends {} = {}>(): T | any => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let queryString = {};

  queryString = Array.from(params.entries()).reduce(
    (acc, curr) => ({ ...acc, [curr[0]]: curr[1] }),
    {},
  );

  return queryString;
};

export default useQueryString;
