import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import { User } from '../../../../../types/user';
import TextField from '@material-ui/core/TextField';
import { USER_ROLE_LABEL } from '../../../../../constants/user';
import CustomSwitch from '../../../../Common/CustomSwitch';
import { Autocomplete } from '@material-ui/lab';
import { useLazyQuery } from '@apollo/react-hooks';
import { UnitLifeByEstablishment } from '../../../../../graphql/unit/types/UnitLifeByEstablishment';
import { GET_UNIT_LIFES_BY_ETABLISHMENT } from '../../../../../graphql/unit/query';
import { GET_ESTABLISHMENT_TYPE } from '../../../../../graphql/etablishment/query';
import { EtablishmentType } from '../../../../../graphql/etablishment/types/EtablishmentType';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';

interface DialogContentProps {
  user: User;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onDateChange: (date: Date | null) => void;
  onCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void;
  establishmentId: number;
  onChangeUnitLife: (event: ChangeEvent<{}>, value: any) => void;
}

interface DialogTextFieldProps {
  label: string;
  name: string;
  placeholder: string;
  value: any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const DialogTextField: FC<DialogTextFieldProps> = ({
  label,
  name,
  placeholder,
  value,
  onChange,
}) => (
  <TextField
    label={label}
    name={name}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    variant="outlined"
    InputLabelProps={{ shrink: true }}
    fullWidth
    margin="normal"
    autoComplete="off"
  />
);

const BasicDialogContent: FC<DialogContentProps> = (props) => {
  const {
    user,
    onChange,
    onDateChange,
    onCheckboxChange,
    establishmentId,
    onChangeUnitLife,
  } = props;
  const classes = useStyles();
  const [unitLifeData, setUnitLifeData] = useState<any[]>([]);
  const [establishmentType, setEstablishmentType] = useState<string>('');
  const [autoCompleteValueUnitLife, setAutocompleteValueUnitLife] =
    useState<any>(null);
  const [autoCompleteInputUnitLifeValue, setAutoCompleteInputUnitLifeValue] =
    useState<any>('');
  const {
    firstName,
    lastName,
    email,
    enabled,
    mobilePhone,
    phone,
    userRole,
    userFunction,
    unitLifeId,
    birthDate,
    disabilityRecognition,
  } = user;

  const [getEstablishmentType] = useLazyQuery<EtablishmentType>(
    GET_ESTABLISHMENT_TYPE,
    {
      onCompleted: (data: EtablishmentType) => {
        if (
          data &&
          data.etablishment &&
          data.etablishment.etablishmentType &&
          data.etablishment.etablishmentType.name
        ) {
          setEstablishmentType(data.etablishment.etablishmentType.name);
        }
      },
    },
  );

  const [getUnitLifesByEtablishment] = useLazyQuery<UnitLifeByEstablishment>(
    GET_UNIT_LIFES_BY_ETABLISHMENT,
    {
      onCompleted: (data: UnitLifeByEstablishment) => {
        if (data && data.unitLifeByEstablishment) {
          setUnitLifeData(data.unitLifeByEstablishment);
        }
      },
    },
  );

  useEffect(() => {
    if (establishmentId !== 0) {
      getUnitLifesByEtablishment({
        variables: { establishmentId: establishmentId },
      });
      getEstablishmentType({
        variables: { id: establishmentId },
      });
    }
  }, [establishmentId]);

  const onInputChangeAutoCompleteUnitLife = (
    event: React.ChangeEvent<any>,
    newValue: any,
  ) => {
    setAutoCompleteInputUnitLifeValue(newValue);
  };

  return (
    <>
      <DialogTextField
        label="Nom"
        name="lastName"
        placeholder={`Nom ${USER_ROLE_LABEL[userRole]}`}
        value={lastName}
        onChange={onChange}
      />
      <DialogTextField
        label="Prénom"
        name="firstName"
        placeholder={`Prénom ${USER_ROLE_LABEL[userRole]}`}
        value={firstName}
        onChange={onChange}
      />
      <DialogTextField
        label="Mail"
        name="email"
        placeholder={`Mail ${USER_ROLE_LABEL[userRole]}`}
        value={email}
        onChange={onChange}
      />
      {userRole !== 'beneficiary' && (
        <TextField
          fullWidth
          margin="normal"
          label="Fonction"
          variant="outlined"
          name="userFunction"
          onChange={onChange}
          value={userFunction}
          placeholder={
            userRole ? `Fonction ${USER_ROLE_LABEL[userRole]}` : 'Fonction'
          }
        />
      )}
      <DialogTextField
        label="Tél Mobile"
        name="mobilePhone"
        value={mobilePhone}
        onChange={onChange}
        placeholder={`Téléphone mobile ${USER_ROLE_LABEL[userRole]}`}
      />
      <DialogTextField
        label="Tél Fixe"
        name="phone"
        value={phone}
        onChange={onChange}
        placeholder={`Téléphone fixe ${USER_ROLE_LABEL[userRole]}`}
      />
      {establishmentType &&
        establishmentType !== 'DOMICILE' &&
        userRole === 'beneficiary' && (
          <Autocomplete
            options={unitLifeData}
            value={unitLifeId}
            onChange={onChangeUnitLife}
            noOptionsText={'Aucune option'}
            inputValue={autoCompleteInputUnitLifeValue}
            onInputChange={onInputChangeAutoCompleteUnitLife}
            getOptionLabel={(option: any) => option && option.name}
            id="unitLifeId"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Unité de vie"
                name="unitLifeId"
                placeholder="Choisissez l'unité de vie"
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        )}
      {userRole === 'beneficiary' && (
        <>
          <KeyboardDatePicker
            autoOk
            inputVariant="outlined"
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="dateStart"
            label="Date de naissance"
            value={birthDate}
            onChange={onDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            className={classes.birthDate}
          />
          <CustomSwitch
            label="Handicap"
            labelChecked="Activé"
            labelUnchecked="Désactivé"
            name="disabilityRecognition"
            checked={disabilityRecognition ? true : false}
            onChange={onCheckboxChange}
          />
        </>
      )}
      <CustomSwitch
        label="Statut"
        labelChecked="Activé"
        labelUnchecked="Désactivé"
        name="enabled"
        checked={enabled}
        onChange={onCheckboxChange}
      />
    </>
  );
};

export default BasicDialogContent;
