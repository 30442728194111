import 'date-fns';
import React, { FC } from 'react';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

import useStyles from './styles';

interface DateTimePickerProps {
  id: string | number;
  label?: string;
  dateFormat?: string;
  width?: string;
  marginRight?: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  selectedDate: Date;
  disableDateStart?: boolean;
  handleDateChange: React.Dispatch<React.SetStateAction<Date>>;
}

const DateTimePicker: FC<DateTimePickerProps> = (props) => {
  const classes = useStyles();
  const {
    id,
    label,
    dateFormat,
    selectedDate,
    handleDateChange,
    width,
    marginRight,
    disablePast,
    disableFuture,
    disableDateStart,
  } = props;

  return (
    <KeyboardDateTimePicker
      className={classes.formControl}
      disabled={disableDateStart}
      style={{ width, marginRight }}
      name={`${id}`}
      variant="inline"
      inputVariant="outlined"
      format={dateFormat || 'dd/MM/yyyy HH:mm'}
      margin="normal"
      id={`${id}`}
      label={label || ''}
      ampm={false}
      disablePast={disablePast || false}
      disableFuture={disableFuture || false}
      value={selectedDate}
      onChange={handleDateChange as any}
      invalidDateMessage="Format de date non valide"
      maxDateMessage="La date ne doit pas être antérieure à la date maximale"
      minDateMessage="La date ne doit pas être antérieure à la date minimale"
    />
  );
};

export default DateTimePicker;
