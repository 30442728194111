import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiPaper-root': {
        maxWidth: '430px',
      },
      '& .MuiDialogTitle-root': {
        height: '74px',
      },
      '& .MuiDialogContent-root': {
        padding: theme.spacing(0, 4, 4),
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

export default useStyles;
