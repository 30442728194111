import moment from 'moment';
import _ from 'lodash';

export const isValidDate = (obj: any): boolean => {
  const date = new Date(obj);
  return date.toString() !== 'Invalid Date';
};

export const formatDate = (date: Date | '' | null): string =>
  !date ? '-' : moment.utc(date).format('DD/MM/YYYY');

export const formatDateWithHours = (date: Date | ''): string => {
  return !date ? '-' : moment.utc(date).format('DD/MM/YYYY HH:mm:ss');
};

export const calculEndDate = (minutes: number, date: Date): string => {
  const minutesRounded = +minutes.toString().split('.')[0];
  const seconds = _.round((+minutes - minutesRounded) * 60);
  const dt = moment
    .utc(date)
    .add(minutesRounded, 'minutes')
    .add(seconds, 'seconds')
    .format('DD/MM/YYYY HH:mm:ss');
  return dt;
};

export const toHoursAndMinute = (minutes: number): string => {
  const minutesRounded = +minutes.toString().split('.')[0];
  const seconds = +minutes - minutesRounded;
  if (+minutes < 60) {
    if (+minutes < 1 && seconds !== 0) {
      return `${_.round(seconds * 60)}sec`;
    } else if (+minutes > 1 && seconds === 0) {
      return `${_.round(minutes)}min`;
    } else if (+minutes > 1 && seconds !== 0) {
      return `${minutesRounded}min ${_.round(seconds * 60)}sec`;
    }
  }
  const hours = Math.trunc(minutesRounded / 60);
  const minutesRemaining = minutesRounded % 60;
  if (minutesRounded % 60 === 0 && seconds === 0) {
    return `${hours}h`;
  }
  if (minutesRounded % 60 === 0 && seconds !== 0) {
    return `${hours}h ${_.round(seconds * 60)}sec`;
  }
  if (seconds === 0) {
    return `${hours}h ${minutesRemaining}min`;
  }
  return `${hours}h ${minutesRemaining}min ${_.round(seconds * 60)}sec`;
};

export const formatDateString = (dateString: string) => {
  const year = dateString.split('-')[0];
  const month = dateString.split('-')[1];
  let day = dateString.split('-')[2];

  const hourArray = dateString.split('T');
  const hour = hourArray[1].split(':')[0];
  const minutes = hourArray[1].split(':')[1];
  if (day) {
    day = day.slice(0, 2);
  }

  const finalStringDate =
    year + '/' + month + '/' + day + ' ' + hour + ':' + minutes;

  return new Date(finalStringDate) ?? new Date(finalStringDate);
};
