import gql from 'graphql-tag';

import { UNIT_LIFE_FRAGMENT } from './fragment';

export const CREATE_UNIT_LIFE = gql`
  mutation CreateUnitLife($input: CreateUnitLifeInput!) {
    createUnitLife(input: $input) {
      action
      success
      message
      data {
        ...UnitLifeInfo
      }
    }
  }
  ${UNIT_LIFE_FRAGMENT}
`;

export const DELETE_UNIT_LIFE = gql`
  mutation DeleteUnitLife($id: Int!) {
    deleteUnitLife(id: $id) {
      id
      name
      createdAt
    }
  }
`;

export const UPDATE_UNIT_LIFE = gql`
  mutation UpdateUnitLife($input: UpdateUnitLifeInput!) {
    updateUnitLife(input: $input) {
      id
      name
      createdAt
    }
  }
`;
