import gql from 'graphql-tag';

import {
  ADMIN_FRAGMENT,
  WORKER_FRAGMENT,
  BENEFICIARY_FRAGMENT,
  ESTABLISHMENT_FRAGMENT,
  USER_INFO_FRAGMENT,
  ESTABLISHMENT_DETAILS_FRAGMENT,
  STATUS_FRAGMENT,
} from './fragment';

export const GET_ETABLISHMENTS = gql`
  query Etablishments($input: FilterEtablishmentInput) {
    etablishments(input: $input) {
      ...EstablishmentInfo
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;

export const GET_ETABLISHMENT_WITHOUT_ORGANIZATION = gql`
  query EtablishmentWithoutOrganization($input: FilterEtablishmentInput) {
    etablishmentWithoutOrganization(input: $input) {
      ...EstablishmentInfo
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;

export const GET_ETABLISHMENT = gql`
  query Etablishment($id: Int!) {
    etablishment(id: $id) {
      ...EstablishmentInfo
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;

export const GET_ESTABLISHMENT_TYPE = gql`
  query EtablishmentType($id: Int!) {
    etablishment(id: $id) {
      id
      etablishmentType {
        id
        name
        slug
      }
    }
  }
`;

export const GET_ETABLISHMENT_DETAILS = gql`
  query EtablishmentDetails($id: Int!) {
    etablishment(id: $id) {
      ...EstablishmentDetailsInfo
    }
  }
  ${ESTABLISHMENT_DETAILS_FRAGMENT}
`;

export const GET_ETABLISHMENT_NAMES = gql`
  query EtablishmentNames {
    etablishments {
      id
      name
      etablishmentType {
        id
        name
      }
    }
  }
`;

export const GET_ETABLISHMENT_PRESTATIONS = gql`
  query EtablishmentPrestations($id: Int!) {
    etablishment(id: $id) {
      id
      prestations
    }
  }
`;

export const GET_ETABLISHMENT_CONTRIBUTORS_INFO = gql`
  query EtablishmentContributorsInfo($id: Int!) {
    etablishment(id: $id) {
      id
      administrators {
        id
        user {
          ...UserInfoFragment
        }
      }
      workers {
        id
        user {
          ...UserInfoFragment
        }
      }
      beneficiaries {
        id
        guardianEnabled
        guardianName
        user {
          ...UserInfoFragment
        }
      }
      residences {
        id
        name
        address
        rcs
        postalCode
        city
        effective
        logo
        enabled
      }
    }
  }
  ${USER_INFO_FRAGMENT}
`;

export const GET_ETABLISHMENT_BY_USER_ID = gql`
  query GetEtablishmentByUserId($id: Int!) {
    etablishmentByUserId(id: $id) {
      id
      documents {
        id
        name
        path
      }
      etablishmentType {
        id
        name
      }
    }
  }
`;

export const GET_ETABLISHMENT_BENEFICIARIES = gql`
  query EtablishmentBeneficiaries(
    $id: Int!
    $filterBeneficiaries: FilterBeneficiaryInput
  ) {
    etablishment(id: $id) {
      id
      beneficiaries(input: $filterBeneficiaries) {
        id
        birthDate
        guardianEnabled
        guardianName
        disabilityRecognition
        user {
          ...UserInfoFragment
        }
      }
    }
  }
  ${USER_INFO_FRAGMENT}
`;

export const GET_ETABLISHMENT_WORKERS = gql`
  query EtablishmentWorkers($id: Int!) {
    etablishment(id: $id) {
      id
      workers {
        id
        lastVisitDate
        nextMeetingDate
        entryDate
        releaseDate
        occupation
        contract {
          ...StatusInfo
        }
        user {
          ...UserInfoFragment
        }
      }
    }
  }
  ${STATUS_FRAGMENT}
  ${USER_INFO_FRAGMENT}
`;

export const GET_ESTABLISHMENT_CHILDS = gql`
  query EstablishmentChilds(
    $establishmentRootId: Int!
    $filterEstablishmentChild: FilterEstablishementChild
  ) {
    establishmentChilds(
      establishmentRootId: $establishmentRootId
      filterEstablishmentChild: $filterEstablishmentChild
    ) {
      ...EstablishmentInfo
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;
