import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

interface EmptyDataProps {
  text: string;
}

const EmptyData: FC<EmptyDataProps> = (props) => {
  const { text } = props;
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Typography variant="body2" color="textSecondary">
        {text}
      </Typography>
    </Box>
  );
};

export default EmptyData;
