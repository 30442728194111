import React, { FC, useState, ChangeEvent } from 'react';
import useStyles from '../styles';
import { User, UserRole } from '../../../../../../types/user';
import { GroupAction } from '../../../../../../types/table';
import { getUserListColumns } from './UserListColumns';
import CustomTable from '../../../../../Common/CustomTable';
import { ValueType } from 'react-select';
import { USER_LIST_LABEL } from '../constants/UserList.constants';
import UserFormDialog from '../../UserFormDialog';
import { DEFAULT_USER } from '../../UserFormDialog/UserFormDialog.constants';
import { useApolloClient } from '@apollo/react-hooks';
import { SnackbarType } from '../../../../../../types';
import { displaySnackbar } from '../../../../../../common/snackbar';
import { UserListActions } from '../interfaces/UserListActions.interface';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SearchInput from '../../../../../Common/SearchInput';
import ConfirmationDialog from '../../../../../Common/ConfirmationDialog';
import { DELETE_CONFIRMATION_DIALOG_MESSAGE } from '../constants/UserListTable.constants';
import ExcelReader from '../../../../../Common/ExcelReader';

interface UserListTableProps {
  users: User[];
  userRole: UserRole;
  actions: UserListActions;
  loading?: boolean;
  handleClickRow?: (id: number) => void;
  establishmentId: number;
  beneficiaryDocuments?: string;
  setBeneficiaryDocuments?: (value: string) => void;
}

const ACTIONS: GroupAction[] = [
  { label: 'Activer', value: 'enable' },
  { label: 'Désactiver', value: 'disable' },
  { label: 'Supprimer', value: 'remove' },
];

const UserListTable: FC<UserListTableProps> = (props) => {
  const {
    users,
    actions,
    userRole,
    loading,
    handleClickRow,
    establishmentId,
    setBeneficiaryDocuments,
    beneficiaryDocuments,
  } = props;
  const {
    createUser,
    deleteUser,
    updateUser,
    deleteUsers,
    updateUsersStatus,
    importExcel,
  } = actions;
  const classes = useStyles();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [userFormDialogInput, setUserFormDialogInput] =
    useState<User>(DEFAULT_USER);
  const [userFormDialogOpen, setUserFormDialogOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [userFormDialogTitle, setUserFormDialogTitle] = useState<string>(
    `Ajout d'un ${USER_LIST_LABEL[userRole]}`,
  );

  const client = useApolloClient();
  const snackbarInfo: SnackbarType = {
    type: 'INFO',
    message: '',
    isOpen: true,
  };
  const filterUsers = (inputUsers: User[]) => {
    if (!!searchString) {
      return inputUsers.filter((user: User) =>
        `${user.firstName}${user.lastName}`
          .toLowerCase()
          .includes(searchString.toLowerCase()),
      );
    }
    return inputUsers;
  };
  const tableUsers = filterUsers(users);

  const onChangeStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const { checked } = event.target;
    updateUsersStatus([id], checked);
  };

  const onDelete = (id: number) => deleteUser(id);

  const onEdit = (id: number) => {
    const user: User = users.find((user) => user.id === id) as User;
    setUserFormDialogInput(user);
    setUserFormDialogTitle(`Modification d'un ${USER_LIST_LABEL[userRole]}`);
    setUserFormDialogOpen(true);
  };

  const noSelectedIds = (selectedIds: number[]): boolean =>
    !selectedIds || selectedIds.length === 0;

  const onHandleAction = (action: ValueType<GroupAction>): void => {
    const actionGroup = action as GroupAction;
    if (!noSelectedIds(selectedIds)) {
      switch (actionGroup.value) {
        case 'enable':
          updateUsersStatus(selectedIds, true);
          break;
        case 'disable':
          updateUsersStatus(selectedIds, false);
          break;
        case 'remove':
          deleteUsers(selectedIds);
          break;
        default:
          break;
      }
    } else {
      snackbarInfo.autoHideDuration = 5000;
      snackbarInfo.message = `Sélectionnez les ${USER_LIST_LABEL[userRole]}s à ${actionGroup.label}`;
      displaySnackbar(client, snackbarInfo);
    }
  };

  const addUser = () => {
    setUserFormDialogInput({
      ...DEFAULT_USER,
      userRole,
    });
    setUserFormDialogTitle(`Ajout d'un ${USER_LIST_LABEL[userRole]}`);
    setUserFormDialogOpen(true);
  };

  const userFormDialogSubmit = (user: User) => {
    if (user.id) {
      updateUser(user, (res) => {
        // res is undefined when update did not succeed
        res && setUserFormDialogOpen(false);
      });
    } else {
      createUser(user, (res) => {
        res && setUserFormDialogOpen(false);
      });
    }
  };

  const onDeleteUser = (id: number) => {
    setDeleteId(id);
    setOpenConfirmDialog(true);
  };

  const onConfirmDelete = () => {
    onDelete(deleteId);
    setDeleteId(0);
    setOpenConfirmDialog(false);
  };

  const columns = getUserListColumns({
    onChangeStatus,
    onDelete: onDeleteUser,
    onEdit,
    classes,
    userRole,
  });

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchString(value);
  };

  const onExcelReaderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      importExcel({ ...event.target.files });
    }
    event.preventDefault();
  };

  const userToolbars = [
    <SearchInput
      onChange={onSearch}
      value={searchString}
      name={userRole}
      placeholder={`Recherchez un ${USER_LIST_LABEL[userRole]}`}
    />,
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="add-user"
      startIcon={<AddBoxIcon />}
      onClick={() => addUser()}
    >
      Ajouter un {`${USER_LIST_LABEL[userRole]}`}
    </Button>,
    <ExcelReader
      onChange={onExcelReaderChange}
      onClick={() => {}}
      loading={false}
    />,
  ];

  return (
    <>
      <CustomTable
        toolbar
        selectable
        maxWidth={1348}
        data={tableUsers}
        columns={columns}
        toolbarActions={ACTIONS}
        onHandleAction={onHandleAction}
        toolbarButtons={userToolbars}
        toolbarStyles={{ display: 'flex', justifyContent: 'flex-end' }}
        selectedItems={selectedIds}
        setSelectedItems={setSelectedIds}
        loadingData={!!loading}
        handleClickRow={handleClickRow}
      />
      <UserFormDialog
        open={userFormDialogOpen}
        title={userFormDialogTitle}
        onClose={() => {
          setUserFormDialogOpen(false);
        }}
        onSubmit={userFormDialogSubmit}
        user={userFormDialogInput}
        loadingButton={loading}
        establishmentId={establishmentId}
        beneficiaryDocuments={beneficiaryDocuments}
        setBeneficiaryDocuments={setBeneficiaryDocuments}
      />
      <ConfirmationDialog
        title="Suppression"
        message={DELETE_CONFIRMATION_DIALOG_MESSAGE[userRole]}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

export default UserListTable;
