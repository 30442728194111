import gql from 'graphql-tag';
import { PLANNING_INFO } from './fragment';

export const CREATE_PLANNING = gql`
  mutation CreatePlanning($input: CreatePlanningInput!) {
    createPlanning(input: $input) {
      action
      success
      data {
        ...PlanningInfo
      }
    }
  }
  ${PLANNING_INFO}
`;
export const UPDATE_PLANNING_MEMBERS = gql`
  mutation updateMembersPlanning($input: updatePlanningMembersInput!) {
    updateMembersPlanning(input: $input) {
      success
      message
      action
    }
  }
`;

export const DELETE_PLANNING = gql`
  mutation DeletePlanning($id: Int!) {
    deletePlanning(id: $id) {
      ...PlanningInfo
    }
  }
  ${PLANNING_INFO}
`;

export const CREATE_INTERVENTION_PLANNING_MULTIPLE_WORKERS = gql`
  mutation createInterventionPlanningMultipleWorkers(
    $input: CreateInterventionPlanningMultipleWorkersInput!
  ) {
    createInterventionPlanningMultipleWorkers(input: $input) {
      action
      success
      message
      data {
        ...PlanningInfo
      }
    }
  }
  ${PLANNING_INFO}
`;
export const DELETE_PLANNING_IN_INTERVENTION = gql`
  mutation DeletePlanningInIntervention($id: Int!) {
    deletePlanningInIntervention(id: $id) {
      ...PlanningInfo
    }
  }
  ${PLANNING_INFO}
`;
export const DELETE_MULTIPLE_PLANNING = gql`
  mutation DeleteMultiplePlanning($ids: [Int!]!) {
    deleteMultiplePlanning(ids: $ids) {
      action
      success
      message
    }
  }
`;

export const UPDATE_INTERVENTION_PLANNING = gql`
  mutation UpdateInterventionPlanning(
    $input: UpdateInterventionPlanningInput!
  ) {
    updateInterventionPlanning(input: $input) {
      ...PlanningInfo
    }
  }
  ${PLANNING_INFO}
`;

export const CREATE_PLANNING_RESIDENCE = gql`
  mutation CreateInterventionPlanningResidence(
    $input: CreateInterventionPlanningResidenceInput!
  ) {
    createInterventionPlanningResidence(input: $input) {
      action
      success
      message
      data {
        ...PlanningInfo
      }
    }
  }
  ${PLANNING_INFO}
`;

export const CREATE_PLANNINGS_RESIDENCE = gql`
  mutation CreateInterventionsPlanningsResidence(
    $input: [CreateInterventionPlanningResidenceImportInput]!
  ) {
    createInterventionsPlanningsResidence(input: $input) {
      action
      success
      message
      data {
        ...PlanningInfo
      }
    }
  }
  ${PLANNING_INFO}
`;
export const UPDATE_PLANNING_MULTIPLE_STATUS = gql`
  mutation UpdateMultiplePlanningStatus(
    $ids: [Int!]!
    $status: InterventionPlanningEnum!
  ) {
    updateMultipleStatus(ids: $ids, status: $status) {
      action
      success
      message
    }
  }
`;
export const UPDATE_INTERVENTION_PLANNING_STATUS = gql`
  mutation UpdateInterventionPlanningStatus(
    $id: Int!
    $status: InterventionPlanningEnum!
    $includeInterv: Boolean
  ) {
    updateInterventionPlanningStatus(
      id: $id
      status: $status
      includeInterv: $includeInterv
    ) {
      ...PlanningInfo
    }
  }
  ${PLANNING_INFO}
`;

export const CREATE_PLANNINNG_DOMICILE = gql`
  mutation CreateInterventionPlanning(
    $input: CreateInterventionPlanningInput!
  ) {
    createInterventionPlanning(input: $input) {
      action
      success
      message
      data {
        ...PlanningInfo
      }
    }
  }
  ${PLANNING_INFO}
`;

export const VALIDATE_INTERVENTION_PLANNING = gql`
  mutation ValidateInterventionPlanning(
    $input: ValidateInterventionPlanningInput!
  ) {
    validateInterventionPlanning(input: $input)
  }
`;

export const CREATE_PLANNING_CHILD = gql`
  mutation CreatePlanningChild($input: CreatePlanningChildInput!) {
    createPlanningChild(input: $input) {
      action
      success
      message
      data {
        ...PlanningInfo
      }
    }
  }
  ${PLANNING_INFO}
`;
