import React, { FC, useState, useEffect } from 'react';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';

import CustomTable from '../../../../../../Common/CustomTable';
import useStyles from './styles';
import { HeadCell } from '../../../../../../../types/table';
import { GET_PLANNING_DATA } from '../../../../../../../graphql/common/planning/query';
import BeneficiaryList from './BeneficiaryList';
import { SnackbarType } from '../../../../../../../types/snackbar';
import { displaySnackbar } from '../../../../../../../common/snackbar';
import { GET_BENEFICIARIES_IDS } from '../../../../../../../graphql/beneficiary/query';
import {
  BeneficiariesByIds,
  BeneficiariesByIdsVariables,
  BeneficiariesByIds_beneficiariesByIds,
} from '../../../../../../../graphql/beneficiary/types/BeneficiariesByIds';
import ConfirmationDialog from '../../../../../../Common/ConfirmationDialog';
import moment from 'moment';
import { PlanningDataType } from '../../../../../../../types/planning';
import { PLANNING_DATA } from '../../../../../../../constants/planning';

interface ChooseByListProps {
  selectedItems: number[];
  setSelectedItems: any;
}

interface ChoiceByListProps {
  inputData: PlanningDataType;
  setInputData: React.Dispatch<React.SetStateAction<any>>;
}
const ChooseByList: FC<
  ChooseByListProps & RouteComponentProps & ChoiceByListProps
> = (props) => {
  const client = useApolloClient();
  const classes = useStyles();
  const location = useLocation();
  const { selectedItems, setSelectedItems, history, inputData, setInputData } =
    props;
  const [open, setOpen] = useState<boolean>(false);
  const [beneficiaries, setBeneficiaries] = useState<
    (BeneficiariesByIds_beneficiariesByIds | null)[]
  >([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [beneficiaryId, setBeneficiaryId] = useState<number>(0);
  const planningData = client.readQuery({ query: GET_PLANNING_DATA });
  const [isDeleteMany, setIsDeleteMany] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };
  const [getBeneficiariesByIds, { data: benefData, loading: loadingBenef }] =
    useLazyQuery<BeneficiariesByIds, BeneficiariesByIdsVariables>(
      GET_BENEFICIARIES_IDS,
    );

  useEffect(() => {
    if (location.hash.replace('#', '') === 'choix_beneficiaires') {
      setOpen(true);
    }
  }, [location]);

  useEffect(() => {
    if (selectedItems.length !== 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  useEffect(() => {
    if (planningData.planningData) {
      const data = planningData.planningData;

      if (data.beneficiaries) {
        getBeneficiariesByIds({ variables: { ids: data.beneficiaries } });
      }
    }
  }, [planningData]);

  useEffect(() => {
    if (benefData && benefData.beneficiariesByIds) {
      setBeneficiaries(benefData.beneficiariesByIds);
    }
  }, [benefData]);

  const handleDelete = (id: number) => {
    setBeneficiaryId(id);
    setOpenConfirmDialog(true);
  };

  const onDeleteBeneficiary = () => {
    if (!isDeleteMany) {
      removeOneBeneficiary();
    }
    removeManyBeneficiaries();
  };

  const handleSelectBenefList = () => {
    history.push(`${location.pathname}${location.search}#choix_beneficiaires`);
  };

  const removeOneBeneficiary = () => {
    if (
      planningData &&
      planningData.planningData &&
      planningData.planningData.beneficiaries
    ) {
      let allBeneficiaries = planningData.planningData.beneficiaries;
      let beneficiaryIndex = allBeneficiaries.indexOf(beneficiaryId);
      setBeneficiaryId(0);
      allBeneficiaries.splice(beneficiaryIndex, 1);
      client.writeQuery({
        query: GET_PLANNING_DATA,
        data: {
          planningData: {
            ...planningData.planningData,
            beneficiaries: allBeneficiaries,
          },
        },
      });
      setInputData((prevState: any) => ({
        ...prevState,
        beneficiaries: allBeneficiaries,
      }));
      setOpenConfirmDialog(false);
      snackbarData.type = 'SUCCESS';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = `Le bénéficiaire a été supprimé`;
      displaySnackbar(client, snackbarData);
    }
  };

  const removeManyBeneficiaries = async () => {
    let multipleBeneficiaries = planningData.planningData.beneficiaries;

    if (selectedItems) {
      const newList = multipleBeneficiaries.filter(
        (item: any) => !selectedItems.includes(item),
      );
      setSelectedItems([]);

      client.writeQuery({
        query: GET_PLANNING_DATA,
        data: {
          planningData: {
            ...planningData.planningData,
            beneficiaries: newList,
          },
        },
      });
      setInputData((prevState: any) => ({
        ...prevState,
        beneficiaries: newList,
      }));
      setOpenConfirmDialog(false);
      snackbarData.type = 'SUCCESS';
      if (selectedItems.length <= 1) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Le bénéficiaire a été supprimé`;
      } else {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Les bénéficiaires ont été supprimés`;
      }
      setBeneficiaryId(0);
      setIsDisabled(false);
      setIsDeleteMany(false);
      displaySnackbar(client, snackbarData);
    }
  };

  const handleClickDelete = () => {
    setIsDeleteMany(true);
    setOpenConfirmDialog(true);
  };

  const columns: HeadCell[] = [
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.lastName) || '-';
      },
    },
    {
      name: 'lastName',
      label: 'Prénom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.firstName) || '-';
      },
    },
    {
      name: 'birthDate',
      label: 'Date de naissance',
      disablePadding: false,
      renderer: (value: any) => {
        return (
          (value &&
            value.birthDate &&
            moment(value.birthDate).format('DD-MM-YYYY')) ||
          '-'
        );
      },
    },
    {
      name: 'disabilityRecognition',
      label: "Reconnaissance d'handicap",
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.disabilityRecognition ? 'Oui' : 'Non') || '-';
      },
    },
    {
      name: 'mail',
      label: 'Mail',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.email) || '-';
      },
    },
    {
      name: 'mobilePhone',
      label: 'Tél mobile',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.mobilePhone) || '-';
      },
    },
    {
      name: 'phone',
      label: 'Tél fixe',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.phone) || '-';
      },
    },
    {
      name: 'guardianEnabled',
      label: 'Sous tutelle',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.guardianEnabled ? 'Oui' : 'Non') || '-';
      },
    },
    {
      name: 'guardianName',
      label: 'Nom tuteur',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.guardianName) || '-';
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => {
        const statut =
          value.user && value.user.enabled ? 'Activé' : 'Désactivé';
        return statut;
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex" justifyContent="center">
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e: any) => {
                e.stopPropagation();
                handleDelete(value.id);
              }}
              disabled={isDisabled}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const toolbarButtons = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="select-beneficiary"
      onClick={handleSelectBenefList}
    >
      Sélectionner le ou les bénéficiaires
    </Button>,
  ];

  const toolbarActionContent = [
    <Button
      variant="contained"
      color="default"
      name="remove-beneficiary"
      disabled={selectedItems.length == 0}
      onClick={handleClickDelete}
    >
      Supprimer
    </Button>,
  ];

  return (
    <Box className={classes.root}>
      <CustomTable
        toolbar
        selectable
        columns={columns}
        data={beneficiaries}
        loadingData={loadingBenef}
        toolbarButtons={toolbarButtons}
        toolbarActionContent={toolbarActionContent}
        toolbarStyles={{ width: '100%' }}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        useFlexToolbar
        toolbarClasses={classes.flowRow}
      />
      <BeneficiaryList
        inputData={inputData}
        setInputData={setInputData}
        open={open}
        setOpen={setOpen}
      />
      <ConfirmationDialog
        title="Suppression"
        message="Etes-vous sûr de vouloir supprimer ce(s) bénéficiaire(s)?"
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteBeneficiary}
        loadingConfirmation={false}
      />
    </Box>
  );
};

export default withRouter(ChooseByList);
