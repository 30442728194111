import React, { FC, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { InterventionFilter } from '../../../../../InterventionReport/interfaces/intervention-report.interfaces';
import { INITIAL_FILTERS } from '../../../../../InterventionReport/constants/intervention-report.constants';
import { useGetInterventionReports } from '../../../../../InterventionReport/hooks/intervention-report-list.hooks';
import {
  selectFilterOptions,
  toQueryFilter,
} from '../../../../../InterventionReport/utils/intervention-report-list-filter.utils';
import { InterventionReportFilterRecord } from '../../../../../../types';
import FilterControls from '../../../../../InterventionReport/components/FilterControls';
import InterventionReportList from '../../../../../InterventionReport/InterventionReportList';
import { InterventionReportListColumn } from '../../../../../InterventionReport/utils/table-columns.utils';
import {
  FilterTypeEnum,
  InterventionReportFilter,
} from '../../../../../../graphql/types/graphql-global-types';
import { GET_DATA_FILTER } from '../../../../../../graphql/filter/query';
import { useQuery } from '@apollo/react-hooks';

interface InterventionReportContainerProps {
  workerUserId: number;
}

const headerColumns: InterventionReportListColumn[] = [
  'title',
  'status',
  'beneficiary',
  'date',
  'duration',
  'prestation',
  'actions',
];

const InterventionReportContainer: FC<InterventionReportContainerProps> = (
  props,
) => {
  const { workerUserId } = props;
  const [filter, setFilter] = useState<InterventionFilter>(INITIAL_FILTERS);
  const queryFilter: InterventionReportFilter = {
    ...toQueryFilter(filter),
    workerIds: [workerUserId],
  };
  const { data, loading, refetch } = useGetInterventionReports({
    variables: {
      filter: queryFilter,
    },
  });

  const { data: dataFilter, loading: loadingFilter, error } = useQuery(
    GET_DATA_FILTER,
    {
      variables: {
        filterType: {
          filterType: [FilterTypeEnum.BENEFICIARY, FilterTypeEnum.WORKER],
        },
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [filter]);

  const { beneficiary }: InterventionReportFilterRecord = selectFilterOptions(
    dataFilter || [],
  );

  const toolbarFilters = [
    <FilterControls
      filter={filter}
      setFilter={setFilter}
      filterOptions={{ beneficiary }}
      onLoading={loading}
    />,
  ];
  return (
    <InterventionReportList
      filter={filter}
      data={data || []}
      //data={[]}
      loadingData={loading}
      toolbarFilters={toolbarFilters}
      refetch={refetch}
      columns={headerColumns}
      setFilter={setFilter}
    />
  );
};

export default InterventionReportContainer;
