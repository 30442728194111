import React, { FC, useState, useEffect, useContext } from 'react';
import {
  withRouter,
  RouteComponentProps,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';

import UserContext, {
  UserContextRole,
  UserContextType,
} from '../../../../Context/UserContext';
import useStyles from './styles';
import CustomAppBar from '../../../../Common/CustomAppBar';
import CustomAppBarFooter from '../../../../Common/CustomAppBar/Footer';
import CustomAppBarHeader from '../../../../Common/CustomAppBar/Header';
import CustomStepper from '../../../../Common/CustomStepper';
import StepDisplayer from '../../../../Common/CustomStepper/StepDisplayer';
import StepperActions from '../../../../Common/CustomStepper/StepperActions';
import { getComponents } from './stepper';
import { GET_PLANNING_DATA } from '../../../../../graphql/common/planning/query';
import { PLANNING_DATA } from '../../../../../constants/planning';
import { PlanningDataType } from '../../../../../types/planning';
import useQueryString from '../../../../../hooks/useQueryString';
import { GET_ETABLISHMENT_BY_USER_ID } from '../../../../../graphql/etablishment/query';
import {
  GetEtablishmentByUserId,
  GetEtablishmentByUserIdVariables,
} from '../../../../../graphql/etablishment/types/GetEtablishmentByUserId';
import { ApolloError } from 'apollo-boost';
import moment from 'moment';
import { SnackbarType } from '../../../../../types/snackbar';
import { displaySnackbar } from '../../../../../common/snackbar';
import {
  CREATE_INTERVENTION_PLANNING_MULTIPLE_WORKERS,
  CREATE_PLANNING_RESIDENCE,
} from '../../../../../graphql/planning/mutation';
import {
  CreateInterventionPlanningResidence,
  CreateInterventionPlanningResidenceVariables,
} from '../../../../../graphql/planning/types/CreateInterventionPlanningResidence';
import { EstablishmentContext } from '../../../../Context';
import {
  CreateInterventionPlanning,
  CreateInterventionPlanningVariables,
} from '../../../../../graphql/planning/types/CreateInterventionPlanning';
import { getNormalDateTime } from '../../../../../common/utils';
import Loader from '../../../../Common/Loader';
import {
  StyledTableCell,
  StyledTableRow,
} from '../../../../Common/CustomTable/styles';

interface PlanningAddProps {}

const STEPS = [
  'Informations <br/>de base',
  'Choix <br/>de bénéficiaire(s)',
  "Choix <br/>d'intervenant(s)",
];

const PlanningAdd: FC<PlanningAddProps & RouteComponentProps> = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const location = useLocation();
  let { id } = useParams() as any;
  const queryString = useQueryString();
  const userContext = useContext(UserContext);
  const { history } = props;
  const [activeStep, setActiveStep] = useState<number>(0);
  const [inputData, setInputData] = useState<PlanningDataType>(PLANNING_DATA);
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
    autoHideDuration: 10000,
  };
  const { establishmentType } = useContext(EstablishmentContext);
  const planningObj = client.readQuery({
    query: GET_PLANNING_DATA,
  });
  const [isBeneficiary, setIsBeneficiary] = useState<boolean>(false);
  const [isIntervenant, setIsIntervenant] = useState<boolean>(false);
  const { data } = useQuery<
    GetEtablishmentByUserId,
    GetEtablishmentByUserIdVariables
  >(GET_ETABLISHMENT_BY_USER_ID, {
    variables: { id: userContext.userId },
  });

  const [createPlanningResicence, { loading: loadingCreatePlanningResidence }] =
    useMutation<
      CreateInterventionPlanningResidence,
      CreateInterventionPlanningResidenceVariables
    >(CREATE_PLANNING_RESIDENCE, {
      onCompleted: (data: CreateInterventionPlanningResidence) => {
        snackbarData.type = 'SUCCESS';
        snackbarData.message = 'Activité créée avec succès';
        snackbarData.autoHideDuration = 80000;
        client.writeQuery({
          query: GET_PLANNING_DATA,
          data: {
            planningData: {
              ...PLANNING_DATA,
            },
          },
        });
        displaySnackbar(client, snackbarData);
      },
      onError: (error: ApolloError) => {
        snackbarData.type = 'ERROR';
        snackbarData.message =
          "Il y a eu une erreur lors de la création de l'activité";
        displaySnackbar(client, snackbarData);
      },
    });

  const [createPlanningDomicile, { loading: loadingCreatePlanningDomicile }] =
    useMutation<
      CreateInterventionPlanning,
      CreateInterventionPlanningVariables
    >(CREATE_INTERVENTION_PLANNING_MULTIPLE_WORKERS, {
      onCompleted: (data: CreateInterventionPlanning) => {
        snackbarData.type = 'SUCCESS';
        snackbarData.message = 'Activité créée avec succès';
        snackbarData.autoHideDuration = 80000;
        client.writeQuery({
          query: GET_PLANNING_DATA,
          data: {
            planningData: {
              ...PLANNING_DATA,
            },
          },
        });
        displaySnackbar(client, snackbarData);
      },
      onError: (error: ApolloError) => {
        snackbarData.type = 'ERROR';
        snackbarData.message =
          "Il y a eu une erreur lors de la création de l'activité";
        displaySnackbar(client, snackbarData);
      },
    });

  useEffect(() => {
    if (data && data.etablishmentByUserId && data.etablishmentByUserId.id) {
      const inputD = client.readQuery({ query: GET_PLANNING_DATA });
      const establishmentId =
        data && data.etablishmentByUserId && data.etablishmentByUserId.id;
      client.writeQuery({
        query: GET_PLANNING_DATA,
        data: {
          planningData: {
            ...inputD.planningData,
            establishmentId,
          },
        },
      });
      setInputData({
        ...inputData,
        establishmentId,
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      planningObj &&
      planningObj.planningData &&
      planningObj.planningData.beneficiaries
    ) {
      let beneficiariesArray = planningObj.planningData.beneficiaries;
      if (beneficiariesArray.length === 0) {
        setIsBeneficiary(false);
      } else {
        setIsBeneficiary(true);
      }
    } else {
      setIsBeneficiary(false);
    }

    if (
      planningObj &&
      planningObj.planningData &&
      planningObj.planningData.workers
    ) {
      let workersArray = planningObj.planningData.workers;
      if (workersArray.length === 0) {
        setIsIntervenant(false);
      } else {
        setIsIntervenant(true);
      }
    } else {
      setIsIntervenant(false);
    }
  }, [planningObj]);

  const closeModal = () => {
    if (userRole === 'SUPER_ADMIN') {
      history.push(`/etablissements/details/${id}`);
    } else {
      history.push(`/votre-etablissement`);
    }
  };
  const user: UserContextType = useContext(UserContext);
  const userRole: UserContextRole = user.userRole;
  const handleClose = () => {
    client.writeQuery({
      query: GET_PLANNING_DATA,
      data: {
        planningData: {
          ...PLANNING_DATA,
        },
      },
    });
    closeModal();
  };

  useEffect(() => {
    if (
      queryString &&
      queryString.hasOwnProperty('etape') &&
      queryString['etape']
    ) {
      switch (queryString['etape']) {
        case 'information_de_base':
          setActiveStep(0);
          break;
        case 'beneficiaires':
          setActiveStep(1);
          break;
        case 'intervenants':
          setActiveStep(2);
          break;
        default:
          setActiveStep(0);
          history.push({
            pathname: location.pathname,
            search: '?etape=information_de_base',
            hash: location.hash,
          });
          break;
      }
    }
  }, [queryString]);

  const pushCurrentURL = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        history.push({
          pathname: location.pathname,
          search: '?etape=information_de_base',
          hash: location.hash,
        });
        break;
      case 1:
        history.push({
          pathname: location.pathname,
          search: '?etape=beneficiaires',
          hash: location.hash,
        });
        break;
      case 2:
        history.push({
          pathname: location.pathname,
          search: '?etape=intervenants',
          hash: location.hash,
        });
        break;
    }
  };

  const handleNextStep = () => {
    if (activeStep === 0) {
      const inputD = client.readQuery({ query: GET_PLANNING_DATA });

      let occurence = 1;
      let dateEnd = '';

      let datEndSend = new Date();
      datEndSend.setUTCHours(0, 0, 0);
      client.writeQuery({
        query: GET_PLANNING_DATA,
        data: {
          planningData: {
            ...inputD.planningData,
            title: inputData.title,
            location: inputData.location,
            prestation: inputData.prestation,
            dateStart: inputData.dateStart,
            dateEnd: inputData.dateEnd
              ? inputData.dateEnd
              : datEndSend.toUTCString(),
            recurrence: inputData.recurrence,
            comment: inputData.comment,
            hourEnd: inputData.hourEnd,
            hourStart: inputData.hourStart,
            periodicityRepetition: +inputData.periodicityRepetition,
            afterOccurence: inputData.afterOccurence
              ? +inputData.afterOccurence
              : 1,
            monday: inputData.monday,
            tuesday: inputData.tuesday,
            wednesday: inputData.wednesday,
            thursday: inputData.thursday,
            friday: inputData.friday,
            saturday: inputData.saturday,
            sunday: inputData.sunday,
            periodicityType: inputData.periodicityType,
            dateEndType: inputData.dateEndType,
          },
        },
      });
    }

    let currentStep = activeStep + 1;
    setActiveStep(currentStep);
    pushCurrentURL(currentStep);
  };

  const handlePrevStep = () => {
    let currentStep = activeStep - 1;
    setActiveStep(currentStep);
    pushCurrentURL(currentStep);
  };

  const handleFinish = () => {
    closeModal();
    const data = client.readQuery({
      query: GET_PLANNING_DATA,
    });
    if (data && data.planningData) {
      let dateStart = moment(data.planningData.dateStart).toDate();
      let dateEnd = moment(data.planningData.dateEnd).toDate();
      let timeStart = moment(data.planningData.hourStart).format('HH:mm');
      let timeEnd = moment(data.planningData.hourEnd).format('HH:mm');

      const dateTimeStart =
        moment(dateStart).format('YYYY-MM-DD').toString() +
        ' ' +
        data.planningData.hourStart;
      const dateTimeEnd =
        moment(dateEnd).format('YYYY-MM-DD').toString() +
        ' ' +
        data.planningData.hourEnd;

      const dateTimeStartTimestamp = new Date(dateTimeStart)
        .getTime()
        .toString();
      const dateTimeEndTimestamp = new Date(dateTimeEnd).getTime().toString();
      const hoursStart = data.planningData.hourStart.split(':');
      const timeStartWithTimezone = new Date();
      timeStartWithTimezone.setHours(hoursStart[0], hoursStart[1], 0);

      const hourEnd = data.planningData.hourEnd.split(':');
      const timeEndWithTimezone = new Date();
      timeEndWithTimezone.setHours(hourEnd[0], hourEnd[1], 0);

      if (establishmentType === 'DOMICILE') {
        const InputVariables = {
          input: {
            description: data.planningData.title,
            comment: data.planningData.comment,
            prestation: data.planningData.prestation,
            workerIds: data.planningData.workers,
            beneficiaryId: data.planningData.beneficiaries[0],
            etablishmentId: +id || data.planningData.establishmentId,
            location: data.planningData.location,
            dateStart: dateStart,
            dateEnd: dateEnd,
            timeStart: getNormalDateTime(timeStartWithTimezone),
            timeEnd: getNormalDateTime(timeEndWithTimezone),
            type: data.planningData.periodicityType,
            repetition: data.planningData.periodicityRepetition,
            ocurrence: data.planningData.afterOccurence,
            monday: data.planningData.monday,
            tuesday: data.planningData.tuesday,
            wednesday: data.planningData.wednesday,
            thursday: data.planningData.thursday,
            friday: data.planningData.friday,
            saturday: data.planningData.saturday,
            sunday: data.planningData.sunday,
            dateEndType: data.planningData.dateEndType,
            dateTimeStartTimestamp: dateTimeStartTimestamp,
            dateTimeEndTimestamp: dateTimeEndTimestamp,
          },
        };
        createPlanningDomicile({
          variables: InputVariables,
        });
      } else {
        const InputVariables = {
          input: {
            description: data.planningData.title,
            comment: data.planningData.comment,
            prestation: data.planningData.prestation,
            workerIds: data.planningData.workers,
            beneficiaryIds: data.planningData.beneficiaries,
            etablishmentId: +id || data.planningData.establishmentId,
            location: data.planningData.location,
            dateStart: dateStart,
            dateEnd: dateEnd,
            timeStart: getNormalDateTime(timeStartWithTimezone),
            timeEnd: getNormalDateTime(timeEndWithTimezone),
            type: data.planningData.periodicityType,
            repetition: data.planningData.periodicityRepetition,
            ocurrence: data.planningData.afterOccurence,
            monday: data.planningData.monday,
            tuesday: data.planningData.tuesday,
            wednesday: data.planningData.wednesday,
            thursday: data.planningData.thursday,
            friday: data.planningData.friday,
            saturday: data.planningData.saturday,
            sunday: data.planningData.sunday,
            dateEndType: data.planningData.dateEndType,
            dateTimeStartTimestamp: dateTimeStartTimestamp,
            dateTimeEndTimestamp: dateTimeEndTimestamp,
          },
        };
        createPlanningResicence({
          variables: InputVariables,
        });
      }
    }
  };

  const isValid = (): boolean => {
    if (
      inputData.title.trim() &&
      inputData.prestation.trim() &&
      inputData.location.trim() &&
      inputData.hourEnd.trim() &&
      inputData.hourStart.trim() &&
      inputData.dateStart
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isBeneficiariesOK = (): boolean => {
    let checkbeneficiaryArray = inputData.beneficiaries;
    return checkbeneficiaryArray.length > 0;
  };

  const isIntervenantOK = () => {
    let checkWorkerArray = inputData.workers;
    return checkWorkerArray.length > 0;
  };

  const isValidNextButton = () => {
    switch (activeStep) {
      case 0:
        return isValid();
      case 1:
        return isBeneficiariesOK();
      default:
        return false;
    }
  };

  return (
    <CustomAppBar title="Ajout d'une activité" onClose={handleClose}>
      <CustomAppBarHeader>
        <CustomStepper steps={STEPS} activeStep={activeStep} />
      </CustomAppBarHeader>

      <StepDisplayer
        components={getComponents(inputData, setInputData)}
        activeStep={activeStep}
      />

      <CustomAppBarFooter>
        <StepperActions
          activeStep={activeStep}
          stepLength={STEPS.length}
          handlePrevStep={handlePrevStep}
          handleNextStep={handleNextStep}
          handleFinish={handleFinish}
          // handleIgnoreStep={handleIgnoreStep}
          isValidNextButton={isValidNextButton()}
          disableFinishButton={
            !isIntervenantOK() ||
            loadingCreatePlanningDomicile ||
            loadingCreatePlanningResidence
          }
        />
      </CustomAppBarFooter>
    </CustomAppBar>
  );
};

export default withRouter(PlanningAdd);
