export const InterventionImportErrorMessages: { [key: string]: string } = {
  INVALID_DATE: 'Date invalide',
  INVALID_HOUR: 'Heure invalide',
  REQUIRED_FIELD: 'Champs requis manquant',
  INVALID_STATUS: 'Statut invalide',
  INVALID_PERIODICITY: 'Périodicité invalide',
  INVALID_REPETITION: 'Répétition invalide',
  WORKER_NOT_FOUND: "Un intervenant n'a pas été trouvé",
  BENEFICIARY_NOT_FOUND: "Un bénéficiaire n'a pas été trouvé",
  PRESTATION_NOT_FOUND: "Une prestation n'a pas été trouvée",
  BENEFICIARY_DUPLICATED: `Veuillez mettre des "nom + prénom" de bénéficiaires différents dans chaque activité`,
  WORKER_DUPLICATED: `Veuillez mettre des "nom + prénom" d'intervenants différents dans chaque activité`,
};
