import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import InterventionReportList from './InterventionReportList';
import useStyles from './styles';
import { useGetInterventionReports } from './hooks/intervention-report-list.hooks';
import { InterventionReportFilterRecord, SnackbarType } from '../../types';
import {
  selectFilterOptions,
  toQueryFilter,
} from './utils/intervention-report-list-filter.utils';
import { INITIAL_FILTERS } from './constants/intervention-report.constants';
import { InterventionFilter } from './interfaces/intervention-report.interfaces';
import FilterControls from './components/FilterControls';
import { useQuery } from '@apollo/react-hooks';
import { GET_DATA_FILTER } from '../../graphql/filter/query';
import { FilterTypeEnum } from '../../graphql/types/graphql-global-types';
import { useApolloClient } from '@apollo/react-hooks';
import { displaySnackbar } from '../../common';
import { InterventionReportListColumn } from './utils/table-columns.utils';

interface InterventionReportProps {}

/**
 * InterventionReport Component
 * @param props InterventionReportProps
 */
const InterventionReport: FC<InterventionReportProps & RouteComponentProps> = (
  props,
) => {
  const classes = useStyles();
  const [filter, setFilter] = useState<InterventionFilter>(INITIAL_FILTERS);

  const client = useApolloClient();
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const {
    data,
    loading,
    refetch,
    error: errorData,
  } = useGetInterventionReports({
    variables: {
      filter: toQueryFilter(filter),
    },
  });

  useEffect(() => {
    if (errorData) {
      if (errorData.graphQLErrors) {
        snackbarData.message = "Une erreur s'est produite durant la recherche";
      } else {
        snackbarData.message = "Une erreur s'est produite";
      }
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      displaySnackbar(client, snackbarData);
    }
  }, [errorData, displaySnackbar]);

  const [datas, setDatas] = useState<any[]>([]);

  const {
    data: dataFilter,
    loading: loadingFilter,
    error,
  } = useQuery(GET_DATA_FILTER, {
    variables: {
      filterType: { filterType: [FilterTypeEnum.ALL] },
    },
  });

  const filterOptions: InterventionReportFilterRecord = selectFilterOptions(
    dataFilter || [],
  );

  useEffect(() => {
    refetch();
  }, [filter]);

  const noFilter = () => Object.values(filter).every((x) => !x);

  const toolbarFilters = [
    <FilterControls
      filter={filter}
      setFilter={setFilter}
      filterOptions={filterOptions}
      onLoading={loading}
    />,
  ];

  return (
    <Box className={classes.root}>
      <InterventionReportList
        filter={filter}
        data={data || []}
        noFilter={noFilter()}
        loadingData={loading}
        toolbarFilters={toolbarFilters}
        refetch={refetch}
        setFilter={setFilter}
      />
    </Box>
  );
};

export default withRouter(InterventionReport);
