import {
  EstablishmentNewEditStep,
  EstablishmentType,
} from '../../../../../types/establishment';
import EstablishmentBasicInfoForm from '../../EstablishmentBasicInfoForm';
import EstablishmentChoice from '../../EstablishmentChoice/EstablishmentChoice';
import React, { FC } from 'react';
import RootList from '../RootList';
import StepWrapper from './StepWrapper';
import UserEditList from '../UserEditList';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '../../../../Common/CustomCard';
import ProposedPrestations from '../../EstablishmentBasicInfoForm/ProposedPrestations';
import {
  selectedEstablishmentTypeValidator,
  validateEstablishmentBasicInformation,
  validateEstablishmentPrestation,
  validateEstablishmentRootList,
} from '../../../../../utils/establishment-validator.utils';

export const basicInformationsStep: () => EstablishmentNewEditStep = () => {
  return {
    component: ({ actions, state }) => {
      return (
        <EstablishmentBasicInfoForm
          inputData={state.establishment}
          onChange={actions.updateData}
        />
      );
    },
    title: 'Informations <br/>de base',
    stepDatasValidator: (data) =>
      validateEstablishmentBasicInformation(data).valid,
  };
};

export const proposedPrestationStep: () => EstablishmentNewEditStep = () => {
  return {
    component: ({ actions, state }) => {
      return (
        <Box
          display="flex"
          justifyContent="center"
          style={{
            padding: 16,
            marginBottom: 100,
            minHeight: 400,
            marginTop: 8,
          }}
        >
          <Grid item>
            <Card title="Prestations proposées">
              <ProposedPrestations
                inputData={state.establishment}
                setInputData={actions.updateData}
              />
            </Card>
          </Grid>
        </Box>
      );
    },
    title: 'Prestations <br/>proposées ',
    stepDatasValidator: (data) => validateEstablishmentPrestation(data).valid,
  };
};

export const administratorsListStep: () => EstablishmentNewEditStep = () => {
  return {
    component: ({ actions, state }) => {
      const { establishment } = state;
      const { updateData } = actions;
      return (
        <StepWrapper>
          <UserEditList
            userRole="administrator"
            usersIds={establishment.administratorIds}
            establishmentId={establishment.id as number}
            onUsersListIdsChange={(ids: number[]) => {
              updateData({
                ...establishment,
                administratorIds: ids,
              });
            }}
          />
        </StepWrapper>
      );
    },
    title: `Liste <br/>d'administrateur`,
    stepDatasValidator: () => true,
  };
};

export const workersListStep: () => EstablishmentNewEditStep = () => {
  return {
    component: ({ actions, state }) => {
      const { establishment } = state;
      const { updateData } = actions;
      return (
        <StepWrapper>
          <UserEditList
            userRole="worker"
            usersIds={establishment.workerIds}
            establishmentId={establishment.id as number}
            onUsersListIdsChange={(ids: number[]) => {
              updateData({
                ...establishment,
                workerIds: ids,
              });
            }}
          />
        </StepWrapper>
      );
    },
    title: `Liste <br/>d'intervenant`,
    stepDatasValidator: () => true,
  };
};

export const beneficiariesListStep: () => EstablishmentNewEditStep = () => {
  return {
    component: ({ actions, state }) => {
      const { establishment } = state;
      const { updateData } = actions;
      return (
        <StepWrapper>
          <UserEditList
            userRole="beneficiary"
            usersIds={establishment.beneficiaryIds}
            establishmentId={establishment.id as number}
            onUsersListIdsChange={(ids: number[]) => {
              updateData({
                ...establishment,
                beneficiaryIds: ids,
              });
            }}
          />
        </StepWrapper>
      );
    },
    title: `Liste <br/>de bénéficiaire`,
    stepDatasValidator: () => true,
  };
};

export const selectEstablishementTypeStep: () => EstablishmentNewEditStep = () => {
  return {
    component: ({ actions, state }) => {
      const { establishment } = state;
      const { updateData, goNext } = actions;
      return (
        <StepWrapper>
          <EstablishmentChoice
            setType={(id) => {
              goNext();
              updateData({
                ...establishment,
                status: id,
                establishmentType: id as EstablishmentType,
              });
            }}
            noTitle={true}
            noParent={true}
          />
        </StepWrapper>
      );
    },
    title: `Choix <br/> d'établissement`,
    stepDatasValidator: (data) =>
      selectedEstablishmentTypeValidator(data).valid,
  };
};

export const establishmentRootList: () => EstablishmentNewEditStep = () => ({
  component: ({ actions, state }) => (
    <StepWrapper>
      <RootList
        inputData={state.establishment}
        setInputData={actions.updateData}
      />
    </StepWrapper>
  ),
  title: 'Rattachement <br/>à une organisation',
  validStepData: false,
  stepDatasValidator: (data) => validateEstablishmentRootList(data).valid,
});
