import {
  Worker,
  WorkerVariables,
  Worker_worker_user,
} from '../graphql/worker/types/Worker';
import { useReadUserQuery } from './User.hooks';
import { User, WorkerData } from '../types/user';

const toUser = (workerUser: Worker_worker_user): User => {
  const {
    __typename,
    id,
    firstName,
    lastName,
    email,
    phone,
    userFunction,
    mobilePhone,
    enabled,
  } = workerUser;
  return {
    id,
    firstName: firstName || '',
    lastName,
    email,
    phone: phone || '',
    enabled,
    mobilePhone: mobilePhone || '',
    userRole: 'worker',
    userFunction: userFunction || undefined,
  };
};

export const useGetWorker = (id: number) => {
  const { data: workerData, loading } = useReadUserQuery<
    Worker,
    WorkerVariables
  >({
    userRole: 'worker',
    options: {
      variables: {
        id,
      },
    },
  });
  let data: WorkerData | null = null;
  if (workerData && workerData.worker) {
    const { id, lastVisitDate, nextMeetingDate } = workerData.worker;
    data = workerData &&
      workerData.worker && {
        ...toUser(workerData.worker.user as Worker_worker_user),
        workerId: id,
        lastVisitDate,
        nextMeetingDate,
      };
  }
  return {
    data,
    loading,
  };
};
