import gql from 'graphql-tag';

export const GET_ME = gql`
  query GetMe {
    me {
      id
      email
      phone
      enabled
      lastName
      userFunction
      firstName
      mobilePhone
      role {
        name
      }
    }
  }
`;
