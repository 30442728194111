import React, { FC, useState, useEffect } from 'react';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

import useStyles from './styles';
import { PlanningDataType } from '../../../../../../types/planning';
import { HeadCell } from '../../../../../../types/table';
import CustomTable from '../../../../../Common/CustomTable';
import CustomFullscreenDialog from '../../../../../Common/CustomFullscreenDialog';
import WorkerChoiceList from './WorkerChoiceList';
import { useApolloClient, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { GET_PLANNING_DATA } from '../../../../../../graphql/common/planning/query';
import {
  WorkerByIds,
  WorkerByIdsVariables,
  WorkerByIds_workerByIds,
} from '../../../../../../graphql/worker/types/WorkerByIds';
import { GET_WORKERS_IDS } from '../../../../../../graphql/worker/query';
import ConfirmationDialog from '../../../../../Common/ConfirmationDialog';
import { SnackbarType } from '../../../../../../types/snackbar';
import { displaySnackbar } from '../../../../../../common/snackbar';

interface WorkerChoiceProps {
  inputData: PlanningDataType;
  setInputData: React.Dispatch<React.SetStateAction<any>>;
}

const WorkerChoice: FC<WorkerChoiceProps & RouteComponentProps> = (props) => {
  const client = useApolloClient();
  const classes = useStyles();
  const location = useLocation();
  const { inputData, setInputData, history } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [workers, setWorkers] = useState<(WorkerByIds_workerByIds | null)[]>(
    [],
  );
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [workerId, setWorkerId] = useState<number>(0);
  const planningData = client.readQuery({ query: GET_PLANNING_DATA });
  const [isDeleteMany, setIsDeleteMany] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };
  const [getWorkersByIds, { data: workersData, loading: loadingWorkers }] =
    useLazyQuery<WorkerByIds, WorkerByIdsVariables>(GET_WORKERS_IDS);

  useEffect(() => {
    if (location.hash.replace('#', '') === 'choix_intervenant') {
      setOpen(true);
    }
  }, [location]);

  useEffect(() => {
    if (selectedItems.length !== 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  useEffect(() => {
    if (planningData.planningData) {
      const data = planningData.planningData;

      if (data.workers) {
        getWorkersByIds({ variables: { ids: data.workers } });
      }
    }
  }, [planningData]);

  useEffect(() => {
    if (workersData && workersData.workerByIds) {
      setWorkers(workersData.workerByIds);
    }
  }, [workersData]);

  const handleDelete = (id: number) => {
    setWorkerId(id);
    setOpenConfirmDialog(true);
  };

  const handleSelectWorkers = () => {
    history.push(`${location.pathname}${location.search}#choix_intervenant`);
  };

  const onDeleteWorker = () => {
    if (!isDeleteMany) {
      removeOneWorker();
    } else {
      removeManyWorkers();
    }
  };

  const handleClickDelete = () => {
    setIsDeleteMany(true);
    setOpenConfirmDialog(true);
  };

  const removeOneWorker = () => {
    if (
      planningData &&
      planningData.planningData &&
      planningData.planningData.workers
    ) {
      let allWorkers = planningData.planningData.workers;
      let workerIndex = allWorkers.indexOf(workerId);
      setWorkerId(0);
      allWorkers.splice(workerIndex, 1);
      client.writeQuery({
        query: GET_PLANNING_DATA,
        data: {
          planningData: {
            ...planningData.planningData,
            workers: allWorkers,
          },
        },
      });
      setInputData((prevState: any) => ({
        ...prevState,
        workers: allWorkers,
      }));
      setOpenConfirmDialog(false);
      snackbarData.type = 'SUCCESS';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = `L'intervenant a été supprimé`;
      displaySnackbar(client, snackbarData);
    }
  };

  const removeManyWorkers = () => {
    let multipleWorkers = planningData.planningData.workers;
    if (selectedItems) {
      const newList = multipleWorkers.filter(
        (item: any) => !selectedItems.includes(item),
      );
      setSelectedItems([]);
      client.writeQuery({
        query: GET_PLANNING_DATA,
        data: {
          planningData: {
            ...planningData.planningData,
            workers: newList,
          },
        },
      });
      setInputData((prevState: any) => ({
        ...prevState,
        workers: newList,
      }));
      setOpenConfirmDialog(false);
      snackbarData.type = 'SUCCESS';
      snackbarData.autoHideDuration = 5000;
      if (selectedItems.length <= 1) {
        snackbarData.message = `L'intervenant a été supprimé`;
      } else {
        snackbarData.message = `Les intervenants ont été supprimés`;
      }
      setWorkerId(0);
      setIsDisabled(false);
      setIsDeleteMany(false);
      displaySnackbar(client, snackbarData);
    }
  };

  const columns: HeadCell[] = [
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.lastName) || '-';
      },
    },
    {
      name: 'lastName',
      label: 'Prénom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.firstName) || '-';
      },
    },
    {
      name: 'function',
      label: 'Fonction',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.userFunction) || '-';
      },
    },
    {
      name: 'mail',
      label: 'Mail',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.email) || '-';
      },
    },
    {
      name: 'mobilePhone',
      label: 'Tél mobile',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.mobilePhone) || '-';
      },
    },
    {
      name: 'phone',
      label: 'Tél fixe',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.phone) || '-';
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => {
        const statut = value.user && value.user.enabled;
        return statut ? 'Activé' : 'Désactivé';
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex" justifyContent="center">
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e: any) => {
                e.stopPropagation();
                handleDelete(value.id);
              }}
              disabled={isDisabled}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const toolbarButtons = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="select-worker"
      onClick={handleSelectWorkers}
    >
      Sélectionner le ou les intervenants
    </Button>,
  ];

  const toolbarActionContent = [
    <Button
      variant="contained"
      color="default"
      name="remove-worker"
      disabled={selectedItems.length == 0}
      onClick={handleClickDelete}
    >
      Supprimer
    </Button>,
  ];

  return (
    <Box className={classes.root}>
      <CustomTable
        toolbar
        selectable
        useFlexToolbar
        columns={columns}
        data={workers}
        loadingData={false}
        toolbarButtons={toolbarButtons}
        toolbarActionContent={toolbarActionContent}
        toolbarStyles={{ width: 'auto' }}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        toolbarClasses={classes.flowRow}
      />
      <WorkerChoiceList
        open={open}
        setOpen={setOpen}
        inputData={inputData}
        setInputData={setInputData}
      />
      <ConfirmationDialog
        title="Suppression"
        message="Etes-vous sûr de vouloir supprimer cet (ces) intervenant(s) ?"
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteWorker}
        loadingConfirmation={false}
      />
    </Box>
  );
};

export default withRouter(WorkerChoice);
