import { MutationHookOptions } from '@apollo/react-hooks';
import { ApolloClient, ApolloError } from 'apollo-boost';
import {
  UpdateInterventionReport,
  UpdateInterventionReportVariables,
} from '../../../graphql/intervention-report/types/UpdateInterventionReport';
import { showSnackbar } from '../../../utils/snackbar.util';
interface UpdateInterventionReportOptionsArgs {
  client: ApolloClient<object>;
  onSuccess?: () => void;
}

export type OnErrorFunc = (error: ApolloError) => void;
export type OnCompletedFunc = (data: any) => void;

export const updateInterventionReportOptions: (
  args: UpdateInterventionReportOptionsArgs,
) => MutationHookOptions<
  UpdateInterventionReport,
  UpdateInterventionReportVariables
> = ({ client, onSuccess }) => ({
  onError: () => {
    showSnackbar({
      type: 'ERROR',
      client,
      message: `Une erreur s'est produite lors de l'enregistrement`,
    });
  },
  onCompleted: () => {
    showSnackbar({
      type: 'SUCCESS',
      client,
      message: `Rapport d'intervention enregistré avec succès.`,
    });
    onSuccess && onSuccess();
  },
});
