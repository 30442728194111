import gql from 'graphql-tag';

import {
  ESTABLISHMENT_FRAGMENT,
  BENEFICIARY_FRAGMENT,
  UNIT_LIFE_FRAGMENT,
} from './fragment';

export const GET_BENEFICIARIES = gql`
  query Beneficiaries($input: FilterBeneficiaryInput) {
    beneficiaries(input: $input) {
      id
      birthDate
      disabilityRecognition
      guardianEnabled
      signature
      guardianName
      unitLife {
        id
        name
      }
      user {
        id
        firstName
        lastName
        email
        phone
        mobilePhone
        enabled
      }
      etablishment {
        ...EtablishmentInfo
      }
      documents {
        name
        path
      }
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;

export const GET_BENEFICIARY = gql`
  query Beneficiary($id: Int!) {
    beneficiary(id: $id) {
      id
      birthDate
      disabilityRecognition
      guardianEnabled
      signature
      guardianName
      documents {
        name
        path
      }
      user {
        id
        firstName
        lastName
        email
        phone
        mobilePhone
        enabled
      }
      etablishment {
        ...EtablishmentInfo
      }
      unitLife {
        id
        name
      }
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;

export const GET_BENEFICIARIES_IDS = gql`
  query BeneficiariesByIds($ids: [Int!]!) {
    beneficiariesByIds(ids: $ids) {
      id
      guardianEnabled
      guardianName
      birthDate
      user {
        id
        firstName
        lastName
        email
        phone
        mobilePhone
        enabled
      }
      etablishment {
        ...EtablishmentInfo
      }
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;

export const GET_BENEFICIARIES_BY_UNITLIFE = gql`
  query BeneficiariesByUnitLife($unitLife: Int!) {
    beneficiariesByUnitLife(unitLife: $unitLife) {
      ...BeneficiaryInfo
    }
  }
  ${BENEFICIARY_FRAGMENT}
`;

export const GET_BENEFICIARIES_BY_MULTIPLE_UNITLIFE = gql`
  query BeneficiariesByMultipleUniLife(
    $unitLifes: [Int!]!
    $etablishmentId: Int!
  ) {
    beneficiariesByMultipleUniLife(
      unitLifes: $unitLifes
      etablishmentId: $etablishmentId
    ) {
      ...BeneficiaryInfo
    }
  }
  ${BENEFICIARY_FRAGMENT}
`;

export const GET_BENEFICIARY_IN_ETABLISHMENT = gql`
  query BeneficiaryInEtablishment(
    $etablishmentId: Int!
    $input: FilterBeneficiaryInput
  ) {
    beneficiaryInEtablishment(etablishmentId: $etablishmentId, input: $input) {
      ...BeneficiaryInfo
    }
  }
  ${BENEFICIARY_FRAGMENT}
`;
