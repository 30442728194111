import { MyPlanningAdvancedFilters, MyPlanningFilter } from '../../../../../graphql/types/graphql-global-types';
import { AppointmentDataType } from '../../../../../types/planning';
import { recordToOptions } from '../../../../../utils/filter.utils';
import {
  PlanningFilter,
  PlanningFilterRecord,
} from '../interfaces/planning-filter.interfaces';
import { PlanningUser } from '../interfaces/planning.interfaces';

const getAdditionnalRecord = (
  users: PlanningUser[],
  mainRecord: Record<number, string>,
) =>
  users.reduce(
    (result: Record<number, string>, user: PlanningUser) =>
      !mainRecord[user.id]
        ? {
            ...result,
            [user.id]: user.name,
          }
        : result,
    {},
  );

interface AppointmentControlsRecord {
  workersRecord: Record<number, string>;
  beneficiariesRecord: Record<number, string>;
}

const buildAppointmentFilterRecord = (
  appointments: AppointmentDataType[],
): AppointmentControlsRecord => {
  let workersRecord: Record<number, string> = {};
  let beneficiariesRecord: Record<number, string> = {};
  appointments.forEach((appointment) => {
    const { beneficiaries, workers } = appointment;
    workersRecord = {
      ...workersRecord,
      ...getAdditionnalRecord(workers, workersRecord),
    };
    beneficiariesRecord = {
      ...beneficiariesRecord,
      ...getAdditionnalRecord(beneficiaries, beneficiariesRecord),
    };
  });
  return {
    workersRecord,
    beneficiariesRecord,
  };
};

export const selectFilterOptions = (
  appointments: AppointmentDataType[],
): PlanningFilterRecord => {
  const { workersRecord, beneficiariesRecord } = buildAppointmentFilterRecord(
    appointments,
  );
  return {
    worker: recordToOptions(workersRecord),
    beneficiary: recordToOptions(beneficiariesRecord),
  };
};

export const toQueryFilter = (filter: PlanningFilter): MyPlanningAdvancedFilters => {
  let res: MyPlanningAdvancedFilters = {};
  if (filter.beneficiary) {
    res.beneficiaryIds = [filter.beneficiary.value as number];
  }
  if (filter.worker) {
    res.workerIds = [filter.worker.value as number];
  }
  if(filter.keyword){
    res.keyword = filter.keyword;
  }
  if(filter.d1){
    res.d1 = filter.d1;
  }
  if(filter.d2){
    res.d2 = filter.d2;
  }
  return res;
};
