import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';
import DocumentViewer from './DocumentViewer';
import CardAction from './CardAction';

interface DocumentThumbnailProps {
  title: string;
  path: string;
  id: number;
}

const DocumentThumbnail: FC<DocumentThumbnailProps> = (props) => {
  const classes = useStyles();
  const { title, path, id } = props;
  const documentName = path.split('/').pop();

  return (
    <Paper elevation={0} className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      {/* <Typography style={{ textAlign: 'center', paddingTop: 18 }}>
        {documentName}
      </Typography> */}
      <DocumentViewer path={path} id={id} />
      {/* <CardAction /> */}
    </Paper>
  );
};

export default DocumentThumbnail;
