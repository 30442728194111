import React from 'react';
// import useStyles from './styles';

interface NotFoundProps {}

/**
 * NotFound Component
 * @param props NotFoundProps
 */
const NotFound = (props: NotFoundProps) => {
  // const classes = useStyles();
  // const {} = props;

  return <h1>Page not found</h1>;
};

export default NotFound;
