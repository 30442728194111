import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { getLocalDateTime } from '../../../../common/utils';
import {
  GET_MY_PLANNINGS,
  GET_MY_PLANNINGS_ADVANCED_FILTERS,
  GET_PLANNING_BY_ETABLISHMENT,
} from '../../../../graphql/planning/query';
import {
  MyPlannings,
  MyPlanningsVariables,
  MyPlannings_myPlannings,
  MyPlannings_myPlannings_intervention_beneficiaries,
} from '../../../../graphql/planning/types/MyPlannings';
import {
  PlanningByEtablishment,
  PlanningByEtablishmentVariables,
  PlanningByEtablishment_planningByEtablishment,
} from '../../../../graphql/planning/types/PlanningByEtablishment';
import {
  MyPlanningAdvancedFilters,
  MyPlanningFilter,
} from '../../../../graphql/types/graphql-global-types';
import { AppointmentDataType } from '../../../../types/planning';
import {
  PlanningStatusName,
  PlanningUser,
  PlanningUserQueryData,
} from '../../../Establishment/EstablishmentAdmin/Planning/interfaces/planning.interfaces';
import {
  MyPlanningsWithAdvancedFilters,
  MyPlanningsWithAdvancedFiltersVariables,
} from '../../../../graphql/planning/types/MyPlanningsWithAdvancedFilters';

const DEFAULT_STATUS_COLOR = '#8ea4d2';

const StatusColors: Record<PlanningStatusName, string> = {
  CANCELED: '#df5447',
  IN_PROGRESS: '#85c7f2',
  TODO: '#8ea4d2',
  COMPLETED: '#87d68d',
  WAITING_VALIDATION: '#ffb800',
};

const toPlanningUser = (userInput: PlanningUserQueryData): PlanningUser => {
  const { id, user } = userInput;
  const signature =
    (userInput as MyPlannings_myPlannings_intervention_beneficiaries)
      .signature || undefined;
  return {
    id: id,
    userId: user.id,
    name: `${user.firstName} ${user.lastName}`.trim(),
    signature,
  };
};

const toPlanningUsers = (userInputs: PlanningUserQueryData[]): PlanningUser[] =>
  userInputs.map((userInput) => toPlanningUser(userInput));

const getColor = (statusName: PlanningStatusName | null): string => {
  if (!statusName) {
    return DEFAULT_STATUS_COLOR;
  }
  return StatusColors[statusName];
};

export const toAppointmentData = (
  planning: MyPlannings_myPlannings,
): AppointmentDataType => {
  const { intervention } = planning;
  const beneficiaries = intervention?.beneficiaries || [];
  const workers = intervention?.workers || [];
  const statusName: PlanningStatusName | null =
    (planning?.status?.name as PlanningStatusName) || undefined || null;
  let dateStart = new Date();
  if (planning.dateStartTimestamp) {
    dateStart = new Date(parseInt(planning.dateStartTimestamp));
  }
  let dateEnd = new Date();
  if (planning.dateEndTimestamp) {
    dateEnd = new Date(parseInt(planning.dateEndTimestamp));
  }
  return {
    id: planning.id,
    title: planning.title || planning.intervention?.description || '',
    endDate: moment
      .utc(getLocalDateTime(planning.dateEnd))
      .format('YYYY-MM-DD HH:mm'),
    startDate: moment
      .utc(getLocalDateTime(planning.dateStart))
      .format('YYYY-MM-DD HH:mm'),
    prestation: intervention?.prestation || '',
    location: intervention?.location || '',
    beneficiaries: toPlanningUsers(beneficiaries),
    workers: toPlanningUsers(workers),
    color: getColor(statusName),
    timeStart: planning.timeStart,
    timeEnd: planning.timeEnd,
    dateStartTimestamp: planning.dateStartTimestamp,
    dateEndTimestamp: planning.dateStartTimestamp,
    status: statusName,
    comment: planning.comment || intervention?.comment || '',
    checkedForAll: false,
  };
};

export const useGetAppointmentsByEstablishmentId = (
  establishmentId: number,
) => {
  const { loading, refetch, data } = useQuery<
    PlanningByEtablishment,
    PlanningByEtablishmentVariables
  >(GET_PLANNING_BY_ETABLISHMENT, {
    variables: {
      etablishmentId: establishmentId,
    },
  });
  if (!data) {
    return {
      data: [],
      refetch,
      loading,
    };
  }
  return {
    data: data.planningByEtablishment.map((planning) =>
      toAppointmentData(planning),
    ),
    refetch,
    loading,
  };
};

export const useGetMyPlannings = (filter: MyPlanningAdvancedFilters) => {
  const { loading, refetch, data, error } = useQuery<
    MyPlanningsWithAdvancedFilters,
    MyPlanningsWithAdvancedFiltersVariables
  >(GET_MY_PLANNINGS_ADVANCED_FILTERS, {
    variables: {
      filter,
    },
  });
  if (!data) {
    return {
      data: [],
      loading,
      refetch,
      error,
    };
  }
  if (error) {
    return {
      data: [],
      loading,
      refetch,
      error,
    };
  }
  return {
    data: data.myPlanningsAdvancedFilters.map((planning) =>
      toAppointmentData(planning),
    ),
    refetch,
    loading,
    originalData: data.myPlanningsAdvancedFilters,
    error,
  };
};
