import React, { FC, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import useStyles from './styles';
import FilterDate from '../../../Common/SearchFilter/FilterDate';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { InterventionFilter } from '../../interfaces/intervention-report.interfaces';
import {
  InterventionReportFilterRecord,
  SelectOptionsType,
  SnackbarType,
} from '../../../../types';
import { Button } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { MAX_DATE_PLANNING_INTERVAL } from '../../../../constants/DateConfig';
import { useApolloClient } from '@apollo/react-hooks';
import { displaySnackbar } from '../../../../common';

interface FilterControlsProps {
  filter: InterventionFilter;
  setFilter: (filter: InterventionFilter) => void;
  filterOptions: InterventionReportFilterRecord;
  establishmentIds?: number[];
  onLoading: boolean;
}

interface InputValues {
  worker: string;
  beneficiary: string;
  establishment: string;
  status: string;
  rapportTypes: string;
}

const DEFAULT_INPUT_VALUES: InputValues = {
  worker: '',
  beneficiary: '',
  establishment: '',
  status: '',
  rapportTypes: '',
};

const FilterControls: FC<FilterControlsProps> = (props) => {
  const classes = useStyles();
  const { filter, setFilter, filterOptions, establishmentIds, onLoading } =
    props;
  const { beneficiary, establishment, worker, status, rapportTypes } =
    filterOptions;
  const [inputValues, setInputValues] =
    useState<InputValues>(DEFAULT_INPUT_VALUES);
  const [filterTmp, setFilterTmp] = useState<InterventionFilter>(filter);
  const client = useApolloClient();

  useEffect(() => {
    etabChange();
  }, []);

  useEffect(() => {
    setFilterTmp({
      ...filter,
    });
  }, [filter]);

  const handleChange = <T extends {}>(value: T | null, name: string) => {
    setFilterTmp({
      ...filterTmp,
      [name]: value,
    });
  };

  const handleAutocompleteInputChanges = <T extends {}>(
    value: T,
    name: string,
  ) => {
    setInputValues((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const etabChange = () => {
    if (establishmentIds && establishmentIds.length > 0)
      handleChange<SelectOptionsType>(
        { value: establishmentIds[0], label: 'etablishment' },
        'establishment',
      );
  };

  const dateChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const utcDate = new Date(value);
    if (name === 'dateStart') utcDate.setUTCHours(0, 0, 0);
    if (name === 'dateEnd') utcDate.setUTCHours(23, 59, 59);

    handleChange(utcDate, name);
  };

  const onWorkerChanges = (
    event: React.ChangeEvent<{}>,
    value: SelectOptionsType | null,
  ) => handleChange<SelectOptionsType>(value, 'worker');

  const onBeneficiaryChanges = (
    event: React.ChangeEvent<{}>,
    value: SelectOptionsType | null,
  ) => handleChange<SelectOptionsType>(value, 'beneficiary');

  const onEstablishmentChanges = (
    event: React.ChangeEvent<{}>,
    value: SelectOptionsType | null,
  ) => handleChange<SelectOptionsType>(value, 'establishment');

  const onStatusChanges = (
    event: React.ChangeEvent<{}>,
    value: SelectOptionsType | null,
  ) => handleChange<SelectOptionsType>(value, 'status');

  const onRapportTypesChanges = (
    event: React.ChangeEvent<{}>,
    value: SelectOptionsType | null,
  ) => handleChange<SelectOptionsType>(value, 'rapportTypes');

  const optionSelectedComparison = (
    option: SelectOptionsType,
    value: SelectOptionsType | null,
  ) => !!value && option.value === value.value;

  const onClickSearch = () => {
    const interval = Math.abs(
      moment(filterTmp.dateStart).diff(filterTmp.dateEnd, 'days'),
    );
    if (moment(filterTmp.dateStart).isAfter(filterTmp.dateEnd)) {
      const snackbarData: SnackbarType = {
        type: 'ERROR',
        message: 'Veuillez vérifier les dates',
        isOpen: true,
      };
      snackbarData.autoHideDuration = 5000;
      displaySnackbar(client, snackbarData);
    } else
      setFilter({
        ...filterTmp,
      });
  };

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap">
      <Box style={{ marginRight: 16, marginBottom: 10 }}>
        <FilterDate
          label="Période du"
          name="dateStart"
          value={moment.utc(filterTmp.dateStart).format('YYYY-MM-DD') || ''}
          onChange={dateChanges}
          placeholder="Date de début"
          minWidth={200}
        />
      </Box>
      <Box style={{ marginRight: 16, marginBottom: 10 }}>
        <FilterDate
          label="Période au"
          name="dateEnd"
          value={moment.utc(filterTmp.dateEnd).format('YYYY-MM-DD') || ''}
          onChange={dateChanges}
          placeholder="Date de fin"
          minWidth={200}
        />
      </Box>
      {worker && (
        <Box style={{ marginRight: 16, marginBottom: 10 }}>
          <Autocomplete
            options={worker}
            value={filterTmp.worker}
            onChange={onWorkerChanges}
            inputValue={inputValues.worker}
            onInputChange={(e, value) =>
              handleAutocompleteInputChanges(value, 'worker')
            }
            getOptionLabel={(options: SelectOptionsType) => options.label}
            getOptionSelected={optionSelectedComparison}
            className={classes.formControl}
            noOptionsText={'Aucune option'}
            style={{ minWidth: 250 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Intervenant"
                name="worker"
                placeholder="Tous les intervenants"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Box>
      )}

      {filterTmp.rapportTypes?.value !== 2
        ? beneficiary && (
            <Box style={{ marginRight: 16, marginBottom: 10 }}>
              <Autocomplete
                options={beneficiary || []}
                value={filterTmp.beneficiary}
                onChange={onBeneficiaryChanges}
                inputValue={inputValues.beneficiary}
                onInputChange={(e, value) =>
                  handleAutocompleteInputChanges(value, 'beneficiary')
                }
                getOptionLabel={(options: SelectOptionsType) => options.label}
                getOptionSelected={optionSelectedComparison}
                className={classes.formControl}
                noOptionsText={'Aucune option'}
                style={{ minWidth: 250 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bénéficiaire"
                    name="beneficiary"
                    placeholder="Tous les bénéficiaires"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Box>
          )
        : null}

      {establishment && (
        <Box style={{ marginRight: 16, marginBottom: 10 }}>
          <Autocomplete
            options={establishment}
            value={filterTmp.establishment}
            onChange={onEstablishmentChanges}
            inputValue={inputValues.establishment}
            onInputChange={(e, value) =>
              handleAutocompleteInputChanges(value, 'establishment')
            }
            getOptionLabel={(options: SelectOptionsType) => options.label}
            getOptionSelected={optionSelectedComparison}
            className={classes.formControl}
            noOptionsText={'Aucune option'}
            style={{ minWidth: 250 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Etablissement"
                name="establishment"
                placeholder="Tous les établissements"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Box>
      )}
      {status && (
        <Box style={{ marginRight: 16, marginBottom: 10 }}>
          <Autocomplete
            options={status}
            value={filterTmp.status}
            onChange={onStatusChanges}
            inputValue={inputValues.status}
            onInputChange={(e, value) =>
              handleAutocompleteInputChanges(value, 'status')
            }
            getOptionLabel={(options: SelectOptionsType) => options.label}
            getOptionSelected={optionSelectedComparison}
            className={classes.formControl}
            noOptionsText={'Aucune option'}
            style={{ minWidth: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Statut"
                name="status"
                placeholder="Tous les statuts"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Box>
      )}

      {rapportTypes && (
        <Box style={{ marginRight: 16, marginBottom: 10 }}>
          <Autocomplete
            options={rapportTypes}
            value={filterTmp.rapportTypes}
            onChange={onRapportTypesChanges}
            inputValue={inputValues.rapportTypes}
            onInputChange={(e, value) =>
              handleAutocompleteInputChanges(value, 'rapportTypes')
            }
            getOptionLabel={(options: SelectOptionsType) => options.label}
            getOptionSelected={optionSelectedComparison}
            className={classes.formControl}
            noOptionsText={'Aucune option'}
            style={{ minWidth: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type de rapports"
                name="rapportTypes"
                placeholder="Par bénéficiaire"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Box>
      )}
      <Box>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          startIcon={<Search />}
          onClick={onClickSearch}
          disabled={onLoading}
        >
          Rechercher
        </Button>
      </Box>
    </Box>
  );
};

export default FilterControls;
