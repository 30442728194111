import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import UnitLifeEditorWrapper from '../UnitLifeEditorWrapper';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { LifeUnit } from '../../../../../../../types/unit';
import Button from '@material-ui/core/Button';
import { DEFAULT_LIFE_UNIT } from '../../../UnitLife';

interface UnitLifeEditorProps {
  lifeUnit: LifeUnit;
  open: boolean;
  onClose: () => void;
  onSubmit: (lifeUnit: LifeUnit) => void;
  title?: string;
  buttonLabel?: string;
  loading?: boolean;
}

const UnitLifeEditor: FC<UnitLifeEditorProps> = (props) => {
  const {
    title,
    lifeUnit: inputLifeUnit,
    open,
    onClose,
    loading,
    onSubmit,
    buttonLabel,
  } = props;
  const classes = useStyles();
  const [lifeUnit, setLifeUnit] = useState<LifeUnit>(inputLifeUnit);

  const onHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name: controlName, value } = event.target;
    setLifeUnit((prevValue) => ({
      ...prevValue,
      [controlName]: value,
    }));
  };
  const { name } = lifeUnit;

  useEffect(() => {
    setLifeUnit(inputLifeUnit);
  }, [inputLifeUnit]);

  useEffect(() => {
    if (!open) {
      setLifeUnit(DEFAULT_LIFE_UNIT);
    }
  }, [open]);

  return (
    <UnitLifeEditorWrapper open={open} onClose={onClose} title={title}>
      <DialogContent className={classes.content}>
        <TextField
          label="Nom de l'unité de vie"
          name="name"
          placeholder={"Nom de l'unité de vie"}
          value={name}
          onChange={onHandleChange}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
          margin="normal"
          autoComplete="off"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => onSubmit(lifeUnit)}
        >
          {buttonLabel}
        </Button>
      </DialogActions>
    </UnitLifeEditorWrapper>
  );
};

export default UnitLifeEditor;
