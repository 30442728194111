import { Box } from '@material-ui/core';
import { values } from 'lodash';
import {
  InterventionReport,
  InterventionReportData,
} from '../../../types/intervention-report.types';
import { formatDate, toHoursAndMinute } from '../../../utils/date.utils';
import {
  ActionRendererArgs,
  actionsRenderer,
  signatureRenderer,
  SignatureRendererArgs,
} from '../components/Renderers/Renderers';
import {
  COLUMNS_RECORD,
  INTERVENTION_REPORT_LIST_DEFAULT_COLUMNS,
} from '../constants/table-columns.constants';

export type InterventionReportListColumn =
  | 'beneficiary'
  | 'date'
  | 'duration'
  | 'prestation'
  | 'worker'
  | 'signature'
  | 'actions'
  | 'establishment'
  | 'title'
  | 'location'
  | 'status';

export interface RenderersDependencies {
  actionsRendererArgs: ActionRendererArgs;
  signatureRendererArgs: SignatureRendererArgs;
  columns?: InterventionReportListColumn[];
}

type RenderersRecord = Record<
  InterventionReportListColumn,
  (value: InterventionReport) => any
>;

const composeRenderersRecord = (
  dependencies: RenderersDependencies,
): RenderersRecord => {
  const { actionsRendererArgs, signatureRendererArgs } = dependencies;
  return {
    title: (value: InterventionReport) => value.title || '-',
    location: (value: InterventionReport) => value.location || '-',
    status: (value: InterventionReport) => value.status || '-',
    beneficiary: (value: InterventionReport) => value.beneficiary || '-',
    date: (value: InterventionReport) => formatDate(value.createdAt),
    duration: (value: InterventionReport) =>
      toHoursAndMinute(value.duration) || '-',
    prestation: (value: InterventionReport) => value.prestation || '-',
    worker: (value: InterventionReport) => value.worker || '-',
    signature: () => signatureRenderer(signatureRendererArgs) || '-',
    actions: (value: any) => {
      return actionsRenderer({ ...actionsRendererArgs, item: value }) || '-';
    },
    establishment: (value: InterventionReport) => value.etablishment,
  };
};

const composeHeadCells = (
  renderers: RenderersRecord,
  columns: InterventionReportListColumn[],
) =>
  columns.map((column) => ({
    ...COLUMNS_RECORD[column],
    renderer: renderers[column],
  }));

export const makeInterventionReportTable = (
  dependencies: RenderersDependencies,
) => {
  const renderers = composeRenderersRecord(dependencies);
  const { columns } = dependencies;
  const resultColumns = columns
    ? columns
    : INTERVENTION_REPORT_LIST_DEFAULT_COLUMNS;

  return composeHeadCells(renderers, resultColumns);
};
