import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import useStyles from './styles';

interface SearchInputProps {
  name?: string;
  value: string;
  placeholder?: string;
  styles?: any;
  classNames?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickSearch?: (event: React.MouseEvent<HTMLElement>) => void;
}

const SearchInput: FC<SearchInputProps> = (props) => {
  const {
    placeholder,
    name,
    value,
    onChange,
    styles,
    classNames,
    onClickSearch,
  } = props;
  const classes = useStyles();

  return (
    <Box className={`${classes.search} ${classNames}`} style={styles}>
      <InputBase
        placeholder={placeholder || 'Rechercher...'}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        name={name || 'search-key'}
        value={value}
        inputProps={{ 'aria-label': 'rechercher' }}
        onChange={onChange}
      />
      <Box
        onClick={(event) => {
          onClickSearch && onClickSearch(event);
        }}
        className={`${classes.searchIcon} ${
          onClickSearch && classes.searchIconActive
        }`}
      >
        <SearchIcon />
      </Box>
    </Box>
  );
};

export default SearchInput;
