import gql from 'graphql-tag';

import { BENEFICIARY_FRAGMENT, ESTABLISHMENT_FRAGMENT } from './fragment';

export const CREATE_BENEFICIARY = gql`
  mutation CreateBeneficiary(
    $firstName: String
    $email: String!
    $phone: String
    $mobilePhone: String
    $enabled: Boolean
    $establishmentId: Int
    $unitLifeId: Int
    $signature: String
    $birthDate: DateTime
    $beneficiaryDocuments: String
    $disabilityRecognition: Boolean
    $lastName: String!
    $guardianEnabled: Boolean
    $guardianName: String
  ) {
    createBeneficiary(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        mobilePhone: $mobilePhone
        birthDate: $birthDate
        signature: $signature
        disabilityRecognition: $disabilityRecognition
        enabled: $enabled
        unitLifeId: $unitLifeId
        beneficiaryDocuments: $beneficiaryDocuments
        etablishmentId: $establishmentId
        guardianEnabled: $guardianEnabled
        guardianName: $guardianName
      }
    ) {
      action
      success
      message
      data {
        ...BeneficiaryInfo
        etablishment {
          ...EtablishmentInfo
        }
        unitLife {
          id
          name
        }
      }
    }
  }
  ${BENEFICIARY_FRAGMENT}
  ${ESTABLISHMENT_FRAGMENT}
`;

export const CREATE_BENEFICIARIES = gql`
  mutation CreateBeneficiaries($input: [CreateBeneficiaryInput!]!) {
    createBeneficiaries(input: $input) {
      action
      success
      message
      data {
        ...BeneficiaryInfo
        etablishment {
          ...EtablishmentInfo
        }
      }
    }
  }
  ${BENEFICIARY_FRAGMENT}
  ${ESTABLISHMENT_FRAGMENT}
`;

export const UPDATE_BENEFICIARY = gql`
  mutation UpdateBeneficiary(
    $id: Int!
    $firstName: String
    $email: String
    $phone: String
    $mobilePhone: String
    $enabled: Boolean
    $unitLifeId: Int
    $signature: String
    $birthDate: DateTime
    $disabilityRecognition: Boolean
    $lastName: String
    $beneficiaryDocuments: String
    $establishmentId: Int
    $guardianEnabled: Boolean
    $guardianName: String
  ) {
    updateBeneficiary(
      input: {
        id: $id
        firstName: $firstName
        email: $email
        phone: $phone
        mobilePhone: $mobilePhone
        enabled: $enabled
        birthDate: $birthDate
        signature: $signature
        disabilityRecognition: $disabilityRecognition
        unitLifeId: $unitLifeId
        beneficiaryDocuments: $beneficiaryDocuments
        establishmentId: $establishmentId
        lastName: $lastName
        guardianEnabled: $guardianEnabled
        guardianName: $guardianName
      }
    ) {
      ...BeneficiaryInfo
      etablishment {
        ...EtablishmentInfo
      }
    }
  }
  ${BENEFICIARY_FRAGMENT}
  ${ESTABLISHMENT_FRAGMENT}
`;

export const UPDATE_BENEFICIARY_STATUS = gql`
  mutation UpdateBeneficiaryStatus($ids: [Int]!, $status: Boolean!) {
    updateBeneficiaryStatus(ids: $ids, status: $status) {
      ...BeneficiaryInfo
    }
  }
  ${BENEFICIARY_FRAGMENT}
`;

export const DELETE_BENEFICIARY = gql`
  mutation DeleteBeneficiary($id: Int!) {
    deleteBeneficiary(id: $id) {
      id
    }
  }
`;

export const DELETE_BENEFICIARIES = gql`
  mutation DeleteBeneficiaries($ids: [Int]!) {
    deleteBeneficiaries(ids: $ids) {
      id
    }
  }
`;
