import XLSX from 'xlsx';

export const convertExcelToJSON = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = async (event: ProgressEvent<FileReader>) => {
      const bstr = event && event.target && event.target.result;
      const workbooks = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        cellDates: false,
        dateNF: 'dd/mm/yyyy',
        bookVBA: true,
      });
      const wsName = workbooks.SheetNames[0];
      const worksheet = workbooks.Sheets[wsName];
      const data = XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
        dateNF: 'dd/mm/yyyy;@',
      });
      resolve(data);
    };

    reader.onerror = reject;

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });
};
