import { MutationHookOptions } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-boost';
import { UserRole } from '../../../../../../types/user';
import { showSnackbar } from '../../../../../../utils/snackbar.util';
import { capitalizeWord } from '../../../../../../utils/string.utils';
import { USER_LIST_LABEL } from '../constants/UserList.constants';
import { OnCompletedFunc } from '../interfaces/UserEditList.interfaces';

export interface OnDeleteUserMutationOptionsArgs {
  client: ApolloClient<object>;
  userRole: UserRole;
}

export interface OnDeleteUserMutationCompletedArgs {
  client: ApolloClient<object>;
  userRole: UserRole;
}

export interface OnDeleteUserMutationErrorArgs {
  client: ApolloClient<object>;
  userRole: UserRole;
}

const onDeleteUserMutationComplete: (
  args: OnDeleteUserMutationCompletedArgs,
) => OnCompletedFunc = ({ client, userRole }) => () => {
  showSnackbar({
    type: 'SUCCESS',
    message: `${capitalizeWord(
      USER_LIST_LABEL[userRole],
    )} supprimé avec succès.`,
    client,
  });
};
const onDeleteUserMutationError: (
  args: OnDeleteUserMutationErrorArgs,
) => OnCompletedFunc = ({ client, userRole }) => () => {
  showSnackbar({
    type: 'ERROR',
    message: `Une erreur s'est produite lors de la suppression`,
    client,
  });
};

export const onDeleteUserMutationOptions: (
  args: OnDeleteUserMutationOptionsArgs,
) => MutationHookOptions = ({ client, userRole }) => ({
  onCompleted: onDeleteUserMutationComplete({ client, userRole }),
  onError: onDeleteUserMutationError({ client, userRole }),
});
