import React, { FC, useRef, useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import useStyles from './styles';

interface SelectOptionsType {
  label: string;
  value: string | number;
  all?: boolean;
}

interface FilterSelectProps {
  label: string;
  name: string;
  placeholder?: string;
  value: string | number;
  options?: SelectOptionsType[];
  isSearchable?: boolean;
  minWidth?: number;
  maxWidth?: number;
  autoComplete?: boolean;
  autoCompleteValue?: string | number;
  autoCompleteInputValue?: string | number;
  marginRight?: number;
  onChange: (event: React.ChangeEvent<any>) => void;
  onChangeAutoComplete?: (event: React.ChangeEvent<any>, newValue: any) => void;
  onInputChangeAutoComplete?: (
    event: React.ChangeEvent<any>,
    newValue: any,
    name: string,
  ) => void;
}

const FilterSelect: FC<FilterSelectProps> = (props) => {
  const {
    onChange,
    label,
    name,
    value,
    autoCompleteValue,
    options,
    minWidth,
    maxWidth,
    marginRight,
    autoComplete,
    placeholder,
    onChangeAutoComplete,
    autoCompleteInputValue,
    onInputChangeAutoComplete,
  } = props;
  const classes = useStyles();
  const [labelWidth, setLabelWidth] = useState<number>(10);
  const inputLabel = useRef<any>(null);

  useEffect(() => {
    if (inputLabel && inputLabel.current && inputLabel.current.offsetWidth) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  const onHandleChange = (
    event: React.ChangeEvent<any>,
    newValue: any,
    name: any,
  ) => {
    if (onInputChangeAutoComplete) {
      onInputChangeAutoComplete(event, newValue, name);
    }
  };

  const getWidth = () => {
    let classes = {};
    if (minWidth) {
      classes = {
        ...classes,
        minWidth,
      };
    }
    if (maxWidth) {
      classes = {
        ...classes,
        maxWidth,
      };
    }
    return classes;
  };

  return (
    <>
      {autoComplete ? (
        <Autocomplete
          options={options || []}
          value={(autoCompleteValue as any) || ''}
          onChange={onChangeAutoComplete}
          inputValue={(autoCompleteInputValue as any) || ''}
          onInputChange={(e, value) => onHandleChange(e, value, name)}
          getOptionLabel={(option: SelectOptionsType) => option && option.label}
          className={classes.formControl}
          id={`controllable-autocomplete-${name}`}
          style={{ ...getWidth(), marginRight }}
          noOptionsText={'Aucune option'}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              name={name}
              placeholder={placeholder}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      ) : (
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ ...getWidth(), marginRight }}
        >
          <InputLabel
            shrink
            ref={inputLabel}
            htmlFor={`simple-select-outlined-label-${name}`}
          >
            {label}
          </InputLabel>
          <Select
            id={`simple-select-outlined-${name}`}
            value={value}
            onChange={onChange}
            input={
              <OutlinedInput
                notched
                name={name}
                labelWidth={labelWidth}
                placeholder="Selectionner..."
                id={`simple-select-outlined-label-${name}`}
              />
            }
          >
            {(options || []).map(
              ({ label, value }: SelectOptionsType, index: number) => (
                <MenuItem key={`${value}-${index}`} value={value}>
                  {label}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default FilterSelect;
