import { UserRole, UserEditDatas, UserEditData } from '../types/user';

export const USER_ROLE_LABEL: Record<UserRole, string> = {
  administrator: "de l'administrateur",
  worker: "de l'intervenant",
  beneficiary: 'du bénéficiaire',
};

export const EMPTY_USER_EDIT_DATA: UserEditData = {
  __typename: '',
  deletedUsersIds: [],
  newUsersIds: [],
};

export const EMPTY_USER_EDIT_DATAS: UserEditDatas = {
  __typename: 'UserEditDatas',
  administrator: {
    ...EMPTY_USER_EDIT_DATA,
    __typename: 'administrator',
  },
  beneficiary: {
    ...EMPTY_USER_EDIT_DATA,
    __typename: 'beneficiary',
  },
  worker: {
    ...EMPTY_USER_EDIT_DATA,
    __typename: 'worker',
  },
};
