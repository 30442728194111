import {
  UpdateUsersStatusOptionsArgs,
  OnUpdateUsersStatusErrorArgs,
  OnUpdateUsersStatusCompletedArgs,
} from '../interfaces/UpdateUserStatusOptions.interfaces';
import { MutationHookOptions } from '@apollo/react-hooks';
import {
  OnErrorFunc,
  OnCompletedFunc,
} from '../interfaces/UserEditList.interfaces';
import { ApolloError } from 'apollo-boost';
import { showSnackbar } from '../../../../../../utils/snackbar.util';
import {
  buildUpdateUsersStatusMessage,
  buildUpdateUserStatusMessage,
} from '../constants/UserList.constants';

const onUpdateUsersStatusMutationError: (
  args: OnUpdateUsersStatusErrorArgs,
) => OnErrorFunc = ({ client }) => (error: ApolloError) => {
  showSnackbar({
    type: 'ERROR',
    client,
    message: `Une erreur s'est produite lors de l'enregistrement.`,
  });
};

const onUpdateUsersStatusMutationCompleted: (
  args: OnUpdateUsersStatusCompletedArgs,
) => OnCompletedFunc = ({ client, userRole, options }) => () => {
  const { numbers, enabled } = options;
  let message = '';
  if (numbers > 1) {
    message = buildUpdateUsersStatusMessage(enabled, userRole);
  } else {
    message = buildUpdateUserStatusMessage(enabled, userRole);
  }
  showSnackbar({
    client,
    message,
    type: 'SUCCESS',
  });
};

export const onUpdateUserStatusMutationOptions: (
  args: UpdateUsersStatusOptionsArgs,
) => MutationHookOptions = ({ client, userRole, options }) => ({
  onError: onUpdateUsersStatusMutationError({ client, userRole }),
  onCompleted: onUpdateUsersStatusMutationCompleted({
    client,
    userRole,
    options,
  }),
});
