import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    details: {
      maxWidth: 1080,
      width: 1080,
      margin: 'auto',
      marginBottom: 48,

      [theme.breakpoints.down(1120)]: {
        width: '100%',
      },
    },
  }),
);

export default useStyles;
