import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import fr from 'date-fns/locale/fr';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Login from './components/Authentication/Login';
import ResetPassword from './components/Authentication/ResetPassword';
import CreatePassword from './components/Authentication/CreatePassword';
import ResetPasswordSuccess from './components/Authentication/ResetPasswordSuccess';
import ForgotPassword from './components/Authentication/ForgotPassword';
import NotFound from './components/Error/NotFound';
import GlobalSnackbar from './components/Common/GlobalSnackbar';
import theme from './theme';
import RouteManager from './components/Route/RouteManager';

const App = () => (
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
      <GlobalSnackbar />
      <BrowserRouter>
        <Switch>
          {/** Public routes */}
          <Route exact path="/authentification" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route
            exact
            path="/reset-password/:userId/:token"
            component={ResetPassword}
          />
          <Route
            exact
            path="/reset-password-success"
            component={ResetPasswordSuccess}
          />
          <Route
            exact
            path="/invitation/:userId/:token"
            component={CreatePassword}
          />

          {/** Private routes, for authenticated user */}
          <RouteManager />

          {/** Error redirection page */}
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);

export default App;
