import { LazyQueryHookOptions, MutationHookOptions } from '@apollo/react-hooks';
import { ApolloClient, ApolloError } from 'apollo-boost';
import {
  Etablishment,
  EtablishmentVariables,
} from '../../../../graphql/etablishment/types/Etablishment';
import {
  Etablishments,
  EtablishmentsVariables,
} from '../../../../graphql/etablishment/types/Etablishments';
import { showSnackbar } from '../../../../utils/snackbar.util';

export interface ExportEstablishmentOptionsArgs {
  client: ApolloClient<object>;
}

export interface ExportEstablishmentOptionsOnCompleted {
  client: ApolloClient<object>;
}

export interface ExportEstablishmentOptionsOnError {
  client: ApolloClient<object>;
}

export const exportEstablishmentOptionsOnCompleted = ({
  client,
}: ExportEstablishmentOptionsOnCompleted) => (data: Etablishments) => {};

export const exportEstablishmentOptionsOnError = ({
  client,
}: ExportEstablishmentOptionsOnError) => (error: ApolloError) => {
  let message = `Une erreur s'est produite lors de la récupération des informations de l'établissement`;
  if (error.networkError) {
    message = 'Erreur de réseau. Veuillez vérifier votre connexion internet';
  }
  showSnackbar({
    client,
    message,
    type: 'ERROR',
  });
};

export const exportEstablishmentsOptions = ({
  client,
}: ExportEstablishmentOptionsArgs): LazyQueryHookOptions<
  Etablishments,
  EtablishmentsVariables
> => ({
  onCompleted: exportEstablishmentOptionsOnCompleted({ client }),
  onError: exportEstablishmentOptionsOnError({ client }),
});
