import moment from 'moment';
import { Beneficiary_beneficiary } from '../../../graphql/beneficiary/types/Beneficiary';

export interface BeneficiaryExcelFormat {
  nom: string;
  prenom: string;
  email: string;
  tel_mobile: string;
  tel_fixe: string;
  statut: string;
  date_de_naissance: string;
  unite_de_vie: string;
  handicape: string;
  sous_tutelle: string;
  nom_tuteur: string;
  etablissement: string;
}

export const formatBeneficiaryData = (
  beneficiary: Beneficiary_beneficiary,
): BeneficiaryExcelFormat | {} => {
  if (!beneficiary) return {};

  return {
    nom: (beneficiary.user && beneficiary.user.lastName) || '',
    prenom: (beneficiary.user && beneficiary.user.firstName) || '',
    email: (beneficiary.user && beneficiary.user.email) || '',
    tel_mobile: (beneficiary.user && beneficiary.user.mobilePhone) || '',
    tel_fixe: (beneficiary.user && beneficiary.user.phone) || '',
    date_de_naissance:
      (beneficiary &&
        beneficiary.birthDate &&
        moment(beneficiary.birthDate).format('DD/MM/YYYY').toString()) ||
      '',
    unite_de_vie:
      (beneficiary && beneficiary.unitLife && beneficiary.unitLife.name) || '',
    handicape: beneficiary && beneficiary.disabilityRecognition ? 'oui' : 'non',
    sous_tutelle: beneficiary && beneficiary.guardianEnabled ? 'oui' : 'non',
    nom_tuteur: (beneficiary && beneficiary.guardianName) || '',
    statut:
      beneficiary.user && beneficiary.user.enabled ? 'Activé' : 'Désactivé',
    etablissement:
      (beneficiary.etablishment && beneficiary.etablishment.name) || '',
  };
};
