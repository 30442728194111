import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    uploadArea: {
      width: '100%',
      height: 300,
      margin: theme.spacing(2, 0),
      border: '1px dashed #9E9E9E',
      borderRadius: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 16,
      },
    },
    borderSolid: {
      borderStyle: 'solid',
    },
    uploadText: {
      fontSize: 14,
      textAlign: 'center',
    },
    uploadButton: {
      '& .MuiButton-label': {
        textTransform: 'capitalize',
        color: '#ED1C26',
      },
    },
    link: {
      color: '#ED1C26',
      textDecoration: 'underline',
    },
    dropzone: {
      textAlign: 'center',
      padding: 20,
      width: '100%',
      border: '3px dashed #eeeeee',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
    },
    hidden: {
      visibility: 'hidden',
      height: '0px',
    },
    iframe: {
      zIndex: 9,
      position: 'relative',
      top: '-110px',
    },
    logo: {
      width: '100%',
      height: '100%',
      // objectFit: 'scale-down',
    },
  }),
);

export default useStyles;
