import { ApolloClient } from 'apollo-boost';

import { SnackbarType } from '../types/snackbar';
import { displaySnackbar } from './snackbar';

export const isValidEmail = (email: string): boolean => {
  const regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  return email !== undefined && regexEmail.test(email.trim());
};

export const isValidPostalCode = (code: string) => {
  const regexCode = /^[0-9]{3,10}$/;
  return code && regexCode.test(code.trim());
};

export const checkActionGroup = (
  selectedItems: number[],
  client: ApolloClient<any>,
  message?: string,
): boolean => {
  if (selectedItems.length > 0) return true;

  const snackbarData: SnackbarType = {
    type: 'INFO',
    message: message || `Sélectionnez les éléments avant de procéder l'action.`,
    isOpen: true,
  };
  snackbarData.autoHideDuration = 5000;
  displaySnackbar(client, snackbarData);
  return false;
};

export const isGlobalList = (dataSource: string) => {
  return dataSource && dataSource === 'all';
};
