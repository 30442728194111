import { ApolloClient } from 'apollo-boost';

import { SnackbarType } from '../types/snackbar';

export const displaySnackbar = (
  client: ApolloClient<any>,
  data: SnackbarType,
) => {
  client.writeData({
    data: {
      snackBar: {
        ...data,
        __typename: 'SnackBar',
      },
    },
  });
};
