import React, { FC, useState, useEffect, useContext } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useStyles from './styles';
import EstablishmentDetailsHeader from '../EstablishmentDetails/EstablishmentDetailsHeader';
import EstablishmentDetailsContent from '../EstablishmentDetails/EstablishmentDetailsContent';
import EstablishmentDetailsInfos from '../EstablishmentDetails/EstablishmentDetailsInfos';
import PrestationList from '../EstablishmentDetails/PrestationList';
import { GET_ETABLISHMENT_DETAILS } from '../../../graphql/etablishment/query';
import {
  EtablishmentDetails,
  EtablishmentDetailsVariables,
} from '../../../graphql/etablishment/types/EtablishmentDetails';
import {
  EstablishmentPrestationData,
  ExtendedEstablishmentInfoData,
} from '../EstablishmentDetails/types';
import {
  INIT_ESTABLISHMENT_INFO,
  INIT_ESTABLISHMENT_PRESTATIONS,
} from '../EstablishmentDetails/types';
import { EstablishmentTypeType } from '../../../types/establishment';
import { GET_ETABLISHMENT_CONTRIBUTORS_INFO } from '../../../graphql/etablishment/query';
import {
  EtablishmentContributorsInfo,
  EtablishmentContributorsInfoVariables,
  EtablishmentContributorsInfo_etablishment,
} from '../../../graphql/etablishment/types/EtablishmentContributorsInfo';
import EstablishmentProvider from '../../Context/EstablishmentContext';
import UserContext from '../../Context/UserContext';
import {
  Prestations,
  PrestationsVariables,
} from '../../../graphql/prestation/types/Prestations';
import { GET_PRESTATIONS } from '../../../graphql/prestation/query';
interface EstablishmentAdminProps {
  establishmentId: number;
  redirectionUrl?: string;
  hideTitle?: boolean;
}
const EstablishmentAdmin: FC<EstablishmentAdminProps> = (props) => {
  const { establishmentId, redirectionUrl, hideTitle } = props;
  const classes = useStyles();
  const user = useContext(UserContext);
  const [establishmentType, setEstablishmentType] =
    useState<EstablishmentTypeType | string>('');

  const [etabInfoData, setEtabInfoData] =
    useState<ExtendedEstablishmentInfoData>({
      ...INIT_ESTABLISHMENT_INFO,
      residences: null,
    });

  const [etabPrestationData, setEtabPrestationData] =
    useState<EstablishmentPrestationData>(INIT_ESTABLISHMENT_PRESTATIONS);

  const variables = {
    input: {
      establishmentId: establishmentId,
    },
  };

  const {
    loading: loadingPrestationList,
    data: prestationList,
    refetch,
  } = useQuery<Prestations, PrestationsVariables>(GET_PRESTATIONS, {
    variables,
  });

  const [contributorInfos, setContributorInfos] =
    useState<EtablishmentContributorsInfo_etablishment | null>(null);

  const { data } = useQuery<EtablishmentDetails, EtablishmentDetailsVariables>(
    GET_ETABLISHMENT_DETAILS,
    {
      variables: { id: establishmentId },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {},
      onError: (error) => {
        console.log('err : ', error);
      },
    },
  );

  const { data: establishmentData } = useQuery<
    EtablishmentContributorsInfo,
    EtablishmentContributorsInfoVariables
  >(GET_ETABLISHMENT_CONTRIBUTORS_INFO, {
    variables: { id: establishmentId },
  });

  useEffect(() => {
    if (establishmentData && establishmentData.etablishment) {
      const residenceParent =
        (establishmentData.etablishment.residences &&
          establishmentData.etablishment.residences[0]) ||
        null;
      setContributorInfos(establishmentData.etablishment);
      setEtabInfoData({
        ...etabInfoData,
        residences: residenceParent,
      });
    } else {
    }
  }, [establishmentData]);

  useEffect(() => {
    if (data && data.etablishment) {
      const establishment = data.etablishment;
      const establishmentType =
        establishment &&
        establishment.etablishmentType &&
        establishment.etablishmentType.name;
      if (establishmentType) {
        setEstablishmentType(establishmentType as EstablishmentTypeType);
      }
      setEtabInfoData({
        ...etabInfoData,
        name: establishment.name,
        logo: establishment.logo || '',
        enabled: establishment.enabled || false,
        rcs: establishment.rcs || '',
        address: establishment.address || '',
        type: establishmentType || '',
        codePostal: establishment.postalCode || '',
        ville: establishment.city || '',
        objet: establishment.etablishmentObject || '',
        status:
          (establishment.etablishmentStatus &&
            establishment.etablishmentStatus.name) ||
          '',
      });
      setEtabPrestationData({
        prestations: establishment.prestations || '',
      });
    }
  }, [data]);

  return (
    <Box className={classes.root}>
      <EstablishmentDetailsHeader
        userRole={user.userRole}
        establishmentId={establishmentId}
        establishmentType={establishmentType}
        hideTitle={hideTitle}
        redirectionUrl={redirectionUrl}
      />
      <Row style={{ marginBottom: 48 }}>
        <Col sm={12} xl={3}>
          <Box className={classes.details}>
            <Box className={classes.infos}>
              <EstablishmentDetailsInfos
                id={establishmentId}
                data={etabInfoData}
                userRole={user.userRole}
                setData={setEtabInfoData}
              />
            </Box>
          </Box>
        </Col>
        <Col sm={12} xl={9}>
          <EstablishmentDetailsContent
            type={establishmentType}
            establishmentId={establishmentId}
            data={null}
          />
        </Col>
      </Row>
    </Box>
  );
};
export default EstablishmentAdmin;
