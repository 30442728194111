import gql from 'graphql-tag';
import { PLANNING_CHILD, PLANNING_DISPLAY, PLANNING_INFO } from './fragment';

export const GET_PLANNING_BY_ID = gql`
  query Planing($id: Int!) {
    planning(id: $id) {
      id
      beneficiarySignature
      dateStart
      dateEnd
      timeStart
      timeEnd
      ocurrence
      createdAt
      notification {
        id
      }
      comment
      title
      intervention {
        id
      }
      interventionReport {
        id
      }
      beneficiary {
        id
      }
      worker {
        id
      }
      status {
        id
      }
      periodicity {
        id
      }
      dateStartTimestamp
      dateEndTimestamp
      idParent
    }
  }
`;

export const GET_PLANNING_BY_INTERVENTION = gql`
  query PlanningByIntervention($interventionId: Int!) {
    planningByIntervention(interventionId: $interventionId) {
      ...PlanningInfo
    }
  }
  ${PLANNING_INFO}
`;

export const GET_PLANNING_BY_ETABLISHMENT = gql`
  query PlanningByEtablishment($etablishmentId: Int!) {
    planningByEtablishment(etablishmentId: $etablishmentId) {
      ...PlanningDisplay
    }
  }
  ${PLANNING_DISPLAY}
`;

export const GET_MY_PLANNINGS = gql`
  query MyPlannings($filter: MyPlanningFilter!) {
    myPlannings(filter: $filter) {
      ...PlanningDisplay
    }
  }
  ${PLANNING_DISPLAY}
`;

export const GET_PLANNING_CHILDS = gql`
  query PlanningChilds {
    planningChilds {
      ...PlanningChild
    }
  }
  ${PLANNING_CHILD}
`;

export const GET_MY_PLANNINGS_ADVANCED_FILTERS = gql`
  query MyPlanningsWithAdvancedFilters($filter: MyPlanningAdvancedFilters!) {
    myPlanningsAdvancedFilters(filter: $filter) {
      ...PlanningDisplay
    }
  }
  ${PLANNING_DISPLAY}
`;
export const GET_WORKERS_NOT_IN_PLANNING = gql`
  query WorkersNotInPlanning($Idplanning: [Int!]!) {
    workersNotInPlanning(planningId: $Idplanning) {
      id
      user {
        id
        firstName
        lastName
        email
        phone
        userFunction
        mobilePhone
        enabled
      }
      etablishment {
        id
        name
      }
    }
  }
`;
export const GET_BENEFICIAIRES_NOT_IN_PLANNING = gql`
  query BenefsNotInPlanning($Idplanning: [Int!]!) {
    beneficiaryNotInPlanning(planningId: $Idplanning) {
      id
      guardianEnabled
      guardianName
      birthDate
      user {
        id
        firstName
        lastName
        email
        phone
        mobilePhone
        enabled
      }
      etablishment {
        id
        name
      }
      unitLife {
        id
        name
      }
    }
  }
`;
export const GET_PLANNING_BENEFICIARIES = gql`
  query PlanningBenefs($Idplanning: [Int!]!) {
    planningBeneficiaries(interventionId: $Idplanning) {
      id
      guardianEnabled
      guardianName
      birthDate
      user {
        id
        firstName
        lastName
        email
        phone
        mobilePhone
        enabled
      }
      etablishment {
        id
        name
      }
      unitLife {
        id
        name
      }
    }
  }
`;
export const GET_PLANNING_WORKERS = gql`
  query PlanningWorkers($Idplanning: [Int!]!) {
    planningWorkers(interventionId: $Idplanning) {
      id
      user {
        id
        firstName
        lastName
        email
        phone
        userFunction
        mobilePhone
        enabled
      }
      etablishment {
        id
        name
      }
    }
  }
`;

export const GET_PRESTATION_BY_PLANNING = gql`
  query PrestationByPlanning($planningId: Int!) {
    prestationByPlanning(planningId: $planningId)
  }
`;
