import React, { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box/Box';

import useStyles from './styles';

interface LoaderProps {
  size?: number;
}

const Loader: FC<LoaderProps> = (props) => {
  const { size } = props;
  const classes = useStyles();

  return (
    <Box className={classes.loader}>
      <CircularProgress color="primary" size={size} />
    </Box>
  );
};

export default Loader;
