import gql from 'graphql-tag';

export const INTERVENTION_FRAGMENT = gql`
  fragment InterventionInfo on Intervention {
    id
    dateStart
    dateEnd
    description
    interventionRecurrence {
      id
    }
    etablishment {
      id
    }
  }
`;

export const INTERVENTION_DETAILS_FRAGMENT = gql`
  fragment InterventionDetailsInfo on Intervention {
    id
    title: description
    endDate: dateEnd
    startDate: dateStart
    interventionRecurrence {
      id
    }
  }
`;

export const INTERVENTION_ALL_DETAILS_FRAGMENT = gql`
  fragment InterventionAllDetailsInfo on Intervention {
    id
    dateStart
    dateEnd
    comment
    prestation
    description
    interventionRecurrence {
      id
    }
    etablishment {
      id
    }
    beneficiaries {
      id
    }
    workers {
      id
    }
    location
    interventionRecurrence {
      name
    }
  }
`;
