import React, { FC } from 'react';
import {
  ActionsAbstraction,
  EstablishmentDataType,
} from '../../../../../../types/establishment';
import { EstablishmentEditorState } from './establishmentEditor.reducer';

interface StepsManagerProps {
  actions: ActionsAbstraction<EstablishmentDataType>;
  state: EstablishmentEditorState;
}

const StepManager: FC<StepsManagerProps> = (props) => {
  const { actions, state } = props;
  const currentStep = state.steps[state.activeStep];
  const component = currentStep.component({ actions, state });
  return <>{component}</>;
};

export default StepManager;
