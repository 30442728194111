import React, { FC } from 'react';

interface StepManagerProps {
  components: any[];
  activeStep: number;
}

const StepDisplayer: FC<StepManagerProps> = (props) => {
  const { components, activeStep } = props;
  return <>{components[activeStep]}</>;
};

export default StepDisplayer;
