import { UserValidationMessages } from '../types/user-validator.types';

export const EXCEL_IMPORT_USERS_VALIDATION_MESSAGES: UserValidationMessages = {
  EMPTY_EMAIL: `Un ou plusieurs adresse email sont manquant dans votre fichier`,
  EMPTY_FIRST_NAME: ``,
  FUNCTION_MUTS_STRING: `La fonction doit contenir des lettres`,
  EMPTY_LAST_NAME: `Un ou plusieurs noms manquants`,
  EMPTY_MOBILE_PHONE: `Un ou plusieurs numéro de téléphone mobile manquants`,
  EMPTY_GUARDIAN_NAME: `Un ou plusieurs nom de tuteurs manquants`,
  EMPTY_PHONE: `Un ou plusieurs numéro de téléphone manquants`,
  INVALID_EMAIL: `Une ou plusieurs email invalides`,
  INVALID_FIRST_NAME: `Un ou plusieurs prénoms invalides`,
  INVALID_LAST_NAME: `Un ou plusieurs noms invalides`,
  INVALID_MOBILE_PHONE: `Un ou plusieurs numéros de téléphone mobile invalides`,
  INVALID_PHONE: `Un ou plusieurs numéros de téléphone invalides`,
  NO_ESTABLISHMENT: `Aucun établissement choisi`,
  INVALID_DATE: `Une ou plusieurs dates invalides`,
  BIRTHDATE_NOT_VALID: `La date de naissance ne doit pas être récente`,
};
