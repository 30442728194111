import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useGetWorker } from '../../../../../../hooks/worker.hooks';
import Loader from '../../../../../Common/Loader';
import WorkerDetails from '../../../../../WorkerDetails';

interface WorkerDetailsContainerProps {
  workerId: number;
}

const WorkerDetailsContainer: FC<
  WorkerDetailsContainerProps & RouteComponentProps
> = (props) => {
  const { workerId } = props;
  const { data: worker, loading } = useGetWorker(workerId);
  if (loading) {
    return <Loader />;
  }
  if (!worker) {
    return <></>;
  }
  return <WorkerDetails worker={worker}></WorkerDetails>;
};

export default withRouter(WorkerDetailsContainer);
