import React, { FC } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import Logo from '../../../assets/images/logo-neo.png';
import useStyles from './styles';
import {
  GET_ETABLISHMENT_DATA,
  GET_ETABLISHMENT_ROOT_DATA,
} from '../../../graphql/common/etablishment/query';
import {
  ETABLISHMENT_DATA,
  ETABLISHMENT_ROOT_DATA,
} from '../../../constants/etablishement';
import EstablishmentAddComponentDisplay from './EstablishmentAddComponentDisplay';
import { mapToIds } from '../../../common/utils';
import { DELETE_ADMINS } from '../../../graphql/administrator/mutation';
import { DELETE_WORKERS } from '../../../graphql/worker/mutation';
import { DELETE_BENEFICIARIES } from '../../../graphql/beneficiary/mutation';
import {
  DeleteAdmins,
  DeleteAdminsVariables,
} from '../../../graphql/administrator/types/DeleteAdmins';
import {
  DeleteWorkers,
  DeleteWorkersVariables,
} from '../../../graphql/worker/types/DeleteWorkers';
import {
  DeleteBeneficiaries,
  DeleteBeneficiariesVariables,
} from '../../../graphql/beneficiary/types/DeleteBeneficiaries';

interface EstablishmentAddProps {}

const EstablishmentAdd: FC<EstablishmentAddProps & RouteComponentProps> = (
  props,
) => {
  const { history } = props;
  const classes = useStyles();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const type = query.get('type') || '';
  const client = useApolloClient();

  const [deleteAdmins] = useMutation<DeleteAdmins, DeleteAdminsVariables>(
    DELETE_ADMINS,
    {
      onCompleted: () => {},
      onError: () => {},
    },
  );

  const [deleteWorkers] = useMutation<DeleteWorkers, DeleteWorkersVariables>(
    DELETE_WORKERS,
    {
      onCompleted: () => {},
      onError: () => {},
    },
  );

  const [deleteBenefs] = useMutation<
    DeleteBeneficiaries,
    DeleteBeneficiariesVariables
  >(DELETE_BENEFICIARIES, {
    onCompleted: () => {},
    onError: () => {},
  });

  const handleClose = () => {
    const data = client.readQuery({
      query: GET_ETABLISHMENT_DATA,
    });

    const administratorIds = mapToIds(data.etablishmentData.administrators);
    const workerIds = mapToIds(data.etablishmentData.workers);
    const beneficiaryIds = mapToIds(data.etablishmentData.beneficiaries);

    const resolveDeleteAdmins = deleteAdmins({
      variables: { ids: administratorIds },
    });
    const resolveDeleteWorkers = deleteWorkers({
      variables: { ids: workerIds },
    });
    const resolveDeleteBenefs = deleteBenefs({
      variables: { ids: beneficiaryIds },
    });

    Promise.all([
      resolveDeleteAdmins,
      resolveDeleteWorkers,
      resolveDeleteBenefs,
    ]).then(() => {
      client.writeQuery({
        query: GET_ETABLISHMENT_DATA,
        data: {
          etablishmentData: ETABLISHMENT_DATA,
        },
      });
      client.writeQuery({
        query: GET_ETABLISHMENT_ROOT_DATA,
        data: {
          etablishmentRootData: ETABLISHMENT_ROOT_DATA,
        },
      });
      history.push('/etablissements');
    });
  };

  return (
    <Box className={classes.root}>
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolBar}>
          <Box className={classes.logo}>
            <img src={Logo} alt="Logo" className={classes.img} />
          </Box>
          <Typography variant="h6" className={classes.title}>
            Ajout d'un établissement
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box className={classes.container}>
        <EstablishmentAddComponentDisplay type={type} />
      </Box>
    </Box>
  );
};

export default withRouter(EstablishmentAdd);
