import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import useStyles from './styles';
import DialogWrapper from './DialogWrapper';
import { User, Beneficiary } from '../../../../../types/user';
import { DialogContent, DialogActions, Button } from '@material-ui/core';
import BasicDialogContent from './BasicDialogContent';
import DialogTitle from './DialogTitle';
import BeneficiaryDialogContent from './BeneficiaryDialogContent';
import moment from 'moment';

interface UserFormDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (user: User) => void;
  user: User;
  loadingButton?: boolean;
  establishmentId: number;
  beneficiaryDocuments?: string;
  setBeneficiaryDocuments?: (value: string) => void;
}

interface SelectedDocument {
  name: string;
  file: string;
}

const UserFormDialog: FC<UserFormDialogProps> = (props) => {
  const {
    open,
    user,
    title,
    onSubmit,
    onClose,
    loadingButton,
    establishmentId,
    beneficiaryDocuments,
    setBeneficiaryDocuments,
  } = props;
  const { userRole } = user;
  const [autoCompleteValueUnitLife, setAutocompleteValueUnitLife] =
    useState<any>(null);
  const classes = useStyles();
  const [modifiedUser, setModifiedUser] = useState<User | Beneficiary>(user);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedDocuments, setSelectedDocuments] = useState<
    SelectedDocument[]
  >([]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const transformedValue = ['phone', 'mobilePhone'].includes(name)
      ? value.replace(/[^0-9 +]/g, '')
      : value;
    setModifiedUser((prevValue) => ({
      ...prevValue,
      [name]: transformedValue,
    }));
  };

  const onChangeAutoCompleteUnitLife = (
    event: React.ChangeEvent<any>,
    newValue: any,
  ) => {
    setAutocompleteValueUnitLife(newValue);
    setModifiedUser((prevValue) => ({
      ...prevValue,
      ['unitLifeId']: newValue && newValue.id,
    }));
  };

  useEffect(() => {
    setModifiedUser(user);
  }, [user]);

  useEffect(() => {
    if (selectedDate) {
      setModifiedUser({
        ...modifiedUser,
        birthDate: new Date(moment(selectedDate).format('MM/DD/YYYY')),
      });
    }
  }, [selectedDate]);

  useEffect(() => {
    if (
      selectedDocuments &&
      selectedDocuments.length > 0 &&
      setBeneficiaryDocuments
    ) {
      setBeneficiaryDocuments(JSON.stringify(selectedDocuments));
    }
  }, [selectedDocuments]);

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setModifiedUser((prevValue) => ({
      ...prevValue,
      [name]: checked,
    }));
  };

  const onHandleClose = () => {
    onClose();
  };

  const onHandleSubmit = () => {
    onSubmit(modifiedUser);
  };

  const onDateChange = (date: Date | null) => {
    setSelectedDate(new Date(moment(date).format('MM/DD/YYYY')));
  };

  const setSignatureFile = (signatureFile: any) => {
    setModifiedUser((prevValue) => ({
      ...prevValue,
      signatureFile,
    }));
  };

  return (
    <DialogWrapper open={open}>
      <DialogTitle onClose={onHandleClose}>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <BasicDialogContent
          onChange={onChange}
          onDateChange={onDateChange}
          onCheckboxChange={onCheckboxChange}
          user={modifiedUser}
          establishmentId={establishmentId}
          onChangeUnitLife={onChangeAutoCompleteUnitLife}
        />
        {userRole === 'beneficiary' && (
          <BeneficiaryDialogContent
            onChange={onChange}
            onCheckboxChange={onCheckboxChange}
            user={modifiedUser as Beneficiary}
            setSignatureFile={setSignatureFile}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
            beneficiaryDocuments={beneficiaryDocuments || undefined}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!!loadingButton}
          onClick={onHandleSubmit}
        >
          {!!user.id ? 'Enregistrer' : 'Ajouter'}
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};

export default UserFormDialog;
