import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: '0 auto',
      height: '100%',
      backgroundColor: '#fff',
    },
    container: {
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 15,
      margin: 0,
      boxSizing: 'border-box',
    },
    wrapForm: {
      width: 'auto',
      padding: 0,
      margin: 0,
      boxSizing: 'border-box',
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // marginTop: 48,

      [theme.breakpoints.down(500)]: {
        width: '100%',
      },
    },
    form: {
      width: 450,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexWrap: 'wrap',

      [theme.breakpoints.down(500)]: {
        width: '100%',
      },
    },
    actions: {
      margin: theme.spacing(6, 0),
      textAlign: 'center',

      [theme.breakpoints.down(500)]: {
        margin: theme.spacing(3, 0),
      },
    },
    link: {
      fontSize: 14,
      cursor: 'pointer',
      color: '#F7941D',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
    button: {
      height: 56,
    },
    textField: {
      height: 56,
    },
    logo: {
      width: 'auto',
      height: 120,
      marginBottom: 48,

      [theme.breakpoints.down(500)]: {
        margin: '32px 0',
        height: 80,
      },

      '& img ': {
        width: '100%',
        height: 'auto',
      },
    },
    title: {
      marginTop: 30,
      marginBottom: 42,
      textAlign: 'center',

      [theme.breakpoints.down(500)]: {
        margin: theme.spacing(6, 0, 2),
      },

      [theme.breakpoints.down(400)]: {
        margin: theme.spacing(4, 0, 2),
      },
    },
    textTitle: {
      marginBottom: 16,
      fontWeight: 500,
    },
    descTitle: {},
    footer: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      margin: theme.spacing(4, 0),

      '@media all and (max-height: 768px)': {
        position: 'relative',
        margin: theme.spacing(2, 0),
      },
    },
    buttonWrapper: {
      height: 56,
      width: '100%',
      position: 'relative',
      margin: theme.spacing(3, 0),

      [theme.breakpoints.down(500)]: {
        margin: theme.spacing(1, 0, 4),
      },
    },
    lodingProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -10,
    },
  }),
);

export default useStyles;
