import React, { FC, useRef, useState, useEffect, useContext } from 'react';
import { useApolloClient, useQuery, useLazyQuery } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import moment, { MomentInput } from 'moment';

import useStyles from './styles';
import { PlanningDataType } from '../../../../../../types/planning';
import { DATETIME_FORMAT } from '../../../../../../constants/format';
import {
  EtablishmentPrestations,
  EtablishmentPrestationsVariables,
} from '../../../../../../graphql/etablishment/types/EtablishmentPrestations';
import {
  GET_ETABLISHMENT_PRESTATIONS,
  GET_ETABLISHMENT_BY_USER_ID,
} from '../../../../../../graphql/etablishment/query';
import { GET_PLANNING_DATA } from '../../../../../../graphql/common/planning/query';
import { EstablishmentContext, UserContext } from '../../../../../Context';
import {
  GetEtablishmentByUserId,
  GetEtablishmentByUserIdVariables,
} from '../../../../../../graphql/etablishment/types/GetEtablishmentByUserId';
import { PLANNING_DATA } from '../../../../../../constants/planning';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import PeriodicityQuot from './PeriodicityQuot/PeriodicityQuot';
import PeriodicityHeb from './PeriodicityHeb/PeriodicityHeb';
import PeriodicityMen from './PeriodicityMen/PeriodicityMen';
import { grey } from '@material-ui/core/colors';
import {
  DateEndType,
  PeriodicityTypeEnum,
} from '../../../../../../graphql/types/graphql-global-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { UserContextType } from '../../../../../Context/UserContext';
import { Prestations, PrestationsVariables } from '../../../../../../graphql/prestation/types/Prestations';
import { GET_PRESTATIONS } from '../../../../../../graphql/prestation/query';
import { Prestation } from '../../../../../../types/prestation';
import { useParams } from 'react-router';
interface BasicInformationsProps {
  inputData: PlanningDataType;
  setInputData: React.Dispatch<React.SetStateAction<any>>;
}

type ChoiceType = 'QUOT' | 'HEB' | 'MEN';

const BasicInformations: FC<BasicInformationsProps> = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const userContext = useContext(UserContext);
  const { inputData, setInputData } = props;
  const [prestations, setPrestations] = useState<string[]>([]);
  const [dataPrestation, setDataPrestation] = useState<any[]>([]);
  const [choice, setChoice] = useState<ChoiceType>('QUOT');
  const planningData = client.readQuery({ query: GET_PLANNING_DATA });
  const [values, setValues] = useState<string>('forReccurence');
  const { establishmentType } = useContext(EstablishmentContext);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const user: UserContextType = useContext(UserContext);
  const {id} = useParams();
  const { data } = useQuery<
    GetEtablishmentByUserId,
    GetEtablishmentByUserIdVariables
  >(GET_ETABLISHMENT_BY_USER_ID, {
    variables: { id: userContext.userId },
  });

  let variables = {input:{
    establishmentId: data?.etablishmentByUserId?.id || +id,
  }};

  const {
    loading: loadingPrestationList,
    data: prestationList,
    refetch,
  } = useQuery<Prestations, PrestationsVariables>(GET_PRESTATIONS, { variables });

  useEffect(() => {
    if (prestationList && prestationList.prestations) {
      setDataPrestation(prestationList.prestations);
    }
  }, [prestationList]);

  const [getEtabPrestations, { data: prestationsData }] = useLazyQuery<
    EtablishmentPrestations,
    EtablishmentPrestationsVariables
  >(GET_ETABLISHMENT_PRESTATIONS);

  useEffect(() => {
    if (data && data.etablishmentByUserId && data.etablishmentByUserId.id) {
      const inputD = client.readQuery({ query: GET_PLANNING_DATA });
      const establishmentId =
        data && data.etablishmentByUserId && data.etablishmentByUserId.id;
      client.writeQuery({
        query: GET_PLANNING_DATA,
        data: {
          planningData: {
            ...inputD.planningData,
            establishmentId,
          },
        },
      });
      setInputData({
        ...inputData,
        establishmentId,
      });
      getEtabPrestations({ variables: { id: data.etablishmentByUserId.id || +id } });
    }
  }, [data]);

  useEffect(() => {
    if (
      prestationsData &&
      prestationsData.etablishment &&
      prestationsData.etablishment.prestations
    ) {
      const inputData = client.readQuery({ query: GET_PLANNING_DATA });
      const prestations: string[] =
        prestationsData.etablishment.prestations.split(',') || [];
      setPrestations(prestations);

      client.writeQuery({
        query: GET_PLANNING_DATA,
        data: {
          planningData: {
            ...inputData.planningData,
            prestation: prestations[0] || '',
          },
        },
      });
      // setInputData((prevState: any) => ({
      //   ...prevState,
      //   prestation: prestations[0] || '',
      // }));
    }
  }, [prestationsData]);

  // const handleDateChange = () => {};

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChoice((event.target as HTMLInputElement).value as ChoiceType);
  };

  const onHandleChange = (
    event: React.ChangeEvent<
      | HTMLInputElement
      | {
          name?: string | undefined;
          value: unknown;
        }
    >,
  ) => {
    const { name, value } = event.target;

    if (name === 'dateStart' || name === 'dateEnd') {
      const daty = new Date(value as string);
      daty.setUTCHours(0, 0, 0);
      setInputData((prevState: any) => ({
        ...prevState,
        [name]: daty.toUTCString(),
      }));
    } else {
      if (name) {
        setInputData((prevState: any) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues((event.target as HTMLInputElement).value);
    if ((event.target as HTMLInputElement).value === 'forReccurence') {
      setInputData((prevState: any) => ({
        ...prevState,
        dateEndType: DateEndType.END_AFTER,
      }));
    } else {
      setInputData((prevState: any) => ({
        ...prevState,
        dateEndType: DateEndType.END_ON,
      }));
    }
  };

  const getChoice = (type: ChoiceType) => {
    const componentList: Record<ChoiceType, any> = {
      QUOT: (
        <PeriodicityQuot inputData={inputData} setInputData={setInputData} />
      ),
      HEB: <PeriodicityHeb inputData={inputData} setInputData={setInputData} />,
      MEN: <PeriodicityMen inputData={inputData} setInputData={setInputData} />,
    };
    return componentList[type];
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setInputData((prevState: any) => ({
      ...prevState,
      dateStart: selectedDate,
    }));
  };

  return (
    <Box className={classes.root}>
      <TextField
        id="title"
        name="title"
        label="Titre"
        variant="outlined"
        placeholder="Titre de l'intervention"
        InputLabelProps={{ shrink: true }}
        autoComplete="off"
        value={inputData.title}
        fullWidth
        margin="normal"
        onChange={onHandleChange}
      />
      <FormControl
        margin="normal"
        className={classes.formControl}
        variant="outlined"
      >
        <InputLabel id="demo-simple-select-outlined-label">
          Nature de la prestation
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={inputData.prestation}
          onChange={onHandleChange}
          name="prestation"
          label="Nature de la prestation"
        >
          {(dataPrestation || []).map((item: Prestation, index: number) => (
            <MenuItem key={index} value={item.libelle}>
              {item.libelle}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        id="location"
        name="location"
        label={
          establishmentType && establishmentType === 'DOMICILE'
            ? "Lieu de l'intervention"
            : 'Lieu / Etablissement'
        }
        variant="outlined"
        placeholder="Lieu de l'intervention"
        InputLabelProps={{ shrink: true }}
        autoComplete="off"
        value={inputData.location}
        fullWidth
        margin="normal"
        onChange={onHandleChange}
      />
      <TextField
        id="comment"
        name="comment"
        label="Commentaire"
        variant="outlined"
        placeholder="Commentaire de l'intervention"
        InputLabelProps={{ shrink: true }}
        autoComplete="off"
        value={inputData.comment}
        fullWidth
        margin="normal"
        onChange={onHandleChange}
      />
      <Box
        border={1}
        borderColor="grey.500"
        borderRadius="2%"
        className={classes.timeContainer}
      >
        <Box fontWeight="fontWeightLight" m={1} className={classes.boxTitle}>
          Périodicité
        </Box>
        <Box className={classes.dateTimeField}>
          <TextField
            id="time-start"
            label="Heure de début"
            type="time"
            defaultValue="00:00"
            variant="outlined"
            name="hourStart"
            className={classes.timePicker}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={onHandleChange}
          />
          <TextField
            id="time-end"
            label="Heure de fin"
            type="time"
            defaultValue="00:00"
            variant="outlined"
            name="hourEnd"
            className={classes.timePicker}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={onHandleChange}
          />
        </Box>
        <Box className={classes.radioChoice}>
          <RadioGroup
            className={classes.inlineGroup}
            name="choice"
            value={choice}
            onChange={handleRadioChange}
          >
            <Grid container>
              <Grid item xs={4}>
                <FormControlLabel
                  value="QUOT"
                  control={<Radio color="primary" />}
                  label="Quotidienne"
                />
              </Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  value="HEB"
                  control={<Radio color="primary" />}
                  label="Hebdomadaire"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="MEN"
                  control={<Radio color="primary" />}
                  label="Mensuelle"
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Box>
        {getChoice(choice)}
      </Box>

      <Box
        border={1}
        borderColor="grey.500"
        borderRadius="2%"
        className={classes.timeContainer}
      >
        <Box fontWeight="fontWeightLight" m={1} className={classes.boxTitle}>
          Plage de périodicité
        </Box>
        <TextField
          id="dateStart"
          label="Date de début"
          type="date"
          variant="outlined"
          className={classes.formControl}
          InputLabelProps={{
            shrink: true,
          }}
          name="dateStart"
          onChange={onHandleChange}
        />
        {/* <KeyboardDatePicker
          disableToolbar
          variant="inline"
          className={classes.formControl}
          format="dd/MM/yyyy"
          margin="normal"
          id="dateStart"
          label="Date de début"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          inputVariant="outlined"
        /> */}
        <Box className={classes.periodicityReccurence}>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={values}
            onChange={handleChange}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{ marginBottom: 8 }}
            >
              <FormControlLabel
                value="forReccurence"
                control={<Radio color="primary" />}
                label="Fin après"
                style={{ margin: 0 }}
              />
              <TextField
                id="reccurence-number"
                type="number"
                placeholder="0"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ min: '1', max: '100' }}
                variant="outlined"
                size="small"
                style={{ margin: '0 8px' }}
                name="afterOccurence"
                value={inputData.afterOccurence}
                onChange={onHandleChange}
              />
              <p style={{ margin: 0 }}>occurence(s)</p>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <FormControlLabel
                value="forDateEnd"
                control={<Radio color="primary" />}
                label="Fin le"
                className={classes.formLabel}
                style={{ margin: 0 }}
              />
              <TextField
                id="dateEnd"
                // label="Date de fin"
                type="date"
                variant="outlined"
                className={classes.formControl}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ margin: '0 0 0 8px' }}
                name="dateEnd"
                onChange={onHandleChange}
              />
            </Box>
          </RadioGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default BasicInformations;
