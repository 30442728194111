import gql from 'graphql-tag';

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      email
      phone
      mobilePhone
      userFunction
      enabled
      role {
        name
      }
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      accessToken
    }
  }
`;

export const SEND_RESET_PASSWORD_LINK = gql`
  mutation SendResetPasswordLink($email: String!) {
    sendResetPasswordLink(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($userId: Int!, $token: String!, $password: String!) {
    resetPassword(
      input: { userId: $userId, token: $token, password: $password }
    )
  }
`;
