import { Action } from '../../../../../types/action';
import {
  EstablishmentDataType,
  EstablishmentType,
} from '../../../../../types/establishment';
import { EstablishmentCreateState } from '../interfaces/EstablishmentCreate.interfaces';

export class SetEstablishmentId implements Action<EstablishmentCreateState> {
  constructor(public payload: number) {}
  reducer(state: EstablishmentCreateState): EstablishmentCreateState {
    return {
      ...state,
      establishmentId: this.payload,
    };
  }
}

export class SetEstablishment implements Action<EstablishmentCreateState> {
  constructor(public payload: EstablishmentDataType) {}
  reducer(state: EstablishmentCreateState): EstablishmentCreateState {
    const establishmentType: EstablishmentType | undefined = this.payload
      .status as EstablishmentType | undefined;
    return {
      ...state,
      establishment: this.payload,
      ...(establishmentType ? { establishmentType } : {}),
    };
  }
}

export class SetEstablishmentType implements Action<EstablishmentCreateState> {
  constructor(public payload: EstablishmentType) {}
  reducer(state: EstablishmentCreateState): EstablishmentCreateState {
    return {
      ...state,
      establishmentType: this.payload,
    };
  }
}

export class EstablishmentCreateFinish
  implements Action<EstablishmentCreateState> {
  constructor() {}
  reducer(state: EstablishmentCreateState): EstablishmentCreateState {
    return {
      ...state,
      establishmentCreateFinish: true,
    };
  }
}

export class SetMinorChanges implements Action<EstablishmentCreateState> {
  constructor(public payload: EstablishmentDataType | null) {}
  reducer(state: EstablishmentCreateState): EstablishmentCreateState {
    return {
      ...state,
      minorChanges: this.payload,
    };
  }
}

export type EstablishmentAction =
  | SetEstablishmentId
  | SetEstablishment
  | SetEstablishmentType
  | EstablishmentCreateFinish
  | SetMinorChanges;
