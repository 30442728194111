import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootDialog: {
      boxSizing: 'border-box',
      width: '100%',
      overflow: 'hidden',
    },
    dialogContent: {
      // maxWidth: 1080,
      width: '100%',
      alignSelf: 'center',
      padding: theme.spacing(2),

      [theme.breakpoints.down(1120)]: {
        width: '100%',
        padding: theme.spacing(2),
      },
    },
    dialogTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: theme.spacing(3, 6),

      [theme.breakpoints.down(876)]: {
        padding: theme.spacing(1),
      },

      [theme.breakpoints.down(786)]: {
        margin: '16px 16px 0',
      },

      [theme.breakpoints.down(730)]: {
        '& .MuiTypography-h4': {
          fontSize: 24,
        },
        '& .MuiTypography-body1': {
          fontSize: 14,
        },
      },

      [theme.breakpoints.down(485)]: {
        '& .MuiTypography-h4': {
          fontSize: 16,
        },
        '& .MuiTypography-body1': {
          fontSize: 12,
        },
      },

      '& .MuiTypography-root': {
        fontWeight: 'bold',
      },
    },
  }),
);

export default useStyles;
