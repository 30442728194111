import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formControl: {
      width: '100%',
      minWidth: 120,
      [theme.breakpoints.down(992)]: {
        margin: '6px 0',
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    button: {
      '& .MuiButton-label': {
        color: theme.palette.common.white,
        display: 'contents',
      },
      width: 150,
      height: 55,
    },
  }),
);

export default useStyles;
