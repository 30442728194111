import {
  UserData,
  UserData_User,
} from '../../../../../../types/Users.interface';
import { UserRole, User } from '../../../../../../types/user';

interface ToUserArgs {
  userData: UserData;
  userRole: UserRole;
  establishmentId?: number;
}

export const toUser = (args: ToUserArgs): User => {
  const { userData, userRole, establishmentId } = args;
  const { id, __typename: userDataTypename, user, ...userDataRest } = userData;
  const { __typename, ...rest }: UserData_User = user as UserData_User;

  return {
    ...(rest as User),
    ...userDataRest,
    id: userData.id,
    lastName: rest.lastName || '',
    phone: rest.phone || '',
    mobilePhone: rest.mobilePhone || '',
    birthDate: (userData as any).birthDate || new Date(),
    disabilityRecognition: (userData as any).disabilityRecognition || false,
    userRole,
    ...(establishmentId ? { establishmentId } : {}),
  };
};

export const mapToUser = (
  userDatas: UserData[],
  userRole: UserRole,
  establishmentId: number,
): User[] => {
  return userDatas.map(
    (userData: UserData): User =>
      toUser({ userData, userRole, establishmentId }),
  );
};
