import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from '../../../services/localStorage';

interface PrivateRouteProps {
  component: any;
  [key: string]: any;
}

/**
 * PrivateRoute
 * @param props PrivateRouteProps
 */
const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;

  return isAuthenticated() ? (
    <Route {...rest} component={Component} />
  ) : (
    <Redirect to="/authentification" />
  );
};

export default PrivateRoute;
