import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    loader: {
      padding: theme.spacing(4, 0),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export default useStyles;
