import { useEffect, useState } from 'react';
import {
  LazyQueryHookOptions,
  MutationHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/react-hooks';
import { exportData, formatEstablishmentData } from '../common/excel';
import {
  CREATE_ESTABLISHMENT,
  DELETE_ESTABLISHMENT,
  DELETE_ESTABLISHMENTS,
  UPDATE_ESTABLISHMENT_STATUS,
} from '../graphql/etablishment/mutation';
import { GET_ETABLISHMENTS } from '../graphql/etablishment/query';
import {
  CreateEstablishment,
  CreateEstablishmentVariables,
} from '../graphql/etablishment/types/CreateEstablishment';
import {
  DeleteEstablishment,
  DeleteEstablishmentVariables,
} from '../graphql/etablishment/types/DeleteEstablishment';
import {
  DeleteEstablishments,
  DeleteEstablishmentsVariables,
} from '../graphql/etablishment/types/DeleteEstablishments';
import {
  Etablishments,
  EtablishmentsVariables,
} from '../graphql/etablishment/types/Etablishments';
import {
  UpdateEstablishmentStatus,
  UpdateEstablishmentStatusVariables,
} from '../graphql/etablishment/types/UpdateEstablishmentStatus';
import { GET_STATUS_BY_NAME } from '../graphql/status/query';
import {
  GetStatusByName,
  GetStatusByNameVariables,
} from '../graphql/status/types/GetStatusByName';
import {
  EstablishmentType,
  EtablishmentDataType,
} from '../types/establishment';
import { useLocation } from 'react-router-dom';

export interface UseCreateEstablishmentArgs {
  options?: MutationHookOptions<
    CreateEstablishment,
    CreateEstablishmentVariables
  >;
  establishmentType: any;
}

export const useCreateEstablishment = ({
  options,
  establishmentType,
}: UseCreateEstablishmentArgs) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const type = query.get('type') || '';
  const [createEstablishment] = useMutation<
    CreateEstablishment,
    CreateEstablishmentVariables
  >(CREATE_ESTABLISHMENT, options);

  const { data: establishmentTypeResidence } = useQuery<
    GetStatusByName,
    GetStatusByNameVariables
  >(GET_STATUS_BY_NAME, { variables: { name: 'RESIDENCE' } });

  const { data: establishmentTypeDomicile } = useQuery<
    GetStatusByName,
    GetStatusByNameVariables
  >(GET_STATUS_BY_NAME, { variables: { name: 'DOMICILE' } });

  const { data: establishmentFunding } = useQuery<
    GetStatusByName,
    GetStatusByNameVariables
  >(GET_STATUS_BY_NAME, { variables: { name: 'OTHERS' } });

  return async (data: EtablishmentDataType) => {
    const {
      picture,
      base64logo,
      __typename,
      prestations,
      id,
      workers,
      beneficiaries,
      administrators,
      ...rest
    } = data;

    let etablishmentTypeId;

    switch (data.status) {
      case 'HOME':
        etablishmentTypeId = establishmentTypeDomicile?.getStatusByName?.id;
        delete data.status;

        break;
      case 'RESIDENCE':
        etablishmentTypeId = establishmentTypeResidence?.getStatusByName?.id;
        delete data.status;
        break;

      default:
        etablishmentTypeId = 0;
        delete data.status;

        break;
    }

    const etablishmentFundingId =
      establishmentFunding &&
      establishmentFunding.getStatusByName &&
      establishmentFunding.getStatusByName.id;

    createEstablishment({
      variables: {
        input: {
          ...rest,
          prestations: prestations.join(','),
          etablishmentTypeId,
          etablishmentFundingId,
        },
      },
    });
  };
};

export const useExportEstablishments = (
  options?: LazyQueryHookOptions<Etablishments, EtablishmentsVariables>,
) => {
  const [getEstablishmentInfo] = useLazyQuery<
    Etablishments,
    EtablishmentsVariables
  >(GET_ETABLISHMENTS, {
    ...options,
    onCompleted: (data: Etablishments) => {
      if (data) {
        exportData({
          csvData: data.etablishments.map((item: any) =>
            formatEstablishmentData(item),
          ),
          fileName: 'Etablissements',
          sheetName: 'Etablissements',
        });
      }
      options && options.onCompleted && options.onCompleted(data);
    },
  });

  return (establishmentIds: number[]) =>
    getEstablishmentInfo({
      variables: {
        input: {
          filter: JSON.stringify({
            ids: establishmentIds,
          }),
          orderBy: JSON.stringify({
            id: 'asc',
          }),
        },
      },
    });
};

export const useDeleteEstablishment = (
  options?: MutationHookOptions<
    DeleteEstablishment,
    DeleteEstablishmentVariables
  >,
) => {
  const [deleteEstablishment] = useMutation<
    DeleteEstablishment,
    DeleteEstablishmentVariables
  >(DELETE_ESTABLISHMENT, options);
  return (establishmentId: number) => {
    deleteEstablishment({
      variables: {
        id: establishmentId,
      },
    });
  };
};

export const useDeleteEstablishments = (
  options?: MutationHookOptions<
    DeleteEstablishments,
    DeleteEstablishmentsVariables
  >,
) => {
  const [deleteEstablishments] = useMutation<
    DeleteEstablishments,
    DeleteEstablishmentsVariables
  >(DELETE_ESTABLISHMENTS, options);
  return (establishmentIds: number[]) => {
    deleteEstablishments({
      variables: {
        ids: establishmentIds,
      },
    });
  };
};

export const useUpdateEstablishmentsStatus = (
  options?: MutationHookOptions<
    UpdateEstablishmentStatus,
    UpdateEstablishmentStatusVariables
  >,
) => {
  const [updateEstablishmentsStatus] = useMutation<
    UpdateEstablishmentStatus,
    UpdateEstablishmentStatusVariables
  >(UPDATE_ESTABLISHMENT_STATUS, options);
  return (establishmentIds: number[], status: boolean) =>
    updateEstablishmentsStatus({
      variables: { ids: establishmentIds, status },
    });
};
