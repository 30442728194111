import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formControl: {
      width: '100%',
    },
    formGroup: {
      margin: theme.spacing(1, 0),
    },
    inputLabel: {
      fontSize: '0.8rem',
      paddingLeft: 16,
    },
    formControlLabel: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      width: '100%',
      boxSizing: 'border-box',
    },
    switch: {
      marginRight: 20,
    },
  }),
);

export default useStyles;
