import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',

      [theme.breakpoints.down(600)]: {
        width: '100%',
      },

      '& h6': {
        fontSize: '20px',
        color: 'white',
      },

      '& .MuiDialogActions-root': {
        padding: 0,
        margin: '24px 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',

        '& .MuiButton-root': {
          height: '56px',
          padding: theme.spacing(0, 6),
          color: '#fff',
          fontSize: '14px',
          // backgroundColor: theme.palette.primary.main,
        },
      },
    },
    dialogTitle: {
      backgroundColor: theme.palette.primary.main,
      height: 70,
      lineHeight: 70,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(2),
    },
    closeButton: {
      color: '#fff',
    },
    content: {
      boxSizing: 'border-box',
      padding: '20px 120px',

      [theme.breakpoints.down(600)]: {
        padding: '20px 70px',
      },
      [theme.breakpoints.down(500)]: {
        padding: '20px 20px',
      },
    },
    textUpdatePassword: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    birthDate: {
      width: '347px',
    },
  });
const useStyles = makeStyles(styles);

export default useStyles;
