import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import Dialog from '@material-ui/core/Dialog';

interface DialogWrapperProps {
  open: boolean;
}

const DialogWrapper: FC<DialogWrapperProps> = (props) => {
  const classes = useStyles();
  const { open, children } = props;
  return (
    <Dialog
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      className={classes.root}
      // onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
    >
      {children}
    </Dialog>
  );
};

export default DialogWrapper;
