import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formControl: {
      width: '100%',
      minWidth: 80,
      [theme.breakpoints.down(992)]: {
        margin: '6px 0',
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export default useStyles;
