import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: {
      minHeight: 400,
      marginBottom: 100,
      marginTop: 8,
    },
    footer: {},
    actionButton: {
      height: 48,
      padding: theme.spacing(0, 4),
      margin: theme.spacing(0, 1),
    },
    formGroup: {
      margin: theme.spacing(1, 0),
    },
    inputLabel: {
      fontSize: '0.8rem',
      paddingLeft: 16,
    },
    formControlLabel: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      width: '100%',
      boxSizing: 'border-box',
    },
    lowercase: {
      textTransform: 'initial',
    },
    switch: {
      marginRight: 20,
    },
    dashedButton: {
      margin: theme.spacing(2, 0),
      height: 54,
      border: '1px dashed #9E9E9E',

      '& .MuiButton-label': {
        textAlign: 'left',
        textTransform: 'initial',
        color: '#00000061',
        fontWeight: 'normal',
      },
    },
    itemText: {},
    dynamicInput: {
      margin: theme.spacing(2, 0),
    },
    listShowInput: {
      maxHeight: 300,
      overflow: 'auto',
    },
    listHideInput: {
      maxHeight: 400,
      overflow: 'auto',
    },
    uploadArea: {
      width: '100%',
      height: 300,
      margin: theme.spacing(2, 0),
      border: '1px dashed #9E9E9E',
      borderRadius: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    uploadText: {
      fontSize: 14,
      textAlign: 'center',
    },
    uploadButton: {
      '& .MuiButton-label': {
        textTransform: 'capitalize',
        color: '#ED1C26',
      },
    },
    margin: {
      marginLeft: 4,
      marginRight: 4,
    },
    button: {
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    white: {
      color: theme.palette.common.white,
    },
    excelIcon: {
      width: 24,
      height: 24,
    },
  }),
);

export default useStyles;
