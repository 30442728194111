import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Ellipsis } from 'react-bootstrap/esm/PageItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      '& .MuiTableRow-root.MuiTableRow-hover:hover': {
        backgroundColor: 'rgba(255, 67, 56, 0.08)',
      },
      '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
        color: '#ffffff !important',
      },
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #f1f1f3 !important',
      },
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      boxShadow: 'none',
      '& .MuiTablePagination-root': {
        paddingTop: theme.spacing(2),
      },
      '& .MuiTableHead-root .MuiTableCell-root': {
        padding: '0px 8px 0 12px',
      },
      '& .MuiTableBody-root .MuiTableCell-root': {
        padding: '3px 8px 3px 12px',
      },
      '& .MuiTablePagination-toolbar': {
        maxWidth: 470,
        margin: '0 auto',
        paddingRight: 8,
        paddingLeft: 8,
      },
      '& .MuiTablePagination-spacer': {
        display: 'none',
      },
      '& .MuiTableCell-head, & .MuiTableCell-root': {
        lineHeight: 1,
      },
    },
    container: {},
    text: {
      '&>span': {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 120,
      },
    },

    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    margin: {
      marginLeft: 4,
      marginRight: 4,
    },
    excelIcon: {
      width: 28,
      height: 28,
    },
  }),
);

export default useStyles;

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },

    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#FAFAFA',
      },
    },
  }),
)(TableRow);
