import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    actionButton: {
      height: 48,
      padding: theme.spacing(0, 4),
      margin: theme.spacing(0, 1),
      [theme.breakpoints.down(416)]: {
        height: 40,
        padding: theme.spacing(0, 2),
      },
    },
    lowercase: {
      textTransform: 'initial',
    },
  }),
);

export default useStyles;
