import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { API_URL } from '../constants/config';
import {
  GET_ETABLISHMENT_DATA,
  GET_ETABLISHMENT_ROOT_DATA,
  GET_USER_EDIT_DATAS,
} from '../graphql/common/etablishment/query';
import {
  ETABLISHMENT_DATA,
  ETABLISHMENT_ROOT_DATA,
} from '../constants/etablishement';
import { EMPTY_USER_EDIT_DATAS } from '../constants/user';
import { GET_PLANNING_DATA } from '../graphql/common/planning/query';
import { PLANNING_DATA } from '../constants/planning';

const createClient = (token: string) => {
  const cache = new InMemoryCache();

  cache.writeQuery({
    query: GET_ETABLISHMENT_DATA,
    data: {
      etablishmentData: ETABLISHMENT_DATA,
    },
  });

  cache.writeQuery({
    query: GET_ETABLISHMENT_ROOT_DATA,
    data: {
      etablishmentRootData: ETABLISHMENT_ROOT_DATA,
    },
  });

  cache.writeQuery({
    query: GET_USER_EDIT_DATAS,
    data: {
      userEditDatas: EMPTY_USER_EDIT_DATAS,
    },
  });

  cache.writeQuery({
    query: GET_PLANNING_DATA,
    data: {
      planningData: PLANNING_DATA,
    },
  });

  const httpLink = new HttpLink({
    uri: API_URL,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  return new ApolloClient({
    // Provide required constructor fields
    cache: cache,
    link: authLink.concat(httpLink),

    // Provide some optional constructor fields
    name: 'react-web-client',
    version: '1.3',
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
};

export default createClient;
