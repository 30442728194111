import React, { FC, useState, useEffect, useContext } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { ApolloError, MutationUpdaterFn } from 'apollo-boost';
import { DataProxy } from 'apollo-cache';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import {
  Workers,
  WorkersVariables,
} from '../../../../graphql/worker/types/Workers';
import {
  CreateWorker,
  CreateWorkerVariables,
} from '../../../../graphql/worker/types/CreateWorker';
import {
  UpdateWorker,
  UpdateWorkerVariables,
} from '../../../../graphql/worker/types/UpdateWorker';
import { GET_WORKERS } from '../../../../graphql/worker/query';
import {
  CREATE_WORKER,
  UPDATE_WORKER,
  DELETE_WORKER,
  DELETE_WORKERS,
  UPDATE_WORKER_STATUS,
  CREATE_WORKERS,
} from '../../../../graphql/worker/mutation';
import {
  UpdateWorkerStatus,
  UpdateWorkerStatusVariables,
} from '../../../../graphql/worker/types/UpdateWorkerStatus';
import { SnackbarType } from '../../../../types';
import { displaySnackbar, checkActionGroup } from '../../../../common';
import { HeadCell, GroupAction } from '../../../../types/table';
import UserFormDialog from '../UserFormDialog';
import { FormInputDataState, User } from '../../../../types/user';
import SearchInput from '../../../Common/SearchInput';
import useStyles from './styles';
import CustomTable from '../../../Common/CustomTable';
import { ValueType } from 'react-select';
import { EtablishmentDataType } from '../../../../types/establishment';
import {
  DeleteWorker,
  DeleteWorkerVariables,
} from '../../../../graphql/worker/types/DeleteWorker';
import {
  DeleteWorkers,
  DeleteWorkersVariables,
} from '../../../../graphql/worker/types/DeleteWorkers';
import ConfirmationDialog from '../../../Common/ConfirmationDialog';
import { EtablishmentContributorsInfo_etablishment_workers } from '../../../../graphql/etablishment/types/EtablishmentContributorsInfo';
import { TableDataSource } from '../../../../types/table';
import { GET_ETABLISHMENT_DATA } from '../../../../graphql/common/etablishment/query';
import { isGlobalList } from '../../../../common/validator';
import { filterUserByName } from '../../../../common/utils';
import {
  formInputDataStateToUser,
  getPartialUsers,
} from '../../../../utils/user.utils';
import { handleEstablishmentCreateUserListValidation } from '../../../../utils/user-list.utils';
import { getUsersFromFiles } from '../../../../utils/excel-import.utils';
import { UserValidationState } from '../../../../types/user-validator.types';
import {
  getExcelImportUserErrorMessage,
  getImportExcelValidators,
  validateUsers,
} from '../../../../utils/user-validator.utils';
import { showSnackbar } from '../../../../utils/snackbar.util';
import ExcelReader from '../../../Common/ExcelReader';
import {
  CreateWorkers,
  CreateWorkersVariables,
} from '../../../../graphql/worker/types/CreateWorkers';
import { CreateWorkerInput } from '../../../../graphql/types/graphql-global-types';
import { EstablishmentContext } from '../../../Context/EstablishmentContext';

interface WorkerListProps {
  etabInputData?: EtablishmentDataType;
  handleClickRow?: (id: number) => void;
  setEtabInputData?: React.Dispatch<React.SetStateAction<EtablishmentDataType>>;
  disableImportAction?: boolean;
  loading?: boolean;
  fromDetails?: boolean;
  dataSource?: TableDataSource;
  workerData?:
    | (EtablishmentContributorsInfo_etablishment_workers | null)[]
    | any[];
}

const ACTIONS: GroupAction[] = [
  { label: 'Activer', value: 'enable' },
  { label: 'Désactiver', value: 'disable' },
  { label: 'Supprimer', value: 'remove' },
];

const INIT_STATE = {
  name: '',
  lastName: '',
  email: '',
  mobilePhone: '',
  phone: '',
  status: false,
};

const getWorkerIds = (etabInputData?: EtablishmentDataType) =>
  (etabInputData && etabInputData.workerIds) || [];

const WorkerList: FC<WorkerListProps> = (props) => {
  const {
    setEtabInputData,
    etabInputData,
    handleClickRow,
    workerData,
    fromDetails,
    loading,
    dataSource,
    disableImportAction,
  } = props;
  const classes = useStyles();
  const client = useApolloClient();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [data, setData] = useState<any[]>((fromDetails && workerData) || []);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [inputData, setInputData] = useState<FormInputDataState>(INIT_STATE);
  const [workerId, setWorkerId] = useState<number>(-1);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openUpdateUserDialog, setOpenUpdateUserDialog] =
    useState<boolean>(false);
  const [editId, setEditId] = useState<number>(-1);
  const [inputUpdateData, setInputUpdateData] =
    useState<FormInputDataState>(INIT_STATE);
  const { newEtablishmentId } = useContext(EstablishmentContext);
  useEffect(() => {
    if (fromDetails && workerData) {
      setData(workerData);
    }
  }, [workerData]);

  useEffect(() => {
    if (setEtabInputData) {
      setEtabInputData((prevState: EtablishmentDataType) => ({
        ...prevState,
        workerIds: selectedItems,
      }));
    }
  }, [selectedItems, setEtabInputData]);

  const filter = searchKey && {
    name: searchKey,
  };
  const creation = !etabInputData;
  const creationFilter = {
    etablishmentNull: true,
  };
  const editFilter = {
    id: getWorkerIds(etabInputData),
    establishmentEdit: true,
  };

  const variables = {
    input: {
      filter: JSON.stringify({
        ...(creation ? creationFilter : editFilter),
        ...filter,
      }),
    },
  };

  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const { loading: loadingWorkerList, data: workerList } = useQuery<
    Workers,
    WorkersVariables
  >(GET_WORKERS, { variables });

  const [createWorker, { loading: loadingCreateWorker }] = useMutation<
    CreateWorker,
    CreateWorkerVariables
  >(CREATE_WORKER, {
    onCompleted: (data: CreateWorker) => {
      if (data && data.createWorker) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = "L'intervenant a été ajouté";
        displaySnackbar(client, snackbarData);
        setOpenDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la création d'intervenant";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const { message } = error.graphQLErrors[0];
        switch (message) {
          case 'EMAIL_ALREADY_USED':
            snackbarData.type = 'ERROR';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = "L'adresse email est déjà utilisée";
            break;
        }
      } else if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [createWorkers, { loading: loadingCreateWorkers }] = useMutation<
    CreateWorkers,
    CreateWorkersVariables
  >(CREATE_WORKERS, {
    onCompleted: (data: CreateWorkers) => {
      if (data && data.createWorkers) {
        if (data.createWorkers.success) {
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `La liste d'intervenants a été importée`;
        } else {
          snackbarData.type = 'WARNING';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = 'Des informations dupliquées existent';
        }
        displaySnackbar(client, snackbarData);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la création des intervenants";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const { message } = error.graphQLErrors[0];
        if (message.includes('EMAIL_ALREADY_USED')) {
          snackbarData.type = 'WARNING';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `L'adresse email ${
            message.split(';')[0]
          } est déjà utilisée`;
        }
      } else if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [updateWorker, { loading: loadingUpdateWorker }] = useMutation<
    UpdateWorker,
    UpdateWorkerVariables
  >(UPDATE_WORKER, {
    onCompleted: (data: UpdateWorker) => {
      if (data && data.updateWorker) {
        setOpenUpdateUserDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour d'intervenant";
      displaySnackbar(client, snackbarData);
    },
  });

  const [deleteWorker] = useMutation<DeleteWorker, DeleteWorkerVariables>(
    DELETE_WORKER,
    {
      onCompleted: (data: DeleteWorker) => {
        if (data && data.deleteWorker) {
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `L'intervenant a été supprimé`;
          displaySnackbar(client, snackbarData);
          setOpenDialog(false);
        }
      },
      onError: (error: ApolloError) => {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          "Une erreur s'est produite lors de la suppression de l'intervenant";
        displaySnackbar(client, snackbarData);
      },
    },
  );

  const [deleteWorkers, { loading: loadingDeleteWorkers }] = useMutation<
    DeleteWorkers,
    DeleteWorkersVariables
  >(DELETE_WORKERS, {
    onCompleted: (data: DeleteWorkers) => {
      if (data && data.deleteWorkers) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Les intervenants ont été supprimés`;
        displaySnackbar(client, snackbarData);
        setOpenConfirmDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la suppression des intervenants";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  useEffect(() => {
    if (
      workerList &&
      workerList.workers &&
      isGlobalList(dataSource as string)
    ) {
      setData(workerList.workers);
    }
  }, [workerList]);

  const cachedWorkerList = client.readQuery({
    query: GET_ETABLISHMENT_DATA,
  });

  useEffect(() => {
    if (
      cachedWorkerList &&
      cachedWorkerList.etablishmentData &&
      cachedWorkerList.etablishmentData.workers &&
      dataSource &&
      dataSource === 'add-establishment'
    ) {
      const result = filterUserByName(
        cachedWorkerList.etablishmentData.workers,
        searchKey,
      );
      const adminList = cachedWorkerList.etablishmentData.workers;
      setData(searchKey ? result : adminList);
    }
  }, [cachedWorkerList, searchKey]);

  const columns: HeadCell[] = [
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.lastName) || '-';
      },
    },
    {
      name: 'lastName',
      label: 'Prénom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.firstName) || '-';
      },
    },
    {
      name: 'mail',
      label: 'Mail',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.email) || '-';
      },
    },
    {
      name: 'mobilePhone',
      label: 'Tél mobile',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.mobilePhone) || '-';
      },
    },
    {
      name: 'phone',
      label: 'Tél fixe',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.phone) || '-';
      },
    },
    {
      name: 'userFunction',
      label: 'Fonction',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.userFunction) || '-';
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => {
        const statut = value && value.user && value.user.enabled;

        return (
          <FormControlLabel
            control={
              <Switch
                checked={statut}
                onChange={(event) => onHandleChangeStatus(event, value.id)}
                name="enabled"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            }
            label={statut ? 'Activé' : 'Désactivé'}
          />
        );
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex">
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleDelete(value.id);
              }}
              className={classes.margin}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifier" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleEditUser(value.id);
              }}
              className={classes.margin}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const [updateWorkerStatus] = useMutation<
    UpdateWorkerStatus,
    UpdateWorkerStatusVariables
  >(UPDATE_WORKER_STATUS, {
    onCompleted: (data: UpdateWorkerStatus) => {
      if (data && data.updateWorkerStatus) {
        const statut =
          data.updateWorkerStatus &&
          data.updateWorkerStatus[0] &&
          data.updateWorkerStatus[0].user &&
          data.updateWorkerStatus[0].user.enabled === true
            ? 'activé'
            : 'désactivé';
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Les intervenants ont été ${statut}s`;
        displaySnackbar(client, snackbarData);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour des intervenants";

      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const onHandleChangeStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const { checked } = event.target;
    updateWorker({
      variables: {
        id,
        enabled: checked,
      },
      update: onUpdatedCachedEtab,
    }).then((options) => {
      if (options.data && options.data.updateWorker) {
        const statut =
          options.data.updateWorker.user &&
          options.data.updateWorker.user.enabled
            ? 'activé'
            : 'désactivé';
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `L'intervenant a été ${statut}`;
        displaySnackbar(client, snackbarData);
      }
    });
  };

  const onUpdatedCachedEtab: any = (
    cache: DataProxy,
    { data }: { data: UpdateWorker | null | undefined },
  ) => {
    if (
      dataSource &&
      dataSource === 'add-establishment' &&
      data &&
      data.updateWorker
    ) {
      let etabData = cache.readQuery<any>({
        query: GET_ETABLISHMENT_DATA,
      });

      if (etabData && etabData.etablishmentData) {
        let workers = etabData.etablishmentData.workers || [];
        let newWorkers = workers.filter((worker: any) => {
          if (
            worker &&
            worker.id &&
            data.updateWorker &&
            data.updateWorker.id
          ) {
            return worker.id !== data.updateWorker.id;
          }
        });
        let newEtablishmentWorkers = [data.updateWorker, ...newWorkers];

        etabData.etablishmentData = {
          ...etabData.etablishmentData,
          workers: newEtablishmentWorkers,
        };
        cache.writeQuery({
          query: GET_ETABLISHMENT_DATA,
          data: {
            etablishmentData: etabData.etablishmentData,
          },
        });
      }
    }
  };

  const addNewWorkersToCache = (cache: DataProxy, newWorkers: any[]) => {
    if (dataSource && dataSource === 'add-establishment') {
      let etabData = cache.readQuery<any>({
        query: GET_ETABLISHMENT_DATA,
      });

      if (etabData && etabData.etablishmentData) {
        const workers = etabData.etablishmentData.workers || [];
        let newEtablishmentWorkers = [...newWorkers, ...workers];

        etabData.etablishmentData = {
          ...etabData.etablishmentData,
          id: newEtablishmentWorkers.length + 1,
          workers: newEtablishmentWorkers,
        };

        cache.writeQuery({
          query: GET_ETABLISHMENT_DATA,
          data: {
            etablishmentData: etabData.etablishmentData,
          },
        });
      }
    } else {
      const workerData = cache.readQuery<Workers>({
        query: GET_WORKERS,
        variables,
      });
      if (newWorkers && workerData) {
        cache.writeQuery({
          query: GET_WORKERS,
          variables,
          data: {
            workers: [...newWorkers, ...workerData.workers],
          },
        });
      }
    }
  };

  const createWorkerUpdate: MutationUpdaterFn<CreateWorker> = (
    cache,
    { data },
  ) => {
    const newWorker = data && data.createWorker && data.createWorker.data;
    addNewWorkersToCache(cache, [newWorker]);
  };

  const onHandleAddUser = (data: FormInputDataState, cb: any) => {
    const user: User = formInputDataStateToUser(data, 'worker') as User;
    handleEstablishmentCreateUserListValidation(user, client, () => {
      createWorker({
        variables: {
          ...user,
          establishmentId: newEtablishmentId,
          skipMail: !!(dataSource === 'add-establishment'),
        },
        update: createWorkerUpdate,
      })
        .then(({ data }) => {
          return cb(!!(data && data.createWorker && data.createWorker.success));
        })
        .catch(() => {
          return cb(false);
        });
    });
  };

  const onDeleteWorker = () => {
    if (workerId && workerId > 0) {
      deleteWorker({
        variables: {
          id: workerId,
        },
        update: (cache, { data }) => {
          const deletedId = data && data.deleteWorker && data.deleteWorker.id;

          if (
            dataSource &&
            dataSource === 'add-establishment' &&
            data &&
            data.deleteWorker
          ) {
            let etabData = cache.readQuery<any>({
              query: GET_ETABLISHMENT_DATA,
            });

            if (etabData && etabData.etablishmentData) {
              let workers = etabData.etablishmentData.workers || [];
              let newWorkers = workers.filter((worker: any) => {
                if (worker && worker.id) {
                  return deletedId !== worker.id;
                }
              });

              etabData.etablishmentData = {
                ...etabData.etablishmentData,
                workers: newWorkers,
              };
              cache.writeQuery({
                query: GET_ETABLISHMENT_DATA,
                data: {
                  etablishmentData: etabData.etablishmentData,
                },
              });
            }
          } else {
            const workerData = cache.readQuery<Workers>({
              query: GET_WORKERS,
              variables,
            });

            if (deletedId && workerData) {
              const newData = workerData.workers.filter(
                (worker) => worker && worker.id !== deletedId,
              );
              cache.writeQuery({
                query: GET_WORKERS,
                variables,
                data: {
                  workers: newData,
                },
              });
            }
          }
        },
      });
    } else {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la suppression de l'intervenant";
      displaySnackbar(client, snackbarData);
    }
    setOpenConfirmDialog(false);
  };

  const onHandleDelete = (id: number) => {
    setWorkerId(id);
    setOpenConfirmDialog(true);
  };

  const onHandleEditUser = (id: number) => {
    setEditId(id);
    const input = data.find((item) => item.id === id).user;
    setInputUpdateData({
      ...input,
      name: input.firstName,
      lastName: input.lastName || '',
      status: input.enabled,
    });
    setOpenUpdateUserDialog(true);
  };

  const onHandleUpdateUser = (data: FormInputDataState, cb: any) => {
    const user: User = formInputDataStateToUser(data, 'worker') as User;
    handleEstablishmentCreateUserListValidation(user, client, () => {
      updateWorker({
        variables: {
          ...user,
          id: editId,
        },
        update: onUpdatedCachedEtab,
      }).then(({ data }) => {
        if (data && data.updateWorker) {
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `L'intervenant a été mis à jour`;
          displaySnackbar(client, snackbarData);
        }
      });
    });
  };

  const onHandleSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKey(value);
  };

  const changeWorkerStatus = (
    selectedItems: number[],
    enabled: boolean,
  ): void => {
    const status = enabled ? 'activer' : 'désactiver';
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les intervenants à ${status}`,
      )
    ) {
      updateWorkerStatus({
        variables: { ids: selectedItems, status: enabled },
        update: (cache, { data }) => {
          if (
            dataSource &&
            dataSource === 'add-establishment' &&
            data &&
            data.updateWorkerStatus
          ) {
            let etabData = cache.readQuery<any>({
              query: GET_ETABLISHMENT_DATA,
            });

            if (etabData && etabData.etablishmentData) {
              let workers = etabData.etablishmentData.workers || [];
              let updatedIds = data.updateWorkerStatus.map(
                (item: any) => item && item.id,
              );
              let newWorkers = workers.filter((worker: any) => {
                if (worker && worker.id) {
                  return !updatedIds.includes(worker.id);
                }
              });
              let newEtablishmentWorkers = [
                ...data.updateWorkerStatus,
                ...newWorkers,
              ];

              etabData.etablishmentData = {
                ...etabData.etablishmentData,
                workers: newEtablishmentWorkers,
              };
              cache.writeQuery({
                query: GET_ETABLISHMENT_DATA,
                data: {
                  etablishmentData: etabData.etablishmentData,
                },
              });
            }
          }
        },
      });
    }
  };

  const removeWorkers = (selectedItems: number[]): void => {
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les intervenants à supprimer`,
      )
    ) {
      deleteWorkers({
        variables: { ids: selectedItems },
        update: (cache, { data }) => {
          const deletedWorkers = ((data && data.deleteWorkers) || []).map(
            (item) => item && item.id,
          );

          if (
            dataSource &&
            dataSource === 'add-establishment' &&
            data &&
            data.deleteWorkers
          ) {
            let etabData = cache.readQuery<any>({
              query: GET_ETABLISHMENT_DATA,
            });

            if (etabData && etabData.etablishmentData) {
              let workers = etabData.etablishmentData.workers || [];
              let newWorkers = workers.filter((worker: any) => {
                if (worker && worker.id) {
                  return !deletedWorkers.includes(worker.id);
                }
              });

              etabData.etablishmentData = {
                ...etabData.etablishmentData,
                workers: newWorkers,
              };
              cache.writeQuery({
                query: GET_ETABLISHMENT_DATA,
                data: {
                  etablishmentData: etabData.etablishmentData,
                },
              });
            }
          } else {
            const workerData = cache.readQuery<Workers>({
              query: GET_WORKERS,
              variables,
            });

            if (deletedWorkers && workerData && workerData.workers) {
              const newData = workerData.workers.filter(
                (worker) => worker && deletedWorkers.indexOf(worker.id) === -1,
              );
              cache.writeQuery({
                query: GET_WORKERS,
                variables,
                data: {
                  workers: newData,
                },
              });
            }
          }
        },
      });
    }
  };

  const onHandleAction = (action: ValueType<GroupAction>): void => {
    const actionGroup = action as GroupAction;

    if (actionGroup && actionGroup.value) {
      switch (actionGroup.value) {
        case 'enable':
          changeWorkerStatus(selectedItems, true);
          break;
        case 'disable':
          changeWorkerStatus(selectedItems, false);
          break;
        case 'remove':
          removeWorkers(selectedItems);
          break;
      }
    }
  };

  const onExcelReaderChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files) {
      const users: User[] = await getUsersFromFiles(
        { ...event.target.files },
        {
          userRole: 'worker',
        },
      );
      const userValidationState: UserValidationState = validateUsers(
        users,
        getImportExcelValidators('worker'),
      );
      if (userValidationState.valid) {
        createWorkers({
          variables: {
            input: getPartialUsers(
              users.map((user) => {
                return {
                  ...user,
                  etablishmentId: newEtablishmentId,
                };
              }),
              ['userRole'],
            ) as CreateWorkerInput[],
          },
          update: (cache, { data }) => {
            const newWorkers =
              data && data.createWorkers && data.createWorkers.data;
            if (newWorkers) {
              addNewWorkersToCache(cache, newWorkers);
            }
          },
        });
      } else if (userValidationState.error) {
        const errorMessage: string = getExcelImportUserErrorMessage(
          userValidationState.error,
        );
        showSnackbar({
          type: 'ERROR',
          message: errorMessage,
          client,
        });
      }
    }
  };

  const workerToolbarButtons = [
    <SearchInput
      onChange={onHandleSearchKey}
      value={searchKey}
      name="worker"
      placeholder="Recherchez un intervenant"
      // styles={{ minWidth: 350 }}
    />,
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="worker-add"
      startIcon={<AddBoxIcon />}
      style={{ minWidth: 200 }}
      onClick={() => setOpenDialog(true)}
    >
      Ajouter un intervenant
    </Button>,
    <ExcelReader
      onChange={onExcelReaderChange}
      onClick={() => {}}
      loading={false}
    />,
  ];

  return (
    <>
      <CustomTable
        toolbar
        selectable
        maxWidth={1348}
        data={data}
        columns={columns}
        toolbarActions={ACTIONS}
        onHandleAction={onHandleAction}
        toolbarButtons={workerToolbarButtons}
        toolbarStyles={{ display: 'flex', justifyContent: 'flex-end' }}
        setSelectedItems={setSelectedItems}
        selectedItems={selectedItems}
        loadingData={loading || loadingWorkerList}
        handleClickRow={handleClickRow}
      />
      <UserFormDialog
        userRole="worker"
        open={openDialog}
        title="Ajout d'un intervenant"
        onSubmit={onHandleAddUser}
        data={inputData}
        loadingButton={loadingCreateWorker}
        handleClose={() => {
          setOpenDialog(false);
        }}
      />
      <UserFormDialog
        userRole="worker"
        open={openUpdateUserDialog}
        title="Modifier un intervenant"
        onSubmit={onHandleUpdateUser}
        data={inputUpdateData}
        loadingButton={loadingUpdateWorker}
        buttonLabel="Enregistrer"
        handleClose={() => {
          setOpenUpdateUserDialog(false);
        }}
      />

      <ConfirmationDialog
        title="Suppression"
        message="Etes-vous sûr de vouloir supprimer cet intervenant ?"
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteWorker}
      />
    </>
  );
};

export default WorkerList;
