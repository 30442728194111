import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import React, { FC } from 'react';
import useStyles from './styles';
import DialogContent from '@material-ui/core/DialogContent';

interface ImageDisplayerDialogProps {
  title: string;
  imageUrl?: string;
  open: boolean;
  setOpen: (value: boolean) => void;
}

const ImageDisplayerDialog: FC<ImageDisplayerDialogProps> = (props) => {
  const { open, setOpen, imageUrl } = props;
  const classes = useStyles();
  return (
    <Dialog className={classes.root} open={open}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <img
          src={imageUrl}
          style={{
            height: 'auto',
            width: 'auto',
            objectFit: 'contain',
            maxHeight: '200px',
            maxWidth: '302px',
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ImageDisplayerDialog;
