import React, { FC } from 'react';
import useStyles, { styles } from './styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActions, DialogContent } from '@material-ui/core';

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.dialogTitle}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface ClosableDialogWrapperProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  dialogActions?: React.ReactNode;
}

const ClosableDialogWrapper: FC<ClosableDialogWrapperProps> = (props) => {
  const { open, onClose, title, dialogActions, children } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      className={classes.root}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.content}>{children}</DialogContent>
      {!!dialogActions && <DialogActions>{dialogActions}</DialogActions>}
    </Dialog>
  );
};

export default ClosableDialogWrapper;
