import gql from 'graphql-tag';

export const INTERVENTION_REPORT_CUSTOM_FRAGMENT = gql`
  fragment InterventionReportCustom on InterventionReportCustom {
    id
    duration
    createdAt
    updatedAt
    timeEnd
    timeStart
    signature
    workerId
    worker
    workerEmail
    workerPhone
    workerMobilePhone
    beneficiaryId
    beneficiary
    beneficiaryEmail
    beneficiaryPhone
    prestation
    etablishmentId
    etablishment
    title
    status
    planning_id
    location
    enabled
    etablishmentAddress
    etablishmentCity
    rcs
    etablishmentTypeId
  }
`;

export const INTERVENTION_REPORT_FRAGMENT = gql`
  fragment InterventionReport on InterventionReport {
    id
    createdAt
    updatedAt
    duration
    signature
    beneficiary {
      id
      user {
        id
        firstName
        lastName
        email
        mobilePhone
        phone
      }
    }
    worker {
      id
      user {
        id
        firstName
        lastName
        email
        mobilePhone
        phone
      }
    }
    planning {
      id
      dateStart
      dateEnd
      beneficiarySignature
      title
      beneficiary {
        id
        user {
          id
          firstName
          lastName
          email
          phone
          mobilePhone
        }
      }
      worker {
        id
        user {
          id
          firstName
          lastName
          email
          phone
          mobilePhone
        }
      }
      intervention {
        id
        prestation
        description
        interventionStatus {
          id
          name
        }
        workers {
          id
          user {
            id
            firstName
            lastName
            email
            mobilePhone
            phone
          }
        }
        beneficiaries {
          id
          user {
            id
            firstName
            lastName
            email
            mobilePhone
            phone
          }
        }
        etablishment {
          id
          name
          address
          name
          rcs
          enabled
          etablishmentType {
            id
            name
          }
          logo
        }
      }
    }
  }
`;
