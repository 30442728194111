import gql from 'graphql-tag';

export const CREATE_PRESTATION = gql`
  mutation createPrestation($input: CreatePrestationInput!) {
    createPrestation(input: $input) {
      action
      success
      message
      data {
        id
        libelle
      }
    }
  }
`;

export const CREATE_PRESTATIONS = gql`
  mutation CreatePrestations($input: [CreatePrestationInput]!) {
    createPrestations(input: $input) {
      action
      success
      message
      data {
        id
        libelle
      }
    }
  }
`;

export const UPDATE_PRESTATION = gql`
  mutation UpdatePrestation($input: UpdatePrestationInput!) {
    updatePrestation(input: $input) {
      id
      libelle
      etablishmentId
    }
  }
`;

export const DELETE_PRESTATION = gql`
  mutation DeletePrestation($id: Int!) {
    deletePrestation(id: $id) {
      id
      libelle
    }
  }
`;

export const DELETE_PRESTATIONS = gql`
  mutation DeletePrestations($ids: [Int]!) {
    deletePrestations(ids: $ids) {
      id
      libelle
    }
  }
`;
