import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { Redirect, useParams, withRouter } from 'react-router-dom';
import EstablishmentAdmin from '../../EstablishmentAdmin';

interface EstablishmentChildDetailsProps {}
interface RouteParams {
  id: string;
  parentId: string;
}

// ONLY USE IN SUPER ADMIN

const EstablishmentSuperAdminChildDetails: FC<EstablishmentChildDetailsProps> = (
  props,
) => {
  const { id, parentId } = useParams<RouteParams>();
  const establishmentId: number = +id;
  const establishmentRootId: number = +parentId;
  if (!establishmentId) {
    return (
      <Redirect to={`/etablissements/details/${establishmentRootId}?tab=1`} />
    );
  }
  return (
    <EstablishmentAdmin
      establishmentId={establishmentId}
      hideTitle={false}
      redirectionUrl={`/etablissements/details/${establishmentRootId}?tab=1`}
    />
  );
};

export default withRouter(EstablishmentSuperAdminChildDetails);
