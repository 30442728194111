export const BENEFICIARY_DOCUMENTS = [
  "Pièce d'identité",
  'Reconnaissance MDPH',
  'Attestation APA',
  'Attestation AAH/PCH',
];

export const ESTABLISHEMENT_DOCUMENTS = [
  'Statut',
  "Autorisation d'ouverture/Arrêté de création",
  "Arrêté d'extension",
  "Projet d'établissement",
  'Contrat de séjour',
  'Règlement intérieur',
];
