import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { overflow: 'hidden' },
    paper: {
      boxShadow: '0 6px 16px rgba(0,0,0,0.09)',
      padding: 15,
      borderRadius: 16,
      marginBottom: 30,
    },
    appBar: {
      position: 'relative',
      height: 80,
    },
    toolBar: {
      height: 80,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
      fontSize: 16,
      fontWeight: 'bold',
    },
    logo: {
      width: 48,
      height: 48,
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    container: {
      height: 'calc(100vh - 80px)',
      overflowY: 'auto',
    },
    content: {
      marginTop: '100px',
      width: '100%',
    },
    footer: {
      height: 80,
      width: '100%',
    },
  }),
);

export default useStyles;
