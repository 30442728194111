import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import useStyles from './styles';
import { PlanningDataType } from '../../../../../../../types/planning';
import { useEffect } from 'react';
import { PeriodicityTypeEnum } from '../../../../../../../graphql/types/graphql-global-types';

interface BasicInformationsProps {
  inputData: PlanningDataType;
  setInputData: React.Dispatch<React.SetStateAction<any>>;
}

const PeriodicityMen: FC<BasicInformationsProps> = (props) => {
  const classes = useStyles();
  const { inputData, setInputData } = props;

  useEffect(() => {
    setInputData((prevState: any) => ({
      ...prevState,
      periodicityType: PeriodicityTypeEnum.MONTHLY,
    }));
  }, []);

  const onHandleChange = (
    event: React.ChangeEvent<
      | HTMLInputElement
      | {
          name?: string | undefined;
          value: unknown;
        }
    >,
  ) => {
    const { name, value } = event.target;

    if (name) {
      setInputData((prevState: any) => ({
        ...prevState,
        [name]: value,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }));
    }
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={2} className={classes.day}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={8} className={classes.day}></Grid>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <p style={{ margin: 0 }}>Tous les</p>
          <TextField
            id="month-number"
            type="number"
            placeholder="0"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ margin: '0 8px' }}
            inputProps={{ min: '1', max: '100' }}
            variant="outlined"
            size="small"
            name="periodicityRepetition"
            onChange={onHandleChange}
            value={inputData.periodicityRepetition}
          />
          <p style={{ margin: 0 }}>mois</p>
        </Box>
      </Grid>
    </Box>
  );
};

export default PeriodicityMen;
