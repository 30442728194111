import React, { FC } from 'react';
import useStyles, { styles } from './styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  withStyles,
  Typography,
  IconButton,
  WithStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.dialogTitle}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface UnitLifeEditorWrapperProps {
  open: boolean;
  onClose: () => void;
  title?: string;
}

const UnitLifeEditorWrapper: FC<UnitLifeEditorWrapperProps> = (props) => {
  const { open, onClose, children, title } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      className={classes.root}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>
      {children}
    </Dialog>
  );
};

export default UnitLifeEditorWrapper;
