export const convertImageFileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader && reader.result) {
        resolve(reader.result.toString());
      }
    };
    reader.onerror = function (error) {
      reject(null);
    };
  });
