import { ApolloClient, ApolloError } from 'apollo-boost';
import { showSnackbar } from '../../../../../../utils/snackbar.util';

export interface OnUpdateUnitLifeCompleteArgs {
  client: ApolloClient<object>;
}

export interface OnUpdateUnitLifeErrorArgs {
  client: ApolloClient<object>;
}

export const onUpdateUnitLifeComplete = ({
  client,
}: OnUpdateUnitLifeCompleteArgs) => () => {
  showSnackbar({
    client,
    message: `Unité de vie mise à jour avec succès`,
    type: 'SUCCESS',
  });
};

export const onUpdateUnitLifeError = ({
  client,
}: OnUpdateUnitLifeErrorArgs) => () => {
  showSnackbar({
    client,
    message: `Erreur lors de la mise à jour de l'unité de vie`,
    type: 'ERROR',
  });
};
