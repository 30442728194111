import React, { FC, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import useStyles from './styles';
import CardInfo from '../CardInfo';
import { Typography } from '@material-ui/core';
import Signature from '../Signature';
import { InterventionReportData } from '../../../../types/intervention-report.types';
import {
  formatDateWithHours,
  toHoursAndMinute,
  calculEndDate,
} from '../../../../utils/date.utils';
import TextItem from '../../../WorkerDetails/components/TextItem';

interface InterventionReportContentProps {
  interventionReport: InterventionReportData;
}

const InterventionReportContent: FC<InterventionReportContentProps> = (
  props,
) => {
  const classes = useStyles();
  const { interventionReport } = props;
  const {
    beneficiary,
    worker,
    duration,
    dateEnd,
    dateStart,
    prestation,
    name,
    signature,
  } = interventionReport;
  const total = beneficiary.name.split(',');

  return (
    <Box className={classes.root}>
      <Row>
        <Col xl={6} lg={6} md={12} sm={12} className={classes.col}>
          <CardInfo title="Intervenant" height={220}>
            <Typography component="h4" className={classes.userName}>
              {worker.name}
            </Typography>
            <TextItem
              title="Mail :"
              content={worker.email}
              inline
              width={100}
              mb={8}
            />
            <TextItem
              title="Tél mobile :"
              content={worker.mobilePhone}
              inline
              width={100}
              mb={8}
            />
            <TextItem
              title="Tél fixe :"
              content={worker.phone}
              inline
              width={100}
              mb={8}
            />
          </CardInfo>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} className={classes.col}>
          <CardInfo title="Bénéficiaire" height={220}>
            <Typography
              component="h4"
              className={classes.userBeneficiaryName}
              style={{ overflow: 'auto', height: 'auto' }}
            >
              {beneficiary.name}
            </Typography>

            {total.length < 2 && (
              <>
                <TextItem
                  title="Mail :"
                  content={beneficiary.email}
                  inline
                  width={100}
                  mb={8}
                />
                <TextItem
                  title="Tél mobile :"
                  content={beneficiary.mobilePhone || '-'}
                  inline
                  width={100}
                  mb={8}
                />
                <TextItem
                  title="Tél fixe :"
                  content={beneficiary.phone || '-'}
                  inline
                  width={100}
                  mb={8}
                />
              </>
            )}
          </CardInfo>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} className={classes.col}>
          <CardInfo title="Détails de l'intervention" height={280}>
            <TextItem
              title="Nature de la prestation :"
              content={prestation || '-'}
              inline
              width={180}
              mb={8}
            />
            <TextItem
              title="Durée:"
              content={toHoursAndMinute(duration)}
              inline
              width={180}
              mb={8}
            />
            <TextItem
              title="Date début :"
              content={formatDateWithHours(dateStart)}
              inline
              mb={8}
              width={180}
            />
            <TextItem
              title="Date fin :"
              content={calculEndDate(duration, dateStart)}
              inline
              width={180}
            />
          </CardInfo>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} className={classes.col}>
          <CardInfo title="Description" height={280}>
            {name}
          </CardInfo>
        </Col>
      </Row>
      <Box
        display="flex"
        justifyContent="flex-end"
        className={classes.signature}
      >
        <Signature title="Signature du bénéficiaire" imageUrl={signature} />
      </Box>
    </Box>
  );
};

export default InterventionReportContent;
