import gql from 'graphql-tag';

import { ESTABLISHMENT_FRAGMENT } from './fragment';

export const CREATE_ESTABLISHMENT = gql`
  mutation CreateEstablishment($input: CreateEtablishmentInput!) {
    createEtablishment(input: $input) {
      action
      success
      message
      data {
        ...EstablishmentInfo
      }
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;

export const UPDATE_ESTABLISHMENT = gql`
  mutation UpdateEstablishment($input: UpdateEtablishmentInput!) {
    updateEtablishment(input: $input) {
      ...EstablishmentInfo
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;

export const UPDATE_ESTABLISHMENT_STATUS = gql`
  mutation UpdateEstablishmentStatus($ids: [Int]!, $status: Boolean!) {
    updateEtablishmentStatus(ids: $ids, status: $status) {
      ...EstablishmentInfo
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;

export const DELETE_ESTABLISHMENT = gql`
  mutation DeleteEstablishment($id: Int!) {
    deleteEtablishment(id: $id) {
      ...EstablishmentInfo
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;

export const DELETE_ESTABLISHMENTS = gql`
  mutation DeleteEstablishments($ids: [Int]!) {
    deleteEtablishments(ids: $ids) {
      ...EstablishmentInfo
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;
