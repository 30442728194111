import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 150,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      [theme.breakpoints.down(416)]: {
        padding: '16px 0',
        minHeight: 100,
      },
    },
  }),
);

export default useStyles;
