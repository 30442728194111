import React, { FC } from 'react';
import Box from '@material-ui/core/Box';

import useStyles from './styles';
import { Tab } from '../../../../types/tabs';
import { EstablishmentTypeType } from '../../../../types/establishment';

interface EstablishmentTabPanelProps {
  tabs: Tab[];
  value: number | string;
  type: EstablishmentTypeType | string;
  [key: string]: any;
}

const EstablishmentTabPanel: FC<EstablishmentTabPanelProps> = (props) => {
  const { value, tabs, type } = props;
  const classes = useStyles();

  return (
    <>
      {tabs.map((tab: Tab) => (
        <div
          key={tab.index}
          role="tabpanel"
          hidden={value !== tab.index}
          id={`scrollable-auto-tabpanel-${tab.index}`}
          aria-labelledby={`scrollable-auto-tab-${tab.index}`}
        >
          {value === tab.index && (
            <Box className={classes.root}>{tab.panel}</Box>
          )}
        </div>
      ))}
    </>
  );
};

export default EstablishmentTabPanel;
