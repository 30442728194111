import { useReducer } from 'react';
import { ETABLISHMENT_DATA } from '../../../../../constants/etablishement';
import { EstablishmentEditState } from '../interfaces/EstablishmentEdit.interfaces';
import { EstablishmentAction } from './establishment-edit.actions';

const initialState: EstablishmentEditState = {
  establishmentId: 0,
  establishment: null,
  establishmentType: 'DOMICILE',
  establishmentEditFinish: false,
  minorChanges: null,
};

const reducer = (
  state: EstablishmentEditState,
  action: EstablishmentAction,
): EstablishmentEditState => action.reducer(state);

export const useEstablishmentEditReducer = (
  partialInitialState?: Partial<EstablishmentEditState>,
) => {
  const partialState = partialInitialState || {};
  return useReducer(reducer, {
    ...initialState,
    ...partialState,
  });
};
