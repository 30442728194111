import { InterventionReportCustom } from '../../../graphql/intervention-report/types/InterventionReportCustom';
import { formatDate } from '../../../utils/date.utils';
export interface interventionReportExcelFormat {
  Titre: string;
  Statut: string;
  Etablissement: string;
  Prestation: string;
  Intervenant: string;
  Beneficiaire: string;
  Date: string;
  Duree: string;
  location: string;
}

export const formatInterventionReportData = (
  interventionReport: InterventionReportCustom,
): any => {
  if (!interventionReport) return {};

  return {
    Date: formatDate(interventionReport.createdAt),
    Titre: interventionReport.title,
    Duree: interventionReport.duration,
    Intervenant: interventionReport.worker,
    Beneficiaire: interventionReport.beneficiary,
    Prestation: interventionReport.prestation,
    Statut: getLabelStatus(
      interventionReport.status ? interventionReport.status : '',
    ),
    Etablissement: interventionReport.etablishment,
    Lieu: interventionReport.location,
  };
};

export const getLabelStatus = (status: string): string => {
  let label: string = '';
  switch (status) {
    case 'TODO':
      label = 'A faire';
      break;
    case 'COMPLETED':
      label = 'Validé';
      break;
    case 'WAITING_VALIDATION':
      label = 'A Valider';
      break;
    case 'IN_PROGRESS':
      label = 'En cours';
      break;
    default:
      label = 'Annuler';
  }

  return label;
};
