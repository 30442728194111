import { Worker_worker } from '../../../graphql/worker/types/Worker';

export interface WorkerExcelFormat {
  nom: string;
  prenom: string;
  email: string;
  tel_mobile: string;
  tel_fixe: string;
  fonction: string;
  statut: string;
  etablissement: string;
}

export const formatWorkerData = (
  worker: Worker_worker,
): WorkerExcelFormat | {} => {
  if (!worker) return {};

  return {
    nom: (worker.user && worker.user.lastName) || '',
    prenom: (worker.user && worker.user.firstName) || '',
    email: (worker.user && worker.user.email) || '',
    tel_mobile: (worker.user && worker.user.mobilePhone) || '',
    tel_fixe: (worker.user && worker.user.phone) || '',
    fonction: (worker.user && worker.user.userFunction) || '',
    statut: worker.user && worker.user.enabled ? 'Activé' : 'Désactivé',
    etablissement: (worker.etablishment && worker.etablishment.name) || '',
  };
};
