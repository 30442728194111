import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 80,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(1, 6),
      backgroundColor: '#fff',
      border: '1px solid #E0E0E0',
      position: 'fixed',
      zIndex: 998,
      bottom: 0,
      left: 0,
      width: '100%',
      boxSizing: 'border-box',
      [theme.breakpoints.down(500)]: {
        padding: theme.spacing(1, 1),
        height: 'auto',
      },
    },
  }),
);

export default useStyles;
