import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 210,
      maxHeight: 210,
      // border: '1px solid #E0E0E0',
      margin: theme.spacing(2, 0),
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  }),
);

export default useStyles;
