import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';

import useStyles from './styles';

interface ConfirmationDialogProps {
  title: string;
  message: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  onConfirm: () => void;
  loadingConfirmation?: boolean;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
  const classes = useStyles();
  const { loadingConfirmation } = props;
  const { title, message, open, setOpen, onConfirm } = props;

  return (
    <Dialog className={classes.root} open={open}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.title}>{title}</DialogContentText>
        <DialogContentText className={classes.message}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          color="default"
          variant="contained"
          className={`${classes.button} ${classes.cancelButton}`}
          onClick={() => setOpen(false)}
        >
          Annuler
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={loadingConfirmation}
          className={classes.button}
          onClick={onConfirm}
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
