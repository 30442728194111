import { InterventionDataType } from '../types/planning';
import { DEFAULT_USER } from '../components/Establishment/EstablishmentAdd/EstablishmentEdit/UserFormDialog/UserFormDialog.constants';
import { convertExcelToJSON } from '../common/excel';
import { getNormalTime } from '../common/utils';
import { getStatus, getPeriodicity } from '../constants/status';
import { InterventionImportErrorMessages } from '../constants/intervention-import.constant';
import moment from 'moment';
import { DateEndType } from '../graphql/types/graphql-global-types';

export type InterventionTypesKey = keyof InterventionDataType;
export type InterventionFieldName = Record<InterventionTypesKey, string>;

export const fieldName: InterventionFieldName = {
  id: 'id',
  description: "Titre de l'intervention",
  dateEnd: 'Date de fin de la périodicité',
  dateStart: 'Date de début de la périodicité',
  prestation: 'Nature de la prestation',
  location: "Lieu de l'intervention",
  beneficiaries: 'Liste des bénéficiaires',
  workers: 'Liste des intervenants',
  timeStart: 'Heure de début',
  timeEnd: 'Heure de fin',
  comment: "Commentaire de l'intervention",
  status: 'Statut',
  type: 'Périodicité',
  repetition: 'Fréquence',
  dateTimeStartTimestamp: '',
  dateTimeEndTimestamp: '',
  etablishmentId: '',
  dateEndType: '',
  monday: 'Lundi',
  tuesday: 'Mardi',
  wednesday: 'Mercredi',
  thursday: 'Jeudi',
  friday: 'Vendredi',
  saturday: 'Samedi',
  sunday: 'Dimanche',
  everyDay: 'Tous les jours ouvrables',
};

export const getValue = (
  fileObj: any,
  field: string,
): number | Date | string | boolean => {
  let value: string = fileObj[field];
  if (typeof value === 'undefined')
    throw new Error(`Colonne '${field}' manquante`);
  value = value.trim();
  switch (field) {
    case fieldName.dateStart:
    case fieldName.dateEnd:
      const date = new Date(value);
      if (date.toString() === 'Invalid date')
        throw new Error(InterventionImportErrorMessages.INVALID_DATE);
      return date;
    case fieldName.timeStart:
    case fieldName.timeEnd:
      if (!value.includes(':'))
        throw new Error(InterventionImportErrorMessages.INVALID_HOUR);
      return value;
    case fieldName.description:
    case fieldName.prestation:
    case fieldName.location:
    case fieldName.workers:
    case fieldName.beneficiaries:
      if (!value)
        throw new Error(InterventionImportErrorMessages.REQUIRED_FIELD);
      return value;
    case fieldName.monday:
    case fieldName.tuesday:
    case fieldName.wednesday:
    case fieldName.thursday:
    case fieldName.friday:
    case fieldName.saturday:
    case fieldName.sunday:
    case fieldName.everyDay:
      return value ? value.toLowerCase() === 'oui' : false;
    case fieldName.status:
      const status = getStatus[value];
      if (!status)
        throw new Error(InterventionImportErrorMessages.INVALID_STATUS);
      return status;
    case fieldName.type:
      const periodicity = getPeriodicity[value];
      if (!periodicity)
        throw new Error(InterventionImportErrorMessages.INVALID_PERIODICITY);
      return periodicity;
    case fieldName.repetition:
      const repetition = +value;
      if (isNaN(repetition) || repetition <= 0)
        throw new Error(InterventionImportErrorMessages.INVALID_REPETITION);
      return repetition;
    default:
      return value;
  }
};

export const convertToIntervention = (
  fileObj: any,
  fieldName: InterventionFieldName,
  etablishmentId: number,
): InterventionDataType => {
  let dateStart = getValue(fileObj, fieldName.dateStart) as Date;
  dateStart.setUTCHours(0, 0, 0);
  dateStart = moment(dateStart.toUTCString()).toDate();
  let dateEnd = getValue(fileObj, fieldName.dateEnd) as Date;
  dateEnd.setUTCHours(0, 0, 0);
  dateEnd = moment(dateEnd.toUTCString()).toDate();
  let hourStart = getValue(fileObj, fieldName.timeStart) as string;
  let hourEnd = getValue(fileObj, fieldName.timeEnd) as string;
  let timeStart = moment(hourStart).format('HH:mm');
  let timeEnd = moment(hourEnd).format('HH:mm');

  const dateTimeStart =
    moment(dateStart).format('YYYY-MM-DD').toString() + ' ' + hourStart;
  const dateTimeEnd =
    moment(dateEnd).format('YYYY-MM-DD').toString() + ' ' + hourEnd;
  const dateTimeStartTimestamp = new Date(dateTimeStart).getTime().toString();
  const dateTimeEndTimestamp = new Date(dateTimeEnd).getTime().toString();
  const splithourStart = hourStart.split(':');
  const timeStartWithTimezone = new Date();
  timeStartWithTimezone.setHours(+splithourStart[0], +splithourStart[1]);

  const splithourEnd = hourEnd.split(':');
  const timeEndWithTimezone = new Date();
  timeEndWithTimezone.setHours(+splithourEnd[0], +splithourEnd[1]);
  let everyDay = getValue(fileObj, fieldName.everyDay) as boolean;
  let periodicity = getValue(fileObj, fieldName.type) as string;
  let days = {
    monday: getValue(fileObj, fieldName.monday) as boolean,
    tuesday: getValue(fileObj, fieldName.tuesday) as boolean,
    wednesday: getValue(fileObj, fieldName.wednesday) as boolean,
    thursday: getValue(fileObj, fieldName.thursday) as boolean,
    friday: getValue(fileObj, fieldName.friday) as boolean,
    saturday: getValue(fileObj, fieldName.saturday) as boolean,
    sunday: getValue(fileObj, fieldName.sunday) as boolean,
  };

  switch (periodicity) {
    case 'DAILY':
      if (everyDay) {
        days = {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: false,
          sunday: false,
        };
      } else {
        days = {
          monday: true,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        };
      }
      break;
    case 'MONTHLY':
      days = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      };
      break;
  }

  return {
    description: getValue(fileObj, fieldName.description) as string,
    comment: getValue(fileObj, fieldName.comment) as string,
    prestation: getValue(fileObj, fieldName.prestation) as string,
    workers: (getValue(fileObj, fieldName.workers) as string)
      .split(',')
      .map((e) => e.trim()),
    beneficiaries: (getValue(fileObj, fieldName.beneficiaries) as string)
      .split(',')
      .map((e) => e.trim()),
    etablishmentId: etablishmentId,
    location: getValue(fileObj, fieldName.location) as string,
    dateStart: dateStart,
    dateEnd: dateEnd,
    timeStart: getNormalTime(timeStartWithTimezone.toISOString()),
    timeEnd: getNormalTime(timeEndWithTimezone.toISOString()),
    type: periodicity,
    repetition: getValue(fileObj, fieldName.repetition) as number,
    dateTimeStartTimestamp: dateTimeStartTimestamp,
    dateTimeEndTimestamp: dateTimeEndTimestamp,
    status: getValue(fileObj, fieldName.status) as string,
    dateEndType: DateEndType.END_ON,
    ...days,
  };
};

export const convertToInterventions = (
  fileObjs: any[],
  fieldName: InterventionFieldName,
  etablishmentId: number,
): InterventionDataType[] =>
  fileObjs.map((fileObj) =>
    convertToIntervention(fileObj, fieldName, etablishmentId),
  );

export const getInterventionsFromFile = async (
  file: File,
  etablishmentId: number,
): Promise<InterventionDataType[]> => {
  const excelData = await convertExcelToJSON(file);
  return convertToInterventions(excelData, fieldName, etablishmentId);
};

export const getInterventionFromFiles = async (
  fileList: FileList,
  etablishmentId: number,
): Promise<InterventionDataType[]> => {
  const interventionsPromises: Promise<InterventionDataType[]>[] = [];
  for (let i = 0; !!fileList[i]; i++) {
    interventionsPromises.push(
      getInterventionsFromFile(fileList[i], etablishmentId),
    );
  }
  return (await Promise.all(interventionsPromises)).flat();
};
