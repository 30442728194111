import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			width: 340,
			height: 730,
			// height: 'auto',
			background: '#fff',
			border: '1px solid #9E9E9E',
			borderRadius: 16,
			padding: theme.spacing(2),
			margin: theme.spacing(0, 1, 2),
			boxSizing: 'border-box',
		},
		title: {
			fontSize: 16,
			fontWeight: 600,
			textAlign: 'center',
			color: '#002B67',
			padding: theme.spacing(2),
		},
	}),
);

export default useStyles;
