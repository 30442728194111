import React, { FC, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  useQuery,
  useMutation,
  useApolloClient,
  useLazyQuery,
} from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Avatar from '@material-ui/core/Avatar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { ValueType } from 'react-select';

import {
  UPDATE_ESTABLISHMENT,
  DELETE_ESTABLISHMENT,
  DELETE_ESTABLISHMENTS,
  UPDATE_ESTABLISHMENT_STATUS,
} from '../../graphql/etablishment/mutation';
import {
  GET_ETABLISHMENTS,
  GET_ETABLISHMENT,
} from '../../graphql/etablishment/query';
import {
  Etablishments,
  EtablishmentsVariables,
} from '../../graphql/etablishment/types/Etablishments';
import {
  Etablishment,
  EtablishmentVariables,
} from '../../graphql/etablishment/types/Etablishment';
import {
  UpdateEstablishment,
  UpdateEstablishmentVariables,
} from '../../graphql/etablishment/types/UpdateEstablishment';
import useStyles from './styles';
import SearchInput from '../Common/SearchInput';
import SearchFilter from '../Common/SearchFilter';
import CustomTable from '../Common/CustomTable';
import { SEARCH_FILTERS_MOCK } from './mock';
import { HeadCell, GroupAction } from '../../types/table';
import ExcelIcon from '../../assets/images/excel.png';
import { SnackbarType } from '../../types/snackbar';
import { displaySnackbar } from '../../common/snackbar';
import { ApolloError } from 'apollo-boost';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import {
  DeleteEstablishment,
  DeleteEstablishmentVariables,
} from '../../graphql/etablishment/types/DeleteEstablishment';
import {
  UpdateEstablishmentStatus,
  UpdateEstablishmentStatusVariables,
} from '../../graphql/etablishment/types/UpdateEstablishmentStatus';
import {
  DeleteEstablishments,
  DeleteEstablishmentsVariables,
} from '../../graphql/etablishment/types/DeleteEstablishments';
import { exportData, formatEstablishmentData } from '../../common/excel';
import { checkActionGroup } from '../../common/validator';
import { ESTABLISHMENT_STATUS } from '../../types/establishment';

interface EstablishmentProps {}

interface EstablishmentFilter {
  type: string;
  enabled: number;
  postalCode: string;
  city: string;
}

const INIT_FILTER: EstablishmentFilter = {
  city: '',
  postalCode: '',
  enabled: -1,
  type: 'RESIDENCE_PARENT',
};

const ACTIONS: GroupAction[] = [
  { label: 'Exporter en Excel', value: 'export' },
  { label: 'Activer', value: 'enable' },
  { label: 'Désactiver', value: 'disable' },
  { label: 'Supprimer', value: 'remove' },
];

const normalizeFilter = (filters: EstablishmentFilter): any => {
  return {
    postalCode: filters.postalCode,
    enabled: filters.enabled,
    city: filters.city,
    etablishmentType: filters.type === 'ALL' ? '' : filters.type,
  };
};

/**
 * Establishment Component
 * @param props EstablishmentProps
 */
const Establishment: FC<EstablishmentProps & RouteComponentProps> = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [filter, setFilter] = useState<EstablishmentFilter>(INIT_FILTER);
  const [searchKey, setSearchKey] = useState<string>('');
  const [etablishments, setEtablishments] = useState<any>([]);
  const client = useApolloClient();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [establishmentId, setEstablishmentId] = useState<number>(0);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const filters = JSON.stringify({
    ...normalizeFilter(filter),
    name: searchKey,
  });
  const variables = filters ? { input: { filter: filters } } : {};

  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const { data, loading } = useQuery<Etablishments, EtablishmentsVariables>(
    GET_ETABLISHMENTS,
    { variables },
  );

  const [getEstablishmentInfo] = useLazyQuery<
    Etablishment,
    EtablishmentVariables
  >(GET_ETABLISHMENT, {
    onCompleted: (data: Etablishment) => {
      if (data && data.etablishment) {
        exportData({
          csvData: [formatEstablishmentData(data.etablishment)],
          fileName: 'Etablissement',
          sheetName: 'Etablissement',
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la récupération des informations de l'établissement";
      if (error.networkError) {
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [getSelectedEstablishmentInfo] = useLazyQuery<
    Etablishments,
    EtablishmentsVariables
  >(GET_ETABLISHMENTS, {
    onCompleted: (data: Etablishments) => {
      if (data) {
        exportData({
          csvData: data.etablishments.map((item: any) =>
            formatEstablishmentData(item),
          ),
          fileName: 'Etablissements',
          sheetName: 'Etablissements',
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la récupération des informations de l'établissement";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [updateEtablishment] = useMutation<
    UpdateEstablishment,
    UpdateEstablishmentVariables
  >(UPDATE_ESTABLISHMENT, {
    onCompleted: (data: UpdateEstablishment) => {
      if (data && data.updateEtablishment) {
        const statut =
          data.updateEtablishment.enabled === true ? 'activé' : 'désactivé';
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `L'établissement a été ${statut}`;
        displaySnackbar(client, snackbarData);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour de l'établissement";

      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [updateEstablishmentStatus] = useMutation<
    UpdateEstablishmentStatus,
    UpdateEstablishmentStatusVariables
  >(UPDATE_ESTABLISHMENT_STATUS, {
    onCompleted: (data: UpdateEstablishmentStatus) => {
      if (data && data.updateEtablishmentStatus) {
        const statut =
          data.updateEtablishmentStatus &&
          data.updateEtablishmentStatus[0] &&
          data.updateEtablishmentStatus[0].enabled === true
            ? 'activé'
            : 'désactivé';
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Les établissements ont été ${statut}s`;
        displaySnackbar(client, snackbarData);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour des établissements";

      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const columns: HeadCell[] = [
    {
      name: 'logo',
      label: 'Logo',
      disablePadding: false,
      renderer: (value: any) => {
        return (
          <Avatar
            alt="logo"
            variant="rounded"
            className="contain-avatar"
            src={value && value.logo}
          />
        );
      },
    },
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.name) || '-';
      },
    },
    {
      name: 'etablishmentType',
      label: 'Type',
      disablePadding: false,
      renderer: (value: any) => {
        const type =
          value && value.etablishmentType
            ? value.etablishmentType.name === 'RESIDENCE_PARENT'
              ? 'Organisation'
              : value.etablishmentType.name === 'RESIDENCE_CHILD'
              ? 'Résidence fille'
              : value.etablishmentType.name === 'RESIDENCE'
              ? 'Résidence'
              : value.etablishmentType.name === 'DOMICILE'
              ? 'Domicile'
              : '-'
            : '-';
        return type;
      },
    },
    {
      name: 'rcs',
      label: 'Siren/Siret',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.rcs) || '-';
      },
    },
    {
      name: 'etablishmentStatus',
      label: "Statut d'organisation",
      disablePadding: false,
      renderer: (value: any) => {
        const status =
          value.etablishmentStatus &&
          ESTABLISHMENT_STATUS[value.etablishmentStatus.name];
        return status;
      },
    },
    {
      name: 'address',
      label: 'Adresse',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.address) || '-';
      },
    },
    {
      name: 'postalCode',
      label: 'Code postal',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.postalCode) || '-';
      },
    },
    {
      name: 'city',
      label: 'Ville',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.city) || '-';
      },
    },
    {
      name: 'administrators',
      label: 'NB Admin',
      numeric: true,
      disablePadding: true,
      renderer: (value: any) => {
        return (
          (value && value.administrators && value.administrators.length) || 0
        );
      },
    },
    {
      name: 'beneficiaries',
      label: 'NB Bénéficiaires',
      numeric: true,
      disablePadding: true,
      renderer: (value: any) => {
        return (
          (value && value.beneficiaries && value.beneficiaries.length) || 0
        );
      },
    },
    {
      name: 'workers',
      label: 'NB Intervenants',
      numeric: true,
      disablePadding: true,
      renderer: (value: any) => {
        return (value && value.workers && value.workers.length) || 0;
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => (
        <FormControlLabel
          control={
            <Switch
              checked={value.enabled}
              onChange={(event) => {
                onHandleChangeStatus(event, value.id);
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              name="enabled"
              color="primary"
            />
          }
          label={value.enabled ? 'Activé' : 'Désactivé'}
        />
      ),
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex">
          <Tooltip title="Exporter en Excel" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleExport(value.id);
              }}
              className={classes.margin}
            >
              <img
                alt="excel-icon"
                src={ExcelIcon}
                className={classes.excelIcon}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleDelete(value.id);
              }}
              className={classes.margin}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifier" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleEdit(value.id);
              }}
              className={classes.margin}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (data && data.etablishments) {
      setEtablishments(data.etablishments);
    }
  }, [data]);

  const onHandleSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const onHandleChangeStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    selectedId: number,
  ) => {
    const { checked } = event.target;
    if (selectedId) {
      updateEtablishment({
        variables: {
          input: {
            id: selectedId,
            residenceIds: [],
            enabled: checked,
          },
        },
      });
    }
  };

  const onHandleExport = (id: number) => {
    getEstablishmentInfo({ variables: { id } });
  };

  const onHandleDelete = (id: number) => {
    setEstablishmentId(id);
    setOpenConfirmDialog(true);
  };

  const [deleteEstablishment, { loading: loadingDeleteEstablishment }] =
    useMutation<DeleteEstablishment, DeleteEstablishmentVariables>(
      DELETE_ESTABLISHMENT,
      {
        onCompleted: (data: DeleteEstablishment) => {
          if (data && data.deleteEtablishment) {
            snackbarData.type = 'SUCCESS';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = `L'établissement a été supprimé`;
            displaySnackbar(client, snackbarData);
            setOpenConfirmDialog(false);
          }
        },
        onError: (error: ApolloError) => {
          snackbarData.type = 'ERROR';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            "Une erreur s'est produite lors de la suppression d'établissement";
          if (error.networkError) {
            snackbarData.type = 'ERROR';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message =
              'Erreur de réseau. Veuillez vérifier votre connexion internet';
          }
          displaySnackbar(client, snackbarData);
        },
      },
    );

  const [deleteEstablishments, { loading: loadingDeleteEstablishments }] =
    useMutation<DeleteEstablishments, DeleteEstablishmentsVariables>(
      DELETE_ESTABLISHMENTS,
      {
        onCompleted: (data: DeleteEstablishments) => {
          if (data && data.deleteEtablishments) {
            snackbarData.type = 'SUCCESS';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = `Les établissements ont été supprimés`;
            displaySnackbar(client, snackbarData);
            setOpenConfirmDialog(false);
          }
        },
        onError: (error: ApolloError) => {
          snackbarData.type = 'ERROR';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            "Une erreur s'est produite lors de la suppression des établissements";
          if (error.networkError) {
            snackbarData.type = 'ERROR';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message =
              'Erreur de réseau. Veuillez vérifier votre connexion internet';
          }
          displaySnackbar(client, snackbarData);
        },
      },
    );

  const onDeleteEstablishment = () => {
    if (establishmentId && establishmentId > 0) {
      deleteEstablishment({
        variables: {
          id: establishmentId,
        },
        update: (cache, { data }) => {
          const deletedId =
            data && data.deleteEtablishment && data.deleteEtablishment.id;

          const establishmentData = cache.readQuery<Etablishments>({
            query: GET_ETABLISHMENTS,
            variables,
          });

          if (deletedId && establishmentData) {
            const newData = establishmentData.etablishments.filter(
              (establishment) =>
                establishment && establishment.id !== deletedId,
            );
            cache.writeQuery({
              query: GET_ETABLISHMENTS,
              variables,
              data: {
                etablishments: newData,
              },
            });
          }
        },
      });
    } else {
      snackbarData.type = 'WARNING';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'ID établissement non trouvé.';
      displaySnackbar(client, snackbarData);
      setOpenConfirmDialog(false);
    }
  };

  const onHandleEdit = (id: number) => {
    history.push(`/etablissements/modifier/${id}`);
  };

  const onHandleAddButton = () => {
    history.push('/etablissements/ajout');
  };

  const exportEstablishmentInfos = (selectedItems: number[]): void => {
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les établissements à exporter`,
      )
    ) {
      const filters = JSON.stringify({ ids: selectedItems });
      const order = JSON.stringify({ id: 'asc' });
      const variables = { input: { filter: filters, orderBy: order } };
      getSelectedEstablishmentInfo({ variables });
    }
  };

  const changeEstablishmentStatus = (
    selectedItems: number[],
    enabled: boolean,
  ): void => {
    const status = enabled ? 'activer' : 'désactiver';
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les établissements à ${status}`,
      )
    ) {
      updateEstablishmentStatus({
        variables: { ids: selectedItems, status: enabled },
      });
    }
  };

  const removeEstablishments = (selectedItems: number[]): void => {
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les établissements à supprimer`,
      )
    ) {
      deleteEstablishments({
        variables: { ids: selectedItems },
        update: (cache, { data }) => {
          const deletedIds = ((data && data.deleteEtablishments) || []).map(
            (item) => item && item.id,
          );

          const etabData = cache.readQuery<Etablishments>({
            query: GET_ETABLISHMENTS,
            variables,
          });

          if (deletedIds && etabData && etabData.etablishments) {
            const newData = etabData.etablishments.filter(
              (item) => item && deletedIds.indexOf(item.id) === -1,
            );
            cache.writeQuery({
              query: GET_ETABLISHMENTS,
              variables,
              data: {
                etablishments: newData,
              },
            });
          }
        },
      });
    }
  };

  const onHandleAction = (action: ValueType<GroupAction>): void => {
    const actionGroup = action as GroupAction;

    if (actionGroup && actionGroup.value) {
      switch (actionGroup.value) {
        case 'export':
          exportEstablishmentInfos(selectedItems);
          break;
        case 'enable':
          changeEstablishmentStatus(selectedItems, true);
          break;
        case 'disable':
          changeEstablishmentStatus(selectedItems, false);
          break;
        case 'remove':
          removeEstablishments(selectedItems);
          break;
      }
    }
  };

  const handleClickRow = (id: number) => {
    history.push({
      pathname: `/etablissements/details/${id}`,
      search: `?tab=0`,
    });
    // window.location.reload();
  };

  const toolbarButtons = [
    <Button
      className={classes.button}
      variant="contained"
      color="secondary"
      startIcon={<AddBoxIcon />}
      onClick={onHandleAddButton}
    >
      Ajouter un établissement
    </Button>,
  ];

  return (
    <Box className={classes.root}>
      <Row>
        <Col xs={12} xl={4} lg={12}>
          <Paper className={classes.paper}>
            <SearchInput
              onChange={onHandleSearchKey}
              value={searchKey}
              placeholder="Recherchez un établissement"
            />
          </Paper>
        </Col>
        <Col xs={12} xl={8} lg={12}>
          <Paper className={classes.paper}>
            <Typography className={classes.labelFilter}>
              Filtre de recherche :
            </Typography>
            <SearchFilter
              filterControls={SEARCH_FILTERS_MOCK}
              filter={filter}
              setFilter={setFilter}
            />
          </Paper>
        </Col>
      </Row>
      <Box>
        <CustomTable
          selectable
          toolbar
          toolbarProps={{
            buttonText: 'Ajouter un établissement',
            toolbarButton: true,
            toolbarAction: true,
            toolbarTitle: 'Liste des établissements',
            setActionButton: onHandleAddButton,
          }}
          loadingData={loading}
          data={etablishments}
          columns={columns}
          toolbarActions={ACTIONS}
          isLeftContent={true}
          onHandleAction={onHandleAction}
          toolbarButtons={toolbarButtons}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          handleClickRow={handleClickRow}
        />
      </Box>
      <ConfirmationDialog
        title="Suppression"
        message="Etes-vous sûr de vouloir supprimer cet établissement?"
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteEstablishment}
        loadingConfirmation={loadingDeleteEstablishment}
      />
    </Box>
  );
};

export default withRouter(Establishment);
