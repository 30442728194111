import { FC } from 'react';
import { EstablishmentEditorState } from '../components/Establishment/EstablishmentAdd/EstablishmentEdit/components/EstablishmentEditor/establishmentEditor.reducer';
import { Administrator_administrator } from '../graphql/administrator/types/Administrator';
import { Beneficiary_beneficiary } from '../graphql/beneficiary/types/Beneficiary';
import { Worker_worker } from '../graphql/worker/types/Worker';

export type EstablishmentType =
  | 'RESIDENCE_ROOT'
  | 'RESIDENCE_CHILD'
  | 'RESIDENCE'
  | 'HOME'
  | 'DOMICILE'
  | 'RESIDENCE_PARENT';

export interface ChoiceType {
  id: EstablishmentType;
  title: string;
  imgSource: string;
  type?: string;
  onClick?: (key: string) => void;
}

export interface EtablishmentDataType {
  __typename: string;
  id: number;
  name: string;
  rcs: string;
  address: string;
  postalCode: string;
  city: string;
  logo: string;
  enabled: boolean;
  picture: any[];
  base64logo: string;
  etablishmentObject: string;
  effective: number;
  prestations: string[];
  etablishmentTypeId: number;
  etablishmentStatusId: number;
  administratorIds: number[];
  workerIds: number[];
  beneficiaryIds: number[];
  residenceIds: number[];
  workers: Worker_worker[];
  administrators: Administrator_administrator[];
  beneficiaries: Beneficiary_beneficiary[];
  status?: string;
  establishmentType?: EstablishmentType;
}

export interface EstablishmentDataType extends EtablishmentDataType {}

export interface EtablishmentRootDataType {
  __typename: string;
  id: number;
  logo: string;
  name: string;
  address: string;
  postalCode: string;
  etablishmentObject: string;
  city: string;
}

export interface ActionsAbstraction<DataType> {
  goNext: () => void;
  goBack: () => void;
  updateData: (data: DataType) => void;
}

export interface EstablishmentNewEditStep {
  component: FC<{
    actions: ActionsAbstraction<EtablishmentDataType>;
    state: EstablishmentEditorState;
  }>;
  title: string;
  stepDatasValidator: (data: EtablishmentDataType) => boolean;
}

export interface EstablishmentEditStep {
  component: FC<StepsInput>;
  title: string;
  nextButtonEnabler: (input: StepsInput) => boolean;
}

export interface StepsInput {
  inputData: EtablishmentDataType;
  setInputData: React.Dispatch<React.SetStateAction<EtablishmentDataType>>;
  state: any;
}

export const ESTABLISHMENT_TYPE: Record<string, string> = {
  DOMICILE: 'Domicile',
  HOME: 'Domicile',
  RESIDENCE: 'Résidence',
  RESIDENCE_CHILD: 'Résidence fille',
  RESIDENCE_PARENT: 'Organisation',
  RESIDENCE_ROOT: 'Organisation',
};

export const ESTABLISHMENT_STATUS: Record<string, string> = {
  ENTERPRISE: 'Entreprise',
  ASSOCIATION: 'Association',
  COLLECTIVITY: 'Collectivité',
  HOSPITAL: 'Hôpital',
};

export type EstablishmentTypeType =
  | 'RESIDENCE_PARENT'
  | 'RESIDENCE_CHILD'
  | 'RESIDENCE'
  | 'DOMICILE';

export type EstablishmentStatusType =
  | 'ENTREPRISE'
  | 'ASSOCIATION'
  | 'COLLECTIVITY'
  | 'HOSPITAL';
