import React, { FC } from 'react';
import Box from '@material-ui/core/Box';

import useStyles from './styles';

interface HeaderProps {}

const Header: FC<HeaderProps> = (props) => {
  const { children } = props;
  const classes = useStyles();

  return <Box className={classes.root}>{children}</Box>;
};

export default Header;
