import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'left',
    },
    inline: {
      display: 'flex',
      flexDirection: 'row',
    },
    mb: {
      marginBottom: theme.spacing(1),
    },
    title: {
      color: '#9E9E9E',
      fontSize: '0.975em',
      paddingRight: 6,
    },
    content: {
      color: '#000',
      fontSize: '0.975em',
    },
  }),
);

export default useStyles;
