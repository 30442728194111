import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/react-hooks';
import { GET_PLANNING_DATA } from '../../../../../graphql/common/planning/query';
import { HeadCell } from '../../../../../types/table';
import CustomTable from '../../../CustomTable';
import { SnackbarType } from '../../../../../types';
import { displaySnackbar } from '../../../../../common';
import {
  updateMembersPlanning,
  updateMembersPlanningVariables,
} from '../../../../../graphql/planning/types/updateMembersPlanning';
import {
  updatePlanningActionEnum,
  updatePlanningMembersEnum,
} from '../../../../../graphql/types/graphql-global-types';
import { UPDATE_PLANNING_MEMBERS } from '../../../../../graphql/planning/mutation';
import {
  BenefsNotInPlanning,
  BenefsNotInPlanningVariables,
} from '../../../../../graphql/planning/types/BenefsNotInPlanning';
import {
  GET_BENEFICIAIRES_NOT_IN_PLANNING,
  GET_WORKERS_NOT_IN_PLANNING,
} from '../../../../../graphql/planning/query';
import {
  WorkersNotInPlanning,
  WorkersNotInPlanningVariables,
} from '../../../../../graphql/planning/types/WorkersNotInPlanning';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  idPlanning: number[];
  onFinish: () => void;
}

const WorkerListUpdate = (props: Props) => {
  const { open, setOpen, idPlanning, onFinish } = props;
  const client = useApolloClient();
  const classes = useStyles();
  const [selectedItem, setSelectedItems] = useState<number[]>([]);
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { data, loading } = useQuery<
    WorkersNotInPlanning,
    WorkersNotInPlanningVariables
  >(GET_WORKERS_NOT_IN_PLANNING, {
    variables: {
      Idplanning: idPlanning,
    },
    onError: (error) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = error.message;
      displaySnackbar(client, snackbarData);
    },
  });
  const [addWorker, { data: addData, loading: addLoading }] = useMutation<
    updateMembersPlanning,
    updateMembersPlanningVariables
  >(UPDATE_PLANNING_MEMBERS, {
    onCompleted: (response) => {
      if (response.updateMembersPlanning.success) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          response?.updateMembersPlanning.message ??
          'La mise à jour a été effectuée';
        displaySnackbar(client, snackbarData);
        onFinish();
      } else {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = response?.updateMembersPlanning.message ?? '';
        displaySnackbar(client, snackbarData);
      }
    },
    onError: (error) => {
      snackbarData.message = 'Une erreur est survenue';
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      displaySnackbar(client, snackbarData);
    },
  });
  const handleSubmitSelection = () => {
    if (selectedItem.length === 0) {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'Vous devez au moins choisir un intervenant';
      displaySnackbar(client, snackbarData);
    } else {
      const variables_add: updateMembersPlanningVariables = {
        input: {
          idmembers: selectedItem,
          action: updatePlanningActionEnum.ADD,
          members: updatePlanningMembersEnum.WORKERS,
          idPlanning: idPlanning,
        },
      };
      addWorker({ variables: variables_add });
    }
  };
  const columns: HeadCell[] = [
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.firstName) || '-';
      },
    },
    {
      name: 'lastName',
      label: 'Prénom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.lastName) || '-';
      },
    },
    {
      name: 'disabilityRecognition',
      label: "Reconnaissance d'handicap",
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.disabilityRecognition ? 'Oui' : 'Non') || '-';
      },
    },
    {
      name: 'mail',
      label: 'Mail',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.email) || '-';
      },
    },
    {
      name: 'mobilePhone',
      label: 'Tél mobile',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.mobilePhone) || '-';
      },
    },
    {
      name: 'phone',
      label: 'Tél fixe',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.phone) || '-';
      },
    },
    {
      name: 'guardianEnabled',
      label: 'Sous tutelle',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.guardianEnabled ? 'Oui' : 'Non') || '-';
      },
    },
    {
      name: 'guardianName',
      label: 'Nom tuteur',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.guardianName) || '-';
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => {
        const statut =
          value.user && value.user.enabled ? 'Activé' : 'Desactivé';
        return statut;
      },
    },
  ];
  const toolbarActionContent = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="submit-selection"
      onClick={handleSubmitSelection}
    >
      Valider ma sélection
    </Button>,
  ];

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Sélection de l'intervenant
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.tableContainer}>
        <CustomTable
          toolbar
          selectable
          hideSelectedText
          selectedItems={selectedItem}
          setSelectedItems={setSelectedItems}
          maxWidth={1640}
          data={(data?.workersNotInPlanning as any[]) || []}
          columns={columns}
          loadingData={loading || addLoading}
          toolbarProps={{ toolbarTitle: 'Liste des intervenants' }}
          toolbarActionContent={toolbarActionContent}
          toolbarStyles={{ width: '165px' }}
          useFlexToolbar
          isLeftContent={true}
          toolbarClasses={classes.flowRowBenefList}
        />
      </div>
    </Dialog>
  );
};

export default WorkerListUpdate;
