import React, { FC, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import useStyles from './styles';
import { EtablishmentDataType } from '../../../../../types/establishment';
import { HeadCell } from '../../../../../types/table';
import CustomTable from '../../../../Common/CustomTable';
import RootChoice from '../RootChoice';
import { GET_ETABLISHMENT } from '../../../../../graphql/etablishment/query';

interface EstablishmentRootListProps {
  setInputData: (data: EtablishmentDataType) => void;
  inputData: EtablishmentDataType;
}

const getResidenceIds = (data: EtablishmentDataType | undefined): number[] =>
  (data && data.residenceIds) || [];

const EstablishmentRootList: FC<
  EstablishmentRootListProps & RouteComponentProps
> = (props) => {
  const { setInputData, history, inputData } = props;
  const classes = useStyles();
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [rootId, setRootId] = useState<number>(getResidenceIds(inputData)[0]);
  const [root, setRoot] = useState<EtablishmentDataType[]>([]);

  const {
    data: rootData,
    loading: rootLoading,
    refetch: refetchRoot,
  } = useQuery(GET_ETABLISHMENT, {
    variables: { id: rootId },
  });

  useEffect(() => {
    if (!rootLoading && rootData) {
      const { etablishment } = rootData;
      setRoot([etablishment]);
    }
  }, [rootData]);

  useEffect(() => {
    if (location.hash.replace('#', '') === 'choix') {
      setOpenDialog(true);
    }
  }, [location]);

  const columns: HeadCell[] = [
    {
      name: 'logo',
      label: 'Logo',
      disablePadding: false,
      renderer: (value: any) => {
        return <Avatar alt="logo" variant="rounded" src="" />;
      },
    },
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return value.name || '-';
      },
    },
    {
      name: 'address',
      label: 'Adresse',
      disablePadding: false,
      renderer: (value: any) => {
        return value.address || '-';
      },
    },
    {
      name: 'postalCode',
      label: 'Code postal',
      disablePadding: false,
      renderer: (value: any) => {
        return value.postalCode || '-';
      },
    },
    {
      name: 'City',
      label: 'Ville',
      disablePadding: false,
      renderer: (value: any) => {
        return value.city || '-';
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex">
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveRoot();
              }}
              className={classes.margin}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleRemoveRoot = () => {
    setRoot([]);
    setInputData({
      ...inputData,
      residenceIds: [],
    });
  };

  const isSetResidence = () => {
    return root.length > 0;
  };

  const handleSelectParent = () => {
    history.push(`${location.pathname}${location.search}#choix`);
  };

  const onSelectRoot = (id: number) => {
    setRootId(id);
    setInputData({
      ...inputData,
      residenceIds: [id],
    });
    refetchRoot({ id });
  };

  const adminToolbarButtons = [
    <Button
      className={`${classes.white}`}
      variant="contained"
      color="secondary"
      name="admin-add"
      disabled={isSetResidence()}
      startIcon={<AddBoxIcon />}
      onClick={handleSelectParent}
    >
      Sélectionner une organisation
    </Button>,
  ];

  return (
    <>
      <CustomTable
        toolbar
        selectable={false}
        maxWidth={1080}
        data={root}
        columns={columns}
        toolbarButtons={adminToolbarButtons}
        noFooter
        noLoading
      />
      <RootChoice
        open={openDialog}
        setOpen={setOpenDialog}
        onSelectRoot={onSelectRoot}
      />
    </>
  );
};

export default withRouter(EstablishmentRootList);
