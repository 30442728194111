import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useLocation } from 'react-router-dom';

import useStyles from './styles';
import { ToolbarProps } from '../../../../types/table';

interface FlexTableToolbarProps extends ToolbarProps {
  numSelected: number;
  rowCount: number;
  toolbarStyles?: any;
  hideSelectedText?: boolean;
  toolbarActionContent?: any;
  toolbarClasses?: any;
}

interface ClassName extends Record<string, any> {}

/**
 * FlexTableToolbar
 * @param props FlexTableToolbarProps
 */
const FlexTableToolbar = (props: FlexTableToolbarProps) => {
  const { numSelected, rowCount, toolbarTitle, toolbarClasses } = props;
  const { toolbarButtons, toolbarActionContent, hideSelectedText } = props;
  const classes = useStyles();
  const { pathname } = useLocation();
  const etabDetailRoute = pathname.includes('etablissements/details');

  return (
    <Toolbar
      className={
        toolbarClasses
          ? toolbarClasses
          : etabDetailRoute
          ? classes.rootDetail
          : classes.root
      }
    >
      <Box
        className={
          etabDetailRoute
            ? classes.actionTableToolbarDetail
            : classes.actionTableToolbar
        }
      >
        {toolbarTitle && (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {toolbarTitle}
          </Typography>
        )}
        {toolbarButtons &&
          toolbarButtons.map((button: any, index: number) => (
            <span
              key={index}
              className={
                etabDetailRoute ? classes.fieldsDetail : classes.fields
              }
            >
              {button}
            </span>
          ))}
      </Box>
      <Box
        className={
          etabDetailRoute
            ? classes.actionTableContentDetail
            : classes.actionTableContent
        }
      >
        {!hideSelectedText && (
          <Typography
            className={classes.subTitle}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected}/{rowCount} sélectionnés ({rowCount})
          </Typography>
        )}
        {toolbarActionContent &&
          toolbarActionContent.map((content: any, index: number) => (
            <span key={index}>{content}</span>
          ))}
      </Box>
    </Toolbar>
  );
};

export default FlexTableToolbar;
