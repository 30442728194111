import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .row': {
        '& .col-xl-3, .col-lg-4, .col-md-6, .col-sm-6, .col-12': {
          paddingLeft: 0,
        },
        [theme.breakpoints.down(1199)]: {
          '& .col-12': {
            paddingTop: 16,
          },
        },
      },
    },
    paper: {
      boxShadow: '0px 10px 25px #00000017',
      borderRadius: 16,
      padding: theme.spacing(2),
    },
    button: {
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    white: {
      color: theme.palette.common.white,
    },
    excelIcon: {
      width: 24,
      height: 24,
    },
    margin: {
      marginLeft: 8,
      marginRight: 8,
    },
    detailsTitle: {
      fontSize: 22,
      fontWeight: 500,
      marginBottom: theme.spacing(3),
    },
    detailsContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(4),

      [theme.breakpoints.down(960)]: {
        flexDirection: 'column',
      },
    },
    headerNav: {
      marginBottom: theme.spacing(1),
    },
    leftContent: {
      width: '100%',
      minHeight: 150,

      [theme.breakpoints.down(960)]: {
        width: '100%',
        marginBottom: theme.spacing(2),
      },
    },
    rightContent: {
      width: '25%',
      height: 'auto',
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      [theme.breakpoints.down(960)]: {
        width: '100%',
      },
    },
  }),
);

export default useStyles;
