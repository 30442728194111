import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

interface HeadProps {
  title: string;
  description?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 150,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      [theme.breakpoints.down(416)]: {
        padding: '16px 0',
        minHeight: 100,
      },
    },
    center: {
      textAlign: 'center',
    },
    title: {
      fontSize: '1.6em',
      fontWeight: 600,
      lineHeight: 'calc(1.6em * 1.4)',
      [theme.breakpoints.down(416)]: {
        lineHeight: 'calc(1.1em * 1.4)',
      },
    },
    description: {},
  }),
);

const Header: FC<HeadProps> = (props) => {
  const { title, description, children } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <div className={classes.center}>
        <Typography
          component="h2"
          color="textPrimary"
          className={classes.title}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            component="p"
            color="textSecondary"
            className={classes.description}
          >
            {description}
          </Typography>
        )}
      </div>
      {children}
    </Box>
  );
};

export default Header;
