import { HeadCell } from '../../../types/table';
import { InterventionReportListColumn } from '../utils/table-columns.utils';

export const INTERVENTION_REPORT_LIST_DEFAULT_COLUMNS: InterventionReportListColumn[] =
  [
    'date',
    'title',
    'duration',
    'worker',
    'beneficiary',
    'prestation',
    'status',
    'establishment',
    'location',
    'signature',
    'actions',
  ];

export const COLUMNS_RECORD: Record<InterventionReportListColumn, HeadCell> = {
  date: {
    name: 'date',
    label: 'Date',
    disablePadding: false,
  },
  title: {
    name: 'name',
    label: 'Titre',
    disablePadding: false,
  },
  duration: {
    name: 'duration',
    label: 'Durée',
    disablePadding: false,
    minWidth: 160,
  },
  worker: {
    name: 'worker',
    label: 'Intervenant',
    disablePadding: false,
  },
  beneficiary: {
    name: 'beneficiary',
    label: 'Bénéficiaire',
    disablePadding: false,
  },
  prestation: {
    name: 'prestation',
    label: 'Nature de la prestation',
    minWidth: 160,
    disablePadding: false,
  },
  status: {
    name: 'status',
    label: 'Statut',
    disablePadding: false,
  },
  establishment: {
    name: 'establishment',
    label: 'Etablissement',
    disablePadding: true,
  },
  location: {
    name: 'location',
    label: `Lieu d'intervention`,
    disablePadding: true,
  },
  signature: {
    name: 'signature',
    label: 'Signature',
    disablePadding: true,
  },
  actions: {
    name: 'actions',
    label: '',
    disablePadding: true,
  },
};
