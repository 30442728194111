import React, { FC, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { InterventionFilter } from '../../../../../InterventionReport/interfaces/intervention-report.interfaces';
import { INITIAL_FILTERS } from '../../../../../InterventionReport/constants/intervention-report.constants';
import { useGetInterventionReports } from '../../../../../InterventionReport/hooks/intervention-report-list.hooks';
import {
  selectFilterOptions,
  toQueryFilter,
} from '../../../../../InterventionReport/utils/intervention-report-list-filter.utils';
import FilterControls from '../../../../../InterventionReport/components/FilterControls';
import InterventionReportList from '../../../../../InterventionReport/InterventionReportList';
import { InterventionReportListColumn } from '../../../../../InterventionReport/utils/table-columns.utils';
import {
  InterventionReportData,
  UserMin,
} from '../../../../../../types/intervention-report.types';
import { FilterTypeEnum } from '../../../../../../graphql/types/graphql-global-types';
import { GET_DATA_FILTER } from '../../../../../../graphql/filter/query';
import { InterventionReportFilterRecord } from '../../../../../../types';

interface InterventionReportsProps {
  establishmentId: number;
}

const COLUMNS: InterventionReportListColumn[] = [
  'date',
  'title',
  'duration',
  'worker',
  'beneficiary',
  'prestation',
  'status',
  `location`,
  'signature',
  'actions',
];

const InterventionReports: FC<InterventionReportsProps> = (props) => {
  const { establishmentId } = props;
  const classes = useStyles();

  const [filter, setFilter] = useState<InterventionFilter>(INITIAL_FILTERS);
  const { data, loading, refetch } = useGetInterventionReports({
    variables: {
      filter: {
        ...toQueryFilter(filter),
        establishmentIds: [establishmentId],
      },
    },
  });

  const {
    data: dataFilter,
    loading: loadingFilter,
    error,
  } = useQuery(GET_DATA_FILTER, {
    variables: {
      filterType: {
        filterType: [FilterTypeEnum.BENEFICIARY, FilterTypeEnum.WORKER],
        etablishmentId: establishmentId,
      },
    },
  });

  const [visible, setVisible] = useState(false);
  const [tabData, setTabData] = useState<InterventionReportData[]>([]);
  const [etablissementType, setEtablissementType] = useState<string>('');

  useEffect(() => {
    refetch();
  }, [filter]);

  useEffect(() => {
    if (
      data &&
      data[0] &&
      data[0].intervention &&
      data[0].intervention.beneficiaries &&
      !visible
    ) {
      setVisible(true);
      setEtablissementType(data[0].establishment.etablishmentType);
      let tabReport: InterventionReportData[] = [];
      // data.map((report, index) => {
      //   const tabTemp : InterventionReportData[] = [];
      //   const workers = report.intervention.workers;
      //   workers.map((worker: any) => {
      //     const beneficiaires = report.intervention.beneficiaries;
      //     beneficiaires.map((item: any) => {
      //       const elt: InterventionReportData = {
      //         beneficiary: {name: item.user.lastName + " " +item.user.firstName , id: item.id, phone: item.user.phone, email: item.user.email, mobilePhone: item.user.mobilePhone, userId: item.user.id},
      //         id: report.id,
      //         signature: report.signature,
      //         name: report.name,
      //         dateStart: report.dateStart,
      //         dateEnd: report.dateEnd,
      //         establishment: report.establishment,
      //         duration: report.duration,
      //         prestation: report.prestation,
      //         worker: {name: worker.user.lastName + " " +worker.user.firstName , id: worker.id, phone: worker.user.phone, email: worker.user.email, mobilePhone: worker.user.mobilePhone, userId: worker.user.id},
      //         planningId: report.planningId,
      //         intervention: report.intervention,
      //       }
      //       tabReport.push(elt);
      //     })
      //   })
      // })
      setTabData(tabReport);
    }
  }, [data]);
  const { beneficiary, worker, status, rapportTypes } = selectFilterOptions(
    dataFilter || [],
  );

  const toolbarFilters = [
    <FilterControls
      filter={filter}
      setFilter={setFilter}
      filterOptions={{ beneficiary, worker, status, rapportTypes }}
      establishmentIds={[establishmentId]}
      onLoading={loading}
    />,
  ];

  return (
    <Box className={classes.root}>
      <InterventionReportList
        filter={filter}
        data={data && data}
        loadingData={loading}
        toolbarFilters={toolbarFilters}
        refetch={refetch}
        columns={COLUMNS}
        setFilter={setFilter}
        establishmentId={establishmentId}
      />
    </Box>
  );
};

export default InterventionReports;
