import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

interface UserInfoProps {
  name: string;
  role: string;
}

const UserInfo: FC<UserInfoProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.name}>{props.name}</Typography>
      <Typography className={classes.role}>{props.role}</Typography>
    </Box>
  );
};

export default UserInfo;
