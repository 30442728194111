import React, { FC, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom';
import {
  useApolloClient,
  useQuery,
  useMutation,
  useLazyQuery,
} from '@apollo/react-hooks';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { ApolloError, MutationUpdaterFn } from 'apollo-boost';
import { ValueType } from 'react-select';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

import CustomTable from '../Common/CustomTable';
import UserFormDialog from '../Establishment/EstablishmentAdd/UserFormDialog';
import { SnackbarType, FilterControlType } from '../../types';
import { GroupAction, HeadCell } from '../../types/table';
import { GET_ETABLISHMENT_NAMES } from '../../graphql/etablishment/query';
import { EtablishmentNames } from '../../graphql/etablishment/types/EtablishmentNames';
import { FormInputDataState, User } from '../../types/user';
import SearchInput from '../Common/SearchInput/SearchInput';
import ExcelIcon from '../../assets/images/excel.png';
import SearchFilter from '../Common/SearchFilter/SearchFilter';
import { AdminFilter } from '../../types/filters';
import useStyles from './styles';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import { checkActionGroup } from '../../common/validator';
import { exportData, formatAdministratorData } from '../../common/excel';
import ExcelReader from '../Common/ExcelReader';
import { displaySnackbar } from '../../common/snackbar';
import {
  CreateAdmin,
  CreateAdminVariables,
} from '../../graphql/administrator/types/CreateAdmin';
import {
  Administrators,
  AdministratorsVariables,
} from '../../graphql/administrator/types/Administrators';
import {
  Administrator,
  AdministratorVariables,
} from '../../graphql/administrator/types/Administrator';
import {
  GET_ADMINISTRATORS,
  GET_ADMINISTRATOR,
} from '../../graphql/administrator/query';
import {
  CREATE_ADMIN,
  UPDATE_ADMIN,
  DELETE_ADMIN,
  UPDATE_ADMIN_STATUS,
  DELETE_ADMINS,
  CREATE_ADMINS,
} from '../../graphql/administrator/mutation';
import {
  UpdateAdminStatus,
  UpdateAdminStatusVariables,
} from '../../graphql/administrator/types/UpdateAdminStatus';
import {
  UpdateAdmin,
  UpdateAdminVariables,
} from '../../graphql/administrator/types/UpdateAdmin';
import {
  DeleteAdminVariables,
  DeleteAdmin,
} from '../../graphql/administrator/types/DeleteAdmin';
import {
  DeleteAdmins,
  DeleteAdminsVariables,
} from '../../graphql/administrator/types/DeleteAdmins';
import EstablishmentChoice from '../Establishment/EstablishmentChoice';
import { getUsersFromFiles } from '../../utils/excel-import.utils';
import { UserValidationState } from '../../types/user-validator.types';
import {
  validateUsers,
  getExcelImportUserErrorMessage,
  getImportExcelValidators,
} from '../../utils/user-validator.utils';
import { showSnackbar } from '../../utils/snackbar.util';
import {
  CreateAdmins,
  CreateAdminsVariables,
} from '../../graphql/administrator/types/CreateAdmins';
import {
  formInputDataStateToUser,
  getPartialUsers,
} from '../../utils/user.utils';
import { handleUserListValidation } from '../../utils/user-list.utils';
import { CreateAdministratorInput } from '../../graphql/types/graphql-global-types';

interface AdministratorProps {}

const ACTIONS: GroupAction[] = [
  { label: 'Exporter en Excel', value: 'export' },
  { label: 'Activer', value: 'enable' },
  { label: 'Désactiver', value: 'disable' },
  { label: 'Supprimer', value: 'remove' },
];

const INIT_FILTER: AdminFilter = {
  establishment: '',
};

const INIT_DATA = {
  name: '',
  lastName: '',
  email: '',
  mobilePhone: '',
  phone: '',
  userFunction: '',
  status: false,
  establishmentId: 0,
};

const normalizeFilter = (filters: AdminFilter): any => {
  return {
    establishmentName: filters.establishment || '',
  };
};

/**
 * Administrator Component
 * @param props AdministratorProps
 */
const EnhancedAdministrator: FC<AdministratorProps & RouteComponentProps> = (
  props,
) => {
  const classes = useStyles();
  const client = useApolloClient();
  const location = useLocation();
  const [openCreateUserDialog, setopenCreateUserDialog] =
    useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [data, setData] = useState<any[]>([]);
  const [adminId, setAdminId] = useState<number>(0);
  const [filter, setFilter] = useState<AdminFilter>(INIT_FILTER);
  const [options, setOptions] = useState<any[]>([]);
  const [inputCreateData, setInputCreateData] =
    useState<FormInputDataState>(INIT_DATA);
  const [inputUpdateData, setInputUpdateData] =
    useState<FormInputDataState>(INIT_DATA);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openUpdateUserDialog, setOpenUpdateUserDialog] =
    useState<boolean>(false);
  const [editId, setEditId] = useState<number>(-1);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [importFiles, setImportFiles] = useState<FileList>();
  const [openEstablishmentChoice, setOpenEstablishmentChoice] =
    useState<boolean>(false);
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const filters = searchKey && {
    name: searchKey,
  };

  const variables = {
    input: {
      filter: JSON.stringify({
        ...normalizeFilter(filter),
        ...filters,
      }),
    },
  };

  const { data: establishmentNames } = useQuery<EtablishmentNames>(
    GET_ETABLISHMENT_NAMES,
  );

  const INPUT_FILTERS: FilterControlType[] = [
    {
      label: 'Etablissement',
      name: 'establishment',
      value: '',
      placeholder: 'Tous les établissements',
      type: 'select',
      options,
      autoComplete: true,
    },
  ];

  useEffect(() => {
    if (establishmentNames && establishmentNames.etablishments) {
      const opts = establishmentNames.etablishments.map((item: any) => {
        if (item)
          return {
            label: item.name,
            value: item.name,
          };
        return true;
      });
      setOptions(opts);
    }
  }, [establishmentNames]);

  const {
    loading: loadingAdminList,
    data: adminList,
    refetch,
  } = useQuery<Administrators, AdministratorsVariables>(GET_ADMINISTRATORS, {
    variables,
  });

  const [getAdminDetails] = useLazyQuery<Administrator, AdministratorVariables>(
    GET_ADMINISTRATOR,
    {
      onCompleted: (data: Administrator) => {
        if (data && data.administrator) {
          const { etablishments, user } = data.administrator;
          setInputUpdateData({
            email: (user && user.email) || '',
            name: (user && user.firstName) || '',
            lastName: (user && user.lastName) || '',
            mobilePhone: (user && user.mobilePhone) || '',
            userFunction: (user && user.userFunction) || '',
            phone: (user && user.phone) || '',
            establishmentId:
              (etablishments && etablishments[0] && etablishments[0].id) || 0,
            status: (user && user.enabled) || false,
          });
          setOpenUpdateUserDialog(true);
        }
      },
      onError: (error: ApolloError) => {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          "Une erreur s'est produite lors de la récupération des informations de l'administrateur";
        if (error.networkError) {
          snackbarData.type = 'ERROR';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            'Erreur de réseau. Veuillez vérifier votre connexion internet';
        }
        displaySnackbar(client, snackbarData);
      },
    },
  );

  const [createAdmin, { loading: loadingCreateAdmin }] = useMutation<
    CreateAdmin,
    CreateAdminVariables
  >(CREATE_ADMIN, {
    onCompleted: (data: CreateAdmin) => {
      if (data && data.createAdmin) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = "L'administrateur a été ajouté";
        displaySnackbar(client, snackbarData);
        setopenCreateUserDialog(false);
        setInputCreateData(INIT_DATA);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la création d'administrateur";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const { message } = error.graphQLErrors[0];
        switch (message) {
          case 'EMAIL_ALREADY_USED':
            snackbarData.type = 'WARNING';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = "L'adresse email est déjà utilisée";
            break;
        }
      } else if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [createAdmins, { loading: loadingCreateAdmins }] = useMutation<
    CreateAdmins,
    CreateAdminsVariables
  >(CREATE_ADMINS, {
    onCompleted: (data: CreateAdmins) => {
      showSnackbar({
        client,
        message: `Les administrateurs ont été ajoutés`,
        type: 'SUCCESS',
      });
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la création des administrateurs";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const { message } = error.graphQLErrors[0];
        if (message.includes('EMAIL_ALREADY_USED')) {
          snackbarData.type = 'WARNING';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `L'adresse email ${
            message.split(';')[0]
          } est déjà utilisée`;
        }
      } else if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [updateAdmin, { loading: loadingUpdateAdmin }] = useMutation<
    UpdateAdmin,
    UpdateAdminVariables
  >(UPDATE_ADMIN, {
    onCompleted: (data: UpdateAdmin) => {
      if (data && data.updateAdmin) {
        setOpenUpdateUserDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour d'administrateur";

      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const { message } = error.graphQLErrors[0];
        switch (message) {
          case 'EMAIL_ALREADY_USED':
            snackbarData.type = 'WARNING';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = "L'adresse email est déjà utilisée";
            break;
        }
      } else if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [deleteAdmin, { loading: loadingDeleteAdmin }] = useMutation<
    DeleteAdmin,
    DeleteAdminVariables
  >(DELETE_ADMIN, {
    onCompleted: (data: DeleteAdmin) => {
      if (data && data.deleteAdmin) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `L'administrateur a été supprimé`;
        displaySnackbar(client, snackbarData);
        setOpenConfirmDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la suppression d'administrateur";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [deleteAdmins, { loading: loadingDeleteAdmins }] = useMutation<
    DeleteAdmins,
    DeleteAdminsVariables
  >(DELETE_ADMINS, {
    onCompleted: (data: DeleteAdmins) => {
      if (data && data.deleteAdmins) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Les administrateurs ont été supprimés`;
        displaySnackbar(client, snackbarData);
        setOpenConfirmDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la suppression des administrateurs";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [getAdminInfo] = useLazyQuery<Administrator, AdministratorVariables>(
    GET_ADMINISTRATOR,
    {
      onCompleted: (data: Administrator) => {
        if (data && data.administrator) {
          exportData({
            csvData: [formatAdministratorData(data.administrator)],
            fileName: 'Administrateur',
            sheetName: 'Administrateur',
          });
        }
      },
      onError: (error: ApolloError) => {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          "Une erreur s'est produite lors de la récupération des informations de l'administrateur";
        if (error.networkError) {
          snackbarData.type = 'ERROR';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            'Erreur de réseau. Veuillez vérifier votre connexion internet';
        }
        displaySnackbar(client, snackbarData);
      },
    },
  );

  const [getSelectedEstablishmentInfo] = useLazyQuery<
    Administrators,
    AdministratorsVariables
  >(GET_ADMINISTRATORS, {
    onCompleted: (data: Administrators) => {
      if (data) {
        exportData({
          csvData: data.administrators.map((item: any) =>
            formatAdministratorData(item),
          ),
          fileName: 'Administrateurs',
          sheetName: 'Administrateurs',
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la récupération des informations de l'administrateur";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [updateAdminStatus] = useMutation<
    UpdateAdminStatus,
    UpdateAdminStatusVariables
  >(UPDATE_ADMIN_STATUS, {
    onCompleted: (data: UpdateAdminStatus) => {
      if (data && data.updateAdminStatus) {
        const statut =
          data.updateAdminStatus &&
          data.updateAdminStatus[0] &&
          data.updateAdminStatus[0].user &&
          data.updateAdminStatus[0].user.enabled === true
            ? 'activé'
            : 'désactivé';
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Les administrateurs ont été ${statut}s`;
        setSelectedItems([]);
        displaySnackbar(client, snackbarData);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour des administrateurs";

      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  useEffect(() => {
    if (adminList && adminList.administrators) {
      setData(adminList.administrators);
    }
  }, [adminList]);

  const columns: HeadCell[] = [
    {
      name: 'lastName',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.lastName) || '-';
      },
    },
    {
      name: 'name',
      label: 'Prénom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.firstName) || '-';
      },
    },
    {
      name: 'etablishment',
      label: 'Etablissement',
      disablePadding: false,
      renderer: (value: any) => {
        const etabName =
          (
            value &&
            value.etablishments &&
            value.etablishments.map((item: any) => item.name)
          ).join(', ') || '-';
        return etabName;
      },
    },
    {
      name: 'mail',
      label: 'Mail',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.email) || '-';
      },
    },
    {
      name: 'mobilePhone',
      label: 'Tél mobile',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.mobilePhone) || '-';
      },
    },
    {
      name: 'phone',
      label: 'Tél fixe',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.phone) || '-';
      },
    },
    {
      name: 'fonction',
      label: 'Fonction',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.userFunction) || '-';
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => {
        const statut = (value && value.user && value.user.enabled) || false;
        return (
          <FormControlLabel
            control={
              <Switch
                checked={statut}
                onChange={(event) => onHandleChangeStatus(event, value.id)}
                name="enabled"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            }
            label={statut ? 'Activé' : 'Désactivé'}
          />
        );
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex" justifyContent="center">
          <Tooltip title="Exporter en Excel" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleExport(value.id);
              }}
              className={classes.margin}
            >
              <img
                alt="excel-icon"
                src={ExcelIcon}
                className={classes.excelIcon}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleDelete(value.id);
              }}
              className={classes.margin}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifier" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleEditUser(value.id);
              }}
              className={classes.margin}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const onHandleChangeStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const { checked } = event.target;
    if (id) {
      updateAdmin({
        variables: {
          id,
          enabled: checked,
        },
      }).then(({ data }) => {
        if (data && data.updateAdmin) {
          const statut =
            data.updateAdmin.user && data.updateAdmin.user.enabled
              ? 'activé'
              : 'désactivé';
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `L'administrateur a été ${statut}`;
          displaySnackbar(client, snackbarData);
        }
      });
    }
  };

  const onCreateAdminUpdate: MutationUpdaterFn<CreateAdmin> = (
    cache,
    { data },
  ) => {
    const adminData = cache.readQuery<Administrators>({
      query: GET_ADMINISTRATORS,
      variables,
    });
    const newAdmin = data && data.createAdmin && data.createAdmin.data;
    if (newAdmin && adminData) {
      cache.writeQuery({
        query: GET_ADMINISTRATORS,
        variables,
        data: {
          administrators: [newAdmin, ...adminData.administrators],
        },
      });
    }
  };

  const onHandleAddUser = (data: FormInputDataState, cb: any) => {
    const user: User = formInputDataStateToUser(data, 'administrator') as User;
    handleUserListValidation(user, client, () => {
      createAdmin({
        variables: {
          ...user,
        },
        update: onCreateAdminUpdate,
      })
        .then(({ data }) => {
          return cb(!!(data && data.createAdmin && data.createAdmin.success));
        })
        .catch(() => {
          return cb(false);
        });
    });
  };

  const onHandleEditUser = (id: number) => {
    setEditId(id);
    getAdminDetails({ variables: { id } });
  };

  const onHandleUpdateUser = (data: FormInputDataState, cb: any) => {
    const user: User = formInputDataStateToUser(data, 'administrator') as User;
    handleUserListValidation(user, client, () => {
      updateAdmin({
        variables: {
          ...user,
          id: editId,
        },
      }).then(({ data }) => {
        if (data && data.updateAdmin) {
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `L'administrateur a été mis à jour`;
          displaySnackbar(client, snackbarData);
        }
      });
    });
  };

  const onHandleExport = (id: number) => {
    getAdminInfo({ variables: { id } });
  };

  const onHandleSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKey(value);
  };

  const onHandleDelete = (id: number) => {
    setAdminId(id);
    setOpenConfirmDialog(true);
  };

  const onDeleteAdmin = () => {
    if (adminId && adminId > 0) {
      deleteAdmin({
        variables: {
          id: adminId,
        },
        update: (cache, { data }) => {
          const deletedId = data && data.deleteAdmin && data.deleteAdmin.id;

          const adminData = cache.readQuery<Administrators>({
            query: GET_ADMINISTRATORS,
            variables,
          });

          if (deletedId && adminData) {
            const newData = adminData.administrators.filter(
              (admin) => admin && admin.id !== deletedId,
            );
            cache.writeQuery({
              query: GET_ADMINISTRATORS,
              variables,
              data: {
                administrators: newData,
              },
            });
          }
        },
      });
    } else {
      snackbarData.type = 'WARNING';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'ID administrateur non trouvé.';
      displaySnackbar(client, snackbarData);
      setOpenConfirmDialog(false);
    }
  };

  const exportAdminInfos = (selectedItems: number[]): void => {
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les administrateurs à exporter`,
      )
    ) {
      const filters = JSON.stringify({ ids: selectedItems });
      const order = JSON.stringify({ id: 'asc' });
      const variables = { input: { filter: filters, orderBy: order } };
      getSelectedEstablishmentInfo({ variables });
    }
  };

  const changeAdminsStatus = (
    selectedItems: number[],
    enabled: boolean,
  ): void => {
    const status = enabled ? 'activer' : 'désactiver';
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les administrateurs à ${status}`,
      )
    ) {
      updateAdminStatus({ variables: { ids: selectedItems, status: enabled } });
    }
  };

  const removeAdmins = (selectedItems: number[]): void => {
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les administrateurs à supprimer`,
      )
    ) {
      deleteAdmins({
        variables: { ids: selectedItems },
        update: (cache, { data }) => {
          const deletedAdmins = ((data && data.deleteAdmins) || []).map(
            (item) => item && item.id,
          );

          const adminData = cache.readQuery<Administrators>({
            query: GET_ADMINISTRATORS,
            variables,
          });

          if (deletedAdmins && adminData && adminData.administrators) {
            const newData = adminData.administrators.filter(
              (admin) => admin && deletedAdmins.indexOf(admin.id) === -1,
            );
            cache.writeQuery({
              query: GET_ADMINISTRATORS,
              variables,
              data: {
                administrators: newData,
              },
            });
          }
        },
      });
    }
  };

  const onHandleAction = (action: ValueType<GroupAction>): void => {
    const actionGroup = action as GroupAction;

    if (actionGroup && actionGroup.value) {
      switch (actionGroup.value) {
        case 'export':
          exportAdminInfos(selectedItems);
          break;
        case 'enable':
          changeAdminsStatus(selectedItems, true);
          break;
        case 'disable':
          changeAdminsStatus(selectedItems, false);
          break;
        case 'remove':
          removeAdmins(selectedItems);
          break;
      }
    }
  };

  const onExcelReaderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenEstablishmentChoice(true);
    if (event.target.files) {
      setImportFiles({ ...event.target.files });
    }
    event.preventDefault();
  };

  const adminToolbarButtons = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="admin-add"
      startIcon={<AddBoxIcon />}
      onClick={() => setopenCreateUserDialog(true)}
    >
      Ajouter un administrateur
    </Button>,

    <ExcelReader
      onChange={onExcelReaderChange}
      onClick={() => {}}
      loading={false}
    />,
  ];

  const onCreateAdminsUpdate: MutationUpdaterFn<CreateAdmins> = (
    cache,
    { data },
  ) => {
    const adminData = cache.readQuery<Administrators>({
      query: GET_ADMINISTRATORS,
      variables,
    });
    const newAdmins = data && data.createAdmins && data.createAdmins.data;
    if (newAdmins && adminData) {
      cache.writeQuery({
        query: GET_ADMINISTRATORS,
        variables,
        data: {
          administrators: [...newAdmins, ...adminData.administrators],
        },
      });
    }
  };

  const onChooseEstablishment = async (id: number) => {
    if (!importFiles) {
      return;
    }
    const users: User[] = await getUsersFromFiles(importFiles, {
      userRole: 'administrator',
      establishmentId: id,
    });
    const validationStatus: UserValidationState = validateUsers(
      users,
      getImportExcelValidators('administrator'),
    );
    if (!validationStatus.valid && validationStatus.error) {
      const message: string = getExcelImportUserErrorMessage(
        validationStatus.error,
      );
      showSnackbar({
        message,
        type: 'ERROR',
        client,
      });
      setOpenEstablishmentChoice(false);
    } else {
      createAdmins({
        variables: {
          input: getPartialUsers(users, [
            'userRole',
          ]) as CreateAdministratorInput[],
        },
        update: onCreateAdminsUpdate,
      }).then(() => {
        refetch();
      });
    }
  };

  return (
    <Box className={classes.root}>
      <Row>
        <Col xs={12} xl={9} lg={8} md={6}>
          <Paper className={classes.paper}>
            <SearchInput
              onChange={onHandleSearchKey}
              value={searchKey}
              placeholder="Recherchez un administrateur"
            />
          </Paper>
        </Col>
        <Col xs={12} xl={3} lg={4} md={6}>
          <Paper className={classes.paper}>
            <Typography className={classes.labelFilter}>
              Filtre de recherche :
            </Typography>
            <SearchFilter
              filterControls={INPUT_FILTERS}
              filter={filter}
              setFilter={setFilter}
              isSearchable
            />
          </Paper>
        </Col>
      </Row>
      <Box className={classes.adminList}>
        <CustomTable
          toolbar
          selectable
          data={data}
          columns={columns}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          toolbarActions={ACTIONS}
          loadingData={loadingAdminList || loadingCreateAdmin}
          onHandleAction={onHandleAction}
          toolbarButtons={adminToolbarButtons}
          isLeftContent={true}
          toolbarProps={{
            toolbarTitle: 'Liste des administrateurs',
          }}
        />
        <UserFormDialog
          userRole="administrator"
          open={openCreateUserDialog}
          title="Ajout d'un administrateur"
          onSubmit={onHandleAddUser}
          data={inputCreateData}
          loadingButton={loadingCreateAdmin}
          activeEstablishmentField={true}
          handleClose={() => {
            setopenCreateUserDialog(false);
          }}
        />
        <UserFormDialog
          userRole="administrator"
          open={openUpdateUserDialog}
          title="Modification d'un administrateur"
          onSubmit={onHandleUpdateUser}
          data={inputUpdateData}
          loadingButton={loadingUpdateAdmin}
          buttonLabel="Enregistrer"
          activeEstablishmentField={true}
          handleClose={() => {
            setOpenUpdateUserDialog(false);
          }}
        />
        <ConfirmationDialog
          title="Suppression"
          message="Etes-vous sûr de vouloir supprimer cet administrateur ?"
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={onDeleteAdmin}
          loadingConfirmation={loadingDeleteAdmin}
        />
        <EstablishmentChoice
          open={openEstablishmentChoice}
          setOpen={setOpenEstablishmentChoice}
          onChooseEstablishment={onChooseEstablishment}
        />
      </Box>
    </Box>
  );
};

export default withRouter(EnhancedAdministrator);
