import React, { FC } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import useStyles from './styles';

export interface FilterTextProps {
  label: string;
  name: string;
  placeholder?: string;
  value: string;
  minWidth?: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterText: FC<FilterTextProps> = (props) => {
  const { label, name, placeholder, value, onChange, minWidth } = props;
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      style={{ minWidth }}
    >
      <TextField
        // id="outlined-basic"
        autoComplete="off"
        variant="outlined"
        placeholder={placeholder || label}
        value={value}
        onChange={onChange}
        label={label}
        name={name}
        InputLabelProps={{ shrink: true }}
      />
    </FormControl>
  );
};

export default FilterText;
