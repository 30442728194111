import { User, Beneficiary } from '../types/user';
import { DEFAULT_USER } from '../components/Establishment/EstablishmentAdd/EstablishmentEdit/UserFormDialog/UserFormDialog.constants';
import { convertExcelToJSON } from '../common/excel';
import moment from 'moment';

export type UserTypesKey = keyof User | keyof Beneficiary;
export type UserFieldName = Record<UserTypesKey, string>;

export const fieldName: UserFieldName = {
  email: 'email',
  enabled: 'statut',
  establishmentId: 'nb_etablissement',
  firstName: 'prenom',
  id: 'id',
  lastName: 'nom',
  mobilePhone: 'tel_mobile',
  phone: 'tel_fixe',
  userRole: 'role',
  unitLifeId: 'nb_unite_de_vie',
  userFunction: 'fonction',
  guardianEnabled: 'sous_tutelle',
  // handicap: 'handicap',
  unitLife: 'unite_de_vie',
  guardianName: 'nom_tuteur',
  birthDate: 'date_de_naissance',
  disabilityRecognition: 'handicape',
  signature: 'signature',
  signatureFile: ' ',
};

/*
 *	emptyField is an object to set default values for not defined field from
 * the excel file.
 */

const getNoIdDefaultUser = (emptyField: Partial<User>): Partial<User> => {
  const { id, ...rest } = DEFAULT_USER;
  return {
    ...rest,
    ...emptyField,
  };
};

const getValue = (
  value: string | number | boolean,
  key: UserTypesKey,
): string | number | boolean | Date => {
  switch (key) {
    case 'enabled':
      return (value as string).toLowerCase() === 'activé';
    case 'mobilePhone':
      return '' + value;
    case 'phone':
      return '' + value;
    case 'guardianEnabled':
      return (value as string).toLowerCase() === 'oui';
    case 'disabilityRecognition':
      return (value as string).toLowerCase() === 'oui';
    case 'birthDate':
      const date = moment(value as string, 'DD/MM/YYYY').toDate();
      return date;
    default:
      return value;
  }
};

export const convertToUser = (
  fileObj: any,
  fieldName: UserFieldName,
  emptyField: Partial<User> = {},
): User =>
  Object.entries(fieldName).reduce(
    (res: User, [key, fileKey]: [string, string]) => {
      const fileFieldValue: string = fileObj[fileKey];
      if (
        (emptyField.establishmentId && key === 'establishmentId') ||
        !fileFieldValue
      ) {
        return res;
      }
      return {
        ...res,
        [key]: getValue(fileFieldValue, key as UserTypesKey),
      };
    },
    getNoIdDefaultUser(emptyField) as User,
  );

export const convertToUsers = (
  fileObjs: any[],
  fieldName: UserFieldName,
  emptyField: Partial<User> = {},
): User[] =>
  fileObjs.map((fileObj) => convertToUser(fileObj, fieldName, emptyField));

export const getUsersFromFile = async (
  file: File,
  emptyField?: Partial<User>,
): Promise<User[]> => {
  const res = await convertExcelToJSON(file);
  if (emptyField?.userRole === 'beneficiary') {
    return (await convertToUsers(res, fieldName, emptyField)).map((user) => {
      const { userFunction, ...rest } = user;
      return rest;
    });
  }
  return convertToUsers(res, fieldName, emptyField);
};

export const getUsersFromFiles = async (
  fileList: FileList,
  emptyField?: Partial<User>,
): Promise<User[]> => {
  const usersPromises: Promise<User[]>[] = [];
  for (let i = 0; !!fileList[i]; i++) {
    usersPromises.push(getUsersFromFile(fileList[i], emptyField));
  }
  return (await Promise.all(usersPromises)).flat();
};
