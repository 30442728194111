import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import useStyles from './styles';
import mockLogo from '../../../../assets/images/mock-logo.png';
import {
  EstablishmentMin,
  InterventionReportData,
} from '../../../../types/intervention-report.types';
import { ESTABLISHMENT_TYPE } from '../../../../types/establishment';
import TextItem from '../../../WorkerDetails/components/TextItem';

interface InterventionReportInfoProps {
  interventionReportId?: number;
  establishment: any;
  onEdit?: () => void;
}

const InterventionReportInfo: FC<InterventionReportInfoProps> = (props) => {
  const { establishment, onEdit } = props;
  const {
    name,
    etablishmentType: establishmentType,
    rcs,
    enabled,
  } = establishment;

  const classes = useStyles();

  const onHandleEdit = () => {
    if (onEdit) {
      onEdit();
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.imageContainer}>
        <img src={mockLogo} alt="etablissement-logo" />
      </Box>
      <Box className={classes.etabInfos}>
        <Box className={classes.headerInfo}>
          <Typography component="h4" className={classes.establishmentName}>
            {name}
          </Typography>
          <Box display="flex">
            <Tooltip title="Modifier" placement="top" arrow>
              <IconButton
                onClick={() => onHandleEdit()}
                className={classes.margin}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box className={classes.etabDetails}>
          <Box className={classes.leftDetailsInfo}>
            <TextItem
              title="Statut :"
              content={enabled === true ? 'Activé' : 'Désactivé'}
              style={{ marginBottom: 8 }}
            />
            <TextItem
              title="Type :"
              content={
                ESTABLISHMENT_TYPE[establishment.etablishmentTypeId] || '-'
              }
            />
          </Box>
          <Box className={classes.rightDetailsInfo}>
            <TextItem
              title="Siren/Siret :"
              content={rcs}
              style={{ marginBottom: 8 }}
            />
            <TextItem
              title="Adresse :"
              content={establishment?.etablishmentAddress || '-'}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InterventionReportInfo;
