import React, { FC, useState, useEffect } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';

import { HeadCell } from '../../../types/table';
import SearchInput from '../../Common/SearchInput';
import useStyles from './styles';
import CustomTable from '../../Common/CustomTable';
import { EstablishmentFilter } from '../../../types/filters';
import SearchFilter from '../../Common/SearchFilter';
import {
  GET_ETABLISHMENTS,
  GET_ETABLISHMENT_WITHOUT_ORGANIZATION,
} from '../../../graphql/etablishment/query';
import {
  Etablishments,
  EtablishmentsVariables,
} from '../../../graphql/etablishment/types/Etablishments';
import { SEARCH_FILTERS } from './filters';
import FullscreenDialog from '../../Common/CustomFullscreenDialog';
import {
  ESTABLISHMENT_TYPE,
  ESTABLISHMENT_STATUS,
} from '../../../types/establishment';
import {
  EtablishmentWithoutOrganization,
  EtablishmentWithoutOrganizationVariables,
} from '../../../graphql/etablishment/types/EtablishmentWithoutOrganization';

interface EstablishmentChoiceProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onChooseEstablishment: (id: number) => void;
}

const INIT_FILTER: EstablishmentFilter = {
  city: '',
  postalCode: '',
  enabled: -1,
  type: 'ALL',
};

const normalizeFilter = (filters: EstablishmentFilter): any => {
  return {
    postalCode: filters.postalCode,
    enabled: filters.enabled,
    city: filters.city,
    etablishmentType: filters.type === 'ALL' ? '' : filters.type,
  };
};

const EstablishmentChoice: FC<
  EstablishmentChoiceProps & RouteComponentProps
> = (props) => {
  const { open, setOpen, history, onChooseEstablishment } = props;
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [filter, setFilter] = useState<EstablishmentFilter>(INIT_FILTER);

  const filters = searchKey && {
    name: searchKey,
  };

  const variables = {
    input: {
      filter: JSON.stringify({
        ...normalizeFilter(filter),
        ...filters,
      }),
    },
  };

  const { data: establishments, loading } = useQuery<
    EtablishmentWithoutOrganization,
    EtablishmentWithoutOrganizationVariables
  >(GET_ETABLISHMENT_WITHOUT_ORGANIZATION, { variables });

  useEffect(() => {
    if (establishments && establishments.etablishmentWithoutOrganization)
      setData(establishments.etablishmentWithoutOrganization);
  }, [establishments]);

  const columns: HeadCell[] = [
    {
      name: 'logo',
      label: 'Logo',
      disablePadding: false,
      renderer: (value: any) => {
        return (
          <Avatar
            alt="logo"
            className="contain-avatar"
            variant="rounded"
            src=""
          />
        );
      },
    },
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.name) || '-';
      },
    },
    {
      name: 'etablishmentType',
      label: 'Type',
      disablePadding: false,
      renderer: (value: any) => {
        return (
          (value.etablishmentStatus &&
            value.etablishmentType.name &&
            ESTABLISHMENT_TYPE[value.etablishmentType.name]) ||
          '-'
        );
      },
    },
    {
      name: 'etablishmentStatus',
      label: "Statut d'organisation",
      disablePadding: false,
      renderer: (value: any) => {
        return (
          (value.etablishmentStatus &&
            value.etablishmentStatus.name &&
            ESTABLISHMENT_STATUS[value.etablishmentStatus.name]) ||
          '-'
        );
      },
    },
    {
      name: 'address',
      label: 'Adresse',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.address) || '-';
      },
    },
    {
      name: 'postalCode',
      label: 'Code postal',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.postalCode) || '-';
      },
    },
    {
      name: 'city',
      label: 'Ville',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.city) || '-';
      },
    },
    {
      name: 'administrators',
      label: 'NB Admin',
      disablePadding: false,
      renderer: (value: any) => {
        return (
          (value && value.administrators && value.administrators.length) || 0
        );
      },
    },
    {
      name: 'beneficiaries',
      label: 'NB Bénéficiaires',
      disablePadding: false,
      renderer: (value: any) => {
        return (
          (value && value.beneficiaries && value.beneficiaries.length) || 0
        );
      },
    },
    {
      name: 'workers',
      label: 'NB Intervenants',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.workers && value.workers.length) || 0;
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.enabled ? 'Activé' : 'Désactivé') || '-';
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex" justifyContent="center">
          <Button
            className={`${classes.white}`}
            variant="contained"
            color="secondary"
            name="set-root"
            onClick={(e) => {
              e.stopPropagation();
              onChooseEstablishment(value.id);
              setOpen(false);
            }}
          >
            Définir
          </Button>
        </Box>
      ),
    },
  ];

  const onHandleSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKey(value);
  };

  const handleGoBack = () => {
    //history.goBack();
    setOpen(false);
  };

  return (
    <FullscreenDialog
      title="Choix de l'établissement"
      subTitle="Avant d'importer la liste excel, vous devez choisir l'établissement de base."
      open={open}
      handleGoBack={handleGoBack}
    >
      <Box className={classes.content}>
        <Row>
          <Col xs={12} md={12} lg={12} xl={4}>
            <Paper className={classes.paper}>
              <SearchInput
                onChange={onHandleSearchKey}
                value={searchKey}
                placeholder="Recherchez un établissement"
              />
            </Paper>
          </Col>
          <Col xs={12} md={12} lg={12} xl={8}>
            <Paper className={classes.paper}>
              <Typography className={classes.labelFilter}>
                Filtre de recherche :
              </Typography>
              <SearchFilter
                filterControls={SEARCH_FILTERS}
                filter={filter}
                setFilter={setFilter}
                overrideCols={{ xs: 12, sm: 6, md: 6, lg: 3, xl: 3 }}
              />
            </Paper>
          </Col>
        </Row>
        <Box
          display="flex"
          justifyContent="center"
          style={{ marginBottom: 100 }}
        >
          <CustomTable
            toolbar
            selectable={false}
            data={data}
            columns={columns}
            toolbarProps={{
              toolbarTitle: 'Liste des établissements',
            }}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            loadingData={loading}
            hideSelectedText={true}
            isLeftContent={true}
          />
        </Box>
      </Box>
    </FullscreenDialog>
  );
};

export default withRouter(EstablishmentChoice);
