import {
  DateEndType,
  PeriodicityTypeEnum,
} from '../graphql/types/graphql-global-types';
import { PlanningDataType } from '../types/planning';

export const PLANNING_DATA: PlanningDataType = {
  __typename: 'PlanningData',
  id: 0,
  dateStart: '',
  dateEnd: '',
  hourStart: '',
  hourEnd: '',
  location: '',
  prestation: '',
  title: '',
  recurrence: 0,
  workers: [],
  beneficiaries: [],
  establishmentId: 0,
  unitLife: [],
  comment: '',
  afterOccurence: 1,
  periodicityType: PeriodicityTypeEnum.DAILY,
  periodicityRepetition: 1,
  monday: true,
  tuesday: true,
  wednesday: true,
  status: '',
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
  dateEndType: DateEndType.END_AFTER,
};
