import {
  Box,
  Radio,
  FormControlLabel,
  Grid,
  TextField,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import useStyles from './styles';
import { PlanningDataType } from '../../../../../../../types/planning';
import { PeriodicityTypeEnum } from '../../../../../../../graphql/types/graphql-global-types';

interface BasicInformationsProps {
  inputData: PlanningDataType;
  setInputData: React.Dispatch<React.SetStateAction<any>>;
}

const PeriodicityHeb: FC<BasicInformationsProps> = (props) => {
  const classes = useStyles();
  const { inputData, setInputData } = props;

  useEffect(() => {
    setInputData((prevState: any) => ({
      ...prevState,
      periodicityType: PeriodicityTypeEnum.WEEKLY,
      monday: true,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    }));
  }, []);

  const onHandleChange = (
    event: React.ChangeEvent<
      | HTMLInputElement
      | {
          name?: string | undefined;
          value: unknown;
        }
    >,
  ) => {
    const { name, value } = event.target;

    if (name) {
      setInputData((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const changeCheckDay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({ ...inputData, [event.target.name]: event.target.checked });
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={7}></Grid>
        <Grid item xs={5} className={classes.day}></Grid>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FormControlLabel
            value="forWeek"
            control={<Radio color="primary" />}
            label="Toutes les"
            style={{ margin: 0 }}
          />
          <TextField
            id="week-number"
            type="number"
            placeholder="0"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ margin: '0 8px' }}
            inputProps={{ min: '1', max: '100' }}
            variant="outlined"
            size="small"
            name="periodicityRepetition"
            value={inputData.periodicityRepetition}
            onChange={onHandleChange}
          />
          <p style={{ margin: 0 }}>semaine(s) le :</p>
        </Box>
        <Grid item xs={12}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  name="monday"
                  checked={inputData.monday}
                  onChange={changeCheckDay}
                />
              }
              label="Lundi"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="tuesday"
                  checked={inputData.tuesday}
                  onChange={changeCheckDay}
                />
              }
              label="Mardi"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="wednesday"
                  checked={inputData.wednesday}
                  onChange={changeCheckDay}
                />
              }
              label="Mercredi"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="thursday"
                  checked={inputData.thursday}
                  onChange={changeCheckDay}
                />
              }
              label="Jeudi"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="friday"
                  checked={inputData.friday}
                  onChange={changeCheckDay}
                />
              }
              label="Vendredi"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="saturday"
                  checked={inputData.saturday}
                  onChange={changeCheckDay}
                />
              }
              label="Samedi"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="sunday"
                  checked={inputData.sunday}
                  onChange={changeCheckDay}
                />
              }
              label="Dimanche"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PeriodicityHeb;
