import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import {
  InterventionReportData,
  PlanninChildData,
  InterventionReport,
} from '../../../../types/intervention-report.types';
import ExcelIcon from '../../../../assets/images/excel.png';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

export interface ActionRendererArgs {
  item: any | null;
  classes: Record<'margin' | 'excelIcon', string>;
  onHandleExport: (id: number) => void;
  onHandleEdit: (item: PlanninChildData) => void;
}

export interface SignatureRendererArgs {
  setImageUrl: (value: React.SetStateAction<string>) => void;
  setSignatureDialogOpen: (value: React.SetStateAction<boolean>) => void;
}

export const signatureRenderer = ({
  setImageUrl,
  setSignatureDialogOpen,
}: SignatureRendererArgs) => (value: InterventionReportData) => {
  if (!!value.signature) {
    const url: string = value.signature;
    return (
      <img
        style={{
          height: 'auto',
          width: 'auto',
          objectFit: 'contain',
          maxHeight: '47px',
          maxWidth: '154px',
        }}
        src={url}
        alt={`Signature de ${value.beneficiary.name}`}
        onClick={(event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
          event.stopPropagation();
          setImageUrl(url);
          setSignatureDialogOpen(true);
        }}
      />
    );
  }
  return '-';
};

export const actionsRenderer = ({
  item,
  classes,
  onHandleExport,
  onHandleEdit,
}: ActionRendererArgs) => (
  <Box display="flex" justifyContent="center">
    <Tooltip title="Exporter en Excel" placement="top" arrow>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          item && onHandleExport(item);
        }}
        className={classes.margin}
      >
        <img alt="excel-icon" src={ExcelIcon} className={classes.excelIcon} />
      </IconButton>
    </Tooltip>
    <Tooltip title="Modifier" placement="top" arrow>
      <IconButton
        onClick={(e) => {
          item && onHandleEdit(item);
          e.stopPropagation();
        }}
        className={classes.margin}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  </Box>
);

// export const actionsRenderer = ({
//   id,
//   classes,
//   onHandleExport,
//   onHandleEdit,
// }: ActionRendererArgs) => (
//   <Box display="flex" justifyContent="center">
//     <Tooltip title="Exporter en Excel" placement="top" arrow>
//       <IconButton
//         onClick={(e) => {
//           e.stopPropagation();
//           id && onHandleExport(id);
//         }}
//         className={classes.margin}
//       >
//         <img alt="excel-icon" src={ExcelIcon} className={classes.excelIcon} />
//       </IconButton>
//     </Tooltip>
//     <Tooltip title="Modifier" placement="top" arrow>
//       <IconButton
//         onClick={(e) => {
//           // id && onHandleEdit(id);
//           e.stopPropagation();
//         }}
//         className={classes.margin}
//       >
//         <EditIcon />
//       </IconButton>
//     </Tooltip>
//   </Box>
// );
