import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: '0px 10px 25px #00000017',
      borderRadius: 16,
      height: 780,
    },
    profileDetails: {
      display: 'flex',
      padding: 24,
      flexDirection: 'column',
      alignItems: 'center',
      '& h3': {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
      },
      '& label': {
        color: '#9E9E9E',
        fontSize: 16,
      },
      paddingBottom: 25,
    },
    // profileDetails: {
    //   display: 'flex',
    //   flexDirection: 'column',
    //   alignItems: 'center',
    //   '& label': {
    //     color: '#9E9E9E',
    //     fontSize: 16,
    //   },
    //   paddingBottom: 25,
    // },
    link: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: `#007bff !important`,
      cursor: 'pointer',
      textDecoration: 'underline !important',
      userSelect: 'none',
    },
    imageContainer: {
      width: 142,
      height: 142,
      borderRadius: 10,
      overflow: 'hidden',
      backgroundColor: '#f5f5f5f5',
      marginBottom: 24,
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },
    field: {
      display: 'flex',
      paddingBottom: 25,
      fontSize: 14,
      lineHeight: 17,
      '& h4': {
        color: '#9E9E9E',
        minWidth: 120,
      },
      '& p': {
        color: '#000000',
      },
    },
    fieldContainer: {
      padding: 24,
    },
  }),
);

export default useStyles;
