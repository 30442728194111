import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: theme.spacing(0, 4),
    },
    button: {
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    white: {
      color: theme.palette.common.white,
    },
    flowRow: {
      flexFlow: 'row',
      marginBottom: theme.spacing(0),
      paddingLeft: 0,
      paddingRight: 6,
      justifyContent: 'space-between',

      '& .MuiBox-root': {
        marginBottom: 0,
      },

      [theme.breakpoints.down(620)]: {
        '& .MuiBox-root': {
          marginBottom: theme.spacing(2),
        },

        flexFlow: 'wrap',
        marginBottom: theme.spacing(2),
      },
    },
  }),
);

export default useStyles;
