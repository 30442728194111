import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import { ApolloClient, ApolloError, ExecutionResult } from 'apollo-boost';
import {
  CREATE_UNIT_LIFE,
  DELETE_UNIT_LIFE,
  UPDATE_UNIT_LIFE,
} from '../../../../../../graphql/unit/mutation';
import {
  CreateUnitLife,
  CreateUnitLifeVariables,
} from '../../../../../../graphql/unit/types/CreateUnitLife';
import {
  DeleteUnitLife,
  DeleteUnitLifeVariables,
} from '../../../../../../graphql/unit/types/DeleteUnitLife';
import {
  UpdateUnitLife,
  UpdateUnitLifeVariables,
} from '../../../../../../graphql/unit/types/UpdateUnitLife';
import { LifeUnit } from '../../../../../../types/unit';

export interface UseCreateUnitLifeArgs {
  options?: MutationHookOptions<CreateUnitLife, CreateUnitLifeVariables>;
}

export interface UseDeleteUnitLifeArgs {
  options?: MutationHookOptions<DeleteUnitLife, DeleteUnitLifeVariables>;
}

export interface UseUpdateUnitLifeArgs {
  options?: MutationHookOptions<UpdateUnitLife, UpdateUnitLifeVariables>;
}

export const useCreateUnitLife = ({
  options,
}: UseCreateUnitLifeArgs): [
  (lifeUnit: LifeUnit) => Promise<ExecutionResult<CreateUnitLife>>,
  boolean,
] => {
  const [createUnitLife, { loading }] = useMutation<
    CreateUnitLife,
    CreateUnitLifeVariables
  >(CREATE_UNIT_LIFE, options);
  return [
    (lifeUnit: LifeUnit) => {
      const { id, createdAt, ...rest } = lifeUnit;
      return createUnitLife({
        variables: {
          input: rest,
        },
      });
    },
    loading,
  ];
};

export const useDeleteUnitLife = ({
  options,
}: UseDeleteUnitLifeArgs): [
  (id: number) => Promise<ExecutionResult<DeleteUnitLife>>,
  boolean,
] => {
  const [deleteUnitLife, { loading }] = useMutation<
    DeleteUnitLife,
    DeleteUnitLifeVariables
  >(DELETE_UNIT_LIFE, options);
  return [
    (id: number) =>
      deleteUnitLife({
        variables: {
          id,
        },
      }),
    loading,
  ];
};

export const useUpdateUnitLife = ({
  options,
}: UseUpdateUnitLifeArgs): [
  (lifeUnit: LifeUnit) => Promise<ExecutionResult<UpdateUnitLife>>,
  boolean,
] => {
  const [updateLifeUnit, { loading }] = useMutation<
    UpdateUnitLife,
    UpdateUnitLifeVariables
  >(UPDATE_UNIT_LIFE, options);
  return [
    (lifeUnit: LifeUnit) => {
      const { createdAt, ...rest } = lifeUnit;
      return updateLifeUnit({
        variables: {
          input: {
            ...rest,
            id: lifeUnit.id as number,
          },
        },
      });
    },
    loading,
  ];
};
