import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			[theme.breakpoints.down(1386)]: {
				padding: '0 24px',
			},
			[theme.breakpoints.down(386)]: {
				padding: '0 16px',
			},
		},
		content: {
			minHeight: 800,
			marginBottom: 100,
			marginTop: 8,
		},
		logo:{
			maxWidth: 300,
			margin: 'auto',
		}
	}),
);

export default useStyles;
