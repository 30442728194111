import { Etablishment_etablishment } from '../../../graphql/etablishment/types/Etablishment';

const establishmentType: Record<string, string> = {
  DOMICILE: 'Domicile',
  RESIDENCE: 'Résidence',
  RESIDENCE_PARENT: 'Organisation',
  RESIDENCE_CHILD: 'Résidence fille',
};

const establishmentStatut: Record<string, string> = {
  ENTERPRISE: 'Entreprise',
  ASSOCIATION: 'Association',
  COLLECTIVITY: 'Collectivité',
  HOSPITAL: 'Hôpital',
};

export interface EstablishmentExcelFormat {
  nom: string;
  rcs: string;
  prestations: string;
  type: string;
  statut_organisation: string;
  adresse: string;
  code_postal: string;
  ville: string;
  nb_admin: number;
  nb_beneficiaire: number;
  nb_intervenant: number;
  statut: string;
}

export const formatEstablishmentData = (
  etablishment: Etablishment_etablishment,
): EstablishmentExcelFormat | {} => {
  if (!etablishment) return {};

  return {
    nom: etablishment.name,
    rcs: etablishment.rcs,
    adresse: etablishment.address,
    code_postal: etablishment.postalCode,
    ville: etablishment.city,
    type:
      (etablishment.etablishmentType &&
        etablishment.etablishmentType.name &&
        establishmentType[etablishment.etablishmentType.name]) ||
      '',
    statut_organisation:
      (etablishment.etablishmentStatus &&
        etablishment.etablishmentStatus.name &&
        establishmentStatut[etablishment.etablishmentStatus.name]) ||
      '',
    nb_admin:
      (etablishment.administrators && etablishment.administrators.length) || 0,
    nb_beneficiaire:
      (etablishment.beneficiaries && etablishment.beneficiaries.length) || 0,
    nb_intervenant: (etablishment.workers && etablishment.workers.length) || 0,
    prestations: etablishment.prestations || '',
    statut: etablishment.enabled ? 'Activé' : 'Désactivé',
  };
};
