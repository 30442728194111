import React from 'react';
import Box from '@material-ui/core/Box';

import Header from '../Header';
import useStyles from './styles';

interface LayoutProps {
  children: any;
}

/**
 * Layout Component
 * @param props LayoutProps
 */
const Layout = (props: LayoutProps) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <Box className={classes.root}>
      <Header />
      <Box>{children}</Box>
    </Box>
  );
};

export default Layout;
