import React, { FC } from 'react';
import Button from '@material-ui/core/Button';

import useStyles from './styles';

interface PlanningStepperActionsProps {
  activeStep: number;
  stepLength: number;
  handlePrevStep: () => void;
  handleNextStep: () => void;
  handleFinish: () => void;
  // handleIgnoreStep: () => void;
  isValidNextButton: boolean;
  disableFinishButton: boolean;
}

const PlanningStepperActions: FC<PlanningStepperActionsProps> = (props) => {
  const classes = useStyles();
  const {
    activeStep,
    stepLength,
    handlePrevStep,
    handleNextStep,
    handleFinish,
    // handleIgnoreStep,
    isValidNextButton,
    disableFinishButton,
  } = props;

  const previousButton = (
    <Button
      variant="contained"
      color="default"
      className={classes.actionButton}
      onClick={handlePrevStep}
    >
      Retour
    </Button>
  );

  const nextButton = (
    <Button
      variant="contained"
      color="primary"
      disabled={!isValidNextButton}
      className={classes.actionButton}
      onClick={handleNextStep}
    >
      Suivant
    </Button>
  );

  // const ignoreButton = (
  //   <Button
  //     variant="text"
  //     color="primary"
  //     className={`${classes.actionButton} ${classes.lowercase}`}
  //     onClick={handleIgnoreStep}
  //   >
  //     Ignorer
  //   </Button>
  // );

  const finishButton = (
    <Button
      variant="contained"
      color="primary"
      disabled={disableFinishButton}
      className={classes.actionButton}
      onClick={handleFinish}
    >
      Finaliser
    </Button>
  );

  return (
    <>
      {activeStep !== 0 && previousButton}
      {activeStep !== stepLength - 1 && nextButton}
      {/* {activeStep > 0 && activeStep < stepLength - 1 && ignoreButton} */}
      {activeStep === stepLength - 1 && finishButton}
    </>
  );
};

export default PlanningStepperActions;
