import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 500,
    },
    formControl: {
      width: '100%',
    },
    formGroup: {
      margin: theme.spacing(1, 0),
    },
    inputLabel: {
      fontSize: '0.8rem',
      paddingLeft: 16,
    },
    dateTimeField: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(2),
    },
    timePicker: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(1),
      width: 330,
    },
    periodicity: {
      marginTop: theme.spacing(2),
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      marginBottom: '16px',
    },
    radioChoice: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(2),
      justifyContent: 'center',
    },
    selectedRadio: {
      display: 'flex',
      flexDirection: 'column',
    },
    inlineGroup: {
      flexDirection: 'row',
      width: 400,
      justifyContent: 'space-between',

      [theme.breakpoints.down(500)]: {
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    periodicityReccurence: {
      marginTop: '25px',
    },
    reccurenceText: {
      marginTop: '12px',
    },
    timeContainer: {
      padding: '15px',
      marginTop: '10px',
      marginBottom: '20px',
    },
    boxTitle: {
      marginBottom: '15px',
    },
    formLabel: {
      '& .MuiFormControlLabel-label': {
        minWidth: 65,
      },
    },
  }),
);

export default useStyles;
