import { LifeUnit } from '../../../../../../types/unit';

export const validateLifeUnit = (lifeUnit: LifeUnit) => {
  if (!lifeUnit.name) {
    return {
      valid: false,
      error: 'EMPTY_NAME',
    };
  }
  return {
    valid: true,
  };
};

export const getLifeUnitErrorMessage = (error: string) => {
  if ((error = 'EMPTY_NAME')) {
    return `Nom manquant`;
  }
  return ``;
};
