import { useReducer } from 'react';
import { EstablishmentCreateState } from '../interfaces/EstablishmentCreate.interfaces';
import { EstablishmentAction } from './establishment-create.actions';

const initialState: EstablishmentCreateState = {
  establishmentId: 0,
  establishment: null,
  establishmentType: 'DOMICILE',
  establishmentCreateFinish: false,
  minorChanges: null,
};

const reducer = (
  state: EstablishmentCreateState,
  action: EstablishmentAction,
): EstablishmentCreateState => action.reducer(state);

export const useEstablishmentCreateReducer = (
  partialInitialState?: Partial<EstablishmentCreateState>,
) => {
  const partialState = partialInitialState || {};
  return useReducer(reducer, {
    ...initialState,
    ...partialState,
  });
};
