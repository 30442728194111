import gql from 'graphql-tag';

export const PLANNING_INFO = gql`
  fragment PlanningInfo on Planning {
    id
    dateStart
    dateEnd
    worker {
      id
    }
    beneficiary {
      id
    }
    intervention {
      id
    }
    status {
      id
      name
    }
  }
`;

export const PLANNING_DISPLAY = gql`
  fragment PlanningDisplay on Planning {
    id
    dateStart
    dateEnd
    timeStart
    timeEnd
    title
    comment
    dateStartTimestamp
    dateEndTimestamp
    intervention {
      id
      description
      comment
      location
      prestation
      workers {
        id
        user {
          id
          lastName
          firstName
        }
      }
      beneficiaries {
        id
        signature
        user {
          id
          lastName
          firstName
        }
      }
    }
    status {
      id
      name
    }
  }
`;

export const PLANNING_CHILD = gql`
  fragment PlanningChild on PlanningChild {
    count
    data {
      parent_id
      child_id
      dateStart
      dateEnd
      duration
    }
  }
`;
