import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formControl: {
      width: '100%',
      minWidth: 120,
      [theme.breakpoints.down(992)]: {
        margin: '6px 0',
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    paper: {
      boxShadow: '0 6px 16px rgba(0,0,0,0.09)',
      padding: 15,
      borderRadius: 16,
      marginBottom: 30,
      width: '100%',
    },
    column: {
      paddingBottom: 15,
    },
    unsetWidth: {
      minWidth: 'unset !important',
    },
    labelFilter: {
      fontSize: '0.875rem',
      color: '#9E9E9E',
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      top: -12,
      left: 30,
      padding: '0 8px',
    },
    button: {
      '& .MuiButton-label': {
        color: theme.palette.common.white,
        display: 'contents',
      },
      width: 150,
      height: 55,
    },
  }),
);

export default useStyles;
