import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: '#f5f5f5',
      borderRadius: 16,
      padding: theme.spacing(3),
    },
    title: {
      fontSize: '1.4em',
      marginBottom: theme.spacing(2),
    },
    content: {},
  }),
);

export default useStyles;
