import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formGroup: {
      margin: theme.spacing(1, 0),
    },
    formControlLabel: {
      width: '100%',
      boxSizing: 'border-box',
    },
    inputLabel: {
      fontSize: '0.8rem',
      paddingLeft: 16,
    },
    switch: {
      color: theme.palette.primary.main,
      marginRight: 20,
    },
  }),
);

export default useStyles;
