import React, { FC, useContext, useEffect, useState } from 'react';
import {
  ActionsAbstraction,
  EstablishmentDataType,
  EstablishmentType,
  EtablishmentDataType,
} from '../../../../../../types/establishment';
import StepsWrapper from '../StepsWrapper';
import {
  GoBack,
  GoNext,
  SetEstablishment,
  useEstablishmentEditorReducer,
} from './establishmentEditor.reducer';
import { composeSteps } from '../../Steps/stepsComposer';
import UserContext, {
  UserContextType,
} from '../../../../../Context/UserContext';
import StepManager from './StepManager';
import Footer from '../../../Footer';
import EditStepperActions from '../StepperActions';
import ConfirmationDialog from '../../../../../Common/ConfirmationDialog';
import { useLocation } from 'react-router-dom';

export interface EstablishmentEditorActions {
  onMajorChanges: (value: EstablishmentDataType) => void;
  onMinorChanges: (value: EstablishmentDataType) => void;
  onFinalize: (value: EstablishmentDataType) => void;
}

interface EstablishmentNewEditorProps {
  setEtablismentSave?: React.Dispatch<React.SetStateAction<number | undefined>>;
  value: EtablishmentDataType;
  establishmentType: EstablishmentType;
  actions: EstablishmentEditorActions;
  title?: string;
  history?: any;
}
interface LocationState {
  previousPath: string;
}

const normalizeEstablishment = (
  establishment: EstablishmentDataType,
): EstablishmentDataType => {
  const { establishmentType, ...rest } = establishment;
  return rest;
};

const EstablishmentEditor: FC<EstablishmentNewEditorProps> = (props) => {
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);

  const {
    actions: editorActions,
    establishmentType,
    title,
    value,
    history,
    setEtablismentSave,
  } = props;
  const location = useLocation<LocationState>();
  const {
    onMajorChanges,
    onFinalize: finalize,
    onMinorChanges,
  } = editorActions;

  const userContext: UserContextType = useContext(UserContext);
  const [state, dispatch] = useEstablishmentEditorReducer({
    steps: composeSteps(establishmentType, userContext.userRole),
    establishment: {
      ...value,
      establishmentType,
    },
  });

  useEffect(() => {
    onMajorChanges(normalizeEstablishment(state.establishment));
  }, [
    state.activeStep,
    state.establishment.administratorIds,
    state.establishment.beneficiaryIds,
    state.establishment.workerIds,
  ]);

  const redirectPage = () => {
    // history.goBack();
    let prevPath: string = '';

    if (location.state && location.state.previousPath) {
      prevPath = location.state.previousPath;
    }

    history.push({
      pathname: prevPath,
      search: '?tab=1',
    });
  };
  const titles: string[] = state.steps.map((step) => step.title);

  const setEstablishment = (establishment: EtablishmentDataType) => {
    dispatch(new SetEstablishment(establishment));
    onMinorChanges(normalizeEstablishment(establishment));
  };

  const onGoNext = () => {
    if (setEtablismentSave) {
      setEtablismentSave(state.activeStep);
    }
    if (state.activeStep === 2) {
      setShowConfirmationDialog(true);
    }
    dispatch(new GoNext());
  };

  const onFinalize = () => {
    state.actionButtonState.finalize.disabled = true;
    redirectPage();
  };

  const onGoBack = () => {
    dispatch(new GoBack());
  };

  const actionsAbstraction: ActionsAbstraction<EtablishmentDataType> = {
    goNext: onGoNext,
    goBack: onGoBack,
    updateData: setEstablishment,
  };

  const handleConfirm = () => {
    if (state.establishment) {
      finalize(normalizeEstablishment(state.establishment));
    }
    dispatch(new GoNext());

    setShowConfirmationDialog(false);
  };

  return (
    <StepsWrapper
      activeStep={state.activeStep}
      title={title || ``}
      titles={titles}
    >
      <ConfirmationDialog
        title={`Cette étape permet de créer définitivement votre établissement`}
        message={`Confirmez-vous la création de celui-ci ?`}
        open={showConfirmationDialog}
        setOpen={setShowConfirmationDialog}
        onConfirm={handleConfirm}
      />

      <StepManager actions={actionsAbstraction} state={state} />

      <Footer>
        <EditStepperActions
          stepsLength={state.steps.length}
          state={state.actionButtonState}
          actions={{
            onFinalize,
            onGoBack,
            onGoNext,
          }}
        />
      </Footer>
    </StepsWrapper>
  );
};

export default EstablishmentEditor;
