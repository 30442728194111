import gql from 'graphql-tag';

export const USER_FRAGMENT = gql`
  fragment UserInfo on User {
    id
  }
`;

export const ADMIN_FRAGMENT = gql`
  fragment AdminInfo on Administrator {
    id
    user {
      ...UserInfo
    }
  }
  ${USER_FRAGMENT}
`;

export const BENEFICIARY_FRAGMENT = gql`
  fragment BeneficiaryInfo on Beneficiary {
    id
    guardianEnabled
    guardianName
    user {
      ...UserInfo
    }
  }
  ${USER_FRAGMENT}
`;

export const WORKER_FRAGMENT = gql`
  fragment WorkerInfo on Worker {
    id
    user {
      ...UserInfo
    }
  }
  ${USER_FRAGMENT}
`;

export const ESTABLISHMENT_FRAGMENT = gql`
  fragment EstablishmentInfo on Etablishment {
    id
    name
    address
    rcs
    postalCode
    city
    effective
    logo
    etablishmentObject
    prestations
    enabled
    etablishmentType {
      id
      name
      slug
    }
    etablishmentStatus {
      id
      name
      slug
    }
    etablishmentFunding {
      id
      name
      slug
    }
    administrators {
      ...AdminInfo
    }
    workers {
      ...WorkerInfo
    }
    beneficiaries {
      ...BeneficiaryInfo
    }
    residences {
      id
      name
    }
  }
  ${USER_FRAGMENT}
  ${BENEFICIARY_FRAGMENT}
  ${WORKER_FRAGMENT}
  ${ADMIN_FRAGMENT}
`;

export const ESTABLISHMENT_DETAILS_FRAGMENT = gql`
  fragment EstablishmentDetailsInfo on Etablishment {
    id
    name
    address
    rcs
    postalCode
    city
    effective
    logo
    prestations
    etablishmentObject
    enabled
    etablishmentType {
      name
      slug
    }
    etablishmentStatus {
      name
      slug
    }
    etablishmentFunding {
      name
      slug
    }
  }
`;

export const ESTABLISHMENT_CONTRIBUTORS_FRAGMENT = gql`
  fragment EstablishmentContributorsInfo on Etablishment {
    administrators {
      ...AdminInfo
    }
    workers {
      ...WorkerInfo
    }
    beneficiaries {
      ...BeneficiaryInfo
    }
    residences {
      id
      name
    }
  }
  ${USER_FRAGMENT}
  ${BENEFICIARY_FRAGMENT}
  ${WORKER_FRAGMENT}
  ${ADMIN_FRAGMENT}
`;

export const USER_INFO_FRAGMENT = gql`
  fragment UserInfoFragment on User {
    id
    email
    firstName
    lastName
    enabled
    phone
    mobilePhone
    userFunction
  }
`;

export const STATUS_FRAGMENT = gql`
  fragment StatusInfo on Status {
    id
    name
    slug
  }
`;
