import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

import useStyles from './styles';

type Display = 'inline' | 'block';

interface TextItemProps {
  title: string;
  content: string;
  style?: any;
  inline?: boolean;
  className?: any;
  width?: number;
  mb?: number;
}

const TextItem: FC<TextItemProps> = (props) => {
  const { title, content, style, inline, className, width, mb } = props;
  const classes = useStyles();

  return (
    <div
      className={`${classes.root} ${inline && classes.inline} ${className}`}
      style={{ marginBottom: mb, ...style }}
    >
      <Typography className={classes.title} style={{ width }}>
        {title}
      </Typography>
      <Typography className={classes.content}>{content}</Typography>
    </div>
  );
};

export default TextItem;
