import React, { FC, useState } from 'react';
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom';
import useStyles from './styles';
import ExcelIcon from '../../assets/images/excel.png';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import useQueryString from '../../hooks/useQueryString';
import { HeadCell } from '../../types/table';
import HeaderNav from './components/HeaderNav';
import WorkerInfos from './components/WorkerInfos';
import DateInfos from './components/DateInfos';
import InterventionReportDetails from '../InterventionReport/InterventionReportDetails';
import { defaultInterventionReportData } from '../InterventionReport/constants/intervention-report.constants';
import { WorkerData } from '../../types/user';
import { formatDate } from '../../utils/date.utils';
import InterventionReportContainer from '../Establishment/EstablishmentDetails/EstablishmentDetailsContent/container/InterventionReportContainer';
interface WorkerDetailsProps {
  worker: WorkerData;
}

const normalizeFilter = (filters: any): any => {
  return {
    establishmentName:
      filters.establishment === 'ALL' ? '' : filters.establishment,
  };
};

interface WorkerDetailsFilters {
  dateStart: string;
  dateEnd: string;
  beneficiary: string;
}

const INIT_FILTER = {
  dateStart: '',
  dateEnd: '',
  beneficiary: 'ALL',
};

const MOCK = [
  {
    id: 1,
    date: '23/08/2020',
    duration: 15,
    prestation: 'Maintenance',
    beneficiary: 'Rakoto',
  },
  {
    id: 2,
    date: '25/08/2020',
    duration: 23,
    prestation: 'Dépannage',
    beneficiary: 'Rabe',
  },
];

/**
 * WorkerDetails Component
 * @param props WorkerDetailsProps
 */
const WorkerDetails: FC<WorkerDetailsProps & RouteComponentProps> = (props) => {
  const { worker } = props;
  const { lastVisitDate, nextMeetingDate } = worker;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.headerNav}>
        <HeaderNav title="Fiche détaillée de l'intervenant" />
      </Box>
      <Box className={classes.detailsContent}>
        <Paper className={`${classes.paper} ${classes.leftContent}`}>
          <WorkerInfos worker={worker} />
        </Paper>
        {/* <Paper className={`${classes.paper} ${classes.rightContent}`}>
          <DateInfos
            latestVisitDate={formatDate(lastVisitDate)}
            nextMeetingDate={formatDate(nextMeetingDate)}
          />
        </Paper> */}
      </Box>
      <Box>
        <Typography color="primary" className={classes.detailsTitle}>
          Rapport d'intervention
        </Typography>
        <InterventionReportContainer workerUserId={worker.id} />
      </Box>
    </Box>
  );
};

export default withRouter(WorkerDetails);
