import { ApolloClient, ApolloError } from 'apollo-boost';
import { showSnackbar } from '../../../../../../utils/snackbar.util';

export interface OnDeleteUnitLifeCompleteArgs {
  client: ApolloClient<object>;
}

export interface OnDeleteUnitLifeErrorArgs {
  client: ApolloClient<object>;
}

export const onDeleteUnitLifeComplete = ({
  client,
}: OnDeleteUnitLifeCompleteArgs) => () => {
  showSnackbar({
    client,
    message: `Unité de vie supprimée avec succès`,
    type: 'SUCCESS',
  });
};

export const onDeleteUnitLifeError = ({
  client,
}: OnDeleteUnitLifeErrorArgs) => () => {
  showSnackbar({
    client,
    message: `Erreur lors de la suppression de l'unité de vie`,
    type: 'ERROR',
  });
};
