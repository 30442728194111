import { PrivateRouteType } from '../../types/private-route';
import Establishment from '../Establishment';
import Administrator from '../Administrator';
import Beneficiaire from '../Beneficiaire';
import Intervenant from '../Intervenant';
import EstablishmentAdd from '../Establishment/EstablishmentAdd';
import Wrapper from '../Common/Wrapper';
import EstablishmentDetails from '../Establishment/EstablishmentDetails';
import InterventionReport from '../InterventionReport';
import EstablishmentEdit from '../Establishment/EstablishmentAdd/EstablishmentEdit';
import PlanningAdd from '../Establishment/EstablishmentAdmin/Planning/PlanningAdd';
import EstablishmentChildCreate from '../Establishment/EstablishmentDetails/EstablishmentChildCreate';
import EstablishmentAdminRoot from '../Establishment/EstablishmentDetails/EstablishmentAdminRoot';
import EstablishmentChildDetails from '../Establishment/EstablishmentDetails/EstablishmentChildDetails';
import EstablishmentSuperAdminChildDetails from '../Establishment/EstablishmentDetails/EstablishmentSuperAdminChildDetails';
import EstablishmentAdminChildDetails from '../Establishment/EstablishmentDetails/EstablishmentAdminChildDetails';
import Prestation from "../Prestation";

const WrappedAdministrator = Wrapper(Administrator);
const WrappedEstablishment = Wrapper(Establishment);
const WrappedBeneficiary = Wrapper(Beneficiaire);
const WrappedIntervenant = Wrapper(Intervenant);
const WrappedEstablishmentDetails = Wrapper(EstablishmentDetails);
const WrappedInterventionReport = Wrapper(InterventionReport);
const WrappedEstablishmentAdmin = Wrapper(EstablishmentAdminRoot);
const WrappedEstablishmentChildDetails = Wrapper(EstablishmentChildDetails);
const WrappedEstablishmentSuperAdminChildDetails = Wrapper(
  EstablishmentSuperAdminChildDetails,
);
const WrappedEstablishmentAdminChildDetails = Wrapper(
  EstablishmentAdminChildDetails,
);
const WrappedPrestation = Wrapper(Prestation);

export const SUPER_ADMIN_ROUTES: PrivateRouteType[] = [
  {
    exact: true,
    path: '/etablissements',
    component: WrappedEstablishment,
  },
  {
    exact: true,
    path: '/etablissements/ajout',
    component: EstablishmentAdd,
  },
  {
    exact: true,
    path: '/creation-etablissement-fille/:id',
    component: EstablishmentChildCreate,
  },
  {
    exact: true,
    path: '/detail-etablissement-fille/:parentId/:id',
    component: WrappedEstablishmentSuperAdminChildDetails,
  },
  {
    exact: true,
    path: '/etablissements/details/:id',
    component: WrappedEstablishmentDetails,
  },
  {
    exact: true,
    path: '/etablissements/ajout_planning/:id',
    component: PlanningAdd,
  },
  {
    exact: true,
    path: '/etablissements/modifier/:id',
    component: EstablishmentEdit,
  },

  {
    exact: true,
    path: '/administrateurs',
    component: WrappedAdministrator,
  },
  {
    exact: true,
    path: '/beneficiaires',
    component: WrappedBeneficiary,
  },
  {
    exact: true,
    path: '/intervenants',
    component: WrappedIntervenant,
  },
  {
    exact: true,
    path: '/rapports-intervention',
    component: WrappedInterventionReport,
  },
  {
    exact: true,
    path: '/prestations',
    component: WrappedPrestation,
  }
];

export const ADMIN_ROUTES: PrivateRouteType[] = [
  {
    exact: true,
    path: '/creation-etablissement-fille/:id',
    component: EstablishmentChildCreate,
  },
  {
    exact: true,
    path: '/detail-etablissement-fille/:parentId/:id',
    component: WrappedEstablishmentAdminChildDetails,
  },
  {
    exact: true,
    path: '/detail-etablissement-fille/:id',
    component: WrappedEstablishmentChildDetails,
  },
  {
    exact: true,
    path: '/votre-etablissement',
    component: WrappedEstablishmentAdmin,
  },
  {
    exact: true,
    path: '/votre-etablissement/ajout_planning',
    component: PlanningAdd,
  },
  {
    exact: true,
    path: '/etablissements/modifier/:id',
    component: EstablishmentEdit,
  },
  {
    exact: true,
    path: '/beneficiaires',
    component: WrappedBeneficiary,
  },
  {
    exact: true,
    path: '/intervenants',
    component: WrappedIntervenant,
  },
  {
    exact: true,
    path: '/prestations',
    component: WrappedPrestation,
  }
];

export const WORKER_ROUTES: PrivateRouteType[] = [
  {
    exact: true,
    path: '/votre-etablissement',
    component: WrappedEstablishmentAdmin,
  },
  {
    exact: true,
    path: '/votre-etablissement/ajout_planning',
    component: PlanningAdd,
  },
  {
    exact: true,
    path: '/beneficiaires',
    component: WrappedBeneficiary,
  },
  {
    exact: true,
    path: '/intervenants',
    component: WrappedIntervenant,
  },
];

export const getPrivateRoutes: Record<string, PrivateRouteType[]> = {
  SUPER_ADMIN: SUPER_ADMIN_ROUTES,
  ADMIN: ADMIN_ROUTES,
  WORKER: WORKER_ROUTES,
};

export const getEstablishmentLink: Record<string, string> = {
  SUPER_ADMIN: '/etablissements',
  ADMIN: '/votre-etablissement',
  WORKER: '/votre-etablissement',
};
