import React, { FC } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import useStyles from './styles';

interface CustomStepperProps {
  steps: string[];
  activeStep: number;
}

const CustomStepper: FC<CustomStepperProps> = (props) => {
  const { steps, activeStep } = props;
  const classes = useStyles();

  return (
    <Stepper alternativeLabel activeStep={activeStep} className={classes.root}>
      {steps.map((label: string, index: number) => (
        <Step key={index} className={classes.step}>
          <StepLabel className={classes.label}>
            <span dangerouslySetInnerHTML={{ __html: label }} />
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
