import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Header from '../../../Header';
import Stepper from '../../../../../Common/CustomStepper';

interface StepsWrapperProps {
  title: string;
  titles: string[];
  activeStep: number;
}

const StepsWrapper: FC<StepsWrapperProps> = (props) => {
  const { title, children, titles, activeStep } = props;
  return (
    <Box>
      <Header title={title}>
        <Stepper steps={titles} activeStep={activeStep} />
      </Header>
      {children}
    </Box>
  );
};

export default StepsWrapper;
