import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    excelIcon: {
      width: 24,
      height: 24,
    },
  }),
);

export default useStyles;
