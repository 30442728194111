import React, { FC } from 'react';
import { PrivateRouteType } from '../../../types/private-route';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

interface PrivateRoutesProps {
  routes: PrivateRouteType[];
}

const PrivateRoutes: FC<PrivateRoutesProps> = ({ routes }) => (
  <>
    {routes.map((route: PrivateRouteType, index: number) => (
      <PrivateRoute
        key={index}
        exact={route.exact}
        {...route}
        component={route.component}
      />
    ))}
  </>
);

export default PrivateRoutes;
