import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-boost';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';

import { displaySnackbar } from '../../../common/snackbar';
import { isAuthenticated } from '../../../services/localStorage';
import { SnackbarType } from '../../../types';
import logo from '../../../assets/images/logo-neoptim.png';
import { RESET_PASSWORD } from '../../../graphql/user/mutation';
import {
  ResetPassword as ResetPasswordArgs,
  ResetPasswordVariables,
} from '../../../graphql/user/types/ResetPassword';
import useStyles from './styles';
import { INIT_PASSWORD, PasswordInputState } from '../../../types/password';

interface ResetPasswordProps {}

/**
 * ResetPassword Component
 * @param props ResetPasswordProps
 */
const ResetPassword = (props: ResetPasswordProps & RouteComponentProps) => {
  const { history } = props;
  const classes = useStyles();
  const { userId, token } = useParams();
  const client = useApolloClient();
  const [error, setError] = useState<boolean>(false);
  const [matched, setMatched] = useState<boolean>(false);

  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const [passwords, setPasswords] = useState<PasswordInputState>(INIT_PASSWORD);

  const [resetPassword, { loading }] = useMutation<
    ResetPasswordArgs,
    ResetPasswordVariables
  >(RESET_PASSWORD, {
    onCompleted: (_: any) => {},
    onError: (error: ApolloError) => {
      if (
        error.graphQLErrors &&
        error.graphQLErrors[0] &&
        error.graphQLErrors[0].message &&
        error.graphQLErrors[0].message.includes('invalid signature')
      ) {
        snackbarData.type = 'WARNING';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Le lien de récupération de mot de passe a été expiré.';
        displaySnackbar(client, snackbarData);
      } else {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Une erreur est survenue lors de la récupération de mot de passe.';
        displaySnackbar(client, snackbarData);
      }
    },
  });

  const isValid = (): boolean => {
    return (
      passwords.password !== '' &&
      passwords.password.length >= 8 &&
      passwords.passwordConfirm !== '' &&
      passwords.password === passwords.passwordConfirm
    );
  };

  useEffect(() => {
    setMatched(isValid());
    setError(!!(passwords.password && passwords.password.trim().length < 8));
  }, [passwords]);

  if (isAuthenticated()) {
    return <Redirect {...{ to: '/' }} />;
  }

  const handleSavePassword = async (
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();

    if (isValid()) {
      const res = await resetPassword({
        variables: {
          userId: Number(userId),
          token,
          password: passwords.password,
        },
      });

      if (res && res.data && res.data.resetPassword) {
        history.push('/reset-password-success');
      }
    } else {
      snackbarData.type = 'WARNING';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        passwords.password === '' && passwords.passwordConfirm === ''
          ? 'Veuillez remplir tous les champs.'
          : passwords.password.length < 8
          ? 'Le mot de passe doit contenir au moins 8 caractères au minimum'
          : 'Les deux mots de passe ne correspondent pas.';
      displaySnackbar(client, snackbarData);
    }
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setPasswords((prevState) => ({
      ...prevState,
      [name]: value.trim(),
    }));
  };

  const isNotMatched = (): boolean => {
    return (
      passwords.password !== '' &&
      passwords.passwordConfirm !== '' &&
      passwords.password !== passwords.passwordConfirm
    );
  };

  const showError = matched ? !matched : isNotMatched();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.wrapForm}>
          <Box className={classes.logo}>
            <img src={logo} alt="Neoptim Logo" />
          </Box>
          <Box className={classes.title}>
            <Typography
              variant="h4"
              component="h1"
              className={classes.textTitle}
            >
              Réinitialisation
            </Typography>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              className={classes.descTitle}
            >
              Entrez un nouveau mot de passe.
            </Typography>
          </Box>
          <form
            onSubmit={handleSavePassword}
            className={classes.form}
            autoComplete="off"
          >
            <TextField
              label="Nouveau mot de passe"
              type="password"
              name="password"
              onChange={handleTextChange}
              variant="outlined"
              margin="normal"
              fullWidth
              error={error}
              helperText="Votre mot de passe doit contenir au moins 8 caractères au minimum."
              className={classes.passwordField}
            />
            <TextField
              label="Confirmer mot de passe"
              type="password"
              name="passwordConfirm"
              onChange={handleTextChange}
              variant="outlined"
              margin="normal"
              fullWidth
              error={showError}
              helperText={
                showError ? 'Les deux mots de passe ne correspondent pas.' : ''
              }
              className={showError ? classes.passwordField : classes.textField}
            />
            <div className={classes.buttonWrapper}>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
                className={classes.button}
                fullWidth
              >
                Valider
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.lodingProgress}
                />
              )}
            </div>
            <Box className={classes.actions}>
              <Link
                className={classes.link}
                color="secondary"
                onClick={() => history.push('/authentification')}
              >
                Revenir à la connexion
              </Link>
            </Box>
          </form>
        </Box>
        <Typography
          variant="body2"
          component="footer"
          color="primary"
          align="center"
          className={classes.footer}
        >
          &copy; Copyright - Neoptim consulting
        </Typography>
      </Box>
    </Box>
  );
};

export default withRouter(ResetPassword);
