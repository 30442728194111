import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    flowRow: {
      flexFlow: 'row',
      marginBottom: theme.spacing(0),
      paddingLeft: 0,
      paddingRight: 6,
      justifyContent: 'space-between',

      '& .MuiBox-root': {
        marginBottom: 0,
      },

      [theme.breakpoints.down(620)]: {
        '& .MuiBox-root': {
          marginBottom: theme.spacing(2),
        },

        flexFlow: 'wrap',
        marginBottom: theme.spacing(2),
      },
    },
    button: {
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    whiteButton: {
      '& .MuiButton-label': {
        color: theme.palette.common.white,
      }
    },
    white: {
      color: theme.palette.common.white,
    },
    tableContainer: {
      margin: 25,
    },
    flowRowBenefList: {
      flexFlow: 'row',
      marginBottom: theme.spacing(0),
      paddingLeft: 0,
      paddingRight: 6,
      justifyContent: 'space-between',

      '& .MuiBox-root': {
        marginBottom: 0,
      },
    },
  }),
);

export default useStyles;
