import React, { createContext } from 'react';

export type UserContextRole = 'SUPER_ADMIN' | 'ADMIN' | '';
export interface UserContextType {
  userRole: UserContextRole;
  userId: number;
}

const user: UserContextType = {
  userRole: '',
  userId: 0,
};

const UserContext = createContext(user);

export default UserContext;
