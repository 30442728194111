import React, { FC } from 'react';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';

interface CustomAppointmentProps extends Appointments.AppointmentProps {
  style?: any;
  [key: string]: any;
}

const CustomAppointment: FC<CustomAppointmentProps> = (props) => {
  const { children, style, ...rest } = props;

  return (
    <Appointments.Appointment
      {...rest}
      style={{
        ...style,
        backgroundColor: (rest.data && rest.data.color) || '#002B67',
        borderRadius: '4px',
      }}
    >
      {children}
    </Appointments.Appointment>
  );
};

export default CustomAppointment;
