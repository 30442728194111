import { User, UserEditDatas } from '../../../../../../types/user';
import { ApolloClient } from 'apollo-boost';
import {
  WriteUserEditCacheArgs,
  ReadUserEditCacheArgs,
  WriteDeleteUserToCacheArgs,
  WriteDeleteUsersToCacheArgs,
  ReadDeletedUsersFromCacheArgs,
  WriteNewUsersToCacheArgs,
} from '../interfaces/UserEditList.interfaces';
import { GET_USER_EDIT_DATAS } from '../../../../../../graphql/common/etablishment/query';
import { EMPTY_USER_EDIT_DATAS } from '../../../../../../constants/user';

export const filterUsers = (users: User[], idsToExclude: number[]) => {
  return users.filter((user: User) => !idsToExclude.includes(user.id));
};

export const writeUserEditCache = ({
  client,
  data,
}: WriteUserEditCacheArgs) => {
  const previousDatas: UserEditDatas = readUserEditCache({ client });
  client.writeQuery({
    query: GET_USER_EDIT_DATAS,
    data: {
      ...previousDatas,
      ...data,
    },
  });
};

export const writeNewUsersToCache = ({
  client,
  userRole,
  ids,
}: WriteNewUsersToCacheArgs) => {
  const previousUsersEditDatas: UserEditDatas = readUserEditCache({ client });
  const newUsersIds: number[] = [
    ...previousUsersEditDatas[userRole].newUsersIds,
    ...ids,
  ];
  client.writeQuery({
    query: GET_USER_EDIT_DATAS,
    data: {
      userEditDatas: {
        ...previousUsersEditDatas,
        [userRole]: {
          ...previousUsersEditDatas[userRole],
          newUsersIds,
        },
      },
    },
  });
};

export const writeDeleteUsersToCache = ({
  client,
  userRole,
  ids,
}: WriteDeleteUsersToCacheArgs) => {
  const previousUsersEditDatas: UserEditDatas = readUserEditCache({ client });
  const deletedUsersIds: number[] = [
    ...previousUsersEditDatas[userRole].deletedUsersIds,
    ...ids,
  ];
  const userEditDatas: UserEditDatas = {
    ...previousUsersEditDatas,
    [userRole]: {
      ...previousUsersEditDatas[userRole],
      deletedUsersIds,
    },
  };
  client.writeQuery({
    query: GET_USER_EDIT_DATAS,
    data: {
      userEditDatas,
    },
  });
};

export const writeDeleteUserToCache = ({
  client,
  userRole,
  id,
}: WriteDeleteUserToCacheArgs) => {
  writeDeleteUsersToCache({ client, userRole, ids: [id] });
};

export const readDeletedUsersFromCache = ({
  client,
  userRole,
}: ReadDeletedUsersFromCacheArgs): number[] =>
  readUserEditCache({ client })[userRole].deletedUsersIds;

export const readUserEditCache = ({
  client,
}: ReadUserEditCacheArgs): UserEditDatas =>
  client.readQuery({
    query: GET_USER_EDIT_DATAS,
  }).userEditDatas;

export const resetUserEditCache = (client: ApolloClient<object>) => {
  client.writeQuery({
    query: GET_USER_EDIT_DATAS,
    data: {
      userEditDatas: EMPTY_USER_EDIT_DATAS,
    },
  });
};
