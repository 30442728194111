import { useReducer } from 'react';
import { ETABLISHMENT_DATA } from '../../../../../../constants/etablishement';
import { Action } from '../../../../../../types/action';
import {
  EstablishmentDataType,
  EstablishmentNewEditStep,
  EstablishmentType,
  EtablishmentDataType,
} from '../../../../../../types/establishment';
import { UserContextRole } from '../../../../../Context/UserContext';
import { composeSteps } from '../../Steps/stepsComposer';

export interface ActionButtonState {
  disabled: boolean;
  displayed: boolean;
}

export interface ActionButtonsState {
  previous: ActionButtonState;
  next: ActionButtonState;
  finalize: ActionButtonState;
}

export interface EstablishmentEditorState {
  steps: EstablishmentNewEditStep[];
  establishment: EtablishmentDataType;
  activeStep: number;
  actionButtonState: ActionButtonsState;
}

export type EstablishmentEditorActionButtons = keyof ActionButtonsState;

const INITIAL_ACTION_BUTTONS_STATE: ActionButtonsState = {
  next: {
    disabled: true,
    displayed: true,
  },
  previous: {
    disabled: true,
    displayed: false,
  },
  finalize: {
    disabled: true,
    displayed: false,
  },
};

const ESTABLISHMENT_CHILD_INITIAL_BUTTON_STATE: ActionButtonsState = {
  ...INITIAL_ACTION_BUTTONS_STATE,
  next: {
    disabled: false,
    displayed: false,
  },
};

export const INITIAL_ESTABLISHMENT_EDITOR_STATE: EstablishmentEditorState = {
  steps: [],
  establishment: ETABLISHMENT_DATA,
  activeStep: 0,
  actionButtonState: INITIAL_ACTION_BUTTONS_STATE,
};

export class SetEstablishmentEditSteps
  implements Action<EstablishmentEditorState> {
  constructor(
    public payload: {
      establishmentType: EstablishmentType;
      userContextRole: UserContextRole;
    },
  ) {}
  reducer(state: EstablishmentEditorState): EstablishmentEditorState {
    return {
      ...state,
      steps: composeSteps(
        this.payload.establishmentType,
        this.payload.userContextRole,
      ),
    };
  }
}

const isResidenceChildStepOne = (
  step: number,
  establishmentType?: EstablishmentType,
) => establishmentType && establishmentType === 'RESIDENCE_CHILD' && step === 1;

const actionButtonStateOnChangeStep = (
  state: ActionButtonsState,
  {
    newActiveStep,
    stepsLength,
    establishment,
  }: {
    newActiveStep: number;
    stepsLength: number;

    establishment: EstablishmentDataType;
  },
  disabled: boolean,
): ActionButtonsState => {
  const previousDisplay: boolean = isResidenceChildStepOne(
    newActiveStep,
    establishment.establishmentType,
  )
    ? false
    : newActiveStep !== 0;
  return {
    next: {
      disabled,
      displayed: newActiveStep !== stepsLength - 1,
    },
    previous: {
      disabled,
      displayed: previousDisplay,
    },
    finalize: {
      disabled,
      displayed: newActiveStep === stepsLength - 1,
    },
  };
};

export class GoBack implements Action<EstablishmentEditorState> {
  reducer(state: EstablishmentEditorState): EstablishmentEditorState {
    const newActiveStep: number = state.activeStep - 1;
    const currentStep = state.steps[newActiveStep];
    const disabled = !currentStep.stepDatasValidator(state.establishment);
    return {
      ...state,
      activeStep: newActiveStep,
      actionButtonState: actionButtonStateOnChangeStep(
        state.actionButtonState,
        {
          newActiveStep,
          stepsLength: state.steps.length,
          establishment: state.establishment,
        },
        disabled,
      ),
    };
  }
}

export class GoNext implements Action<EstablishmentEditorState> {
  reducer(state: EstablishmentEditorState): EstablishmentEditorState {
    const newActiveStep: number = state.activeStep + 1;
    const currentStep = state.steps[newActiveStep];
    const disabled = !currentStep.stepDatasValidator(state.establishment);
    return {
      ...state,
      activeStep: newActiveStep,
      actionButtonState: actionButtonStateOnChangeStep(
        state.actionButtonState,
        {
          newActiveStep,
          stepsLength: state.steps.length,
          establishment: state.establishment,
        },
        disabled,
      ),
    };
  }
}

export class SetEstablishment implements Action<EstablishmentEditorState> {
  constructor(public payload: EtablishmentDataType) {}
  reducer(state: EstablishmentEditorState): EstablishmentEditorState {
    const currentStep = state.steps[state.activeStep];
    const disabled = !currentStep.stepDatasValidator(this.payload);
    const actionButtonState = state.actionButtonState;
    return {
      ...state,
      establishment: this.payload,
      actionButtonState: {
        finalize: {
          ...actionButtonState.finalize,
          disabled,
        },
        next: {
          ...actionButtonState.next,
          disabled,
        },
        previous: {
          ...actionButtonState.previous,
          disabled,
        },
      },
    };
  }
}

export class SetButtonDisabled implements Action<EstablishmentEditorState> {
  constructor(
    public payload: {
      disabled: boolean;
      button: EstablishmentEditorActionButtons;
    },
  ) {}
  reducer(state: EstablishmentEditorState): EstablishmentEditorState {
    return {
      ...state,
      actionButtonState: {
        ...state.actionButtonState,
        [this.payload.button]: {
          ...state.actionButtonState[this.payload.button],
          disabled: this.payload.disabled,
        },
      },
    };
  }
}

export type EstablishmentEditorAction =
  | SetEstablishmentEditSteps
  | GoBack
  | GoNext
  | SetEstablishment
  | SetButtonDisabled;

const reducer = (
  state: EstablishmentEditorState,
  action: EstablishmentEditorAction,
): EstablishmentEditorState => action.reducer(state);

const knownChildEstablishmentTypes: EstablishmentType[] = [
  'RESIDENCE',
  'DOMICILE',
];

const residenceChildInitialState = (
  partialState: Partial<EstablishmentEditorState>,
): Partial<EstablishmentEditorState> => ({
  ...partialState,
  actionButtonState: ESTABLISHMENT_CHILD_INITIAL_BUTTON_STATE,
});

const otherChildInitialState = (
  partialState: Partial<EstablishmentEditorState>,
  establishment: EtablishmentDataType | undefined,
): Partial<EstablishmentEditorState> => {
  const activeStep = partialState?.activeStep || 0;
  const steps = partialState?.steps;
  return {
    ...partialState,
    actionButtonState: {
      ...ESTABLISHMENT_CHILD_INITIAL_BUTTON_STATE,
      next: {
        displayed: true,
        disabled:
          !!establishment &&
          !!steps &&
          !steps[activeStep].stepDatasValidator(establishment),
      },
    },
  };
};

const initialStateFromPartial = (
  partialInitialState?: Partial<EstablishmentEditorState>,
): Partial<EstablishmentEditorState> => {
  let partialState = partialInitialState || {};
  const establishment = partialState.establishment;
  const establishmentType: EstablishmentType | undefined =
    establishment?.establishmentType;

  if (establishmentType === 'RESIDENCE_CHILD') {
    partialState = residenceChildInitialState(partialState);
  } else if (
    establishmentType &&
    knownChildEstablishmentTypes.includes(establishmentType)
  ) {
    partialState = otherChildInitialState(partialState, establishment);
  }
  return partialState;
};

export const useEstablishmentEditorReducer = (
  partialInitialState?: Partial<EstablishmentEditorState>,
) => {
  const partialState = initialStateFromPartial(partialInitialState);
  return useReducer(reducer, {
    ...INITIAL_ESTABLISHMENT_EDITOR_STATE,
    ...partialState,
  });
};
