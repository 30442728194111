import React, { FC, useState, useContext, useEffect } from 'react';
import {
  withRouter,
  RouteComponentProps,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import useStyles from './styles';
import CustomFullscreenDialog from '../../../../../../Common/CustomFullscreenDialog';
import CustomTable from '../../../../../../Common/CustomTable';
import { HeadCell } from '../../../../../../../types/table';
import { FilterControlType } from '../../../../../../../types';
import SearchInput from '../../../../../../Common/SearchInput';
import SearchFilter from '../../../../../../Common/SearchFilter';
import UserContext from '../../../../../../Context/UserContext';
import { GET_ETABLISHMENT_WORKERS } from '../../../../../../../graphql/etablishment/query';
import { GET_WORKER_IN_ETABLISHMENT } from '../../../../../../../graphql/worker/query';
import {
  EtablishmentWorkers,
  EtablishmentWorkersVariables,
  EtablishmentWorkers_etablishment_workers,
} from '../../../../../../../graphql/etablishment/types/EtablishmentWorkers';
import {
  WorkerInEtablishment,
  WorkerInEtablishmentVariables,
  WorkerInEtablishment_workerInEtablishment,
} from '../../../../../../../graphql/worker/types/WorkerInEtablishment';
import { GET_PLANNING_DATA } from '../../../../../../../graphql/common/planning/query';
import { SnackbarType } from '../../../../../../../types/snackbar';
import { displaySnackbar } from '../../../../../../../common/snackbar';
import { EstablishmentContext } from '../../../../../../Context';
import { PlanningDataType } from '../../../../../../../types/planning';

interface WorkerChoiceListProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  inputData: PlanningDataType;
  setInputData: React.Dispatch<React.SetStateAction<any>>;
}

interface WorkerListFilter {
  enabled: number;
  occupation: number | string;
  contractType: number | string;
}

const INIT_FILTER: WorkerListFilter = {
  enabled: -1,
  occupation: '',
  contractType: 'ALL',
};

const WorkerChoiceList: FC<WorkerChoiceListProps & RouteComponentProps> = (
  props,
) => {
  const classes = useStyles();
  const location = useLocation();
  const client = useApolloClient();
  const userContext = useContext(UserContext);
  const { history, open, setOpen, inputData, setInputData } = props;
  const [filter, setFilter] = useState<WorkerListFilter>(INIT_FILTER);
  const [searchKey, setSearchKey] = useState<string>('');
  const [workers, setWorkers] = useState<
    (WorkerInEtablishment_workerInEtablishment | null)[]
  >([]);
  const [selectedItem, setSelectedItems] = useState<number[]>([]);
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };
  const { establishmentType } = useContext(EstablishmentContext);

  const INPUT_FILTERS: FilterControlType[] = [
    {
      label: 'Statut',
      name: 'enabled',
      value: -1,
      placeholder: 'Tous les statuts',
      type: 'select',
      options: [
        {
          label: 'Tous les statuts',
          value: -1,
          all: true,
        },
        {
          label: 'Activé',
          value: 1,
        },
        {
          label: 'Désactivé',
          value: 0,
        },
      ],
    },
    {
      label: 'Fonction',
      name: 'occupation',
      value: '',
      placeholder: 'Tapez une fonction',
      type: 'text',
    },
  ];

  const planningData = client.readQuery({
    query: GET_PLANNING_DATA,
  });

  // const { loading, data } = useQuery<
  //   EtablishmentWorkers,
  //   EtablishmentWorkersVariables
  // >(GET_ETABLISHMENT_WORKERS, {
  //   variables: {
  //     id:
  //       (planningData &&
  //         planningData.planningData &&
  //         planningData.planningData.establishmentId) ||
  //       0,
  //   },
  // });

  let filters = {
    name: searchKey.trim() || '',
  };

  const inputFilters = {
    input: {
      filter: JSON.stringify({
        ...filters,
        ...filter,
      }),
    },
  };

  const { id }: any = useParams();
  const { loading, data } = useQuery<
    WorkerInEtablishment,
    WorkerInEtablishmentVariables
  >(GET_WORKER_IN_ETABLISHMENT, {
    variables: {
      etablishmentId:
        +id ||
        (planningData &&
          planningData.planningData &&
          planningData.planningData.establishmentId) ||
        0,
      filterWorker: inputFilters.input,
    },
  });

  useEffect(() => {
    if (data && data.workerInEtablishment) {
      setWorkers(data.workerInEtablishment);
    }
  }, [data]);

  const handleGoBack = () => {
    history.push(`${location.pathname}${location.search}`);
    setOpen(false);
  };

  const handleSubmitSelection = () => {
    let mergedWorkers: number[] = [];
    if (selectedItem.length != 0) {
      const planningData = client.readQuery({
        query: GET_PLANNING_DATA,
      });

      let planningWorkers = planningData.planningData.workers;

      if (planningWorkers.length !== 0) {
        mergedWorkers = planningWorkers.concat(selectedItem);
      } else {
        mergedWorkers = selectedItem;
      }

      client.writeQuery({
        query: GET_PLANNING_DATA,
        data: {
          planningData: {
            ...planningData.planningData,
            workers: mergedWorkers,
          },
        },
      });
      setInputData((prevState: any) => ({
        ...prevState,
        workers: mergedWorkers,
      }));
      setSelectedItems([]);
      history.push(`${location.pathname}${location.search}`);
      setOpen(false);
    } else {
      snackbarData.type = 'INFO';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'Veuillez sélectionner au moins un intervenant';
      displaySnackbar(client, snackbarData);
    }
  };

  const onHandleSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKey(value);
  };

  const columns: HeadCell[] = [
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.lastName) || '-';
      },
    },
    {
      name: 'lastName',
      label: 'Prénom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.firstName) || '-';
      },
    },
    {
      name: 'function',
      label: 'Fonction',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.userFunction) || '-';
      },
    },
    {
      name: 'mail',
      label: 'Mail',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.email) || '-';
      },
    },
    {
      name: 'mobilePhone',
      label: 'Tél mobile',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.mobilePhone) || '-';
      },
    },
    {
      name: 'phone',
      label: 'Tél fixe',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.phone) || '-';
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => {
        return value && value.user && value.user.enabled
          ? 'Activé'
          : 'Désactivé';
      },
    },
  ];

  const toolbarActionContent = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="submit-selection"
      onClick={handleSubmitSelection}
    >
      Valider ma sélection
    </Button>,
  ];

  return (
    <CustomFullscreenDialog
      open={open}
      handleGoBack={handleGoBack}
      title="Sélection d'intervenant"
    >
      <Box display="flex" justifyContent="center">
        <Row className={classes.row}>
          <Col xl={4} lg={4} md={12} xs={12}>
            <Paper className={classes.paper}>
              <SearchInput
                onChange={(e) => onHandleSearchKey(e)}
                value={searchKey}
                placeholder="Recherchez un intervenant"
                classNames={classes.searchInput}
              />
            </Paper>
          </Col>
          <Col xl={6} lg={6} md={12} xs={12}>
            <Paper className={classes.paper}>
              <Typography className={classes.labelFilter}>
                Filtre de recherche :
              </Typography>
              <SearchFilter
                filterControls={INPUT_FILTERS}
                filter={filter}
                setFilter={setFilter}
                isSearchable
                overrideCols={{ xs: 12, sm: 12, lg: 6, md: 6, xl: 6 }}
              />
            </Paper>
          </Col>
        </Row>
      </Box>
      <CustomTable
        toolbar
        selectable
        hideSelectedText
        maxWidth={1640}
        selectedItems={selectedItem}
        setSelectedItems={setSelectedItems}
        data={workers}
        columns={columns}
        loadingData={loading}
        useFlexToolbar
        toolbarProps={{ toolbarTitle: 'Liste des intervenants' }}
        toolbarActionContent={toolbarActionContent}
        toolbarStyles={{ width: '165px' }}
        isLeftContent={true}
        toolbarClasses={classes.flowRow}
      />
    </CustomFullscreenDialog>
  );
};

export default withRouter(WorkerChoiceList);
