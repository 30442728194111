import React, { FC } from 'react';
import { ApolloError } from 'apollo-boost';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useMutation, useApolloClient } from '@apollo/react-hooks';

import useStyles from './styles';
import { SnackbarType } from '../../../../types';
import { ExtendedEstablishmentInfoData } from '../types';
import { displaySnackbar } from '../../../../common';
import mockLogo from '../../../../assets/images/mock-logo.png';
import { ESTABLISHMENT_TYPE } from '../../../../types/establishment';
import { UPDATE_ESTABLISHMENT } from '../../../../graphql/etablishment/mutation';
import { UpdateEstablishment } from '../../../../graphql/etablishment/types/UpdateEstablishment';
import { UpdateEstablishmentVariables } from '../../../../graphql/etablishment/types/UpdateEstablishment';

interface EstablishmentDetailsInfosProps {
  id: number;
  data: ExtendedEstablishmentInfoData;
  userRole?: string;
  setData?: (value: any) => void;
}

const INIT_SNACKBAR: SnackbarType = {
  type: 'ERROR',
  message: '',
  isOpen: true,
};

const EstablishmentDetailsInfos: FC<
  EstablishmentDetailsInfosProps & RouteComponentProps
> = (props) => {
  const classes = useStyles();
  const { data, id, history, userRole, setData } = props;
  const client = useApolloClient();
  const snackbarData = INIT_SNACKBAR;

  const [updateStatus] = useMutation<
    UpdateEstablishment,
    UpdateEstablishmentVariables
  >(UPDATE_ESTABLISHMENT, {
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour de l'établissement";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
    onCompleted: (data: UpdateEstablishment) => {
      if (data && data.updateEtablishment) {
        const statut = data.updateEtablishment.enabled ? 'activé' : 'désactivé';
        updateData(data);
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `L'établissement a été ${statut}`;
        displaySnackbar(client, snackbarData);
      }
    },
  });

  const updateData = (newData: UpdateEstablishment) => {
    if (setData && newData && newData.updateEtablishment) {
      setData({ ...data, enabled: newData.updateEtablishment.enabled });
    }
  };

  const GET_STATUS: Record<any, string> = {
    HOSPITAL: 'Hôpital',
    ENTERPRISE: 'Entreprise',
    ASSOCIATION: 'Association',
    COLLECTIVITY: 'Collectivité',
  };

  const handleChangeStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    updateStatus({
      variables: {
        input: {
          id,
          enabled: checked,
        },
      },
    });
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.profileDetails}>
        <Box className={classes.imageContainer}>
          <img src={data.logo || mockLogo} alt="etablissement-logo" />
        </Box>
        <div
          style={{
            lineHeight: '1.5em',
            height: '3.6em',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <Typography component="h3">{data.name}</Typography>
        </div>
        <Typography component="label" htmlFor="status">
          {data.enabled ? 'Activé' : 'Désactivé'}
        </Typography>
        <Switch
          color="primary"
          name="status"
          checked={data.enabled}
          disabled={userRole && userRole == 'WORKER' ? true : false}
          onChange={handleChangeStatus}
        />
      </Box>
      <Typography component="hr" />
      <Box className={classes.fieldContainer}>
        <Box className={classes.field}>
          <Typography component="h4" title={ESTABLISHMENT_TYPE[data.type]}>
            Type :
          </Typography>
          <Typography noWrap component="p">
            {ESTABLISHMENT_TYPE[data.type]}
          </Typography>
        </Box>
        {data.residences && (
          <Box className={classes.field}>
            <Typography component="h4">Organisation :</Typography>
            <Typography noWrap component="p">
              {data.residences.name && data.residences.id ? (
                userRole && userRole == 'SUPER_ADMIN' ? (
                  <Link
                    href={`/etablissements/details/${data.residences.id}?tab=0`}
                    className={classes.link}
                  >
                    {data.residences.name}
                  </Link>
                ) : (
                  <>{data.residences.name}</>
                )
              ) : (
                '-'
              )}
            </Typography>
          </Box>
        )}
        <Box className={classes.field}>
          <Typography component="h4">Siren/Siret :</Typography>
          <Typography noWrap component="p" title={data.rcs}>
            {data.rcs}
          </Typography>
        </Box>
        <Box className={classes.field}>
          <Typography component="h4">Ville :</Typography>
          <Typography
            noWrap
            component="p"
            title={`${data.ville || (data.residences && data.residences.city)}`}
          >
            {data.ville || (data.residences && data.residences.city)}
          </Typography>
        </Box>
        <Box className={classes.field}>
          <Typography component="h4">Adresse :</Typography>
          <Typography noWrap component="p" title={data.address}>
            {data.address}
          </Typography>
        </Box>
        <Box className={classes.field}>
          <Typography component="h4">Code postal :</Typography>
          <Typography
            noWrap
            component="p"
            title={`${
              data.codePostal || (data.residences && data.residences.postalCode)
            }`}
          >
            {data.codePostal || (data.residences && data.residences.postalCode)}
          </Typography>
        </Box>
        <Box className={classes.field}>
          <Typography component="h4">Objet :</Typography>
          <Typography noWrap component="p" title={data.objet}>
            {data.objet}
          </Typography>
        </Box>
        <Box className={classes.field}>
          <Typography component="h4">Statut :</Typography>
          <Typography noWrap component="p">
            {GET_STATUS[data.status]}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(EstablishmentDetailsInfos);
