import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import useStyles from './styles';
import { PlanningDataType } from '../../../../../../../types/planning';
import { PeriodicityTypeEnum } from '../../../../../../../graphql/types/graphql-global-types';

interface BasicInformationsProps {
  inputData: PlanningDataType;
  setInputData: React.Dispatch<React.SetStateAction<any>>;
}

const PeriodicityQuot: FC<BasicInformationsProps> = (props) => {
  const classes = useStyles();
  const { inputData, setInputData } = props;
  const [values, setValues] = useState<string>('forDay');

  useEffect(() => {
    setInputData((prevState: any) => ({
      ...prevState,
      periodicityType: PeriodicityTypeEnum.DAILY,
    }));
  }, []);

  const onHandleChange = (
    event: React.ChangeEvent<
      | HTMLInputElement
      | {
          name?: string | undefined;
          value: unknown;
        }
    >,
  ) => {
    const { name, value } = event.target;

    if (name) {
      setInputData((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues((event.target as HTMLInputElement).value);
    if (values === 'forDay') {
      setInputData((prevState: any) => ({
        ...prevState,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false,
      }));
    } else {
      setInputData((prevState: any) => ({
        ...prevState,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      }));
    }
  };

  return (
    <Box className={classes.root}>
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={values}
        onChange={handleChange}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FormControlLabel
            value="forDay"
            control={<Radio color="primary" />}
            label="Tous les"
            style={{ margin: 0 }}
          />
          <TextField
            id="day-number"
            type="number"
            placeholder="0"
            name="periodicityRepetition"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ margin: '0 8px' }}
            inputProps={{ min: '1', max: '100' }}
            variant="outlined"
            size="small"
            value={inputData.periodicityRepetition}
            onChange={onHandleChange}
          />
          <p style={{ margin: 0 }}>jour(s)</p>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FormControlLabel
            value="everyDay"
            control={<Radio color="primary" />}
            label="Tous les jours ouvrables"
            style={{ margin: 0 }}
          />
        </Box>
      </RadioGroup>
    </Box>
  );
};

export default PeriodicityQuot;
