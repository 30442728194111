import React, { FC, useState } from 'react';
import useStyles from './styles';
import {
  EstablishmentDataType,
  EtablishmentDataType,
} from '../../../../../types/establishment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

interface ProposedPrestationsProps {
  inputData: EstablishmentDataType;
  setInputData: (data: EstablishmentDataType) => void;
}

const ProposedPrestations: FC<ProposedPrestationsProps> = (props) => {
  const { inputData, setInputData } = props;
  const classes = useStyles();
  const [showInput, setShowInput] = useState<boolean>(false);
  const [currentPrestation, setCurrentPrestation] = useState<string>('');

  const onDeleteItem = (key: string) => {
    const prestations = inputData.prestations.filter(
      (item: string) => item !== key,
    );
    setInputData({
      ...inputData,
      prestations,
    });
  };

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCurrentPrestation(value);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.charCode === 13 && currentPrestation.trim()) {
      const index = inputData.prestations.findIndex(
        (item) => item === currentPrestation,
      );

      if (index === -1) {
        setInputData({
          ...inputData,
          prestations: [...inputData.prestations, currentPrestation],
        });
        onRemoveInput();
      }
    }
  };

  const onRemoveInput = () => {
    setCurrentPrestation('');
    setShowInput(false);
  };

  return (
    <>
      {inputData.prestations.length > 0 && (
        <List
          dense={false}
          className={showInput ? classes.listShowInput : classes.listHideInput}
        >
          {inputData.prestations.map((item: string) => (
            <ListItem divider key={item}>
              <ListItemText primary={item} className={classes.itemText} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => onDeleteItem(item)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      {showInput && (
        <>
          <OutlinedInput
            id="outlined-adornment-input"
            type="text"
            placeholder="Ex: Maintenance, ménage..."
            fullWidth
            autoComplete="off"
            value={currentPrestation}
            onChange={onTextChange}
            onKeyPress={onKeyPress}
            className={classes.dynamicInput}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end" onClick={onRemoveInput}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <Divider />
        </>
      )}
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        fullWidth
        className={classes.dashedButton}
        onClick={() => setShowInput(true)}
      >
        Ajouter une prestation
      </Button>
    </>
  );
};

export default ProposedPrestations;
