import React, { FC, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FilterText from './FilterText';
import FilterSelect from './FilterSelect';
import FilterDate from './FilterDate';
import { FilterControlType } from '../../../types';

interface FilterType {
  [key: string]: any;
}

interface SearchFilterProps {
  filterControls: FilterControlType[];
  filter: FilterType;
  overrideCols?: any;
  isSearchable?: boolean;
  setFilter: (filter: FilterType | any) => void;
}

const SearchFilter: FC<SearchFilterProps> = (props) => {
  const {
    filterControls,
    filter,
    setFilter,
    overrideCols,
    isSearchable,
  } = props;
  const [property, setProperty] = useState<string>('');
  const [autoCompleteValue, setAutoCompleteValue] = useState<string>('');
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState<string>(
    '',
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFilter((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChangeAutoComplete = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
  ) => {
    setAutoCompleteValue(newValue);
  };

  const onInputChangeAutoComplete = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    name: any,
  ) => {
    setProperty(name);
    setAutoCompleteInputValue(newValue);
  };

  useEffect(() => {
    if (property) {
      setFilter((prevState: any) => ({
        ...prevState,
        [property]: autoCompleteInputValue,
      }));
    }
  }, [autoCompleteInputValue]);

  return (
    <Box>
      <Row>
        {filterControls.length === 1 ? (
          <Col key={filterControls[0].name}>
            {filterControls[0].type === 'select' ? (
              <FilterSelect
                {...filterControls[0]}
                value={filter[filterControls[0].name]}
                onChange={handleChange}
                isSearchable={isSearchable}
                autoCompleteValue={autoCompleteValue}
                autoCompleteInputValue={autoCompleteInputValue}
                onChangeAutoComplete={onChangeAutoComplete}
                onInputChangeAutoComplete={onInputChangeAutoComplete}
              />
            ) : (
              <FilterText
                {...filterControls[0]}
                value={filter[filterControls[0].name]}
                onChange={handleChange}
              />
            )}
          </Col>
        ) : (
          filterControls.map(
            (filterControl: FilterControlType, index: number) => (
              <Col
                key={`${filterControl.name}-${index}`}
                {...(overrideCols || { xs: 12, xl: 3, lg: 3, md: 6, sm: 6 })}
              >
                {filterControl.type === 'select' ? (
                  <FilterSelect
                    {...filterControl}
                    value={filter[filterControl.name]}
                    onChange={handleChange}
                    isSearchable={isSearchable}
                  />
                ) : filterControl.type === 'date' ? (
                  <FilterDate
                    {...filterControl}
                    value={filter[filterControl.name]}
                    onChange={handleChange}
                  />
                ) : (
                  <FilterText
                    {...filterControl}
                    value={filter[filterControl.name]}
                    onChange={handleChange}
                  />
                )}
              </Col>
            ),
          )
        )}
      </Row>
    </Box>
  );
};

export default SearchFilter;
