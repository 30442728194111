import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 16,
    },
    flexItem: {
      display: 'flex',
      flexDirection: 'row',
    },
    icon: {
      width: 16,
      height: 16,
    },
    title: {
      marginLeft: 16,
    },
    detailsTitle: {
      fontSize: 14,
      fontWeight: 500,
    },
    detailsDate: {
      fontSize: 14,
      color: 'rgb(123, 125, 134)',
    },
    detailsTime: {
      fontSize: 14,
    },
    detailsStatus: {
      fontSize: 14,
    },
    mr16: {
      marginBottom: 16,
    },
  }),
);

export default useStyles;
