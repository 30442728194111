import gql from 'graphql-tag';

import { ESTABLISHMENT_FRAGMENT, WORKER_FRAGMENT } from './fragment';

export const GET_WORKERS = gql`
  query Workers($input: FilterWorkerInput) {
    workers(input: $input) {
      id
      user {
        id
        firstName
        lastName
        email
        phone
        userFunction
        mobilePhone
        enabled
      }
      etablishment {
        ...EtablishmentInfo
      }
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;

export const GET_WORKER = gql`
  query Worker($id: Int!) {
    worker(id: $id) {
      id
      lastVisitDate
      nextMeetingDate
      user {
        id
        firstName
        lastName
        email
        phone
        userFunction
        mobilePhone
        enabled
      }
      etablishment {
        ...EtablishmentInfo
      }
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;

export const GET_WORKERS_IDS = gql`
  query WorkerByIds($ids: [Int!]!) {
    workerByIds(ids: $ids) {
      id
      occupation
      user {
        id
        firstName
        lastName
        email
        userFunction
        phone
        mobilePhone
        enabled
        userFunction
      }
      etablishment {
        ...EtablishmentInfo
      }
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;

export const GET_WORKER_IN_ETABLISHMENT = gql`
  query WorkerInEtablishment($etablishmentId: Int!, $filterWorker:FilterWorkerInput) {
    workerInEtablishment(etablishmentId: $etablishmentId, filterWorker: $filterWorker) {
      ...WorkerInfo
    }
  }
  ${WORKER_FRAGMENT}
`;
