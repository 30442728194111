import React, { FC, useState, useRef, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EtablishmentDataType } from '../../../../../types/establishment';
import { GetStatusBySlug } from '../../../../../graphql/status/types/GetStatusBySlug';

interface LegalDatasProps {
  inputData: EtablishmentDataType;
  setInputData: (value: EtablishmentDataType) => void;
  establishmentStatus: GetStatusBySlug | undefined;
}

const getStatus = (status: string) => {
  switch (status) {
    case 'ENTERPRISE':
      return 'Entreprise';
    case 'ASSOCIATION':
      return 'Association';
    case 'COLLECTIVITY':
      return 'Collectivité';
    case 'HOSPITAL':
      return 'Hôpital';
    default:
      return '-';
  }
};

const LegalDatas: FC<LegalDatasProps> = (props) => {
  const { inputData, setInputData, establishmentStatus } = props;
  const classes = useStyles();
  const [labelWidth, setLabelWidth] = useState<number>(0);
  const inputLabel = useRef<any>(null);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const onHandleChange = (
    event: React.ChangeEvent<
      | HTMLInputElement
      | {
          name?: string | undefined;
          value: unknown;
        }
    >,
  ) => {
    const { name, value } = event.target;
    if (name) {
      setInputData({
        ...inputData,
        [name]:
          name === 'postalCode'
            ? (value as string).replace(/[^0-9]/g, '')
            : value,
      });
    }
  };

  const toggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setInputData({
      ...inputData,
      [name]: checked,
    });
  };

  return (
    <>
      <TextField
        id="name"
        name="name"
        label="Raison sociale"
        variant="outlined"
        placeholder="Raison sociale de l'établissement"
        InputLabelProps={{ shrink: true }}
        fullWidth
        margin="normal"
        autoComplete="off"
        value={inputData.name}
        onChange={onHandleChange}
      />
      <TextField
        id="rcs"
        name="rcs"
        label="Siren/Siret"
        variant="outlined"
        placeholder="Siren/Siret de l'établissement"
        InputLabelProps={{ shrink: true }}
        fullWidth
        margin="normal"
        autoComplete="off"
        value={inputData.rcs}
        onChange={onHandleChange}
      />
      <TextField
        id="address"
        name="address"
        label="Adresse"
        variant="outlined"
        placeholder="Adresse complète de l'établissement"
        InputLabelProps={{ shrink: true }}
        fullWidth
        margin="normal"
        autoComplete="off"
        value={inputData.address}
        onChange={onHandleChange}
      />
      <TextField
        id="objet"
        name="etablishmentObject"
        label="Objet"
        variant="outlined"
        placeholder="Objet de l'établissement"
        InputLabelProps={{ shrink: true }}
        fullWidth
        margin="normal"
        autoComplete="off"
        value={inputData.etablishmentObject}
        onChange={onHandleChange}
      />
      <TextField
        id="postalCode"
        name="postalCode"
        label="Code postal"
        variant="outlined"
        placeholder="Code postal de l'établissement"
        InputLabelProps={{ shrink: true }}
        fullWidth
        margin="normal"
        autoComplete="off"
        value={inputData.postalCode}
        onChange={onHandleChange}
      />
      <TextField
        id="city"
        name="city"
        label="Ville"
        variant="outlined"
        placeholder="Ville de l'établissement"
        InputLabelProps={{ shrink: true }}
        fullWidth
        margin="normal"
        autoComplete="off"
        value={inputData.city}
        onChange={onHandleChange}
      />
      <FormControl
        variant="outlined"
        margin="normal"
        className={classes.formControl}
      >
        <InputLabel
          shrink
          ref={inputLabel}
          htmlFor={`simple-select-outlined-label-status`}
        >
          Statut d'organisation
        </InputLabel>
        <Select
          id={`simple-select-outlined-status`}
          value={inputData.etablishmentStatusId}
          name="etablishmentStatusId"
          onChange={onHandleChange}
          input={
            <OutlinedInput
              notched
              name="status"
              labelWidth={labelWidth}
              placeholder="Selectionner..."
              id={`simple-select-outlined-label-status`}
            />
          }
        >
          {establishmentStatus &&
            establishmentStatus.getStatusBySlug &&
            establishmentStatus.getStatusBySlug.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {getStatus(item.name)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormGroup aria-label="status" className={classes.formGroup}>
        <InputLabel className={classes.inputLabel}>Statut</InputLabel>
        <FormControlLabel
          className={classes.formControlLabel}
          label={inputData.enabled ? 'Activé' : 'Désactivé'}
          labelPlacement="start"
          control={
            <Switch
              name="enabled"
              color="primary"
              checked={inputData.enabled}
              className={classes.switch}
              onChange={toggleChange}
            />
          }
        />
      </FormGroup>
    </>
  );
};

export default LegalDatas;
