import { HeadCell } from '../../../../../../types/table';
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { User, Beneficiary, UserRole } from '../../../../../../types/user';
import moment from 'moment';
interface UserListColumnsArgs {
  classes: Record<string, string>;
  onChangeStatus: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => void;
  onDelete: (index: number) => void;
  onEdit: (index: number) => void;
  userRole: UserRole;
}

const USER_KEY_LABEL: Record<keyof User | keyof Partial<Beneficiary>, string> =
  {
    id: '',
    firstName: 'Prénom',
    lastName: 'Nom',
    email: 'Mail',
    birthDate: 'Date de naissance',
    disabilityRecognition: 'Handicap',
    mobilePhone: 'Tél mobile',
    phone: 'Tél fixe',
    enabled: 'Statut',
    establishmentId: '',
    unitLifeId: '',
    // handicap: 'Handicap',
    unitLife: 'Unité de vie',
    userFunction: 'Fonction',
    userRole: 'Role',
    guardianEnabled: 'Sous tutelle',
    guardianName: 'Nom tuteur',
    signature: 'Signature',
    signatureFile: '',
  };

const TABLE_CELL: Record<string, string> = {
  ...USER_KEY_LABEL,
  actions: '',
};

const defaultDisplayedUserKeys: (keyof User)[] = [
  'lastName',
  'firstName',
  'email',
  'mobilePhone',
  'phone',
];

const beneficiarySpecialKeys: (keyof Partial<Beneficiary>)[] = [
  'guardianEnabled',
  'guardianName',
];

const userHeadCell = defaultDisplayedUserKeys.map((key) => ({
  name: key,
  label: USER_KEY_LABEL[key],
  disablePadding: false,
  renderer: (user: User) => user[key] || '-',
}));

const beneficiaryHeadCell: HeadCell[] = [
  {
    name: 'birthDate',
    label: USER_KEY_LABEL['birthDate'],
    disablePadding: false,
    renderer: (user: Beneficiary) =>
      moment(user.birthDate).format('DD-MM-YYYY') || '-',
  },
  {
    name: 'handicap',
    label: USER_KEY_LABEL['disabilityRecognition'],
    disablePadding: false,
    renderer: (user: Beneficiary) =>
      user.disabilityRecognition ? 'Oui' : 'Non',
  },
  {
    name: 'guardianEnabled',
    label: USER_KEY_LABEL['guardianEnabled'],
    disablePadding: false,
    renderer: (user: Beneficiary) => (user.guardianEnabled ? 'Oui' : 'Non'),
  },
  {
    name: 'guardianName',
    label: USER_KEY_LABEL['guardianName'],
    disablePadding: false,
    renderer: (user: Beneficiary) => user.guardianName || '-',
  },
];

const workerHeadCell: HeadCell[] = [
  {
    name: 'userFunction',
    label: USER_KEY_LABEL['userFunction'],
    disablePadding: false,
    renderer: (user) => user.userFunction || '-',
  },
];

const userStatusRenderer =
  (
    onChangeStatus: (
      event: React.ChangeEvent<HTMLInputElement>,
      id: number,
    ) => void,
  ) =>
  (user: User) => {
    const status = user.enabled;
    return (
      <FormControlLabel
        control={
          <Switch
            checked={status}
            onChange={(event) => onChangeStatus(event, user.id)}
            onClick={(event) => event.stopPropagation()}
            name="enabled"
            color="primary"
          />
        }
        label={status ? 'Activé' : 'Désactivé'}
      />
    );
  };

const userActionsRenderer =
  ({
    onDelete,
    onEdit,
    classes,
  }: {
    onDelete: (id: number) => void;
    onEdit: (id: number) => void;
    classes: Record<string, string>;
  }) =>
  (user: User) =>
    (
      <Box display="flex">
        <Tooltip title="Supprimer" placement="top" arrow>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onDelete(user.id);
            }}
            className={classes.margin}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Modifier" placement="top" arrow>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onEdit(user.id);
            }}
            className={classes.margin}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );

export const getUserListColumns: (
  params: UserListColumnsArgs,
) => HeadCell[] = ({ onChangeStatus, onDelete, onEdit, classes, userRole }) => [
  ...userHeadCell,
  ...(userRole === 'beneficiary' ? beneficiaryHeadCell : []),
  ...(userRole !== 'beneficiary' ? workerHeadCell : []),
  {
    name: 'enabled',
    label: 'Statut',
    disablePadding: true,
    renderer: userStatusRenderer(onChangeStatus),
  },
  {
    name: 'actions',
    label: ' ',
    disablePadding: true,
    renderer: userActionsRenderer({ onDelete, onEdit, classes }),
  },
];
