import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: 30,
    },
    dateEditor: {
      fontSize: 14,
      marginTop: 0,
    },
    mr: {
      marginRight: 20,
    },
    label: {
      fontSize: 14,
      fontWeight: 500,
    },
    link: {
      display: 'block',
      fontWeight: 500,
      fontSize: 14,
      textDecoration: 'underline !important',
      color: '#002B67 !important',
      marginBottom: 8,
      cursor: 'pointer',
      userSelect: 'none',
      width: 'fit-content',
    },
    select: {
      marginBottom: 16,
    },
    textEditor: {
      fontSize: 14,
      marginBottom: 16,
    },
    container: {
      height: 'calc(100vh - 80px)',
      overflowY: 'auto',
    },
    dateTimeField: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(2),
    },
    timePicker: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(1),
      width: 270,
    },
    selectPrestation: {
      fontSize: 14,
      width: '100%',
      marginBottom: 16,
    },
    timeField: {
      width: '80%',
    },
    labelTitle: {
      marginTop: '40%',
      fontSize: '25px',
      marginLeft: '60px',
    },
  }),
);
export default useStyles;
