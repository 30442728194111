import { ApolloError } from 'apollo-boost';
import { showSnackbar } from '../../../../../../utils/snackbar.util';
import { MutationHookOptions } from '@apollo/react-hooks';
import { User } from '../../../../../../types/user';
import { UpdateUser, UserData } from '../../../../../../types/Users.interface';
import { toUser } from './Users.util';
import {
  OnErrorFunc,
  OnCompletedFunc,
} from '../interfaces/UserEditList.interfaces';
import {
  CREATE_USERS_DATA_KEY,
  USER_LIST_LABEL,
} from '../constants/UserList.constants';
import {
  OnCreateUsersMutationErrorArgs,
  OnCreateUsersMutationCompletedArgs,
  CreateUsersMutationOptionsArgs,
} from '../interfaces/CreateUsersMutationOptions.interfaces';
import { capitalizeWord } from '../../../../../../utils/string.utils';
import { SnackbarType } from '../../../../../../types/snackbar';
import { displaySnackbar } from '../../../../../../common';

export const onCreateUsersMutationError: (
  args: OnCreateUsersMutationErrorArgs,
) => OnErrorFunc =
  ({ client }) =>
  (error: ApolloError) => {
    const snackbarData: SnackbarType = {
      type: 'ERROR',
      message: '',
      isOpen: true,
    };
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      const { message } = error.graphQLErrors[0];
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = `Une erreur s'est produite lors de la création`;
      if (message.includes('EMAIL_ALREADY_USED')) {
        snackbarData.type = 'WARNING';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `L'adresse email ${
          message.split(';')[0]
        } est déjà utilisée`;
      }
    } else if (error.networkError) {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        'Erreur de réseau. Veuillez vérifier votre connexion internet';
    }
    displaySnackbar(client, snackbarData);
  };

export const onCreateUsersMutationCompleted: (
  args: OnCreateUsersMutationCompletedArgs,
) => OnCompletedFunc =
  ({ client, addUsers, userRole }) =>
  (data: UpdateUser) => {
    showSnackbar({
      client,
      message: `${capitalizeWord(
        USER_LIST_LABEL[userRole],
      )}s ajoutés avec succès`,
      type: 'SUCCESS',
    });
    const key: string = CREATE_USERS_DATA_KEY[userRole];
    const users: User[] = (data as any)[key].data.map((userData: UserData) =>
      toUser({
        userData,
        userRole,
      }),
    );
    addUsers(users);
  };

export const onCreateUsersMutationOptions: (
  args: CreateUsersMutationOptionsArgs,
) => MutationHookOptions = ({ client, addUsers, userRole }) => ({
  onCompleted: onCreateUsersMutationCompleted({
    client,
    addUsers,
    userRole,
  }),
  onError: onCreateUsersMutationError({ client }),
});
