import { EMPTY_STRING_ERRORS } from '../constants/prestation-validation.constants';
import {
  PrestationValidatorFunc,
  PrestationValidators,
} from '../types/prestation-validator.types';

const requiredString: PrestationValidatorFunc = (prestation, key) => {
  const value = prestation[key];
  if (!value) {
    return {
      valid: false,
      error: EMPTY_STRING_ERRORS[key],
    };
  }
  return {
    valid: true,
  };
};

export const DEFAULT_PRESTATION_VALIDATORS: PrestationValidators = {
  libelle: [requiredString],
  establishmentId: [requiredString],
};
