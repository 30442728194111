import { InterventionPlanningEnum } from '../graphql/types/graphql-global-types';
import { SelectOptionsType } from '../types';

export const GetRecurrence: Record<string, string> = {
  ANY: 'Aucune',
  ONCE: 'Une seule fois',
  DAILY: 'Tous les jours',
  WORKDAY: 'Tous les jours de la semaine (du lundi au vendredi)',
  WEEKLY: 'Toutes les semaines',
  MONTHLY: 'Tous les mois',
};

export const statusOptions: SelectOptionsType[] = [
  { value: 0, label: 'Tous' },
  { value: -1, label: 'Non validé' },
  { value: 1, label: 'Validé' },
];

export const getPeriodicity: Record<string, string> = {
  AUCUN: 'ANY',
  UNE_SEULE_FOIS: 'ONCE',
  TOUS_LES_JOURS: 'DAILY',
  TOUS_LES_JOURS_OUVRES: 'WORKDAY',
  TOUTES_LES_SEMAINES: 'WEEKLY',
  TOUS_LES_MOIS: 'MONTHLY',
};
export const getStatus: Record<string, string> = {
  A_FAIRE: 'TODO',
  ANNULE: 'CANCELED',
  TERMINE: 'COMPLETED',
  EN_COURS: 'IN_PROGRESS',
  EN_ATTENTE_DE_VALIDATION: 'WAITING_VALIDATION',
};

export const rapportTypes: SelectOptionsType[] = [
  { value: 1, label: 'Par bénéficiaire' },
  { value: 2, label: 'Par intervenant' },
];
