import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',

      [theme.breakpoints.down(600)]: {
        width: '100%',
      },

      '& h6': {
        fontSize: '20px',
        color: 'white',
      },

      '& .MuiDialogActions-root': {
        padding: 0,
        margin: '24px 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',

        '& .MuiButton-root': {
          height: '56px',
          padding: theme.spacing(0, 6),
          color: '#fff',
          fontSize: '14px',
          // backgroundColor: theme.palette.primary.main,
        },
      },
    },
    dialogTitle: {
      backgroundColor: theme.palette.primary.main,
      height: 70,
      lineHeight: 70,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(2),
    },
    closeButton: {
      color: '#fff',
    },
    content: {
      boxSizing: 'border-box',
      padding: '20px 120px',

      [theme.breakpoints.down(600)]: {
        padding: '20px 70px',
      },
      [theme.breakpoints.down(500)]: {
        padding: '20px 20px',
      },
    },
    textUpdatePassword: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 620,
      boxShadow: '0px 10px 25px #00000017',
      marginTop: theme.spacing(3),
    },
    lens: {
      width: theme.spacing(4.5),
      marginTop: theme.spacing(2),
      height: theme.spacing(4.5),
    },
    titleContainer: {
      paddingLeft: theme.spacing(4.5),
      paddingBottom: theme.spacing(3),
    },
    title: {
      ...theme.typography.h6,
      color: theme.palette.text.secondary,
      // fontWeight: theme.typography.fontWeightBold,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
    },
    colorfulContent: {},
    icon: {
      marginLeft: theme.spacing(1),
      verticalAlign: 'middle',
    },
    bottomAction: {
      padding: 16,
    },
    reactSelect: {
      width: '80%',
      marginRight: 8,
    },
    inlineFlexRow: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0 16px 16px',
    },
  }),
);

export default useStyles;
