import gql from 'graphql-tag';

import { ADMIN_FRAGMENT, ESTABLISHMENT_FRAGMENT } from './fragment';

export const GET_ADMINISTRATORS = gql`
  query Administrators($input: FilterAdminInput) {
    administrators(input: $input) {
      id
      user {
        id
        firstName
        lastName
        email
        phone
        mobilePhone
        userFunction
        enabled
      }
      etablishments {
        ...EtablishmentInfo
      }
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;

export const GET_ADMINISTRATOR = gql`
  query Administrator($id: Int!) {
    administrator(id: $id) {
      id
      user {
        id
        firstName
        lastName
        email
        phone
        userFunction
        mobilePhone
        enabled
      }
      etablishments {
        ...EtablishmentInfo
      }
    }
  }
  ${ESTABLISHMENT_FRAGMENT}
`;
