import React, { FC } from 'react';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import LensIcon from '@material-ui/icons/Lens';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';
import { capitalize } from '../../../../common/utils';

interface SchedulePopupDetailsProps {
  data: any;
}

const getStatusLabel: Record<string, string> = {
  TODO: 'À faire',
  CANCELED: 'Annulée',
  COMPLETED: 'Terminée',
  IN_PROGRESS: 'En cours',
  WAITING_VALIDATION: 'En attente de validation',
};

const SchedulePopupDetails: FC<SchedulePopupDetailsProps> = (props) => {
  const classes = useStyles();
  const { data } = props;
  const dateStartMoment = Date.parse(data.timeStart);
  const dateEndMoment = moment.utc(data.dateEndTimestamp).format('HH:mm');

  return (
    <Box className={classes.root}>
      <Box className={`${classes.flexItem} ${classes.mr16}`}>
        <LensIcon className={classes.icon} style={{ color: data.color }} />
        <Box className={classes.title}>
          <Typography className={classes.detailsTitle}>{data.title}</Typography>
          <Typography className={classes.detailsDate}>
            {capitalize(moment(data.startDate).format('dddd DD MMMM YYYY'))}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.flexItem}>
        <AccessTimeIcon
          className={classes.icon}
          style={{ color: data.color }}
        />
        <Box className={classes.title}>
          <Typography className={classes.detailsTime}>
            {`${data.timeStart.substr(0, 5)} - ${data.timeEnd.substr(0, 5)}`}
          </Typography>
          <Typography
            className={classes.detailsStatus}
            style={{ color: data.color }}
          >
            {getStatusLabel[data.status]}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SchedulePopupDetails;
