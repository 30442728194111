import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-boost';
import {
  Redirect,
  RouteComponentProps,
  withRouter,
  useLocation,
} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from './styles';
import logo from '../../../assets/images/logo-neoptim.png';
import { isValidEmail } from '../../../common/validator';
import { displaySnackbar } from '../../../common/snackbar';
import { SnackbarType } from '../../../types';
import { LOGIN } from '../../../graphql/user/mutation';
import { Login, LoginVariables } from '../../../graphql/user/types/Login';
import {
  INVALID_PASSWORD,
  USER_NOT_FOUND,
  ACCOUNT_NOT_ACTIVATED,
  NOT_AUTHORIZED,
} from '../../../constants/error';
import {
  isAuthenticated,
  setAccessToken,
} from '../../../services/localStorage';

interface LoginProps {}

interface LocationState {
  email: string;
  [key: string]: any;
}

/**
 * Login Component
 * @param props LoginProps
 */
const LoginApp = (props: LoginProps & RouteComponentProps) => {
  const { history } = props;
  const classes = useStyles();
  const client = useApolloClient();
  const location = useLocation<LocationState>();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location]);

  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const [loginMutation, { data, loading }] = useMutation<Login, LoginVariables>(
    LOGIN,
    {
      onCompleted: (data: Login) => {
        snackbarData.message = 'Vous êtes connecté';
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        displaySnackbar(client, snackbarData);
      },
      onError: (error: ApolloError): void => {
        snackbarData.message = "Une erreur s'est produite.";
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          const { message } = error.graphQLErrors[0];
          switch (message) {
            case USER_NOT_FOUND:
              snackbarData.message = 'Adresse email incorrecte.';
              break;
            case INVALID_PASSWORD:
              snackbarData.message = 'Mot de passe incorrect.';
              break;
            case ACCOUNT_NOT_ACTIVATED:
              snackbarData.message = "Votre compte n'est pas encore activé.";
              break;
            case NOT_AUTHORIZED:
              snackbarData.message = 'Ce site est reservé aux administrateurs.';
              break;
          }
        } else if (error.networkError) {
          snackbarData.type = 'ERROR';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            'Erreur de réseau. Veuillez vérifier votre connexion internet.';
        }

        displaySnackbar(client, snackbarData);
      },
    },
  );

  useEffect(() => {
    if (data && data.login && data.login.accessToken) {
      setAccessToken(data.login.accessToken);
      window.location.reload();
    }
  }, [data]);

  if (isAuthenticated()) {
    return <Redirect {...{ to: '/' }} />;
  }

  const onLogin = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (!isValid()) {
      snackbarData.type = 'WARNING';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        password !== '' && !isValidEmail(email)
          ? 'Adresse email incorrecte'
          : 'Veuillez remplir tous les champs.';
      displaySnackbar(client, snackbarData);
    } else {
      await loginMutation({ variables: { email, password } });
    }
  };

  const handleEmail = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value.trim());
  };

  const handlePassword = (event: ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value.trim());
  };

  const isValid = (): boolean => {
    return isValidEmail(email) && password !== '';
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.wrapForm}>
          <Box className={classes.logo}>
            <img src={logo} alt="Neoptim Logo" />
          </Box>
          <Box className={classes.title}>
            <Typography
              variant="h4"
              component="h1"
              className={classes.textTitle}
            >
              Connexion
            </Typography>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              className={classes.descTitle}
            >
              Utilisez votre compte Administrateur Neoptim.
            </Typography>
          </Box>
          <form onSubmit={onLogin} className={classes.form} autoComplete="off">
            <TextField
              label="Email"
              type="text"
              name="email"
              onChange={handleEmail}
              variant="outlined"
              margin="normal"
              value={email}
              fullWidth
              className={classes.textField}
            />
            <TextField
              label="Mot de passe"
              type="password"
              name="password"
              onChange={handlePassword}
              variant="outlined"
              margin="normal"
              value={password}
              fullWidth
              className={classes.textField}
            />
            <Box className={classes.actions}>
              <Link
                className={classes.link}
                onClick={() => history.push('/forgot-password')}
              >
                Mot de passe oublié ?
              </Link>
            </Box>
            <div className={classes.buttonWrapper}>
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
                className={classes.button}
                fullWidth
              >
                Se connecter
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.lodingProgress}
                />
              )}
            </div>
          </form>
        </Box>
        <Typography
          variant="body2"
          component="footer"
          color="primary"
          align="center"
          className={classes.footer}
        >
          &copy; Copyright - Neoptim consulting
        </Typography>
      </Box>
    </Box>
  );
};

export default withRouter(LoginApp);
