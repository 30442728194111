import React, { FC, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import { FormControl } from '@material-ui/core';
import DateTimePicker from '../../../Common/DateTimePicker';
import Dropzone from '../../../Common/Dropzone';
import ClosableDialogWrapper from '../../../Common/ClosableDialogWrapper/ClosableDialogWrapper';
import Button from '@material-ui/core/Button/Button';
import { InterventionReportEditData } from '../../interfaces/intervention-report.interfaces';
import Typography from '@material-ui/core/Typography/Typography';
import { convertImageFileToBase64 } from '../../../../utils/image.utils';
import moment from 'moment';
import { formatDateString } from '../../../../utils/date.utils';
interface InterventionReportFormDialogProps {
  interventionReportEditData: InterventionReportEditData;
  open: boolean;
  onClose: () => void;
  onSubmit: (interventionReportEditData: InterventionReportEditData) => void;
  title?: string;
  loadingButton?: boolean;
  buttonLabel?: string;
  disableDateStart?: boolean;
}

const InterventionReportFormDialog: FC<InterventionReportFormDialogProps> = (
  props,
) => {
  const classes = useStyles();
  const {
    interventionReportEditData,
    open,
    title,
    onClose,
    onSubmit,
    loadingButton,
    buttonLabel,
    disableDateStart,
  } = props;
  const [editData, setEditData] = useState<InterventionReportEditData>(
    interventionReportEditData,
  );
  const [inputImage, setInputImage] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

  const { dateEnd, dateStart, signature, id } = editData;
  useEffect(() => {
    setSelectedFiles([]);
  }, [open]);

  useEffect(() => {
    setEditData(interventionReportEditData);
  }, [interventionReportEditData]);

  useEffect(() => {
    if (!open) {
      setInputImage('');
    }
  }, [open]);

  useEffect(() => {
    if (selectedFiles[0]) {
      convertImageFileToBase64(selectedFiles[0]).then((data) => {
        setInputImage(data);
      });
    }
  }, [selectedFiles]);

  const onHandleChange = (value: any, name: string) => {
    setEditData((prevValue) => ({
      ...prevValue,
      [name]: moment(value).utcOffset(0, true).toDate(),
    }));
  };

  const handleSave = () => {
    let signatures: Partial<InterventionReportEditData> = {};
    if (selectedFiles[0]) {
      signatures = {
        signatureFile: selectedFiles[0],
      };
    }
    onSubmit({
      ...editData,
      ...signatures,
    });
  };

  const finalDateStart = formatDateString(dateStart.toString());
  const finalDateEnd = formatDateString(dateEnd.toISOString());
  const actionButtons = (
    <Button
      variant="contained"
      color="primary"
      disabled={loadingButton}
      onClick={handleSave}
    >
      {buttonLabel || 'Enregistrer'}
    </Button>
  );

  return (
    <ClosableDialogWrapper
      open={open}
      onClose={onClose}
      title={title}
      dialogActions={actionButtons}
    >
      <FormControl>
        <Box className={classes.dateTimeField}>
          <DateTimePicker
            id="dateStart"
            disableDateStart={disableDateStart}
            label="Date et heure de début"
            selectedDate={finalDateStart}
            handleDateChange={(event) => {
              onHandleChange(event, 'dateStart');
            }}
            width="49%"
            marginRight="10px"
            dateFormat="dd/MM/yyyy HH:mm"
          />
          <DateTimePicker
            id="dateEnd"
            label="Date et heure de fin"
            selectedDate={finalDateEnd}
            handleDateChange={(event) => {
              onHandleChange(event, 'dateEnd');
            }}
            width="49%"
            dateFormat="dd/MM/yyyy HH:mm"
          />
        </Box>
        <Typography component="label" className={classes.label}>
          Signature
        </Typography>
        <Dropzone
          accept="image/*"
          multiple={false}
          base64Img={''}
          pictureUrl={signature}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          enablePreview
          showAction
          height={120}
        />
      </FormControl>
    </ClosableDialogWrapper>
  );
};

export default InterventionReportFormDialog;
