import React, { FC, useEffect, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import useStyles from './styles';
import CustomTable from '../../Common/CustomTableOptimize';
import { HeadCell } from '../../../types/table';
import Avatar from '@material-ui/core/Avatar';
import ExcelIcon from '../../../assets/images/excel.png';
import PDFIcon from '../../../assets/images/pdf.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  InterventionReport,
  InterventionReportData,
} from '../../../types/intervention-report.types';
import {
  Table,
  TableHeader,
  TableBody,
  TableCell,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
import InterventionReportDetails from '../InterventionReportDetails';
import {
  defaultInterventionReportData,
  defaultIntervetionReportEditData,
} from '../constants/intervention-report.constants';
import { formatDate, formatDateString } from '../../../utils/date.utils';
import ImageDisplayerDialog from '../../Common/ImageDisplayerDialog';
import { useUpdateInterventionReport } from './hooks/intervention-report-list.hooks';
import {
  InterventionFilter,
  InterventionReportEditData,
} from '../interfaces/intervention-report.interfaces';
import InterventionReportFormDialog from '../components/InterventionReportFormDialog/InterventionReportFormDialog';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { updateInterventionReportOptions } from '../utils/intervention-report-list-update-option.utils';
import {
  InterventionReports,
  InterventionReportsVariables,
} from '../../../graphql/intervention-report/types/InterventionReports';
import { ApolloQueryResult } from 'apollo-boost';
import {
  ActionRendererArgs,
  SignatureRendererArgs,
} from '../components/Renderers/Renderers';
import {
  InterventionReportListColumn,
  makeInterventionReportTable,
} from '../utils/table-columns.utils';
import {
  Document,
  Page,
  View,
  Image,
  Text,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import logoNeoptim from '../../../assets/images/logo-neoptim.png';
import { CREATE_PLANNING_CHILD } from '../../../graphql/planning/mutation';
import { GET_PLANNING_BY_ID } from '../../../graphql/planning/query';
import { exportData } from '../../../common/excel';
import {
  formatInterventionReportData,
  getLabelStatus,
} from '../../../common/excel/formatter/interventionReport';
import { StyledTableCell } from '../../Common/CustomTable/styles';
import Loader from '../../Common/Loader';
import { splitTable } from './filters';
import { AnyARecord } from 'dns';
import _ from 'lodash';
interface InterventionReportListProps {
  filter: InterventionFilter;
  data: any;
  columns?: InterventionReportListColumn[];
  loadingData?: boolean;
  toolbarFilters?: any[];
  noFilter?: boolean;
  refetch: (
    variables?: InterventionReportsVariables,
  ) => Promise<ApolloQueryResult<InterventionReports>>;
  setFilter: (filter: InterventionFilter) => void;
  establishmentId?: number;
}

/**
 * InterventionReportList Component
 * @param props InterventionReportListProps
 */
const InterventionReportList: FC<InterventionReportListProps> = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const {
    filter,
    data,
    columns,
    loadingData,
    toolbarFilters,
    noFilter,
    refetch,
    setFilter,
    establishmentId,
  } = props;

  const [loadingBtn, setLoadingBtn] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [detailOpen, setDetailOpen] = useState<boolean>(false);
  const [signatureDialogOpen, setSignatureDialogOpen] =
    useState<boolean>(false);
  const [interventionReport, setInterventionReport] =
    useState<InterventionReportData>(defaultInterventionReportData);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [formDialogOpen, setFormDialogOpen] = useState<boolean>(false);
  const [pdfData, setPdfData] = useState<InterventionReport[][]>([]);
  const [loadingDataPdf, setLoadingDataPdf] = useState<boolean>(false);
  const [downloadablePdf, setDownloadablePdf] = useState<boolean>(false);
  const [clearSelected, setClearSelected] = useState<boolean>(false);
  const [urlDownload, setUrlDownload] = useState<string | null>('');
  const [editData, setEditData] = useState<InterventionReportEditData>(
    defaultIntervetionReportEditData,
  );
  const [dataToChild, setDataToChild] = useState({
    beneficiaryId: 0,
    etablishmentId: 0,
    workerId: 0,
  });
  const [planningId, setPlanningId] = useState(0);
  const onUpdateSuccess = () => {
    setFormDialogOpen(false);
    refetch();
  };

  const [
    updateInterventionReport,
    { loading: interventionReportUpdateLoading },
  ] = useUpdateInterventionReport(
    updateInterventionReportOptions({
      client,
      onSuccess: onUpdateSuccess,
    }),
  );

  const { data: dataPlanning, refetch: refetchPlanningData } = useQuery(
    GET_PLANNING_BY_ID,
    {
      variables: {
        id: planningId,
      },
    },
  );

  useEffect(() => {
    refetchPlanningData();
  }, [planningId]);

  const [MutationCreatePlanningChild] = useMutation(CREATE_PLANNING_CHILD, {
    onCompleted: () => {
      refetch();
      setFormDialogOpen(false);
    },
    onError: (error) => {
      setFormDialogOpen(false);
    },
  });

  const onHandleEdit = (item: any) => {
    setDetailOpen(false);
    setEditData({
      id: item.planning_id,
      dateEnd: moment(item.createdAt).add(item.duration, 'minutes').toDate(),
      dateStart: item.createdAt,
      signature: item.signature,
      planningId: item.planning_id,
    });
    setPlanningId(item.planning_id);
    setDataToChild({
      beneficiaryId: item.beneficiaryId,
      etablishmentId: item.etablishmentId,
      workerId: item.workerId,
    });
    setFormDialogOpen(true);
  };

  const onHandleExport = (item: any) => {
    exportData({
      csvData: [
        {
          Date: formatDate(item.createdAt),
          Titre: item.title,
          Duree: item.duration,
          Intervenant: item.worker,
          Beneficiaire: item.beneficiary,
          Prestation: item.prestation,
          Statut: item.status == 'TODO' ? 'A faire' : 'Validé',
          Etablissement: item.etablishment,
          Lieu: item.location,
        },
      ],
      fileName: `Rapports d'intervention`,
      sheetName: `Rapports d'intervention`,
    });
  };

  const onSubmit = (interventionReportEditData: InterventionReportEditData) => {
    const dateEndFinal = moment
      .utc(interventionReportEditData.dateEnd)
      .format('YYYY-MM-DDThh:mm:ss');

    const inputChild = {
      planningParentId: interventionReportEditData.id,
      beneficiaryId: dataToChild.beneficiaryId,
      workerId: dataToChild.workerId,
      dateEnd: formatDateString(dateEndFinal),
      dateStart: dataPlanning.planning.dateStart,
      timeEnd: moment
        .utc(interventionReportEditData.dateEnd)
        .format('hh:mm:ss'),
      timeStart: dataPlanning.planning.timeStart,
      ocurrence: dataPlanning.planning.ocurrence,
      comment: dataPlanning.planning.comment,
      dateEndTimestamp: dataPlanning.planning.dateEndTimestamp,
      dateStartTimestamp: dataPlanning.planning.dateStartTimestamp,
      periodicityId: dataPlanning.planning.periodicity.id,
      notificationId: dataPlanning.planning.notification.id,
      statusId: dataPlanning.planning.status.id,
      interventionReportId: dataPlanning.planning.interventionReportId
        ? dataPlanning.planning.interventionReportId
        : null,
    };
    MutationCreatePlanningChild({
      variables: {
        input: inputChild,
      },
    });
  };

  const onClose = () => {
    setFormDialogOpen(false);
  };

  const handleClickItem = (item: any) => {
    setInterventionReport({
      ...item,
      worker: {
        name: item.worker,
        phone: item.workerPhone,
        email: item.workerEmail,
      },
      beneficiary: {
        name: item.beneficiary,
        phone: item.beneficiaryPhone,
        email: item.beneficiaryEmail,
      },
      establishment: {
        etablishment: item.etablishment,
        etablishmentAddress: item.etablishmentAddress,
        etablishmentCity: item.etablishmentCity,
        etablishmentTypeId: item.etablishmentTypeId,
        rcs: item.rcs,
        enabled: item.enabled,
      },

      dateEnd: moment(item.createdAt)
        .add(_.round(+item.duration), 'minutes')
        .toDate(),
      dateStart: item.createdAt,
    });

    setDetailOpen(true);
  };

  const actionsRendererArgs: ActionRendererArgs = {
    item: null,
    classes,
    onHandleEdit,
    onHandleExport,
  };

  const signatureRendererArgs: SignatureRendererArgs = {
    setImageUrl,
    setSignatureDialogOpen,
  };

  const handleLoading = (load: boolean) => {
    setLoadingBtn(load);
  };

  const tableColumns: HeadCell[] = makeInterventionReportTable({
    actionsRendererArgs,
    signatureRendererArgs,
    columns,
  });

  const getEtabName = () => {
    const etabNames = selectedItems.map((etab: any) => {
      return etab.etablishment;
    });
    const uniqueNames = new Set(etabNames);
    const uniqueNamesTab = Array.from(uniqueNames);
    return uniqueNamesTab.length === 1
      ? `RAPPORT D'INTERVENTION DE L'ETABLISSEMENT \n  ${selectedItems[0].etablishment.toUpperCase()}`
      : "RAPPORT D'INTERVENTION DES ETABLISSEMENTS";
  };

  interface MyDocumentProps {
    pdfData: any;
  }

  const MyDocument: FC<MyDocumentProps> = (props) => {
    let sousTab: any = [];
    const decoupageNumber = 3000;
    const tabResult = splitTable(
      props.pdfData,
      1,
      0,
      decoupageNumber,
      decoupageNumber,
      0,
      sousTab,
    );

    const loopTab = (_sousTab: Array<any>) => {
      return _sousTab.map((a: any) => {
        return (
          <Table data={a}>
            <TableHeader>
              <TableCell style={{ width: 15 }}>
                <Text
                  style={{ fontWeight: 100, fontSize: 12, textAlign: 'center' }}
                >
                  Date
                </Text>
              </TableCell>
              <TableCell style={{ width: 22 }}>
                <Text
                  style={{ fontWeight: 100, fontSize: 12, textAlign: 'center' }}
                >
                  Titre
                </Text>
              </TableCell>
              <TableCell style={{ width: 10 }}>
                <Text
                  wrap={true}
                  style={{ fontWeight: 100, fontSize: 12, textAlign: 'center' }}
                >
                  Durée (en min)
                </Text>
              </TableCell>
              <TableCell style={{ width: 20 }}>
                <Text
                  style={{ fontWeight: 100, fontSize: 12, textAlign: 'center' }}
                >
                  Intervenant
                </Text>
              </TableCell>
              <TableCell style={{ width: 20 }}>
                <Text
                  style={{ fontWeight: 100, fontSize: 12, textAlign: 'center' }}
                >
                  Bénéficiaire
                </Text>
              </TableCell>
              <TableCell style={{ width: 17 }}>
                <Text
                  wrap={true}
                  style={{ fontWeight: 100, fontSize: 12, textAlign: 'center' }}
                >
                  Nature de la prestation
                </Text>
              </TableCell>
              <TableCell style={{ width: 6 }}>
                <Text
                  style={{ fontWeight: 100, fontSize: 12, textAlign: 'center' }}
                >
                  Statut
                </Text>
              </TableCell>
              <TableCell style={{ width: 50, maxWidth: 100 }}>
                <Text
                  style={{ fontWeight: 100, fontSize: 12, textAlign: 'center' }}
                >
                  Signature
                </Text>
              </TableCell>
            </TableHeader>

            {/* ++++++++++++++++++++++++++++++++++++ TABLE BODY +++++++++++++++++++++++++++++++++++++ */}
            <TableBody>
              <DataTableCell
                style={{ textAlign: 'center', fontSize: 10, width: 15 }}
                getContent={(r) =>
                  moment(r.createdAt).format('DD/MM/YYYY').toString()
                }
              />
              <DataTableCell
                style={{
                  textAlign: 'center',
                  fontSize: 10,
                  width: 22,
                }}
                isHeader
                getContent={(r) => <Text wrap={true}> {r?.title ?? '-'}</Text>}
              />
              <DataTableCell
                style={{
                  textAlign: 'center',
                  minHeight: '28px',
                  maxHeight: '28px',
                  fontSize: 10,
                  width: 10,
                }}
                getContent={(r) => r.duration}
              />
              <DataTableCell
                style={{ textAlign: 'center', fontSize: 10, width: 20 }}
                getContent={(r) => r.worker}
              />
              <DataTableCell
                isHeader
                style={{ textAlign: 'center', fontSize: 10, width: 20 }}
                getContent={(r) => (
                  <View wrap={true}>
                    <Text>{r.beneficiary}</Text>
                  </View>
                )}
              />
              <DataTableCell
                style={{ textAlign: 'center', fontSize: 10, width: 17 }}
                getContent={(r) => r.prestation}
              />
              <DataTableCell
                style={{
                  textAlign: 'center',
                  fontSize: 10,
                  width: 6,
                }}
                isHeader
                getContent={(r) => (
                  <Text>{r.status ? getLabelStatus(r.status) : ''}</Text>
                )}
              />
              <DataTableCell
                style={{ maxWidth: 100, width: 50 }}
                getContent={(r) =>
                  r.signature ? (
                    <Image
                      style={{
                        width: '50px',
                        height: '30px',
                        margin: 'auto',
                      }}
                      src={r.signature}
                      source={r.signature}
                    />
                  ) : (
                    <Text style={{ textAlign: 'center' }}>-</Text>
                  )
                }
              />
            </TableBody>
          </Table>
        );
      });
    };

    return props.pdfData.length > 0 ? (
      <Document>
        <Page wrap={true} size="A4" style={{ padding: 4 }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginTop: '35px',
            }}
          >
            <Text
              style={{
                textAlign: 'center',
                margin: '25px 0px 25px',
                fontSize: 15,
              }}
            >
              {`${getEtabName()} `}
            </Text>
          </View>
          {loopTab(tabResult)}
        </Page>
      </Document>
    ) : (
      <Document>
        <Page></Page>
      </Document>
    );
  };

  useEffect(() => {
    if (!loadingDataPdf) {
      setSelectedItems([]);
      setPdfData([]);
    }
  }, [loadingDataPdf]);

  useEffect(() => {
    if (selectedItems.length > 0) {
      setPdfData([]);
      setLoadingDataPdf(false);
      setClearSelected(false);
      setUrlDownload(null);
    }
  }, [selectedItems]);

  const getPdfData = () => {
    setPdfData(
      selectedItems.map((item: any) => ({
        ...item,
        duration: +item.duration.toFixed(3).slice(0, -1),
      })),
    );
  };

  const removeSelected = (url: string | null, blob: Blob | null) => {
    setLoadingDataPdf(false);
    setPdfData([]);
    setUrlDownload(url);
    setClearSelected(true);
  };

  const handleExportExcel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    exportData({
      csvData: selectedItems.map((item: any) =>
        formatInterventionReportData({
          ...item,
          duration: +item.duration.toFixed(3).slice(0, -1),
        }),
      ),
      fileName: `Rapports_intervention`,
      sheetName: `Rapports d'intervention`,
    });
    setLoadingDataPdf(false);
    setPdfData([]);
    setUrlDownload(null);
    setClearSelected(true);
    setSelectedItems([]);
  };

  const toolbarActions = [
    <Box>
      <Button
        variant="contained"
        style={{ marginRight: 16 }}
        className={classes.button}
        disabled={!selectedItems.length || loadingBtn}
        onClick={(e) => handleExportExcel(e)}
        startIcon={
          <Avatar
            variant="square"
            alt="excel-icon"
            src={ExcelIcon}
            className={classes.excelIcon}
          />
        }
      >
        Exporter en excel
      </Button>
      {useMemo(
        () =>
          pdfData.length > 0 && (
            <PDFDownloadLink
              style={{ textDecoration: 'none' }}
              document={<MyDocument pdfData={pdfData} />}
              fileName={`rapport_d'intervention_${new Date().getTime()}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <>
                    <Button
                      className={classes.button}
                      variant="contained"
                      disabled={loading}
                    >
                      <CircularProgress
                        size={24}
                        className={classes.lodingProgress}
                      />
                      Chargement
                    </Button>
                  </>
                ) : (
                  <>{removeSelected(url, blob)}</>
                )
              }
            </PDFDownloadLink>
          ),
        [pdfData],
      )}
    </Box>,

    urlDownload && pdfData.length == 0 && (
      <a href={urlDownload} style={{ textDecoration: 'none' }} download>
        <Button
          className={`${classes.button} ${classes.downButton}`}
          variant="contained"
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            setLoadingDataPdf(false);
            setPdfData([]);
            setUrlDownload(null);
            setClearSelected(true);
            setSelectedItems([]);
          }}
        >
          Télécharger
        </Button>
      </a>
    ),

    filter.rapportTypes?.value !== 2
      ? !loadingDataPdf &&
        !urlDownload && (
          <Button
            className={classes.button}
            variant="contained"
            disabled={!selectedItems.length || loadingBtn}
            onClick={(e) => {
              e.stopPropagation();
              setLoadingDataPdf(true);
              setDownloadablePdf(false);
              getPdfData();
            }}
            startIcon={
              <Avatar
                variant="square"
                alt="pdf-icon"
                src={PDFIcon}
                className={classes.pdfIcon}
              />
            }
          >
            Exporter en PDF
          </Button>
        )
      : null,
  ];

  return (
    <Box className={classes.root}>
      <CustomTable
        handleLoading={handleLoading}
        filter={filter}
        toolbar
        selectable
        data={data && data.interventionReports && data.interventionReports.data}
        setFilter={setFilter}
        noFilter={noFilter}
        noFilterText="Veuillez effectuer un filtre pour afficher les rapports d'intervention"
        clearSelected={clearSelected}
        columns={tableColumns}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        loadingData={loadingData}
        toolbarStyles={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
        toolbarButtons={toolbarFilters}
        toolbarActionContent={toolbarActions}
        handleClickRow={handleClickItem}
        useFlexToolbar={true}
        count={
          data && data.interventionReports && data.interventionReports.count
        }
        establishmentId={establishmentId}
      />

      <InterventionReportDetails
        interventionReport={interventionReport}
        onEdit={onHandleEdit}
        open={detailOpen}
        setOpen={setDetailOpen}
      />
      <ImageDisplayerDialog
        open={signatureDialogOpen}
        title="Signature"
        setOpen={(value) => {
          setSignatureDialogOpen(value);
        }}
        imageUrl={imageUrl}
      />
      <InterventionReportFormDialog
        interventionReportEditData={editData}
        onClose={onClose}
        onSubmit={onSubmit}
        open={formDialogOpen}
        disableDateStart={true}
        title="Modification de rapport d'intervention"
        buttonLabel="Enregistrer"
        loadingButton={interventionReportUpdateLoading}
      />
    </Box>
  );
};

export default InterventionReportList;
