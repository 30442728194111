import React, { FC, useContext, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { ApolloError, MutationUpdaterFn } from 'apollo-boost';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { showSnackbar } from '../../../../../utils/snackbar.util';
import useStyles from './styles';
import { EstablishmentContext, UserContext } from '../../../../Context';
import ExcelReader from '../../../../Common/ExcelReader';
import { getInterventionFromFiles } from '../../../../../utils/intervention-import.utils';
import { InterventionDataType } from '../../../../../types/planning';
import EstablishmentChoice from '../../../EstablishmentChoice';
import {
  CreateInterventionsPlanningsResidence,
  CreateInterventionsPlanningsResidenceVariables,
} from '../../../../../graphql/planning/types/CreateInterventionsPlanningsResidence';
import { CREATE_PLANNINGS_RESIDENCE } from '../../../../../graphql/planning/mutation';
import { displaySnackbar } from '../../../../../common/snackbar';
import { SnackbarType, FilterControlType } from '../../../../../types';
import { CreateInterventionPlanningResidenceImportInput } from '../../../../../graphql/types/graphql-global-types';
import { InterventionImportErrorMessages } from '../../../../../constants/intervention-import.constant';
import Row from 'react-bootstrap/cjs/Row';
import Col from 'react-bootstrap/cjs/Col';
import { Switch } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  UserContextRole,
  UserContextType,
} from '../../../../Context/UserContext';

interface PlanningToolbarProps {
  filterControls: React.ReactNode;
  calendarMode: boolean;
  onChangeCalendarMode: (value: boolean) => void;
  refetchAppointment: () => void;
}

const PlanningToolbar: FC<PlanningToolbarProps & RouteComponentProps> = (
  props,
) => {
  const client = useApolloClient();
  const classes = useStyles();
  const {
    history,
    filterControls,
    calendarMode,
    onChangeCalendarMode,
    refetchAppointment,
  } = props;
  const { establishmentType } = useContext(EstablishmentContext);
  const [openEstablishmentChoice, setOpenEstablishmentChoice] =
    useState<boolean>(false);
  const user: UserContextType = useContext(UserContext);
  const userRole: UserContextRole = user.userRole;
  const [importFiles, setImportFiles] = useState<FileList>();
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };
  const [
    createInterventionsPlanningsResidence,
    { loading: loadingCreateInterventionsPlanningsResidence },
  ] = useMutation<
    CreateInterventionsPlanningsResidence,
    CreateInterventionsPlanningsResidenceVariables
  >(CREATE_PLANNINGS_RESIDENCE, {
    onCompleted: (data: CreateInterventionsPlanningsResidence) => {
      snackbarData.type = 'SUCCESS';
      snackbarData.autoHideDuration = 5000;
      showSnackbar({
        client,
        message: `Les activités ont été ajoutées`,
        type: 'SUCCESS',
      });
      refetchAppointment();
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la création des activités";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const { message } = error.graphQLErrors[0];
        switch (message) {
          case 'WORKER_NOT_FOUND':
            snackbarData.message =
              InterventionImportErrorMessages.WORKER_NOT_FOUND;
            break;
          case 'WORKER_DUPLICATED':
            snackbarData.message =
              InterventionImportErrorMessages.WORKER_DUPLICATED;
            break;
          case 'BENEFICIARY_NOT_FOUND':
            snackbarData.message =
              InterventionImportErrorMessages.BENEFICIARY_NOT_FOUND;
            break;
          case 'BENEFICIARY_DUPLICATED':
            snackbarData.message =
              InterventionImportErrorMessages.BENEFICIARY_DUPLICATED;
            break;
          case 'PRESTATION_NOT_FOUND':
            snackbarData.message =
              InterventionImportErrorMessages.PRESTATION_NOT_FOUND;
            break;
        }
      } else if (error.networkError) {
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });
  let { id }: any = useParams();
  const onHandleAddPlanning = () => {
    if (userRole === 'SUPER_ADMIN') {
      history.push({
        pathname: `/etablissements/ajout_planning/${id}`,
        search: '?etape=information_de_base',
      });
    } else {
      history.push({
        pathname: `/votre-etablissement/ajout_planning`,
        search: '?etape=information_de_base',
      });
    }
  };
  const onChooseEstablishment = async (id: number) => {
    if (!importFiles) {
      return;
    }
    try {
      const interventions: InterventionDataType[] =
        await getInterventionFromFiles(importFiles, id);
      createInterventionsPlanningsResidence({
        variables: {
          input:
            interventions as CreateInterventionPlanningResidenceImportInput[],
        },
      });
    } catch (ex) {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = ex.message;
      displaySnackbar(client, snackbarData);
    }
  };

  const onExcelReaderChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setOpenEstablishmentChoice(true);
    if (event.target.files) {
      setImportFiles({ ...event.target.files });
    }
    event.preventDefault();
  };

  return (
    <Row>
      <Col sm={12}>{filterControls}</Col>
      <Col sm={12} className={`${classes.toolBox}`}>
        <Button
          className={`${classes.button} ${classes.white}`}
          variant="contained"
          color="secondary"
          name="admin-add"
          style={{ minWidth: 200 }}
          startIcon={<AddBoxIcon />}
          onClick={onHandleAddPlanning}
        >
          Ajouter une activité
        </Button>
        <Box>
          <ExcelReader
            onChange={onExcelReaderChange}
            onClick={() => {}}
            loading={false}
          />
          <FormControlLabel
            style={{ marginLeft: 16, marginBottom: 0 }}
            control={
              <Switch
                checked={calendarMode}
                onChange={(event) => {
                  onChangeCalendarMode(event.target.checked);
                }}
                name="calendarMode"
                color="primary"
              />
            }
            label={calendarMode ? 'Vue calendrier' : 'Vue tableau'}
          />
          <EstablishmentChoice
            open={openEstablishmentChoice}
            setOpen={setOpenEstablishmentChoice}
            onChooseEstablishment={onChooseEstablishment}
          />
        </Box>
      </Col>
    </Row>
  );
};

export default withRouter(PlanningToolbar);
