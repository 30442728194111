import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    col: {
      marginBottom: 28,
    },
    userName: {
      fontSize: '1.3em',
      fontWeight: 600,
      color: '#000',
      marginBottom: theme.spacing(1),
    },
    userBeneficiaryName: {
      fontSize: '1.3em',
      fontWeight: 600,
      color: '#000',
      marginBottom: theme.spacing(1),
      overflowY: 'scroll',
      width: 480,
      height: 125,
    },

    signature: {
      [theme.breakpoints.down(600)]: {
        width: '100%',
        justifyContent: 'center',
      },
    },
  }),
);

export default useStyles;
