import { nl } from 'date-fns/locale';
import { PlanningFilter } from '../../../../../types/filters';

export const INITIAL_FILTER: PlanningFilter = {
  beneficiary: null,
  worker: null,
  keyword: '',
  d1: null,
  d2: null,
};
