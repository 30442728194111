import { gql } from 'apollo-boost';
import { INTERVENTION_REPORT_FRAGMENT } from './fragment';

export const UPDATE_INTERVENTION_REPORT = gql`
  mutation UpdateInterventionReport($input: UpdateInterventionReportInput!) {
    updateInterventionReport(input: $input) {
      action
      success
      message
      data {
        ...InterventionReport
      }
    }
  }
  ${INTERVENTION_REPORT_FRAGMENT}
`;
