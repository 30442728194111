import { ApolloError } from 'apollo-boost';
import { showSnackbar } from '../../../../../../utils/snackbar.util';
import { MutationHookOptions } from '@apollo/react-hooks';
import { User } from '../../../../../../types/user';
import { UpdateUser } from '../../../../../../types/Users.interface';
import { toUser } from './Users.util';
import {
  OnErrorFunc,
  OnCompletedFunc,
  CreateUser,
  CreateUserVariables,
} from '../interfaces/UserEditList.interfaces';
import {
  CREATE_USER_DATA_KEY,
  USER_LIST_LABEL,
} from '../constants/UserList.constants';
import {
  OnCreateUserMutationCompletedArgs,
  CreateUserMutationOptionsArgs,
} from '../interfaces/CreateUserMutationOptions.interfaces';
import { capitalizeWord } from '../../../../../../utils/string.utils';
import { SnackbarType } from '../../../../../../types';
import { displaySnackbar } from '../../../../../../common';

const snackbarData: SnackbarType = {
  type: 'ERROR',
  message: '',
  isOpen: true,
};

export const onCreateUserMutationCompleted: (
  args: OnCreateUserMutationCompletedArgs,
) => OnCompletedFunc =
  ({ client, addUser, userRole }) =>
  (data: UpdateUser) => {
    showSnackbar({
      client,
      message: `${capitalizeWord(
        USER_LIST_LABEL[userRole],
      )} ajouté avec succès`,
      type: 'SUCCESS',
    });
    const key: string = CREATE_USER_DATA_KEY[userRole];
    const user: User = toUser({
      userData: (data as any)[key].data,
      userRole,
    });
    addUser(user);
  };

export const onCreateUserMutationOptions: (
  args: CreateUserMutationOptionsArgs,
) => MutationHookOptions<CreateUser, CreateUserVariables> = ({
  client,
  addUser,
  userRole,
}) => ({
  onCompleted: onCreateUserMutationCompleted({
    client,
    addUser,
    userRole,
  }),
  onError: (error: ApolloError) => {
    snackbarData.type = 'ERROR';
    snackbarData.autoHideDuration = 5000;
    snackbarData.message = "Une erreur s'est produite lors de la création";
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      const { message } = error.graphQLErrors[0];

      if (message.includes('EMAIL_ALREADY_USED')) {
        snackbarData.type = 'WARNING';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `L'adresse email ${
          message.split(';')[0]
        } est déjà utilisée`;
      }
    } else if (error.networkError) {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        'Erreur de réseau. Veuillez vérifier votre connexion internet';
    }
    displaySnackbar(client, snackbarData);
  },
});
