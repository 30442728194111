import React, { FC, useState, useEffect, useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  useApolloClient,
  useQuery,
  useMutation,
  useLazyQuery,
} from '@apollo/react-hooks';
import {
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
  Box,
  Typography,
  Button,
  Paper,
  Avatar,
} from '@material-ui/core';

import { ValueType } from 'react-select';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { ApolloError, MutationUpdaterFn } from 'apollo-boost';
import { GET_PRESIGNED_URL } from '../../graphql/fileUpload/mutations';

import useStyles from './styles';
import SearchInput from '../Common/SearchInput/SearchInput';
import SearchFilter from '../Common/SearchFilter/SearchFilter';
import CustomTable from '../Common/CustomTable/CustomTable';
import UserFormDialog from '../Establishment/EstablishmentAdd/UserFormDialog/UserFormDialog';

import { FormInputDataState, User } from '../../types/user';
import ExcelIcon from '../../assets/images/excel.png';
import { BeneficiaryFilter } from '../../types/filters';
import { GroupAction, HeadCell } from '../../types/table';
import { FilterControlType } from '../../types/search';
import { SnackbarType } from '../../types/snackbar';
import { displaySnackbar } from '../../common/snackbar';
import {
  CreateBeneficiary,
  CreateBeneficiaryVariables,
} from '../../graphql/beneficiary/types/CreateBeneficiary';
import {
  Beneficiaries,
  BeneficiariesVariables,
} from '../../graphql/beneficiary/types/Beneficiaries';
import {
  CREATE_BENEFICIARY,
  UPDATE_BENEFICIARY,
  DELETE_BENEFICIARY,
  DELETE_BENEFICIARIES,
  UPDATE_BENEFICIARY_STATUS,
  CREATE_BENEFICIARIES,
} from '../../graphql/beneficiary/mutation';
import {
  UpdateBeneficiary,
  UpdateBeneficiaryVariables,
} from '../../graphql/beneficiary/types/UpdateBeneficiary';
import {
  UpdateBeneficiaryStatus,
  UpdateBeneficiaryStatusVariables,
} from '../../graphql/beneficiary/types/UpdateBeneficiaryStatus';
import {
  GET_BENEFICIARIES,
  GET_BENEFICIARY,
} from '../../graphql/beneficiary/query';
import {
  Beneficiary,
  BeneficiaryVariables,
} from '../../graphql/beneficiary/types/Beneficiary';
import { EtablishmentNames } from '../../graphql/etablishment/types/EtablishmentNames';
import {
  GET_ETABLISHMENT_BY_USER_ID,
  GET_ETABLISHMENT_NAMES,
} from '../../graphql/etablishment/query';
import { checkActionGroup } from '../../common/validator';
import { exportData, formatBeneficiaryData } from '../../common/excel';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import {
  DeleteBeneficiary,
  DeleteBeneficiaryVariables,
} from '../../graphql/beneficiary/types/DeleteBeneficiary';
import {
  DeleteBeneficiaries,
  DeleteBeneficiariesVariables,
} from '../../graphql/beneficiary/types/DeleteBeneficiaries';
import ExcelReader from '../Common/ExcelReader';
import EstablishmentChoice from '../Establishment/EstablishmentChoice';
import { getUsersFromFile } from '../../utils/excel-import.utils';
import { UserValidationState } from '../../types/user-validator.types';
import {
  getExcelImportUserErrorMessage,
  getImportExcelValidators,
  validateUsers,
} from '../../utils/user-validator.utils';
import { showSnackbar } from '../../utils/snackbar.util';
import {
  CreateBeneficiaries,
  CreateBeneficiariesVariables,
} from '../../graphql/beneficiary/types/CreateBeneficiaries';
import { formInputDataStateToUser } from '../../utils/user.utils';
import { handleUserListValidation } from '../../utils/user-list.utils';
import { UserContext } from '../Context';
import { UserContextRole, UserContextType } from '../Context/UserContext';
import {
  GetEtablishmentByUserId,
  GetEtablishmentByUserIdVariables,
} from '../../graphql/etablishment/types/GetEtablishmentByUserId';
import { EstablishmentType } from '../../types/establishment';

interface EnhancedBeneficiairyProps {}

const ACTIONS: GroupAction[] = [
  { label: 'Exporter en Excel', value: 'export' },
  { label: 'Activer', value: 'enable' },
  { label: 'Désactiver', value: 'disable' },
  { label: 'Supprimer', value: 'remove' },
];

const INIT_FILTER: BeneficiaryFilter = {
  establishment: '',
};

const INIT_DATA = {
  name: '',
  lastName: '',
  email: '',
  mobilePhone: '',
  signature: '',
  phone: '',
  birthDate: new Date(moment().format('MM/DD/YYYY')),
  disabilityRecognition: false,
  status: false,
  establishmentId: 0,
  unitLifeId: 0,
  beneficiaryDocuments: '',
};

const normalizeFilter = (filters: BeneficiaryFilter): any => {
  return {
    establishmentName: filters.establishment || '',
  };
};

/**
 * Beneficiairy Component
 * @param props EnhancedBeneficiairy
 */
const EnhancedBeneficiairy: FC<
  EnhancedBeneficiairyProps & RouteComponentProps
> = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [openCreateUserDialog, setOpenCreateUserDialog] =
    useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [data, setData] = useState<any[]>([]);
  const [filter, setFilter] = useState<BeneficiaryFilter>(INIT_FILTER);
  const [inputData, setInputData] = useState<FormInputDataState>(INIT_DATA);
  const [options, setOptions] = useState<any[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [beneficiaryId, setBeneficiaryId] = useState<number>(0);
  const [openUpdateUserDialog, setOpenUpdateUserDialog] =
    useState<boolean>(false);
  const [openEstablishmentChoice, setOpenEstablishmentChoice] =
    useState<boolean>(false);
  const [editId, setEditId] = useState<number>(-1);
  const [inputUpdateData, setInputUpdateData] =
    useState<FormInputDataState>(INIT_DATA);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [importFiles, setImportFiles] = useState<FileList>();

  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };
  const user: UserContextType = useContext(UserContext);
  const userRole: UserContextRole = user.userRole;

  const { data: establishmentData } = useQuery<
    GetEtablishmentByUserId,
    GetEtablishmentByUserIdVariables
  >(GET_ETABLISHMENT_BY_USER_ID, {
    variables: {
      id: user.userId,
    },
  });

  // const userRole: UserContextRole = useContext(UserContext).userRole;

  const establishmentType =
    establishmentData?.etablishmentByUserId?.etablishmentType?.name;
  let filters = {
    name: searchKey || '',
    userRole,
  };

  const unitLifeColumn = [
    {
      name: 'unitLife',
      label: 'Unité de vie',
      disablePadding: false,
      minWidth: 180,
      renderer: (value: any) => {
        return (value && value.unitLife && value.unitLife.name) || '-';
      },
    },
  ];
  const variables = {
    input: {
      filter: JSON.stringify({
        // etablishmentNull: false,
        ...normalizeFilter(filter),
        ...filters,
      }),
    },
  };

  const [getBeneficiaryInfo] = useLazyQuery<Beneficiary, BeneficiaryVariables>(
    GET_BENEFICIARY,
    {
      onCompleted: (data: Beneficiary) => {
        if (data && data.beneficiary) {
          exportData({
            csvData: [formatBeneficiaryData(data.beneficiary)],
            fileName: 'Bénéficiaire',
            sheetName: 'Bénéficiaire',
          });
        }
      },
      onError: (error: ApolloError) => {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          "Une erreur s'est produite lors de la récupération des informations du bénéficiaire";
        if (error.networkError) {
          snackbarData.type = 'ERROR';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            'Erreur de réseau. Veuillez vérifier votre connexion internet';
        }
        displaySnackbar(client, snackbarData);
      },
    },
  );

  const [getBeneficiaryDetails] = useLazyQuery<
    Beneficiary,
    BeneficiaryVariables
  >(GET_BENEFICIARY, {
    onCompleted: (data: Beneficiary) => {
      if (data && data.beneficiary) {
        const documents = data.beneficiary.documents;

        const {
          unitLife,
          etablishment,
          user,
          birthDate,
          disabilityRecognition,
          guardianEnabled,
          guardianName,
          signature,
        } = data.beneficiary;
        setInputUpdateData({
          email: (user && user.email) || '',
          name: (user && user.firstName) || '',
          lastName: (user && user.lastName) || '',
          mobilePhone: (user && user.mobilePhone) || '',
          phone: (user && user.phone) || '',
          establishmentId: (etablishment && etablishment.id) || 0,
          signature: signature || '',
          unitLifeId: (unitLife && unitLife.id) || 0,
          status: (user && user.enabled) || false,
          guardianEnabled: guardianEnabled || false,
          guardianName: guardianName || '',
          birthDate: new Date(moment(birthDate).format('MM/DD/YYYY')) || '',
          disabilityRecognition: disabilityRecognition || false,
          beneficiaryDocuments: JSON.stringify(documents),
        });
        setOpenUpdateUserDialog(true);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la récupération des informations du bénéficiaire";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [getSelectedBeneficiaryInfo] = useLazyQuery<
    Beneficiaries,
    BeneficiariesVariables
  >(GET_BENEFICIARIES, {
    onCompleted: (data: Beneficiaries) => {
      if (data) {
        exportData({
          csvData: data.beneficiaries.map((item: any) =>
            formatBeneficiaryData(item),
          ),
          fileName: 'Bénéficiaires',
          sheetName: 'Bénéficiaires',
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la récupération des informations du bénéficiaire";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const { data: establishmentNames } = useQuery<EtablishmentNames>(
    GET_ETABLISHMENT_NAMES,
  );

  const INPUT_FILTERS: FilterControlType[] = [
    {
      label: 'Etablissement',
      name: 'establishment',
      value: '',
      placeholder: 'Tous les établissements',
      type: 'select',
      options,
      autoComplete: true,
    },
  ];

  useEffect(() => {
    if (establishmentNames && establishmentNames.etablishments) {
      const opts = establishmentNames.etablishments.map((item: any) => {
        if (item)
          return {
            label: item.name,
            value: item.name,
          };
        return true;
      });
      setOptions(opts);
    }
  }, [establishmentNames]);

  const {
    loading: loadingBenefList,
    data: beneficiaryList,
    refetch,
  } = useQuery<Beneficiaries, BeneficiariesVariables>(GET_BENEFICIARIES, {
    variables,
    onError: (err) => {
      console.log('***********', err);
    },
  });

  const [createBeneficiary, { loading: loadingCreateBenef }] = useMutation<
    CreateBeneficiary,
    CreateBeneficiaryVariables
  >(CREATE_BENEFICIARY, {
    onCompleted: (data: CreateBeneficiary) => {
      if (data && data.createBeneficiary) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = 'Le bénéficiaire a été ajouté';
        refetch();
        displaySnackbar(client, snackbarData);
        setOpenCreateUserDialog(false);
        setInputData(INIT_DATA);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la création du bénéficiaire";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const { message } = error.graphQLErrors[0];
        switch (message) {
          case 'EMAIL_ALREADY_USED':
            snackbarData.type = 'WARNING';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = "L'adresse email est déjà utilisée";
            break;
        }
      } else if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [createBeneficiaries, { loading: loadingCreateBeneficiaries }] =
    useMutation<CreateBeneficiaries, CreateBeneficiariesVariables>(
      CREATE_BENEFICIARIES,
      {
        onCompleted: (data: CreateBeneficiaries) => {
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          showSnackbar({
            client,
            message: `Les bénéficiaires ont été ajoutés`,
            type: 'SUCCESS',
          });
        },
        onError: (error: ApolloError) => {
          snackbarData.message =
            "Une erreur s'est produite lors de la création des bénéficiaires";
          if (error.graphQLErrors && error.graphQLErrors.length > 0) {
            const { message } = error.graphQLErrors[0];
            if (message.includes('EMAIL_ALREADY_USED')) {
              snackbarData.type = 'WARNING';
              snackbarData.autoHideDuration = 5000;
              snackbarData.message = `L'adresse email ${
                message.split(';')[0]
              } est déjà utilisée`;
            }
          } else if (error.networkError) {
            snackbarData.type = 'ERROR';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message =
              'Erreur de réseau. Veuillez vérifier votre connexion internet';
          }
          displaySnackbar(client, snackbarData);
        },
      },
    );

  const [updateBeneficiary, { loading: loadingUpdateBenef }] = useMutation<
    UpdateBeneficiary,
    UpdateBeneficiaryVariables
  >(UPDATE_BENEFICIARY, {
    onCompleted: (data: UpdateBeneficiary) => {
      if (data && data.updateBeneficiary) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Le bénéficiaire a été mis à jour`;
        displaySnackbar(client, snackbarData);
        setOpenUpdateUserDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour du bénéficiaire";

      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const { message } = error.graphQLErrors[0];
        switch (message) {
          case 'EMAIL_ALREADY_USED':
            snackbarData.type = 'WARNING';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = "L'adresse email est déjà utilisée";
            break;
        }
      } else if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [updateBeneficiaryStatus] = useMutation<
    UpdateBeneficiaryStatus,
    UpdateBeneficiaryStatusVariables
  >(UPDATE_BENEFICIARY_STATUS, {
    onCompleted: (data: UpdateBeneficiaryStatus) => {
      if (data && data.updateBeneficiaryStatus) {
        const statut =
          data.updateBeneficiaryStatus &&
          data.updateBeneficiaryStatus[0] &&
          data.updateBeneficiaryStatus[0].user &&
          data.updateBeneficiaryStatus[0].user.enabled === true
            ? 'activé'
            : 'désactivé';
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Les bénéficiaires ont été ${statut}s`;
        displaySnackbar(client, snackbarData);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour des bénéficiaires";

      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [deleteBeneficiary, { loading: loadingDeleteBeneficiary }] =
    useMutation<DeleteBeneficiary, DeleteBeneficiaryVariables>(
      DELETE_BENEFICIARY,
      {
        onCompleted: (data: DeleteBeneficiary) => {
          if (data && data.deleteBeneficiary) {
            snackbarData.type = 'SUCCESS';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = `Le bénéficiare a été supprimé`;
            displaySnackbar(client, snackbarData);
            setOpenConfirmDialog(false);
          }
        },
        onError: (error: ApolloError) => {
          console.log('ato1');

          snackbarData.type = 'ERROR';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            "Une erreur s'est produite lors de la suppression du bénéficiaire";
          if (error.networkError) {
            console.log('ato2');

            snackbarData.type = 'ERROR';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message =
              'Erreur de réseau. Veuillez vérifier votre connexion internet';
          }
          displaySnackbar(client, snackbarData);
        },
      },
    );

  const [deleteBeneficiaries, { loading: loadingDeleteBeneficiaries }] =
    useMutation<DeleteBeneficiaries, DeleteBeneficiariesVariables>(
      DELETE_BENEFICIARIES,
      {
        onCompleted: (data: DeleteBeneficiaries) => {
          if (data && data.deleteBeneficiaries) {
            snackbarData.type = 'SUCCESS';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = `Les bénéficiaires ont été supprimés`;
            displaySnackbar(client, snackbarData);
            setOpenConfirmDialog(false);
          }
        },
        onError: (error: ApolloError) => {
          snackbarData.type = 'ERROR';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            "Une erreur s'est produite lors de la suppression des bénéficiaires";
          if (error.networkError) {
            snackbarData.type = 'ERROR';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message =
              'Erreur de réseau. Veuillez vérifier votre connexion internet';
          }
          displaySnackbar(client, snackbarData);
        },
      },
    );

  useEffect(() => {
    if (beneficiaryList && beneficiaryList.beneficiaries) {
      setData(beneficiaryList.beneficiaries);
    }
  }, [beneficiaryList]);

  const onHandleChangeStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const { checked } = event.target;
    if (id) {
      updateBeneficiary({
        variables: {
          id,
          enabled: checked,
        },
      }).then((resUpdateBeneficiary) => {
        if (
          resUpdateBeneficiary &&
          resUpdateBeneficiary.data &&
          resUpdateBeneficiary.data.updateBeneficiary
        ) {
          const statut =
            resUpdateBeneficiary.data.updateBeneficiary.user &&
            resUpdateBeneficiary.data.updateBeneficiary.user.enabled
              ? 'activé'
              : 'désactivé';
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `Le bénéficiaire a été ${statut}`;
          displaySnackbar(client, snackbarData);
        }
      });
    }
  };

  const createBeneficiaryUpdate: MutationUpdaterFn<CreateBeneficiary> = (
    cache,
    { data },
  ) => {
    const benefData = cache.readQuery<Beneficiaries>({
      query: GET_BENEFICIARIES,
      variables,
    });
    const newBenef =
      data && data.createBeneficiary && data.createBeneficiary.data;
    if (newBenef && benefData) {
      cache.writeQuery({
        query: GET_BENEFICIARIES,
        variables,
        data: {
          beneficiaries: [newBenef, ...benefData.beneficiaries],
        },
      });
    }
  };

  const [getUrl] = useMutation(GET_PRESIGNED_URL);

  const uploadLogo = (file: any, dataBeneficiaire: any, form: any, cb: any) => {
    getUrl({ variables: { fileName: file.name, folder: 'signature' } }).then(
      (data) => {
        if (data) {
          const { uploadFile } = data.data;
          const { url, uuid } = uploadFile;
          fetch(url, {
            method: 'PUT',
            body: file,
          })
            .then((res) => {
              const dataUpdated = { ...dataBeneficiaire, signature: url };
              if (data)
                createBeneficiary({
                  variables: {
                    ...dataUpdated,
                    birthDate: new Date(
                      moment(dataBeneficiaire.birthDate).format('DD/MM/YYYY'),
                    ),
                    beneficiaryDocuments: form.beneficiaryDocuments,
                  },
                  update: (cache, { data }) => {
                    const benefData = cache.readQuery<Beneficiaries>({
                      query: GET_BENEFICIARIES,
                      variables,
                    });
                    const newBenef =
                      data &&
                      data.createBeneficiary &&
                      data.createBeneficiary.data;
                    if (newBenef && benefData) {
                      cache.writeQuery({
                        query: GET_BENEFICIARIES,
                        variables,
                        data: {
                          beneficiaries: [newBenef, ...benefData.beneficiaries],
                        },
                      });
                    }
                  },
                })
                  .then(({ data }) => {
                    return cb(
                      !!(
                        data &&
                        data.createBeneficiary &&
                        data.createBeneficiary.success
                      ),
                    );
                  })
                  .catch(() => {
                    return cb(false);
                  });
            })
            .catch((error) => {});
        }
      },
    );
  };

  const updateSignature = (form: any, dataBeneficiaire: any, cb: any) => {
    getUrl({
      variables: { fileName: form.file.name, folder: 'signature' },
    }).then((data) => {
      if (data) {
        const { uploadFile } = data.data;
        const { url, uuid } = uploadFile;

        fetch(url, {
          method: 'PUT',
          body: form.file,
        })
          .then((res) => {
            const dataUpdated = { ...dataBeneficiaire, signature: url };
            if (dataBeneficiaire)
              updateBeneficiary({
                variables: {
                  ...dataUpdated,
                  id: editId,
                  beneficiaryDocuments: form.beneficiaryDocuments,
                },
              });
          })
          .catch((error) => {});
      }
    });
  };

  const onHandleAddUser = (data: FormInputDataState, cb: any) => {
    const user: User = formInputDataStateToUser(data, 'beneficiary') as User;

    handleUserListValidation(user, client, () => {
      if (data && data.file) {
        uploadLogo(data.file, user, data, cb);
      } else {
        createBeneficiary({
          variables: {
            ...user,
            birthDate: user.birthDate,
            beneficiaryDocuments: data.beneficiaryDocuments,
          },
          update: createBeneficiaryUpdate,
        })
          .then(({ data }) => {
            return cb(
              !!(
                data &&
                data.createBeneficiary &&
                data.createBeneficiary.success
              ),
            );
          })
          .catch(() => {
            return cb(false);
          });
      }
    });
  };

  const onHandleUpdateUser = (dataUser: FormInputDataState, cb: any) => {
    const user: User = formInputDataStateToUser(
      dataUser,
      'beneficiary',
    ) as User;
    handleUserListValidation(user, client, () => {
      if (dataUser && dataUser.file) {
        updateSignature(dataUser, user, cb);
      } else {
        const newUserData = { ...user };
        updateBeneficiary({
          variables: {
            ...newUserData,
            id: editId,
            beneficiaryDocuments: dataUser.beneficiaryDocuments,
          },
        }).then((res) => {
          if (res) {
            const { data } = res;
            if (data && data.updateBeneficiary) {
              snackbarData.type = 'SUCCESS';
              snackbarData.autoHideDuration = 5000;
              snackbarData.message = `Le bénéficiaire a été mis à jour`;
              displaySnackbar(client, snackbarData);
            }
          }
        });
      }
    });
  };

  const onHandleDelete = (id: number) => {
    setBeneficiaryId(id);
    setOpenConfirmDialog(true);
  };

  const onDeleteBeneficiary = () => {
    if (beneficiaryId && beneficiaryId > 0) {
      console.log('beneficiaryId**********', beneficiaryId);

      deleteBeneficiary({
        variables: {
          id: beneficiaryId,
        },
        update: (cache, { data }) => {
          const deletedId =
            data && data.deleteBeneficiary && data.deleteBeneficiary.id;
          console.log('deletedId====>', deletedId);

          const beneficiaryData = cache.readQuery<Beneficiaries>({
            query: GET_BENEFICIARIES,
            variables,
          });

          if (deletedId && beneficiaryData) {
            console.log('ewa==>', deletedId, '*****', beneficiaryData);

            const newData = beneficiaryData.beneficiaries.filter(
              (beneficiary) => beneficiary && beneficiary.id !== deletedId,
            );
            cache.writeQuery({
              query: GET_BENEFICIARIES,
              variables,
              data: {
                beneficiaries: newData,
              },
            });
          }
        },
      });
    } else {
      snackbarData.type = 'WARNING';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'ID bénéficiare non trouvé.';
      displaySnackbar(client, snackbarData);
      setOpenConfirmDialog(false);
    }
  };

  const onHandleEditUser = (id: number) => {
    setEditId(id);
    // const input = data.find((item) => item.id === id).user;
    getBeneficiaryDetails({ variables: { id } });
  };

  const onHandleExport = (id: number) => {
    getBeneficiaryInfo({ variables: { id } });
  };

  const onHandleSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKey(value);
  };

  const exportBenefInfos = (selectedItems: number[]): void => {
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les bénéficiaires à exporter`,
      )
    ) {
      const filters = JSON.stringify({ ids: selectedItems });
      const order = JSON.stringify({ id: 'asc' });
      const variables = { input: { filter: filters, orderBy: order } };
      getSelectedBeneficiaryInfo({ variables });
    }
  };

  const changeBenefStatus = (
    selectedItems: number[],
    enabled: boolean,
  ): void => {
    const status = enabled ? 'activer' : 'désactiver';
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les bénéficiaires à ${status}`,
      )
    ) {
      updateBeneficiaryStatus({
        variables: { ids: selectedItems, status: enabled },
      });
    }
  };

  const removeBeneficiaries = (selectedItems: number[]): void => {
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les bénéficiaires à supprimer`,
      )
    ) {
      deleteBeneficiaries({
        variables: { ids: selectedItems },
        update: (cache, { data }) => {
          const deletedIds = ((data && data.deleteBeneficiaries) || []).map(
            (item) => item && item.id,
          );

          const benefData = cache.readQuery<Beneficiaries>({
            query: GET_BENEFICIARIES,
            variables,
          });

          if (deletedIds && benefData && benefData.beneficiaries) {
            const newData = benefData.beneficiaries.filter(
              (benef) => benef && deletedIds.indexOf(benef.id) === -1,
            );
            cache.writeQuery({
              query: GET_BENEFICIARIES,
              variables,
              data: {
                beneficiaries: newData,
              },
            });
          }
        },
      });
    }
  };

  const onHandleAction = (action: ValueType<GroupAction>): void => {
    const actionGroup = action as GroupAction;

    if (actionGroup && actionGroup.value) {
      switch (actionGroup.value) {
        case 'export':
          exportBenefInfos(selectedItems);
          break;
        case 'enable':
          changeBenefStatus(selectedItems, true);
          break;
        case 'disable':
          changeBenefStatus(selectedItems, false);
          break;
        case 'remove':
          removeBeneficiaries(selectedItems);
          break;
      }
    }
  };

  const onExcelReaderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenEstablishmentChoice(true);
    if (event.target.files) {
      setImportFiles({ ...event.target.files });
    }
    event.preventDefault();
  };

  const benefToolbarButtons = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="beneficiary-add"
      startIcon={<AddBoxIcon />}
      onClick={() => setOpenCreateUserDialog(true)}
    >
      Ajouter un bénéficiaire
    </Button>,
    <ExcelReader
      onChange={onExcelReaderChange}
      onClick={() => {}}
      loading={false}
    />,
  ];

  let columns: HeadCell[] = [
    {
      name: 'lastName',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value.user && value.user.lastName) || '-';
      },
    },
    {
      name: 'name',
      label: 'Prénom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.firstName) || '-';
      },
    },
    {
      name: 'birthDate',
      label: 'Date de naissance',
      disablePadding: false,
      renderer: (value: any) => {
        return (
          (value &&
            value.birthDate &&
            moment(value.birthDate).format('DD/MM/YYYY')) ||
          '-'
        );
      },
    },
    {
      name: 'disabilityRecognition',
      label: `Reconnaissance d'handicap`,
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.disabilityRecognition ? 'Oui' : 'Non') || '-';
      },
    },
    {
      name: 'etablishment',
      label: 'Etablissement',
      disablePadding: false,
      renderer: (value: any) => {
        const etabName = (value.etablishment && value.etablishment.name) || '-';
        return etabName;
      },
    },
    {
      name: 'mail',
      label: 'Mail',
      disablePadding: false,
      renderer: (value: any) => {
        return (value.user && value.user.email) || '-';
      },
    },
    {
      name: 'mobilePhone',
      label: 'Tél mobile',
      disablePadding: false,
      renderer: (value: any) => {
        return (value.user && value.user.mobilePhone) || '-';
      },
    },
    {
      name: 'phone',
      label: 'Tél fixe',
      disablePadding: false,
      renderer: (value: any) => {
        return (value.user && value.user.phone) || '-';
      },
    },
    {
      name: 'guardianEnabled',
      label: 'Sous tutelle',
      disablePadding: false,
      renderer: (value: any) => {
        return value.guardianEnabled !== undefined
          ? value.guardianEnabled
            ? 'Oui'
            : 'Non'
          : '-';
      },
    },
    {
      name: 'guardianName',
      label: 'Nom tuteur',
      disablePadding: false,
      renderer: (value: any) => {
        return value.guardianName || '';
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => {
        const statut = value.user && value.user.enabled;
        return (
          <FormControlLabel
            control={
              <Switch
                checked={statut}
                onChange={(event) => onHandleChangeStatus(event, value.id)}
                name="enabled"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            }
            label={statut ? 'Activé' : 'Désactivé'}
          />
        );
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex" justifyContent="center">
          <Tooltip title="Exporter en Excel" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleExport(value.id);
              }}
              className={classes.margin}
            >
              <img
                alt="excel-icon"
                src={ExcelIcon}
                className={classes.excelIcon}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleDelete(value.id);
              }}
              className={classes.margin}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifier" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleEditUser(value.id);
              }}
              className={classes.margin}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  if (establishmentType !== 'DOMICILE') {
    columns.splice(3, 0, unitLifeColumn[0] as unknown as HeadCell);
  }

  const onCreateBeneficiariesUpdate: MutationUpdaterFn<CreateBeneficiaries> = (
    cache,
    { data },
  ) => {
    const beneficiaryDatas = cache.readQuery<Beneficiaries>({
      query: GET_BENEFICIARIES,
      variables,
    });
    const newBeneficiaries =
      data && data.createBeneficiaries && data.createBeneficiaries.data;
    if (newBeneficiaries && beneficiaryDatas) {
      cache.writeQuery({
        query: GET_BENEFICIARIES,
        variables,
        data: {
          beneficiaries: [
            ...newBeneficiaries,
            ...beneficiaryDatas.beneficiaries,
          ],
        },
      });
    }
  };

  const onChooseEstablishment = async (id: number) => {
    const file = importFiles && importFiles[0];
    const users: User[] = await getUsersFromFile(file as File, {
      userRole: 'beneficiary',
      establishmentId: id,
    });
    const validationStatus: UserValidationState = validateUsers(
      users,
      getImportExcelValidators('beneficiary'),
    );
    if (!validationStatus.valid && validationStatus.error) {
      const message: string = getExcelImportUserErrorMessage(
        validationStatus.error,
      );
      showSnackbar({
        message,
        type: 'ERROR',
        client,
      });
      setOpenEstablishmentChoice(false);
    } else {
      createBeneficiaries({
        variables: {
          input: users.map((user) => {
            const { userRole, establishmentId, ...rest } = user;
            return {
              ...rest,
              etablishmentId: establishmentId,
            };
          }),
        },
        update: onCreateBeneficiariesUpdate,
      }).then(() => {
        refetch();
      });
    }
  };

  return (
    <Box className={classes.root}>
      <Row>
        <Col xs={12} xl={9} lg={8} md={6}>
          <Paper className={classes.paper}>
            <SearchInput
              onChange={onHandleSearchKey}
              value={searchKey}
              placeholder="Recherchez un bénéficiaire"
            />
          </Paper>
        </Col>
        {userRole === 'SUPER_ADMIN' && (
          <Col xs={12} xl={3} lg={4} md={6}>
            <Paper className={classes.paper}>
              <Typography className={classes.labelFilter}>
                Filtre de recherche :
              </Typography>
              <SearchFilter
                filterControls={INPUT_FILTERS}
                filter={filter}
                setFilter={setFilter}
              />
            </Paper>
          </Col>
        )}
      </Row>
      <Box className={classes.beneficiaryList}>
        <CustomTable
          toolbar
          selectable
          data={data}
          columns={columns}
          toolbarActions={ACTIONS}
          loadingData={loadingBenefList}
          onHandleAction={onHandleAction}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          toolbarButtons={benefToolbarButtons}
          isLeftContent={true}
          toolbarProps={{
            toolbarTitle: 'Liste des bénéficiaires',
          }}
        />
        <UserFormDialog
          userRole="beneficiary"
          open={openCreateUserDialog}
          title="Ajout d'un bénéficiaire"
          onSubmit={onHandleAddUser}
          data={inputData}
          loadingButton={loadingCreateBenef}
          activeEstablishmentField={true}
          handleClose={() => {
            setOpenCreateUserDialog(false);
          }}
          displayUnitLife={establishmentType !== 'DOMICILE'}
        />
        <UserFormDialog
          userRole="beneficiary"
          open={openUpdateUserDialog}
          title="Modification d'un bénéficiaire"
          onSubmit={onHandleUpdateUser}
          data={inputUpdateData}
          loadingButton={loadingUpdateBenef}
          activeEstablishmentField={true}
          buttonLabel="Enregistrer"
          handleClose={() => {
            setOpenUpdateUserDialog(false);
          }}
          displayUnitLife={establishmentType !== 'DOMICILE'}
        />
        <ConfirmationDialog
          title="Suppression"
          message="Etes-vous sûr de vouloir supprimer ce bénéficiaire?"
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={onDeleteBeneficiary}
          loadingConfirmation={loadingDeleteBeneficiary}
        />
        <EstablishmentChoice
          open={openEstablishmentChoice}
          setOpen={setOpenEstablishmentChoice}
          onChooseEstablishment={onChooseEstablishment}
        />
      </Box>
    </Box>
  );
};

export default withRouter(EnhancedBeneficiairy);
