import { ApolloClient } from 'apollo-boost';
import { User } from '../types/user';
import { UserValidationState } from '../types/user-validator.types';
import { showSnackbar } from './snackbar.util';
import {
  getCreateEstablishmentUserValidation,
  getDefaultUserValidators,
  getUserValidationDefaultErrorMessages,
  validateUser,
} from './user-validator.utils';

export const handleUserListValidation = (
  user: User,
  client: ApolloClient<object>,
  onSuccess: () => void,
) => {
  const userValidationState: UserValidationState = validateUser(
    user,
    getDefaultUserValidators(user.userRole),
  );
  if (userValidationState.valid) {
    onSuccess();
  } else if (userValidationState.error) {
    const errorMessage: string = getUserValidationDefaultErrorMessages(
      userValidationState.error,
    );
    showSnackbar({
      type: 'ERROR',
      message: errorMessage,
      client,
    });
  }
};

export const handleEstablishmentCreateUserListValidation = (
  user: User,
  client: ApolloClient<object>,
  onSuccess: () => void,
) => {
  const userValidationState: UserValidationState = validateUser(
    user,
    getCreateEstablishmentUserValidation(user.userRole),
  );
  if (userValidationState.valid) {
    onSuccess();
  } else if (userValidationState.error) {
    const errorMessage: string = getUserValidationDefaultErrorMessages(
      userValidationState.error,
    );
    showSnackbar({
      type: 'ERROR',
      message: errorMessage,
      client,
    });
  }
};
