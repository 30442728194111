import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import { ExecutionResult } from 'graphql';
import { UPDATE_INTERVENTION_REPORT } from '../../../../graphql/intervention-report/mutation';
import {
  UpdateInterventionReport,
  UpdateInterventionReportVariables,
} from '../../../../graphql/intervention-report/types/UpdateInterventionReport';
import { useUploadSignature } from '../../../../hooks/upload-image.hooks';
import { InterventionReportEditData } from '../../interfaces/intervention-report.interfaces';

export type UseUpdateInterventionReportHook = (
  options?: MutationHookOptions<
    UpdateInterventionReport,
    UpdateInterventionReportVariables
  >,
) => [
  (
    interventionReport: InterventionReportEditData,
  ) => Promise<ExecutionResult<UpdateInterventionReport>>,
  {
    loading: boolean;
  },
];

export const useUpdateInterventionReport: UseUpdateInterventionReportHook = (
  options?: MutationHookOptions<
    UpdateInterventionReport,
    UpdateInterventionReportVariables
  >,
) => {
  const [updateInterventionReport, { loading }] = useMutation<
    UpdateInterventionReport,
    UpdateInterventionReportVariables
  >(UPDATE_INTERVENTION_REPORT, options);
  const uploadSignature = useUploadSignature();
  return [
    async (interventionReport: InterventionReportEditData) => {
      const {
        dateStart,
        dateEnd,
        signature,
        signatureFile,
        id,
        planningId,
      } = interventionReport;
      const url = signatureFile && (await uploadSignature(signatureFile));
      return updateInterventionReport({
        variables: {
          input: {
            id,
            dateStart,
            dateEnd,
            signature: url || signature,
            planningId,
          },
        },
      });
    },
    { loading },
  ];
};
