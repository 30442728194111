import gql from 'graphql-tag';

export const GET_PLANNING_DATA = gql`
  query GetPlanningData {
    planningData @client {
      __typename
      id
      title
      prestation
      location
      dateStart
      dateEnd
      hourStart
      hourEnd
      recurrence
      workers
      beneficiaries
      establishmentId
      unitLife
      comment
      afterOccurence
      periodicityType
      periodicityRepetition
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      dateEndType
    }
  }
`;
