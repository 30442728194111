import moment from 'moment';
import {
  InterventionPlanningEnum,
  InterventionReportFilter,
} from '../../../graphql/types/graphql-global-types';
import {
  InterventionReportFilterRecord,
  SelectOptionsType,
} from '../../../types';
import { InterventionReportData } from '../../../types/intervention-report.types';
import { isValidDate } from '../../../utils/date.utils';
import { recordToOptions } from '../../../utils/filter.utils';
import { InterventionFilter } from '../interfaces/intervention-report.interfaces';
import { rapportTypes, statusOptions } from '../../../constants/status';
import { InterventionReports } from '../../../graphql/intervention-report/types/InterventionReports';
interface InterventionReportsControlRecords {
  establishmentsRecord: Record<number, string>;
  beneficiariesRecord: Record<number, string>;
  workersRecord: Record<number, string>;
}

const buildInterventionReportsControlRecords = (
  interventionReports: any,
): InterventionReportsControlRecords => {
  let workersRecord: Record<number, string> = {};
  let beneficiariesRecord: Record<number, string> = {};
  let establishmentsRecord: Record<number, string> = {};

  if (interventionReports && interventionReports.getFilter) {
    interventionReports.getFilter.etablishments.forEach((etab: any) => {
      establishmentsRecord[etab.id] = etab.name;
    });

    interventionReports.getFilter.workers.forEach((worker: any) => {
      workersRecord[worker.user.id] =
        worker.user.lastName + ' ' + worker.user.firstName;
    });

    interventionReports.getFilter.beneficiaries.forEach((beneficiary: any) => {
      beneficiariesRecord[beneficiary.user.id] =
        beneficiary.user.lastName + ' ' + beneficiary.user.firstName;
    });
  }

  return {
    workersRecord,
    beneficiariesRecord,
    establishmentsRecord,
  };
};

export const selectFilterOptions = (
  interventionReports: InterventionReportData[],
): InterventionReportFilterRecord => {
  const { workersRecord, beneficiariesRecord, establishmentsRecord } =
    buildInterventionReportsControlRecords(interventionReports);
  return {
    worker: recordToOptions(workersRecord),
    beneficiary: recordToOptions(beneficiariesRecord),
    establishment: recordToOptions(establishmentsRecord),
    status: statusOptions,
    rapportTypes: rapportTypes,
  };
};

const convertToISOString = (date: any): string | null => {
  const formatedDate = moment(date, 'YYYY-MM-DD').toDate();
  if (isValidDate(formatedDate)) {
    return formatedDate.toISOString();
  }
  return null;
};

const convertToInterventionPlanningEnum = (
  status: number,
): InterventionPlanningEnum[] | null => {
  switch (status) {
    case 0:
      return [
        InterventionPlanningEnum.COMPLETED,
        InterventionPlanningEnum.IN_PROGRESS,
        InterventionPlanningEnum.TODO,
        InterventionPlanningEnum.WAITING_VALIDATION,
      ];
    case 1:
      return [InterventionPlanningEnum.COMPLETED];
    case -1:
      return [
        InterventionPlanningEnum.IN_PROGRESS,
        InterventionPlanningEnum.TODO,
        InterventionPlanningEnum.WAITING_VALIDATION,
      ];
    default:
      return null;
  }
};

export const toQueryFilter = (
  filter: InterventionFilter,
): InterventionReportFilter => ({
  dateStart: convertToISOString(filter.dateStart),
  dateEnd: convertToISOString(filter.dateEnd),
  workerIds: filter.worker && [filter.worker.value as number],
  beneficiaryIds: filter.beneficiary && [filter.beneficiary.value as number],
  establishmentIds: filter.establishment && [
    filter.establishment.value as number,
  ],
  status:
    filter.status &&
    convertToInterventionPlanningEnum(filter.status.value as number),
  skipFilter: filter.skipFilter,
  takeFilter: filter.takeFilter,
  rapportTypes: filter.rapportTypes?.value ? +filter.rapportTypes.value : null,
});
