import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0),

      [theme.breakpoints.down(768)]: {
        height: 'auto',
        flexDirection: 'column',
        margin: theme.spacing(1, 0, 1),
      },
    },
    headerText: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '100%',

      [theme.breakpoints.down(768)]: {
        marginBottom: 8,
      },
    },
    title: {
      fontSize: 24,
      color: theme.palette.primary.main,
      textAlign: 'center',
      width: '100%',
      alignSelf: 'center',

      [theme.breakpoints.down(450)]: {
        fontSize: 18,
      },
    },
    backButton: {
      textTransform: 'initial',
      width: 'auto',

      '& .MuiButton-label': {
        display: 'flex',
        justifyContent: 'flex-start',
      },
      [theme.breakpoints.down(768)]: {
        width: 'auto',
      },
    },
    text: {
      [theme.breakpoints.down(768)]: {
        display: 'none',
      },
    },
    button: {
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    white: {
      color: theme.palette.common.white,
    },
  }),
);

export default useStyles;
