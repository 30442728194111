import { Prestation_prestationById } from "../../../graphql/prestation/types/Prestation";


export interface PrestationExcelFormat{
    libelle: string;
    etablissement: string;
}

export const formatPrestationData = (
    prestation: Prestation_prestationById,
): PrestationExcelFormat | {} => {
    if(!prestation) return {};

    return{
        libelle: (prestation.libelle) || '',
        etablissement: (prestation.etablishment?.name && prestation.etablishment.name) || '',
    };
};