import React, { FC, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { useParams } from 'react-router-dom';
import EstablishmentCreate from '../../EstablishmentAdd/EstablishmentCreate';

interface EstablishmentChildCreateProps {}
interface ParamsState {
  id: string;
}

const EstablishmentChildCreate: FC<EstablishmentChildCreateProps> = (props) => {
  const { id } = useParams<ParamsState>();
  if (!id) {
    return <></>;
  }
  return (
    <EstablishmentCreate
      establishmentType="RESIDENCE_CHILD"
      rootEstablishmentId={+id}
    />
  );
};

export default EstablishmentChildCreate;
