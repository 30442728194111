import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down(1386)]: {
        padding: '0 24px',
      },
      [theme.breakpoints.down(386)]: {
        padding: '0 16px',
      },
    },
    content: {
      minHeight: 400,
      marginBottom: 100,
      marginTop: 8,
    },
    footer: {},
    actionButton: {
      height: 48,
      padding: theme.spacing(0, 4),
      margin: theme.spacing(0, 1),
      [theme.breakpoints.down(416)]: {
        height: 40,
        padding: theme.spacing(0, 2),
      },
    },
    lowercase: {
      textTransform: 'initial',
    },
    itemText: {},
  }),
);

export default useStyles;
