import { Administrator_administrator } from '../../../graphql/administrator/types/Administrator';

export interface AdministratorExcelFormat {
  nom: string;
  prenom: string;
  email: string;
  tel_mobile: string;
  tel_fixe: string;
  fonction: string;
  statut: string;
  nb_etablissement: number;
}

export const formatAdministratorData = (
  administrator: Administrator_administrator,
): AdministratorExcelFormat | {} => {
  if (!administrator) return {};

  return {
    nom: (administrator.user && administrator.user.firstName) || '',
    prenom: (administrator.user && administrator.user.lastName) || '',
    email: (administrator.user && administrator.user.email) || '',
    tel_mobile: (administrator.user && administrator.user.mobilePhone) || '',
    tel_fixe: (administrator.user && administrator.user.phone) || '',
    fonction: (administrator.user && administrator.user.userFunction) || '',
    statut:
      administrator.user && administrator.user.enabled ? 'Activé' : 'Désactivé',
    nb_etablissement:
      (administrator.etablishments && administrator.etablishments.length) || 0,
  };
};
