import React, { FC, useState, useEffect, useContext } from 'react';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { useQuery, useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import CustomTable from '../../../../../../Common/CustomTable';
import useStyles from './styles';
import { HeadCell } from '../../../../../../../types/table';
import UnitLifeList from './UnitLifeList';
import { DATE_FORMAT } from '../../../../../../../constants/format';
import { GET_PLANNING_DATA } from '../../../../../../../graphql/common/planning/query';
import { GET_UNIT_LIFE_BY_IDS } from '../../../../../../../graphql/unit/query';
import {
  UnitLifesByIds,
  UnitLifesByIdsVariables,
  UnitLifesByIds_unitLifesByIds,
} from '../../../../../../../graphql/unit/types/UnitLifesByIds';
import ConfirmationDialog from '../../../../../../Common/ConfirmationDialog';
import { GET_BENEFICIARIES_BY_MULTIPLE_UNITLIFE } from '../../../../../../../graphql/beneficiary/query';
import {
  BeneficiariesByMultipleUniLife,
  BeneficiariesByMultipleUniLifeVariables,
} from '../../../../../../../graphql/beneficiary/types/BeneficiariesByMultipleUniLife';
import { SnackbarType } from '../../../../../../../types/snackbar';
import { displaySnackbar } from '../../../../../../../common/snackbar';
import { EstablishmentContext } from '../../../../../../Context';
import { PlanningDataType } from '../../../../../../../types/planning';

interface ChooseByUnitLifeProps {
  selectedItems: number[];
  setSelectedItems: any;
  inputData: PlanningDataType;
  setInputData: React.Dispatch<React.SetStateAction<any>>;
}

const ChooseByUnitLife: FC<ChooseByUnitLifeProps & RouteComponentProps> = (
  props,
) => {
  const classes = useStyles();
  const location = useLocation();
  const client = useApolloClient();
  const { selectedItems, setSelectedItems, history, inputData, setInputData } =
    props;
  const [open, setOpen] = useState<boolean>(false);
  const [unitLifes, setUnitLifes] = useState<
    (UnitLifesByIds_unitLifesByIds | null)[]
  >([]);
  const { establishmentId } = useContext(EstablishmentContext);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const planningData = client.readQuery({ query: GET_PLANNING_DATA });
  const [isDeleteMany, setIsDeleteMany] = useState<boolean>(false);
  const [unitLifeId, setUnitLifeId] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const [getBeneficiariesByMultipleUnitLife] = useLazyQuery<
    BeneficiariesByMultipleUniLife,
    BeneficiariesByMultipleUniLifeVariables
  >(GET_BENEFICIARIES_BY_MULTIPLE_UNITLIFE, {
    onCompleted: (data: BeneficiariesByMultipleUniLife) => {
      if (data && data.beneficiariesByMultipleUniLife) {
        let benefList = data.beneficiariesByMultipleUniLife;
        let benefIdArray: number[] = [];
        benefList.forEach((benef) => {
          if (benef && benef.id) {
            benefIdArray.push(benef.id);
          }
        });
        client.writeQuery({
          query: GET_PLANNING_DATA,
          data: {
            planningData: {
              ...planningData.planningData,
              beneficiaries: benefIdArray,
            },
          },
        });
        setInputData((prevState: any) => ({
          ...prevState,
          beneficiaries: benefIdArray,
        }));
      }
    },
  });
  const { loading, data: unitLifeData } = useQuery<
    UnitLifesByIds,
    UnitLifesByIdsVariables
  >(GET_UNIT_LIFE_BY_IDS, {
    variables: {
      ids:
        planningData &&
        planningData.planningData &&
        planningData.planningData.unitLife,
    },
  });

  useEffect(() => {
    if (selectedItems.length !== 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  useEffect(() => {
    if (unitLifeData && unitLifeData.unitLifesByIds) {
      setUnitLifes(unitLifeData.unitLifesByIds);
    }
  }, [unitLifeData]);

  useEffect(() => {
    if (location.hash.replace('#', '') === 'choix_unite_vie') {
      setOpen(true);
    }
  }, [location]);

  const onDeleteUnitLife = () => {
    if (isDeleteMany === false) {
      removeOneUnitLife();
    } else {
      removeMultipleUnitLife();
    }
  };

  const handleClickDelete = () => {
    setIsDeleteMany(true);
    setOpenConfirmDialog(true);
  };

  const removeOneUnitLife = () => {
    if (
      planningData &&
      planningData.planningData &&
      planningData.planningData.unitLife
    ) {
      let allUnitLifes = planningData.planningData.unitLife;
      let removedUnitLife = [unitLifeId];

      const newUnitLifeList = allUnitLifes.filter(
        (item: any) => !removedUnitLife.includes(item),
      );
      client.writeQuery({
        query: GET_PLANNING_DATA,
        data: {
          planningData: {
            ...planningData.planningData,
            unitLife: newUnitLifeList,
          },
        },
      });

      getBeneficiariesByMultipleUnitLife({
        variables: {
          unitLifes: newUnitLifeList,
          etablishmentId: establishmentId,
        },
      });
      setUnitLifeId(0);
      setUnitLifes(newUnitLifeList);
      snackbarData.type = 'SUCCESS';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = `L'unité de vie a été supprimée`;
      displaySnackbar(client, snackbarData);
      setOpenConfirmDialog(false);
    }
  };

  const removeMultipleUnitLife = () => {
    if (
      planningData &&
      planningData.planningData &&
      planningData.planningData.unitLife
    ) {
      let allUnitLifes = planningData.planningData.unitLife;
      let removedUnitLife = selectedItems;
      const newUnitLifeList = allUnitLifes.filter(
        (item: any) => !removedUnitLife.includes(item),
      );
      client.writeQuery({
        query: GET_PLANNING_DATA,
        data: {
          planningData: {
            ...planningData.planningData,
            unitLife: newUnitLifeList,
          },
        },
      });
      getBeneficiariesByMultipleUnitLife({
        variables: {
          unitLifes: newUnitLifeList,
          etablishmentId: establishmentId,
        },
      });
      setIsDeleteMany(false);
      setIsDisabled(false);
      setUnitLifes(newUnitLifeList);
      snackbarData.type = 'SUCCESS';
      snackbarData.autoHideDuration = 5000;
      if (selectedItems.length <= 1) {
        snackbarData.message = `L'unité de vie a été supprimée`;
      } else {
        snackbarData.message = `Les unités de vie ont été supprimées`;
      }
      displaySnackbar(client, snackbarData);
      setOpenConfirmDialog(false);
    }
  };

  const handleDelete = (id: number) => {
    setIsDeleteMany(false);
    setUnitLifeId(id);
    setOpenConfirmDialog(true);
  };

  const handleSelectUnitLife = () => {
    history.push(`${location.pathname}${location.search}#choix_unite_vie`);
  };

  const columns: HeadCell[] = [
    {
      name: 'date',
      label: 'Date',
      disablePadding: false,
      renderer: (value: any) => {
        return (
          (value &&
            value.createdAt &&
            moment(value.createdAt).format(DATE_FORMAT)) ||
          '-'
        );
      },
    },
    {
      name: 'unitLife',
      label: 'Unité de vie',
      minWidth: 120,
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.name) || '-';
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex" justifyContent="center">
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e: any) => {
                e.stopPropagation();
                handleDelete(value.id);
              }}
              disabled={isDisabled}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const toolbarButtons = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="select-unitlife"
      onClick={handleSelectUnitLife}
    >
      Sélectionner la ou les unités de vie
    </Button>,
  ];

  const toolbarActionContent = [
    <Button
      variant="contained"
      color="default"
      name="remove-beneficiary"
      disabled={selectedItems.length == 0}
      onClick={handleClickDelete}
    >
      Supprimer
    </Button>,
  ];

  return (
    <Box>
      <CustomTable
        toolbar
        selectable
        columns={columns}
        data={unitLifes}
        loadingData={false}
        maxWidth={1200}
        toolbarButtons={toolbarButtons}
        toolbarActionContent={toolbarActionContent}
        toolbarStyles={{ width: 'auto' }}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        useFlexToolbar
        toolbarClasses={classes.flowRow}
      />
      <UnitLifeList
        open={open}
        setOpen={setOpen}
        inputData={inputData}
        setInputData={setInputData}
      />
      <ConfirmationDialog
        title="Suppression"
        message="Etes-vous sûr de vouloir supprimer cette (ces) unité(s) de vie?"
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteUnitLife}
        loadingConfirmation={false}
      />
    </Box>
  );
};

export default withRouter(ChooseByUnitLife);
