import {
  EstablishmentNewEditStep,
  EstablishmentType,
} from '../../../../../types/establishment';
import { UserContextRole } from '../../../../Context/UserContext';
import { SUPER_ADMIMNISTRATOR_STEPS } from './SuperAdministatorSteps';
import { ADMINISTRATOR_STEPS } from './AdministratorSteps';

export const composeSteps = (
  establishmentType: EstablishmentType,
  userContextRole: UserContextRole,
): EstablishmentNewEditStep[] => {
  switch (userContextRole) {
    case 'SUPER_ADMIN':
      return SUPER_ADMIMNISTRATOR_STEPS[establishmentType];
    case 'ADMIN':
      return ADMINISTRATOR_STEPS[establishmentType];
    default:
      return [];
  }
};
