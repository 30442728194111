import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
      minWidth: 100,
      [theme.breakpoints.down(650)]: {
        marginLeft: 8,
      },
    },
    name: {
      fontSize: 14,
      fontWeight: 'bold',
      color: '#002B67',
      marginBottom: -4,
      [theme.breakpoints.down(650)]: {
        color: '#ffffff',
      },
    },
    role: {
      fontSize: 14,
      color: '#9E9E9E',
    },
  }),
);

export default useStyles;
