import { QueryHookOptions, useLazyQuery, useQuery } from '@apollo/react-hooks';
import { ApolloError, ApolloQueryResult } from 'apollo-boost';
import moment from 'moment';
import { useEffect } from 'react';
import { exportData } from '../../../common/excel';
import { GET_INTERVENTION_REPORTS } from '../../../graphql/intervention-report/query';
import {
  InterventionReports,
  InterventionReportsVariables,
  InterventionReports_interventionReports,
  InterventionReports_interventionReports_data,
} from '../../../graphql/intervention-report/types/InterventionReports';
import { InterventionReportData } from '../../../types/intervention-report.types';
import { formatDate, toHoursAndMinute } from '../../../utils/date.utils';
// import { toInterventionReportData } from '../utils/intervention-report-list.utils';

type GetInterventionReportsHook = (
  options?: QueryHookOptions<InterventionReports, InterventionReportsVariables>,
) => {
  // data: InterventionReportData[] | null;
  data: any | null;
  refetch: (
    variables?: InterventionReportsVariables | undefined,
  ) => Promise<ApolloQueryResult<InterventionReports>>;
  loading: boolean;
  error: ApolloError | undefined;
};

export const useGetInterventionReports: GetInterventionReportsHook = (
  options?: QueryHookOptions<InterventionReports, InterventionReportsVariables>,
) => {
  const { data, refetch, loading, error } = useQuery<
    InterventionReports,
    InterventionReportsVariables
  >(GET_INTERVENTION_REPORTS, options);
  // let uniqueData: any[] = data?.interventionReports as any[];
  if (!data) {
    return {
      data: null,
      refetch,
      loading,
      error,
    };
  }
  if (error) {
    return { data: null, refetch, loading, error };
  }

  let newData = data.interventionReports.data;

  if (
    options &&
    options.variables &&
    options.variables.filter &&
    options.variables.filter.workerIds
  ) {
    newData =
      data.interventionReports &&
      data.interventionReports.data &&
      data.interventionReports.data.filter(
        (InterventionReport) =>
          options &&
          InterventionReport &&
          options.variables &&
          options.variables.filter &&
          options.variables.filter.workerIds, //&&
        //options.variables.filter.workerIds[0] ==
        // toInterventionReportData(
        //   data.interventionReports,
        //   InterventionReport,
        // ).worker.id,
      );
  }

  return {
    data: {
      interventionReports: {
        count: data.interventionReports.count,
        data:
          newData &&
          newData.map(
            (InterventionReport) => InterventionReport, //&&
            // toInterventionReportData(
            //   data.interventionReports,
            //   InterventionReport,
            // ),
          ),
        error,
        __typename: 'InterventionsData',
      },
    },
    refetch,
    loading,
    error,
  };
};

const labels: Record<string, string> = {
  IN_PROGRESS: 'En cours',
  WAITING_VALIDATION: 'En cours de validation',
  COMPLETED: 'Terminé',
  CANCELED: 'Annulé',
  TODO: 'A faire',
};

const formatInterventionReportData = (
  interventionReport: InterventionReportData,
) => ({
  Titre: interventionReport?.planning?.title || interventionReport?.name || '-',
  Statut:
    labels[interventionReport?.intervention?.interventionStatus?.name || ''] ||
    '-',
  Etablissement: interventionReport.establishment.name,
  Prestation: interventionReport.prestation,
  Intervenant: interventionReport.worker.name,
  Bénéficiaire: interventionReport.beneficiary.name,
  Date: formatDate(interventionReport.dateStart),
  Durée: toHoursAndMinute(interventionReport.duration),
});

export const useExportExcelInterventionReports = () => {
  const [exportInterventionReports] = useLazyQuery<
    InterventionReports,
    InterventionReportsVariables
  >(GET_INTERVENTION_REPORTS, {
    onCompleted: (data: InterventionReports) => {
      exportData({
        csvData:
          data.interventionReports &&
          data.interventionReports.data &&
          data.interventionReports.data.map(
            (interventionReport) => interventionReport, //&&
            // formatInterventionReportData(
            //   toInterventionReportData(
            //     data.interventionReports,
            //     interventionReport,
            //   ),
            // ),
          ),
        fileName: `Rapports d'intervention`,
        sheetName: `Rapports d'intervention`,
      });
    },
  });
  return (filter: any) => {
    exportInterventionReports({
      variables: {
        filter,
      },
    });
  };
};

// export const useLazyQueryGetInterventionReports = () => {
//   const [getInterventionReport, {data}] = useLazyQuery<
//     InterventionReports,
//     InterventionReportsVariables
//   >(GET_INTERVENTION_REPORTS);

//   return async (filter: any)  => {
//     await getInterventionReport({
//       variables: {
//         filter
//       },
//     })
//     return data?.interventionReports.data
//   };
// };
