import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';

import useStyles from './styles';
import { ToolbarProps } from '../../../../types/table';

interface TableToolbarProps extends ToolbarProps {
  numSelected: number;
  rowCount: number;
  toolbarStyles?: any;
  hideSelectedText?: boolean;
  toolbarActionContent?: any;
  isLeftContent?: boolean;
  xlCol: number[];
}

/**
 * TableToolbarProps
 * @param props TableToolbarProps
 */
const TableToolbar = (props: TableToolbarProps) => {
  const { numSelected, rowCount, toolbarTitle, xlCol } = props;
  const { onHandleAction, toolbarActions, toolbarStyles } = props;
  const { toolbarButtons, toolbarActionContent, hideSelectedText } = props;
  const classes = useStyles();

  return (
    <Toolbar className={classes.root}>
      <Row className={classes.row}>
        <Col
          className={`${classes.colSpacing} ${classes.buttons} ${props.isLeftContent ? classes.leftCol : {}}`}
          xs={12}
          xl={xlCol[0] || 8}
          lg={12}
        >
          <Box className={classes.actionTableToolbar}>
            {toolbarTitle && (
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {toolbarTitle}
              </Typography>
            )}
            {toolbarButtons &&
              toolbarButtons.map((button: any, index: number) => (
                <span key={index} className={classes.marginRight}>
                  {button}
                </span>
              ))}
          </Box>
        </Col>

        <Col
          className={`${classes.colSpacing} ${classes.actions}`}
          style={toolbarStyles}
          xs={12}
          xl={xlCol[1] || 4}
          lg={12}
        >
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            {!hideSelectedText && (
              <Typography
                className={classes.subTitle}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected}/{rowCount} sélectionnés ({rowCount})
              </Typography>
            )}
            <Box
              style={{
                width:
                  (toolbarStyles &&
                    toolbarStyles.width !== undefined &&
                    toolbarStyles.width) ||
                  '181px',
              }}
              zIndex="10"
            >
              {toolbarActionContent &&
                toolbarActionContent.map((content: any, index: number) => (
                  <span key={index}>{content}</span>
                ))}
              {toolbarActions && (
                <Select
                  placeholder="Action groupée"
                  styles={{
                    singleValue: (base) => ({ ...base, color: '#424242' }),
                    valueContainer: (base) => ({
                      ...base,
                      color: '#424242',
                      width: '100%',
                      fontSize: '14px',
                      backgroundColor: '#fff',
                    }),
                  }}
                  options={toolbarActions}
                  onChange={onHandleAction}
                  noOptionsMessage={() => 'Aucune option'}
                />
              )}
            </Box>
          </Box>
        </Col>
      </Row>
    </Toolbar>
  );
};

export default TableToolbar;
