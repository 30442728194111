import gql from 'graphql-tag';

import { WORKER_FRAGMENT, ESTABLISHMENT_FRAGMENT } from './fragment';

export const CREATE_WORKER = gql`
  mutation CreateWorker(
    $firstName: String!
    $email: String!
    $phone: String
    $mobilePhone: String
    $enabled: Boolean
    $userFunction: String
    $lastName: String!
    $establishmentId: Int
    $skipMail: Boolean
  ) {
    createWorker(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        mobilePhone: $mobilePhone
        enabled: $enabled
        userFunction: $userFunction
        etablishmentId: $establishmentId
        skipMail: $skipMail
      }
    ) {
      action
      success
      message
      data {
        ...WorkerInfo
        etablishment {
          ...EtablishmentInfo
        }
      }
    }
  }
  ${WORKER_FRAGMENT}
  ${ESTABLISHMENT_FRAGMENT}
`;

export const CREATE_WORKERS = gql`
  mutation CreateWorkers($input: [CreateWorkerInput!]!) {
    createWorkers(input: $input) {
      action
      success
      message
      data {
        ...WorkerInfo
        etablishment {
          ...EtablishmentInfo
        }
      }
    }
  }
  ${WORKER_FRAGMENT}
  ${ESTABLISHMENT_FRAGMENT}
`;

export const UPDATE_WORKER = gql`
  mutation UpdateWorker(
    $id: Int!
    $firstName: String
    $email: String
    $phone: String
    $mobilePhone: String
    $enabled: Boolean
    $userFunction: String
    $lastName: String
    $establishmentId: Int
  ) {
    updateWorker(
      input: {
        id: $id
        firstName: $firstName
        email: $email
        phone: $phone
        mobilePhone: $mobilePhone
        enabled: $enabled
        userFunction: $userFunction
        lastName: $lastName
        establishmentId: $establishmentId
      }
    ) {
      ...WorkerInfo
      etablishment {
        ...EtablishmentInfo
      }
    }
  }
  ${WORKER_FRAGMENT}
  ${ESTABLISHMENT_FRAGMENT}
`;

export const UPDATE_WORKER_STATUS = gql`
  mutation UpdateWorkerStatus($ids: [Int]!, $status: Boolean!) {
    updateWorkerStatus(ids: $ids, status: $status) {
      ...WorkerInfo
    }
  }
  ${WORKER_FRAGMENT}
`;

export const DELETE_WORKER = gql`
  mutation DeleteWorker($id: Int!) {
    deleteWorker(id: $id) {
      id
    }
  }
`;

export const DELETE_WORKERS = gql`
  mutation DeleteWorkers($ids: [Int]!) {
    deleteWorkers(ids: $ids) {
      id
    }
  }
`;
