import React, { FC } from 'react';
import Button from '@material-ui/core/Button';

import useStyles from './styles';

interface UploadButtonProps {
  accept?: string;
  multiple?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  [key: string]: any;
}

const UploadButton: FC<UploadButtonProps> = (props) => {
  const { children, accept, multiple, onChange, onClick } = props;
  const classes = useStyles();

  return (
    <span>
      <input
        accept={accept || '*'}
        style={{ display: 'none' }}
        id="contained-button-file"
        multiple={multiple || false}
        type="file"
        onChange={onChange}
        value={''}
      />
      <label htmlFor="contained-button-file" className={classes.label}>
        <Button {...props} component="span" onClick={onClick}>
          {children}
        </Button>
      </label>
    </span>
  );
};

export default UploadButton;
