import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import EstablishmentAddHeader from '../Header';
import ChoiceBox from './ChoiceBox';
import { ChoiceType } from '../../../../types/establishment';
import useStyles from './styles';

interface EstablishmentChoiceProps {
  setType?: (key: string) => void;
  noTitle?: boolean;
  noParent?: boolean;
}

const EstablishmentChoice: FC<EstablishmentChoiceProps> = (props) => {
  const classes = useStyles();
  const { setType, noTitle, noParent } = props;

  const parentChoices: ChoiceType[] = [
    {
      id: 'RESIDENCE_ROOT',
      title: 'Organisation',
      imgSource: 'root.png',
      onClick: setType,
    },
    {
      id: 'RESIDENCE',
      title: 'Résidence',
      imgSource: 'residence.png',
      onClick: setType,
    },
    {
      id: 'HOME',
      title: 'Domicile',
      imgSource: 'home.png',
      onClick: setType,
    },
  ];

  const childChoices: ChoiceType[] = [
    {
      id: 'RESIDENCE',
      title: 'Résidence',
      imgSource: 'residence.png',
      onClick: setType,
    },
    {
      id: 'HOME',
      title: 'Domicile',
      imgSource: 'home.png',
      onClick: setType,
    },
  ];

  const choices = noParent ? childChoices : parentChoices;

  return (
    <Box>
      {noTitle ? (
        <></>
      ) : (
        <EstablishmentAddHeader
          title="Choix d'établissement"
          description="Créez un établissement en fonction des types ci-dessous"
        />
      )}

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.root}
      >

        {choices.map((choice: ChoiceType, index: number) => (
          <Grid item key={index}>
            <ChoiceBox {...{ ...choice }} type={choices.length == 2 ? 'ADMIN': 'SUPER_ADMIN'} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EstablishmentChoice;
