import { User, Beneficiary, UserRole } from '../types/user';
import {
  UserValidationState,
  UserValidationError,
  UserValidatorFunc,
  UserValidators,
  BeneficiaryValidatorState,
} from '../types/user-validator.types';
import { isValidEmail } from '../common';
import {
  EMPTY_STRING_ERRORS,
  INVALID_PHONE_ERROR,
  USER_DEFAULT_VALIDATION_MESSAGES,
} from '../constants/user-validation';
import { EXCEL_IMPORT_USERS_VALIDATION_MESSAGES } from '../constants/excel-import-user.constant';
import FileSaver from 'file-saver';
import moment from 'moment';

export const requiredString: UserValidatorFunc = (user, key) => {
  const value = user[key as keyof User];
  if (!value) {
    return {
      valid: false,
      error: EMPTY_STRING_ERRORS[key],
    };
  }
  return {
    valid: true,
  };
};

export const phoneValidator: UserValidatorFunc = (user, key) => {
  const value: any = user[key as keyof User];
  if (!value) {
    return {
      valid: false,
      error: EMPTY_STRING_ERRORS[key],
    };
  }
  if (isNaN(+value) || value.length < 7 || value.length > 16) {
    return {
      valid: false,
      error: INVALID_PHONE_ERROR[key],
    };
  }
  return {
    valid: true,
  };
};

export const validateEmail: UserValidatorFunc = (user) => {
  if (!isValidEmail(user.email)) {
    return {
      valid: false,
      error: UserValidationError.INVALID_EMAIL,
    };
  }
  return {
    valid: true,
  };
};

export const validateUserFunction: UserValidatorFunc = (user) => {
  if (user && user.userFunction && Number(user.userFunction.toString())) {
    return {
      valid: false,
      error: UserValidationError.NOT_TYPE_NUMBER,
    };
  }
  return {
    valid: true,
  };
};

export const validateGuardian: UserValidatorFunc = (user) => {
  const beneficiary: Beneficiary = user as Beneficiary;
  if (beneficiary.guardianEnabled) {
    return requiredString(beneficiary, 'guardianName');
  }
  return {
    valid: true,
  };
};

export const validateEstablishmentId: UserValidatorFunc = (user) => {
  if (!user.establishmentId) {
    return {
      valid: false,
      error: UserValidationError.NO_ESTABLISHMENT,
    };
  }
  return {
    valid: true,
  };
};

export const validateUnitLife: UserValidatorFunc = (user) => {
  const beneficiary: Beneficiary = user as Beneficiary;
  if (beneficiary.guardianEnabled) {
    return requiredString(beneficiary, 'guardianName');
  }
  return {
    valid: true,
  };
};

export const validateBirthDate: UserValidatorFunc = (user) => {
  const beneficiary: Beneficiary = user as Beneficiary;

  if (beneficiary.birthDate.toString() == 'Invalid Date') {
    return {
      valid: false,
      error: UserValidationError.NOT_VALID_DATE,
    };
  } else if (moment(beneficiary.birthDate).isAfter(new Date())) {
    return {
      valid: false,
      error: UserValidationError.BIRTHDATE_NOT_VALID,
    };
  }

  return {
    valid: true,
  };
};

const BENEFICIARY_VALIDATORS: Partial<
  Record<keyof Beneficiary, UserValidatorFunc[]>
> = {
  guardianEnabled: [validateGuardian],
  birthDate: [validateBirthDate],
  unitLife: [],
};

const USER_VALIDATORS: UserValidators = {
  firstName: [],
  lastName: [requiredString],
  email: [requiredString, validateEmail],
  phone: [],
  mobilePhone: [],
  userRole: [],
  userFunction: [],
  establishmentId: [validateEstablishmentId],
};

const CREATE_ESTABLISHMENT_USER_VALIDATORS: UserValidators = {
  ...USER_VALIDATORS,
  establishmentId: [],
};

const EXCEL_IMPORT_USER_VALIDATORS: UserValidators = {
  ...USER_VALIDATORS,
  establishmentId: [],
};

export const getDefaultUserValidators = (userRole: UserRole) => ({
  ...USER_VALIDATORS,
  ...(userRole === 'beneficiary' ? BENEFICIARY_VALIDATORS : {}),
});

export const getCreateEstablishmentUserValidation = (userRole: UserRole) => ({
  ...CREATE_ESTABLISHMENT_USER_VALIDATORS,
  ...(userRole === 'beneficiary' ? BENEFICIARY_VALIDATORS : {}),
});

export const getImportExcelValidators = (userRole: UserRole) => ({
  ...EXCEL_IMPORT_USER_VALIDATORS,
  ...(userRole === 'beneficiary' ? BENEFICIARY_VALIDATORS : {}),
});

export const validateUser = (
  user: User,
  userValidators: UserValidators,
): UserValidationState | BeneficiaryValidatorState => {
  const res: UserValidationState = {
    valid: true,
  };
  const userKeys: (keyof User)[] = Object.keys(user) as (keyof User)[];
  for (let key of userKeys) {
    const validators: UserValidatorFunc[] | undefined = userValidators[key];
    if (validators) {
      for (let validator of validators) {
        const userValidationState:
          | UserValidationState
          | BeneficiaryValidatorState = validator(user, key);
        if (!userValidationState.valid) {
          return userValidationState;
        }
      }
    }
  }
  return res;
};

export const getExcelImportUserErrorMessage = (error: UserValidationError) =>
  EXCEL_IMPORT_USERS_VALIDATION_MESSAGES[error];

export const getUserValidationDefaultErrorMessages = (
  error: UserValidationError,
) => USER_DEFAULT_VALIDATION_MESSAGES[error];

export const validateUsers = (
  users: User[],
  validator: UserValidators,
): UserValidationState => {
  for (let user of users) {
    const validationState: UserValidationState = validateUser(user, validator);
    if (!validationState.valid) {
      return validationState;
    }
  }
  return {
    valid: true,
  };
};
