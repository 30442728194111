import React, { FC, useState, useEffect, useContext } from 'react';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { useQuery, useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import moment from 'moment';

import CustomFullscreenDialog from '../../../../../../Common/CustomFullscreenDialog';
import useStyles from './styles';
import CustomTable from '../../../../../../Common/CustomTable';
import { HeadCell } from '../../../../../../../types/table';
import SearchInput from '../../../../../../Common/SearchInput';
import {
  UnitLifes_unitLifes,
  UnitLifes,
} from '../../../../../../../graphql/unit/types/UnitLifes';
import {
  GET_UNIT_LIFES,
  GET_UNIT_LIFES_BY_ETABLISHMENT,
} from '../../../../../../../graphql/unit/query';
import {
  GET_BENEFICIARIES_BY_MULTIPLE_UNITLIFE,
  GET_BENEFICIARIES_BY_UNITLIFE,
} from '../../../../../../../graphql/beneficiary/query';
import { DATE_FORMAT } from '../../../../../../../constants/format';
import { SnackbarType } from '../../../../../../../types/snackbar';
import { displaySnackbar } from '../../../../../../../common/snackbar';
import {
  BeneficiariesByUnitLife,
  BeneficiariesByUnitLifeVariables,
} from '../../../../../../../graphql/beneficiary/types/BeneficiariesByUnitLife';
import {
  BeneficiariesByMultipleUniLife,
  BeneficiariesByMultipleUniLifeVariables,
} from '../../../../../../../graphql/beneficiary/types/BeneficiariesByMultipleUniLife';
import { BeneficiariesByIds_beneficiariesByIds } from '../../../../../../../graphql/beneficiary/types/BeneficiariesByIds';
import { GET_PLANNING_DATA } from '../../../../../../../graphql/common/planning/query';
import { EstablishmentContext } from '../../../../../../Context';
import { PlanningDataType } from '../../../../../../../types/planning';
import { UnitLifeByEstablishment } from '../../../../../../../graphql/unit/types/UnitLifeByEstablishment';

interface UnitLifeListProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  inputData: PlanningDataType;
  setInputData: React.Dispatch<React.SetStateAction<any>>;
}

const UnitLifeList: FC<UnitLifeListProps & RouteComponentProps> = (props) => {
  const client = useApolloClient();
  const location = useLocation();
  const classes = useStyles();
  const { history, open, setOpen, setInputData } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const [unitData, setUnitData] = useState<(UnitLifes_unitLifes | null)[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [unitLifeId, setUnitLifeId] = useState<number>(0);
  const { establishmentId } = useContext(EstablishmentContext);
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  let filters = {
    name: searchKey.trim() || '',
  };

  const variables = {
    input: {
      filter: JSON.stringify({
        ...filters,
      }),
    },
  };

  const { data, loading } = useQuery<UnitLifeByEstablishment>(
    GET_UNIT_LIFES_BY_ETABLISHMENT,
    {
      variables: {
        establishmentId: establishmentId,
      },
    },
  );

  const [
    getBeneficiariesByUnitLife,
    { data: beneficiaryData, loading: beneficiaryLoading },
  ] = useLazyQuery<BeneficiariesByUnitLife, BeneficiariesByUnitLifeVariables>(
    GET_BENEFICIARIES_BY_UNITLIFE,
  );
  const planningData = client.readQuery({ query: GET_PLANNING_DATA });
  let benefInPlanning: number[] = [];

  const [
    getBeneficiariesByMultipleUnitLife,
    { data: benefMultipleData, loading: benefMultipleLoading },
  ] = useLazyQuery<
    BeneficiariesByMultipleUniLife,
    BeneficiariesByMultipleUniLifeVariables
  >(GET_BENEFICIARIES_BY_MULTIPLE_UNITLIFE, {
    onCompleted: (data) => {
      if (data && data.beneficiariesByMultipleUniLife) {
        const beneficiaryInUnitLife = data.beneficiariesByMultipleUniLife;
        let beneficiaryInPlanning: (number | undefined)[] = [];
        beneficiaryInUnitLife.map((beneficiary) => {
          beneficiaryInPlanning.push(beneficiary?.id);
        });
        client.writeQuery({
          query: GET_PLANNING_DATA,
          data: {
            planningData: {
              ...planningData.planningData,
              beneficiaries: beneficiaryInPlanning,
              unitLife: selectedItems,
            },
          },
        });
        setInputData((prevState: any) => ({
          ...prevState,
          beneficiaries: beneficiaryInPlanning,
        }));
        history.push(`${location.pathname}${location.search}`);
        setOpen(false);
      }
    },
  });

  useEffect(() => {
    if (data && data.unitLifeByEstablishment) {
      setUnitData(data.unitLifeByEstablishment);
    }
  }, [data]);

  const handleGoBack = () => {
    history.push(`${location.pathname}${location.search}`);
    setOpen(false);
  };

  const handleSubmitSelection = () => {
    if (selectedItems.length != 0) {
      getBeneficiariesByMultipleUnitLife({
        variables: {
          unitLifes: selectedItems,
          etablishmentId: establishmentId,
        },
      });
    } else {
      snackbarData.type = 'INFO';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'Veuillez sélectionner au moins une unité de vie';
      displaySnackbar(client, snackbarData);
    }
  };

  const onHandleSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKey(value);
  };

  const columns: HeadCell[] = [
    {
      name: 'createdAt',
      label: 'Date',
      disablePadding: false,
      minWidth: 100,
      renderer: (value: any) => {
        return (
          (value &&
            value.createdAt &&
            moment(value.createdAt).format(DATE_FORMAT)) ||
          '-'
        );
      },
    },
    {
      name: 'name',
      label: "Nom de l'unité de vie",
      disablePadding: false,
      minWidth: 200,
      renderer: (value: any) => {
        return (value && value.name) || '-';
      },
    },
  ];

  const toolbarActionContent = [
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="submit-selection"
      onClick={handleSubmitSelection}
    >
      Valider ma sélection
    </Button>,
  ];

  return (
    <CustomFullscreenDialog
      open={open}
      handleGoBack={handleGoBack}
      title="Sélection d'unité de vie"
    >
      <Box margin="auto" width="100%" maxWidth="1200px">
        <Paper className={classes.paper}>
          <SearchInput
            onChange={onHandleSearchKey}
            value={searchKey}
            placeholder="Recherchez une unité de vie"
          />
        </Paper>
        <CustomTable
          toolbar
          selectable
          hideSelectedText
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          maxWidth={1640}
          data={unitData}
          columns={columns}
          loadingData={loading}
          toolbarProps={{ toolbarTitle: 'Liste des unités de vie' }}
          toolbarActionContent={toolbarActionContent}
          toolbarStyles={{ width: '165px' }}
          useFlexToolbar
          toolbarClasses={classes.flowRowUnitLife}
        />
      </Box>
    </CustomFullscreenDialog>
  );
};

export default withRouter(UnitLifeList);
