import { convertExcelToJSON } from "../common/excel/converter";
import { Prestation } from "../types/prestation";

export type PrestationTypesKey = keyof Prestation;
export type PrestationFieldName = Record<PrestationTypesKey, string>;

export const fieldName: PrestationFieldName = {
    libelle: 'libelle',
    establishmentId: 'establishmentId',
  };

  const getValue = (
    value: string | number | boolean,
    key: PrestationTypesKey,
  ): string | number | boolean | Date => {
    switch (key) {
      case 'libelle':
        return '' + value;
      default:
        return value;
    }
  };

  export const getFromFile = async (
    file: File,
    emptyField?: Partial<Prestation>,
  ): Promise<Prestation[]> => {
    const res = await convertExcelToJSON(file);
    
    return convertToPrestations(res, fieldName, emptyField);
  };

  export const convertToPrestations = (
    fileObjs: any[],
    fieldName: PrestationFieldName,
    emptyField: Partial<Prestation> = {},
  ): Prestation[] =>
    fileObjs.map((fileObj) => convertToPrestation(fileObj, fieldName, emptyField));

    export const convertToPrestation = (
        fileObj: any,
        fieldName: PrestationFieldName,
        emptyField: Partial<Prestation> = {},
      ): Prestation =>
        Object.entries(fieldName).reduce(
          (res: Prestation, [key, fileKey]: [string, string]) => {
            const fileFieldValue: string = fileObj[fileKey];
            if (!fileFieldValue) {
              return res;
            }
            return {
              ...res,
              [key]: getValue(fileFieldValue, key as PrestationTypesKey),
            };
          },
          {} as Prestation
        );
  