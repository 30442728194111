import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'calc(100vh - 90px)',
      minHeight: 500,
    },
    container: {
      maxWidth: 400,
      textAlign: 'center',
    },
    titleMessage: {
      fontWeight: 'normal',
      fontSize: '2.6em',
      color: '#E70F18',
      marginBottom: theme.spacing(3),

      [theme.breakpoints.down(500)]: {
        fontSize: '2em',
      },
    },
    textMessage: {
      fontWeight: 'normal',
      fontSize: '1em',
      color: '#262A3E',
    },
    imgContainer: {
      width: 275,
      height: 275,
      margin: 'auto',
      marginBottom: theme.spacing(3),

      [theme.breakpoints.down(500)]: {
        width: 150,
        height: 150,
      },
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  }),
);

export default useStyles;
