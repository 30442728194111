import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import { ApolloClient, ApolloError } from 'apollo-boost';
import { UPDATE_ESTABLISHMENT } from '../../../../../graphql/etablishment/mutation';
import {
  UpdateEstablishment,
  UpdateEstablishmentVariables,
} from '../../../../../graphql/etablishment/types/UpdateEstablishment';
import {
  EstablishmentType,
  EtablishmentDataType,
} from '../../../../../types/establishment';
import { showSnackbar } from '../../../../../utils/snackbar.util';
import {
  EstablishmentAction,
  EstablishmentEditFinish,
} from '../reducer/establishment-edit.actions';

export const onEstablishmentDataReadError = (client: ApolloClient<object>) => (
  error: ApolloError,
) => {
  let message: string =
    "Une erreur s'est produite lors du chargement de l'établissement.";
  if (error.networkError) {
    message = 'Erreur de réseau. Veuillez vérifier votre connexion internet.';
  }
  showSnackbar({
    type: 'ERROR',
    client,
    message,
  });
};

const mapIntoIds = (input: any[]): any[] =>
  (input || []).map((item) => item.id);

export const toEstablishmentDataType = (data: any): EtablishmentDataType => {
  const {
    administrators,
    workers,
    beneficiaries,
    residences,
    etablishmentStatus,
    etablishmentType,
    etablishmentFunding,
    prestations,
    ...rest
  } = data;
  return {
    ...rest,
    administratorIds: mapIntoIds(administrators),
    workerIds: mapIntoIds(workers),
    beneficiaryIds: mapIntoIds(beneficiaries),
    residenceIds: mapIntoIds(residences),
    etablishmentStatusId: etablishmentStatus.id,
    etablishmentTypeId: etablishmentType.id,
    etablishmentFundingId: etablishmentFunding.id,
    prestations: prestations.split(',').filter((item: string) => !!item),
  };
};

export const onEstablishmentSaveError = (client: ApolloClient<object>) => (
  error: ApolloError,
) => {
  let message =
    "Une erreur s'est produite lors de l'enregistrement' de l'établissement.";

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    message = error.graphQLErrors[0].message;
  } else if (error.networkError) {
    message = 'Erreur de réseau. Veuillez vérifier votre connexion internet.';
  }
  showSnackbar({
    type: 'ERROR',
    client,
    message,
  });
};

export const onEstablishmentSaveComplete = (client: ApolloClient<object>) => (
  data: UpdateEstablishment,
) => {
  if (data && data.updateEtablishment) {
    showSnackbar({
      type: 'SUCCESS',
      message: 'Etablissement enregistré avec succès',
      client,
    });
  }
};
export interface UseUpdateEstablishmentArgs {
  options?: MutationHookOptions<
    UpdateEstablishment,
    UpdateEstablishmentVariables
  >;
}

export const updateEstablishmentOptions = (
  client: ApolloClient<object>,
): MutationHookOptions<UpdateEstablishment, UpdateEstablishmentVariables> => ({
  //onCompleted: onEstablishmentSaveComplete(client),
  onError: onEstablishmentSaveError(client),
});

export const finalizeEstablishmentOptions = (
  client: ApolloClient<object>,
  dispatch: React.Dispatch<EstablishmentAction>,
): MutationHookOptions<UpdateEstablishment, UpdateEstablishmentVariables> => ({
  onCompleted: () => {
    onEstablishmentSaveComplete(client);
    dispatch(new EstablishmentEditFinish());
  },
  onError: onEstablishmentSaveError(client),
});

export const useUpdateEstablishment = ({
  options,
}: UseUpdateEstablishmentArgs) => {
  const [updateEstablishment] = useMutation<
    UpdateEstablishment,
    UpdateEstablishmentVariables
  >(UPDATE_ESTABLISHMENT, options);
  return (data: EtablishmentDataType) => {
    const { __typename, prestations, ...rest } = data;
    const { picture, base64logo, ...filteredData } = rest;

    updateEstablishment({
      variables: {
        input: {
          ...filteredData,
          prestations: (prestations || []).join(','),
        },
      },
    });
  };
};
export const getEstablishmentType = (establishment: any): EstablishmentType => {
  const type =
    establishment &&
    establishment.etablishmentType &&
    establishment.etablishmentType.name;
  return type || 'DOMICILE';
};
