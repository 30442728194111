import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import { useLocation, withRouter, RouteComponentProps } from 'react-router-dom';

import useStyles from './styles';
import useQueryString from '../../../../hooks/useQueryString';

interface HeaderNavProps {
  title: string;
}

const HeaderNav: FC<HeaderNavProps & RouteComponentProps> = (props) => {
  const classes = useStyles();
  const { title, history } = props;
  const { pathname } = useLocation();
  const queryString = useQueryString();

  const handleGoBack = () => {
    const tabId = queryString.hasOwnProperty('tab')
      ? Number(queryString['tab'])
      : 0;
    history.push({ pathname, search: `?tab=${tabId}` });
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Box className={classes.headerText}>
        <Button
          color="default"
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={handleGoBack}
          className={classes.backButton}
        >
          <span className={classes.text}>Retour</span>
        </Button>
        <Typography className={classes.title} component="h2">
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default withRouter(HeaderNav);
