export interface MenuItemInterface {
  text: string;
  path: string;
}

const SuperAdminMenus: MenuItemInterface[] = [
  {
    text: 'Etablissements',
    path: '/etablissements',
  },
  {
    text: 'Administrateurs',
    path: '/administrateurs',
  },
  {
    text: 'Intervenants',
    path: '/intervenants',
  },
  {
    text: 'Bénéficiaires',
    path: '/beneficiaires',
  },
  {
    text: "Rapports d'intervention",
    path: '/rapports-intervention',
  },
  {
    text: "Prestations",
    path: '/prestations',
  },
];

const SuperAdminMenusOrganisation: MenuItemInterface[] = [
  {
    text: 'Organisation',
    path: '/etablissements',
  },
  {
    text: 'Administrateurs',
    path: '/administrateurs',
  },
  {
    text: "Rapports d'intervention",
    path: '/rapports-intervention',
  },
  {
    text: "Prestations",
    path: '/prestations',
  },
];

const AdminMenus: MenuItemInterface[] = [
  {
    text: 'Votre établissement',
    path: '/votre-etablissement',
  },
  {
    text: 'Intervenants',
    path: '/intervenants',
  },
  {
    text: 'Bénéficiaires',
    path: '/beneficiaires',
  },
  {
    text: "Prestations",
    path: '/prestations',
  },
];

const AdminMenusOrganisation: MenuItemInterface[] = [
  {
    text: 'Votre organisation',
    path: '/votre-etablissement',
  },
];

const IntervenantMenus: MenuItemInterface[] = [
  {
    text: 'Votre établissement',
    path: '/votre-etablissement',
  },
];

export const getMenus: Record<string, MenuItemInterface[]> = {
  SUPER_ADMIN: SuperAdminMenus,
  ADMIN: AdminMenus,
};

export const getMenusOrganisation = (
  role: string,
  type: string,
): MenuItemInterface[] => {
  switch (role) {
    case 'SUPER_ADMIN':
      return SuperAdminMenus;
      break;
    case 'Administrateur':
      if (type !== 'RESIDENCE_PARENT') return AdminMenus;
      return AdminMenusOrganisation;
      break;
    case 'Intervenant':
      return IntervenantMenus;
      break;
    default:
      return AdminMenus;
      break;
  }
};
