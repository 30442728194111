import React, { useState, FormEvent, ChangeEvent, useEffect } from 'react';
import { useMutation, useQuery, useApolloClient } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-boost';
import {
  Redirect,
  withRouter,
  RouteComponentProps,
  useParams,
} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { displaySnackbar } from '../../../common/snackbar';
import { SnackbarType } from '../../../types';
import useStyles from './styles';
import logo from '../../../assets/images/logo-neoptim.png';
import { INIT_PASSWORD, PasswordInputState } from '../../../types/password';
import { GET_ADMINISTRATOR } from '../../../graphql/administrator/query';
import {
  Administrator,
  AdministratorVariables,
} from '../../../graphql/administrator/types/Administrator';
import { CREATE_PASSWORD } from '../../../graphql/administrator/mutation';
import {
  CreatePassword,
  CreatePasswordVariables,
} from '../../../graphql/administrator/types/CreatePassword';
import {
  isAuthenticated,
  clearLocalStorage,
} from '../../../services/localStorage';
import Link from '@material-ui/core/Link';

interface CreatePasswordProps {}

/**
 * CreatePassword Component
 * @param props CreatePasswordProps
 */
const CreateAccountPassword = (
  props: CreatePasswordProps & RouteComponentProps,
) => {
  const { history } = props;
  const classes = useStyles();
  const { userId, token } = useParams();
  const client = useApolloClient();
  const [email, setEmail] = useState<string>('');
  const [passwords, setPasswords] = useState<PasswordInputState>(INIT_PASSWORD);
  const [error, setError] = useState<boolean>(false);
  const [matched, setMatched] = useState<boolean>(false);

  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  if (isAuthenticated()) {
    clearLocalStorage();
  }

  const isValid = (): boolean => {
    return (
      passwords.password !== '' &&
      passwords.password.length >= 8 &&
      passwords.passwordConfirm !== '' &&
      passwords.password === passwords.passwordConfirm
    );
  };

  const isNotMatched = (): boolean => {
    return (
      passwords.password !== '' &&
      passwords.passwordConfirm !== '' &&
      passwords.password !== passwords.passwordConfirm
    );
  };

  const { data: adminInfo } = useQuery<Administrator, AdministratorVariables>(
    GET_ADMINISTRATOR,
    { variables: { id: Number(userId) } },
  );

  useEffect(() => {
    if (adminInfo && adminInfo.administrator && adminInfo.administrator.user) {
      setEmail(adminInfo.administrator.user.email || '');
    }
  }, [adminInfo]);

  const [createPassword, { loading }] = useMutation<
    CreatePassword,
    CreatePasswordVariables
  >(CREATE_PASSWORD, {
    onCompleted: (_: any) => {},
    onError: (error: ApolloError) => {
      if (
        error.graphQLErrors &&
        error.graphQLErrors[0] &&
        error.graphQLErrors[0].message &&
        (error.graphQLErrors[0].message.includes('invalid signature') ||
          error.graphQLErrors[0].message.includes('jwt expired'))
      ) {
        snackbarData.type = 'WARNING';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Le lien pour la création du mot de passe a expiré.';
        displaySnackbar(client, snackbarData);
      } else {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Une erreur est survenue lors de la création du mot de passe.';
        displaySnackbar(client, snackbarData);
      }
    },
  });

  useEffect(() => {
    setMatched(isValid());
    setError(!!(passwords.password && passwords.password.trim().length < 8));
  }, [passwords]);

  const handleCreatePassword = async (
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();

    if (isValid()) {
      const res = await createPassword({
        variables: {
          userId: Number(userId),
          token,
          password: passwords.password,
        },
      });
      if (res && res.data && res.data.createPassword) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Votre mot de passe a été créé, vous pouvez maintenant vous connecter.';
        displaySnackbar(client, snackbarData);

        history.push({
          pathname: '/authentification',
          state: { email },
        });
      }
    } else {
      snackbarData.type = 'WARNING';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        passwords.password === '' && passwords.passwordConfirm === ''
          ? 'Veuillez remplir tous les champs.'
          : passwords.password.length < 8
          ? 'Le mot de passe doit contenir au moins 8 caractères au minimum'
          : 'Les deux mots de passe ne correspondent pas.';
      displaySnackbar(client, snackbarData);
    }
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setPasswords((prevState) => ({
      ...prevState,
      [name]: value.trim(),
    }));
  };

  const showError = matched ? !matched : isNotMatched();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.wrapForm}>
          <Box className={classes.logo}>
            <img src={logo} alt="Neoptim Logo" />
          </Box>
          <Box className={classes.title}>
            <Typography
              variant="h4"
              component="h1"
              className={classes.textTitle}
            >
              {email}
            </Typography>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              className={classes.descTitle}
            >
              Créer votre mot de passe
            </Typography>
          </Box>
          <form
            onSubmit={handleCreatePassword}
            className={classes.form}
            autoComplete="off"
          >
            <TextField
              label="Nouveau mot de passe"
              type="password"
              name="password"
              onChange={handleTextChange}
              variant="outlined"
              margin="normal"
              fullWidth
              error={error}
              helperText="Votre mot de passe doit contenir au moins 8 caractères au minimum."
              className={classes.passwordField}
            />
            <TextField
              label="Confirmer mot de passe"
              type="password"
              name="passwordConfirm"
              onChange={handleTextChange}
              variant="outlined"
              margin="normal"
              fullWidth
              error={showError}
              helperText={
                showError ? 'Les deux mots de passe ne correspondent pas.' : ''
              }
              className={showError ? classes.passwordField : classes.textField}
            />
            <div className={classes.buttonWrapper}>
              <Button
                type="submit"
                disabled={false}
                variant="contained"
                color="primary"
                className={classes.button}
                fullWidth
              >
                Créer
              </Button>
              {false && (
                <CircularProgress
                  size={24}
                  className={classes.lodingProgress}
                />
              )}
            </div>
            <Box className={classes.actions}>
              <Link
                className={classes.link}
                onClick={() => history.push('/forgot-password')}
              >
                Renvoyer le lien
              </Link>
            </Box>
          </form>
        </Box>
        <Typography
          variant="body2"
          component="footer"
          color="primary"
          align="center"
          className={classes.footer}
        >
          &copy; Copyright - Neoptim consulting
        </Typography>
      </Box>
    </Box>
  );
};

export default withRouter(CreateAccountPassword);
