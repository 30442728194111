import React, { FC, useState, useEffect, useContext } from 'react';
import Box from '@material-ui/core/Box';
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom';

import useStyles from './styles';
import EstablishmentDetailsTabs from '../../EstablishmentAdd/EstablishmentDetailsTabs';
import EstablishmentTabPanel from '../EstablishmentTabPanel';
import EstablishmentChildList from '../../../Establishment/EstablishmentChildList';
import { Tab } from '../../../../types/tabs';
import useQueryString from '../../../../hooks/useQueryString';
import { EstablishmentTypeType } from '../../../../types/establishment';
import { EtablishmentContributorsInfo_etablishment } from '../../../../graphql/etablishment/types/EtablishmentContributorsInfo';
import DownloadableDocument from '../../EstablishmentAdmin/DownloadableDocuments';
import UnitLife from '../../EstablishmentAdmin/UnitLife';
import Planning from '../../EstablishmentAdmin/Planning';
import UserContext from '../../../Context/UserContext';
import UserListDetails from '../UserListDetails';
import InterventionReports from './components/InterventionReports/InterventionReports';
import WorkerDetailsContainer from './container/WorkerDetailsContainer';
import Prestation from '../../../Prestation';

interface EstablishmentDetailsContentProps {
  type: EstablishmentTypeType | string;
  establishmentId: number;
  data: EtablishmentContributorsInfo_etablishment | null;
}

interface QueryString {
  tab: string;
  intervention_id: string;
}

const EstablishmentDetailsContent: FC<
  EstablishmentDetailsContentProps & RouteComponentProps
> = (props) => {
  const { history, type, data, establishmentId } = props;
  const user = useContext(UserContext);
  const classes = useStyles();
  const { pathname } = useLocation();
  const queryString = useQueryString<QueryString>();
  const [details, setDetails] =
    useState<EtablishmentContributorsInfo_etablishment | null>(data || null);
  const tabId = queryString.hasOwnProperty('tab')
    ? Number(queryString['tab'])
    : 0;
  const workerId = queryString.hasOwnProperty('intervenant_id')
    ? Number(queryString['intervenant_id'])
    : 0;
  const [value, setValue] = useState<number>(tabId);

  const handleClickWorkerItem = (id: number): void => {
    history.push({ pathname, search: `?tab=${tabId}&intervenant_id=${id}` });
  };

  const handleClickTab = (
    event: React.ChangeEvent<{}>,
    newValue: string | number,
  ) => {
    setValue(newValue as number);
    history.push({ pathname, search: `?tab=${newValue as number}` });
  };

  useEffect(() => {
    setDetails(data);
  });

  let SUPER_ADMIN_TABS: Tab[] = [
    {
      index: 0,
      value: 0,
      label: 'Activités',
      establishmentType: ['DOMICILE', 'RESIDENCE', 'RESIDENCE_CHILD'],
      panel: <Planning establishmentId={establishmentId} />,
    },
    {
      index: 1,
      value: 1,
      label: 'Administrateurs',
      establishmentType: [
        'DOMICILE',
        'RESIDENCE',
        'RESIDENCE_CHILD',
        'RESIDENCE_PARENT',
      ],
      panel: (
        <UserListDetails
          userRole="administrator"
          establishmentId={establishmentId}
        />
      ),
    },
    {
      index: 2,
      value: 2,
      label: 'Intervenants',
      establishmentType: [
        'DOMICILE',
        'RESIDENCE',
        'RESIDENCE_CHILD',
        'RESIDENCE_PARENT',
      ],
      panel:
        workerId && workerId > 0 ? (
          <WorkerDetailsContainer workerId={workerId} />
        ) : (
          <UserListDetails
            userRole="worker"
            establishmentId={establishmentId}
            handleClickRow={handleClickWorkerItem}
          />
        ),
    },
    {
      index: 3,
      value: 3,
      label: 'Bénéficiaires',
      establishmentType: [
        'DOMICILE',
        'RESIDENCE',
        'RESIDENCE_CHILD',
        'RESIDENCE_PARENT',
      ],
      panel: (
        <UserListDetails
          userRole="beneficiary"
          establishmentId={establishmentId}
        />
      ),
    },
    {
      index: 4,
      value: 4,
      label: "Rapports d'intervention",
      establishmentType: [
        'DOMICILE',
        'RESIDENCE',
        'RESIDENCE_CHILD',
        'RESIDENCE_PARENT',
      ],
      panel: <InterventionReports establishmentId={establishmentId} />,
    },
    {
      index: 5,
      value: 5,
      label: 'Résidence fille',
      establishmentType: ['RESIDENCE_PARENT'],
      panel: <EstablishmentChildList establishmentRootId={establishmentId} />,
    },
    {
      index: 5,
      value: 5,
      label: 'Prestations',
      establishmentType: [
        'DOMICILE',
        'RESIDENCE',
        'RESIDENCE_CHILD',
        'RESIDENCE_PARENT',
      ],
      panel: <Prestation establishmentId={establishmentId} />,
    },
  ];

  let SUPER_ADMIN_TABS_ORGANISATION: Tab[] = [
    {
      index: 3,
      value: 3,
      label: "Rapports d'intervention",
      establishmentType: [
        'DOMICILE',
        'RESIDENCE',
        'RESIDENCE_CHILD',
        'RESIDENCE_PARENT',
      ],
      panel: <InterventionReports establishmentId={establishmentId} />,
    },
    {
      index: 4,
      value: 4,
      label: 'Etablissement',
      establishmentType: ['RESIDENCE_PARENT'],
      panel: <EstablishmentChildList establishmentRootId={establishmentId} />,
    },
    {
      index: 5,
      value: 5,
      label: 'Prestations',
      establishmentType: ['DOMICILE', 'RESIDENCE', 'RESIDENCE_CHILD'],
      panel: <Prestation establishmentId={establishmentId} />,
    },
  ];

  let ADMIN_TABS_ORGANISATION: Tab[] = [
    {
      index: 3,
      value: 3,
      label: "Rapports d'intervention",
      establishmentType: [
        'DOMICILE',
        'RESIDENCE',
        'RESIDENCE_CHILD',
        'RESIDENCE_PARENT',
      ],
      panel: <InterventionReports establishmentId={establishmentId} />,
    },
    {
      index: 4,
      value: 4,
      label: 'Etablissement',
      establishmentType: ['RESIDENCE_PARENT'],
      panel: <EstablishmentChildList establishmentRootId={establishmentId} />,
    },
    // {
    //   index: 5,
    //   value: 5,
    //   label: 'Prestations',
    //   establishmentType: ['RESIDENCE_PARENT'],
    //   panel: <Prestation establishmentId={establishmentId} />,
    // },
  ];

  let ADMIN_TABS: Tab[] = [
    {
      index: 0,
      value: 0,
      label: 'Activités',
      establishmentType: ['DOMICILE', 'RESIDENCE', 'RESIDENCE_CHILD'],
      panel: <Planning establishmentId={establishmentId} />,
    },
    {
      index: 1,
      value: 1,
      label: 'Etablissements',
      establishmentType: ['RESIDENCE_PARENT'],
      panel: <EstablishmentChildList establishmentRootId={establishmentId} />,
    },
    {
      index: 2,
      value: 2,
      label: "Rapports d'intervention",
      establishmentType: ['DOMICILE', 'RESIDENCE', 'RESIDENCE_CHILD'],
      panel: <InterventionReports establishmentId={establishmentId} />,
    },
    {
      index: 3,
      value: 3,
      label: 'Documents téléchargeables',
      establishmentType: [
        'DOMICILE',
        'RESIDENCE',
        'RESIDENCE_CHILD',
        'RESIDENCE_PARENT',
      ],
      panel: <DownloadableDocument />,
    },
    {
      index: 4,
      value: 4,
      label: 'Unité de vie',
      establishmentType: ['RESIDENCE'],
      panel: <UnitLife establishmentId={establishmentId} />,
    },
    // {
    //   index: 5,
    //   value: 5,
    //   label: 'Prestations',
    //   establishmentType: ['RESIDENCE'],
    //   panel: <Prestation establishmentId={establishmentId} />,
    // },
  ];

  let WORKER_TABS: Tab[] = [
    {
      index: 0,
      value: 0,
      label: 'Activités',
      establishmentType: ['DOMICILE', 'RESIDENCE', 'RESIDENCE_CHILD'],
      panel: <Planning />,
    },
  ];

  const getTabs = (userRole: string) => {
    const tabs: Record<string, Tab[]> = {
      ADMIN: type === 'RESIDENCE_PARENT' ? ADMIN_TABS_ORGANISATION : ADMIN_TABS,
      SUPER_ADMIN:
        type === 'RESIDENCE_PARENT'
          ? SUPER_ADMIN_TABS_ORGANISATION
          : SUPER_ADMIN_TABS,
      WORKER: WORKER_TABS,
    };
    return tabs[userRole];
  };

  const filterTabs = (type: EstablishmentTypeType | string): Tab[] => {
    return (getTabs(user.userRole) || [])
      .filter((tab: Tab) =>
        tab.establishmentType.includes(type as EstablishmentTypeType),
      )
      .map((tab: Tab, index: number) => ({ ...tab, index, value: index }));
  };

  return (
    <Box className={classes.root}>
      <EstablishmentDetailsTabs
        tabs={filterTabs(type)}
        value={value}
        type={type}
        handleChange={handleClickTab}
      />
      <EstablishmentTabPanel
        tabs={filterTabs(type)}
        value={value}
        type={type}
      />
    </Box>
  );
};

export default withRouter(EstablishmentDetailsContent);
