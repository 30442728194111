import React, { FC, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import UserEditList from '../../EstablishmentAdd/EstablishmentEdit/UserEditList';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { GET_ETABLISHMENT } from '../../../../graphql/etablishment/query';
import { ApolloClient, ApolloError } from 'apollo-boost';
import { showSnackbar } from '../../../../utils/snackbar.util';
import { EtablishmentDataType } from '../../../../types/establishment';
import {
  onEstablishmentSaveError,
  toEstablishmentDataType,
  useUpdateEstablishment,
} from '../../EstablishmentAdd/EstablishmentEdit/utils/EstablishmentEdit.utils';
import { UserRole } from '../../../../types/user';

interface UserListDetailsProps {
  establishmentId: number;
  userRole: UserRole;
  handleClickRow?: (id: number) => void;
}

const UserListDetails: FC<UserListDetailsProps> = (props) => {
  const { establishmentId, userRole, handleClickRow } = props;
  const client = useApolloClient();
  const [
    establishment,
    setEstablishment,
  ] = useState<EtablishmentDataType | null>(null);
  const onEstablishmentDataReadError = (client: ApolloClient<object>) => (
    error: ApolloError,
  ) => {
    let message: string =
      "Une erreur s'est produite lors du chargement de l'établissement.";
    if (error.networkError) {
      message = 'Erreur de réseau. Veuillez vérifier votre connexion internet.';
    }
    showSnackbar({
      type: 'ERROR',
      client,
      message,
    });
  };

  const { data: establishmentData, loading: dataLoading } = useQuery(
    GET_ETABLISHMENT,
    {
      variables: { id: establishmentId },
      onError: onEstablishmentDataReadError(client),
    },
  );

  const updateEstablishment = useUpdateEstablishment({
    options: {
      onError: onEstablishmentSaveError(client),
    },
  });

  useEffect(() => {
    if (!dataLoading && establishmentData && establishmentData.etablishment) {
      const { etablishment: establishment } = establishmentData;
      const establishmentDataType: EtablishmentDataType = toEstablishmentDataType(
        establishment,
      );
      setEstablishment(establishmentDataType);
    }
  }, [establishmentData]);

  const getUserIds = (
    estab: EtablishmentDataType,
    userRole: UserRole,
  ): number[] => {
    if (userRole === 'administrator') {
      return estab.administratorIds;
    }
    if (userRole === 'beneficiary') {
      return estab.beneficiaryIds;
    }
    if (userRole === 'worker') {
      return estab.workerIds;
    }
    return [];
  };

  const onUserIdsChange = (ids: number[]) => {
    if (!establishment) {
      return;
    }
    if (userRole === 'administrator') {
      updateEstablishment({
        ...establishment,
        administratorIds: ids,
      });
    } else if (userRole === 'beneficiary') {
      updateEstablishment({
        ...establishment,
        beneficiaryIds: ids,
      });
    } else if (userRole === 'worker') {
      updateEstablishment({
        ...establishment,
        workerIds: ids,
      });
    }
  };

  if (!establishment) {
    return <></>;
  }

  return (
    <>
      <UserEditList
        establishmentId={establishmentId}
        usersIds={getUserIds(establishment, userRole)}
        onUsersListIdsChange={onUserIdsChange}
        userRole={userRole}
        handleClickRow={handleClickRow}
      />
    </>
  );
};

export default UserListDetails;
