import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { Beneficiary } from '../../../../../types/user';
import CustomSwitch from '../../../../Common/CustomSwitch';
import TextField from '@material-ui/core/TextField';
import Dropzone from '../../../../Common/Dropzone';
import { Box, Typography } from '@material-ui/core';
import { BENEFICIARY_DOCUMENTS } from '../../../../../constants/document';
import useStyles from './styles';

interface SelectedDocument {
  name: string;
  file: string;
}

interface BeneficiaryDialogContentProps {
  user: Beneficiary;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setSignatureFile: (args: any) => void;
  setSelectedDocuments: (value: SelectedDocument[]) => void;
  selectedDocuments: SelectedDocument[];
  beneficiaryDocuments?: string;
}

const BeneficiaryDialogContent: FC<BeneficiaryDialogContentProps> = (props) => {
  const {
    user,
    onChange,
    onCheckboxChange,
    setSignatureFile,
    selectedDocuments,
    setSelectedDocuments,
    beneficiaryDocuments,
  } = props;
  const { guardianEnabled = false, guardianName = '' } = user;
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

  const classes = useStyles();

  const insertDocument = (name: string, file: string) => {
    const fileExist = selectedDocuments.filter((doc) => doc.name == name);
    if (fileExist && fileExist.length > 0) {
      setSelectedDocuments([...selectedDocuments, { name, file }]);
    } else {
      setSelectedDocuments([...selectedDocuments, { name, file }]);
    }
  };

  const addToDocument = (name: string, file: string) => {
    insertDocument(name, file);
  };

  const removeToDocument = (name: string) => {
    insertDocument(name, '');
  };

  const getDocumentUrl = (name: string): string => {
    if (beneficiaryDocuments) {
      const documents = JSON.parse(beneficiaryDocuments);
      const document = documents.filter((item: any) => item.name == name);
      return document && document[0] && document[0].path
        ? document[0].path
        : '';
    }
    return '';
  };

  useEffect(() => {
    if (selectedFiles[0]) {
      setSignatureFile(selectedFiles[0]);
    }
  }, [selectedFiles]);
  return (
    <>
      <CustomSwitch
        label="Sous tutelle"
        labelChecked="Oui"
        labelUnchecked="Non"
        name="guardianEnabled"
        checked={guardianEnabled}
        onChange={onCheckboxChange}
      />
      {guardianEnabled && (
        <TextField
          label="Nom et prénom tuteur"
          name="guardianName"
          placeholder="Nom et prénom du tuteur"
          value={guardianName || ''}
          onChange={onChange}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
          margin="normal"
          autoComplete="off"
        />
      )}
      <Typography
        color="secondary"
        align="center"
        className={classes.textUpdatePassword}
      >
        Signature
      </Typography>
      <Dropzone
        accept="image/*"
        multiple={false}
        base64Img={''}
        pictureUrl={user.signature}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        enablePreview
        showAction
        height={120}
      />
      {BENEFICIARY_DOCUMENTS.map((document, index) => {
        return (
          <Box key={index}>
            <Typography
              color="secondary"
              align="center"
              className={classes.textUpdatePassword}
            >
              {document}
            </Typography>
            <Dropzone
              accept="image/*, .doc, .pdf, .txt, .docx, .xlsx"
              multiple={false}
              base64Img={''}
              isDownloadableDocument={true}
              pictureUrl={getDocumentUrl(document)}
              documentName={document}
              selectedFiles={selectedFiles}
              setSelecetedDocument={addToDocument}
              onDeleteDocument={removeToDocument}
              enablePreview
              label={'Glissez et déposez ici votre document'}
              showAction
              height={120}
            />
          </Box>
        );
      })}
    </>
  );
};

export default BeneficiaryDialogContent;
