import { useQuery } from '@apollo/react-hooks';
import React, { FC, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { GET_ETABLISHMENT_BY_USER_ID } from '../../../../graphql/etablishment/query';
import {
  GetEtablishmentByUserId,
  GetEtablishmentByUserIdVariables,
} from '../../../../graphql/etablishment/types/GetEtablishmentByUserId';
import EmptyPage from '../../../Common/EmptyPage';
import Loader from '../../../Common/Loader';
import { UserContext } from '../../../Context';
import EstablishmentAdmin from '../../EstablishmentAdmin/EstablishmentAdmin';

interface EstablishmentAdminRootProps {}

const EstablishmentAdminRoot: FC<EstablishmentAdminRootProps> = (props) => {
  const userContext = useContext(UserContext);
  const { loading, data: establishmentData } = useQuery<
    GetEtablishmentByUserId,
    GetEtablishmentByUserIdVariables
  >(GET_ETABLISHMENT_BY_USER_ID, { variables: { id: userContext.userId } });
  const establishmentId: number | null | undefined =
    establishmentData &&
    establishmentData.etablishmentByUserId &&
    establishmentData.etablishmentByUserId.id;
  if (loading) {
    return <Loader />;
  }
  if (!establishmentId || establishmentId === 0) {
    return (
      <EmptyPage
        title="Page établissement"
        text="Votre établissement n'est pas encore disponible. Merci de contacter l'administrateur Neoptim."
      />
    );
  }
  return (
    <EstablishmentAdmin establishmentId={establishmentId} hideTitle={true} />
  );
};

export default withRouter(EstablishmentAdminRoot);
