import React, { FC, useState, useEffect } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowIcon from '@material-ui/icons/ArrowBack';

import { FilterControlType } from '../../../../types';
import { HeadCell } from '../../../../types/table';
import SearchInput from '../../../Common/SearchInput';
import useStyles from './style';
import CustomTable from '../../../Common/CustomTable';
import { EstablishmentFilter } from '../../../../types/filters';
import SearchFilter from '../../../Common/SearchFilter';
import { GET_STATUS_BY_NAME } from '../../../../graphql/status/query';
import { GET_ETABLISHMENTS } from '../../../../graphql/etablishment/query';
import {
  GetStatusByName,
  GetStatusByNameVariables,
} from '../../../../graphql/status/types/GetStatusByName';
import {
  Etablishments,
  EtablishmentsVariables,
} from '../../../../graphql/etablishment/types/Etablishments';
import { GET_ETABLISHMENT_ROOT_DATA } from '../../../../graphql/common/etablishment/query';

interface EstablishmentRootChoiceProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const INPUT_FILTERS: FilterControlType[] = [
  {
    label: 'Code postal',
    name: 'postalCode',
    value: '',
    placeholder: 'Tapez le code postal',
    type: 'text',
  },
  {
    label: 'Ville',
    name: 'city',
    value: '',
    placeholder: 'Tapez une ville',
    type: 'text',
  },
];

const INIT_FILTER: EstablishmentFilter = {
  city: '',
  postalCode: '',
};

// const Transition = forwardRef((props: any, ref: any): any => (
//   <Slide direction="up" ref={ref} {...props} />
// ));

const normalizeFilter = (filters: EstablishmentFilter): any => {
  return {
    postalCode: filters.postalCode,
    enabled: filters.enabled,
    city: filters.city,
  };
};

const EstablishmentRootChoice: FC<
  EstablishmentRootChoiceProps & RouteComponentProps
> = (props) => {
  const { open, setOpen, history } = props;
  const classes = useStyles();
  const client = useApolloClient();
  const location = useLocation();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [filter, setFilter] = useState<EstablishmentFilter>(INIT_FILTER);

  const { data: etablishmentType } = useQuery<
    GetStatusByName,
    GetStatusByNameVariables
  >(GET_STATUS_BY_NAME, { variables: { name: 'RESIDENCE_PARENT' } });

  const filters = searchKey && {
    name: searchKey,
  };

  const variables = {
    input: {
      filter: JSON.stringify({
        ...normalizeFilter(filter),
        ...filters,
        etablishmentTypeId:
          etablishmentType &&
          etablishmentType.getStatusByName &&
          etablishmentType.getStatusByName.id,
      }),
    },
  };

  const { data: establishments, loading } = useQuery<
    Etablishments,
    EtablishmentsVariables
  >(GET_ETABLISHMENTS, { variables });

  useEffect(() => {
    if (establishments && establishments.etablishments)
      setData(establishments.etablishments);
  }, [establishments]);

  const columns: HeadCell[] = [
    {
      name: 'logo',
      label: 'Logo',
      disablePadding: false,
      renderer: (value: any) => {
        return <Avatar alt="logo" variant="rounded" src="" />;
      },
    },
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return value.name || '-';
      },
    },
    {
      name: 'address',
      label: 'Adresse',
      disablePadding: false,
      renderer: (value: any) => {
        return value.address || '-';
      },
    },
    {
      name: 'postalCode',
      label: 'Code postal',
      disablePadding: false,
      renderer: (value: any) => {
        return value.postalCode || '-';
      },
    },
    {
      name: 'City',
      label: 'Ville',
      disablePadding: false,
      renderer: (value: any) => {
        return value.city || '-';
      },
    },
    {
      name: 'actions',
      label: ' ',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex" justifyContent="center">
          <Button
            className={`${classes.white}`}
            variant="contained"
            color="secondary"
            name="set-root"
            onClick={(e) => {
              e.stopPropagation();
              handleSetEstablishment(value);
            }}
          >
            Définir
          </Button>
        </Box>
      ),
    },
  ];

  const onHandleSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKey(value);
  };

  const handleGoBack = () => {
    history.push(`${location.pathname}${location.search}`);
    setOpen(false);
  };

  const handleSetEstablishment = (value: any) => {
    client.writeQuery({
      query: GET_ETABLISHMENT_ROOT_DATA,
      data: {
        etablishmentRootData: {
          ...value,
          __typename: 'EtablishmentRootData',
        },
      },
    });
    // const data = client.readQuery({
    //   query: GET_ETABLISHMENT_ROOT_DATA,
    // });
    handleGoBack();
  };

  return (
    <Dialog
      fullScreen
      className={classes.rootDialog}
      open={open}
      // TransitionComponent={Transition as any}
    >
      <Box className={classes.dialogTitle}>
        <Button
          color="default"
          variant="text"
          startIcon={<ArrowIcon />}
          onClick={handleGoBack}
          style={{ textTransform: 'initial' }}
        >
          Retour
        </Button>
        <Typography
          component="h2"
          variant="h4"
          color="textPrimary"
          style={{ textAlign: 'center', width: '100%' }}
        >
          Choix de l'organisation
        </Typography>
      </Box>
      <DialogContent className={classes.dialogContent}>
        <Row>
          <Col xs={12} xl={6} lg={6} md={12}>
            <Paper className={classes.paper}>
              <SearchInput
                onChange={onHandleSearchKey}
                value={searchKey}
                placeholder="Recherchez une organisation"
              />
            </Paper>
          </Col>
          <Col xs={12} xl={6} lg={6} md={12}>
            <Paper className={classes.paper}>
              <Typography className={classes.labelFilter}>
                Filtre de recherche :
              </Typography>
              <SearchFilter
                filterControls={INPUT_FILTERS}
                filter={filter}
                setFilter={setFilter}
                overrideCols={{ xs: 12, xl: 6, lg: 6, md: 6, sm: 6 }}
              />
            </Paper>
          </Col>
        </Row>
        <Box
          display="flex"
          justifyContent="center"
          style={{ marginBottom: 100 }}
        >
          <CustomTable
            toolbar
            selectable={false}
            maxWidth={1080}
            data={data}
            columns={columns}
            toolbarProps={{
              toolbarTitle: 'Liste des organisations',
            }}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            loadingData={loading}
            hideSelectedText={true}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default withRouter(EstablishmentRootChoice);
