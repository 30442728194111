import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',

      [theme.breakpoints.down(639)]: {
        justifyContent: 'center',
      },
    },
  }),
);

export default useStyles;
