import React, { FC, useContext, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery, useApolloClient, useMutation } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import { ApolloError } from 'apollo-boost';

import EstablishmentHeader from '../Header';
import EstablishmentFooter from '../Footer';
import Stepper from '../../../Common/CustomStepper';
import useStyles from './style';
import { ETABLISHMENT_DATA } from '../../../../constants/etablishement';
import { EtablishmentDataType } from '../../../../types/establishment';
import { GET_ETABLISHMENT_DATA } from '../../../../graphql/common/etablishment/query';
import { CREATE_ESTABLISHMENT } from '../../../../graphql/etablishment/mutation';
import { GET_STATUS_BY_NAME } from '../../../../graphql/status/query';
import {
  CreateEstablishment,
  CreateEstablishmentVariables,
} from '../../../../graphql/etablishment/types/CreateEstablishment';
import {
  GetStatusByName,
  GetStatusByNameVariables,
} from '../../../../graphql/status/types/GetStatusByName';
import { SnackbarType } from '../../../../types/snackbar';
import { displaySnackbar } from '../../../../common/snackbar';
import { isValidPostalCode } from '../../../../common/validator';
import StepDisplayer from './StepDisplayer';
import getStepComponents from './Steps';
import StepperActions from './StepperActions';
import { GET_PRESIGNED_URL } from '../../../../graphql/fileUpload/mutations';
import {
  mapToIds,
  getCachedAdminData,
  getCachedBeneficiaryData,
  getCachedWorkerData,
} from '../../../../common/utils';
import ConfirmationDialog from '../../../Common/ConfirmationDialog/ConfirmationDialog';
import { EstablishmentContext } from '../../../Context/EstablishmentContext';

interface EstablishmentHomeProps {}

const STEPS = [
  'Rattachement <br/>à une organisation',
  'Informations <br/>de base',
  'Prestations  <br/>proposées',
  "Liste <br/>d'administrateur",
  "Liste <br/>d'intervenant",
  'Liste <br/>de bénéficiaire',
];

const EstablishmentHome: FC<EstablishmentHomeProps & RouteComponentProps> = (
  props,
) => {
  const { history } = props;
  const classes = useStyles();
  const client = useApolloClient();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [type, setType] = useState<string>('HOME');
  const [inputData, setInputData] =
    useState<EtablishmentDataType>(ETABLISHMENT_DATA);
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const { setNewEtablishmentId } = useContext(EstablishmentContext);
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const createOnError = (error: ApolloError) => {
    snackbarData.type = 'ERROR';
    snackbarData.autoHideDuration = 5000;
    snackbarData.message =
      "Une erreur s'est produite lors de la création de l'établissement.";

    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      const { message } = error.graphQLErrors[0];
      switch (message) {
      }
    } else if (error.networkError) {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        'Erreur de réseau. Veuillez vérifier votre connexion internet.';
    }
    displaySnackbar(client, snackbarData);
  };

  const createOnComplete = (data: CreateEstablishment) => {
    if (data && data.createEtablishment) {
      const id = data.createEtablishment.data?.id;
      setNewEtablishmentId(id!);
      client.writeQuery({
        query: GET_ETABLISHMENT_DATA,
        data: {
          etablishmentData: ETABLISHMENT_DATA,
        },
      });
    }
    snackbarData.type = 'SUCCESS';
    snackbarData.autoHideDuration = 5000;
    snackbarData.message = 'Votre établissement a été ajouté';
    displaySnackbar(client, snackbarData);
  };

  const [createEstablishment, { loading }] = useMutation<
    CreateEstablishment,
    CreateEstablishmentVariables
  >(CREATE_ESTABLISHMENT, {
    onCompleted: createOnComplete,
    onError: createOnError,
  });

  const { data: etabType } = useQuery<
    GetStatusByName,
    GetStatusByNameVariables
  >(GET_STATUS_BY_NAME, { variables: { name: 'DOMICILE' } });

  const { data: etabFunding } = useQuery<
    GetStatusByName,
    GetStatusByNameVariables
  >(GET_STATUS_BY_NAME, { variables: { name: 'OTHERS' } });

  const isSelectedEstablishmentRoot = (): boolean => {
    return inputData.residenceIds.length > 0 && inputData.residenceIds[0] != 0;
  };
  const [fileLoading, setFileLoading] = useState<boolean>(false);

  const isValid = (): boolean => {
    return !!(
      inputData.rcs.trim() &&
      inputData.name.trim() &&
      inputData.address.trim() &&
      inputData.city.trim() &&
      inputData.postalCode.trim() &&
      isValidPostalCode(inputData.postalCode)
    );
  };

  const isSelectedAdmin = () => {
    return true;
  };

  const isSelectedWorker = () => {
    return true;
  };

  const isValidPrestation = () => {
    return inputData.prestations.length > 0;
  };

  const isValidNextButton = (): boolean => {
    switch (activeStep) {
      case 0:
        return isSelectedEstablishmentRoot();
      case 1:
        return isValid();
      case 2:
        return isValidPrestation();
      case 3:
        return isSelectedAdmin();
      case 4:
        return isSelectedWorker();
      default:
        return false;
    }
  };

  const data = client.readQuery({
    query: GET_ETABLISHMENT_DATA,
  });

  const handleNextStep = () => {
    if (
      activeStep === 0 ||
      activeStep === 1 ||
      activeStep === 2 ||
      activeStep === 3 ||
      activeStep === 4 ||
      activeStep === 5
    ) {
      client.writeQuery({
        query: GET_ETABLISHMENT_DATA,
        data: {
          etablishmentData: {
            ...inputData,
            administrators: getCachedAdminData(data),
            workers: getCachedWorkerData(data),
            beneficiaries: getCachedBeneficiaryData(data),
          },
        },
      });
    }
    if (activeStep === 2) {
      setShowConfirmationDialog(true);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const [getUrl] = useMutation(GET_PRESIGNED_URL);

  const handleIgnoreStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const uploadLogo = async (file: File, dataEstablishement: any) => {
    setFileLoading(true);
    try {
      const data = await getUrl({
        variables: { fileName: file.name, folder: 'logo' },
      });
      if (data) {
        const { uploadFile } = data.data;
        const { url, uuid } = uploadFile;
        const res = await fetch(url, {
          method: 'PUT',
          body: file,
        });
        const dataUpdated = {
          etablishmentData: {
            ...dataEstablishement.etablishmentData,
            logo: url,
          },
        };
        create(dataUpdated);
      } else {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          "Une erreur s'est produite lors de la création de l'établissement.";
        displaySnackbar(client, snackbarData);
      }
    } catch (e) {
      console.error(e);
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la création de l'établissement.";
      displaySnackbar(client, snackbarData);
    } finally {
      setFileLoading(false);
    }
  };

  const handleFinishCreation = () => {
    history.push('/etablissements/ajout?type=SUCCESS');
  };

  const handleConfirm = () => {
    const data = client.readQuery({
      query: GET_ETABLISHMENT_DATA,
    });
    if (inputData.picture[0]) {
      uploadLogo(inputData.picture[0], data);
    } else {
      if (data) create(data);
    }
    setActiveStep(activeStep + 1);
    setShowConfirmationDialog(false);
  };

  const create = (data: any) => {
    if (data && data.etablishmentData) {
      const typeId =
        etabType && etabType.getStatusByName && etabType.getStatusByName.id;
      const fundingId =
        etabFunding &&
        etabFunding.getStatusByName &&
        etabFunding.getStatusByName.id;
      const {
        id,
        __typename,
        prestations,
        administrators,
        workers,
        beneficiaries,
        ...rest
      } = data.etablishmentData;
      const administratorIds = mapToIds(administrators);
      const workerIds = mapToIds(workers);
      const beneficiaryIds = mapToIds(beneficiaries);

      createEstablishment({
        variables: {
          input: {
            ...rest,
            prestations: prestations.join(','),
            etablishmentTypeId: typeId,
            etablishmentFundingId: fundingId,
            administratorIds,
            workerIds,
            beneficiaryIds,
          },
        },
        update: (cache, { data }) => {
          client.writeQuery({
            query: GET_ETABLISHMENT_DATA,
            data: {
              etablishmentData: ETABLISHMENT_DATA,
            },
          });
        },
      });
    }
  };

  return (
    <Box className={classes.root}>
      <EstablishmentHeader title="Etablissement DOMICILE">
        <Stepper steps={STEPS} activeStep={activeStep} />
      </EstablishmentHeader>
      <StepDisplayer
        components={getStepComponents(inputData, setInputData, type)}
        activeStep={activeStep}
      />
      <ConfirmationDialog
        title={`Cette étape permet de créer définitivement votre établissement`}
        message={`Confirmez-vous la création de celui-ci ?`}
        open={showConfirmationDialog}
        setOpen={setShowConfirmationDialog}
        onConfirm={handleConfirm}
      />
      <EstablishmentFooter>
        <StepperActions
          activeStep={activeStep}
          stepsLength={STEPS.length}
          handlePrevStep={handlePrevStep}
          handleNextStep={handleNextStep}
          handleFinish={handleFinishCreation}
          handleIgnoreStep={handleIgnoreStep}
          isValidNextButton={isValidNextButton()}
          finalizeButtonDisable={loading || fileLoading}
        />
      </EstablishmentFooter>
    </Box>
  );
};

export default withRouter(EstablishmentHome);
