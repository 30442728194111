import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Logo from '../../../assets/images/picto-neo-web.png';
import useStyles from './styles';

interface EmptyPageProps {
  title: string;
  text: string;
}

const EmptyPage: FC<EmptyPageProps> = (props) => {
  const classes = useStyles();
  const { title, text } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      className={classes.root}
    >
      <Box className={classes.container}>
        <Box className={classes.imgContainer}>
          <img src={Logo} alt="logo" className={classes.img} />
        </Box>
        <Typography variant="h4" className={classes.titleMessage}>
          {title}
        </Typography>
        <Typography variant="h4" className={classes.textMessage}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyPage;
