import moment from 'moment';
import 'moment/locale/fr';
import React, { FC, useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select, { ValueType } from 'react-select';
import MenuItem from '@material-ui/core/MenuItem';
import Dropzone from '../../../components/Common/Dropzone';
import DialogActions from '@material-ui/core/DialogActions';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import {
  ViewState,
  IntegratedEditing,
  EditingState,
  AppointmentModel,
  ChangeSet,
} from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  Appointments,
  Toolbar,
  ViewSwitcher,
  DateNavigator,
  AppointmentTooltip,
  AppointmentForm,
} from '@devexpress/dx-react-scheduler-material-ui';

import useStyles, { styles } from './styles';
import CustomAppointment from './CustomAppointment';
import AppointmentDetails from './AppointmentDetails';
import { GET_ETABLISHMENT_BY_USER_ID } from '../../../graphql/etablishment/query';
import { UPDATE_INTERVENTION_PLANNING_STATUS } from '../../../graphql/planning/mutation';
import { InterventionPlanningEnum } from '../../../graphql/types/graphql-global-types';
import { GET_PRESIGNED_URL } from '../../../graphql/fileUpload/mutations';
import {
  GetEtablishmentByUserId,
  GetEtablishmentByUserIdVariables,
} from '../../../graphql/etablishment/types/GetEtablishmentByUserId';
import ConfirmationDialog from '../../Common/ConfirmationDialog';
import UserContext from '../../Context/UserContext';
import { GET_PLANNING_BY_ETABLISHMENT } from '../../../graphql/planning/query';
import { isNumber } from 'lodash';
import {
  DELETE_PLANNING_IN_INTERVENTION,
  UPDATE_INTERVENTION_PLANNING,
} from '../../../graphql/planning/mutation';
import { SnackbarType } from '../../../types/snackbar';
import { displaySnackbar } from '../../../common/snackbar';
import {
  DeletePlanningInIntervention,
  DeletePlanningInInterventionVariables,
} from '../../../graphql/planning/types/DeletePlanningInIntervention';
import {
  UpdateInterventionPlanning,
  UpdateInterventionPlanningVariables,
} from '../../../graphql/planning/types/UpdateInterventionPlanning';

import { VALIDATE_INTERVENTION_PLANNING } from '../../../graphql/planning/mutation';
import Loader from '../Loader';
import SchedulePopupDetails from './SchedulePopupDetails/SchedulePopupDetails';
import { PlanningFilter } from '../../../types/filters';
moment.locale('fr');

interface SchedulerProps {
  appointments: AppointmentModel[];
  refetchAppointment: () => void;
  loadingData?: boolean;
  filter: PlanningFilter;
}

const APPOINTMENTS: AppointmentModel[] = [];
interface InputUpdate {
  id: number;
  dateStart?: string | Date;
  dateEnd?: string | Date;
}

const INPUT_DATA = {
  picture: '',
};

const EnhancedScheduler: FC<SchedulerProps> = (props) => {
  const { appointments, refetchAppointment, loadingData, filter } = props;
  const classes = useStyles();
  const [locale] = useState<string>('fr-FR');
  const client = useApolloClient();
  const [etablishmentId, setEtablishmentId] = useState<number>(0);
  const userContext = useContext(UserContext);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = useState<any>();
  const [openConfirmTerminateDialog, setOpenConfirmTerminateDialog] =
    useState<boolean>(false);
  const [interventionId, setInterventionId] = useState<number>(0);
  const [openInterventionValidation, setOpenInterventionValidation] =
    useState<boolean>(false);
  const [planningId, setPlanningId] = useState<number>(0);
  const [beneficiaires, setBeneficiaires] = useState([]);
  const [selectedBeneficiaire, setSelectedBeneficiaire] = useState<any>();
  const [inputBeneficiaireValue, setInputBeneficiaireValue] = useState();
  const [signature, setSignature] = useState('');
  const [etabType, setEtabType] = useState<string>('');
  const [inputData, setInputData] = useState(INPUT_DATA);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [singleAppointementData, setSingleAppointementData] = useState<any>();
  const [interventionPlanningId, setInterventionPlanningId] =
    useState<number>(0);
  const [validSignature, setValidSignature] = useState(false);
  const [planningStatus, setPlanningStatus] = useState<string>('');
  const [displayDeleteBtn, setDisplayDeleteBtn] = useState<boolean>(false);

  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const {
    loading: loadingEtab,
    data: etabData,
    refetch: refetchView,
  } = useQuery<GetEtablishmentByUserId, GetEtablishmentByUserIdVariables>(
    GET_ETABLISHMENT_BY_USER_ID,
    { variables: { id: userContext.userId } },
  );

  const [updatePlanning, { loading: loadingUpdateIntervention }] = useMutation<
    UpdateInterventionPlanning,
    UpdateInterventionPlanningVariables
  >(UPDATE_INTERVENTION_PLANNING, {
    onCompleted: (data: UpdateInterventionPlanning) => {
      snackbarData.type = 'SUCCESS';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'Activité modifiée avec succès';
      displaySnackbar(client, snackbarData);
      refetchView();
      refetchAppointment();
    },
  });

  const [validateInterventionPlanning] = useMutation(
    VALIDATE_INTERVENTION_PLANNING,
    {
      onCompleted: (data) => {
        setSignature('');
        setSelectedFiles([]);
        setSelectedBeneficiaire([]);
        refetchAppointment();
        setBeneficiaires([]);
        setOpenInterventionValidation(false);
        snackbarData.message = 'Intervention validée';
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        displaySnackbar(client, snackbarData);
      },
      onError: (error) => {
        setOpenInterventionValidation(false);
        snackbarData.message = 'Intervention non validée';
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        displaySnackbar(client, snackbarData);
      },
    },
  );

  useEffect(() => {
    if (
      etabData &&
      etabData.etablishmentByUserId &&
      etabData.etablishmentByUserId.id &&
      etabData.etablishmentByUserId.etablishmentType &&
      etabData.etablishmentByUserId.etablishmentType.name
    ) {
      setEtabType(etabData.etablishmentByUserId.etablishmentType.name);
      setEtablishmentId(etabData.etablishmentByUserId.id);
    }
  }, [etabData]);

  const [
    deletePlanningInIntervention,
    { loading: loadingDeletePlanningInIntervention },
  ] = useMutation<
    DeletePlanningInIntervention,
    DeletePlanningInInterventionVariables
  >(DELETE_PLANNING_IN_INTERVENTION, {
    onCompleted: (data: DeletePlanningInIntervention) => {
      snackbarData.type = 'SUCCESS';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = "L'activité a été effacée";
      displaySnackbar(client, snackbarData);
      setOpenConfirmDialog(false);
      refetchView();
      refetchAppointment();
      setPlanningId(0);
    },
  });

  const onCommitChanges = (changes: ChangeSet): void => {
    let interventionToUpdateId = 0;
    let allChange: [string, any][] = [];
    const { added, changed, deleted } = changes;
    if (changed) {
      let inputUpdate: InputUpdate = {
        id: 0,
      };
      Object.entries(changed).forEach((change) => {
        const [key, value] = change;
        interventionToUpdateId = parseInt(key);
        inputUpdate.id = interventionToUpdateId;
        allChange = Object.entries(value);
      });
      allChange.forEach((change) => {
        switch (change[0]) {
          case 'title':
            let title = {
              description: change[1],
            };
            inputUpdate = {
              ...inputUpdate,
              ...title,
            };
            break;
          case 'location':
            let location = {
              location: change[1],
            };
            inputUpdate = {
              ...inputUpdate,
              ...location,
            };
            break;
          case 'comment':
            let comment = {
              comment: change[1],
            };
            inputUpdate = {
              ...inputUpdate,
              ...comment,
            };
            break;
          case 'prestation':
            let prestation = {
              prestation: change[1],
            };
            inputUpdate = {
              ...inputUpdate,
              ...prestation,
            };
            break;
          case 'startDate':
            let startDate = {
              dateStart: change[1],
            };
            inputUpdate = {
              ...inputUpdate,
              ...startDate,
            };
            break;
          case 'endDate':
            let endDate = {
              dateEnd: change[1],
            };
            inputUpdate = {
              ...inputUpdate,
              ...endDate,
            };
            break;
          case 'checkedForAll':
            let allOccurencies = {
              allOccurencies: change[1],
            };
            inputUpdate = {
              ...inputUpdate,
              ...allOccurencies,
            };
            break;
          default:
            break;
        }
      });

      if (inputUpdate.dateStart && inputUpdate.dateEnd) {
        const diffDate = moment(inputUpdate.dateEnd).diff(
          moment(inputUpdate.dateStart),
          'minutes',
        );

        if (diffDate <= 0) {
          snackbarData.type = 'ERROR';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message =
            "Veuillez vérifier l'heure de début et l'heure de fin";
          displaySnackbar(client, snackbarData);
          return;
        }

        updatePlanning({
          variables: {
            input: inputUpdate,
          },
        });
      } else {
        updatePlanning({
          variables: {
            input: inputUpdate,
          },
        });
      }
    }
    if (deleted) {
      setOpenConfirmDialog(true);
      if (isNumber(deleted)) {
        setPlanningId(deleted);
      }
    }
  };

  const onDeletedPlanning = () => {
    deletePlanningInIntervention({
      variables: {
        id: planningId,
      },
    });
  };

  const [updateInterventionStatus] = useMutation(
    UPDATE_INTERVENTION_PLANNING_STATUS,
    {
      refetchQueries: [
        {
          query: GET_PLANNING_BY_ETABLISHMENT,
          variables: { etablishmentId: etablishmentId },
        },
      ],
      onCompleted: (data) => {
        setShowTooltip(false);
        if (data && data.updateInterventionPlanningStatus) {
          switch (data.updateInterventionPlanningStatus.status.name) {
            case 'IN_PROGRESS':
              snackbarData.message = "L'intervention a commencé";
              break;
            case 'WAITING_VALIDATION':
              snackbarData.message = 'Intervention en attente de validation';
              break;
            case 'COMPLETED':
              snackbarData.message = 'Intervention validée';
              break;
            case 'CANCELED':
              snackbarData.message = 'Intervention annulée';
              break;
            default:
              break;
          }
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          displaySnackbar(client, snackbarData);
        }
      },
      onError: (error) => {
        setShowTooltip(false);
      },
    },
  );

  const handleStartIntervention = async (id: number) => {
    await updateInterventionStatus({
      variables: { id, status: InterventionPlanningEnum.IN_PROGRESS },
    });
  };

  const handleStopIntervention = async (id: number) => {
    setInterventionId(id);
    setOpenConfirmTerminateDialog(true);
  };

  const handleValidateIntervention = async (id: number) => {
    if (etabType == 'RESIDENCE') {
      confirmValidateIntervention();
    } else {
      setOpenInterventionValidation(true);
    }
  };

  const [getUrl] = useMutation(GET_PRESIGNED_URL);

  const uploadLogo = async (file: File) => {
    getUrl({ variables: { fileName: file.name, folder: 'logo' } }).then(
      (data) => {
        if (data) {
          const { uploadFile } = data.data;
          const { url, uuid } = uploadFile;

          fetch(url, {
            method: 'PUT',
            body: file,
          }).then(async (res) => {
            await validateInterventionPlanning({
              variables: {
                input: {
                  planningId: interventionPlanningId,
                  dateTimeEnd: new Date(
                    singleAppointementData && singleAppointementData.endDate,
                  ),
                  dateTimeStart: new Date(
                    singleAppointementData && singleAppointementData.startDate,
                  ),
                  duration: moment(
                    singleAppointementData && singleAppointementData.endDate,
                  ).diff(
                    moment(
                      singleAppointementData &&
                        singleAppointementData.startDate,
                    ),
                    'minutes',
                  ),
                  signature: url,
                },
              },
            }).catch((error) => {});
          });
        }
      },
    );
  };

  const confirmValidateIntervention = async () => {
    if (selectedFiles && selectedFiles.length > 0) {
      uploadLogo(selectedFiles[0]);
    } else {
      if (
        singleAppointementData != undefined &&
        singleAppointementData.endDate &&
        singleAppointementData.startDate
      ) {
        await validateInterventionPlanning({
          variables: {
            input: {
              planningId: interventionPlanningId,
              dateTimeEnd: new Date(
                singleAppointementData && singleAppointementData.endDate,
              ),
              dateTimeStart: new Date(
                singleAppointementData && singleAppointementData.startDate,
              ),
              duration: moment(
                singleAppointementData && singleAppointementData.endDate,
              ).diff(
                moment(
                  singleAppointementData && singleAppointementData.startDate,
                ),
                'minutes',
              ),
              signature: selectedBeneficiaire && selectedBeneficiaire.signature,
            },
          },
        });
      }
    }
    // await updateInterventionStatus({variables: {id: interventionId , status: InterventionPlanningEnum.COMPLETED}})
  };

  const validateIntervention = async () => {
    setOpenConfirmTerminateDialog(false);
    await updateInterventionStatus({
      variables: {
        id: interventionId,
        status: InterventionPlanningEnum.WAITING_VALIDATION,
      },
    });
  };

  const handleCancelIntervention = async (id: number) => {
    await updateInterventionStatus({
      variables: { id, status: InterventionPlanningEnum.CANCELED },
    });
  };

  const renderCancelButton = (id: number) => (
    <Button
      variant="outlined"
      color="secondary"
      style={{ marginLeft: '2px', marginRight: '5px' }}
      onClick={(e) => handleCancelIntervention(id)}
    >
      Annuler l'intervention
    </Button>
  );

  useEffect(() => {
    if (!inputData.picture) {
      setSignature('');
      setValidSignature(false);
    }
  }, [inputData]);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setValidSignature(true);
    }
  }, [selectedFiles]);

  const DialogTitle = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;

    return (
      <MuiDialogTitle
        disableTypography
        className={classes.dialogTitle}
        {...other}
      >
        <Typography variant="h6" style={{ color: 'white' }}>
          {children}
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const onChangeBeneficiaire = (e: React.ChangeEvent<any>, newValue: any) => {
    if (newValue) {
      setSelectedBeneficiaire(newValue);
      setSignature(newValue.signature);
      setInputData({ ...inputData, picture: newValue.signature });

      if (newValue.signature) {
        setValidSignature(true);
      }
    }
  };

  const onInputChangeBeneficiaire = (
    e: React.ChangeEvent<any>,
    newValue: any,
  ) => {
    if (newValue) {
      setInputBeneficiaireValue(newValue);
    }
  };

  const userExistOnWorkers = (workers: any[], userId: number): boolean => {
    let inArray = false;
    workers.map((worker) => {
      if (worker && worker.userId == userId) {
        inArray = true;
      }
    });
    return inArray;
  };

  const setDataTooltip = (appointmentData: any, beneficiaires: any) => {
    setSingleAppointementData(appointmentData);
    setPlanningStatus(appointmentData.status);
    setInterventionPlanningId(appointmentData.id);
    setBeneficiaires(beneficiaires);
  };

  useEffect(() => {
    if (
      planningStatus !== InterventionPlanningEnum.WAITING_VALIDATION &&
      planningStatus !== InterventionPlanningEnum.COMPLETED
    ) {
      setDisplayDeleteBtn(true);
    } else {
      setDisplayDeleteBtn(false);
    }
  }, [planningStatus]);

  const handleChangeStatus = (
    item: ValueType<{ value: string; label: string }>,
  ) => {
    setCurrentStatus(item);
  };

  const handleApplyStatus = (id: number) => {
    const status = currentStatus && (currentStatus as any).value;
    switch (status) {
      case 'IN_PROGRESS':
        handleStartIntervention(id);
        break;
      case 'WAITING_VALIDATION':
        handleStopIntervention(id);
        break;
      case 'COMPLETED':
        handleValidateIntervention(id);
        break;
      case 'CANCELED':
        handleCancelIntervention(id);
        break;
    }
  };

  const tooltipContent = (props: any) => {
    const { appointmentData, formatDate, appointmentResources } = props;
    const beneficiaires = appointmentData.beneficiaries;

    setDataTooltip(appointmentData, beneficiaires);

    return (
      <Box>
        <SchedulePopupDetails data={appointmentData} />
        {(userContext.userRole === 'ADMIN' ||
          userContext.userRole === 'SUPER_ADMIN') &&
        ['TODO', 'IN_PROGRESS', 'WAITING_VALIDATION'].includes(
          appointmentData.status,
        ) ? (
          <Box className={classes.inlineFlexRow}>
            <Select
              menuPosition={'fixed'}
              menuPlacement={'bottom'}
              name="activityStatus"
              placeholder="Statut"
              className={classes.reactSelect}
              value={currentStatus}
              styles={{
                option: (base: any) => ({
                  ...base,
                  fontSize: '14px',
                  zIndex: 999,
                }),
                singleValue: (base: any) => ({ ...base, color: '#424242' }),
                valueContainer: (base: any) => ({
                  ...base,
                  color: '#424242',
                  width: '80%',
                  fontSize: '14px',
                  backgroundColor: '#fff',
                }),
              }}
              options={[
                { label: 'En cours', value: 'IN_PROGRESS' },
                { label: 'Terminé', value: 'WAITING_VALIDATION' },
                { label: 'Validé', value: 'COMPLETED' },
                { label: 'Annulé', value: 'CANCELED' },
              ]}
              onChange={handleChangeStatus}
              noOptionsMessage={() => 'Aucune option'}
            />
            <Button
              color="primary"
              variant="contained"
              disabled={!currentStatus}
              onClick={(e) => handleApplyStatus(appointmentData.id)}
            >
              Appliquer
            </Button>
          </Box>
        ) : (
          userContext.userRole != 'SUPER_ADMIN' &&
          userExistOnWorkers(appointmentData.workers, userContext.userId) && (
            <Box className={classes.bottomAction}>
              {appointmentData.status === 'TODO' && (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => handleStartIntervention(appointmentData.id)}
                    style={{ marginRight: 8 }}
                  >
                    Commencer
                  </Button>
                  {renderCancelButton(appointmentData.id)}
                </Box>
              )}
              {appointmentData.status === 'IN_PROGRESS' && (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => handleStopIntervention(appointmentData.id)}
                    style={{ marginRight: 8 }}
                  >
                    Terminer
                  </Button>
                  {renderCancelButton(appointmentData.id)}
                </Box>
              )}
              {appointmentData.status === 'WAITING_VALIDATION' && (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) =>
                      handleValidateIntervention(appointmentData.id)
                    }
                  >
                    Valider
                  </Button>
                </>
              )}
            </Box>
          )
        )}
        <ConfirmationDialog
          title="Intervention"
          message="Etes-vous sûr de vouloir terminer cette intervention? "
          open={openConfirmTerminateDialog}
          setOpen={setOpenConfirmTerminateDialog}
          onConfirm={validateIntervention}
          loadingConfirmation={false}
        />
      </Box>
    );
  };

  if (!!loadingData) {
    return <Loader />;
  }

  return (
    <Paper elevation={2} className={classes.root}>
      {appointments && (
        <Scheduler locale={locale} data={appointments}>
          <ViewState defaultCurrentDate={filter.d1?.toISOString()} />
          <EditingState onCommitChanges={onCommitChanges} />
          <IntegratedEditing />

          <MonthView displayName="Mois" />
          <DayView displayName="Jour" />
          <WeekView displayName="Semaine" startDayHour={0} endDayHour={24} />

          <Appointments appointmentComponent={CustomAppointment} />
          <AppointmentTooltip
            contentComponent={tooltipContent}
            showOpenButton
            showCloseButton
            showDeleteButton={displayDeleteBtn}
          />
          <AppointmentForm basicLayoutComponent={AppointmentDetails} />

          <Toolbar />
          <DateNavigator />
          <ViewSwitcher />
        </Scheduler>
      )}
      <ConfirmationDialog
        title="Suppression"
        message="Etes-vous sûr de vouloir supprimer cette activité?"
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeletedPlanning}
        loadingConfirmation={false}
      />
      <Dialog
        open={openInterventionValidation}
        fullWidth
        style={{ margin: 'auto' }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenInterventionValidation(false);
          }}
        >
          Validation de l'Intervention
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            options={beneficiaires}
            value={selectedBeneficiaire}
            onChange={onChangeBeneficiaire}
            inputValue={inputBeneficiaireValue}
            onInputChange={onInputChangeBeneficiaire}
            getOptionLabel={(option: any) => {
              return option.name;
            }}
            id="controllable-autocomplete"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Bénéficiaire"
                name="bénéficiaire"
                placeholder="Choisissez un bénéficiaire"
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />

          <Dropzone
            accept="image/*"
            pictureUrl={
              signature
                ? selectedBeneficiaire && selectedBeneficiaire.signature
                : ''
            }
            base64Img={
              signature
                ? selectedBeneficiaire && selectedBeneficiaire.signature
                : ''
            }
            setInputData={setInputData}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!selectedBeneficiaire || !validSignature}
            style={{ marginRight: 15, marginBottom: 15 }}
            variant="contained"
            onClick={confirmValidateIntervention}
            color="primary"
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default EnhancedScheduler;
