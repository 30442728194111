import moment from 'moment';
import momentTz from 'moment-timezone';
import { DATETIME_FORMAT_UTC, TIME_FORMAT } from '../constants/format';

export const mapToIds = (data: any[]): number[] => {
  return (data || []).map((item) => item && item.id);
};

export const includeSearch = (searchKey: string, item: string): any => {
  return searchKey.toLowerCase().includes(item.toLowerCase());
};

export const filterUserByName = (data: any[], searchKey: string): any[] => {
  return (data || []).filter((item: any) => {
    if (item && item.user) {
      return (
        (includeSearch(item.user.firstName as string, searchKey) ||
          includeSearch(item.user.lastName as string, searchKey)) &&
        item
      );
    }
  });
};

export const getCachedAdminData = (data: any) => {
  return data &&
    data.etablishmentData &&
    data.etablishmentData.administrators &&
    data.etablishmentData.administrators.length > 0
    ? data.etablishmentData.administrators
    : [];
};

export const getCachedBeneficiaryData = (data: any) => {
  return data &&
    data.etablishmentData &&
    data.etablishmentData.beneficiary &&
    data.etablishmentData.beneficiary.length > 0
    ? data.etablishmentData.beneficiary
    : [];
};

export const getCachedWorkerData = (data: any) => {
  return data &&
    data.etablishmentData &&
    data.etablishmentData.workers &&
    data.etablishmentData.workers.length > 0
    ? data.etablishmentData.workers
    : [];
};

export const formatTimeByOffset = (
  dateString: string,
  offset: number,
): Date | string => {
  // dateString: on the form yyyy-mm-dd hh:mm:ss

  if (!dateString) return '';
  if (dateString.length === 0) return '';

  const year = dateString.slice(0, 4);
  const month = dateString.slice(5, 7);
  const day = dateString.slice(8, 10);
  const hour = dateString.slice(11, 13);
  const minute = dateString.slice(14, 16);
  const second = dateString.slice(17, 19);

  const dateObject = new Date(
    `${year}-${month}-${day}T${hour}:${minute}:${second}`,
  );

  const currentHours = dateObject.getHours();
  dateObject.setHours(currentHours + offset);
  return dateObject;
};

export const formatTime = (date: Date | string): string => {
  if (!date) return '';
  const newDateString = new Date(date)
    .toISOString()
    .replace('T', ' ')
    .slice(11, 16);
  return `${newDateString}`;
};

export const getLocalTime = (dateTime: string): string => {
  const deviceTimezone = Math.abs(new Date().getTimezoneOffset() / 60);

  return formatTime(formatTimeByOffset(dateTime, deviceTimezone));
};

export const getLocalDateTime = (dateTime: string): Date | string => {
  const deviceTimezone = Math.abs(new Date().getTimezoneOffset() / 60);

  return formatTimeByOffset(dateTime, deviceTimezone);
};

export const getUtcDateTime = (dateTime: string | Date): string => {
  return moment(dateTime).utc().format(DATETIME_FORMAT_UTC);
};
export const getNormalDateTime = (dateTime: Date): string => {
  return moment(dateTime).format('HH:mm');
};
export const getUtcTime = (dateTime: string | Date): string => {
  return moment(dateTime).utc().format(TIME_FORMAT);
};

export const getNormalTime = (dateTime: string | Date): string => {
  return moment(dateTime).format(TIME_FORMAT);
};
export const capitalize = (text: string) => {
  if (!text) return '';
  if (text.length === 1) return text.toUpperCase();

  return text
    .split(' ')
    .map(
      (item) =>
        `${item.substr(0, 1).toUpperCase()}${item.substr(1).toLowerCase()}`,
    )
    .join(' ');
};
