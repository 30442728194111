import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';
import React, { FC, useContext, useEffect } from 'react';
import { useParams, RouteComponentProps, withRouter } from 'react-router-dom';
import { ESTABLISHMENT_EDITOR_TITLE } from '../../../../constants/etablishement';
import { GET_ETABLISHMENT } from '../../../../graphql/etablishment/query';
import {
  EstablishmentDataType,
  EtablishmentDataType,
} from '../../../../types/establishment';
import { showSnackbar } from '../../../../utils/snackbar.util';
import UserContext, { UserContextType } from '../../../Context/UserContext';
import EstablishmentEditorWrapper from '../EstablishmentEditorWrapper/EstablishmentEditorWrapper';
import SuccessPage from '../SuccessPage';
import EstablishmentEditor from './components/EstablishmentEditor';
import { EstablishmentEditorActions } from './components/EstablishmentEditor/EstablishmentEditor';
import {
  SetEstablishment,
  SetEstablishmentType,
  SetMinorChanges,
} from './reducer/establishment-edit.actions';
import { useEstablishmentEditReducer } from './reducer/establishment-edit.reducer';
import {
  finalizeEstablishmentOptions,
  getEstablishmentType,
  onEstablishmentDataReadError,
  toEstablishmentDataType,
  updateEstablishmentOptions,
  useUpdateEstablishment,
} from './utils/EstablishmentEdit.utils';
import { GET_PRESIGNED_URL } from '../../../../graphql/fileUpload/mutations';

interface EstablishmentEditProps {}

interface ParamsState {
  id: string;
}

const EstablishmentEdit: FC<EstablishmentEditProps & RouteComponentProps> = (
  props,
) => {
  const { history } = props;
  const params = useParams<ParamsState>();
  const client = useApolloClient();
  const [state, dispatch] = useEstablishmentEditReducer({
    establishmentId: Number.parseInt(params.id),
  });
  const userContext: UserContextType = useContext(UserContext);

  const { data: establishmentData, loading: dataLoading } = useQuery(
    GET_ETABLISHMENT,
    {
      variables: { id: state.establishmentId },
      onError: onEstablishmentDataReadError(client),
    },
  );

  useEffect(() => {
    if (!dataLoading && establishmentData) {
      const { etablishment: establishment } = establishmentData;
      const establishmentDataType: EtablishmentDataType = toEstablishmentDataType(
        establishment,
      );
      dispatch(new SetEstablishment(establishmentDataType));
      dispatch(new SetEstablishmentType(getEstablishmentType(establishment)));
    }
  }, [establishmentData]);

  const updateEstablishment = useUpdateEstablishment({
    options: updateEstablishmentOptions(client),
  });

  const finalizeEstablishment = useUpdateEstablishment({
    options: finalizeEstablishmentOptions(client, dispatch),
  });

  const invalidEstablishmentChild = () =>
    state.establishmentType === 'RESIDENCE_CHILD' &&
    state.minorChanges &&
    !state.minorChanges.residenceIds;

  const redirect = () => {
    if (userContext.userRole === 'SUPER_ADMIN') {
      history.push('/etablissements');
    } else {
      history.push('/votre-etablissement');
    }
  };

  const handleClose = () => {
    if (invalidEstablishmentChild()) {
      showSnackbar({
        client,
        message: `Vous devez choisir une organisation`,
        type: 'ERROR',
      });
      return;
    }
    if (state.minorChanges) {
      updateEstablishment(state.minorChanges);
    }
    redirect();
  };

  const [getUrl] = useMutation(GET_PRESIGNED_URL);

  const uploadLogo = (file: any, dataEstablishement: any) => {
    getUrl({ variables: { fileName: file.name, folder: 'logo' } }).then(
      (data) => {
        if (data) {
          const { uploadFile } = data.data;
          const { url, uuid } = uploadFile;
          fetch(url, {
            method: 'PUT',
            body: file,
          })
            .then((res) => {})
            .catch((error) => {});
          const dataUpdated = {
            ...dataEstablishement,
            logo: url,
          };
          finalizeEstablishment({
            ...dataUpdated,
            id: state.establishmentId,
          });
        }
      },
    );
  };

  const onFinalize = (data: EtablishmentDataType) => {
    const { picture, ...rest } = data;
    if (picture && picture[0]) {
      uploadLogo(picture[0], rest);
    } else {
      const newData = { ...data, logo: rest.logo || '' };
      finalizeEstablishment({
        ...newData,
        id: state.establishmentId,
      });
    }
  };

  if (!state.establishment) {
    return <></>;
  }

  if (state.establishmentEditFinish) {
    return (
      <SuccessPage
        message="Votre établissement a été mis à jour avec succès"
        onContinue={redirect}
      />
    );
  }

  const onMajorChanges = (value: EstablishmentDataType) => {
    dispatch(new SetEstablishment(value));
    dispatch(new SetMinorChanges(null));
    updateEstablishment({
      ...value,
      id: state.establishmentId,
    });
  };

  const onMinorChanges = (value: EstablishmentDataType) => {
    dispatch(new SetMinorChanges(value));
  };

  const actions: EstablishmentEditorActions = {
    onMajorChanges,
    onFinalize,
    onMinorChanges,
  };

  return (
    <EstablishmentEditorWrapper
      title="Modification d'un établissement"
      onClose={handleClose}
    >
      <EstablishmentEditor
        actions={actions}
        establishmentType={state.establishmentType}
        value={state.establishment}
        title={ESTABLISHMENT_EDITOR_TITLE[state.establishmentType]}
      />
    </EstablishmentEditorWrapper>
  );
};

export default withRouter(EstablishmentEdit);
