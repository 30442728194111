import gql from 'graphql-tag';
import { INTERVENTION_REPORT_FRAGMENT , INTERVENTION_REPORT_CUSTOM_FRAGMENT} from './fragment';

export const GET_INTERVENTION_REPORTS = gql`
  query InterventionReports($filter: InterventionReportFilter) {
    interventionReports(filter: $filter) {
      count
      data {
        ...InterventionReportCustom
      }
    }
  }
  ${INTERVENTION_REPORT_CUSTOM_FRAGMENT}
`;

export const GET_INTERVENTION_REPORT = gql`
  query InterventionReport($id: Int!) {
    interventionReport(id: $id) {
      ...InterventionReport
    }
  }
  ${INTERVENTION_REPORT_FRAGMENT}
`;
