import { isValidPostalCode } from '../common';
import {
  EMPTY_LIST_ERRORS,
  EMPTY_STRING_ERRORS,
  ESTABLISHMENT_VALIDATION_MESSAGES,
  EXISTENCE_ERRORS,
  INVALID_STRING_ERRORS,
} from '../constants/establishment-validation.constant';
import {
  EstablishmentDataType,
  EstablishmentType,
} from '../types/establishment';
import {
  EstablishmentValidationError,
  EstablishmentValidatorFunc,
  EstablishmentValidators,
} from '../types/establishment-validator.types';
import { validate } from './universal-validator.utils';

const requiredString: EstablishmentValidatorFunc = (establishment, key) => {
  const value = establishment[key];
  if (!value) {
    return {
      valid: false,
      error: EMPTY_STRING_ERRORS[key],
    };
  }
  return {
    valid: true,
  };
};

const postalCodeValidator: EstablishmentValidatorFunc = (
  establishment,
  key,
) => {
  const value: any = establishment[key];
  if (!isValidPostalCode(value)) {
    return {
      valid: false,
      error: INVALID_STRING_ERRORS[key],
    };
  }
  return {
    valid: true,
  };
};

const noEmptyList: EstablishmentValidatorFunc = (establishment, key) => {
  const value: any = establishment[key];
  if (!value || !(value as Array<number>).length) {
    return {
      valid: false,
      error: EMPTY_LIST_ERRORS[key],
    };
  }
  return {
    valid: true,
  };
};

const existenceValidator: EstablishmentValidatorFunc = (establishment, key) => {
  const value: any = establishment[key];
  if (!value) {
    return {
      valid: false,
      error: EXISTENCE_ERRORS[key],
    };
  }
  return {
    valid: true,
  };
};

const establishmentTypeValidator: EstablishmentValidatorFunc = (
  establishment,
) => {
  const existenceValidation = existenceValidator(
    establishment,
    'establishmentType',
  );
  if (!existenceValidation.valid) {
    return existenceValidation;
  }
  if (establishment.establishmentType === 'RESIDENCE_CHILD') {
    return {
      valid: false,
      error: EXISTENCE_ERRORS['etablishmentTypeId'],
    };
  }
  return {
    valid: true,
  };
};

const establishmentTypeIdValidator: EstablishmentValidatorFunc = (
  establishment,
) => {
  if (!establishment.etablishmentTypeId) {
    return {
      valid: false,
      error: EXISTENCE_ERRORS['establishmentType'],
    };
  }
  return {
    valid: true,
  };
};

const DEFAULT_ESTABLISHMENT_VALIDATORS: EstablishmentValidators = {
  name: [requiredString],
  rcs: [requiredString],
  address: [requiredString],
  city: [requiredString],
  postalCode: [requiredString, postalCodeValidator],
};

const BASIC_INFORMATION_VALIDATORS: EstablishmentValidators = {
  name: [requiredString],
  rcs: [requiredString],
  address: [requiredString],
  city: [requiredString],
  postalCode: [requiredString, postalCodeValidator],
};

const NO_EMPTY_PRESTATION_VALIDATOR: EstablishmentValidators = {
  prestations: [noEmptyList],
  administrators: [],
};

const NO_EMPTY_ESTABLISHMENT_ROOT: EstablishmentValidators = {
  residenceIds: [noEmptyList],
};

const NO_ESTABLISHMENT_TYPE_VALIDATOR: EstablishmentValidators = {
  establishmentType: [establishmentTypeValidator],
  etablishmentTypeId: [establishmentTypeIdValidator],
};

const ESTABLISHMENT_CHILD_VALIDATORS: EstablishmentValidators = {
  ...DEFAULT_ESTABLISHMENT_VALIDATORS,
  residenceIds: [noEmptyList],
};

export const getEstablishmentDefaultErrorMessage = (
  error: EstablishmentValidationError,
) => ESTABLISHMENT_VALIDATION_MESSAGES[error];

export const getDefaultEstablishmentValidators = (
  establishmentType: EstablishmentType,
): EstablishmentValidators =>
  establishmentType === 'RESIDENCE_CHILD'
    ? ESTABLISHMENT_CHILD_VALIDATORS
    : DEFAULT_ESTABLISHMENT_VALIDATORS;

export const validateEstablishmentBasicInformation = (
  establishment: EstablishmentDataType,
) =>
  validate<EstablishmentDataType, EstablishmentValidationError>(
    establishment,
    BASIC_INFORMATION_VALIDATORS,
  );

export const validateEstablishmentPrestation = (
  establishment: EstablishmentDataType,
) =>
  validate<EstablishmentDataType, EstablishmentValidationError>(
    establishment,
    NO_EMPTY_PRESTATION_VALIDATOR,
  );

export const validateEstablishmentRootList = (
  establishment: EstablishmentDataType,
) =>
  validate<EstablishmentDataType, EstablishmentValidationError>(
    establishment,
    NO_EMPTY_ESTABLISHMENT_ROOT,
  );

export const selectedEstablishmentTypeValidator = (
  establishment: EstablishmentDataType,
) =>
  validate<EstablishmentDataType, EstablishmentValidationError>(
    establishment,
    NO_ESTABLISHMENT_TYPE_VALIDATOR,
  );
