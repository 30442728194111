import gql from 'graphql-tag';

export const UPDATE_DOCUMENT = gql`
    mutation UpdateDocument($input: UpdateDocumentInput!){
        updateDocument(input: $input){
            id
            name
            path
        }
    }
`;