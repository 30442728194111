import React, { MouseEvent } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { isAuthenticated } from '../../../services/localStorage';
import useStyles from './styles';
import logo from '../../../assets/images/logo-neoptim.png';
import partyPopper from '../../../assets/images/party-popper.png';

interface ResetPasswordSuccessProps {}

/**
 * Reset password success Component
 * @param props ResetPasswordSuccessProps
 */
const ResetPasswordSuccess = (
  props: ResetPasswordSuccessProps & RouteComponentProps,
) => {
  const { history } = props;
  const classes = useStyles();

  if (isAuthenticated()) {
    return <Redirect {...{ to: '/' }} />;
  }

  const goToLogin = (event: MouseEvent<HTMLButtonElement>): void => {
    history.push('/authentification');
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.wrapForm}>
          <Box className={classes.logo}>
            <img src={logo} alt="Neoptim Logo" />
          </Box>
          <Box className={classes.imgContainer}>
            <img src={partyPopper} alt="party-popper" />
          </Box>
          <Box className={classes.title}>
            <Typography
              variant="h4"
              component="h1"
              className={classes.textTitle}
            >
              Félicitations !
            </Typography>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              className={classes.descTitle}
            >
              Votre mot de passe a été réinitialisé avec succès.
            </Typography>
          </Box>
          <Box className={classes.form}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              fullWidth
              onClick={goToLogin}
            >
              Vers la connexion
            </Button>
          </Box>
        </Box>
        <Typography
          variant="body2"
          component="footer"
          color="primary"
          align="center"
          className={classes.footer}
        >
          &copy; Copyright - Neoptim consulting
        </Typography>
      </Box>
    </Box>
  );
};

export default withRouter(ResetPasswordSuccess);
