import { MutationHookOptions } from '@apollo/react-hooks';
import { ApolloClient, ApolloError, ApolloQueryResult } from 'apollo-boost';
import {
  DeleteEstablishments,
  DeleteEstablishmentsVariables,
} from '../../../../graphql/etablishment/types/DeleteEstablishments';
import {
  EstablishmentChilds,
  EstablishmentChildsVariables,
} from '../../../../graphql/etablishment/types/EstablishmentChilds';
import { showSnackbar } from '../../../../utils/snackbar.util';

export interface DeleteEstablishmentsOptionsArgs {
  client: ApolloClient<object>;
  refetch?: (
    variables?: EstablishmentChildsVariables | undefined,
  ) => Promise<ApolloQueryResult<EstablishmentChilds>>;
}
export const DeleteEstablishmentsOptions = ({
  client,
  refetch,
}: DeleteEstablishmentsOptionsArgs): MutationHookOptions<
  DeleteEstablishments,
  DeleteEstablishmentsVariables
> => ({
  onCompleted: (data: DeleteEstablishments) => {
    const count = data.deleteEtablishments.length;
    let message =
      count > 1
        ? `Les établissements on été supprimés`
        : `L'établissement a été supprimé`;
    showSnackbar({
      client,
      message,
      type: 'SUCCESS',
    });
    refetch && refetch();
  },
  onError: (error: ApolloError) => {
    let message =
      "Une erreur s'est produite lors de la suppression d'établissement";
    if (error.networkError) {
      message = 'Erreur de réseau. Veuillez vérifier votre connexion internet';
    }
    showSnackbar({
      client,
      message,
      type: 'ERROR',
    });
  },
});
