import gql from 'graphql-tag';

export const GET_DATA_FILTER = gql`
  query getFilter($filterType: FilterType) {
    getFilter(filterType: $filterType) {
      workers {
        id
        user {
          id
          firstName
          lastName
        }
      }
      beneficiaries {
        id
        user {
          id
          firstName
          lastName
        }
      }
      etablishments {
        id
        name
      }
    }
  }
`;
