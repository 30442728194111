import React, { FC } from 'react';
import useStyles from './styles';
import Button from '@material-ui/core/Button';
import { ActionButtonsState } from '../EstablishmentEditor/establishmentEditor.reducer';

export interface EditStepperActionsProp {
  onGoBack: () => void;
  onGoNext: () => void;
  onFinalize: () => void;
}
interface EditStepperActionsProps {
  stepsLength: number;
  state: ActionButtonsState;
  actions: EditStepperActionsProp;
}

const EditStepperActions: FC<EditStepperActionsProps> = (props) => {
  const { stepsLength, actions, state } = props;
  const { previous, next, finalize } = state;
  const { onFinalize, onGoBack, onGoNext } = actions;
  const classes = useStyles();

  const previousButton = (
    <Button
      variant="contained"
      color="default"
      className={classes.actionButton}
      disabled={previous.disabled}
      onClick={onGoBack}
    >
      Retour
    </Button>
  );
  const nextButton = (
    <Button
      variant="contained"
      color="primary"
      className={classes.actionButton}
      disabled={next.disabled}
      onClick={onGoNext}
    >
      Suivant
    </Button>
  );
  const finalizeButton = (
    <Button
      variant="contained"
      color="primary"
      disabled={finalize.disabled}
      className={classes.actionButton}
      onClick={onFinalize}
    >
      Finaliser
    </Button>
  );
  return (
    <>
      {stepsLength === 1 ? (
        <>{finalizeButton}</>
      ) : (
        <>
          {previous.displayed && previousButton}
          {next.displayed && nextButton}
          {finalize.displayed && finalizeButton}
        </>
      )}
    </>
  );
};

export default EditStepperActions;
