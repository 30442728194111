import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
      appointmentListMargin: {
        marginTop: theme.spacing(4)
      },
      white: {
        color: 'white',
        fontSize: '.875rem',
        textTransform: 'initial',
      },
      link: {
        display: 'block',
        fontWeight: 500,
        fontSize: 14,
        textDecoration: 'underline !important',
        color: '#002B67 !important',
        marginBottom: 8,
        cursor: 'pointer',
        userSelect: 'none',
      },
      linkBox: {
        display: 'flex',
        flexDirection: 'column',
      },
});
const useStyles = makeStyles(styles);
export default useStyles;
