import React, { FC, useState, ChangeEvent, useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import DateTimePicker from '../../../Common/DateTimePicker';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { FormInputDataState, UserRole } from '../../../../types/user';
import { USER_ROLE_LABEL } from '../../../../constants/user';
import useStyles, { styles } from './styles';
import CustomSwitch from '../../../Common/CustomSwitch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  GET_UNIT_LIFES,
  GET_UNIT_LIFES_BY_ETABLISHMENT,
} from '../../../../graphql/unit/query';
import { GET_ETABLISHMENT_NAMES } from '../../../../graphql/etablishment/query';
import {
  EtablishmentNames,
  EtablishmentNames_etablishments,
} from '../../../../graphql/etablishment/types/EtablishmentNames';
import { UnitLifes } from '../../../../graphql/unit/types/UnitLifes';
import Dropzone from '../../../Common/Dropzone';
import { EstablishmentType } from '../../../../types/establishment';
import { UnitLifeByEstablishment } from '../../../../graphql/unit/types/UnitLifeByEstablishment';
// import { useContext } from 'react';
import { EstablishmentContext } from '../../../Context';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { BENEFICIARY_DOCUMENTS } from '../../../../constants/document';

interface UserFormDialogProps {
  userRole?: UserRole;
  title: string;
  open: boolean;
  loadingButton?: boolean;
  buttonLabel?: string;
  data: FormInputDataState;
  disableMailField?: boolean;
  isUpdatePassword?: boolean;
  activeEstablishmentField?: boolean;
  handleClose: () => void;
  onSubmit: (data: FormInputDataState, cb: any) => void;
  displayUnitLife?: boolean;
}

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

interface SelectedDocument {
  name: string;
  file: string;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.dialogTitle}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const UserFormDialog: FC<UserFormDialogProps> = (props) => {
  const {
    userRole,
    title,
    onSubmit,
    loadingButton,
    data,
    open,
    handleClose,
    buttonLabel,
    isUpdatePassword,
    disableMailField,
    activeEstablishmentField,
    displayUnitLife = true,
  } = props;
  const classes = useStyles();
  const [unitLifeData, setUnitLifeData] = useState<any[]>([]);
  const [inputData, setInputData] = useState<FormInputDataState>(data);
  const [date, setDate] = useState<Date>(
    new Date(moment(data.birthDate).format('MM/DD/YYYY')),
  );
  const [establishmentData, setEstablishmentData] = useState<any[]>([]);
  const [autoCompleteValue, setAutocompleValue] = useState<any>(null);
  const [
    autoCompleteValueUnitLife,
    setAutocompleteValueUnitLife,
  ] = useState<any>(null);
  const [showPasswordFields, setShowPasswordFields] = useState<boolean>(false);
  const [autoCompleteInputValue, setAutocompleInputValue] = useState<string>(
    '',
  );
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const { establishmentId } = useContext(EstablishmentContext);
  const [selectedDocuments, setSelecetedDocuments] = useState<
    SelectedDocument[]
  >([]);
  const [
    establishmentIdSuperAdmin,
    setEstablishmentIdSuperAdmin,
  ] = useState<number>(0);
  const [getUnitLifesByEtablishment] = useLazyQuery<UnitLifeByEstablishment>(
    GET_UNIT_LIFES_BY_ETABLISHMENT,
    {
      onCompleted: (data: UnitLifeByEstablishment) => {
        if (data && data.unitLifeByEstablishment) {
          setUnitLifeData(data.unitLifeByEstablishment);
        }
      },
    },
  );
  const [
    autoCompleteInputUnitLifeValue,
    setAutoCompleteInputUnitLifeValue,
  ] = useState<any>('');

  useEffect(() => {
    if (establishmentId !== 0) {
      getUnitLifesByEtablishment({
        variables: { establishmentId: establishmentId },
      });
    }
  }, [establishmentId]);
  useEffect(() => {
    setInputData({
      ...inputData,
      birthDate: new Date(moment(date).format('MM/DD/YYYY')),
    });
  }, [date]);

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

  useEffect(() => {
    if (selectedFiles[0]) {
      setInputData({ ...inputData, file: selectedFiles[0] });
    }
  }, [selectedFiles]);

  useEffect(() => {
    setSelectedFiles([]);
  }, [open]);

  const filterEstablishment = (
    establishments: EtablishmentNames_etablishments[],
  ): EtablishmentNames_etablishments[] => {
    const role = userRole || 'administrator';
    if (role === 'beneficiary' || role === 'worker') {
      return establishments.filter(
        (establishment) =>
          establishment.etablishmentType?.name !== 'RESIDENCE_PARENT',
      );
    }
    return establishments;
  };

  const [getEstablishmentNames] = useLazyQuery<EtablishmentNames>(
    GET_ETABLISHMENT_NAMES,
    {
      onCompleted: ({ etablishments }) => {
        setEstablishmentData(filterEstablishment(etablishments));
      },
    },
  );

  useEffect(() => {
    if (activeEstablishmentField) {
      getEstablishmentNames();
    }
  }, [activeEstablishmentField]);

  useEffect(() => {
    if (establishmentIdSuperAdmin !== 0) {
      getUnitLifesByEtablishment({
        variables: { establishmentId: establishmentIdSuperAdmin },
      });
    }
  }, [establishmentIdSuperAdmin]);

  useEffect(() => {
    setInputData(data);
  }, [data]);

  useEffect(() => {
    if (inputData && inputData.establishmentId) {
      const value = establishmentData.find(
        (item) => item && item.id === inputData.establishmentId,
      );
      setAutocompleValue(value);
    }
    if (inputData && inputData.unitLifeId) {
      const value = unitLifeData.find(
        (item) => item && item.id === inputData.unitLifeId,
      );
      setAutocompleteValueUnitLife(value);
    }
  }, [inputData]);

  useEffect(() => {
    if (selectedDate) {
      setInputData({
        ...inputData,
        birthDate: new Date(moment(selectedDate).format('MM/DD/YYYY')),
      });
    }
  }, [selectedDate]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'phone' || name === 'mobilePhone') {
      setInputData({
        ...inputData,
        [name]: value.trim().replace(/[^0-9 +]/g, ''),
      });
    } else {
      setInputData({
        ...inputData,
        [name]: value.length > 0 && value !== ' ' ? value : '',
      });
    }
  };

  const checkboxHandle = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setInputData({
      ...inputData,
      [name]: checked,
    });
  };

  const resetForm = () => {
    setInputData({
      email: '',
      name: '',
      lastName: '',
      mobilePhone: '',
      phone: '',
      status: false,
      userFunction: '',
      birthDate: new Date(moment().format('MM/DD/YYYY')),
      disabilityRecognition: false,
      establishmentId: 0,
      guardianEnabled: false,
      guardianName: '',
    });
    setAutocompleValue(null);
    setAutocompleInputValue('');
    setShowPasswordFields(false);
    setAutoCompleteInputUnitLifeValue('');
  };

  const onHandleClose = () => {
    resetForm();
    handleClose();
  };

  const onHandleSubmit = () => {
    onSubmit(inputData, (res: boolean) => {
      if (res) resetForm();
    });
  };

  const onChangeAutoComplete = (
    event: React.ChangeEvent<any>,
    newValue: any,
  ) => {
    if (newValue && newValue.id) {
      setEstablishmentIdSuperAdmin(newValue.id);
    }
    setInputData({
      ...inputData,
      establishmentId: (newValue && newValue.id) || 0,
    });
    setAutocompleValue(newValue);
  };

  const onChangeAutoCompleteUnitLife = (
    event: React.ChangeEvent<any>,
    newValue: any,
  ) => {
    setInputData({
      ...inputData,
      unitLifeId: (newValue && newValue.id) || 0,
    });
    setAutocompleteValueUnitLife(newValue);
  };

  const onInputChangeAutoComplete = (
    event: React.ChangeEvent<any>,
    newValue: any,
  ) => {
    setAutocompleInputValue(newValue);
  };

  const onInputChangeAutoCompleteUnitLife = (
    event: React.ChangeEvent<any>,
    newValue: any,
  ) => {
    setAutoCompleteInputUnitLifeValue(newValue);
  };

  const handleShowPasswordFields = () => {
    setShowPasswordFields(!showPasswordFields);
    setInputData({ ...inputData, updatePassword: !showPasswordFields });
  };

  const getEstablishmentType = (
    inputData: FormInputDataState,
  ): EstablishmentType | null => {
    const establishment = (establishmentData as EtablishmentNames_etablishments[]).find(
      (establishment) => establishment.id === inputData.establishmentId,
    );
    const establishmentType = establishment?.etablishmentType?.name;
    if (establishmentType) {
      return establishmentType as EstablishmentType;
    }
    return null;
  };

  const establishmentType = getEstablishmentType(inputData);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(new Date(moment(date).format('MM/DD/YYYY')));
  };

  useEffect(() => {
    if (selectedDocuments && selectedDocuments.length > 0) {
      setInputData({
        ...inputData,
        beneficiaryDocuments: JSON.stringify(selectedDocuments),
      });
    }
  }, [selectedDocuments]);

  const insertDocument = (name: string, file: string) => {
    const fileExist = selectedDocuments.filter((doc) => doc.name == name);
    if (fileExist && fileExist.length > 0) {
      setSelecetedDocuments([...selectedDocuments, { name, file }]);
    } else {
      setSelecetedDocuments([...selectedDocuments, { name, file }]);
    }
  };

  const addToDocument = (name: string, file: string) => {
    insertDocument(name, file);
  };

  const removeToDocument = (name: string) => {
    insertDocument(name, '');
  };

  const removeSignature = () => {
    setInputData({ ...inputData, signature: '' });
  };

  const getDocumentUrl = (name: string): string => {
    if (data && data.beneficiaryDocuments) {
      const documents = JSON.parse(data.beneficiaryDocuments);
      const document = documents.filter((item: any) => item.name == name);
      return document && document[0] && document[0].path
        ? document[0].path
        : '';
    }
    return '';
  };

  const lifeUnitIsDisplayed =
    displayUnitLife &&
    userRole === 'beneficiary' &&
    establishmentType &&
    establishmentType === 'RESIDENCE';

  return (
    <Dialog
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      className={classes.root}
      // onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title" onClose={onHandleClose}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          label="Nom"
          name="lastName"
          placeholder={userRole ? `Nom ${USER_ROLE_LABEL[userRole]}` : 'Nom'}
          value={inputData.lastName}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
          margin="normal"
          autoComplete="off"
        />
        <TextField
          label="Prénom"
          name="name"
          placeholder={
            userRole ? `Prénom ${USER_ROLE_LABEL[userRole]}` : 'Prénom'
          }
          value={inputData.name}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
          margin="normal"
          autoComplete="off"
        />
        <TextField
          label="Mail"
          name="email"
          disabled={disableMailField || false}
          value={inputData.email}
          onChange={handleChange}
          placeholder={userRole ? `Mail ${USER_ROLE_LABEL[userRole]}` : 'Mail'}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
          margin="normal"
          autoComplete="off"
        />
        {userRole !== 'beneficiary' && (
          <TextField
            fullWidth
            margin="normal"
            label="Fonction"
            variant="outlined"
            name="userFunction"
            onChange={handleChange}
            value={inputData.userFunction || ''}
            InputLabelProps={{ shrink: true }}
            placeholder={
              userRole ? `Fonction ${USER_ROLE_LABEL[userRole]}` : 'Fonction'
            }
            autoComplete="off"
          />
        )}
        <TextField
          label="Tél Mobile"
          name="mobilePhone"
          value={inputData.mobilePhone}
          onChange={handleChange}
          placeholder={
            userRole
              ? `Téléphone mobile ${USER_ROLE_LABEL[userRole]}`
              : 'Téléphone mobile'
          }
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
          margin="normal"
          autoComplete="off"
        />
        <TextField
          label="Tél Fixe"
          name="phone"
          value={inputData.phone}
          placeholder={
            userRole
              ? `Téléphone fixe ${USER_ROLE_LABEL[userRole]}`
              : 'Téléphone fixe'
          }
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
          margin="normal"
          autoComplete="off"
          onChange={handleChange}
        />
        {activeEstablishmentField && (
          <Autocomplete
            options={establishmentData}
            value={autoCompleteValue}
            onChange={onChangeAutoComplete}
            noOptionsText={'Aucune option'}
            inputValue={autoCompleteInputValue}
            onInputChange={onInputChangeAutoComplete}
            getOptionLabel={(option: any) => option && option.name}
            id="controllable-autocomplete"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Etablissement"
                name="establishment"
                placeholder="Choisissez un établissement"
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        )}
        {lifeUnitIsDisplayed && (
          <Autocomplete
            options={unitLifeData}
            value={autoCompleteValueUnitLife}
            onChange={onChangeAutoCompleteUnitLife}
            noOptionsText={'Aucune option'}
            inputValue={autoCompleteInputUnitLifeValue}
            onInputChange={onInputChangeAutoCompleteUnitLife}
            getOptionLabel={(option: any) => option && option.name}
            id="controllable-autocomplete"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Unité de vie"
                name="unitLife"
                placeholder="Choisissez l'unité de vie"
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        )}

        {userRole === 'beneficiary' && (
          <>
            <KeyboardDatePicker
              autoOk
              inputVariant="outlined"
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="dateStart"
              label="Date de naissance"
              value={inputData.birthDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className={classes.birthDate}
            />
            <CustomSwitch
              label="Handicap"
              labelChecked="Activé"
              labelUnchecked="Désactivé"
              name="disabilityRecognition"
              checked={inputData.disabilityRecognition ? true : false}
              onChange={checkboxHandle}
            />
          </>
        )}

        <CustomSwitch
          label="Statut"
          labelChecked="Activé"
          labelUnchecked="Désactivé"
          name="status"
          checked={inputData.status}
          onChange={checkboxHandle}
        />

        {isUpdatePassword && (
          <Box onClick={handleShowPasswordFields}>
            <Typography
              color="secondary"
              align="center"
              className={classes.textUpdatePassword}
            >
              Modifier le mot de passe
            </Typography>
          </Box>
        )}
        {isUpdatePassword && showPasswordFields && (
          <Box>
            <Box>
              <TextField
                fullWidth
                autoFocus
                type="password"
                margin="normal"
                autoComplete="off"
                variant="outlined"
                name="oldPassword"
                onChange={handleChange}
                label="Ancien mot de passe"
                value={inputData.oldPassword}
                placeholder="Ancien mot de passe"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                margin="normal"
                type="password"
                autoComplete="off"
                variant="outlined"
                name="newPassword"
                onChange={handleChange}
                value={inputData.newPassword}
                label="Nouveau mot de passe"
                InputLabelProps={{ shrink: true }}
                placeholder="Nouveau mot de passe"
              />
              <TextField
                fullWidth
                margin="normal"
                type="password"
                autoComplete="off"
                variant="outlined"
                name="confirmPassword"
                onChange={handleChange}
                value={inputData.confirmPassword}
                label="Confirmation mot de passe"
                InputLabelProps={{ shrink: true }}
                placeholder="Confirmation mot de passe"
              />
            </Box>
          </Box>
        )}
        {userRole === 'beneficiary' && (
          <>
            <CustomSwitch
              label="Sous tutelle"
              labelChecked="Oui"
              labelUnchecked="Non"
              name="guardianEnabled"
              checked={!!inputData.guardianEnabled}
              onChange={checkboxHandle}
            />
            {!!inputData.guardianEnabled && (
              <TextField
                label="Nom et prénom tuteur"
                name="guardianName"
                placeholder="Nom et prénom du tuteur"
                value={!!inputData.guardianName ? inputData.guardianName : ''}
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
                autoComplete="off"
              />
            )}
            <Typography
              color="secondary"
              align="center"
              className={classes.textUpdatePassword}
            >
              Signature
            </Typography>
            <Dropzone
              accept="image/*"
              multiple={false}
              base64Img={''}
              pictureUrl={inputData.signature}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              onDelete={removeSignature}
              label={'Glissez et déposez ici votre signature'}
              enablePreview
              showAction
              height={120}
            />

            {BENEFICIARY_DOCUMENTS.map((document, index) => {
              return (
                <Box key={index}>
                  <Typography
                    color="secondary"
                    align="center"
                    className={classes.textUpdatePassword}
                  >
                    {document}
                  </Typography>
                  <Dropzone
                    accept="image/*, .doc, .pdf, .txt, .docx, .xlsx"
                    multiple={false}
                    base64Img={''}
                    isDownloadableDocument={true}
                    pictureUrl={getDocumentUrl(document)}
                    documentName={document}
                    selectedFiles={selectedFiles}
                    setSelecetedDocument={addToDocument}
                    onDeleteDocument={removeToDocument}
                    enablePreview
                    label={'Glissez et déposez ici votre document'}
                    showAction
                    height={120}
                  />
                </Box>
              );
            })}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={loadingButton}
          onClick={onHandleSubmit}
        >
          {buttonLabel || 'Ajouter'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserFormDialog;
