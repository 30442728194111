import gql from 'graphql-tag';
import {
  INTERVENTION_DETAILS_FRAGMENT,
  INTERVENTION_ALL_DETAILS_FRAGMENT,
} from './fragment';

export const GET_INTERVENTION_PER_ETABLISHMENT = gql`
  query InterventionsPerEtablishment($etablishmentId: Int!) {
    interventionsPerEtablishment(etablishmentId: $etablishmentId) {
      ...InterventionAllDetailsInfo
    }
  }
  ${INTERVENTION_ALL_DETAILS_FRAGMENT}
`;

export const GET_INTERVENTION = gql`
  query Intervention($id: Int!) {
    intervention(id: $id) {
      ...InterventionAllDetailsInfo
    }
  }
  ${INTERVENTION_ALL_DETAILS_FRAGMENT}
`;
