import React, { FC, useState, useEffect, useContext } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-boost';
import { DataProxy } from 'apollo-cache';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { ValueType } from 'react-select';

import {
  Administrators,
  AdministratorsVariables,
} from '../../../../graphql/administrator/types/Administrators';
import {
  CreateAdmin,
  CreateAdminVariables,
} from '../../../../graphql/administrator/types/CreateAdmin';
import {
  UpdateAdmin,
  UpdateAdminVariables,
} from '../../../../graphql/administrator/types/UpdateAdmin';
import {
  DeleteAdmin,
  DeleteAdminVariables,
} from '../../../../graphql/administrator/types/DeleteAdmin';
import { GET_ADMINISTRATORS } from '../../../../graphql/administrator/query';
import {
  CREATE_ADMIN,
  UPDATE_ADMIN,
  DELETE_ADMIN,
  DELETE_ADMINS,
  CREATE_ADMINS,
  UPDATE_ADMIN_STATUS,
} from '../../../../graphql/administrator/mutation';
import {
  CreateAdmins,
  CreateAdminsVariables,
} from '../../../../graphql/administrator/types/CreateAdmins';
import {
  UpdateAdminStatus,
  UpdateAdminStatusVariables,
} from '../../../../graphql/administrator/types/UpdateAdminStatus';
import {
  DeleteAdmins,
  DeleteAdminsVariables,
} from '../../../../graphql/administrator/types/DeleteAdmins';
import { SnackbarType } from '../../../../types';
import { displaySnackbar, checkActionGroup } from '../../../../common';
import { HeadCell, GroupAction } from '../../../../types/table';
import UserFormDialog from '../UserFormDialog';
import { FormInputDataState, User } from '../../../../types/user';
import SearchInput from '../../../Common/SearchInput';
import useStyles from './styles';
import CustomTable from '../../../Common/CustomTable';
import ConfirmationDialog from '../../../Common/ConfirmationDialog';
import { EtablishmentDataType } from '../../../../types/establishment';
import ExcelReader from '../../../Common/ExcelReader';
import { EtablishmentContributorsInfo_etablishment_administrators } from '../../../../graphql/etablishment/types/EtablishmentContributorsInfo';
import { TableDataSource } from '../../../../types/table';
import { GET_ETABLISHMENT_DATA } from '../../../../graphql/common/etablishment/query';
import { isGlobalList } from '../../../../common/validator';
import { filterUserByName } from '../../../../common/utils';
import { getUsersFromFiles } from '../../../../utils/excel-import.utils';
import { UserValidationState } from '../../../../types/user-validator.types';
import {
  getExcelImportUserErrorMessage,
  getImportExcelValidators,
  validateUsers,
} from '../../../../utils/user-validator.utils';
import {
  formInputDataStateToUser,
  getPartialUsers,
} from '../../../../utils/user.utils';
import { showSnackbar } from '../../../../utils/snackbar.util';
import { handleEstablishmentCreateUserListValidation } from '../../../../utils/user-list.utils';
import { CreateAdministratorInput } from '../../../../graphql/types/graphql-global-types';
import { EstablishmentContext } from '../../../Context/EstablishmentContext';

interface AdminListProps {
  etabInputData?: EtablishmentDataType;
  setEtabInputData?: React.Dispatch<React.SetStateAction<EtablishmentDataType>>;
  disableImportAction?: boolean;
  activeEstablishmentField?: boolean;
  loading?: boolean;
  fromDetails?: boolean;
  dataSource?: TableDataSource;
  adminData?:
    | (EtablishmentContributorsInfo_etablishment_administrators | null)[]
    | any[];
}

interface AdminExcelRows {
  nom: string;
  prenoms: string;
  email: string;
  tel_mobile: string;
  tel_fixe: string;
  statut: number;
}

const ACTIONS: GroupAction[] = [
  { label: 'Activer', value: 'enable' },
  { label: 'Désactiver', value: 'disable' },
  { label: 'Supprimer', value: 'remove' },
];

const INIT_DATA = {
  name: '',
  lastName: '',
  email: '',
  mobilePhone: '',
  phone: '',
  status: false,
};

const AdminList: FC<AdminListProps> = (props) => {
  const {
    setEtabInputData,
    etabInputData,
    adminData,
    loading,
    disableImportAction,
    fromDetails,
    dataSource,
    activeEstablishmentField,
  } = props;
  const classes = useStyles();
  const client = useApolloClient();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [adminId, setAdminId] = useState<number>(-1);
  const [searchKey, setSearchKey] = useState<string>('');
  const [data, setData] = useState<any[]>([]);
  const [inputData, setInputData] = useState<FormInputDataState>(INIT_DATA);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [inputUpdateData, setInputUpdateData] =
    useState<FormInputDataState>(INIT_DATA);
  const [openUpdateUserDialog, setOpenUpdateUserDialog] =
    useState<boolean>(false);
  const [editId, setEditId] = useState<number>(-1);

  const { newEtablishmentId } = useContext(EstablishmentContext);

  useEffect(() => {
    if (fromDetails && adminData) {
      setData(adminData);
    }
  }, [adminData]);

  useEffect(() => {
    if (setEtabInputData) {
      setEtabInputData((prevState: EtablishmentDataType) => ({
        ...prevState,
        administratorIds: selectedItems,
      }));
    }
  }, [selectedItems, setEtabInputData]);

  const filter = searchKey && {
    name: searchKey,
  };

  const variables = {
    input: {
      filter: JSON.stringify({
        ...filter,
      }),
    },
  };

  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const {
    loading: loadingAdminList,
    data: adminList,
    refetch,
  } = useQuery<Administrators, AdministratorsVariables>(GET_ADMINISTRATORS, {
    variables,
  });

  const [createAdmin, { loading: loadingCreateAdmin }] = useMutation<
    CreateAdmin,
    CreateAdminVariables
  >(CREATE_ADMIN, {
    onCompleted: (data: CreateAdmin) => {
      if (data && data.createAdmin) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = "L'administrateur a été ajouté";
        displaySnackbar(client, snackbarData);
        setOpenDialog(false);
        setInputData(INIT_DATA);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.message =
        "Une erreur s'est produite lors de la création d'administrateur";

      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const { message } = error.graphQLErrors[0];
        switch (message) {
          case 'EMAIL_ALREADY_USED':
            snackbarData.type = 'WARNING';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = "L'adresse email est déjà utilisée";
            break;
        }
      } else if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [updateAdmin, { loading: loadingUpdateAdmin }] = useMutation<
    UpdateAdmin,
    UpdateAdminVariables
  >(UPDATE_ADMIN, {
    onCompleted: (data: UpdateAdmin) => {
      if (data && data.updateAdmin) {
        setOpenUpdateUserDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour d'administrateur";
      displaySnackbar(client, snackbarData);
    },
  });

  const [deleteAdmin] = useMutation<DeleteAdmin, DeleteAdminVariables>(
    DELETE_ADMIN,
    {
      onCompleted: (data: DeleteAdmin) => {
        if (data && data.deleteAdmin) {
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `L' administrateur a été supprimé`;
          displaySnackbar(client, snackbarData);
          setOpenDialog(false);
        }
      },
      onError: (error: ApolloError) => {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          "Une erreur s'est produite lors de la suppression d'administrateur";
        displaySnackbar(client, snackbarData);
      },
    },
  );

  const [deleteAdmins, { loading: loadingDeleteAdmins }] = useMutation<
    DeleteAdmins,
    DeleteAdminsVariables
  >(DELETE_ADMINS, {
    onCompleted: (data: DeleteAdmins) => {
      if (data && data.deleteAdmins) {
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Les administrateurs ont été supprimés`;
        displaySnackbar(client, snackbarData);
        setOpenConfirmDialog(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la suppression des administrateurs";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [createAdmins, { loading: loadingCreateAdmins }] = useMutation<
    CreateAdmins,
    CreateAdminsVariables
  >(CREATE_ADMINS, {
    onCompleted: (data: CreateAdmins) => {
      if (data && data.createAdmins) {
        if (data.createAdmins.success) {
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = "La liste d'administrateur a été importée";
        } else {
          snackbarData.type = 'WARNING';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = 'Des informations dupliquées existent';
        }
        displaySnackbar(client, snackbarData);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la création des administrateurs";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const { message } = error.graphQLErrors[0];
        if (message.includes('EMAIL_ALREADY_USED')) {
          snackbarData.type = 'WARNING';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `L'adresse email ${
            message.split(';')[0]
          } est déjà utilisée`;
        }
      } else if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [updateAdminStatus] = useMutation<
    UpdateAdminStatus,
    UpdateAdminStatusVariables
  >(UPDATE_ADMIN_STATUS, {
    onCompleted: (data: UpdateAdminStatus) => {
      if (data && data.updateAdminStatus) {
        const statut =
          data.updateAdminStatus &&
          data.updateAdminStatus[0] &&
          data.updateAdminStatus[0].user &&
          data.updateAdminStatus[0].user.enabled === true
            ? 'activé'
            : 'désactivé';
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Les administrateurs ont été ${statut}s`;
        displaySnackbar(client, snackbarData);
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la mise à jour des administrateurs";

      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  useEffect(() => {
    if (
      adminList &&
      adminList.administrators &&
      isGlobalList(dataSource as string)
    ) {
      setData(adminList.administrators);
    }
  }, [adminList]);

  const cachedAdminList = client.readQuery({
    query: GET_ETABLISHMENT_DATA,
  });

  useEffect(() => {
    if (
      cachedAdminList &&
      cachedAdminList.etablishmentData &&
      cachedAdminList.etablishmentData.administrators &&
      dataSource &&
      dataSource === 'add-establishment'
    ) {
      const result = filterUserByName(
        cachedAdminList.etablishmentData.administrators,
        searchKey,
      );
      const adminList = cachedAdminList.etablishmentData.administrators;
      setData(searchKey ? result : adminList);
    }
  }, [cachedAdminList, searchKey]);

  const columns: HeadCell[] = [
    {
      name: 'name',
      label: 'Nom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.lastName) || '-';
      },
    },
    {
      name: 'lastName',
      label: 'Prénom',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.firstName) || '-';
      },
    },
    {
      name: 'mail',
      label: 'Mail',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.email) || '-';
      },
    },
    {
      name: 'mobilePhone',
      label: 'Tél mobile',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.mobilePhone) || '-';
      },
    },
    {
      name: 'phone',
      label: 'Tél fixe',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.phone) || '-';
      },
    },
    {
      name: 'userFunction',
      label: 'Fonction',
      disablePadding: false,
      renderer: (value: any) => {
        return (value && value.user && value.user.userFunction) || '-';
      },
    },
    {
      name: 'enabled',
      label: 'Statut',
      disablePadding: true,
      renderer: (value: any) => {
        const statut = value && value.user && value.user.enabled;
        return (
          <FormControlLabel
            control={
              <Switch
                checked={statut}
                onChange={(event) => onHandleChangeStatus(event, value.id)}
                name="enabled"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            }
            label={statut ? 'Activé' : 'Désactivé'}
          />
        );
      },
    },
    {
      name: 'actions',
      label: '',
      disablePadding: true,
      renderer: (value: any) => (
        <Box display="flex">
          <Tooltip title="Supprimer" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleDelete(value.id);
              }}
              className={classes.margin}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifier" placement="top" arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onHandleEditUser(value.id);
              }}
              className={classes.margin}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const onHandleChangeStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const { checked } = event.target;
    if (id) {
      updateAdmin({
        variables: {
          id,
          enabled: checked,
        },
        update: onUpdatedCachedEtab,
      }).then((options) => {
        if (options.data && options.data.updateAdmin) {
          const statut =
            options.data.updateAdmin.user &&
            options.data.updateAdmin.user.enabled
              ? 'activé'
              : 'désactivé';
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `L'administrateur a été ${statut}`;
          displaySnackbar(client, snackbarData);
        }
      });
    }
  };
  const addNewAdminsToCache = (cache: DataProxy, newAdmins: any[]) => {
    if (dataSource && dataSource === 'add-establishment') {
      let etabData = cache.readQuery<any>({
        query: GET_ETABLISHMENT_DATA,
      });

      if (etabData && etabData.etablishmentData) {
        const admins = etabData.etablishmentData.administrators || [];
        let newEtablishmentAdmins = [...newAdmins, ...admins];

        etabData.etablishmentData = {
          ...etabData.etablishmentData,
          id: newEtablishmentAdmins.length + 1,
          administrators: newEtablishmentAdmins,
        };

        cache.writeQuery({
          query: GET_ETABLISHMENT_DATA,
          data: {
            etablishmentData: etabData.etablishmentData,
          },
        });
      }
    } else {
      const adminData = cache.readQuery<Administrators>({
        query: GET_ADMINISTRATORS,
        variables,
      });
      if (newAdmins && adminData) {
        cache.writeQuery({
          query: GET_ADMINISTRATORS,
          variables,
          data: {
            administrators: [...newAdmins, ...adminData.administrators],
          },
        });
      }
    }
  };
  const onHandleAddUser = (data: FormInputDataState, cb: any) => {
    const user: User = formInputDataStateToUser(data, 'administrator') as User;
    handleEstablishmentCreateUserListValidation(user, client, () => {
      createAdmin({
        variables: {
          ...user,
          establishmentId: newEtablishmentId,
          skipMail: !!(dataSource === 'add-establishment'),
        },
        update: (cache, { data }) => {
          const newAdmin = data && data.createAdmin && data.createAdmin.data;
          addNewAdminsToCache(cache, [newAdmin]);
        },
      })
        .then(({ data }) => {
          return cb(!!(data && data.createAdmin && data.createAdmin.success));
        })
        .catch(() => {
          return cb(false);
        });
    });
  };

  const onDeleteAdmin = () => {
    if (adminId && adminId > 0) {
      deleteAdmin({
        variables: {
          id: adminId,
        },
        update: (cache, { data }) => {
          const deletedId = data && data.deleteAdmin && data.deleteAdmin.id;

          if (
            dataSource &&
            dataSource === 'add-establishment' &&
            data &&
            data.deleteAdmin
          ) {
            let etabData = cache.readQuery<any>({
              query: GET_ETABLISHMENT_DATA,
            });

            if (etabData && etabData.etablishmentData) {
              let admins = etabData.etablishmentData.administrators || [];
              let newAdmins = admins.filter((admin: any) => {
                if (admin && admin.id) {
                  return deletedId !== admin.id;
                }
              });

              etabData.etablishmentData = {
                ...etabData.etablishmentData,
                administrators: newAdmins,
              };
              cache.writeQuery({
                query: GET_ETABLISHMENT_DATA,
                data: {
                  etablishmentData: etabData.etablishmentData,
                },
              });
            }
          } else {
            const adminData = cache.readQuery<Administrators>({
              query: GET_ADMINISTRATORS,
              variables,
            });

            if (deletedId && adminData) {
              const newData = adminData.administrators.filter(
                (admin) => admin && admin.id !== deletedId,
              );
              cache.writeQuery({
                query: GET_ADMINISTRATORS,
                variables,
                data: {
                  administrators: newData,
                },
              });
            }
          }
        },
      });
    } else {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la suppression d'administrateur";
      displaySnackbar(client, snackbarData);
    }
    setOpenConfirmDialog(false);
  };

  const onHandleDelete = (id: number) => {
    setAdminId(id);
    setOpenConfirmDialog(true);
  };

  const onHandleEditUser = (id: number) => {
    setEditId(id);
    const input = data.find((item) => item.id === id).user;
    setInputUpdateData({
      ...input,
      name: input.firstName,
      lastName: input.lastName || '',
      status: input.enabled,
    });
    setOpenUpdateUserDialog(true);
  };

  const onUpdatedCachedEtab: any = (
    cache: DataProxy,
    { data }: { data: UpdateAdmin | null | undefined },
  ) => {
    if (
      dataSource &&
      dataSource === 'add-establishment' &&
      data &&
      data.updateAdmin
    ) {
      let etabData = cache.readQuery<any>({
        query: GET_ETABLISHMENT_DATA,
      });

      if (etabData && etabData.etablishmentData) {
        let admins = etabData.etablishmentData.administrators || [];
        let newAdmins = admins.filter((admin: any) => {
          if (admin && admin.id && data.updateAdmin && data.updateAdmin.id) {
            return admin.id !== data.updateAdmin.id;
          }
        });
        let newEtablishmentAdmins = [data.updateAdmin, ...newAdmins];

        etabData.etablishmentData = {
          ...etabData.etablishmentData,
          administrators: newEtablishmentAdmins,
        };
        cache.writeQuery({
          query: GET_ETABLISHMENT_DATA,
          data: {
            etablishmentData: etabData.etablishmentData,
          },
        });
      }
    }
  };

  const onHandleUpdateUser = (data: FormInputDataState, cb: any) => {
    const user: User = formInputDataStateToUser(data, 'administrator') as User;
    handleEstablishmentCreateUserListValidation(user, client, () => {
      updateAdmin({
        variables: {
          ...user,
          id: editId,
        },
        update: onUpdatedCachedEtab,
      }).then(({ data }) => {
        if (data && data.updateAdmin) {
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = `L'administrateur a été mis à jour`;
          displaySnackbar(client, snackbarData);
        }
      });
    });
  };

  const onHandleSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKey(value);
  };

  const changeAdminsStatus = (
    selectedItems: number[],
    enabled: boolean,
  ): void => {
    const status = enabled ? 'activer' : 'désactiver';
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les administrateurs à ${status}`,
      )
    ) {
      updateAdminStatus({
        variables: { ids: selectedItems, status: enabled },
        update: (cache, { data }) => {
          if (
            dataSource &&
            dataSource === 'add-establishment' &&
            data &&
            data.updateAdminStatus
          ) {
            let etabData = cache.readQuery<any>({
              query: GET_ETABLISHMENT_DATA,
            });

            if (etabData && etabData.etablishmentData) {
              let admins = etabData.etablishmentData.administrators || [];
              let updatedIds = data.updateAdminStatus.map(
                (item: any) => item && item.id,
              );
              let newAdmins = admins.filter((admin: any) => {
                if (admin && admin.id) {
                  return !updatedIds.includes(admin.id);
                }
              });
              let newEtablishmentAdmins = [
                ...data.updateAdminStatus,
                ...newAdmins,
              ];

              etabData.etablishmentData = {
                ...etabData.etablishmentData,
                administrators: newEtablishmentAdmins,
              };
              cache.writeQuery({
                query: GET_ETABLISHMENT_DATA,
                data: {
                  etablishmentData: etabData.etablishmentData,
                },
              });
            }
          }
        },
      });
    }
  };

  const removeAdmins = (selectedItems: number[]): void => {
    if (
      checkActionGroup(
        selectedItems,
        client,
        `Sélectionnez les administrateurs à supprimer`,
      )
    ) {
      deleteAdmins({
        variables: { ids: selectedItems },
        update: (cache, { data }) => {
          const deletedAdmins = ((data && data.deleteAdmins) || []).map(
            (item) => item && item.id,
          );

          if (
            dataSource &&
            dataSource === 'add-establishment' &&
            data &&
            data.deleteAdmins
          ) {
            let etabData = cache.readQuery<any>({
              query: GET_ETABLISHMENT_DATA,
            });

            if (etabData && etabData.etablishmentData) {
              let admins = etabData.etablishmentData.administrators || [];
              let newAdmins = admins.filter((admin: any) => {
                if (admin && admin.id) {
                  return !deletedAdmins.includes(admin.id);
                }
              });

              etabData.etablishmentData = {
                ...etabData.etablishmentData,
                administrators: newAdmins,
              };
              cache.writeQuery({
                query: GET_ETABLISHMENT_DATA,
                data: {
                  etablishmentData: etabData.etablishmentData,
                },
              });
            }
          } else {
            const adminData = cache.readQuery<Administrators>({
              query: GET_ADMINISTRATORS,
              variables,
            });

            if (deletedAdmins && adminData && adminData.administrators) {
              const newData = adminData.administrators.filter(
                (admin) => admin && deletedAdmins.indexOf(admin.id) === -1,
              );
              cache.writeQuery({
                query: GET_ADMINISTRATORS,
                variables,
                data: {
                  administrators: newData,
                },
              });
            }
          }
        },
      });
    }
  };

  const onHandleAction = (action: ValueType<GroupAction>): void => {
    const actionGroup = action as GroupAction;

    if (actionGroup && actionGroup.value) {
      switch (actionGroup.value) {
        case 'enable':
          changeAdminsStatus(selectedItems, true);
          break;
        case 'disable':
          changeAdminsStatus(selectedItems, false);
          break;
        case 'remove':
          removeAdmins(selectedItems);
          break;
      }
    }
  };

  const onExcelReaderChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files) {
      const users: User[] = await getUsersFromFiles(
        { ...event.target.files },
        {
          userRole: 'administrator',
        },
      );
      const userValidationState: UserValidationState = validateUsers(
        users,
        getImportExcelValidators('administrator'),
      );
      if (userValidationState.valid) {
        createAdmins({
          variables: {
            input: getPartialUsers(
              users.map((user) => {
                return {
                  ...user,
                  establishmentId: newEtablishmentId,
                };
              }),
              ['userRole'],
            ) as CreateAdministratorInput[],
          },
          update: (cache, { data }) => {
            const newAdmins =
              data && data.createAdmins && data.createAdmins.data;
            if (newAdmins) {
              addNewAdminsToCache(cache, newAdmins);
            }
          },
        });
      } else if (userValidationState.error) {
        const errorMessage: string = getExcelImportUserErrorMessage(
          userValidationState.error,
        );
        showSnackbar({
          type: 'ERROR',
          message: errorMessage,
          client,
        });
      }
    }
  };

  const adminToolbarButtons = [
    <SearchInput
      onChange={onHandleSearchKey}
      value={searchKey}
      name="admin"
      placeholder="Recherchez un administrateur"
    />,
    <Button
      className={`${classes.button} ${classes.white}`}
      variant="contained"
      color="secondary"
      name="admin-add"
      style={{ minWidth: 218 }}
      startIcon={<AddBoxIcon />}
      onClick={() => setOpenDialog(true)}
    >
      Ajouter un administrateur
    </Button>,
    <ExcelReader
      onChange={onExcelReaderChange}
      onClick={() => {}}
      loading={false}
    />,
  ];

  return (
    <>
      <CustomTable
        toolbar
        selectable
        maxWidth={1348}
        data={data}
        columns={columns}
        toolbarActions={ACTIONS}
        onHandleAction={onHandleAction}
        toolbarButtons={adminToolbarButtons}
        toolbarStyles={{ display: 'flex', justifyContent: 'flex-end' }}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        loadingData={loading || loadingAdminList}
      />
      <UserFormDialog
        userRole="administrator"
        open={openDialog}
        title="Ajout d'un administrateur"
        onSubmit={onHandleAddUser}
        data={inputData}
        loadingButton={loadingCreateAdmin}
        handleClose={() => {
          setOpenDialog(false);
        }}
      />
      <UserFormDialog
        userRole="administrator"
        open={openUpdateUserDialog}
        title="Modifier un administrateur"
        onSubmit={onHandleUpdateUser}
        data={inputUpdateData}
        loadingButton={loadingUpdateAdmin}
        buttonLabel="Enregistrer"
        handleClose={() => {
          setOpenUpdateUserDialog(false);
        }}
      />
      <ConfirmationDialog
        title="Suppression"
        message="Etes-vous sûr de vouloir supprimer cet administrateur ?"
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteAdmin}
      />
    </>
  );
};

export default AdminList;
