import React from 'react';
import Box from '@material-ui/core/Box';

import BasicInformations from './BasicInformations';
import BeneficiaryChoice from './BeneficiaryChoice';
import WorkerChoice from './WorkerChoice';

export const getComponents = (
  inputData: any,
  setInputData: React.Dispatch<React.SetStateAction<any>>,
) => {
  return [
    <Box
      display="flex"
      justifyContent="center"
      style={{ padding: 16, marginBottom: 100 }}
    >
      <BasicInformations inputData={inputData} setInputData={setInputData} />
    </Box>,
    <Box
      display="flex"
      justifyContent="center"
      style={{ padding: 16, marginBottom: 100 }}
    >
      <BeneficiaryChoice inputData={inputData} setInputData={setInputData} />
    </Box>,
    <Box
      display="flex"
      justifyContent="center"
      style={{ padding: 16, marginBottom: 100 }}
    >
      <WorkerChoice inputData={inputData} setInputData={setInputData} />
    </Box>,
  ];
};
