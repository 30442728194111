import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  typography: {
    //fontFamily: 'Roboto',
  },
  palette: {
    primary: {
      main: '#002B67',
      light: '#87888a',
    },
    secondary: {
      main: '#F7941D',
    },
    text: {
      primary: '#212121',
      secondary: '#9E9E9E',
    },
  },
});

export default theme;
