/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DateEndType {
  END_AFTER = "END_AFTER",
  END_ON = "END_ON",
}

export enum FilterTypeEnum {
  ALL = "ALL",
  BENEFICIARY = "BENEFICIARY",
  ETABLISHMENT = "ETABLISHMENT",
  WORKER = "WORKER",
}

export enum InterventionPlanningEnum {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  TODO = "TODO",
  WAITING_VALIDATION = "WAITING_VALIDATION",
}

export enum PeriodicityTypeEnum {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

export enum Slug {
  CONTRACT = "CONTRACT",
  DOWNLOADABLE_DOCUMENT = "DOWNLOADABLE_DOCUMENT",
  ETABLISHMENT_STATUS = "ETABLISHMENT_STATUS",
  ETABLISHMENT_TYPE = "ETABLISHMENT_TYPE",
  FUNDING = "FUNDING",
  INTERVENTION_RECURRENCE = "INTERVENTION_RECURRENCE",
  INTERVENTION_STATUS = "INTERVENTION_STATUS",
  OCCUPATION = "OCCUPATION",
  TO_DO = "TO_DO",
}

export enum updatePlanningActionEnum {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export enum updatePlanningMembersEnum {
  BENEFICIARY = "BENEFICIARY",
  WORKERS = "WORKERS",
}

export interface CreateAdministratorInput {
  firstName?: string | null;
  lastName: string;
  email: string;
  password?: string | null;
  phone?: string | null;
  mobilePhone?: string | null;
  userFunction?: string | null;
  avatar?: string | null;
  enabled?: boolean | null;
  establishmentId?: number | null;
  skipMail?: boolean | null;
}

export interface CreateBeneficiaryInput {
  firstName?: string | null;
  lastName: string;
  email: string;
  phone?: string | null;
  mobilePhone?: string | null;
  avatar?: string | null;
  enabled?: boolean | null;
  birthDate?: any | null;
  signature?: string | null;
  guardianEnabled?: boolean | null;
  guardianName?: string | null;
  disabilityRecognition?: boolean | null;
  lastVisitDate?: any | null;
  nextMeetingDate?: any | null;
  occupation?: string | null;
  beneficiaryDocuments?: string | null;
  unitLife?: string | null;
  etablishmentType?: string | null;
  contractId?: number | null;
  unitLifeId?: number | null;
  etablishmentId?: number | null;
  interventionIds?: (number | null)[] | null;
  downloadableDocumentIds?: (number | null)[] | null;
}

export interface CreateEtablishmentInput {
  name: string;
  address: string;
  rcs: string;
  postalCode: string;
  city: string;
  effective?: number | null;
  logo?: string | null;
  prestations?: string | null;
  etablishmentObject?: string | null;
  enabled?: boolean | null;
  status?: string | null;
  etablishmentTypeId?: number | null;
  etablishmentStatusId?: number | null;
  etablishmentFundingId?: number | null;
  administratorIds?: (number | null)[] | null;
  interventionIds?: (number | null)[] | null;
  workerIds?: (number | null)[] | null;
  beneficiaryIds?: (number | null)[] | null;
  residenceIds?: (number | null)[] | null;
}

export interface CreateInterventionInput {
  description?: string | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  etablishmentId?: number | null;
  interventionStatusId?: number | null;
  interventionRecurrenceId?: number | null;
  workerIds?: (number | null)[] | null;
  beneficiaryIds?: (number | null)[] | null;
  comment?: string | null;
  prestation?: string | null;
  location?: string | null;
}

export interface CreateInterventionPlanningInput {
  description: string;
  dateEnd: any;
  dateStart: any;
  creatorId?: number | null;
  timeStart: string;
  timeEnd: string;
  ocurrence?: number | null;
  type: PeriodicityTypeEnum;
  repetition: number;
  monday?: boolean | null;
  tuesday?: boolean | null;
  wednesday?: boolean | null;
  thursday?: boolean | null;
  friday?: boolean | null;
  saturday?: boolean | null;
  sunday?: boolean | null;
  etablishmentId: number;
  location?: string | null;
  prestation?: string | null;
  interventionRecurrenceId?: number | null;
  comment?: string | null;
  workerId?: number | null;
  beneficiaryId?: number | null;
  dateEndType: DateEndType;
  dateTimeStartTimestamp?: string | null;
  dateTimeEndTimestamp?: string | null;
}

export interface CreateInterventionPlanningMultipleWorkersInput {
  description: string;
  dateEnd: any;
  dateStart: any;
  creatorId?: number | null;
  timeStart: string;
  timeEnd: string;
  ocurrence?: number | null;
  type: PeriodicityTypeEnum;
  repetition: number;
  monday?: boolean | null;
  tuesday?: boolean | null;
  wednesday?: boolean | null;
  thursday?: boolean | null;
  friday?: boolean | null;
  saturday?: boolean | null;
  sunday?: boolean | null;
  etablishmentId: number;
  location?: string | null;
  prestation?: string | null;
  interventionRecurrenceId?: number | null;
  comment?: string | null;
  workerIds: (number | null)[];
  beneficiaryId?: number | null;
  dateEndType: DateEndType;
  dateTimeStartTimestamp?: string | null;
  dateTimeEndTimestamp?: string | null;
}

export interface CreateInterventionPlanningResidenceImportInput {
  description: string;
  dateEnd: any;
  dateStart: any;
  timeStart: string;
  timeEnd: string;
  ocurrence?: number | null;
  type: PeriodicityTypeEnum;
  repetition: number;
  monday?: boolean | null;
  tuesday?: boolean | null;
  wednesday?: boolean | null;
  thursday?: boolean | null;
  friday?: boolean | null;
  saturday?: boolean | null;
  sunday?: boolean | null;
  etablishmentId: number;
  location?: string | null;
  prestation?: string | null;
  interventionRecurrenceId?: number | null;
  comment?: string | null;
  workers: (string | null)[];
  beneficiaries: (string | null)[];
  dateEndType: DateEndType;
  dateTimeStartTimestamp?: string | null;
  dateTimeEndTimestamp?: string | null;
  status?: InterventionPlanningEnum | null;
}

export interface CreateInterventionPlanningResidenceInput {
  description: string;
  dateEnd: any;
  dateStart: any;
  timeStart: string;
  timeEnd: string;
  ocurrence?: number | null;
  type: PeriodicityTypeEnum;
  repetition: number;
  monday?: boolean | null;
  tuesday?: boolean | null;
  wednesday?: boolean | null;
  thursday?: boolean | null;
  friday?: boolean | null;
  saturday?: boolean | null;
  sunday?: boolean | null;
  etablishmentId: number;
  location?: string | null;
  prestation?: string | null;
  interventionRecurrenceId?: number | null;
  comment?: string | null;
  workerIds: (number | null)[];
  beneficiaryIds: (number | null)[];
  dateEndType: DateEndType;
  dateTimeStartTimestamp?: string | null;
  dateTimeEndTimestamp?: string | null;
  status?: InterventionPlanningEnum | null;
}

export interface CreateNotificationInput {
  seen?: number | null;
}

export interface CreatePlanningChildInput {
  planningParentId?: number | null;
  beneficiarySignature?: string | null;
  beneficiaryId?: number | null;
  workerId?: number | null;
  dateEnd?: any | null;
  dateStart?: any | null;
  timeStart: string;
  timeEnd: string;
  ocurrence?: number | null;
  comment?: string | null;
  dateStartTimestamp?: string | null;
  dateEndTimestamp?: string | null;
  notificationId?: number | null;
  interventionId?: number | null;
  interventionReportId?: number | null;
  statusId?: number | null;
  periodicityId?: number | null;
  title?: string | null;
}

export interface CreatePlanningInput {
  dateStart?: any | null;
  dateEnd?: any | null;
  beneficiaryId?: number | null;
  workerId?: number | null;
  interventionId?: number | null;
}

export interface CreatePrestationInput {
  libelle?: string | null;
  establishmentId?: number | null;
}

export interface CreateUnitLifeInput {
  name: string;
  establishmentId?: number | null;
}

export interface CreateWorkerInput {
  firstName?: string | null;
  lastName: string;
  email: string;
  password?: string | null;
  phone?: string | null;
  mobilePhone?: string | null;
  avatar?: string | null;
  enabled?: boolean | null;
  lastVisitDate?: any | null;
  userFunction?: string | null;
  nextMeetingDate?: any | null;
  entryDate?: any | null;
  releaseDate?: any | null;
  skipMail?: boolean | null;
  occupation?: string | null;
  contractId?: number | null;
  etablishmentId?: number | null;
  interventionIds?: (number | null)[] | null;
  downloadableDocumentIds?: (number | null)[] | null;
}

export interface FilterAdminInput {
  take?: number | null;
  skip?: number | null;
  filter?: any | null;
  id?: number | null;
  orderBy?: any | null;
}

export interface FilterBeneficiaryInput {
  take?: number | null;
  skip?: number | null;
  filter?: any | null;
  id?: number | null;
  orderBy?: any | null;
}

export interface FilterEstablishementChild {
  filter?: any | null;
}

export interface FilterEtablishmentInput {
  take?: number | null;
  skip?: number | null;
  filter?: any | null;
  orderBy?: any | null;
}

export interface FilterPrestation {
  id?: number | null;
  libelle?: string | null;
  establishmentId?: number | null;
}

export interface FilterType {
  filterType?: (FilterTypeEnum | null)[] | null;
  etablishmentId?: number | null;
}

export interface FilterUnitLifeInput {
  filter?: any | null;
}

export interface FilterWorkerInput {
  take?: number | null;
  skip?: number | null;
  filter?: any | null;
  id?: number | null;
  orderBy?: any | null;
}

export interface InterventionReportFilter {
  ids?: number[] | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  workerIds?: number[] | null;
  beneficiaryIds?: number[] | null;
  establishmentIds?: number[] | null;
  status?: (InterventionPlanningEnum | null)[] | null;
  skipFilter?: number | null;
  takeFilter?: number | null;
  rapportTypes?: number | null;
}

export interface MyPlanningAdvancedFilters {
  etablishmentId?: number | null;
  workerIds?: number[] | null;
  beneficiaryIds?: number[] | null;
  d1?: any | null;
  d2?: any | null;
  keyword?: string | null;
}

export interface MyPlanningFilter {
  etablishmentId?: number | null;
  workerIds?: number[] | null;
  beneficiaryIds?: number[] | null;
}

export interface UpdateDocumentInput {
  id: number;
  name?: string | null;
  path?: string | null;
}

export interface UpdateEtablishmentInput {
  id: number;
  name?: string | null;
  address?: string | null;
  rcs?: string | null;
  postalCode?: string | null;
  city?: string | null;
  effective?: number | null;
  logo?: string | null;
  prestations?: string | null;
  etablishmentObject?: string | null;
  enabled?: boolean | null;
  etablishmentTypeId?: number | null;
  etablishmentStatusId?: number | null;
  etablishmentFundingId?: number | null;
  administratorIds?: (number | null)[] | null;
  interventionIds?: (number | null)[] | null;
  workerIds?: (number | null)[] | null;
  beneficiaryIds?: (number | null)[] | null;
  residenceIds?: (number | null)[] | null;
}

export interface UpdateInterventionInput {
  id: number;
  description?: string | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  etablishmentId?: number | null;
  interventionStatusId?: number | null;
  interventionRecurrenceId?: number | null;
  workerIds?: (number | null)[] | null;
  beneficiaryIds?: (number | null)[] | null;
  comment?: string | null;
  prestation?: string | null;
  location?: string | null;
}

export interface UpdateInterventionPlanningInput {
  id: number;
  description?: string | null;
  timeStart?: string | null;
  timeEnd?: string | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  comment?: string | null;
  location?: string | null;
  prestation?: string | null;
  beneficiaryId?: number | null;
  allOccurencies?: boolean | null;
}

export interface UpdateInterventionReportInput {
  id: number;
  planningId: number;
  dateStart: any;
  dateEnd: any;
  signature?: string | null;
}

export interface UpdatePrestationInput {
  id: number;
  libelle?: string | null;
  etablishmentId?: number | null;
}

export interface UpdateUnitLifeInput {
  id: number;
  name: string;
  establishmentId?: number | null;
}

export interface UpdateUserInput {
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  userFunction?: string | null;
  mobilePhone?: string | null;
  enabled?: boolean | null;
  oldPassword?: string | null;
  newPassword?: string | null;
}

export interface ValidateInterventionPlanningInput {
  planningId: number;
  beneficiaryId?: number | null;
  dateTimeEnd: any;
  dateTimeStart: any;
  duration: number;
  signature?: string | null;
}

/**
 * ------- Inputs ------- #
 */
export interface updatePlanningMembersInput {
  idmembers: number[];
  action: updatePlanningActionEnum;
  members: updatePlanningMembersEnum;
  idPlanning: number[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
