import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';
import TextItem from '../TextItem';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import { WorkerData } from '../../../../types/user';

interface WorkersInfosProps {
  worker: WorkerData;
}

const WorkerInfos: FC<WorkersInfosProps> = (props) => {
  const { worker } = props;
  const { firstName, lastName, mobilePhone, phone, email, enabled } = worker;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.nameInfo}>
        <Typography className={classes.name}>
          {`${firstName} ${lastName}`}
        </Typography>
        <FormGroup className={`CustomSwitch-root ${classes.formGroup}`}>
          <InputLabel className={classes.inputLabel}>Statut</InputLabel>
          {enabled ? 'Activé' : 'Désactivé'}
        </FormGroup>
      </Box>
      <Box className={classes.info}>
        <Box>
          <Typography
            variant="body2"
            component="h4"
            color="textSecondary"
            className={classes.textSecondary}
          >
            Coordonnées
          </Typography>
          <TextItem
            width={100}
            title="Mail :"
            content={email}
            inline
            className={classes.mb}
          />
          <TextItem
            width={100}
            title="Tél mobile :"
            content={mobilePhone || '-'}
            inline
            className={classes.mb}
          />
          <TextItem
            width={100}
            title="Tél fixe :"
            content={phone || '-'}
            inline
          />
        </Box>
      </Box>
    </Box>
  );
};

export default WorkerInfos;
