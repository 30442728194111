import gql from 'graphql-tag';

export const USER_FRAGMENT = gql`
  fragment UserInfo on User {
    id
    firstName
    lastName
    email
    phone
    userFunction
    mobilePhone
    enabled
  }
`;

export const WORKER_FRAGMENT = gql`
  fragment WorkerInfo on Worker {
    id
    user {
      ...UserInfo
    }
  }
  ${USER_FRAGMENT}
`;

export const ESTABLISHMENT_FRAGMENT = gql`
  fragment EtablishmentInfo on Etablishment {
    id
    name
  }
`;
