import React, { FC, useState, useEffect, useRef, useContext } from 'react';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import {
  useApolloClient,
  useQuery,
  useLazyQuery,
  useMutation,
} from '@apollo/react-hooks';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NotificationIcon from '@material-ui/icons/Notifications';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Navbar from 'react-bootstrap/Navbar';

import { clearLocalStorage } from '../../../services/localStorage';
import { GET_ME } from '../../../graphql/user/query';

import { UPDATE_USER } from '../../../graphql/user/mutation';
import {
  UpdateUser,
  UpdateUserVariables,
} from '../../../graphql/user/types/UpdateUser';
import { GetMe } from '../../../graphql/user/types/GetMe';
import Logo from '../../../assets/images/logo-neoptim.png';
import AvatarImg from '../../../assets/images/wolves.png';
import UserInfo from './UserInfo';
import useStyles from './styles';
import {
  validateUser,
  getDefaultUserValidators,
  getUserValidationDefaultErrorMessages,
} from '../../../utils/user-validator.utils';
import { UserValidationState } from '../../../types/user-validator.types';
import UserContext from '../../Context/UserContext';
import { SnackbarType } from '../../../types';
import { displaySnackbar } from '../../../common/snackbar';
import { getRole } from '../../../types/role';
import { MenuItemInterface, getMenus, getMenusOrganisation } from './menu';
import { AppContext } from '../../Context';
import { GET_ETABLISHMENT_BY_USER_ID } from '../../../graphql/etablishment/query';
import {
  GetEtablishmentByUserId,
  GetEtablishmentByUserIdVariables,
} from '../../../graphql/etablishment/types/GetEtablishmentByUserId';
import { FormInputDataState, User, UserRole } from '../../../types/user';
import UserFormDialog from '../../Establishment/EstablishmentAdd/UserFormDialog';
import { ApolloError } from 'apollo-boost';

interface HeaderProps {}

const INIT_DATA = {
  name: '',
  lastName: '',
  email: '',
  mobilePhone: '',
  phone: '',
  status: false,
  userFunction: '',
  updatePassword: false,
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const MODEL_PASSWORD_ERROR = {
  status: false,
  error: '',
};

const Header: FC<HeaderProps & RouteComponentProps> = (props) => {
  const { history } = props;
  const classes = useStyles();
  const client = useApolloClient();
  const { pathname } = useLocation();
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const navEl = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeMenu, setActiveMenu] = useState<string>('');
  const [userId, setUserId] = useState<number>(0);
  const [userName, setUserName] = useState<string>('');
  const [userRole, setUserRole] = useState<string>('');
  const [menus, setMenus] = useState<MenuItemInterface[]>([]);
  const [establishmentType, setEstablishementType] = useState<string>('');
  const [passwordError, setPasswordError] = useState<any>(MODEL_PASSWORD_ERROR);
  const [isNavExpanded, setIsNavExpanded] = useState<boolean>(false);
  const [showPasswordFields, setShowPasswordFields] = useState<boolean>(false);
  const [openProfileUpdateModal, setOpenProfileUpdateModal] = useState(false);
  const [inputUpdateUserData, setInputUpdateUserData] =
    useState<FormInputDataState>(INIT_DATA);

  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };

  const open = Boolean(anchorEl);

  const { data } = useQuery<GetMe>(GET_ME);

  const [getEtablishement] = useLazyQuery<GetEtablishmentByUserId>(
    GET_ETABLISHMENT_BY_USER_ID,
    {
      onCompleted: (data: GetEtablishmentByUserId) => {
        if (
          data &&
          data.etablishmentByUserId &&
          data.etablishmentByUserId &&
          data.etablishmentByUserId.etablishmentType &&
          data.etablishmentByUserId.etablishmentType.name
        ) {
          setEstablishementType(
            data.etablishmentByUserId.etablishmentType.name,
          );
          if (userRole !== 'Administrateur Neoptim') {
            setMenus(
              getMenusOrganisation(
                userRole,
                data.etablishmentByUserId.etablishmentType.name,
              ),
            );
          }
        }
      },
      onError: (error) => {
        console.log('err ', error);
      },
    },
  );

  const [getUserData] = useLazyQuery<GetMe>(GET_ME, {
    onCompleted: (data: GetMe) => {
      if (data && data.me) {
        const {
          id,
          phone,
          enabled,
          lastName,
          firstName,
          mobilePhone,
          userFunction,
          email,
        } = data.me;

        setInputUpdateUserData({
          ...inputUpdateUserData,
          status: enabled,
          email: `${email}`,
          phone: `${phone}`,
          lastName: lastName,
          name: `${firstName}`,
          userFunction: `${userFunction}`,
          mobilePhone: `${mobilePhone}`,
        });
        getEtablishement({ variables: { id } });
      }
    },
    onError: (error: ApolloError) => {
      snackbarData.type = 'ERROR';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message =
        "Une erreur s'est produite lors de la récupération des informations de l'administrateur";
      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  const [updateUser, { loading: loadingUpdateUser }] = useMutation<
    UpdateUser,
    UpdateUserVariables
  >(UPDATE_USER, {
    onCompleted: (data: UpdateUser) => {
      if (data && data.updateUser) {
        setInputUpdateUserData({ ...inputUpdateUserData });
        snackbarData.type = 'SUCCESS';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = `Votre profil a été mis à jour`;
        displaySnackbar(client, snackbarData);
        setOpenProfileUpdateModal(false);
      }
    },
    onError: (error: ApolloError) => {
      if (error.graphQLErrors) {
        const errorMessage = error.graphQLErrors[0].message;
        switch (errorMessage) {
          case 'OLD_PASSWORD_DONT_MATCH':
            snackbarData.type = 'WARNING';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message =
              'Votre ancien mot de passe ne correspond pas';
            break;
          case 'USER_NOT_FOUND':
            snackbarData.type = 'ERROR';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = 'Utilisateur introuvable';
            break;
          default:
            snackbarData.type = 'ERROR';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message =
              "Une erreur s'est produite lors de la mise à jour d'administrateur";
            break;
        }
      }

      if (error.networkError) {
        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message =
          'Erreur de réseau. Veuillez vérifier votre connexion internet';
      }
      displaySnackbar(client, snackbarData);
    },
  });

  useEffect(() => {
    document.addEventListener('click', handleExpanded, false);
    return () => {
      document.removeEventListener('click', handleExpanded, false);
    };
  }, []);

  useEffect(() => {
    if (data && data.me) {
      const { role, phone, email, enabled, lastName, firstName, mobilePhone } =
        data.me;
      const userRole = getRole((role && role.name) || '');

      if (menus.length == 0) {
        if (userRole !== 'Administrateur Neoptim') {
          setUserId(data.me.id);
          getEtablishement({
            variables: { id: data && data.me && data.me.id },
          });
        } else {
          if (role) {
            setMenus(
              getMenusOrganisation(role && role.name, establishmentType),
            );
          }
        }
      } else {
      }

      setInputUpdateUserData({
        ...inputUpdateUserData,
        status: enabled,
        email: `${email}`,
        phone: `${phone}`,
        lastName: lastName,
        name: `${firstName}`,
        mobilePhone: `${mobilePhone}`,
      });
      setUserName(`${firstName} ${lastName}`);
      setUserRole(userRole);
    }
  }, [data]);

  useEffect(() => {
    const paths = pathname.split('/').filter((item) => item);
    if (paths && paths.length > 0) setActiveMenu(`/${paths[0]}`);
  }, [pathname]);

  const handleClick = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const isValidPassword = (
    newPassword: String | undefined,
    oldPassword: String | undefined,
  ): Boolean => {
    if (newPassword == '' || newPassword == '') {
      snackbarData.type = 'WARNING';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'Champ manquant';
      displaySnackbar(client, snackbarData);
      return false;
    }
    if (
      (oldPassword && oldPassword.trim().length < 8) ||
      (newPassword && newPassword.trim().length < 8)
    ) {
      setPasswordError({
        status: true,
        error: 'Saisissez au minimum 8 caractères',
      });
      snackbarData.type = 'WARNING';
      snackbarData.autoHideDuration = 5000;
      snackbarData.message = 'Saisissez au minimum 8 caractères';
      displaySnackbar(client, snackbarData);
      return false;
    }

    return true;
  };

  const handleSaveProfile = (data: FormInputDataState, cb: any): void => {
    const userRole = userContext.userRole as UserRole;
    const user: User = {
      email: data.email,
      phone: data.phone,
      enabled: data.status,
      firstName: data.name,
      id: userContext.userId,
      lastName: data.lastName,
      mobilePhone: data.mobilePhone,
      userRole,
    };

    if (data && data.updatePassword == false) {
      const userValidationState: UserValidationState = validateUser(
        user,
        getDefaultUserValidators(userRole),
      );
      if (userValidationState.valid) {
        updateUser({
          variables: {
            input: {
              phone: data.phone,
              firstName: data.name,
              enabled: data.status,
              id: userContext.userId,
              lastName: data.lastName,
              userFunction: data.userFunction,
              mobilePhone: data.mobilePhone,
            },
          },
        });
      } else if (userValidationState.error) {
        const errorMessage: string = getUserValidationDefaultErrorMessages(
          userValidationState.error,
        );

        snackbarData.type = 'ERROR';
        snackbarData.autoHideDuration = 5000;
        snackbarData.message = errorMessage;
        displaySnackbar(client, snackbarData);
      }
    } else {
      if (isValidPassword(data.newPassword, data.confirmPassword)) {
        if (
          data &&
          data.newPassword &&
          data.newPassword.trim() === data.confirmPassword &&
          data.confirmPassword.trim()
        ) {
          const userValidationState: UserValidationState = validateUser(
            user,
            getDefaultUserValidators(userRole),
          );
          if (userValidationState.valid) {
            updateUser({
              variables: {
                input: {
                  phone: data.phone,
                  firstName: data.name,
                  enabled: data.status,
                  id: userContext.userId,
                  lastName: data.lastName,
                  mobilePhone: data.mobilePhone,
                  newPassword: data.newPassword,
                  oldPassword: data.oldPassword,
                  userFunction: data.userFunction,
                },
              },
            });
          } else if (userValidationState.error) {
            const errorMessage: string = getUserValidationDefaultErrorMessages(
              userValidationState.error,
            );

            snackbarData.type = 'ERROR';
            snackbarData.autoHideDuration = 5000;
            snackbarData.message = errorMessage;
            displaySnackbar(client, snackbarData);
          }
        } else {
          snackbarData.type = 'WARNING';
          snackbarData.autoHideDuration = 5000;
          snackbarData.message = 'La confirmation de mot de passe est invalide';
          displaySnackbar(client, snackbarData);
        }
      }
    }
  };

  const handleUpdateProfile = (): void => {
    getUserData();
    handleClose();
    setOpenProfileUpdateModal(true);
  };

  const handleLogout = (): void => {
    clearLocalStorage();
    handleClose();
    window.location.reload();
  };

  const navigateTo = (path: string): void => {
    history.push(path);
  };

  const renderMenu = () => (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
    >
      <MenuItem key="profile" onClick={handleUpdateProfile}>
        Mon compte
      </MenuItem>
      <MenuItem key="logout" onClick={handleLogout}>
        Déconnexion
      </MenuItem>
    </Menu>
  );

  const handleExpanded = (event: any) => {
    if (
      navEl &&
      navEl.current &&
      navEl.current !== event.target &&
      !navEl.current.contains(event.target)
    ) {
      setIsNavExpanded(false);
    }
  };

  const handleClickLogo = () => {
    if (appContext && appContext.baseURL) {
      history.push(appContext.baseURL);
    }
  };

  return (
    <div ref={navEl}>
      <Navbar
        collapseOnSelect
        onToggle={(expanded: boolean) => setIsNavExpanded(expanded)}
        expanded={isNavExpanded}
        expand="xl"
        className={classes.root}
      >
        <Box display="flex">
          <span className={classes.brandLink} onClick={handleClickLogo}>
            <img src={Logo} alt="Logo" className={classes.logo} />
          </span>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </Box>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className={classes.collapsNavBar}
        >
          <Box className={classes.menuList}>
            <Box className={classes.userSectionMobile}>
              <UserInfo name={userName} role={userRole} />
              <Avatar className={classes.avatar} src={AvatarImg} />
            </Box>
            <Box className={classes.navbar}>
              {menus.map((item: MenuItemInterface, index: number) => (
                <MenuItem
                  key={index}
                  onClick={() => navigateTo(item.path)}
                  className={`${classes.navbarItem} ${
                    activeMenu.includes(item.path) && classes.activeMenu
                  }`}
                >
                  {item.text}
                </MenuItem>
              ))}
            </Box>
          </Box>
        </Navbar.Collapse>
        <Box className={classes.rightMenu}>
          <Box className={classes.userSection}>
            <UserInfo name={userName} role={userRole} />
            <Avatar className={classes.avatar} src={AvatarImg} />
          </Box>
          <IconButton
            aria-label="more"
            color="inherit"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <ExpandMoreIcon className={classes.expandButton} />
          </IconButton>
          {renderMenu()}
        </Box>
      </Navbar>
      <UserFormDialog
        isUpdatePassword={true}
        disableMailField={true}
        buttonLabel="Enregistrer"
        data={inputUpdateUserData}
        title="Modification profil"
        onSubmit={handleSaveProfile}
        open={openProfileUpdateModal}
        handleClose={() => {
          setOpenProfileUpdateModal(false);
        }}
      />
    </div>
  );
};

export default withRouter(Header);
