import React, { FC } from 'react';
import Box from '@material-ui/core/Box';

import useStyles from './styles';

interface FooterProps {}

const Footer: FC<FooterProps> = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <div>{children}</div>
    </Box>
  );
};

export default Footer;
