import React, { FC, useContext, useEffect } from 'react';
import Link from '@material-ui/core/Link';
import { AppointmentForm } from '@devexpress/dx-react-scheduler-material-ui';
import { useParams } from 'react-router-dom';
import useStyles from './styles';
import { Box, InputLabel, MenuItem, Select, Button } from '@material-ui/core';
import { displaySnackbar } from '../../../../common/snackbar';
import { SnackbarType } from '../../../../types';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';
import {
  Intervention,
  InterventionVariables,
} from '../../../../graphql/intervention/types/Intervention';
import { GET_INTERVENTION } from '../../../../graphql/intervention/query';
import { useState } from 'react';
import { isNumber } from 'lodash';
import { PLANNING_DATA } from '../../../../constants/planning';
import { PlanningDataType } from '../../../../types/planning';
import { InterventionPlanningEnum } from '../../../../graphql/types/graphql-global-types';
import BeneficiaryPlanningEdit from './BeneficiaryPlanningEdit/BeneficiaryPlanningEdit';
import WorkerPlanningEdit from './WorkerPlanningEdit/WorkerPlanningEdit';
import { GET_PLANNING_DATA } from '../../../../graphql/common/planning/query';
import { EstablishmentContext } from '../../../Context';
import { GET_ETABLISHMENT_DETAILS } from '../../../../graphql/etablishment/query';
import {
  EtablishmentDetails,
  EtablishmentDetailsVariables,
} from '../../../../graphql/etablishment/types/EtablishmentDetails';
import { UPDATE_INTERVENTION_PLANNING_STATUS } from '../../../../graphql/planning/mutation';
import {
  GET_PLANNING_BY_ETABLISHMENT,
  GET_PRESTATION_BY_PLANNING,
} from '../../../../graphql/planning/query';
import { Prestation } from '../../../../types/prestation';
import {
  Prestations,
  PrestationsVariables,
} from '../../../../graphql/prestation/types/Prestations';
import { GET_PRESTATIONS } from '../../../../graphql/prestation/query';
import moment from 'moment';
import FilterDate from '../../SearchFilter/FilterDate';
import TextField from '@material-ui/core/TextField';
import Row from 'react-bootstrap/cjs/Row';
import Col from 'react-bootstrap/cjs/Col';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  PrestationByPlanning,
  PrestationByPlanningVariables,
} from '../../../../graphql/planning/types/PrestationByPlanning';

interface AppointmentDetailsProps extends AppointmentForm.BasicLayoutProps {}

const AppointmentDetails: FC<AppointmentDetailsProps> = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const { appointmentData, onFieldChange, ...rest } = props;
  const [interventionId, setInterventionId] = useState<number>(0);
  const [appointment, setAppointment] =
    useState<PlanningDataType>(PLANNING_DATA);
  const [prestations, setPrestation] = useState<string[]>([]);
  const [dataPrestation, setDataPrestation] = useState<any[]>([]);
  const [openBeneficiaryDialog, setOpenBeneficiaryDialog] =
    useState<boolean>(false);
  const [openWorkerDialog, setOpenWorkerDialog] = useState<boolean>(false);
  const { establishmentId } = useContext(EstablishmentContext);
  const [isReadyOnly, setIsReadOnly] = useState<boolean>(false);

  const { data } = useQuery<EtablishmentDetails, EtablishmentDetailsVariables>(
    GET_ETABLISHMENT_DETAILS,
    {
      variables: { id: establishmentId },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data && data.etablishment && data.etablishment.prestations) {
          setPrestation(data.etablishment.prestations.split(','));
        }
      },
      onError: (error) => {},
    },
  );
  const snackbarData: SnackbarType = {
    type: 'ERROR',
    message: '',
    isOpen: true,
  };
  const { id }: any = useParams();
  const variables = {
    input: {
      establishmentId: establishmentId || parseInt(id),
    },
  };

  const idPlanning: any = appointmentData?.id || '';

  const { data: prestationData } = useQuery<
    PrestationByPlanning,
    PrestationByPlanningVariables
  >(GET_PRESTATION_BY_PLANNING, {
    fetchPolicy: 'network-only',
    variables: {
      planningId: idPlanning,
    },
    onCompleted: (data) => {
      if (data?.prestationByPlanning) {
        onFieldChange({ prestation: data.prestationByPlanning });
      }
    },
  });

  const {
    loading: loadingPrestationList,
    data: prestationList,
    refetch,
  } = useQuery<Prestations, PrestationsVariables>(GET_PRESTATIONS, {
    variables,
  });

  useEffect(() => {
    if (prestationList && prestationList.prestations) {
      setDataPrestation(prestationList.prestations);
    }
  }, [prestationList]);

  useEffect(() => {
    if (appointmentData && appointmentData.id && isNumber(appointmentData.id)) {
      setInterventionId(appointmentData.id);
    }
  }, [interventionId]);

  useEffect(() => {
    if (
      appointmentData &&
      appointmentData.status === InterventionPlanningEnum.COMPLETED
    ) {
      setIsReadOnly(true);
    }
  }, [appointmentData]);

  const [updateInterventionStatus] = useMutation(
    UPDATE_INTERVENTION_PLANNING_STATUS,
    {
      refetchQueries: [
        {
          query: GET_PLANNING_BY_ETABLISHMENT,
          variables: { etablishmentId: appointmentData.etablissementId },
        },
      ],
      onCompleted: (data) => {
        if (data && data.updateInterventionPlanningStatus) {
          switch (data.updateInterventionPlanningStatus.status.name) {
            case 'IN_PROGRESS':
              snackbarData.message = "L'intervention a commencé";
              break;
            case 'WAITING_VALIDATION':
              snackbarData.message = 'Intervention en attente de validation';
              break;
            case 'COMPLETED':
              snackbarData.message = 'Intervention validée';
              break;
            case 'CANCELED':
              snackbarData.message = 'Intervention annulée';
              break;
            default:
              break;
          }
          snackbarData.type = 'SUCCESS';
          snackbarData.autoHideDuration = 5000;
          displaySnackbar(client, snackbarData);
        }
      },
      onError: (error) => {},
    },
  );

  const { data: interventionData } = useQuery<
    Intervention,
    InterventionVariables
  >(GET_INTERVENTION, {
    variables: { id: interventionId },
  });

  const planningData = client.readQuery({
    query: GET_PLANNING_DATA,
  });

  const handleOpenBenef = () => {
    setOpenBeneficiaryDialog(true);
  };

  const handleOpenWorker = () => {
    setOpenWorkerDialog(true);
  };
  const onChangeBothDates = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const heureDebut = moment.utc(appointmentData.startDate).format('HH:mm');
    const heureFin = moment.utc(appointmentData.endDate).format('HH:mm');
    onFieldChange({
      startDate: moment
        .utc(value + ' ' + heureDebut, 'YYYY-MM-DD HH:mm')
        .toDate(),
    });
    onFieldChange({
      endDate: moment.utc(value + ' ' + heureFin, 'YYYY-MM-DD HH:mm').toDate(),
    });
  };
  const onChangeStartHour = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const date = moment.utc(appointmentData.startDate).format('DD/MM/YYYY');
    onFieldChange({
      startDate: moment.utc(date + ' ' + value, 'DD/MM/YYYY HH:mm').toDate(),
    });
    onFieldChange({
      endDate: moment.utc(appointmentData.endDate, 'YYYY-MM-DD HH:mm').toDate(),
    });
  };
  const onChangeEndHour = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const date = moment.utc(appointmentData.endDate).format('DD/MM/YYYY');
    onFieldChange({
      endDate: moment.utc(date + ' ' + value, 'DD/MM/YYYY HH:mm').toDate(),
    });
    onFieldChange({
      startDate: moment
        .utc(appointmentData.startDate, 'YYYY-MM-DD HH:mm')
        .toDate(),
    });
  };
  const startDate_moment = moment.utc(
    appointmentData.startDate,
    'YYYY-MM-DD HH:mm',
  );
  const endDate_moment = moment.utc(
    appointmentData.endDate,
    'YYYY-MM-DD HH:mm',
  );
  const startDate_formatted = startDate_moment.format('YYYY-MM-DD');
  const endDate_formatted = endDate_moment.format('YYYY-MM-DD');

  const startHour = startDate_moment.format('HH:mm');
  const endHour = endDate_moment.format('HH:mm');

  /*
  const handleOpenBenef = () => {
    if (
      interventionData &&
      interventionData.intervention &&
      interventionData.intervention.beneficiaries
    ) {
      let benefs = interventionData.intervention.beneficiaries;
      let benefArray: (number | undefined)[] = [];
      benefs.forEach((benef) => {
        benefArray.push(benef?.id);
      });

      client.writeQuery({
        query: GET_PLANNING_DATA,
        data: {
          planningData: {
            ...planningData.planningData,
            id: appointmentData.id,
            title: appointmentData.title,
            beneficiaries: benefArray,
          },
        },
      });
    }
    setOpenBeneficiaryDialog(true);
  };

  const handleStartIntervention = async () => {
    await updateInterventionStatus({variables: {id: appointmentData.id , status: InterventionPlanningEnum.IN_PROGRESS}})
  }

  const handleStopIntervention = async () => {
    await updateInterventionStatus({variables: {id: appointmentData.id , status: InterventionPlanningEnum.WAITING_VALIDATION}})
  }

  const handleValidateIntervention = async () => {
    await updateInterventionStatus({variables: {id: appointmentData.id , status: InterventionPlanningEnum.COMPLETED}})
  }

  const handleCancelIntervention = async () => {
    await updateInterventionStatus({variables: {id: appointmentData.id , status: InterventionPlanningEnum.CANCELED}})
  }

  const renderCancelButton = () => (
    <Button
      onClick={handleCancelIntervention}
    >
      Annuler l'intervention
    </Button>
  )
<AppointmentForm.CommandLayoutProps/>

*/

  return (
    <>
      {appointmentData.title === undefined ? (
        <Box className={classes.root}>
          <AppointmentForm.Label
            className={classes.labelTitle}
            text="Cette date ne contient aucune activité !"
          />
        </Box>
      ) : (
        <Box className={classes.root}>
          <AppointmentForm.Label text="Titre" className={classes.label} />
          <AppointmentForm.TextEditor
            value={appointmentData.title || ''}
            onValueChange={(nextValue) => onFieldChange({ title: nextValue })}
            placeholder="Description"
            readOnly={isReadyOnly}
            type="ordinaryTextEditor"
            className={classes.textEditor}
          />

          <AppointmentForm.Label
            text="Nature de la prestation"
            className={classes.label}
          />
          <Select
            labelId="demo-simple-select-outlined-label"
            id="prestation"
            variant="outlined"
            value={appointmentData.prestation || ''}
            onChange={(nextValue) =>
              onFieldChange({ prestation: nextValue.target.value })
            }
            label="Nature de la prestation"
            className={classes.selectPrestation}
            disabled={isReadyOnly || !appointmentData.checkedForAll}
          >
            {(dataPrestation || []).map((item: Prestation, index: number) => (
              <MenuItem key={index} value={item.libelle}>
                {item.libelle}
              </MenuItem>
            ))}
          </Select>

          <AppointmentForm.Label
            text="Lieu / Etablissement"
            className={classes.label}
          />
          <AppointmentForm.TextEditor
            value={appointmentData.location || ''}
            onValueChange={(nextValue) =>
              onFieldChange({ location: nextValue })
            }
            placeholder="AM 154 Mont Marseille 1457"
            readOnly={isReadyOnly || !appointmentData.checkedForAll}
            type="ordinaryTextEditor"
            className={classes.textEditor}
          />
          <AppointmentForm.Label text="Commentaire" className={classes.label} />
          <AppointmentForm.TextEditor
            value={appointmentData.comment || ''}
            onValueChange={(nextValue) => onFieldChange({ comment: nextValue })}
            placeholder="Commentaire"
            readOnly={isReadyOnly}
            type="ordinaryTextEditor"
            className={classes.textEditor}
          />

          <AppointmentForm.Label text="Date" className={classes.label} />
          <Row style={{ marginBottom: 15 }}>
            <Col sm={12}>
              <TextField
                type="date"
                disabled={isReadyOnly}
                style={{ width: '100%' }}
                value={startDate_formatted}
                onChange={onChangeBothDates}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <Col md={6} sm={12}>
              <TextField
                label="Heure début"
                type="time"
                style={{ width: '100%' }}
                value={startHour}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={onChangeStartHour}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </Col>
            <Col md={6} sm={12}>
              <TextField
                label="Heure fin"
                type="time"
                style={{ width: '100%' }}
                value={endHour}
                onChange={onChangeEndHour}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </Col>
          </Row>
          <Col sm={12} style={{ paddingLeft: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={appointmentData.checkedForAll}
                  onChange={(event) => {
                    onFieldChange({ checkedForAll: event.target.checked });
                  }}
                  name="checkedA"
                />
              }
              label="Appliquer pour toutes les occurences"
            />
          </Col>
          <Link className={classes.link} onClick={handleOpenBenef}>
            Liste de bénéficiaires
          </Link>
          <Link className={classes.link} onClick={handleOpenWorker}>
            Liste d'intervenants
          </Link>
          {/* <AppointmentForm.Label text="Récurrence" className={classes.label} />
        <AppointmentForm.Select
          value={appointmentData.recurrence || ''}
          onValueChange={(nextValue) =>
            onFieldChange({ recurrence: nextValue })
          }
          placeholder="Tous les jours"
          type="outlinedSelect"
          className={classes.select}
        />

        <AppointmentForm.Label text="Statut" className={classes.label} />
        <AppointmentForm.Select
          value={appointmentData.statut || ''}
          onValueChange={(nextValue) => onFieldChange({ statut: nextValue })}
          placeholder="Confirmé"
          type="outlinedSelect"
          className={classes.select}
        />

        <Link className={classes.link} onClick={handleOpenBenef}>
          Liste de bénéficiaire
        </Link>
        <Link className={classes.link} onClick={handleOpenWorker}>
          Liste d'intervenant
        </Link>

        <Box>
          {appointmentData.status === 'TODO' && (
            <>
              <Button
                onClick={handleStartIntervention}
              >
                Commencer
              </Button>
              {renderCancelButton()}
            </>
          )}
          {appointmentData.status === 'IN_PROGRESS' && (
            <>
              <Button
                onClick={handleStopIntervention}
              >
                Terminer
              </Button>
              {renderCancelButton()}
            </>
          )}
          {appointmentData.status === 'WAITING_VALIDATION' && (
            <>
              <Button
                onClick={handleValidateIntervention}
              >
                Valider
              </Button>
            </>
          )}
        </Box>  */}
          {openBeneficiaryDialog && appointmentData?.id && (
            <BeneficiaryPlanningEdit
              open={openBeneficiaryDialog}
              setOpen={setOpenBeneficiaryDialog}
              id={[appointmentData.id as number]}
            />
          )}
          {openWorkerDialog && appointmentData?.id && (
            <WorkerPlanningEdit
              open={openWorkerDialog}
              setOpen={setOpenWorkerDialog}
              id={[appointmentData.id as number]}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default AppointmentDetails;
