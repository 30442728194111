import { EtablishmentContributorsInfo_etablishment_residences } from '../../../graphql/etablishment/types/EtablishmentContributorsInfo';

export interface EstablishmentInfoData {
  name: string;
  type: string;
  rcs: string;
  logo: string;
  enabled: boolean;
  address: string;
  objet: string;
  codePostal: string;
  ville: string;
  status: string;
}

export interface ExtendedEstablishmentInfoData extends EstablishmentInfoData {
  residences: EtablishmentContributorsInfo_etablishment_residences | null;
}

export interface EstablishmentPrestationData {
  prestations: string;
}

export const INIT_ESTABLISHMENT_INFO = {
  name: '',
  type: '',
  rcs: '',
  logo: '',
  enabled: false,
  address: '',
  objet: '',
  codePostal: '',
  ville: '',
  status: '',
};

export const INIT_ESTABLISHMENT_PRESTATIONS = {
  prestations: '',
};
