import gql from 'graphql-tag';

export const GET_ETABLISHMENT_DATA = gql`
  query GetEtablishmentData {
    etablishmentData @client {
      __typename
      id
      name
      address
      rcs
      postalCode
      city
      effective
      logo
      prestations
      enabled
      etablishmentObject
      etablishmentTypeId
      etablishmentStatusId
      administratorIds
      workerIds
      beneficiaryIds
      residenceIds
      workers
      administrators
      beneficiaries
    }
  }
`;

export const GET_ETABLISHMENT_ROOT_DATA = gql`
  query GetEtablishmentRootData {
    etablishmentRootData @client {
      __typename
      id
      logo
      name
      address
      postalCode
      etablishmentObject
      city
    }
  }
`;

export const GET_USER_EDIT_DATAS = gql`
  query GetUserEditDatas {
    userEditDatas @client {
      __typename
      administrator {
        deletedUsersIds
        newUsersIds
      }
      worker {
        deletedUsersIds
        newUsersIds
      }
      beneficiary {
        deletedUsersIds
        newUsersIds
      }
    }
  }
`;
