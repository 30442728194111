import gql from 'graphql-tag';

import { UNIT_LIFE_FRAGMENT } from './fragment';

export const GET_UNIT_LIFES = gql`
  query UnitLifes($input: FilterUnitLifeInput) {
    unitLifes(input: $input) {
      ...UnitLifeInfo
    }
  }
  ${UNIT_LIFE_FRAGMENT}
`;

export const GET_UNIT_LIFES_BY_ETABLISHMENT = gql`
  query UnitLifeByEstablishment($establishmentId: Int!) {
    unitLifeByEstablishment(establishmentId: $establishmentId) {
      ...UnitLifeInfo
    }
  }
  ${UNIT_LIFE_FRAGMENT}
`;

export const GET_UNIT_LIFE = gql`
  query UnitLife($id: Int!) {
    unitLife(id: $id) {
      ...UnitLifeInfo
    }
  }
  ${UNIT_LIFE_FRAGMENT}
`;

export const GET_UNIT_LIFE_BY_IDS = gql`
  query UnitLifesByIds($ids: [Int!]!) {
    unitLifesByIds(ids: $ids) {
      ...UnitLifeInfo
    }
  }
  ${UNIT_LIFE_FRAGMENT}
`;
