import { PrestationDataType } from './prestation';

export enum PrestationValidationError {
  EMPTY_LIBELLE = 'EMPTY_LIBELLE',
}

export interface PrestationValdationState {
  valid: boolean;
  error?: PrestationValidationError;
}

export type PrestationValidatorFunc = (
  prestation: PrestationDataType,
  key: keyof PrestationDataType,
) => PrestationValdationState;

export type PrestationValidators = Partial<
  Record<keyof PrestationDataType, PrestationValidatorFunc[]>
>;
export type PrestationValidationMessages = Record<
  PrestationValidationError,
  string
>;
